import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { ViewerTopBar } from 'src/components/EmbeddedViewer/ViewerTopBar'
import { DocumentViewer } from 'src/components/EmbeddedViewer/DocumentViewer'
import { useOnboardingStepPayload } from '../useOnboardingStepPayload'
import { AttachmentsDownloadSource, useAnalytics } from 'src/analytics'
import { getFileExtension } from 'src/utils/fileExtension'

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid ${palette('cloudBlueLight', 1)};
`

export function GuidelineStep() {
    const { guidelines } = useOnboardingStepPayload('guidelines')

    const analytics = useAnalytics()
    const onDownload = useCallback(
        (fileUrl: string) => {
            analytics?.sendAttachmentDownloaded(
                getFileExtension(fileUrl).toLowerCase(),
                AttachmentsDownloadSource.ONBOARDING_GUIDELINES,
            )
        },
        [analytics],
    )

    return (
        <Container>
            <ViewerTopBar currentFileName="Session Guidelines" currentFile={guidelines} />
            <DocumentViewer currentFileUrl={guidelines} onDownload={onDownload} />
        </Container>
    )
}
