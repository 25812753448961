export function deepClone(obj: any, levels: number = Infinity): any {
    if (Array.isArray(obj)) {
        if (levels === 1) {
            return [...obj]
        }
        return obj.map((item) => deepClone(item, levels - 1))
    }

    if (obj instanceof Date) {
        return new Date(obj)
    }

    if (typeof obj === 'object') {
        if (levels === 1) {
            return { ...obj }
        }

        const newObj: any = {}
        for (const key in obj) {
            newObj[key] = deepClone(obj[key], levels - 1)
        }
        return newObj
    }

    return obj
}

type ObjectLeafType = HTMLElement | Date | string | number | boolean | bigint | symbol | Function

type PathKeys<T> = T extends ObjectLeafType
    ? []
    : {
          [K in keyof T]-?: K extends string | number ? [K] | [K, ...PathKeys<T[K]>] : never
      }[keyof T]

type Join<T, D extends string> = T extends []
    ? never
    : T extends [infer F]
    ? F
    : T extends [infer F, ...infer R]
    ? F extends string | number
        ? `${F}${D}${Join<R, D>}`
        : never
    : string

// this is courtesy of ChatGPT
export type ObjectPaths<T> = Join<PathKeys<T>, '.'>

export function getValuesByPath<T extends object>(obj: T, path: ObjectPaths<T>): any {
    const keys = (path as string).split('.')
    let value = obj
    for (const key of keys) {
        value = (value as any)[key]

        if (!value) {
            break
        }
    }
    return value
}
