import { useCallback, useMemo, useRef, useState } from 'react'

import { Descendant } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'

import {
    useTaskMachine,
    useTask,
    useTaskEditorMode,
} from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TranscriptionTask as TranscriptionTaskType } from 'src/models'
import { Spinner } from 'src/components/Session/Spinner'
import { PublishButton } from 'src/components/Session/PublishButton'
import { TaskAudioBufferingTimeoutHandler } from 'src/components/Session/TaskAudioBufferingTimeoutHandler'
import { useAudioTimingConverter, useTaskMediaPlayer } from 'src/state/TaskAudioContext'
import { AUDIO_OFFSET_FOR_TASK_START_IN_SECONDS, AudioPlayer } from 'src/components/AudioPlayer'
import { withTags } from 'src/components/Editor/plugins/withTags/withTags'
import { withSuggestions } from 'src/components/Editor/plugins/withSuggestions/withSuggestions'
import { EditorContext } from 'src/components/Editor/EditorContext'
import { withTimeline } from 'src/components/Editor/plugins/withTimeline/withTimeline'
import { Timeline } from 'src/components/Editor/plugins/withTimeline/timeline'
import { createBlocks, withTranscript } from 'src/components/Editor/plugins/withTranscript'
import { useAudioAutoplay } from 'src/components/Editor/plugins/withTimeline/hooks/useAudioAutoplay'
import { convertEditorValueToPublishingData } from 'src/components/Editor/convert-to-publish'
import { withLogger } from 'src/components/Editor/plugins/withLogger'
import { useTaskPayloadSender } from 'src/components/TaskRouter/TaskState'
import { withSpellchecker, Spellchecker } from 'src/components/Editor/plugins/withSpellchecker'
import { useSpellchecker } from 'src/components/Spellchecker/Spellchecker'
import { TaskCache } from 'src/state/TaskCache'
import { getValidationsRegexesAccordingToCustomerId } from 'src/components/Editor/plugins/withValidations/regexList'
import { Validations, withValidations } from 'src/components/Editor/plugins/withValidations'
import { withGlossers } from 'src/components/Editor/plugins/withGlossers'

import { ErrorScreen } from '../Common/ErrorScreen'
import { SomethingWentWrongIcon } from '../icons'
import { TaskEditor } from './TaskEditor'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useAnalytics } from 'src/analytics'
import { useAudioTimePlayed } from 'src/hooks/useAudioTimePlayed'
import { useCurrentTimeRef } from '../Editor/plugins/withTimeline/hooks/useCurrentTimeRef'
import { useSessionMedia } from 'src/state/SessionMediaProvider'
import { BaseSegment } from '../Editor/plugins/withTimeline/timeline/Segment'
import { HeuristicAligner } from '../Editor/plugins/withTimeline/align/HeuristicAligner'

interface TaskContentProps {
    isRuntimeTimestampsVisible?: boolean
}

const SessionPanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-height: 100%;
`

const SpinnerContainer = styled.div`
    position: absolute;
    z-index: ${theme('zIndexes.screenLoaders')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${palette('cloudBlueLight', 7)};
`

const StyledSpinner = styled(Spinner)`
    opacity: 1;
    animation: none;
`

const TaskContentContainer = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
    overflow: hidden;
`

export function TranscriptionTask() {
    const isValidationFeatureFlagOn = useFeatureFlag('validations')
    const [, sendEvent] = useTaskMachine([])
    const task = useTask<TranscriptionTaskType>()
    const { sessionStatus } = useSessionStatus([
        'sessionStatus',
        'sessionStatus.sessionId',
        'sessionStatus.platformJobId',
        'sessionStatus.customerId',
    ])
    const analytics = useAnalytics()
    const editorMode = useTaskEditorMode()
    const { playedTimeRanges } = useTaskMediaPlayer(['playedTimeRanges'])
    const isEditorInGlossersMode = editorMode === 'glossers'
    const { suggestionsEnabled } = task.payload.controls

    useAudioTimingConverter(() => {
        const { timing, text, controls } = task.payload
        const { start, end } = timing
        const bufferingStartTime = Math.max(
            start,
            text.editable.timing.start - AUDIO_OFFSET_FOR_TASK_START_IN_SECONDS,
        )
        const bufferingEndTime = Math.min(text.editable.timing.end, bufferingStartTime + 10)

        return {
            timing: timing,
            duration: end - start,
            requiredRangeToBePlayed: controls.audio.forceListen ? text.editable.timing : undefined,
            requiredRangeToBeBuffered: {
                start: bufferingStartTime,
                end: bufferingEndTime,
            },
            activeSegment: {
                start: text.editable.timing.start - start,
                end: text.editable.timing.end - start,
            },
        }
    }, [task])

    const isSpeakerLineVisible = task.payload.controls.diarization.visible
    const isRuntimeTimestampsVisible = task?.payload?.controls?.runtimeTimestamp?.visible

    const [initialContent, timeline] = useMemo(() => {
        const cachedTranscriptSnapshot = TaskCache.recoverTranscriptSnapshot()

        if (cachedTranscriptSnapshot) {
            return [
                cachedTranscriptSnapshot.document,
                new Timeline({
                    segmentTimings: cachedTranscriptSnapshot.timings.timing,
                    aligner: new HeuristicAligner(),
                    initialSegment: cachedTranscriptSnapshot.timings,
                }),
            ]
        }

        // create the intial blocks regardless of the cached transcript, to restore the initial timings in the timeline
        const [before, beforeTimings] = createBlocks(task.payload.text.before, {
            editable: false,
            isFirstSegment: true,
        })
        const [editable, editableTimings] = createBlocks(task.payload.text.editable, {
            editable: true,
            isFirstSegment: before.length === 0, // The editable segment is the first one if there are no 'before' blocks
            prevLegalAnnotation: before[before.length - 1]?.legal?.annotation,
            shouldAutoPopulationOverwrite: task.payload.controls.legal.autoPopulation.overwrite,
            initialSpeakersByLegalAnnotation: task.payload.annotationMap,
        })
        const [after, afterTimings] = createBlocks(task.payload.text.after, {
            editable: false,
            isFirstSegment: false,
        })

        const general = BaseSegment.concat(beforeTimings, editableTimings, afterTimings)
        const timeline = new Timeline({
            segmentTimings: task.payload.timing,
            aligner: new HeuristicAligner(),
            initialSegment: general,
        })

        const createdTranscript = [before, editable, after].flat()

        return [createdTranscript, timeline]
    }, [
        task.payload.annotationMap,
        task.payload.controls.legal.autoPopulation.overwrite,
        task.payload.text.after,
        task.payload.text.before,
        task.payload.text.editable,
        task.payload.timing,
    ])

    const { spellchecker } = useSpellchecker()
    const currentTimeRef = useCurrentTimeRef()

    const plugins = useMemo(() => {
        let plugins = [
            withTranscript({ isSpeakerLineVisible }),
            withTags({
                enableGlossary: true,
                enableUnclears: true,
                enableLabels: task.payload.controls.label.visible ?? false,
                labels: task.payload.controls.label.items,
            }),
            withTimeline({ timeline, currentTimeRef }),
            withLogger,
        ]
        const { customerId, platformJobId } = sessionStatus || {}
        const validationRegexes = getValidationsRegexesAccordingToCustomerId(customerId)
        const analyticsMetadata = {
            customerId,
            sessionId: sessionStatus?.sessionId,
            id: task.id,
            platformJobId,
        }
        const shouldAddSpellcheckerPlugin = !isEditorInGlossersMode
        const shouldAddSuggestionsPlugin = !isEditorInGlossersMode && suggestionsEnabled
        const shouldAddValidationsPlugin =
            isValidationFeatureFlagOn && task.layerId !== 'annotate' && !isEditorInGlossersMode
        const shouldAddGlossersPlugin = isEditorInGlossersMode

        if (shouldAddSpellcheckerPlugin) {
            plugins.push(withSpellchecker(spellchecker))
        }
        if (shouldAddSuggestionsPlugin) {
            plugins.push(withSuggestions)
        }
        if (shouldAddValidationsPlugin) {
            plugins.push(
                withValidations(validationRegexes, spellchecker, analytics, analyticsMetadata),
            )
        }
        if (shouldAddGlossersPlugin) {
            plugins.push(withGlossers)
        }

        return plugins
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSpeakerLineVisible, timeline, spellchecker, suggestionsEnabled, editorMode])

    const [content, setContent] = useState<Descendant[]>(initialContent)
    const onChange = useCallback((newContent: Descendant[]) => {
        setContent(newContent)
    }, [])

    return (
        <EditorContext
            plugins={plugins}
            content={content}
            meta={{
                controls: task.payload.controls,
                editorMode,
                sessionId: sessionStatus?.sessionId,
                taskId: task.id,
            }}
            saveCache
            onChange={onChange}
            task={task}
            playedTimeRanges={playedTimeRanges}
        >
            <TranscriptionTaskPayloadSender />

            <TaskAudioBufferingTimeoutHandler
                timeoutInSeconds={task.payload.controls.audio.bufferingTimeoutInSeconds}
                onTimeout={() => {
                    // TODO: if we are using the video URL, maybe first switch to audio only URL, instead of aborting
                    sendEvent({ type: 'ABORT', reason: 'audio_unavailable' })
                }}
                loader={
                    <SpinnerContainer>
                        <StyledSpinner
                            title="Buffering Audio"
                            subtitle="This can take a few seconds to a minute."
                        />
                    </SpinnerContainer>
                }
                connectionError={
                    <ErrorScreen
                        icon={<SomethingWentWrongIcon />}
                        title="Audio connection error"
                        subtitle={
                            <>
                                Try refreshing the page.
                                <br />
                                If the problem persists contact{' '}
                                <a href="mailto:support@verbit.ai">support</a>.
                            </>
                        }
                        actionTitle="Refresh Page"
                        action={() => globalThis.location.reload()}
                    />
                }
            >
                <>
                    <SessionPanel>
                        <TaskContent isRuntimeTimestampsVisible={isRuntimeTimestampsVisible} />
                    </SessionPanel>

                    <PublishButton />
                </>
            </TaskAudioBufferingTimeoutHandler>
        </EditorContext>
    )
}

const TaskContent = ({ isRuntimeTimestampsVisible }: TaskContentProps) => {
    const task = useTask<TranscriptionTaskType>()
    const containerRef = useRef<HTMLDivElement | null>(null)
    useAudioAutoplay(task.payload.controls.audio.autoplay)
    return (
        <TaskContentContainer>
            <TaskEditor containerRef={containerRef} />
            <AudioPlayer isRuntimeTimestampsVisible={isRuntimeTimestampsVisible} />
        </TaskContentContainer>
    )
}

const TranscriptionTaskPayloadSender = () => {
    const task = useTask<TranscriptionTaskType>()
    const [, sendEvent] = useTaskMachine([])
    const { playedTimeRanges } = useTaskMediaPlayer(['playedTimeRanges'])
    const editor = useSlateStatic()

    const { mediaRef } = useSessionMedia(['mediaRef'])
    const { getPlayedAudioTime } = useAudioTimePlayed(mediaRef)

    // explanation see implementation
    useTaskPayloadSender(() => {
        const spellingErrorCount = Spellchecker.getSpellingErrorsCount(editor)
        const invalidTermsCount = Validations.getInvalidTermsCount(editor)
        const currentValidationsCount = Validations.getCurrentValidations(editor)

        sendEvent('ASSIGN_SPELLING_ERRORS_COUNT_END', { spellingErrorCount })
        sendEvent('ASSIGN_INVALID_TERMS_COUNT_END', { invalidTermsCount })
        sendEvent('ASSIGN_CURRENT_VALIDATIONS_STATUS', { currentValidationsCount })
        sendEvent('ASSIGN_AUDIO_TIME_LISTENED', {
            audioTimeListenedSeconds: getPlayedAudioTime() / 1000,
        })

        if (Validations.isValidationEditor(editor)) {
            Validations.compareBeforeAndAfterStrings(editor)
        }

        return convertEditorValueToPublishingData(editor, task, playedTimeRanges)
    }, [editor.children, task, playedTimeRanges])

    return null
}
