import { EventObject, send, Interpreter, assign, Machine } from 'xstate'
import { AppStateContext } from 'src/state/state-machines/AppMachine/AppMachine'
import { HttpClient } from 'src/network'
import { RedirectURLs } from 'src/network/responses'
import { Transcript } from 'src/models/transcripts'
import { EditorControls } from 'src/models'

interface ErrorEvent extends EventObject {
    type: 'ERROR'
    error: Error
}

interface RefreshTranscriptEvent extends EventObject {
    type: 'REFRESH_TRANSCRIPT'
}

export interface LiveStateSchema {
    states: {
        initializing: {}
        working: {}
    }
}

export type LiveEvents = ErrorEvent | RefreshTranscriptEvent

export interface LiveStateContext {
    controls: EditorControls
    httpClient?: HttpClient
    liveTranscript?: Transcript
    websocketUrl?: string
    sessionId?: string
    mediaSource?: string
    redirect_urls?: RedirectURLs
    didEditorReload?: boolean
}

export type LiveMachineType = Interpreter<LiveStateContext, LiveStateSchema, LiveEvents>

export const LiveMachine = Machine<LiveStateContext, LiveStateSchema, LiveEvents>(
    {
        id: 'live-machine',
        initial: 'initializing',
        states: {
            initializing: {
                invoke: {
                    id: 'fetchTranscript',
                    src: 'fetchTranscript',
                    data: {
                        httpClient: (ctx: AppStateContext) => ctx.httpClient,
                    },
                    onError: {
                        actions: send({ type: 'ERROR', error: (_: any, e: any) => e.data.error }),
                    },
                    onDone: {
                        target: 'working',
                        actions: [
                            assign({
                                liveTranscript: (_, event) => {
                                    return event.data
                                },
                            }),
                        ],
                    },
                },
            },
            working: {
                on: {
                    REFRESH_TRANSCRIPT: {
                        target: 'initializing',
                        actions: assign({ didEditorReload: true }),
                    },
                },
            },
        },
    },
    {
        services: {
            fetchTranscript: (ctx) => async (send) => {
                const { httpClient } = ctx
                if (!httpClient) throw new Error(`No HttpClient available in LiveMachine`)

                try {
                    const transcripts = await httpClient.getTranscript()
                    return transcripts
                } catch (e) {
                    send({ type: 'ERROR', error: e as Error })
                    return
                }
            },
        },
        actions: {},
        delays: {},
        guards: {},
    },
)
