import { useEffect, useState } from 'react'
import { Editor } from 'slate'
import { useSlate } from 'slate-react'
import { isAllWhitespace } from 'src/components/Editor/utils'

export function useIsEditorEmpty() {
    const editor = useSlate()
    const [isEditorEmpty, setIsEditorEmpty] = useState(true)

    useEffect(() => {
        if (isEditorEmpty) {
            const text = Editor.string(editor, [])
            const isEmpty = text.length === 0 || (text.length > 0 && isAllWhitespace(text))
            if (!isEmpty) {
                setIsEditorEmpty(false)
            }
        }
    }, [editor, editor.children, isEditorEmpty])

    return isEditorEmpty
}
