import { useCallback, useState } from 'react'
import { StateValue } from 'xstate'
import { Popover2 } from '@blueprintjs/popover2'

import { useToast } from 'src/components/Toasts/ToastContext'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { Container, Footer, Message, Title } from 'src/components/Common/styled/popup'
import { AnchorButton, NegativeButton, PositiveButton } from 'src/components/Common/styled/buttons'

export function ExitPopup() {
    const [isOpen, setIsOpen] = useState(false)
    const [{ value: appState }] = useAppMachine(['state'])
    const [{ value: state, context }, sendTaskEvent] = useTaskMachine(['state', 'exiting'])

    const addToast = useToast()

    const onExit = useCallback(async () => {
        if (
            state === 'waiting-for-task' ||
            state === 'aborting' ||
            context.task?.type === 'resting'
        ) {
            sendTaskEvent('EXIT')
            addToast({ intent: 'success', message: <>You have left the session.</> })
        } else {
            sendTaskEvent('TOGGLE_EXIT')
            addToast({
                intent: 'none',
                message: <>Session will be closed once you submit this task.</>,
            })
        }
        setIsOpen(false)
    }, [addToast, context.task, sendTaskEvent, state])

    const onDismiss = useCallback(async () => {
        if (state === 'in-progress' && context.exiting) {
            sendTaskEvent('TOGGLE_EXIT')
            addToast({
                intent: 'none',
                message: <>Session will not be closed. Glad to keep you here!</>,
            })
        }
        setIsOpen(false)
    }, [addToast, context.exiting, sendTaskEvent, state])

    if (appState !== 'working') {
        return null
    }

    return (
        <Popover2
            className="no-shadow"
            isOpen={isOpen}
            modifiers={{ arrow: { enabled: false }, offset: { options: { offset: [0, 9] } } }}
            onClose={() => setIsOpen(false)}
            canEscapeKeyClose
            content={
                <Container>
                    <Title>Leaving already? The team still needs you.</Title>
                    <Message>There's still time left in the session you've committed to.</Message>
                    {state === 'waiting-for-task' && <p>New tasks should be back shortly.</p>}
                    <Footer>
                        <PositiveButton
                            onClick={onExit}
                            disabled={state === 'in-progress' && context.exiting}
                        >
                            {getExitMessage(
                                (state === 'in-progress' && context.exiting) || false,
                                state,
                            )}
                        </PositiveButton>
                        <NegativeButton onClick={onDismiss}>
                            {state === 'in-progress' ? <>Keep Working</> : <>Wait for more tasks</>}
                        </NegativeButton>
                    </Footer>
                </Container>
            }
        >
            <AnchorButton onClick={() => setIsOpen(!isOpen)}>Exit</AnchorButton>
        </Popover2>
    )
}

function getExitMessage(exiting: boolean, status: StateValue) {
    if (exiting) {
        return `Exiting${(status === 'in-progress' && ' after this task') || ''}`
    } else {
        return `Exit${(status === 'in-progress' && ' after this task') || ''}`
    }
}
