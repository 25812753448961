import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import {
    GlossaryEditModal,
    GlossaryEditModalProps,
} from 'src/components/Glossary/GlossaryEditModal'
import { ErrorDialog, ErrorDialogProps } from 'src/components/Session/ErrorDialog'
import {
    GlossaryDeleteModal,
    GlossaryDeleteModalProps,
} from 'src/components/Glossary/GlossaryDeleteModal'

export interface ModalComponentProps {
    onClose: () => void
}

type OmitModalComponentProps<T> = Omit<T, keyof ModalComponentProps>

type ActiveModalOption<Name, Props extends ModalComponentProps> = {
    name: Name
    props: OmitModalComponentProps<Props> & Partial<ModalComponentProps>
}

type ActiveModal =
    | ActiveModalOption<'glossaryEdit', GlossaryEditModalProps>
    | ActiveModalOption<'glossaryDelete', GlossaryDeleteModalProps>
    | ActiveModalOption<'error', ErrorDialogProps>

interface ModalProviderProps {
    children: ReactNode
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const [activeModal, setActiveModal] = useState<ActiveModal | null>(null)
    const closeModal = useCallback(() => setActiveModal(null), [])

    const contextValue = useMemo<ModalContextValue>(
        () => ({
            openModal: setActiveModal,
            closeModal,
            currentModal: activeModal?.name,
        }),
        [activeModal, closeModal],
    )

    const renderActiveModal = useCallback(() => {
        if (!activeModal) {
            return null
        }

        const onClose = () => {
            activeModal.props.onClose?.()
            closeModal()
        }

        switch (activeModal.name) {
            case 'glossaryEdit':
                return <GlossaryEditModal {...activeModal.props} onClose={onClose} />
            case 'glossaryDelete':
                return <GlossaryDeleteModal {...activeModal.props} onClose={onClose} />
            case 'error':
                return <ErrorDialog {...activeModal.props} onClose={onClose} />
        }
    }, [activeModal, closeModal])

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
            {renderActiveModal()}
        </ModalContext.Provider>
    )
}

interface ModalContextValue {
    openModal: (modal: ActiveModal) => void
    closeModal: () => void
    currentModal?: ActiveModal['name']
}

export const ModalContext = createContext<ModalContextValue | null>(null)

export const useModal = () => {
    const modalContextValue = useContext(ModalContext)

    if (!modalContextValue) {
        throw new Error('You have forgot to use ModalContext, shame on you.')
    }

    return modalContextValue
}
