import { useRef } from 'react'
import { useSessionMediaService } from 'src/state/SessionMediaProvider'

export function useCurrentTimeRef() {
    const currentTimeRef = useRef(0)
    useSessionMediaService(
        ({ currentTime }) => {
            currentTimeRef.current = currentTime
        },
        ['currentTime'],
    )

    return currentTimeRef
}
