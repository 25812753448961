import { Interpreter, Machine } from 'xstate'

export interface TaskStateContext {}

export interface TaskValidationStateSchema {
    states: {
        idle: {}
        pending: {}
        valid: {}
        invalid: {}
    }
}

export type TaskEvent =
    | { type: 'VALIDATE_TASK' }
    | { type: 'TASK_VALIDATION_FAILED' }
    | { type: 'TASK_VALIDATION_SUCCEEDED' }

export type TaskValidationMachineType = Interpreter<
    TaskStateContext,
    TaskValidationStateSchema,
    TaskEvent
>

export const TaskValidationMachine = Machine<
    TaskStateContext,
    TaskValidationStateSchema,
    TaskEvent
>({
    id: 'taskValidation',
    initial: 'idle',
    context: {},
    states: {
        idle: {
            on: {
                VALIDATE_TASK: 'pending',
            },
        },
        pending: {
            on: {
                TASK_VALIDATION_SUCCEEDED: 'valid',
                TASK_VALIDATION_FAILED: 'invalid',
            },
        },
        valid: {
            type: 'final',
        },
        invalid: {
            on: {
                VALIDATE_TASK: 'pending',
            },
        },
    },
})
