import { Editor, Range, Point, Transforms } from 'slate'
import { Suggestion } from './Suggestion'
import { SuggestionView } from './SuggestionView'

export function withSuggestions(editor: Editor) {
    const { isInline, apply, normalizeNode, insertBreak, deleteFragment, renderElement } = editor

    editor.isInline = (el) => Suggestion.isSuggestion(el) || isInline(el)

    editor.apply = (operation) => {
        let op = operation

        // if we moved one character backward/forward
        if (
            op.type === 'set_selection' &&
            Range.isRange(op.properties) &&
            Range.isRange(op.newProperties) &&
            Range.isCollapsed(op.properties) &&
            Range.isCollapsed(op.newProperties) &&
            Editor.string(editor, { anchor: op.properties.anchor, focus: op.newProperties.anchor })
                .length <= 1
        ) {
            let normalizedPoint: Point | undefined

            if (Suggestion.hasSuggestion(editor, { at: op.properties })) {
                const [[, suggestionPath]] = Editor.nodes(editor, {
                    at: op.properties,
                    match: Suggestion.isSuggestion,
                })
                const sugEndPoint = Editor.end(editor, suggestionPath)
                const sugStartPoint = Editor.start(editor, suggestionPath)

                // exiting suggestion forward
                if (
                    Point.equals(op.properties.anchor, sugEndPoint) &&
                    Point.isAfter(op.newProperties.anchor, op.properties.anchor)
                ) {
                    normalizedPoint = Editor.after(editor, op.properties.anchor, {
                        distance: 1,
                        unit: 'offset',
                    })
                }

                // exiting suggestion backward
                else if (
                    Point.equals(op.properties.anchor, sugStartPoint) &&
                    Point.isBefore(op.newProperties.anchor, op.properties.anchor)
                ) {
                    normalizedPoint = Editor.before(editor, op.properties.anchor, {
                        distance: 1,
                        unit: 'offset',
                    })
                }
            } else if (Suggestion.hasSuggestion(editor, { at: op.newProperties })) {
                const [[, suggestionPath]] = Editor.nodes(editor, {
                    at: op.newProperties,
                    match: Suggestion.isSuggestion,
                })
                const sugStartPoint = Editor.start(editor, suggestionPath)
                const sugEndPoint = Editor.end(editor, suggestionPath)
                const sugOuterStartPoint = Editor.before(editor, sugStartPoint, {
                    distance: 1,
                    unit: 'offset',
                })
                const sugOuterEndPoint = Editor.after(editor, sugEndPoint, {
                    distance: 1,
                    unit: 'offset',
                })

                // entering suggestion backward
                if (
                    sugOuterEndPoint &&
                    Point.equals(op.properties.anchor, sugOuterEndPoint) &&
                    Point.isBefore(op.newProperties.anchor, op.properties.anchor)
                ) {
                    normalizedPoint = Editor.before(editor, op.properties.anchor, {
                        distance: 1,
                        unit: 'offset',
                    })
                }

                // entering suggestion forward
                else if (
                    sugOuterStartPoint &&
                    Point.equals(op.properties.anchor, sugOuterStartPoint) &&
                    Point.isAfter(op.newProperties.anchor, op.properties.anchor)
                ) {
                    normalizedPoint = Editor.after(editor, op.properties.anchor, {
                        distance: 1,
                        unit: 'offset',
                    })
                }
            }

            if (normalizedPoint) {
                op.newProperties = {
                    anchor: normalizedPoint,
                    focus: normalizedPoint,
                }
            }
        }

        apply(op)
    }

    editor.normalizeNode = (entry) => {
        const [node, path] = entry

        if (Suggestion.isSuggestion(node)) {
            const suggestionString = Editor.string(editor, path)

            // if we modified the suggestion text, unwrap it
            if (suggestionString !== node.originalText) {
                Suggestion.unwrapSuggestion(editor, { at: path })
                return
            }
        }

        normalizeNode(entry)
    }

    editor.insertBreak = () => {
        if (editor.selection && Range.isCollapsed(editor.selection)) {
            const breakPoint = editor.selection.anchor

            // if the break point is at the end of a text node inside a suggestion node, move the breakpoint outside suggestion node to prevent the selection from staying the same after inserting the break
            // no need to take care of the opposite scenario where the cursor is at the beginning of the text node because slate's native behaviour handles it
            if (
                Suggestion.hasSuggestion(editor, { at: breakPoint }) &&
                Point.equals(Editor.end(editor, breakPoint.path), breakPoint)
            ) {
                Transforms.move(editor, { distance: 1, unit: 'offset' })
            }
        }

        insertBreak()
    }

    editor.deleteFragment = () => {
        if (Suggestion.hasSuggestion(editor)) {
            // unwrapSuggestion before deleteFragment to prevent wrong result selection in the end
            Suggestion.unwrapSuggestion(editor)
        }

        deleteFragment()
    }

    editor.renderElement = (props) => {
        const element = props.element

        if (Suggestion.isSuggestion(element)) {
            return <SuggestionView {...props} element={element} />
        }

        return renderElement(props)
    }

    return editor
}
