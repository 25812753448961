import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components/macro'

import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useSpeakersById } from 'src/state/SpeakersProvider'
import { useMediaSource } from 'src/hooks/useAudioSource'
import { useAudioBufferingState } from 'src/hooks/useAudioBufferingState'
import { useSessionMedia } from './SessionMediaProvider'

const Audio = styled.audio`
    display: none;
`

interface SpeakerVoiceSamplePlayerProviderProps {
    children?: React.ReactNode
}

export const SpeakerVoiceSamplePlayerProvider = ({
    children,
}: SpeakerVoiceSamplePlayerProviderProps) => {
    const [currentPlayingSpeakerVoiceSample, setCurrentPlayingSpeakerVoiceSample] =
        useState<CurrentPlayingVoiceSample>(null)
    const speakersById = useSpeakersById()
    const voiceSampleAudioRef = useRef<HTMLAudioElement>(null)
    const isBuffering = useAudioBufferingState(voiceSampleAudioRef.current)
    // reduce the buffer length to 10 seconds to reduce the network load
    const audioUrl = useSessionStatus(['sessionStatus.audioUrl']).sessionStatus?.audioUrl ?? null
    const { isConnected, connect } = useMediaSource(voiceSampleAudioRef, audioUrl, {
        maxBufferLength: 10,
        maxMaxBufferLength: 10,
        label: 'SpeakerVoiceSamplePlayerProvider',
    })

    const pauseSpeakerVoiceSample = useCallback(() => {
        setCurrentPlayingSpeakerVoiceSample(null)
        voiceSampleAudioRef.current?.pause()
    }, [voiceSampleAudioRef])

    const playSpeakerVoiceSample = useCallback<PlayVoiceSample>(
        (speakerId, sampleId) => {
            const sample = speakersById[speakerId].samples[sampleId]

            if (!voiceSampleAudioRef.current || !sample) {
                return
            }

            if (!isConnected) {
                connect(sample.start)
            }

            setCurrentPlayingSpeakerVoiceSample({ speakerId, sampleId })
            voiceSampleAudioRef.current.currentTime = sample.start
            voiceSampleAudioRef.current.play().catch(() => {})
        },
        [speakersById, isConnected, connect],
    )

    useEffect(() => {
        if (!currentPlayingSpeakerVoiceSample || !voiceSampleAudioRef.current) return

        const { speakerId, sampleId } = currentPlayingSpeakerVoiceSample

        // If this sample was deleted by another user
        if (!speakersById[speakerId].samples[sampleId]) {
            pauseSpeakerVoiceSample()
        }
    }, [currentPlayingSpeakerVoiceSample, pauseSpeakerVoiceSample, speakersById])

    const onTimeUpdate = useCallback(() => {
        if (!currentPlayingSpeakerVoiceSample || !voiceSampleAudioRef.current) return

        const { speakerId, sampleId } = currentPlayingSpeakerVoiceSample
        const currentPlayingSampleEnd = speakersById[speakerId]?.samples[sampleId]?.end

        if (
            !currentPlayingSampleEnd ||
            voiceSampleAudioRef.current.currentTime >= currentPlayingSampleEnd
        ) {
            pauseSpeakerVoiceSample()
        }
    }, [
        voiceSampleAudioRef,
        currentPlayingSpeakerVoiceSample,
        pauseSpeakerVoiceSample,
        speakersById,
    ])

    const contextValue = useMemo<SpeakerVoiceSamplePlayerContextValue>(
        () => ({
            playVoiceSample: playSpeakerVoiceSample,
            pause: pauseSpeakerVoiceSample,
            isConnected,
            isBuffering,
            currentPlayingVoiceSample: currentPlayingSpeakerVoiceSample,
        }),
        [
            playSpeakerVoiceSample,
            pauseSpeakerVoiceSample,
            isConnected,
            isBuffering,
            currentPlayingSpeakerVoiceSample,
        ],
    )

    return (
        <SpeakerVoiceSamplePlayerContext.Provider value={contextValue}>
            <Audio ref={voiceSampleAudioRef} onTimeUpdate={onTimeUpdate} preload="auto" controls />
            {children}
        </SpeakerVoiceSamplePlayerContext.Provider>
    )
}

export type CurrentPlayingVoiceSample = {
    speakerId: string
    sampleId: string
} | null

type PlayVoiceSample = (speakerId: string, sampleId: string) => void

interface SpeakerVoiceSamplePlayerContextValue {
    playVoiceSample: PlayVoiceSample
    pause(): void
    isConnected: boolean
    isBuffering: boolean
    currentPlayingVoiceSample: CurrentPlayingVoiceSample
}

export const SpeakerVoiceSamplePlayerContext =
    createContext<SpeakerVoiceSamplePlayerContextValue | null>(null)

export const useSpeakerVoiceSamplePlayer = () => {
    const speakerVoiceSamplePlayer = useContext(SpeakerVoiceSamplePlayerContext)

    if (!speakerVoiceSamplePlayer) {
        throw new Error('You have forgot to use SpeakerVoiceSamplePlayerContext, shame on you.')
    }

    return speakerVoiceSamplePlayer
}

export const useToggleSpeakerVoiceSample = () => {
    const speakerVoiceSamplePlayer = useSpeakerVoiceSamplePlayer()
    const taskAudio = useSessionMedia(['pause'])

    return useCallback(
        (speakerId: string, sampleId: string, isPlaying: boolean, e: React.MouseEvent) => {
            e.stopPropagation()
            taskAudio.pause()
            isPlaying
                ? speakerVoiceSamplePlayer.pause()
                : speakerVoiceSamplePlayer.playVoiceSample(speakerId, sampleId)
        },
        [speakerVoiceSamplePlayer, taskAudio],
    )
}
