import { VerbitLogo } from '@verbit-ai/icons-library'

type LogoProps = {
    theme?: 'light' | 'dark'
    size?: number
}

export const getOrganizationIcon = () => (props: LogoProps) =>
(
    <VerbitLogo
        theme="light"
        size={36}
        {...props}
    />
)