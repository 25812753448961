import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

export const Title = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    color: #10264a;
`

export const Table = styled.div`
    display: flex;
    max-width: 1200px;
    min-height: 280px;
`

const Column = styled.div`
    flex: 1;
    padding-bottom: 12px;
    overflow: hidden;
`

const HeaderRow = styled.div`
    display: flex;
    align-items: center;
    padding: 0 20px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 700;
    color: ${palette('navy', 6)};

    @media (max-width: 1440px) {
        font-size: 13px;
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;
    }
`

export const TableRow = styled.div`
    display: flex;
    align-items: center;
    min-height: 18px;
    margin-top: 18px;
    padding: 0 20px;
    font-size: 14px;
    color: ${palette('navy', 9)};

    > svg {
        width: 18px;
        height: 18px;
        margin-right: 12px;
    }
`

interface GridColumnProps {
    title?: ReactNode
    children: ReactNode
}

export const TableColumn = ({ title, children }: GridColumnProps) => (
    <Column>
        {title && <HeaderRow>{title}</HeaderRow>}
        {children}
    </Column>
)

export const TranscriptStepWrapper = styled.div`
    border-bottom: 1px solid ${palette('cloudBlueLight', 6)};
    border-right: 1px solid ${palette('cloudBlueLight', 6)};
    border-left: 1px solid ${palette('cloudBlueLight', 6)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`

export const EditorWrapper = styled.div<{ isSpeakerBoxVisible: boolean }>`
    width: 100%;
    padding: 40px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow-y: scroll;
    color: #222222;
    font-size: 18px;
`
// we need to pull the wrapper a little bit more to the left than we need to extend the width because
// we want the text to be aligned and the blocks introduce a bit of padding for non-readable blocks.
