import { ZERO_WIDTH_WHITESPACE } from 'src/utils/string'
import {
    Editor,
    Span,
    Path,
    Point,
    Transforms,
    Range,
    Location,
    Node,
    BaseElement,
    Text,
} from 'slate'

import { uuid } from 'src/utils/uuid'
import { GlossaryTerm } from 'src/models'
import { SuggestionTagData, TagData } from 'src/models/tag'
import { DistributiveOmit } from 'src/models/DistributiveOmit'
import { Label } from 'src/models/label'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'

interface AtWithSpanOptions {
    at?: Location | Span
}
interface AtOptions {
    at?: Location
}
interface AtMatchOptions {
    at?: Location | Span
    match?: (node: Node) => boolean
}

interface InsertTagsOptions extends AtWithSpanOptions {
    contents?: string
}

interface TagBase extends BaseElement {
    type: 'tag'
    hasPlaceholder?: boolean
    seq: string
}

interface SelectionTag extends TagBase {
    kind: 'selection'
    tagType: string
}

interface UnclearTag extends SelectionTag {
    tagType: 'unclear'
}

interface GlossaryTag extends SelectionTag {
    tagType: 'glossary'
    glossary: GlossaryTerm
}

interface VoidTag extends TagBase {
    kind: 'void'
}

interface LabelTag extends VoidTag {
    tagType: 'label'
    label: Label
}

export const EMPTY_TAG_PLACEHOLDERS: { [key in TagData['type']]?: string } = {
    unclear: '[UNK]',
}

export type Tag = GlossaryTag | UnclearTag | LabelTag

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Tag = {
    createTag: (
        editor: Editor,
        tagData: DistributiveOmit<Exclude<TagData, SuggestionTagData>, 'seq'>,
    ): Tag | null => {
        const desc = Tag.getTagDescription(editor, tagData.type)
        if (!desc) return null

        const seq = uuid()

        switch (tagData.type) {
            case 'glossary':
                return {
                    type: 'tag',
                    seq,
                    kind: 'selection',
                    tagType: 'glossary',
                    glossary: tagData.glossary,
                    children: [],
                }
            case 'unclear':
                return {
                    type: 'tag',
                    seq,
                    kind: 'selection',
                    tagType: 'unclear',
                    children: [],
                }
            case 'label':
                return {
                    type: 'tag',
                    seq,
                    kind: 'void',
                    tagType: 'label',
                    label: tagData.label,
                    children: [],
                }
        }
    },

    // check, if a node is a general tag
    isTag: (node: any): node is Tag => {
        return node.type === 'tag'
    },

    // check, if a node is a tag of kind selection
    isSelectionTag: (node: any): node is SelectionTag => {
        return Tag.isTag(node) && node.kind === 'selection'
    },

    isVoidTag: (node: any): node is Tag => {
        return Tag.isTag(node) && node.kind === 'void'
    },

    isGlossaryTag: (node: any): node is GlossaryTag => {
        return Tag.isTag(node) && node.tagType === 'glossary'
    },

    isLabelTag: (node: any): node is LabelTag => {
        return Tag.isTag(node) && node.kind === 'void' && node.tagType === 'label'
    },

    // check, if a selection node has a placeholder
    isPlaceholderTag: (node: any) => {
        return Tag.isSelectionTag(node) && !!node.hasPlaceholder
    },

    // check, if a certain tag type provides a placeholder
    isPlaceholderAvailable: (editor: Editor, tagType: string) => {
        const tag = editor.availableTags.find((t) => t.tagType === tagType)
        if (!tag) return false

        return tag.kind === 'selection' && !!tag.placeholderText
    },

    // get a tag description object if available
    getTagDescription: (editor: Editor, tagType: string) => {
        const tag = editor.availableTags.find((t) => t.tagType === tagType)
        if (!tag) return null

        return tag
    },

    // get the placeholder contents for a specific tag type
    getPlaceholderText: (editor: Editor, tagType: string) => {
        if (!Tag.isPlaceholderAvailable(editor, tagType)) return ''

        // we know this works because Tag.hasPlaceholder verified this
        const tag = editor.availableTags.find((t) => t.tagType === tagType)!
        return (tag.kind === 'selection' && tag.placeholderText) || ''
    },

    // check, if a slate location contains a tag with a placeholder
    hasPlaceholder: (editor: Editor, options: AtWithSpanOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return false

        const [node] = Editor.nodes(editor, { at, match: Tag.isPlaceholderTag })
        return !!node
    },

    hasTag: (editor: Editor, options: AtWithSpanOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return false

        const [node] = Editor.nodes(editor, { at, match: (n) => Tag.isTag(n) })
        return !!node
    },

    // check, if a slate location contains a tag of kind selection
    hasSelectionTag: (editor: Editor, options: AtWithSpanOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return false

        const [node] = Editor.nodes(editor, { at, match: (n) => Tag.isSelectionTag(n) })
        return !!node
    },

    hasVoidTag: (editor: Editor, options: AtWithSpanOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return false

        const [node] = Editor.nodes(editor, { at, match: (n) => Tag.isVoidTag(n) })
        return !!node
    },

    // check, if a tag is empty (not accounting for placeholders)
    isEmpty: (editor: Editor, tagPath: Path) => {
        const contents = Editor.string(editor, tagPath)
        const entry = Tag.getTag(editor, { at: tagPath })

        if (!entry) {
            return true
        }

        const [tag] = entry

        return (
            contents === ZERO_WIDTH_WHITESPACE.repeat(2) ||
            (Tag.isTag(tag) &&
                (contents === EMPTY_TAG_PLACEHOLDERS[tag.tagType] ||
                    contents ===
                        `${ZERO_WIDTH_WHITESPACE}${
                            EMPTY_TAG_PLACEHOLDERS[tag.tagType]
                        }${ZERO_WIDTH_WHITESPACE}`))
        )
    },

    // insert a tag based on its blueprint
    insertTag: (
        editor: Editor,
        tagData: DistributiveOmit<Exclude<TagData, SuggestionTagData>, 'seq'>,
        options: InsertTagsOptions = {},
    ) => {
        let { at = editor.selection, contents } = options
        if (!at) return

        // do not nest tags
        if (Tag.hasTag(editor, { at })) {
            return
        }

        const tagDesc = Tag.getTagDescription(editor, tagData.type)
        if (!tagDesc) {
            return
        }

        if (Path.isPath(at)) {
            at = {
                anchor: Editor.start(editor, at),
                focus: Editor.end(editor, at),
            }
        } else if (Point.isPoint(at)) {
            at = {
                anchor: at,
                focus: at,
            }
        } else if (Span.isSpan(at)) {
            const [start, end] = at
            at = {
                anchor: Editor.start(editor, start),
                focus: Editor.end(editor, end),
            }
        }

        const newNode = Tag.createTag(editor, tagData)
        if (!newNode) {
            return
        }

        if (Range.isCollapsed(at) || newNode.kind === 'void') {
            const editable = Block.isEditableBlockInLocation(editor, at)
            newNode.children = [{ type: 'text', text: contents ?? '', editable }]
            Transforms.insertNodes(editor, newNode, { at })
        } else {
            Transforms.wrapNodes(editor, newNode, { at, split: true })

            const [[, tagPath]] = Editor.nodes(editor, {
                at: [],
                match: (n) => Tag.isTag(n) && n.seq === newNode.seq,
            })
            const tagRange = {
                anchor: Editor.start(editor, tagPath),
                focus: Editor.end(editor, tagPath),
            }

            if (contents) {
                Transforms.insertText(editor, contents, { at: tagRange })
            }
        }

        // make sure the tag is selected correctly, to avoid all kinds of weird selection behavior
        const [[, tagPath]] = Editor.nodes(editor, {
            at: [],
            match: (n) => Tag.isTag(n) && n.seq === newNode.seq,
        })
        const newTagEndPoint = Tag.getEndPoint(editor, tagPath)
        newTagEndPoint && Transforms.select(editor, newTagEndPoint)

        return newNode.seq
    },

    unwrapTag: (editor: Editor, options: AtOptions = {}) => {
        let { at = editor.selection } = options
        if (!at) return null

        if (Path.isPath(at)) {
            at = {
                anchor: Editor.start(editor, at),
                focus: Editor.end(editor, at),
            }
        } else if (Point.isPoint(at)) {
            at = {
                anchor: at,
                focus: at,
            }
        }

        const ref = Editor.rangeRef(editor, at)
        if (!ref.current) return null

        if (Tag.hasVoidTag(editor, { at: ref.current })) {
            Transforms.removeNodes(editor, { at: ref.current, match: Tag.isVoidTag })
        }

        if (Tag.hasTag(editor, { at: ref.current })) {
            Transforms.unwrapNodes(editor, { at, match: Tag.isTag })
        }
        ref.unref()
    },

    // query a possible previous tag based on a location
    // Note: we need to go by text nodes otherwise this helper function is unreliable
    // This function is designed to only find a previous tag, when the tag is a direct sibling to the location we're looking at.
    getPreviousTag: (editor: Editor, options: AtOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return null

        const [textMatch] = Editor.nodes(editor, { at, match: Text.isText })
        if (!textMatch) return null // this can happen for void nodes as Editor.nodes() stops at the void node thus not finding a text node.

        const [blockMatch] = Editor.nodes(editor, { at: textMatch[1], match: Block.isBlock })

        const prevText = Editor.previous(editor, { at: textMatch[1], match: Text.isText })
        if (!prevText) return null

        // only find the previous tag, when it is within the current block
        const [prevBlockMatch] = Editor.nodes(editor, { at: prevText[1], match: Block.isBlock })
        if (!Path.equals(blockMatch[1], prevBlockMatch[1])) {
            return null
        }

        const [prev] = Editor.nodes(editor, { at: prevText[1], match: Tag.isTag })

        if (!prev) {
            return null
        }
        return prev
    },

    // query the possible next tag based on a location
    // Note: we need to go by text nodes otherwise this helper function is unreliable
    // This function is designed to only find a next tag, when the tag is a direct sibling to the location we're looking at.
    getNextTag: (editor: Editor, options: AtOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return null

        const [textMatch] = Editor.nodes(editor, { at, match: Text.isText, reverse: true })
        if (!textMatch) return null // this can happen for void nodes as Editor.nodes() stops at the void node thus not finding a text node.

        const [blockMatch] = Editor.nodes(editor, { at: textMatch[1], match: Block.isBlock })

        const nextText = Editor.next(editor, { at: textMatch[1], match: Text.isText })
        if (!nextText) return null

        // only find the next tag, when it is within the current block
        const [nextBlockMatch] = Editor.nodes(editor, { at: nextText[1], match: Block.isBlock })
        if (!Path.equals(blockMatch[1], nextBlockMatch[1])) {
            return null
        }

        const [next] = Editor.nodes(editor, { at: nextText[1], match: Tag.isTag })

        if (!next) {
            return null
        }
        return next
    },

    getTag: (editor: Editor, options: AtWithSpanOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return null

        const [nodeMatch] = Editor.nodes(editor, { at, match: Tag.isTag })
        return nodeMatch
    },

    // get the start point of a tag (which is offset 1 because of the zero-width whitespace)
    getStartPoint: (editor: Editor, tagPath: Path) => {
        const [[tag]] = Editor.nodes(editor, { at: tagPath, match: Tag.isTag })

        const point = Editor.start(editor, tagPath)
        if (Tag.isVoidTag(tag)) {
            return point
        }
        return Editor.after(editor, point)
    },

    // get the end point of a tag (which is the 2nd last offset because of the zero-width whitespace)
    getEndPoint: (editor: Editor, tagPath: Path) => {
        const [[tag]] = Editor.nodes(editor, { at: tagPath, match: Tag.isTag })

        const point = Editor.end(editor, tagPath)
        if (Tag.isVoidTag(tag)) {
            return point
        }
        return Editor.before(editor, point)
    },

    getTagRange: (editor: Editor, tagPath: Path) => {
        const anchor = Tag.getStartPoint(editor, tagPath)
        const focus = Tag.getEndPoint(editor, tagPath)

        if (anchor && focus) {
            return { anchor, focus }
        }
        return null
    },

    // check if a location is at the start of a tag (which is offset 1 because of the zero-width whitespace)
    isAtStartOfTag: (editor: Editor, options: AtMatchOptions = {}) => {
        const { at = editor.selection, match = Tag.isTag } = options
        if (!at) return false

        const [tagMatch] = Editor.nodes(editor, { at, match })
        if (!tagMatch) return false

        const [, tagPath] = tagMatch

        let point = null
        if (Point.isPoint(at)) {
            point = at
        } else if (Path.isPath(at)) {
            point = Tag.getStartPoint(editor, at)
        } else if (Range.isRange(at)) {
            point = at.anchor
        }

        const startPoint = Tag.getStartPoint(editor, tagPath)
        if (!point || !startPoint) return false

        return Point.equals(point, startPoint)
    },

    // check if a location is at the end of a tag (which is the 2nd last offset because of the zero-width whitespace)
    isAtEndOfTag: (editor: Editor, options: AtMatchOptions = {}) => {
        const { at = editor.selection, match = Tag.isTag } = options
        if (!at) return false

        const [tagMatch] = Editor.nodes(editor, { at, match })
        if (!tagMatch) return false

        const [, tagPath] = tagMatch

        let point = null
        if (Point.isPoint(at)) {
            point = at
        } else if (Path.isPath(at)) {
            point = Tag.getEndPoint(editor, at)
        } else if (Range.isRange(at)) {
            point = at.focus
        }
        const endPoint = Tag.getEndPoint(editor, tagPath)

        if (!point || !endPoint) return false

        return Point.equals(point, endPoint)
    },
}
