import { useSessionStatus } from 'src/state/SessionStatusProvider'
import styled, { keyframes } from 'styled-components/macro'
import { palette } from 'styled-tools'

const blink = keyframes`
  50% { color: transparent; }
`

const LoaderContainer = styled.div`
    display: flex;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
    color: ${palette('blue', 0)};
    margin: 0 8px;

    .loader__dot {
        animation: 1s ${blink} infinite;
    }

    .loader__dot:nth-child(2) {
        animation-delay: 250ms;
    }

    .loader__dot:nth-child(3) {
        animation-delay: 500ms;
    }
`

export function Loader() {
    const { sessionStatus } = useSessionStatus()
    const status = sessionStatus?.playbackState && sessionStatus?.playbackState.toLowerCase()
    const isLoaderVisible = status !== 'playing' ? false : true

    if (!isLoaderVisible) {
        return null
    }

    return (
        <LoaderContainer>
            Processing
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
            <span className="loader__dot">.</span>
        </LoaderContainer>
    )
}
