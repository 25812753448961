import styled from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

export const Container = styled.div<{
    editable: boolean
    sectionsVisible: boolean
    hasExplicitBreak: boolean
}>`
    display: flex;
    flex-direction: column;
    position: relative;
    outline: none;
    margin: ${ifProp(['hasExplicitBreak', 'editable'], 6, 4)}px 0 2px 0;
    user-select: ${ifProp('editable', 'initial', 'none')};
    align-items: flex-start;
    justify-content: flex-start;
    transition: margin-top 125ms ease-in;
`

export const SectionContainer = styled.div`
    display: block;
    position: relative;
    width: 100%;
    outline: none;
    user-select: none;
`

export const BlockContainer = styled.div`
    display: flex;
    position: relative;
    outline: none;
    align-items: flex-start;
    justify-content: flex-start;
`

export const PopOverContainer = styled.div`
    user-select: none;
    bottom: 0;
    left: 7px;
    position: absolute;
`

export const PopOverHeader = styled.h6`
    font-size: 14px;
    line-height: 16px;
    color: ${palette('navy', 11)}
    font-weight: 600;
    margin: 0 0 10px;
    padding: 0;
`

export const PopoverText = styled.div`
    text-align: start;
`

export const PopOverAction = styled.div`
    text-decoration: underline;
    text-align: start;
    cursor: pointer;
    margin-top: 20px;
    color: #5c7199;
`

export const SpeakerChangeReminderContainer = styled.div`
    text-align: center;
`

export const VisibilityContainer = styled.div`
    width: 100%;
`
