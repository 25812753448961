import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { InputGroup, Spinner, MenuItem, Button } from '@blueprintjs/core'
import { Select, ItemRenderer } from '@blueprintjs/select'
import styled, { keyframes } from 'styled-components/macro'
import { darken } from 'polished'

import { TranscriptionLayerId } from 'src/models'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'

const submitButtonBackgroundColor = 'rgb(21, 206, 150)'

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(75px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const CenterBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 340px;
    height: 320px;
    padding: 30px 60px;
    background-color: rgba(145, 210, 183, 0.1);
    border: 1px solid rgba(111, 199, 172, 0.1);
    border-radius: 12px;
    opacity: 0;
    animation: ${fadeIn} 750ms 180ms ease-in-out forwards;
`

const Heading = styled.h1`
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 800;
`

const LoadingContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const LoadingText = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 28px;
`

const StyledInputGroup = styled(InputGroup)`
    margin-bottom: 5px;
`

const RoleSelectButton = styled(Button)`
    width: 100%;
    justify-content: space-between;
`

const SubmitButton = styled(Button)`
    &.bp4-intent-primary {
        background-color: ${submitButtonBackgroundColor};
    }
    &.bp4-intent-primary:hover,
    &.bp4-intent-primary:active {
        background-color: ${darken(0.08, submitButtonBackgroundColor)};
    }

    margin-top: 10px;
`

const SubmitButtonText = styled.span`
    font-weight: 600;
`

export type RoleType = TranscriptionLayerId | 'all'

const RoleSelect = Select.ofType<RoleType>()

export const EnrollWorker = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const isLiveMode = urlParams.has('live')
    const [role, setRole] = useState<RoleType | undefined>(() => {
        const queryLayerIds = urlParams.getAll('task_types')
        if (
            queryLayerIds.includes('edit') &&
            queryLayerIds.includes('annotate') &&
            queryLayerIds.includes('review')
        ) {
            return 'all'
        } else if (queryLayerIds.includes('edit')) {
            return 'edit'
        } else if (queryLayerIds.includes('annotate')) {
            return 'annotate'
        } else if (queryLayerIds.includes('review')) {
            return 'review'
        } else {
            return undefined
        }
    })
    const [name, setName] = useState(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const queryName = urlParams.get('name')
        return queryName ?? ''
    })

    const [shouldShakeName, setShouldShakeName] = useState(false)
    const [shouldShakeRole, setShouldShakeRole] = useState(false)
    const [, sendAction] = useAppMachine([])

    useEffect(() => {
        if (isLiveMode) {
            sendAction({
                type: 'JOIN_WITH_BACKDOOR',
                workerName: 'Jhon Doe',
                layerIds: ['customer_content_manager'],
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = useCallback(
        (workerName: string, role: RoleType) => {
            const layerIds: TranscriptionLayerId[] =
                role === 'all' ? ['edit', 'annotate', 'review'] : [role]
            sendAction({ type: 'JOIN_WITH_BACKDOOR', workerName, layerIds })
        },
        [sendAction],
    )

    const handleButtonPress = async () => {
        if (name === '') {
            setShouldShakeName(true)
            setTimeout(() => {
                setShouldShakeName(false)
            }, 700)
            return
        }
        if (!role) {
            setShouldShakeRole(true)
            setTimeout(() => {
                setShouldShakeRole(false)
            }, 700)
            return
        }

        onSubmit(name, role)
    }

    return (
        <Container>
            <CenterBox>
                <Heading>
                    Welcome!{' '}
                    <span role="img" aria-label="man">
                        🙋‍♂️
                    </span>
                </Heading>
                <StyledInputGroup
                    className={shouldShakeName ? 'shakeX' : ''}
                    value={name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    large={true}
                    leftIcon="user"
                    placeholder="What's your name?"
                    width="250px"
                />
                <RoleSelect
                    className={`role-select ${shouldShakeRole ? 'shakeX' : ''}`}
                    items={['edit', 'annotate', 'review', 'all']}
                    onItemSelect={(role) => setRole(role)}
                    itemRenderer={renderRole}
                    filterable={false}
                    popoverProps={{ minimal: true, usePortal: false }}
                >
                    <RoleSelectButton
                        icon="hat"
                        rightIcon="caret-down"
                        text={role ? getRoleTitle(role) : 'Select your role'}
                    />
                </RoleSelect>
                <SubmitButton large={true} onClick={handleButtonPress} intent="primary">
                    <SubmitButtonText>Let's Go!</SubmitButtonText>
                </SubmitButton>
            </CenterBox>
        </Container>
    )
}

export function EnrollLoader() {
    return (
        <Container>
            <CenterBox>
                <LoadingContainer>
                    <Spinner size={70} />
                    <LoadingText>Getting things ready...</LoadingText>
                </LoadingContainer>
            </CenterBox>
        </Container>
    )
}

const getRoleTitle = (role: RoleType) => {
    switch (role) {
        case 'edit':
            return 'Corrector'
        case 'annotate':
            return 'Annotator'
        case 'review':
            return 'Reviewer'
        case 'all':
            return 'Batman (all roles!)'
    }
}
const renderRole: ItemRenderer<RoleType> = (role, { handleClick, modifiers }) => {
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={role}
            onClick={handleClick}
            text={getRoleTitle(role)}
        />
    )
}
