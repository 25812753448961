import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import Hls from 'hls.js'
import { noop } from 'lodash/fp'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'

interface AudioSourceOptions {
    maxBufferLength?: number
    maxMaxBufferLength?: number
    label?: string
}

export type HlsConnect = (hlsSrc: string, audio: HTMLAudioElement, startPosition: number) => void

export const useMediaSource = (
    mediaRef: RefObject<HTMLAudioElement>,
    src: string | null,
    options: AudioSourceOptions = {},
) => {
    const [isHlsConnected, setIsHlsConnected] = useState(false)
    const isHlsConnecting = useRef(false)
    const clearHlsRef = useRef(noop)
    const isHLS = src?.endsWith('.m3u8')
    const { maxBufferLength, maxMaxBufferLength, label } = options

    const loadHls = useCallback<HlsConnect>(
        (hlsSrc, audio, startPosition) => {
            if (isHlsConnected) {
                clearHlsRef.current()
            }

            isHlsConnecting.current = true

            console.info(
                `[${label}] Creating Hls instance and attaching it to audio element with options ${JSON.stringify(
                    { maxBufferLength },
                )}`,
            )

            const opts: Partial<Hls.Config> = {
                startPosition,
                // try to be more lenient with retry counts and have shorter retry delays
                fragLoadingRetryDelay: 500,
                manifestLoadingRetryDelay: 500,
                levelLoadingRetryDelay: 500,
                // @ts-ignore: @types/hls.js is not yet synced with hls.js itself, so this property is missing from typings
                fragLoadingMaxRetryTimeout: 8000,
                manifestLoadingMaxRetryTimeout: 8000,
                levelLoadingMaxRetryTimeout: 8000,
                fragLoadingMaxRetry: 32,
                manifestLoadingMaxRetry: 32,
                levelLoadingMaxRetry: 32,
                xhrSetup: (xhr) => {
                    xhr.withCredentials = true // Enable cookies for HLS authentication (using CloudFront signed cookies)
                },
            }

            if (maxBufferLength) {
                opts.maxBufferLength = maxBufferLength
            }

            if (maxMaxBufferLength) {
                opts.maxMaxBufferLength = maxMaxBufferLength
            }

            const hls = new Hls(opts)

            clearHlsRef.current = () => {
                hls.off(Hls.Events.MANIFEST_LOADED, onHLSManifestLoaded)
                hls.off(Hls.Events.ERROR, onHLSError)
                hls.detachMedia()
                hls.destroy()
                setIsHlsConnected(false)
            }

            const onHLSManifestLoaded = () => {
                isHlsConnecting.current = false
                setIsHlsConnected(true)
            }

            const onHLSError = (event: 'hlsError', data: Hls.errorData) => {
                isHlsConnecting.current = false

                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // try to recover network error
                            console.log(
                                'hls.js: fatal network error encountered, try to recover. Error details: ',
                                data.details,
                            )
                            hls.startLoad()
                            break
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.log(
                                'hls.js: fatal media error encountered, try to recover. Error details: ',
                                data.details,
                            )
                            hls.recoverMediaError()
                            break
                        default:
                            // cannot recover, destroy hls instance
                            console.error(
                                'hls.js: Cannot recover from error, destroying hls instance. Error details: ',
                                data.details,
                            )
                            clearHlsRef.current()
                            break
                    }
                }
            }

            hls.on(Hls.Events.MANIFEST_LOADED, onHLSManifestLoaded)
            hls.on(Hls.Events.ERROR, onHLSError)
            hls.loadSource(hlsSrc)
            hls.attachMedia(audio)
        },
        [isHlsConnected, label, maxBufferLength, maxMaxBufferLength],
    )

    const connect = useCallback(
        (startTime: number) => {
            if (src && mediaRef.current && !isHlsConnecting.current) {
                if (isHLS) {
                    loadHls(src, mediaRef.current, startTime)
                } else {
                    mediaRef.current.load()
                }
            }
        },
        [src, mediaRef, isHLS, loadHls],
    )

    const disconnect = useCallback(() => {
        clearHlsRef.current()
    }, [])

    useEffect(() => {
        const audio = mediaRef.current

        if (!audio || !src) return

        if (isHLS) {
            if (!Hls.isSupported() && audio.canPlayType('application/vnd.apple.mpegurl')) {
                audio.src = src
            }
        } else {
            audio.src = src
        }
    }, [isHLS, mediaRef, src, maxBufferLength])

    useWillUnmount(() => {
        if (isHlsConnected) {
            clearHlsRef.current()
        }
    })

    return {
        isConnected: isHLS ? isHlsConnected : true,
        connect: isHLS ? connect : noop,
        disconnect: isHLS ? disconnect : noop,
    }
}
