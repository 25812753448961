import { useCallback } from 'react'
import styled from 'styled-components/macro'
import { Range } from 'slate'
import { useSlateStatic } from 'slate-react'

import { useAnalytics } from 'src/analytics'
import * as Icons from 'src/components/icons'
import { useEditorMeta } from 'src/components/Editor/EditorContext'
import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TranscriptionTask as TranscriptionTaskType } from 'src/models'
import { KeyStringCodes } from 'src/utils/keyboard'
import { Block } from '../withTranscript'
import isHotkey from 'is-hotkey'
import { throttle } from 'lodash'
import { useToast } from 'src/components/Toasts/ToastContext'
import { Intent } from '@blueprintjs/core'

const ToastContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ToastText = styled.span`
    margin-left: 8px;
`

export function useSlateGlossersEditorProps() {
    const editor = useSlateStatic()
    const addToast = useToast()
    const analytics = useAnalytics()

    const task = useTask<TranscriptionTaskType>()
    const { editorMode } = useEditorMeta()
    const isEditorInGlossersMode = editorMode === 'glossers'
    const { type: taskType, id: taskId } = task

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const displayGlossersReminderToast = useCallback(
        throttle(() => {
            addToast({
                intent: Intent.NONE,
                message: (
                    <ToastContainer>
                        <Icons.AttentionIcon />
                        <ToastText>
                            Don’t worry about correcting the text, we got that part covered.
                        </ToastText>
                    </ToastContainer>
                ),
            })
        }, 500),
        [addToast],
    )

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (!isEditorInGlossersMode) {
                return
            }
            const exceptionList = [
                KeyStringCodes.ArrowLeft,
                KeyStringCodes.ArrowRight,
                KeyStringCodes.ArrowUp,
                KeyStringCodes.ArrowDown,
                KeyStringCodes.Escape,
                KeyStringCodes.CapsLock,
                KeyStringCodes.ShiftLeft,
                KeyStringCodes.ControlLeft,
                KeyStringCodes.ControlRight,
                KeyStringCodes.AltLeft,
                KeyStringCodes.Tab,
                KeyStringCodes.MetaLeft,
                KeyStringCodes.MetaRight,
                KeyStringCodes.AltRight,
                KeyStringCodes.ShiftRight,
            ]

            if (editor.selection) {
                const allowDeleteBackward =
                    e.code === KeyStringCodes.Backspace &&
                    Block.isSelectionAtTheBeginningOfBlock(editor)
                const allowDeleteForward =
                    e.code === KeyStringCodes.Delete && Block.isSelectionAtTheEndOfBlock(editor)
                const allowBreakingBlock =
                    (e.code === KeyStringCodes.Enter || e.code === KeyStringCodes.NumpadEnter) &&
                    Range.isCollapsed(editor.selection)
                const isPressedKeyInExceptionList = exceptionList.some((key) => e.code === key)
                const shouldIgnoreKeyDown =
                    !isPressedKeyInExceptionList &&
                    !allowDeleteBackward &&
                    !allowDeleteForward &&
                    !allowBreakingBlock &&
                    !isHotkey('mod+z', { byKey: true }, e) &&
                    !isHotkey('mod+g', { byKey: true }, e) &&
                    !isHotkey('mod+shift+z', { byKey: true }, e) &&
                    !isHotkey('mod+a', { byKey: true }, e) &&
                    !isHotkey('mod+c', { byKey: true }, e) &&
                    !isHotkey('mod+d', { byKey: true }, e)
                if (shouldIgnoreKeyDown) {
                    e.preventDefault()
                    e.stopPropagation()
                    displayGlossersReminderToast()
                    analytics?.sendTypingAttemptOccurInGlossersMode(taskId, taskType, e.code)
                }
            }
        },
        [analytics, displayGlossersReminderToast, editor, isEditorInGlossersMode, taskId, taskType],
    )

    return {
        onKeyDown,
    }
}
