import { ReactNode } from 'react'
import { Node } from 'slate'
import { RenderElementProps, useSelected } from 'slate-react'
import styled, { css, useTheme } from 'styled-components/macro'
import { ifProp, palette, prop } from 'styled-tools'

import { AsteriskIcon, InaudibleIcon } from 'src/components/icons'

import { Text as ContentTextSpan } from '../withTranscript/components/ContentTextView'
import { Tag } from './Tag'
import { ZERO_WIDTH_WHITESPACE_REGEX } from 'src/utils/string'

type TagIconProps = {
    selected: boolean
}

const StyledAsteriskIcon = styled(AsteriskIcon)<TagIconProps>`
    opacity: ${ifProp('selected', 1, 0.75)};
`
const StyledInaudibleIcon = styled(InaudibleIcon)<TagIconProps>`
    opacity: ${ifProp('selected', 1, 0.75)};
`

const RelativeWrapper = styled.div`
    position: relative;
    display: inline-block;
    user-select: none;
`

const StartVerticalBorder = styled.span`
    display: none;
    position: absolute;
    width: 2px;
    height: 4px;

    border-radius: 2px;
    background-color: rgb(99, 120, 152);
    bottom: -7px;
    left: -2px;
`
const EndVerticalBorder = styled.span`
    display: none;
    position: absolute;
    width: 2px;
    height: 4px;

    border-radius: 2px;
    background-color: rgb(99, 120, 152);
    bottom: -7px;
    right: -2px;
`

const TagSpan = styled.span<{ selected: boolean; color: string; hasIcon: boolean }>`
    padding: 0 1px;
    -webkit-box-decoration-break: clone;
    margin-top: 2px;
    margin-bottom: 2px;
    vertical-align: baseline;

    z-index: 1;

    & svg > path {
        /* stroke: ${prop('color')}; */
        fill: ${prop('color')};
    }

    & ${ContentTextSpan} {
        color: ${prop('color')} !important;
    }

    ${ifProp(
        'selected',
        css`
            background: ${palette('cloudBlueLight', 3)};
            border-bottom: 2px solid ${prop('color')};

            & ${StartVerticalBorder} {
                display: initial;
            }

            & ${EndVerticalBorder}  {
                display: initial;
            }
        `,
    )}
`

const IconWrapper = styled.span`
    width: 10px;
    & > svg {
        position: absolute;
        top: -1.4em;
        left: -7px;
    }
    z-index: 0;
`

const VoidTag = styled.span<{ selected: boolean }>`
    padding: 0 3px;
    white-space: nowrap;
    vertical-align: baseline;

    z-index: 1;

    background: ${palette('cloudBlueLight', 9)};
    border-radius: 2px;
    border: 1px solid ${palette('cloudBlueLight', 8)};
    ${ifProp('selected', `border: 1px solid #3b72ea;`)}
`

interface SelectionTagProps {
    icon?: ReactNode
    color: string
    attributes: RenderElementProps['attributes']
    children: any
}
function SelectionTagWrapper({ attributes, children, icon, color }: SelectionTagProps) {
    const selected = useSelected()
    return (
        <TagSpan selected={selected} {...attributes} color={color} hasIcon={!!icon}>
            <RelativeWrapper contentEditable={false}>
                {icon ? <IconWrapper>{icon}</IconWrapper> : null}
                <StartVerticalBorder />
            </RelativeWrapper>

            {children}
            <RelativeWrapper contentEditable={false}>
                <EndVerticalBorder />
            </RelativeWrapper>
        </TagSpan>
    )
}

type ElementAttributes = RenderElementProps['attributes']
interface VoidAttributes extends ElementAttributes {
    contentEditable?: true
}
interface VoidTagProps {
    icon?: ReactNode
    attributes: VoidAttributes
    children: any
    voidContents: any
}

const VoidTagContent = styled.span`
    user-select: none;
`
function VoidTagWrapper({ attributes, children, icon, voidContents }: VoidTagProps) {
    const selected = useSelected()

    delete attributes.contentEditable

    return (
        <VoidTag selected={selected} {...attributes}>
            {children}
            <VoidTagContent contentEditable={false}>
                {icon ? <IconWrapper>{icon}</IconWrapper> : null}
                {voidContents}
            </VoidTagContent>
        </VoidTag>
    )
}

interface TagViewProps extends RenderElementProps {
    element: Tag
}

export function TagView({ element, attributes, children }: TagViewProps) {
    const theme = useTheme()
    const selected = useSelected()

    switch (element.tagType) {
        case 'unclear':
            return (
                <SelectionTagWrapper
                    color={theme.palette.purple[0]}
                    icon={
                        <StyledInaudibleIcon
                            selected={selected}
                            width="12"
                            height="12"
                            color={theme.palette.purple[0]}
                        />
                    }
                    attributes={attributes}
                    children={children}
                />
            )
        case 'glossary': {
            const isEdited =
                element.glossary.text !==
                Node.string(element).replace(ZERO_WIDTH_WHITESPACE_REGEX, '')
            return (
                <SelectionTagWrapper
                    color={theme.palette.blue[0]}
                    icon={
                        isEdited && <StyledAsteriskIcon selected={selected} width="9" height="9" />
                    }
                    attributes={attributes}
                    children={children}
                />
            )
        }

        case 'label':
            return (
                <VoidTagWrapper
                    attributes={attributes}
                    children={children}
                    voidContents={element.label.text}
                />
            )

        default: {
            return children
        }
    }
}
