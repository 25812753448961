import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react'
import { Channel, UnreadCounterMap } from '@verbit-ai/chat-frontend/lib/types'
import { chatClient, useChatChannels, useMessageSentListener } from '@verbit-ai/chat-frontend'

import { TranscriptionLayerId } from '../models'
import { getEnv } from '../utils/env'
import { useAnalytics } from '../analytics'
import { useSessionStatus } from './SessionStatusProvider'
import { useAppMachine } from './state-machines/AppMachine/AppMachineProvider'

interface ChatContextValue {
    channels: { [channelId: string]: Channel }
    channelUnreadCounters: UnreadCounterMap
}

const ChatContext = createContext<ChatContextValue>({
    channels: {},
    channelUnreadCounters: {},
})

interface ChatProviderProps {
    children: ReactNode
}

const env = getEnv()
export const ChatProvider = ({ children }: ChatProviderProps) => {
    const { sessionStatus: status } = useSessionStatus(['sessionStatus.sessionId'])
    const [{ context: appContext }] = useAppMachine([
        'workerId',
        'workerName',
        'workerEmail',
        'layerIds',
        'httpClient',
    ])
    const { workerName, workerEmail, layerIds, httpClient } = appContext
    const analytics = useAnalytics()
    const { fetchAndJoinChannels, channels, channelUnreadCounters } = useChatChannels()

    const joinSessionChannelChat = useCallback(
        async (
            channelId: string,
            name: string,
            email: string,
            layerIds: TranscriptionLayerId[],
        ) => {
            try {
                const { client_user_token, jwt } = await httpClient.authenticateChatClientUser()
                const chatClientUserId = jwt.id.toString()
                const chatClientUser = {
                    id: chatClientUserId,
                    email,
                    name,
                    layerIds,
                }

                await chatClient.connectUser(chatClientUser, client_user_token)
                await fetchAndJoinChannels([channelId])
            } catch (e) {
                console.log(e)
            }
        },
        [httpClient, fetchAndJoinChannels],
    )

    useEffect(() => {
        if (env === 'dev' && !document.cookie.includes('qa_verbit_universal_token')) {
            return
        }

        if (status?.sessionId && workerName && workerEmail && layerIds) {
            joinSessionChannelChat(status.sessionId, workerName, workerEmail, layerIds)
        }
    }, [joinSessionChannelChat, status?.sessionId, workerName, workerEmail, layerIds])

    useMessageSentListener(channels, (channel, messageEvent) => {
        analytics?.sendMessageSent(channel, messageEvent)
    })

    return (
        <ChatContext.Provider value={{ channels, channelUnreadCounters }}>
            {children}
        </ChatContext.Provider>
    )
}

export const useChat = () => {
    const chatContext = useContext(ChatContext)

    if (!chatContext) {
        throw new Error('You have forgot to use ChatContext, shame on you.')
    }

    return chatContext
}
