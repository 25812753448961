import { BaseEditor, Editor } from 'slate'
import { Timeline } from './timeline'

export interface TimelineEditor extends BaseEditor {
    timeline: Timeline
}

const EDITOR_TO_TIMELINE_ENABLED = new WeakMap<Editor, boolean>()

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimelineEditor = {
    shouldAllowTimelineUpdate(editor: Editor) {
        return EDITOR_TO_TIMELINE_ENABLED.get(editor) ?? true
    },
    withoutTimeline(editor: Editor, fn: () => void) {
        const prev = TimelineEditor.shouldAllowTimelineUpdate(editor)

        EDITOR_TO_TIMELINE_ENABLED.set(editor, false)

        try {
            fn()
        } finally {
            EDITOR_TO_TIMELINE_ENABLED.set(editor, prev)
        }
    },
}
