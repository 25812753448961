import React, { ErrorInfo } from 'react'
import LogRocket from 'logrocket'

interface ErrorBoundaryProps {
    fallback?: React.FC | React.ComponentClass
    children?: React.ReactNode
}

interface ErrorBoundaryState {
    hasError: boolean
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = { hasError: false }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        window.Rollbar?.error(error, errorInfo)
        LogRocket.error(error, errorInfo)
    }

    render() {
        const { fallback: FallbackComponent } = this.props

        // You can render any custom fallback UI
        if (this.state.hasError) {
            if (FallbackComponent) {
                return <FallbackComponent />
            }

            // this is the fallback of the fallback
            // just in case :D
            return (
                <>
                    <h1>Something went wrong.</h1>
                    <p>Please refresh the page or talk to our support agents.</p>
                </>
            )
        }

        return this.props.children
    }
}
