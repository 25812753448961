import { Fragment, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { prop } from 'styled-tools'
import { identity } from 'lodash/fp'

import { TimingRange } from 'src/utils/range'

interface TimeRangeWrapperProps {
    startPercentage: number
    widthPercentage: number
}

const TimeRangeWrapper = styled.div.attrs<TimeRangeWrapperProps>((props) => ({
    style: {
        left: `${props.startPercentage}%`,
        width: `${props.widthPercentage}%`,
    },
}))<TimeRangeWrapperProps>`
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    pointer-events: none;
`

const TimeRange = styled.div<{ color: string }>`
    width: 100%;
    height: 100%;
    background-color: ${prop('color')};
`

interface UnplayedTimeRangesProps {
    start: number
    end: number
    ranges: TimingRange[]
    currentTime: number
    color: string
    colorBeforeCurrentTime: string
    decorateRange: (range: ReactNode, rangeIndex: number) => ReactNode
}

export const UnplayedTimeRanges = ({
    start,
    end,
    ranges,
    currentTime,
    color,
    colorBeforeCurrentTime,
    decorateRange = identity,
}: UnplayedTimeRangesProps) => {
    const duration = end - start

    return (
        <>
            {ranges.map(({ start: rangeStart, end: rangeEnd }, index) => {
                const startPercentage = ((rangeStart - start) / duration) * 100
                const widthPercentage = ((rangeEnd - rangeStart) / duration) * 100
                const currentTimePercentage = (currentTime / duration) * 100

                return (
                    <Fragment key={rangeStart}>
                        <TimeRangeWrapper
                            startPercentage={startPercentage}
                            widthPercentage={widthPercentage}
                        >
                            {decorateRange(<TimeRange color={color} />, index)}
                        </TimeRangeWrapper>

                        {currentTimePercentage > startPercentage && (
                            <TimeRangeWrapper
                                startPercentage={startPercentage}
                                widthPercentage={Math.min(
                                    widthPercentage,
                                    currentTimePercentage - startPercentage,
                                )}
                            >
                                <TimeRange color={colorBeforeCurrentTime} />
                            </TimeRangeWrapper>
                        )}
                    </Fragment>
                )
            })}
        </>
    )
}
