import { Editor, Text, Path, Location, BaseEditor, RangeRef } from 'slate'

import { ContentText } from 'src/components/Editor/plugins/withTranscript'
import {
    ZERO_WIDTH_WHITESPACE,
    ZERO_WIDTH_WHITESPACE_REGEX,
    isPunctuation,
    isSingleWord,
} from 'src/utils/string'
import { AnalyticsClient } from 'src/analytics/client'
import { SessionStatus, Task } from 'src/models'

import { ValidationMessage, Validations } from './index'
import { ValidationRegexObject } from './Validations'
import { Spellchecker } from '../withSpellchecker'
import { Tag } from '../withTags/Tag'
import { SpellcheckerAPI } from '../../../Spellchecker/SpellcheckerAPI'
import {
    isThereSpaceBetweenCurrentAndNextNodes,
    isThereSpaceBetweenCurrentAndPreviousNodes,
} from './utils'
import { Suggestion } from '../withSuggestions/Suggestion'
import { ValidationPopup } from './ValidationPopup'

export type RegexesHashMapObjectType = {
    validationName: string
} & ValidationMessage

interface ValidationRef {
    ref: RangeRef
    beforeString: string
    invalidValidationId: string
}

export interface ValidationEditor extends BaseEditor {
    regexExpressionsMap?: Map<string, RegexesHashMapObjectType>
    firstValidationTriggered: boolean
    textualChangeAppliedToEditor: boolean
    refMap: Map<string, ValidationRef>
}

export type ValidationsAnalyticsMetadata = Partial<SessionStatus> & Partial<Task>

const generateUniqueKeyFromNodePath = (nodePath: Path | null): string | undefined =>
    nodePath?.join('-')

const createHashMapFromRegexesArray = (
    regexExpressions: ValidationRegexObject[],
): Map<string, RegexesHashMapObjectType> => {
    const regexesHashMap = new Map()
    regexExpressions.forEach((regexExpression) => {
        const {
            id,
            validationName,
            validationMessage: { messageTitle, messageSegments },
        } = regexExpression
        regexesHashMap.set(id, {
            validationName,
            messageTitle,
            messageSegments,
        })
    })
    return regexesHashMap
}

export function withValidations(
    regexExpressions: ValidationRegexObject[],
    spellchecker: SpellcheckerAPI,
    analytics: AnalyticsClient | null,
    analyticsMetadata: ValidationsAnalyticsMetadata,
) {
    return (editor: Editor) => {
        const { normalizeNode, insertText, deleteBackward, deleteForward, renderEditor } = editor
        const invalidStringsCache = new Map()
        const lastUnsetNodes = new Map()

        editor.firstValidationTriggered = false
        editor.textualChangeAppliedToEditor = false
        editor.refMap = new Map()

        editor.regexExpressionsMap = createHashMapFromRegexesArray(regexExpressions)
        editor.normalizeNode = (entry) => {
            const [node, path] = entry
            if (Text.isText(node) && node.editable) {
                const previousNodeEntry = Editor.previous(editor, { at: path, match: Text.isText })
                const nextNodeEntry = Editor.next(editor, { at: path, match: Text.isText })
                let previousNode: ContentText | null = null
                let previousNodePath: Location | null = null
                let nextNode: ContentText | null = null
                let nextNodePath: Location | null = null
                const isCurrentNodeZeroWidthSpace =
                    ZERO_WIDTH_WHITESPACE_REGEX.test(node.text) ||
                    node.text === ZERO_WIDTH_WHITESPACE
                const aboveNode = Editor.above(editor, { at: path })
                const currentNodeUniqueKey = generateUniqueKeyFromNodePath(path)

                if (!!previousNodeEntry && previousNodeEntry[0].editable) {
                    previousNode = previousNodeEntry[0] as Text
                    previousNodePath = previousNodeEntry[1]
                }

                if (!!nextNodeEntry && nextNodeEntry[0].editable) {
                    nextNode = nextNodeEntry[0] as Text
                    nextNodePath = nextNodeEntry[1]
                }

                const isCurrentNodeStringExistsInInvalidStringsCache = invalidStringsCache.get(
                    node.text,
                )
                const shouldRemoveInvalidFlagFromCurrentNode =
                    (Validations.isInvalidValidation(node) &&
                        isSingleWord(node.text) &&
                        !spellchecker.isCorrect(node.text) &&
                        !isPunctuation(node.text)) ||
                    (Validations.isInvalidValidation(node) &&
                        (Tag.isTag(aboveNode?.[0]) ||
                            Suggestion.isSuggestion(aboveNode?.[0]) ||
                            !isCurrentNodeStringExistsInInvalidStringsCache ||
                            !isThereSpaceBetweenCurrentAndPreviousNodes(entry, previousNodeEntry) ||
                            !isThereSpaceBetweenCurrentAndNextNodes(entry, nextNodeEntry)))

                if (shouldRemoveInvalidFlagFromCurrentNode) {
                    Validations.removeInvalidFlagFromNode(editor, { at: path })
                    lastUnsetNodes.set(currentNodeUniqueKey, true)
                    return
                }

                const shouldRemoveInvalidFlagFromPreviousNode =
                    !!previousNode &&
                    Validations.isInvalidValidation(previousNode) &&
                    !Spellchecker.isSpellingError(previousNode) &&
                    !isPunctuation(previousNode.text) &&
                    !isThereSpaceBetweenCurrentAndPreviousNodes(entry, previousNodeEntry) &&
                    !isCurrentNodeZeroWidthSpace
                if (shouldRemoveInvalidFlagFromPreviousNode) {
                    Validations.removeInvalidFlagFromNode(editor, {
                        at: previousNodePath as Location,
                    })
                }

                const shouldRemoveInvalidFlagFromNextNode =
                    !!nextNode &&
                    Validations.isInvalidValidation(nextNode) &&
                    !Spellchecker.isSpellingError(nextNode) &&
                    !isPunctuation(nextNode.text) &&
                    nextNode.text[0] !== ' ' &&
                    !isPunctuation(nextNode.text[0]) &&
                    !isThereSpaceBetweenCurrentAndNextNodes(entry, nextNodeEntry) &&
                    !isCurrentNodeZeroWidthSpace
                if (shouldRemoveInvalidFlagFromNextNode) {
                    Validations.removeInvalidFlagFromNode(editor, { at: nextNodePath as Location })
                }

                const shouldCheckNodeValidation =
                    !Validations.isInvalidValidation(node) &&
                    !Spellchecker.isSpellingError(node) &&
                    !Tag.isTag(aboveNode?.[0]) &&
                    !Suggestion.isSuggestion(aboveNode?.[0]) &&
                    ((isSingleWord(node.text) && spellchecker.isCorrect(node.text)) ||
                        (!isSingleWord(node.text) && !lastUnsetNodes.get(currentNodeUniqueKey)))

                if (shouldCheckNodeValidation) {
                    const invalidTerm = Validations.getInvalidTermData(
                        editor,
                        node,
                        regexExpressions,
                    )
                    if (!!invalidTerm) {
                        const {
                            start: offsetStart,
                            end: offsetEnd,
                            invalidValidationId,
                            validationName,
                        } = invalidTerm
                        const invalidTermString = node.text.slice(offsetStart, offsetEnd + 1)

                        /**
                         *  if it's not a single word- the isCorrect() method will return false.
                         * so we want pass the condition in this case
                         * */
                        if (
                            !isSingleWord(invalidTermString) ||
                            isPunctuation(invalidTermString) ||
                            spellchecker.isCorrect(invalidTermString)
                        ) {
                            const isThereSpaceAtTheBeginningOfInvalidTerm =
                                (offsetStart > 0 &&
                                    (node.text[offsetStart - 1] === ' ' ||
                                        !node.text[offsetStart - 1] ||
                                        isPunctuation(node.text[offsetStart - 1]) ||
                                        isPunctuation(node.text[offsetStart]))) ||
                                (offsetStart === 0 &&
                                    (!previousNode ||
                                        (!!previousNodePath &&
                                            !Path.isSibling(path, previousNodePath as Path)) ||
                                        previousNode.text[previousNode.text.length - 1] === ' ' ||
                                        isPunctuation(
                                            previousNode.text[previousNode.text.length - 1],
                                        ) ||
                                        isPunctuation(node.text[0])))
                            const isThereSpaceAtTheEndOfInvalidTerm =
                                (offsetEnd < node.text.length - 1 &&
                                    (node.text[offsetEnd + 1] === ' ' ||
                                        isPunctuation(node.text[offsetEnd + 1]) ||
                                        isPunctuation(node.text[offsetEnd]))) ||
                                (offsetEnd === node.text.length - 1 &&
                                    (!nextNode ||
                                        nextNode.text[0] === ' ' ||
                                        isPunctuation(nextNode.text[0]) ||
                                        (!!nextNodePath &&
                                            !Path.isSibling(path, nextNodePath as Path))))

                            const isTermSeperatedFromOtherTerms =
                                isThereSpaceAtTheBeginningOfInvalidTerm &&
                                isThereSpaceAtTheEndOfInvalidTerm
                            if (isTermSeperatedFromOtherTerms) {
                                const invalidString = node.text.substring(
                                    offsetStart,
                                    offsetEnd + 1,
                                )
                                Validations.setInvalidNode(
                                    editor,
                                    {
                                        at: {
                                            anchor: { path, offset: offsetStart },
                                            focus: { path, offset: offsetEnd + 1 },
                                        },
                                    },
                                    invalidValidationId,
                                )
                                invalidStringsCache.set(invalidString, true)
                                analytics?.sendValidationInvalidTermMarked({
                                    validationId: invalidValidationId,
                                    invalidString,
                                    validationName,
                                    triggerTiming: Validations.isTextualChangeAppliedToEditor(
                                        editor,
                                    )
                                        ? 'during task'
                                        : 'initial task',
                                    ...analyticsMetadata,
                                })

                                return
                            }
                        }
                    }
                }

                if (!!lastUnsetNodes.get(currentNodeUniqueKey)) {
                    lastUnsetNodes.delete(currentNodeUniqueKey)
                }
            }
            normalizeNode(entry)
        }

        editor.insertText = (text) => {
            Validations.setEditorTextualChange(editor, true)
            insertText(text)
        }

        editor.deleteBackward = (unit) => {
            Validations.setEditorTextualChange(editor, true)
            deleteBackward(unit)
        }

        editor.deleteForward = (unit) => {
            Validations.setEditorTextualChange(editor, true)
            deleteForward(unit)
        }

        editor.renderEditor = () => {
            return (
                <>
                    <ValidationPopup />
                    {renderEditor()}
                </>
            )
        }

        return editor
    }
}
