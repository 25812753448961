import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { chakra } from '@chakra-ui/react'
import { IconButton } from '@verbit-ai/verbit-ui-library'
import { PauseFilledIcon, PlayFilledIcon, SkipForwardIcon } from '@verbit-ai/icons-library'

import { useSessionMedia } from 'src/state/SessionMediaProvider'
import { Loader } from './Loader'
import { PlaybackSpeedButton } from './PlaybackSpeedButton'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useExactAudioTimeUpdateListener } from 'src/factories/MediaService'
import { isMac } from 'src/utils/platform'
import { useMousetrap } from 'src/hooks/useMousetrap'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'
import { MediaTrack } from './MediaTrack'
import { VolumeButton } from './VolumeButton'

export const FORWARD_BACKWARD_JUMP_IN_SECONDS = 2
export const PLAYBACK_SPEEDS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 4]

const Container = styled.div`
    display: flex;
    background-color: ${palette('white', 0)};
    height: 46px;
    z-index: ${theme('zIndexes.audioPlayer')};
    border-top: solid 1px ${palette('cloudBlueLight', 6)};
    // width: 100%;
`

const ControlsSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
`

const StyledIconButton = chakra(IconButton, (props: { isHighlight: boolean }) => ({
    baseStyle: {
        mx: 1,
        borderRadius: '50%',
        background: props.isHighlight ? '#f4f6fe' : 'transparent',
    },
}))

export function LiveMediaPlayer() {
    const { mediaRef, isMediaPlaying, pause, seekTime, togglePlayPause, currentTime, duration } =
        useSessionMedia([
            'mediaRef',
            'isMediaPlaying',
            'pause',
            'seekTime',
            'togglePlayPause',
            'currentTime',
            'duration',
        ])

    const [playbackSpeed, setPlaybackSpeed] = useState(1)
    const initialVolume = mediaRef.current?.volume === 0 ? 0 : mediaRef.current?.volume || 1
    const [volumeValue, setVolumeValue] = useState(initialVolume)

    const [exactCurrentTime, setExactCurrentTime] = useState(currentTime)
    useExactAudioTimeUpdateListener(mediaRef, isMediaPlaying, currentTime, setExactCurrentTime)

    const updateVolume = useCallback(
        (volume: number) => {
            const valueToSet = Number(volume.toFixed(1))
            setVolumeValue(valueToSet)

            if (mediaRef.current && valueToSet <= 1) {
                mediaRef.current.volume = valueToSet
            }
        },
        [mediaRef],
    )

    const seekBackward = useCallback(() => {
        seekTime(Math.max(0, exactCurrentTime - FORWARD_BACKWARD_JUMP_IN_SECONDS))
    }, [exactCurrentTime, seekTime])
    const seekForward = useCallback(() => {
        seekTime(exactCurrentTime + FORWARD_BACKWARD_JUMP_IN_SECONDS)
    }, [exactCurrentTime, seekTime])

    const increasePlaybackSpeed = useCallback(() => {
        const currentSpeedIndex = PLAYBACK_SPEEDS.indexOf(playbackSpeed)
        const nextSpeedIndex = Math.min(currentSpeedIndex + 1, PLAYBACK_SPEEDS.length - 1)
        setPlaybackSpeed(PLAYBACK_SPEEDS[nextSpeedIndex])
    }, [playbackSpeed])
    const decreasePlaybackSpeed = useCallback(() => {
        const currentSpeedIndex = PLAYBACK_SPEEDS.indexOf(playbackSpeed)
        const nextSpeedIndex = Math.max(currentSpeedIndex - 1, 0)
        setPlaybackSpeed(PLAYBACK_SPEEDS[nextSpeedIndex])
    }, [playbackSpeed])

    const replayAfterSeekEndRef = useRef(false)
    const onAudioTrackSeekStart = useCallback(() => {
        if (!mediaRef.current?.paused) {
            pause()
            replayAfterSeekEndRef.current = true
        }
    }, [mediaRef, pause])
    const onAudioTrackSeekEnd = useCallback(() => {
        if (replayAfterSeekEndRef.current) {
            // play()
            replayAfterSeekEndRef.current = false
        }
    }, [])
    const onTrackTimeUpdate = useCallback(
        (time: number) => {
            seekTime(time)
        },
        [seekTime],
    )

    useEffect(() => {
        if (!mediaRef.current) return
        mediaRef.current.playbackRate = playbackSpeed
    }, [playbackSpeed, mediaRef])

    // when audio player unmounts, pause the audio so the state doesn't choke
    useWillUnmount(pause)

    // Keyboard shortcuts
    useMousetrap(isMac ? 'alt+space' : 'ctrl+space', togglePlayPause, {
        label: 'Audio: Play/Pause',
        preventDefault: true,
        stopImmediatePropagation: true,
    })
    useMousetrap('mod+left', seekBackward, {
        label: 'Audio: Seek backward',
        preventDefault: true,
        stopImmediatePropagation: true,
        allowRepeat: true,
    })
    useMousetrap('mod+right', seekForward, {
        label: 'Audio: Seek forward',
        preventDefault: true,
        stopImmediatePropagation: true,
        allowRepeat: true,
    })

    useMousetrap('alt+.', increasePlaybackSpeed, {
        label: 'Audio: Increase playback speed',
        preventDefault: true,
        stopImmediatePropagation: true,
    })
    useMousetrap('alt+,', decreasePlaybackSpeed, {
        label: 'Audio: Decrease playback speed',
        preventDefault: true,
        stopImmediatePropagation: true,
    })

    const shouldEnableControls = duration > 0

    return (
        <Container>
            <ControlsSection>
                <Loader />
                <StyledIconButton
                    aria-label="seek backwards"
                    variant="link"
                    icon={
                        <SkipForwardIcon
                            style={{ transform: 'rotate(180deg)' }}
                            color="#1a4af0"
                            pointerEvents="none"
                        />
                    }
                    disabled={!shouldEnableControls}
                    onClick={seekBackward}
                />
                <StyledIconButton
                    aria-label="Play"
                    variant="link"
                    // @ts-ignore
                    isHighlight
                    icon={
                        isMediaPlaying ? (
                            <PauseFilledIcon color="#1a4af0" pointerEvents="none" />
                        ) : (
                            <PlayFilledIcon color="#1a4af0" pointerEvents="none" />
                        )
                    }
                    disabled={!shouldEnableControls}
                    onClick={() => togglePlayPause()}
                />
                <StyledIconButton
                    aria-label="seek forwards"
                    variant="link"
                    icon={<SkipForwardIcon pointerEvents="none" color="#1a4af0" />}
                    disabled={!shouldEnableControls}
                    onClick={seekForward}
                />
            </ControlsSection>
            <MediaTrack
                duration={duration}
                currentTime={exactCurrentTime}
                onSeekEnd={onAudioTrackSeekEnd}
                onSeekStart={onAudioTrackSeekStart}
                onTrackTimeUpdate={onTrackTimeUpdate}
            />
            <ControlsSection>
                <VolumeButton
                    isMuted={volumeValue === 0}
                    value={volumeValue}
                    onChange={(sliderValue) => {
                        updateVolume(sliderValue)
                    }}
                />
                <PlaybackSpeedButton
                    speedOptions={PLAYBACK_SPEEDS}
                    selectedSpeed={playbackSpeed}
                    onChange={setPlaybackSpeed}
                />
            </ControlsSection>
        </Container>
    )
}
