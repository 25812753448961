import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { LoadingAnimation } from 'src/components/LoadingAnimation'
import { TrainingMetricsBox } from 'src/components/Exit/TrainingMetricsBox'
import { TrainingSessionStatsIcon, TrainingSessionStoppedIcon } from 'src/components/icons'

const TrainingContainer = styled.div`
    height: 100%;
    padding-top: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${palette('cloudBlueLight', 7)};
`

const Title = styled.h1`
    font-weight: 500;
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 32px;
    margin-top: 24px;
    color: #030d69;
    text-align: center;
`

const Paragraph = styled.p`
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
`

const renderTitle = (type: string) => {
    switch (type) {
        case 'LEFT':
            return <Title>Training has stopped. You can come back to it at any time</Title>
        default:
            return (
                <Title>
                    Training is finished.
                    <br />
                    Please wait while we’re preparing your feedback.
                </Title>
            )
    }
}

const renderIcon = (type: string) => {
    switch (type) {
        case 'LEFT':
            return <TrainingSessionStoppedIcon />
        default:
            return <TrainingSessionStatsIcon />
    }
}

export interface TrainingSessioStatsProps {
    sessionStats: {
        workTime: number
        transcribedAudio: number
        segmentsCompleted: number
    }
    type: string
}

export const TrainingSessionStats = ({ sessionStats, type }: TrainingSessioStatsProps) => {
    return (
        <TrainingContainer>
            {renderIcon(type)}
            {renderTitle(type)}
            <LoadingAnimation />
            <Paragraph>Here is a brief summary of your work</Paragraph>
            <TrainingMetricsBox sessionStats={sessionStats} />
        </TrainingContainer>
    )
}
