import { RefObject, useEffect } from 'react'
import { useHighlightedWord } from 'src/components/Editor/EditorContext'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

const EXTRA_SPACE_FROM_BOTTOM = 50

type WrapperElementType = RefObject<HTMLDivElement | null>

export function useWordAutoScroll(editorScrollableWrapperRef: WrapperElementType) {
    const { highlightedWord } = useHighlightedWord()
    const { isMediaPlaying } = useSessionMedia(['isMediaPlaying'])

    const containerRect = editorScrollableWrapperRef?.current?.getBoundingClientRect()
    const wordRect = highlightedWord?.current?.getBoundingClientRect()

    useEffect(() => {
        if (!!wordRect && !!containerRect && !!highlightedWord?.current) {
            const shouldScroll = !(wordRect.bottom < containerRect.bottom - EXTRA_SPACE_FROM_BOTTOM)

            if (shouldScroll && isMediaPlaying) {
                highlightedWord?.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
        }
    }, [highlightedWord, isMediaPlaying, containerRect, wordRect])
}
