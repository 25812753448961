import { DoubleDownArrowsIcon } from '@verbit-ai/icons-library'
import { FloatingActionButton } from '@verbit-ai/verbit-ui-library'

type ScrollToBottomButtonProps = {
    onClick: () => void
    hasUnreadMessages: boolean
}

export function ScrollToBottomButton(props: ScrollToBottomButtonProps) {
    const { onClick, hasUnreadMessages } = props

    return (
        <FloatingActionButton
            icon={<DoubleDownArrowsIcon />}
            onClick={onClick}
            __css={{
                // TODO: Move indicator to ui-library https://verbit.atlassian.net/browse/RND-37665
                '&::after': hasUnreadMessages && {
                    content: '""',
                    position: 'absolute',
                    top: '-2px',
                    right: '-2px',
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    background: 'red.400',
                },
            }}
        />
    )
}
