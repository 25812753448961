import React from 'react'
import styled from 'styled-components/macro'

import { EmptyGlossaryIcon } from 'src/components/icons'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 50px;
    color: #10264a;
`

const Title = styled.div`
    margin-top: 10px;
    font-weight: 700;
    line-height: 24px;
`

const Message = styled.div`
    line-height: 18px;
`

export const EmptyGlossaryMessage = () => (
    <Container data-testid={'empty-glossary-message'}>
        <EmptyGlossaryIcon />
        <Title>There are no terms yet</Title>
        <Message>Search terms in the files and add them to the Glossary</Message>
    </Container>
)
