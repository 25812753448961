import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import { useAnalytics } from '../../analytics'
import { useTaskMachine } from '../../state/state-machines/TaskMachine/TaskMachineProvider'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { AudioPlayerButton } from 'src/components/AudioPlayer/buttons'

export const FlexTargetPopover = styled(Popover2)`
    display: flex;

    .bp4-popover {
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 6px;
    }

    .bp4-popover-target {
        display: flex;
    }
`

const PlaybackSpeedMenu = styled(Menu)`
    min-width: 58px;
    padding: 0;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
`

const PlaybackSpeedMenuItem = styled(MenuItem)`
    padding: 9px;
    border-radius: 0;
    color: ${palette('navy', 2)} !important;
    font-size: 14px;
    line-height: normal;
    transition: background-color 250ms ease-out;

    &:hover,
    &.bp4-active {
        font-weight: 800;
        background-color: ${palette('cloudBlueLight', 2)} !important;
    }
`

const SelectedPlaybackSpeed = styled.div`
    display: flex;
    flex-direction: column;
    width: 40px;
    font-size: 16px;
    line-height: 12px;
    font-weight: 600;
`

const SpeedLabel = styled.div`
    font-size: 9px;
    line-height: 9px;
`

interface PlaybackSpeedButtonProps {
    speedOptions: number[]
    selectedSpeed: number
    onChange: (speed: number) => void
}

export const PlaybackSpeedButton = ({
    speedOptions,
    selectedSpeed,
    onChange,
}: PlaybackSpeedButtonProps) => {
    const analytics = useAnalytics()
    const [{ context }] = useTaskMachine(['task'])
    const { task } = context

    const onSpeedItemClick = (speed: number) => {
        onChange(speed)
        if (!!task) {
            analytics?.sendAudioSpeedChange(task.id, speed)
        }
    }

    return (
        <FlexTargetPopover
            position={'top'}
            modifiers={{
                offset: { enabled: true },
                arrow: { enabled: false },
            }}
            content={
                <PlaybackSpeedMenu>
                    {speedOptions.map((speed) => (
                        <PlaybackSpeedMenuItem
                            key={speed}
                            onClick={() => onSpeedItemClick(speed)}
                            text={`${speed}x`}
                            active={speed === selectedSpeed}
                        />
                    ))}
                </PlaybackSpeedMenu>
            }
        >
            <Tooltip label="Play Speed" placement="top" hasArrow>
                <AudioPlayerButton>
                    <SelectedPlaybackSpeed>
                        <div>{selectedSpeed}x</div>
                        <SpeedLabel>speed</SpeedLabel>
                    </SelectedPlaybackSpeed>
                </AudioPlayerButton>
            </Tooltip>
        </FlexTargetPopover>
    )
}
