import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { GlossaryTerm, GlossaryTermCategory } from 'src/models'
import { useAnalytics } from 'src/analytics'
import { GlossaryOpenSource } from 'src/analytics/types'
import { useOnClickOutside } from 'src/hooks/useClickOutside'
import { usePrevious } from 'src/hooks/usePrevious'
import {
    SearchIcon,
    GlossaryIcon,
    UserIcon,
    LocationIcon,
    ProfessionalTermIcon,
} from '@verbit-ai/icons-library'
import * as Styled from 'src/components/Glossary/styled'
import { Row } from 'src/components/Glossary/Row'
import { GlossaryItem } from 'src/components/Glossary/GlossaryItem'
import { useGlossaryList } from 'src/components/Glossary/useGlossaryList'
import { useSession } from 'src/state/session'

import { EmptyGlossaryMessage } from './EmptyGlossaryMessage'
import { MenuPopover, MenuPopoverOption } from './MenuPopover'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

const SearchBar = styled(Styled.SearchBar)`
    margin: 14px 12px;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top: solid 1px ${palette('cloudBlueLight', 1)};
    overflow: hidden;
`

const FilterAndSortBar = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 12px;
    border-top: solid 1px ${palette('cloudBlueLight', 1)};
`

const SelectedSortOption = styled.div`
    display: flex;
    align-items: center;
`

const SortOptionLabel = styled.div`
    margin-left: 5px;
`

const NoMatchesLabel = styled.div`
    padding: 15px 12px;
    font-size: 13px;
    line-height: 18px;
    color: ${palette('cloudBlue', 0)};
`

const StyledGlossaryItem = styled(GlossaryItem)`
    padding: 4px 12px;
    cursor: default;

    &:last-of-type {
        margin-bottom: 32px;
    }

    ${Styled.ItemIcon} {
        svg {
            width: 18px;
            height: 18px;
        }
    }

    ${Styled.ItemText} {
        margin-left: 10px;
    }
`

const StyledRow = styled(Row)`
    padding: 4px 12px;

    ${Styled.ItemIcon} {
        svg {
            width: 18px;
            height: 18px;
        }
    }

    ${Styled.ItemText} {
        margin-left: 10px;
    }
`

const AddItemSection = styled.div`
    border-top: solid 1px ${palette('cloudBlueLight', 1)};
`

const AddItemSectionHeaderRow = styled(StyledRow)`
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: ${palette('greyBlue', 0)};

    span {
        font-style: italic;
    }

    svg {
        color: ${palette('cloudBlue', 0)};
    }
`

const filterOptions: MenuPopoverOption<GlossaryTermCategory>[] = [
    {
        value: null,
        label: 'All Terms',
        icon: <GlossaryIcon />,
    },
    {
        value: 'person',
        label: 'People',
        icon: <UserIcon />,
    },

    {
        value: 'place',
        label: 'Places',
        icon: <LocationIcon />,
    },
    {
        value: 'term',
        label: 'Prof. Terms',
        icon: <ProfessionalTermIcon />,
    },
]

type SortOption = 'A-Z' | 'category' | 'score'

const sortOptions: MenuPopoverOption<SortOption>[] = [
    {
        value: 'A-Z',
        label: 'A - Z',
    },
    {
        value: 'category',
        label: 'By Type',
    },
]

export const GlossaryPanel = () => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [useShortcuts, setUseShortcuts] = useState(true)
    const [isFocused, setIsFocused] = useState(true)
    const [selectedFilter, setSelectedFilter] = useState<GlossaryTermCategory | null>(
        filterOptions[0].value,
    )
    const [selectedSort, setSelectedSort] = useState<SortOption | null>(sortOptions[0].value)
    const itemSorter = useCallback(
        (term: GlossaryTerm) => {
            switch (selectedSort) {
                case 'A-Z':
                    return term.text.toLowerCase()

                case 'category':
                    return term.category.toLowerCase()

                // keep original sort
                case 'score':
                    return ''

                default:
                    return term.text.toLowerCase()
            }
        },
        [selectedSort],
    )

    const itemFilter = useCallback(
        (term: GlossaryTerm) => {
            if (!selectedFilter) {
                return true
            }

            return term.category === selectedFilter
        },
        [selectedFilter],
    )

    // TODO: move to useGlossaryList
    const analytics = useAnalytics()
    const { taskId } = useSession()

    useEffect(() => {
        analytics?.sendOpenGlossary(taskId ?? '', GlossaryOpenSource.HEADER)
    }, [analytics, taskId])

    const {
        searchTerm,
        items,
        filteredItems,
        addItemOptions,

        activeItemIdx,
        expandedItemIdx,
        isAddItemExpanded,
        displayAddItem,

        itemRefs,
        searchInputRef,

        handleSearchInputChange,
        handleTermEditRequest,
        handleTermDeleteRequest,
        handleItemMouseOver,
        handleAddItemMouseOver,
    } = useGlossaryList({
        source: 'Glossary Panel',
        withShortcuts: useShortcuts,
        withFlatAddItemOptions: true,
        itemSorter,
        itemFilter,
    })

    const fullSortOptions = useMemo(
        () =>
            searchTerm.length
                ? sortOptions.concat({ value: 'score', label: 'Most Relevant' })
                : sortOptions,
        [searchTerm],
    )
    const prevSearchTerm = usePrevious(searchTerm)
    const prevSelectedSort = usePrevious(selectedSort, { unique: true })
    useEffect(() => {
        // when starting search, always sort by score by default
        if (searchTerm.length && prevSearchTerm !== undefined && !prevSearchTerm.length) {
            setSelectedSort('score')
        }

        // when exiting "search mode" reset to previous sort
        if (!searchTerm.length && selectedSort === 'score') {
            setSelectedSort(prevSelectedSort ?? 'A-Z')
        }
    }, [prevSearchTerm, prevSelectedSort, searchTerm.length, selectedSort])

    useOnClickOutside(containerRef, () => {
        setIsFocused(false)
        setUseShortcuts(false)
    })

    return (
        <Container
            ref={containerRef}
            onMouseEnter={() => setUseShortcuts(true)}
            onMouseLeave={() => {
                if (!isFocused) {
                    setUseShortcuts(false)
                }
            }}
            onMouseDown={() => setIsFocused(true)}
        >
            <SearchBar onClick={() => searchInputRef.current?.focus()}>
                <Styled.SearchInput
                    type="text"
                    placeholder="Search Glossary"
                    spellCheck={false}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    ref={searchInputRef}
                    // apparently, windows needs a onFocus event to actually maintain the focus
                    onFocus={() => {}}
                />

                <SearchIcon />
            </SearchBar>

            {!!items.length && (
                <FilterAndSortBar>
                    <MenuPopover
                        onSelect={setSelectedFilter}
                        selectedOptionValue={selectedFilter}
                        options={filterOptions}
                    />

                    <MenuPopover
                        onSelect={setSelectedSort}
                        selectedOptionValue={selectedSort}
                        options={fullSortOptions}
                        renderSelectedOptionLabel={(label) => (
                            <SelectedSortOption>
                                <div>Sort:</div>
                                <SortOptionLabel>{label}</SortOptionLabel>
                            </SelectedSortOption>
                        )}
                    />
                </FilterAndSortBar>
            )}

            <Body>
                {items.length || searchTerm ? (
                    <>
                        <Styled.ListScroller>
                            {filteredItems.length > 0 ? (
                                <Styled.List>
                                    {filteredItems.map((term, i) => (
                                        <StyledGlossaryItem
                                            innerRef={(el) => (itemRefs.current[i] = el)}
                                            key={term.id}
                                            term={term}
                                            index={i}
                                            active={i === activeItemIdx}
                                            expanded={i === expandedItemIdx}
                                            onMouseOver={handleItemMouseOver}
                                            onEditSelected={handleTermEditRequest}
                                            onDeleteSelected={handleTermDeleteRequest}
                                        />
                                    ))}
                                </Styled.List>
                            ) : (
                                <NoMatchesLabel>No matches</NoMatchesLabel>
                            )}
                            {displayAddItem && (
                                <AddItemSection>
                                    <AddItemSectionHeaderRow
                                        icon={<FiPlus />}
                                        expanded={isAddItemExpanded && expandedItemIdx === -1}
                                        onMouseOver={handleAddItemMouseOver}
                                        text={
                                            <div>
                                                Add: <span>{searchTerm}</span> to Glossary as:
                                            </div>
                                        }
                                    />

                                    {addItemOptions.map((props, i) => (
                                        <StyledRow key={i} {...props} />
                                    ))}
                                </AddItemSection>
                            )}
                        </Styled.ListScroller>
                    </>
                ) : (
                    <EmptyGlossaryMessage />
                )}
            </Body>
        </Container>
    )
}
