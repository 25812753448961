import { Editor } from 'slate'
import { Block } from 'src/components/Editor/plugins/withTranscript'
import { convertTimingToDateTime, formatTimecode } from 'src/utils/timecode'
import { LiveEditsEditor } from '../withLiveEdits/LiveEditsEditor'
import { HistoryEditor } from 'slate-history'

export const withTimecode = (editor: Editor) => {
    const { normalizeNode } = editor

    editor.normalizeNode = (entry) => {
        const [block, path] = entry
        const [blockIndex] = path

        if (Block.isBlock(block)) {
            const blockStartTime = editor.timeline.getBlockStartTime(blockIndex)
            const newTime = formatTimecode('00:00:00:00', convertTimingToDateTime(blockStartTime), {
                includeFps: false,
            })

            if (block.mediaTimeCode !== newTime) {
                HistoryEditor.withoutSaving(editor, () => {
                    LiveEditsEditor.withoutLiveEdits(editor, () => {
                        Block.setMediaTimeCode(editor, [blockIndex], newTime)
                    })
                })
            }
        }

        normalizeNode(entry)
    }

    return editor
}
