import { TimingRange } from 'src/utils/range'

import { TimeSegment } from './TimeSegment'
import { TimedTextSegment } from './TimedTextSegment'
import { BaseSegment } from './Segment'
import { TimelineOperation } from './translateSlateOperation'
import { TimeAligner } from '../align/TimeAligner'

type TimelineOptions = {
    segmentTimings: TimingRange
    aligner: TimeAligner
    initialSegment?: TimedTextSegment
}

export class Timeline {
    current: TimedTextSegment
    segmentTimings: TimingRange

    aligner: TimeAligner

    constructor({ segmentTimings, aligner, initialSegment }: TimelineOptions) {
        this.segmentTimings = segmentTimings
        this.aligner = aligner

        this.current = initialSegment ?? TimedTextSegment.create({ timing: segmentTimings })
    }

    get lastBlockIndex() {
        return this.current.blocks.length - 1
    }

    clear(segmentTimings?: TimingRange) {
        this.segmentTimings = segmentTimings ?? this.segmentTimings

        this.current = TimedTextSegment.create({ timing: this.segmentTimings })
    }

    mergeOrginalSegment(segment: TimedTextSegment) {
        this.current = TimedTextSegment.merge(this.current, segment)
    }

    apply(ops: TimelineOperation[]) {
        this.current = TimedTextSegment.apply(this.current, this.aligner, ops)
    }

    getBlockStartTime(index: number): number {
        return TimeSegment.getBlockStartTime(this.current, index) ?? 0
    }

    getBlockEndTime(index: number): number {
        return TimeSegment.getBlockEndTime(this.current, index) ?? 0
    }

    getTimingsForBlock(index: number) {
        return TimeSegment.getTimingsForBlock(this.current, index)
    }

    getBlockIndexForTime(time: number, affinity?: 'start' | 'end') {
        return TimeSegment.getBlockIndexForTime(this.current, time, affinity)
    }

    getBlockChunk(index: number) {
        return BaseSegment.getBlock(this.current, index)
    }
}
