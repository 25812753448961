import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 100px);
    background-color: ${palette('white', 0)};
`

export const LiveBodyContainer = styled(Container)`
    position: relative;
    display: flex;
    flex-direction: row;
`
