import { Editor, Path, Text, Transforms, Range, Point } from 'slate'

import { ZERO_WIDTH_WHITESPACE } from 'src/utils/string'
import { isPunctuation } from 'src/utils/string'
import { Block } from 'src/components/Editor/plugins/withTranscript'
import { Tag } from 'src/components/Editor/plugins/withTags/Tag'

import { SpellcheckerAPI } from 'src/components/Spellchecker/SpellcheckerAPI'
import { Spellchecker } from './Spellchecker'
import { Suggestion } from '../withSuggestions/Suggestion'
import { Validations } from '../withValidations'

const WORD_SPLIT_REGEX = new RegExp(`( |${ZERO_WIDTH_WHITESPACE}|[.,!?;:-])`)

interface NodesObject {
    [key: string]: true
}

export function withSpellchecker(spellchecker: SpellcheckerAPI) {
    return (editor: Editor) => {
        const { normalizeNode, insertBreak } = editor
        let lastUnsetNodes: NodesObject = {}

        editor.normalizeNode = (entry) => {
            const [node, path] = entry

            if (Text.isText(node) && node.editable) {
                const words = node.text.split(WORD_SPLIT_REGEX).filter((w) => w !== '')
                if (!words.length) {
                    return normalizeNode(entry)
                }

                // If the node is a tag node descendant, remove the spelling error if needed and return
                if (
                    Tag.hasTag(editor, { at: path }) ||
                    Suggestion.hasSuggestion(editor, { at: path })
                ) {
                    if (node.spellingError) {
                        Spellchecker.removeSpellingError(editor, { at: path })
                        return
                    }
                    return normalizeNode(entry)
                }

                let offset = 0
                const selectionOffset = editor.selection?.anchor.offset || -1
                const isSelectionInNode =
                    !!editor.selection &&
                    Range.isCollapsed(editor.selection) &&
                    Path.equals(path, editor.selection.anchor.path)

                for (const [i, word] of words.entries()) {
                    // Increment the offset in case the "word" is whitespace/punctuation/zero width whitespace and remove spelling error if needed
                    if (/\s+/.test(word) || word === ZERO_WIDTH_WHITESPACE || isPunctuation(word)) {
                        if (node.spellingError && (word === ' ' || isPunctuation(word))) {
                            Spellchecker.removeSpellingError(editor, {
                                at: {
                                    anchor: { path, offset },
                                    focus: { path, offset: offset + word.length },
                                },
                            })
                            return
                        }
                        offset += word.length
                        continue
                    }

                    const [[, blockPath]] = Editor.nodes(editor, { at: path, match: Block.isBlock })

                    const prevTextEntry = Editor.previous(editor, { at: path, match: Text.isText })
                    let prevText: Text | null = null
                    if (prevTextEntry && Path.isDescendant(prevTextEntry[1], blockPath)) {
                        prevText = prevTextEntry[0]
                    }

                    const nextTextEntry = Editor.next(editor, { at: path, match: Text.isText })
                    let nextText: Text | null = null
                    if (nextTextEntry && Path.isDescendant(nextTextEntry[1], blockPath)) {
                        nextText = nextTextEntry[0]
                    }

                    // next node is a spelling error and there's no space/divider between the nodes
                    if (
                        Spellchecker.isSpellingError(nextText) &&
                        nextTextEntry &&
                        i === words.length - 1
                    ) {
                        Spellchecker.removeSpellingError(editor, { at: nextTextEntry[1] })
                        lastUnsetNodes[nextTextEntry[1].join('-')] = true
                        return
                    }

                    // previous node is a spelling error and there's no space/divider between the nodes
                    if (Spellchecker.isSpellingError(prevText) && prevTextEntry && i === 0) {
                        Spellchecker.removeSpellingError(editor, { at: prevTextEntry[1] })
                        lastUnsetNodes[prevTextEntry[1].join('-')] = true
                        return
                    }

                    // In case the marked word changed, unmark it
                    if (Spellchecker.isSpellingError(node) && spellchecker.isCorrect(word)) {
                        Spellchecker.removeSpellingError(editor, {
                            at: {
                                anchor: { path, offset },
                                focus: { path, offset: offset + word.length },
                            },
                        })
                        return
                    }

                    // Mark the word as spelling error if needed:
                    // If the word is not marked as a spelling error and it should,
                    // If the word is not inside the lastUnsetNodes object, and if the current node
                    // is not inside the current selection - to avoid marking a word that is currently typed.
                    // In addition, we make sure that if we are correcting a word, the marking will happen immediately.
                    if (
                        !Spellchecker.isSpellingError(node) &&
                        !spellchecker.isCorrect(word) &&
                        !lastUnsetNodes[path.join('-')] &&
                        !Validations.isInvalidValidation(node) &&
                        (!isSelectionInNode ||
                            selectionOffset < offset ||
                            selectionOffset > offset + word.length ||
                            (editor.selection &&
                                !Editor.isEnd(editor, editor.selection?.anchor, blockPath)))
                    ) {
                        Spellchecker.setSpellingError(editor, {
                            at: {
                                anchor: { path, offset },
                                focus: { path, offset: offset + word.length },
                            },
                        })
                        return
                    }
                    offset += word.length
                }

                if (lastUnsetNodes[path.join('-')]) {
                    delete lastUnsetNodes[path.join('-')]
                }
            }
            normalizeNode(entry)
        }

        editor.insertBreak = () => {
            // In case of inserting a break before or after a marked word, move the selection one offset (prevent's the capitalization bug)
            if (editor.selection && Range.isCollapsed(editor.selection)) {
                const breakPoint = editor.selection.anchor
                const { path, offset } = editor.selection.anchor
                const [currentEntry] = Editor.nodes(editor, { at: path, match: Text.isText })

                if (currentEntry) {
                    const [currentNode] = currentEntry
                    const nextTextEntry = Editor.next(editor, { at: path, match: Text.isText })
                    const nextNextTextEntry =
                        nextTextEntry &&
                        Editor.next(editor, { at: nextTextEntry[1], match: Text.isText })

                    if (
                        Spellchecker.isSpellingError(currentNode) &&
                        nextTextEntry &&
                        nextTextEntry[0].text === ' ' &&
                        nextNextTextEntry &&
                        Spellchecker.isSpellingError(nextNextTextEntry[0]) &&
                        offset === currentNode.text.length
                    ) {
                        Transforms.move(editor, { distance: 2, unit: 'offset' })
                    }

                    if (
                        Spellchecker.hasSpellingError(editor, { at: breakPoint }) &&
                        Point.equals(Editor.end(editor, breakPoint.path), breakPoint)
                    ) {
                        Transforms.move(editor, { distance: 1, unit: 'offset' })
                    }

                    if (
                        nextTextEntry &&
                        Spellchecker.hasSpellingError(editor, { at: nextTextEntry[1] }) &&
                        Point.equals(Editor.end(editor, breakPoint.path), breakPoint)
                    ) {
                        Transforms.move(editor, { distance: 1, unit: 'offset' })
                    }
                }
            }

            insertBreak()
        }

        return editor
    }
}
