import { useState, useMemo, useRef, useCallback, RefObject } from 'react'
import { compact } from 'lodash/fp'

import { TimingRange } from 'src/utils/range'

/**
 * This hook returns the played time ranges of a media element
 * and the event callbacks that are required to be called in order to calculate these ranges
 * **/
export const useMediaPlayedTimeRanges = (mediaRef: RefObject<HTMLMediaElement>) => {
    const [played, setPlayed] = useState<TimingRange[]>([])
    const isCurrentPlayedTimeRangeLocked = useRef(false)
    const mediaElem = mediaRef.current

    const resetPlayed = useCallback(() => setPlayed([]), [])

    const onBeforePlay = useCallback(() => {
        if (!mediaElem) {
            return
        }

        const currentTime = mediaElem.currentTime

        setPlayed((played) => [
            ...played,
            {
                start: currentTime,
                end: currentTime,
            },
        ])
    }, [mediaElem])

    const onAfterPause = useCallback(() => {
        if (!mediaElem) {
            return
        }

        const currentTime = mediaElem.currentTime

        setPlayed((played) =>
            !played.length
                ? played
                : [
                      ...played.slice(0, played.length - 1),
                      {
                          ...played[played.length - 1],
                          end: currentTime,
                      },
                  ],
        )
    }, [mediaElem])

    const onSeeked = useCallback(
        (prevTime: number) => {
            if (!mediaElem || mediaElem.paused) {
                return
            }

            const currentTime = mediaElem.currentTime

            isCurrentPlayedTimeRangeLocked.current = true
            setPlayed((played) =>
                compact([
                    ...played.slice(0, played.length - 1),
                    played.length && {
                        start: played[played.length - 1].start,
                        end: prevTime,
                    },
                    {
                        start: currentTime,
                        end: currentTime,
                    },
                ]),
            )
            isCurrentPlayedTimeRangeLocked.current = false
        },
        [mediaElem],
    )

    const onTimeUpdate = useCallback(
        (exactCurrentTime: number) => {
            if (mediaElem && !mediaElem.paused && !isCurrentPlayedTimeRangeLocked.current) {
                setPlayed((played) =>
                    !played.length
                        ? played
                        : [
                              ...played.slice(0, played.length - 1),
                              {
                                  ...played[played.length - 1],
                                  end: exactCurrentTime,
                              },
                          ],
                )
            }
        },
        [mediaElem],
    )

    return {
        playedTimeRanges: played,
        resetPlayedTimeRanges: resetPlayed,
        callbacks: useMemo(
            () => ({
                onBeforePlay,
                onAfterPause,
                onSeeked,
                onTimeUpdate,
            }),
            [onBeforePlay, onAfterPause, onSeeked, onTimeUpdate],
        ),
    }
}
