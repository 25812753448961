import { isMac } from 'src/utils/platform'

export enum KeyStringCodes {
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
    Escape = 'Escape',
    Enter = 'Enter',
    NumpadEnter = 'NumpadEnter',
    Backspace = 'Backspace',
    Tab = 'Tab',
    Delete = 'Delete',
    CapsLock = 'CapsLock',
    ShiftLeft = 'ShiftLeft',
    ControlLeft = 'ControlLeft',
    ControlRight = 'ControlRight',
    AltLeft = 'AltLeft',
    MetaLeft = 'MetaLeft',
    MetaRight = 'MetaRight',
    AltRight = 'AltRight',
    ShiftRight = 'ShiftRight',
}

export enum KeyNumericCodes {
    PageUp = 33,
    PageDown = 34,
    End = 35,
    Home = 36,
    Left = 37,
    Up = 38,
    Right = 39,
    Down = 40,
}

export const KeyShortcutLabels = (type: string): string =>
    ({
        PLAY: isMac ? ' ⌥ (option) + Space ' : ' Ctrl + Space ',
        REPLAY: isMac ? ' ⌥ (option) + S ' : ' Alt + S ',
        BACKWARD: isMac ? ' ⌘ (cmd) + ← ' : ' Ctrl + ← ',
        FORWARD: isMac ? ' ⌘ (cmd) + → ' : ' Ctrl + → ',
    }[type] ?? '')
