import { useEffect, useRef } from 'react'

interface UsePreviousOptions {
    unique?: boolean
}

// the unique option allows to keep a previous value for more than one render since it'll only update, when the current state changes to something else as well.
export const usePrevious = <T>(state: T, options: UsePreviousOptions = {}): T | undefined => {
    const { unique = false } = options

    const prevState = useRef<T>()
    const currentState = useRef<T>()

    useEffect(() => {
        if (unique && currentState.current !== state) {
            prevState.current = currentState.current
        } else if (!unique) {
            prevState.current = state
        }
        currentState.current = state
    })

    return prevState.current
}
