import { useCallback } from 'react'
import styled from 'styled-components/macro'

import { DocumentViewerContainer } from 'src/components/EmbeddedViewer/EmbeddedViewerContainer'
import { useOnboardingStepPayload } from 'src/components/OnboardingTask/useOnboardingStepPayload'
import { AttachmentsDownloadSource, useAnalytics } from 'src/analytics'
import { getFileExtension } from 'src/utils/fileExtension'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 85%;
`

export const AttachmentsStep = () => {
    const { attachments } = useOnboardingStepPayload('attachments')

    const analytics = useAnalytics()
    const onDownload = useCallback(
        (fileUrl: string) => {
            analytics?.sendAttachmentDownloaded(
                getFileExtension(fileUrl).toLowerCase(),
                AttachmentsDownloadSource.ONBOARDING_ATTACHMENTS,
            )
        },
        [analytics],
    )

    return (
        <Container>
            <DocumentViewerContainer attachments={attachments} onDownload={onDownload} />
        </Container>
    )
}
