import { createElement, ReactNode } from 'react'

import { Task, TaskBase, toWord, TranscriptionTask } from 'src/models'
import { SpeakersByLegalAnnotationMap } from 'src/state/LegalAnnotationsProvider'
import { TaskJSON, toEditorControls, TranscriptionTaskJSON } from 'src/network/responses'
import { TranscriptionTask as TranscriptionTaskComponent } from 'src/components/TranscriptionTask/TranscriptionTask'

import { TaskItem } from './Registry'
import { ExaminationLegalAnnotation } from 'src/components/Editor/plugins/withTranscript/Block'

class TranscriptionTaskItem extends TaskItem {
    allowCache = true

    constructor() {
        super('transcription')
    }

    isTaskJSON(json: TaskJSON): json is TranscriptionTaskJSON {
        return json.type === 'transcription'
    }

    isTask(task: Task): task is TranscriptionTask {
        return task.type === 'transcription'
    }

    convertToTask(base: TaskBase, json: TranscriptionTaskJSON): TranscriptionTask {
        return {
            ...base,
            type: 'transcription',
            payload: {
                controls: toEditorControls(json.payload.controls),
                text: {
                    before: {
                        type: 'before',
                        words: json.payload.segments.before.words.map(toWord),
                        editable: false,
                        timing: {
                            start: json.payload.segments.before.start,
                            end: json.payload.segments.before.end,
                        },
                    },
                    editable: {
                        type: 'editable',
                        words: json.payload.segments.body.words.map(toWord),
                        editable: true,
                        timing: {
                            start: json.payload.segments.body.start,
                            end: json.payload.segments.body.end,
                        },
                    },
                    after: {
                        type: 'after',
                        words: json.payload.segments.after.words.map(toWord),
                        editable: false,
                        timing: {
                            start: json.payload.segments.after.start,
                            end: json.payload.segments.after.end,
                        },
                    },
                },
                section: json.payload.section,
                timing: {
                    start: json.payload.start,
                    end: json.payload.end,
                },
                annotationMap: Object.entries(
                    json.payload.annotation_map,
                ).reduce<SpeakersByLegalAnnotationMap>((acc, [legalAnnotation, speakerId]) => {
                    const nextAcc = { ...acc }

                    if (legalAnnotation === ExaminationLegalAnnotation.A) {
                        nextAcc[ExaminationLegalAnnotation.A] = speakerId
                    }

                    if (
                        legalAnnotation === ExaminationLegalAnnotation.Q &&
                        nextAcc[ExaminationLegalAnnotation.A] !== speakerId
                    ) {
                        nextAcc[ExaminationLegalAnnotation.Q] = speakerId
                    }

                    return nextAcc
                }, {}),
            },
        }
    }

    getTitle(task: TranscriptionTask): string | null {
        const {
            payload: {
                controls: { editable, suggestionsEnabled },
            },
        } = task

        // This if condition for checking if we are in Glossers mode.
        // the hook is not working here because we are out of a task
        // We should get the data about if a session is in glossers mode in another place! not inside the task..
        if (!editable && !suggestionsEnabled) {
            return 'Add relevant terminology to the glossary & separate speakers'
        }

        switch (task.layerId) {
            case 'edit':
                return 'Correct the following'
            case 'annotate':
                return 'Annotate the following'
            case 'review':
                return 'Review the following'
            default:
                return null
        }
    }

    render(): ReactNode {
        return createElement(TranscriptionTaskComponent)
    }
}

const instance = new TranscriptionTaskItem()
export { instance as TranscriptionTaskItem }
