import { RefObject, useEffect, useRef } from 'react'

export function useOnClickOutside(ref: RefObject<HTMLElement>, handler: (e: MouseEvent) => void) {
    const handlerRef = useRef(handler)

    useEffect(() => {
        handlerRef.current = handler
    }, [handler])

    useEffect(() => {
        const listener: EventListener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (
                !ref.current ||
                (event.target instanceof HTMLElement && ref.current.contains(event.target))
            ) {
                return
            }

            handlerRef.current(event as MouseEvent)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref])
}
