import { GlossaryTerm } from 'src/models'

export interface GlossaryDictJSON {
    glossary: {
        type: 'glossary'
        glossary: GlossaryTerm
    }[]
}

export interface GlossaryDict {
    terms: GlossaryTerm[]
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GlossaryDict = {
    empty: { terms: [] },
}

export const toGlossaryDict = (json: GlossaryDictJSON): GlossaryDict => ({
    terms: json.glossary.map((term) => term.glossary),
})

export const toGlossaryTerm = (data: Omit<GlossaryTerm, 'id'>, id: any): GlossaryTerm => ({
    ...data,
    id,
})
