import { Icon } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { MockManager } from '../MockManager'

const RecordIconWrapper = styled.div`
    color: red;
    animation: blink 2s linear 0s infinite;

    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.2;
        }
        100% {
            opacity: 1;
        }
    }
`

const Button = styled.div`
    margin-left: 8px;
    cursor: pointer;
`

type MockRecordControllerProps = {
    manager: MockManager
}

export function MockRecordController({ manager }: MockRecordControllerProps) {
    const onDownload = () => {
        manager.downloadMock()
    }

    return (
        <>
            <RecordIconWrapper>
                <Icon icon="record" />
            </RecordIconWrapper>
            <Button onClick={onDownload}>
                <Icon icon="download" /> Download Mock file
            </Button>
        </>
    )
}
