import { createElement } from 'react'

import {
    EditorControls,
    OnboardingStep,
    OnboardingStepsDict,
    OnboardingTask,
    Task,
    TaskBase,
    toGlossaryDict,
    toWord,
} from 'src/models'
import {
    OnboardingStepJSON,
    OnboardingTaskJSON,
    TaskJSON,
    toEditorControls,
} from 'src/network/responses'
import { OnboardingTask as OnboardingTaskComponent } from 'src/components/OnboardingTask/OnboardingTask'

import { TaskItem } from './Registry'

class OnboardingTaskItem extends TaskItem {
    constructor() {
        super('onboarding')
    }

    isTaskJSON(json: TaskJSON): json is OnboardingTaskJSON {
        return json.type === 'onboarding'
    }

    isTask(task: Task): task is OnboardingTask {
        return task.type === 'onboarding'
    }

    convertToTask(base: TaskBase, json: OnboardingTaskJSON): OnboardingTask {
        const { steps, controls } = json.payload
        const stepsDict = steps.map(convertToOnboardingStep).reduce<OnboardingStepsDict>(
            (accumulatedStepsDict, currentStep) => ({
                ...accumulatedStepsDict,
                [currentStep.type]: currentStep,
            }),
            {},
        )

        return {
            ...base,
            type: 'onboarding',
            payload: {
                steps: stepsDict,
                orderedStepTypes: steps.map(({ type }) => type),
                controls: toEditorControls(controls),
            },
        }
    }

    render() {
        return createElement(OnboardingTaskComponent)
    }
}

const instance = new OnboardingTaskItem()
export { instance as OnboardingTaskItem }

export const convertToOnboardingStep = (stepJson: OnboardingStepJSON): OnboardingStep => {
    switch (stepJson.type) {
        case 'session_intro': {
            return {
                type: 'session_intro',
                payload: {
                    title: stepJson.payload.title,
                    startedAt: stepJson.payload.started_at
                        ? new Date(stepJson.payload.started_at)
                        : null,
                    userRole: stepJson.payload.role_display_name,
                    responsibilities: stepJson.payload.responsibilities,
                    attachments: stepJson.payload.attachments,
                    vertical: stepJson.payload.vertical,
                    roleSwitch: stepJson.payload?.role_switch,
                    sessionName: stepJson.payload?.session_name,
                },
            }
        }

        case 'attachments': {
            return {
                type: 'attachments',
                payload: {
                    attachments: stepJson.payload.attachments,
                },
            }
        }

        case 'guidelines': {
            return {
                type: 'guidelines',
                payload: {
                    guidelines: stepJson.payload.guidelines,
                },
            }
        }

        case 'team_intro': {
            return {
                type: 'team_intro',
                payload: {
                    team: stepJson.payload.team.map(
                        ({ layer_id, role_display_name, count, online_count }) => ({
                            layerId: layer_id,
                            roleDisplayName: role_display_name,
                            count: Math.max(count, online_count),
                            onlineCount: online_count,
                        }),
                    ),
                },
            }
        }

        case 'gloss_highlights': {
            return {
                type: 'gloss_highlights',
                payload: toGlossaryDict(stepJson.payload),
            }
        }

        case 'speakers_intro': {
            return {
                type: 'speakers_intro',
                payload: undefined,
            }
        }

        case 'transcript_intro': {
            const controls: EditorControls = {
                diarization: stepJson.payload.controls.diarization,
                speaker: stepJson.payload.controls.speaker,
                section: stepJson.payload.controls.section,
                label: stepJson.payload.controls.label,
                legal: {
                    annotation: stepJson.payload.controls.legal.annotation,
                    exhibit: stepJson.payload.controls.legal.exhibit,
                    autoPopulation: stepJson.payload.controls.legal.auto_population,
                },
                audio: {
                    autoplay: stepJson.payload.controls.audio.auto_play,
                    forceListen: stepJson.payload.controls.audio.force_listen,
                    reportListened: stepJson.payload.controls.audio.report_listened,
                    bufferingTimeoutInSeconds: stepJson.payload.controls.audio.max_buffer_time,
                },
                timer: stepJson.payload.controls.timer,
            }

            return {
                type: 'transcript_intro',
                payload: {
                    controls,
                    timing: {
                        start: stepJson.payload.start,
                        end: stepJson.payload.end,
                    },
                    body: {
                        editable: false,
                        timing: {
                            start: stepJson.payload.body.start,
                            end: stepJson.payload.body.end,
                        },
                        type: null,
                        words: stepJson.payload.body.words.map(toWord),
                    },
                },
            }
        }

        case 'transcript_latest': {
            const controls: EditorControls = {
                diarization: stepJson.payload.controls.diarization,
                speaker: stepJson.payload.controls.speaker,
                section: stepJson.payload.controls.section,
                label: stepJson.payload.controls.label,
                legal: {
                    annotation: stepJson.payload.controls.legal.annotation,
                    exhibit: stepJson.payload.controls.legal.exhibit,
                    autoPopulation: stepJson.payload.controls.legal.auto_population,
                },
                audio: {
                    autoplay: stepJson.payload.controls.audio.auto_play,
                    forceListen: stepJson.payload.controls.audio.force_listen,
                    reportListened: stepJson.payload.controls.audio.report_listened,
                    bufferingTimeoutInSeconds: stepJson.payload.controls.audio.max_buffer_time,
                },
                timer: stepJson.payload.controls.timer,
            }

            return {
                type: 'transcript_latest',
                payload: {
                    controls,
                    timing: {
                        start: stepJson.payload.start,
                        end: stepJson.payload.end,
                    },
                    body: {
                        editable: false,
                        timing: {
                            start: stepJson.payload.body.start,
                            end: stepJson.payload.body.end,
                        },
                        type: null,
                        words: stepJson.payload.body.words.map(toWord),
                    },
                    currentSection: stepJson.payload.section?.text,
                },
            }
        }
    }
}
