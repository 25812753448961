import React, { useCallback, useEffect, useRef } from 'react'

import styled from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'
import { Popover2 } from '@blueprintjs/popover2'

import * as Icons from 'src/components/icons'

import { getFileExtension } from './ViewerTabBar'
import { CloseIcon } from 'src/components/icons'

const Tab = styled.div<{ itemId: string; isCurrentTab: boolean; unvisited: boolean | undefined }>`
    height: 35px;
    min-width: 70px;
    max-width: 150px;
    cursor: pointer;
    border-bottom: none;
    background: ${ifProp('isCurrentTab', palette('cloudBlueLight', 1))};
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    padding: 0 10px;

    svg path {
        &:not(.inner) {
            stroke: ${ifProp('unvisited', '#FC2F3E', '#304571')};
        }
        &.inner {
            fill: ${ifProp('unvisited', '#FC2F3E', '#304571')};
        }
    }
`

const TabText = styled.div`
    width: auto;
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    &:first-letter {
        text-transform: capitalize;
    }
`

const PopoverContent = styled.div`
    position: relative;
    max-width: 335px;
    padding: 25px 30px 25px 50px;
`

const ErrorIcon = styled(Icons.ErrorIcon)`
    position: absolute;
    left: 20px;
    margin: 0;
`

const PopoverHeader = styled.span`
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
`

const PopoverParagraph = styled.p`
    line-height: 1.7;
    margin-bottom: 0;
`

const RedText = styled.span`
    font-weight: bold;
    color: #fc2f3e;
`

const CloseButton = styled.button`
    position: absolute;
    right: 10px;
    top: 23px;
    background-color: transparent;
    border: none;
    cursor: pointer;
`

const StyledDocFileIcon = styled(Icons.WordFileIcon)`
    flex-shrink: 0;
`
const StyledImageFileIcon = styled(Icons.ImgFileIcon)`
    flex-shrink: 0;
`
const StyledPptFileIcon = styled(Icons.PptFileIcon)`
    flex-shrink: 0;
`
const StyledPdfFileIcon = styled(Icons.PdfFileIcon)`
    flex-shrink: 0;
`
const StyledDefaultFileIcon = styled(Icons.DefaultFileIcon)`
    flex-shrink: 0;
`

const ICON_TYPES: { [fileType: string]: React.FC } = {
    doc: StyledDocFileIcon,
    docx: StyledDocFileIcon,
    pdf: StyledPdfFileIcon,
    pptx: StyledPptFileIcon,
    ppt: StyledPptFileIcon,
    png: StyledImageFileIcon,
    jpg: StyledImageFileIcon,
    jpeg: StyledImageFileIcon,
}
interface TabComponentProps {
    itemId: string
    unvisited: boolean
    isCurrentTab: boolean
    isPopoverOpen: boolean
    onTabClick: () => void
    onPopoverClose: () => void
    index: number
    url: string
    fileName: string
    scrollIntoView: boolean
}

const TabComponent = (props: TabComponentProps) => {
    const {
        itemId,
        unvisited,
        isCurrentTab,
        isPopoverOpen,
        onTabClick,
        onPopoverClose,
        index,
        url,
        fileName,
        scrollIntoView,
    } = props
    const tabRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (scrollIntoView) {
            tabRef.current?.scrollIntoView()
        }
    }, [scrollIntoView])

    const renderMatchingIcon = useCallback((fileType: string) => {
        const Icon = ICON_TYPES[fileType.toLowerCase()] ?? StyledDefaultFileIcon
        return <Icon />
    }, [])

    return (
        <Popover2
            key={index + url}
            onClose={onPopoverClose}
            isOpen={isPopoverOpen}
            position="bottom"
            content={
                <PopoverContent>
                    <ErrorIcon />
                    <PopoverHeader>Please review all the files</PopoverHeader>
                    <CloseButton onClick={onPopoverClose}>
                        <CloseIcon />
                    </CloseButton>
                    <PopoverParagraph>
                        We’ve highlighted in <RedText>red</RedText> the files you’ve missed.
                        <br />
                        Review them for potential Glossary terms and submit again.
                    </PopoverParagraph>
                </PopoverContent>
            }
        >
            <Tab
                itemId={itemId}
                ref={tabRef}
                isCurrentTab={isCurrentTab}
                unvisited={unvisited}
                onClick={onTabClick}
            >
                {renderMatchingIcon(getFileExtension(url))}
                <TabText>{fileName}</TabText>
            </Tab>
        </Popover2>
    )
}

export default TabComponent
