import { ReactNode } from 'react'
import { Classes } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import * as Icons from 'src/components/icons'
import { Button } from 'src/components/Session/panels/SpeakerPanel/common'
import { AnchorButton } from 'src/components/Session/panels/SpeakerPanel/SpeakerForm/SpeakerForm'

const DialogPopover = styled(Popover2)`
    display: flex;
    flex: 1;
    overflow: hidden;

    .bp4-popover-target {
        display: flex;
        flex: 1;
        overflow: hidden;
    }
`

const DialogContainer = styled.div`
    width: 226px;
    padding: 14px 10px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: ${palette('white', 0)};
`

const DialogText = styled.div`
    position: relative;
    padding-left: 23px;
    font-size: 14px;
    color: ${palette('navy', 6)};
`

const DangerIcon = styled(Icons.DangerIcon)`
    position: absolute;
    top: -2px;
    left: 0;
    width: 18px;
    height: 18px;
    color: #ffaf00;
`

const DialogFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding-left: 23px;
`

interface WarningDialogProps {
    children: ReactNode
    isOpen: boolean
    text: string
    acceptLabel?: string
    declineLabel?: string
    onAccept?: () => void
    onDecline?: () => void
}

export const WarningDialog = ({
    children,
    isOpen,
    text,
    acceptLabel,
    declineLabel,
    onAccept,
    onDecline,
}: WarningDialogProps) => (
    <DialogPopover
        position="left"
        popoverClassName={`${Classes.POPOVER_CONTENT_SIZING} clean-popover-styles`}
        isOpen={isOpen}
        onClose={onDecline}
        lazy={true}
        content={
            <DialogContainer>
                <DialogText>
                    <DangerIcon />
                    {text}
                </DialogText>

                <DialogFooter>
                    {onAccept && <Button onClick={onAccept}>{acceptLabel}</Button>}
                    {onDecline && <AnchorButton onClick={onDecline}>{declineLabel}</AnchorButton>}
                </DialogFooter>
            </DialogContainer>
        }
    >
        {children}
    </DialogPopover>
)
