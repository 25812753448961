import { useEffect } from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'

import { Block } from 'src/components/Editor/plugins/withTranscript'

/**
 * Place the editor selection/caret at the beginning of the first editable segment on first render.
 */
export function useActiveSegmentAutoFocus() {
    const editor = useSlateStatic()

    // put the cursor in the first editable segment
    useEffect(() => {
        const [[, firstEditableBlockPath]] = Editor.nodes(editor, {
            at: [],
            match: (n) => Block.isBlock(n) && n.editable,
        })
        const startPoint = Editor.start(editor, firstEditableBlockPath)

        ReactEditor.focus(editor)
        Transforms.select(editor, { anchor: startPoint, focus: startPoint })
    }, [editor])
}
