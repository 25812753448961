import { MouseEventHandler, useCallback, useRef, useState } from 'react'
import { chakra } from '@chakra-ui/react'
import moment from 'moment'

import { useBoundingClientRect } from 'src/hooks/useBoundingClientRect'
import { useSessionStatus } from 'src/state/SessionStatusProvider'

const MediaTrackContainer = chakra('div', {
    baseStyle: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
        backgroundColor: 'cloudBlueLight.2',
    },
})

const MediaTimestamp = chakra('div', {
    baseStyle: {
        mx: 2,
    },
})

const MediaTrackBackdrop = chakra('div', {
    baseStyle: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        mx: 4,
    },
})

const MediaTrackProgressContainer = chakra('div', {
    baseStyle: {
        flex: 1,
        position: 'relative',
        width: '100%',
        height: '4px',
        backgroundColor: '#C1FDF7',
    },
})

const MediaTrackProgress = chakra('div', {
    baseStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        backgroundColor: '#40E0D0',
        willChange: 'width',
        borderRadius: '7.5px',

        _before: {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: 15,
            height: 15,
            backgroundColor: '#40E0D0',
            borderRadius: '50%',
            transform: 'translateY(-35%)',
        },
    },
})

type MediaTrackProps = {
    duration: number
    currentTime: number
    onSeekStart: () => void
    onSeekEnd: () => void
    onTrackTimeUpdate: (time: number) => void
}

export function MediaTrack({
    duration,
    currentTime,
    onSeekEnd,
    onSeekStart,
    onTrackTimeUpdate,
}: MediaTrackProps) {
    const [mouseDown, setMouseDown] = useState(false)
    const trackWrapperRef = useRef<HTMLDivElement>(null)
    const rect = useBoundingClientRect(trackWrapperRef)
    const progressPercentage = Math.min((currentTime / duration) * 100, 100)

    const onMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>(
        (e) => {
            onSeekStart()
            setMouseDown(true)
            const x = e.pageX - rect.x
            const ratio = x / (rect.width - 1)
            onTrackTimeUpdate(ratio * duration)
        },
        [onSeekStart, rect.x, rect.width, onTrackTimeUpdate, duration],
    )

    const onMouseMove = useCallback<MouseEventHandler<HTMLDivElement>>(
        (e) => {
            if (mouseDown) {
                const x = e.pageX - rect.x
                const ratio = x / (rect.width - 1)
                onTrackTimeUpdate(ratio * duration)
            }
        },
        [rect, duration, mouseDown, onTrackTimeUpdate],
    )

    const onMouseUp = useCallback(() => {
        setMouseDown(false)
        onSeekEnd()
    }, [onSeekEnd])

    const currentTimeDisplay = moment
        .utc(moment.duration({ seconds: currentTime }).asMilliseconds())
        .format('HH:mm:ss')

    const { sessionStatus } = useSessionStatus()
    const status = sessionStatus?.playbackState && sessionStatus?.playbackState.toLowerCase()
    const shouldShowDuration = status === 'stopped' ? true : false

    const durationDisplay = moment
        .utc(moment.duration({ seconds: duration }).asMilliseconds())
        .format('HH:mm:ss')

    return (
        <MediaTrackContainer>
            <MediaTimestamp>{currentTimeDisplay}</MediaTimestamp>
            <MediaTrackBackdrop
                ref={trackWrapperRef}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
            >
                <MediaTrackProgressContainer>
                    <MediaTrackProgress style={{ width: `${progressPercentage}%` }} />
                </MediaTrackProgressContainer>
            </MediaTrackBackdrop>
            <MediaTimestamp>{shouldShowDuration ? durationDisplay : '--:--'}</MediaTimestamp>
        </MediaTrackContainer>
    )
}
