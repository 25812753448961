import styled, { css } from 'styled-components/macro'
import { ifProp, palette, theme } from 'styled-tools'

export const PanelContainer = styled.div<{
    isAudioPlayerVisible: boolean
    $isRealTimeReadOnly?: boolean
}>`
    display: ${ifProp('$isRealTimeReadOnly', 'none', 'flex')};
    flex-direction: column;
    justify-content: space-between;
    height: ${ifProp(
        'isAudioPlayerVisible',
        css`calc(100% - ${theme('sizes.audioPlayerHeight')}px)`,
        '100%',
    )};
    width: 22%;
    min-width: 290px;
    max-width: 422px;
`

export const ActivePanelWrapper = styled.div`
    height: 100%;
    background-color: ${palette('white', 0)};
    border-left: solid 1px ${palette('grey', 13)};
    z-index: ${theme('zIndexes.sidePanel')};
`
