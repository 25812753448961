import { Header2 as VerbitHeader } from '@verbit-ai/verbit-ui-library'

const openSalesforceChat = () => {
    if (window.embedded_svc) {
        window.embedded_svc.bootstrapEmbeddedService()
    } else {
        console.error('Salesforce chat script not loaded yet')
    }
}

export const TopBarHelpButton = () => {
    const { Link } = VerbitHeader

    return <Link onClick={openSalesforceChat}>Help</Link>
}
