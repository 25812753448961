import { DOMNode } from 'slate-react/dist/utils/dom'
import { BaseSyntheticEvent } from 'react'

export const didEventOccurInsideTheEditorNodes = (e: BaseSyntheticEvent): boolean | null => {
    if (!e.target?.attributes) {
        return null
    }
    const attributesArr = Array.from(e.target.attributes)
    return attributesArr.some((attribute) => (attribute as DOMNode).nodeName.includes('slate'))
}

export const isAllWhitespace = (text: string): boolean => {
    return /^\s*$/.test(text)
}
