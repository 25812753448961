import { useContext, useMemo, createContext, ReactChild } from 'react'

import { Task } from 'src/models'
import { TaskRegistry } from 'src/tasks/Registry'

import { useMachineDeps } from '../useMachineDeps'
import { useAppMachine } from '../AppMachine/AppMachineProvider'
import { TaskMachineType, TaskStateSchema } from './TaskMachine'
import { EditorMode } from 'src/components/Editor/EditorContext'

export type TaskMachineState = TaskMachineType['state'] & { value: keyof TaskStateSchema['states'] }

const TaskMachineContext = createContext<{
    machine?: TaskMachineType
} | null>(null)

interface TaskMachineProviderProps {
    children: ReactChild
}

type TaskMachineProperties = 'state' | keyof TaskMachineType['state']['context']

export function TaskMachineProvider({ children }: TaskMachineProviderProps) {
    const [, , appMachine] = useAppMachine(['state'])
    const service = appMachine.children.get('worker') as TaskMachineType | undefined

    const props = useMemo(() => ({ machine: service }), [service])

    // @ts-ignore
    window.sendTaskEvent = service?.send

    return <TaskMachineContext.Provider value={props}>{children}</TaskMachineContext.Provider>
}

export function useIsTaskMachineActive() {
    const taskMachineContextProps = useContext(TaskMachineContext)
    return !!taskMachineContextProps?.machine
}

export function useTaskMachine(
    deps: TaskMachineProperties[],
): [TaskMachineState, TaskMachineType['send'], TaskMachineType] {
    const props = useContext(TaskMachineContext)
    if (!props) {
        throw new Error('You forgot to use <TaskMachineProvider />.')
    }

    if (!props.machine) {
        throw new Error(
            'TaskMachine is not active. \nYou forgot to use `useIsTaskMachineActive` to check if the machine is active.',
        )
    }

    useMachineDeps(props.machine, deps)

    return [props.machine.state as TaskMachineState, props.machine.send, props.machine]
}

export function useTask<T extends Task>(): T {
    const [taskMachineState] = useTaskMachine(['task'])
    const { task } = taskMachineState.context

    if (!task || !TaskRegistry.isTask(task)) {
        throw new Error(`There isn't a valid task rn (${JSON.stringify(task)})`)
    }

    return task as T
}

export function useTaskEditorMode(): EditorMode {
    const task = useTask<Task>()
    if (
        !task ||
        !TaskRegistry.isTask(task) ||
        (task.type !== 'transcription' && task.type !== 'onboarding')
    ) {
        throw new Error(`useEditorMode should be used only in task with type "transcription")`)
    }

    if (task.type === 'transcription') {
        const { editable } = task.payload.controls
        const isEditorInGlossersMode = !editable
        if (isEditorInGlossersMode) {
            return 'glossers'
        }
    }

    return 'basic'
}
