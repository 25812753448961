import { cloneDeep } from 'lodash/fp'
import { Operation } from 'slate'
import { Block, Content } from '../withTranscript'

export type BlockModificationsObject = {
    changedBlockIndexes: Record<number, boolean>
}

export type ChangedSegment = { startBlockIndex: number; endBlockIndex: number }

type UpdateModificationsObjectOptions = {
    isMerge?: boolean
    isSplit?: boolean
}

export function updateModificationObject(
    modifications: BlockModificationsObject | null,
    blockIndex: number,
    options: UpdateModificationsObjectOptions,
): BlockModificationsObject {
    const { isMerge, isSplit } = options

    let m = modifications ? cloneDeep(modifications) : null

    if (!m) {
        m = { changedBlockIndexes: {} }
    }

    // if the block at blockIndex is a block that was merged or split, we need to update all following block indexes by adding/subtracting 1
    if (isMerge || isSplit) {
        for (const key in m.changedBlockIndexes) {
            const otherChangedBlockIndex = parseInt(key)

            if (otherChangedBlockIndex >= blockIndex) {
                delete m.changedBlockIndexes[otherChangedBlockIndex]

                if (isMerge) {
                    m.changedBlockIndexes[Math.max(0, otherChangedBlockIndex - 1)] = true
                } else if (isSplit) {
                    m.changedBlockIndexes[otherChangedBlockIndex + 1] = true
                }
            }
        }
    }

    m.changedBlockIndexes[blockIndex] = true

    return m
}

export function trackModificationByOperation(
    modifications: BlockModificationsObject | null,
    op: Operation,
): BlockModificationsObject | null {
    switch (op.type) {
        case 'set_selection':
            break
        case 'split_node': {
            const { properties, path } = op
            if (Block.isBlock(properties)) {
                const [block] = path
                return updateModificationObject(modifications, block + 1, { isSplit: true })
            }
            break
        }
        default: {
            const { path } = op
            const [block] = path
            return updateModificationObject(modifications, block, {
                isMerge: op.type === 'merge_node' && Content.isContent(op.properties),
            })
        }
    }

    return modifications
}

export function getChangedSegments(
    modifications: BlockModificationsObject | null,
    lastBlockIndex: number,
): ChangedSegment[] {
    if (!modifications) return []

    const changedSegments: ChangedSegment[] = []

    const changedBlockIndexes = Object.keys(modifications.changedBlockIndexes || {})
        .map((index) => parseInt(index))
        .sort((a, b) => a - b)

    let pendingSegment: ChangedSegment | null = null

    for (const blockIndex of changedBlockIndexes) {
        if (!pendingSegment) {
            pendingSegment = {
                startBlockIndex: Math.max(0, blockIndex - 1),
                endBlockIndex: blockIndex,
            }
            continue
        }

        if (blockIndex <= pendingSegment.endBlockIndex + 2) {
            pendingSegment.endBlockIndex = blockIndex
        } else {
            pendingSegment.endBlockIndex = Math.min(
                lastBlockIndex,
                pendingSegment.endBlockIndex + 1,
            )
            changedSegments.push(pendingSegment)

            pendingSegment = {
                startBlockIndex: Math.max(0, blockIndex - 1),
                endBlockIndex: blockIndex,
            }
        }
    }

    if (pendingSegment) {
        pendingSegment.endBlockIndex = Math.min(lastBlockIndex, pendingSegment.endBlockIndex + 1)
        changedSegments.push(pendingSegment)
    }

    return changedSegments
}
