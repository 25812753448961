import './styles.css'

export const LoadingAnimation = () => {
    return (
        <div className="lds-ellipsis">
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}
