import { useToggle } from 'react-use'
import styled, { css } from 'styled-components/macro'
import { palette } from 'styled-tools'

import { TickIcon } from 'src/components/icons'

const Container = styled.button`
    display: flex;
    padding: 8px;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
`

const StyledTickIcon = styled(TickIcon)<{ $isChecked: boolean }>`
    path {
        transition: fill 250ms ease-out;
    }

    ${(props) =>
        !props.$isChecked &&
        css`
            path#circle {
                fill: #ccdae9;
            }

            path#tick {
                fill: ${palette('white', 0)};
            }
        `}
`

interface CheckboxProps {
    index: number
    handleChange: (index: number) => void
}

export const Checkbox = ({ handleChange, index }: CheckboxProps) => {
    const [isChecked, toggleIsChecked] = useToggle(false)

    const handleClick = () => {
        toggleIsChecked()
        handleChange(index)
    }

    return (
        <Container onClick={handleClick} data-testid={'responsibility-checkbox'}>
            <StyledTickIcon $isChecked={isChecked} />
        </Container>
    )
}
