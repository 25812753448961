import { useState, useCallback, useEffect, useContext } from 'react'

import styled from 'styled-components/macro'
import { delay } from 'lodash'
import { ifProp, palette } from 'styled-tools'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { Attachment } from 'src/models/attachment'
import { useTaskValidator } from 'src/state/TaskValidationProvider'

import TabComponent from './Tab'
import { LightArrowLeftIcon, LightArrowRightIcon } from '../icons'
import './style.css'

const Container = styled.div`
    position: relative;
    height: 35px;
    width: 100%;
`

const ArrowButton = styled.button<{ shouldBeVisible: boolean }>`
    display: ${ifProp('shouldBeVisible', 'grid', 'none')};
    background-color: ${palette('cloudBlueLight', 1)};
    place-content: center;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    height: 22px;
    width: 22px;
    &:hover {
        cursor: pointer;
    }
`

const StyledLeftArrow = styled(ArrowButton)`
    margin-right: 16px;
`
const StyledRightArrow = styled(ArrowButton)`
    margin-left: 16px;
`

const DownloadAllButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    width: 105px;
    background-color: transparent;
    height: 100%;
    border: none;
    cursor: pointer;
    color: ${palette('blue', 0)};
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    text-align: right;
`

export const getFileExtension = (filePath: string) => {
    const filename = new URL(filePath).pathname.split('/').reverse()[0]
    const extension = filename.split('.').pop()
    return extension || ''
}

interface ViewerTabBarProps {
    tabs: Attachment[]
    onTabSelect: (tab: string) => void
    setCurrentFileName: (tabName: string) => void
    onDownload?: (fileUrl: string) => unknown
}

export const ViewerTabBar = ({
    tabs,
    onTabSelect,
    setCurrentFileName,
    onDownload,
}: ViewerTabBarProps) => {
    const [unvisitedTabsIndexes, setUnvisitedTabsIndexes] = useState(() =>
        [...Array(tabs.length).keys()].slice(1),
    )
    const firstUnvisitedTab = unvisitedTabsIndexes[0]
    const [currentTabIndex, setCurrentTabIndex] = useState(!!tabs.length && 0)
    const [indicateUnvisited, setIndicateUnvisited] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [scrollIntoView, setScrollIntoView] = useState(false)
    const [doesScrollMenuInitCompleted, setDoesScrollMenuInitCompleted] = useState(false)
    const openAllAttachments = useCallback(
        () =>
            tabs?.forEach(({ url }) => {
                onDownload?.(url)
                window.open(url, '_blank', 'noopener,noreferrer')
            }),
        [onDownload, tabs],
    )

    useTaskValidator('allTabsVisited', () => {
        setScrollIntoView(true)
        setIndicateUnvisited(true)
        if (!!unvisitedTabsIndexes.length) {
            delay(() => {
                setIsPopoverOpen(true)
                setScrollIntoView(false)
            }, 250)
        }
        return !unvisitedTabsIndexes.length
    })

    useEffect(() => {
        const tabsBar = document.getElementById('tabsBar')
        const closePopover = () => setIsPopoverOpen(false)

        tabsBar?.addEventListener('scroll', closePopover)

        return () => tabsBar?.removeEventListener('scroll', closePopover)
    }, [])

    const extractTabIndex = useCallback(
        (tabIndex: number) => {
            if (!(unvisitedTabsIndexes.indexOf(tabIndex) !== -1)) return
            setUnvisitedTabsIndexes((prev) => prev.filter((tab: number) => tab !== tabIndex))
        },
        [unvisitedTabsIndexes],
    )

    const onTabClick = (tab: string, tabIndex: number, tabName: string) => {
        extractTabIndex(tabIndex)
        setCurrentTabIndex(tabIndex)
        onTabSelect(tab)
        setCurrentFileName(tabName)
    }

    return (
        <Container>
            <ScrollMenu
                wrapperClassName={'attachments-scroll-top-wrapper'}
                scrollContainerClassName={'attachments-scroll-container'}
                LeftArrow={<LeftArrow doesScrollMenuInitCompleted={doesScrollMenuInitCompleted} />}
                RightArrow={
                    <RightArrow doesScrollMenuInitCompleted={doesScrollMenuInitCompleted} />
                }
                onInit={() => setDoesScrollMenuInitCompleted(true)}
            >
                {tabs.map(({ url, name }, index) => (
                    <TabComponent
                        key={url + index}
                        itemId={String(url + index)}
                        url={url}
                        fileName={name}
                        index={index}
                        isPopoverOpen={index === firstUnvisitedTab && isPopoverOpen}
                        isCurrentTab={currentTabIndex === index}
                        unvisited={unvisitedTabsIndexes.includes(index) && indicateUnvisited}
                        onTabClick={() => onTabClick(url, index, name)}
                        scrollIntoView={scrollIntoView && index === firstUnvisitedTab}
                        onPopoverClose={() => {
                            setIsPopoverOpen(false)
                        }}
                    />
                ))}
            </ScrollMenu>
            <DownloadAllButton onClick={openAllAttachments}>Download All</DownloadAllButton>
        </Container>
    )
}

interface ArrowProps {
    doesScrollMenuInitCompleted: boolean
}

function LeftArrow({ doesScrollMenuInitCompleted }: ArrowProps) {
    const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext)
    const shouldDisplayArrow = doesScrollMenuInitCompleted && !isFirstItemVisible

    return (
        <StyledLeftArrow shouldBeVisible={shouldDisplayArrow} onClick={() => scrollPrev()}>
            <LightArrowLeftIcon />
        </StyledLeftArrow>
    )
}

function RightArrow({ doesScrollMenuInitCompleted }: ArrowProps) {
    const { isLastItemVisible, scrollNext } = useContext(VisibilityContext)
    const shouldDisplayArrow = doesScrollMenuInitCompleted && !isLastItemVisible
    return (
        <StyledRightArrow shouldBeVisible={shouldDisplayArrow} onClick={() => scrollNext()}>
            <LightArrowRightIcon />
        </StyledRightArrow>
    )
}
