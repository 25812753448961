import React from 'react'

import { TaskJSON, RestTaskJSON } from 'src/network/responses'
import { Task, TaskBase, RestTask } from 'src/models'
import { RestTask as RestTaskComponent } from 'src/components/RestTask/RestTask'

import { TaskItem } from './Registry'

class RestTaskItem extends TaskItem {
    constructor() {
        super('resting')
    }

    isTaskJSON(json: TaskJSON): json is RestTaskJSON {
        return json.type === 'resting'
    }

    isTask(task: Task): task is RestTask {
        return task.type === 'resting'
    }

    convertToTask(base: TaskBase, json: RestTaskJSON): RestTask {
        return {
            ...base,
            type: 'resting',
            payload: {
                restingDuration: json.payload.resting_time,
            },
        }
    }

    render() {
        return React.createElement(RestTaskComponent)
    }
}

const instance = new RestTaskItem()
export { instance as RestTaskItem }
