export enum SessionEndReason {
    REMOVED_BY_OPS = 'Removed by operations',
    REMOVED_BY_NEMO = 'Removed by Nemo',
    TIMED_OUT = 'Timed out',
    USER_EXIT = 'User exit',
    SESSION_ENDED = 'Session ended',
}
export enum GlossaryOpenSource {
    HEADER = 'Header',
    EDITOR_EMPTY = 'Editor empty',
    EDITOR_WITH_TEXT = 'Editor with text',
}

export enum AttachmentsDownloadSource {
    PANEL = 'Attachments Panel',
    GLOSSARY_PREPOPULATION = 'Glossary Prepopulation',
    ONBOARDING_GUIDELINES = 'Onboarding Guidelines',
    ONBOARDING_ATTACHMENTS = 'Onboarding Attachments',
}

export enum ActionTriggerSource {
    CONTEXT_MENU = 'Context Menu',
    KEYBOARD_SHORTCUT = 'Keyboard Shortcut',
}

export const USER_EMAIL = '$email'
export const USER_ID_TITLE = 'User ID'
export const TRAX_USER_ROLES_TITLE = 'TraX User Roles'
export const TRAX_USER_INITIAL_ROLE = 'TraX Initial User Role'
export const LOG_ROCKET_SESSION_URL = 'LogRocket Session'
export const SCREEN_RESOLUTION = 'Screen Resolution'
export const DEVICE_PIXEL_RATIO = 'Device Pixel Ratio'

export const SESSION_ID_TITLE = 'Session ID'
export const PLATFORM_ID_TITLE = 'Platform job ID'
export const REASON_TITLE = 'Reason'

export const TASK_ID_TITLE = 'Task ID'
export const TASK_TYPE_TITLE = 'Task Type'
export const TASK_LAYER_ID_TITLE = 'Layer'
export const ASSIGNED_AT_TITLE = 'Assigned at'
export const EDITABLE_AUDIO_LENGTH_TITLE = 'Editable audio length'
export const TASK_WORK_DURATION_TITLE = 'Time took to submit the task'
export const ALLOTTED_TIME_FOR_TASK_TITLE = 'Allotted time for task'
export const ONBOARDING_STEPS_LIST = 'Onboarding steps list'
export const CURRENT_STEP = 'Current onboarding step'
export const SPELLING_ERRORS_COUNT_START = 'Number of spelling errors when receiving a task'
export const SPELLING_ERRORS_COUNT_END = 'Number of spelling errors when submitting a task'
export const VALIDATIONS_INVALID_TERMS_COUNT_START = 'Number of triggered validations on load task'
export const VALIDATIONS_INVALID_TERMS_COUNT_END = 'Number of triggered validations on submit'
export const CURRENT_VALIDATIONS_STATUS = 'Validations json upon submit'
export const TASK_AUTO_SUBMISSION = 'Task Autosubmission?'
export const TASK_USER_IDLE_TIME = 'User Idle time in Task'
export const TASK_TIME_WAITED_FOR_ASSIGNMENT = 'Time waited from the previous task'
export const TASK_IS_FIRST_IN_LAYER = 'Is first Task from this type in this layer?'
export const TASK_AUDIO_LISTEN_TIME = 'Time listened to the audio'
export const VALIDATION_ID = 'Validation ID'
export const VALIDATION_NAME = 'Validation name'
export const VALIDATIONS_TRIGGER_STRING = 'Original trigger string'
export const VALIDATIONS_AFTER_STRING = 'String after task submission'
export const VALIDATIONS_TRIGGER_TIMING = 'Trigger timing'
export const CUSTOMER_ID = 'Customer ID'
export const STRATEGY_TITLE = 'Strategy'
export const VERTICAL_TITLE = 'Vertical'
export const TRAINING_TITLE = 'Is training'

export const ONBOARDING_TOTAL_AUDIO_LENGTH = 'Total audio length in Seconds'
export const ONBOARDING_AUDIO_LISTEN_TIME = 'Time listened to the audio in Seconds'

export const ATTACHMENTS_FILE_TYPE = 'File Type'
export const ATTACHMENTS_DOWNLOAD_SOURCE = 'Downloaded Source'

export const PUBLISH_TYPE_TITLE = 'Publish type'
export const REMAINING_TIME_ON_TIMER_TITLE = 'Remaining time on timer'

export const TERM_ID_TITLE = 'Term ID'
export const TERM_TYPE_TITLE = 'Term type'
export const TERM_IS_VERIFIED_TITLE = 'Is Term Verified'

export const GLOSSARY_TERM_TEXT_TITLE = 'Term text'
export const GLOSSARY_ORIGINAL_TRIGGER_TITLE = 'Original trigger string'
export const GLOSSARY_EVENT_SOURCE_TITLE = 'Event Source'

export const SUGGESTION_TERM_TEXT_TITLE = 'Suggestion string'
export const SUGGESTION_ORIGINAL_TRIGGER_TITLE = 'Original trigger string'

export const GLOSSARY_OPEN_SOURCE_TITLE = 'Source'

export const REPLACEMENT_SUGGESTION = 'Suggestion string'

export const KEYBOARD_SHORTCUT_ACTION = 'Action'
export const KEYBOARD_SHORTCUT_COMBO = 'Combo'

export const SELECTED_SPEED = 'Selected Speed'

export const SPEAKERS_SEPARATION_COUNT = 'Speakers separation count'

export const PRESSED_KEY_TITLE = 'Pressed Key'

export const CHAT_MESSAGE_CONTENT_TITLE = 'Content'
export const CHAT_QUOTED_MESSAGE_CONTENT_TITLE = 'Quoted Message Content'
export const CHAT_MESSAGE_ATTACHMENTS_TITLE = 'Attachments'
export const CHAT_CHANNEL_MEMBERS_TITLE = 'Users in team'
export const CHAT_CHANNEL_ONLINE_MEMBERS_TITLE = 'Users online'

export const ACTION_TRIGGER_SOURCE = 'Action Source'
