import styled from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'
import { UserIcon } from '@verbit-ai/icons-library'

export const Container = styled.div`
    /* min-width is required instead of width because this element can be empty and width doesn't apply then */
    min-width: 16px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
`

export const Line = styled.div<{
    connectToLineAbove: boolean
    isBlockEditable: boolean
    isSpeakerBoxVisible: boolean
}>`
    width: 2px;
    border-radius: 5px;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: ${palette('grey', 13)};
    flex-grow: 1;
    margin-top: ${ifProp('connectToLineAbove', '-25px', '5px')};
    margin-bottom: ${ifProp('isBlockEditable', '1px', ifProp('isSpeakerBoxVisible', '0px', '4px'))};
    transition: margin-top 125ms ease-in;
`

export const SpeakerIcon = styled(UserIcon)<{ $extraMarginTop: boolean }>`
    width: 16px;
    height: 16px;
    margin-top: ${({ $extraMarginTop }) => ($extraMarginTop ? 4 : 2)}px;
    color: ${palette('cloudBlueLight', 0)};
    transition: color 120ms ease-in-out;
`
