import { ValidationRegexObject } from './Validations'

/*
 Temporarily defined as hard coded array in this file- for POC purposes
 Will be sent from the server
 */

enum CustomersIds {
    Esquire = '2453',
    USLS = '540',
    Advanced = '1245',
    SeekingAlpha = '2639',
    OpsTestAccount = '3055',
    QAAccount = '1471',
    QAAccount2 = '2417',
    MartinOpsTestAccount = '3257',
}

const allLegalValidationRegexes: ValidationRegexObject[] = [
    {
        id: 'legal-000',
        regex: /[.?:]\s[a-z]+['"]*[A-Za-z0-9]+/,
        validationName: 'SPELL \\ capitalization \\ first letter',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                'Is the capitalization here correct? The first letter in a sentence is usually capitalized.',
            ],
        },
    },
    {
        id: 'legal-001',
        regex: /(?<=\b)([A-Z]{1}\.)+([A-Za-z]{1})+(\.)(?=\s)|^(([A-Z]{1}\.)+([A-Za-z]{1})+(\.)(?=\s))|(([A-Z]{1}\.)+([A-Za-z]{1})+(\.))$/,
        validationName: 'GUIDE \\ abbreviation \\ X.X.X. to XXX',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                "Are the dots here appropriate? Most initialisms shouldn't be separated by dots.",
                'Example: write FBI and not F.B.I.',
            ],
        },
    },
    {
        id: 'legal-002',
        regex: /\b(officer|professor|attorney|detective|sergeant|lieutenant|commander|chief)\b( [A-Z]{1}[a-z]+)/,
        validationName: 'SPELL \\ capitalization \\ ranks list',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                'Is the capitalization here appropriate? Titles and ranks should be capitalized only when followed by a capitalized name.',
                'Example: Attorney Joanna Smith.',
            ],
        },
    },
    {
        id: 'legal-003',
        regex: /((((?<![.])(([.]{2})(?![.])))|((?<![.])(([.]{4,})(?![.]))))|[,]{2,}|[?]{2,}|[!]{2,}|[:]{2,}|["]{2,}|[%]{2,}|[*]{2,}|[(]{2,}|[)]{2,})(?=\w|\b|\s|[a-z]+)/,
        validationName: 'double identical punctuation is not allowed',
        validationMessage: {
            messageTitle: 'Check and fix accordingly',
            messageSegments: [
                "There shouldn't be two identical consecutive punctuation signs. Delete one of them.",
            ],
        },
    },
    {
        id: 'legal-004',
        regex: /[?%:!".,]*((\.[!:%])|(\.[,.]([?!]))|([!:,?]\.)|(,[?!:%])|(,['",]([.?!%'"]))|([!:?],)|(\?[!.:%])|(\?['"]([.,?!%'"]))|([!:]\?)|(![?.:%])|(!['"]([.,?!%'"]))|([?:]!)|][?])[?%:"!.,]*/,
        validationName: 'PUNCT \\ double punct \\ not identical punctuation',
        validationMessage: {
            messageTitle: 'Check and fix accordingly.',
            messageSegments: [
                "There shouldn't be two consecutive punctuation signs. Delete the unnecessary sign.",
            ],
        },
    },
    {
        id: 'legal-005',
        regex: /[A-Z][a-z]+[,]* [jJ]unior/,
        validationName: 'GUIDE \\ abbreviation \\ Jr.',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                'Is this referring to the generational suffix of "junior"? If so it should be written as "Jr."',
                'Generational suffix: My name is John Smith Jr.',
                "General use: I'm a junior in college.",
            ],
        },
    },
    {
        id: 'legal-006',
        regex: /\b[A-Z][a-z]+[,]* [sS]enior\b/,
        validationName: 'GUIDE \\ abbreviation \\ Sr.',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                'Is this referring to the generational suffix of "senior"? If so it should be written as "Sr."',
                'Generational suffix: My name is John Smith Sr.',
                "General use: I'm a senior in college.",
            ],
        },
    },
    {
        id: 'legal-007',
        regex: /\b[A-Za-z]+[A-Z]+[\w'"]*\b/,
        validationName: 'SPELL \\ typo \\ capital letter',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                "Is the capitalization here correct? Typically, letters in the middle of a word shouldn't be capitalized.",
                'Exceptions: iPhone, eBay.',
            ],
        },
    },
]

const earningCallsValidationRegexes: ValidationRegexObject[] = [
    {
        id: 'ec-000',
        regex: /\b\d+\s?percent\b/i,
        validationName: 'GUIDE \\ abbreviation \\Percent to %',
        validationMessage: {
            messageTitle: '"Check and fix if needed',
            messageSegments: [
                'Does this directly follow a number? If so, it should be written as "%".',
                'Symbol: The company paid a 40% tax.',
                'Word: A large percent of our investors are over 40 years old."',
            ],
        },
    },
    {
        id: 'ec-001',
        regex: /\b\d+\s?dollar\b/i,
        validationName: 'GUIDE \\ abbreviation \\Dollar to $ ',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                'Is this adjacent to a number? If so, it should be written as "$".',
                'Symbol: It costs $25.',
                'Word: The euro is at a 25 year high against the dollar."',
            ],
        },
    },
    {
        id: 'ec-002',
        regex: /\bUS\b/,
        validationName: 'GUIDE \\ abbreviation \\ US to U.S.',
        validationMessage: {
            messageTitle: 'Check and fix if needed',
            messageSegments: [
                'Is this referring to the country United States? If so, it should be be separated by dots and written as "U.S."',
            ],
        },
    },
    {
        id: 'ec-003',
        regex: /\bet cetera\b/i,
        validationName: 'GUIDE \\ abbreviation \\ et cetera to etc.',
        validationMessage: {
            messageTitle: 'Check and fix accordingly',
            messageSegments: ['This should be abbreviated and written as "etc."'],
        },
    },
]

/** Legal customers */
const esquireValidationsRegexes: ValidationRegexObject[] = [...allLegalValidationRegexes]
const uslsValidationsRegexes: ValidationRegexObject[] = [...allLegalValidationRegexes]
const advancedValidationsRegexes: ValidationRegexObject[] = [...allLegalValidationRegexes]

/** Earning Calls customers */
const seekingAlphaValidationsRegexes: ValidationRegexObject[] = [...earningCallsValidationRegexes]

export const getValidationsRegexesAccordingToCustomerId = (
    customerId: string | undefined,
): ValidationRegexObject[] => {
    switch (customerId) {
        case CustomersIds.Esquire:
            return esquireValidationsRegexes
        case CustomersIds.USLS:
            return uslsValidationsRegexes
        case CustomersIds.Advanced:
            return advancedValidationsRegexes
        case CustomersIds.SeekingAlpha:
            return seekingAlphaValidationsRegexes
        case CustomersIds.OpsTestAccount:
            return earningCallsValidationRegexes
        case CustomersIds.QAAccount:
            return allLegalValidationRegexes
        case CustomersIds.QAAccount2:
            return allLegalValidationRegexes
        case CustomersIds.MartinOpsTestAccount:
            return earningCallsValidationRegexes
        default:
            return []
    }
}
