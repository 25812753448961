import { DictionaryCache, DictionaryOptions, RawDictionary } from './SpellcheckerAPI'

//@ts-ignore
import enAffPath from 'dictionary-en/index.aff'
//@ts-ignore
import enDicPath from 'dictionary-en/index.dic'

type PartialRecord<K extends string | number | symbol, T> = {
    [P in K]?: T
}

async function loadDictionary(dictionary: DictionaryOptions): Promise<RawDictionary> {
    switch (dictionary) {
        case 'en':
        default: {
            return {
                aff: await (await fetch(enAffPath)).text(),
                dic: await (await fetch(enDicPath)).text(),
            }
        }
    }
}

export class DictionaryHTTPCache implements DictionaryCache {
    private cache: PartialRecord<DictionaryOptions, RawDictionary> = {}

    async load(dictionary: DictionaryOptions): Promise<RawDictionary> {
        if (this.cache[dictionary]) {
            return this.cache[dictionary]!
        }

        const dic = await loadDictionary(dictionary)

        this.cache[dictionary] = dic
        return dic
    }
}

export const DictionaryHTTPCacheInstance = new DictionaryHTTPCache()
