import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { GlossaryTerm, GlossaryTermCategory } from 'src/models'
import * as Styled from './styled'
import { Row } from './Row'
import { Submenu } from './GlossarySubmenu'
import {
    UserIcon,
    LocationIcon,
    ProfessionalTermIcon,
    TrashIcon,
    EditIcon,
} from '@verbit-ai/icons-library'

interface GlossaryItemProps {
    term: GlossaryTerm
    index: number
    active: boolean
    expanded: boolean
    innerRef?: (el: HTMLElement | null) => void
    onMouseOver?: (term: GlossaryTerm, index: number) => void
    onClick?: (term: GlossaryTerm, index: number) => void
    onEditSelected?: (term: GlossaryTerm, index: number) => void
    onDeleteSelected?: (term: GlossaryTerm, index: number) => void
    submenuProps?: Styled.SubmenuProps
    className?: string
}

export function getTermCategoryIcon(
    category: GlossaryTermCategory,
    options: { size?: number; color?: string } = {},
) {
    const { size = 16, color } = options

    switch (category) {
        case 'person':
            return <UserIcon width={size} height={size} color={color} />
        case 'place':
            return <LocationIcon width={size} height={size} color={color} />
        case 'term':
            return <ProfessionalTermIcon width={size} height={size} color={color} />
    }
}

export const GlossaryItem = ({
    term,
    index,
    active,
    expanded,
    innerRef,
    onMouseOver,
    onClick,
    onEditSelected,
    onDeleteSelected,
    submenuProps,
    className,
}: GlossaryItemProps) => {
    const handleMouseOver = useCallback(() => {
        onMouseOver?.(term, index)
    }, [term, index, onMouseOver])

    const handleClick = useCallback(() => {
        onClick?.(term, index)
    }, [term, index, onClick])

    const actionItems = useMemo(
        () => [
            {
                text: 'Delete',
                icon: <TrashIcon />,
                onClick: () => onDeleteSelected?.(term, index),
            },
            {
                text: 'Edit',
                icon: <EditIcon />,
                onClick: () => onEditSelected?.(term, index),
            },
        ],
        [onEditSelected, onDeleteSelected, term, index],
    )

    return (
        <Row
            text={term.text}
            icon={getTermCategoryIcon(term.category)}
            active={active}
            verified={term.verified}
            expanded={expanded}
            subMenu={submenuProps && <Submenu items={actionItems} submenuProps={submenuProps} />}
            visibleOnRowHoverChildren={
                !submenuProps && (
                    <ActionItems>
                        {actionItems.map(({ icon, onClick }, i) => (
                            <ActionItem key={i} onClick={onClick}>
                                {icon}
                            </ActionItem>
                        ))}
                    </ActionItems>
                )
            }
            innerRef={innerRef}
            onClick={handleClick}
            onMouseOver={handleMouseOver}
            className={className}
        />
    )
}

const ActionItems = styled.div`
    display: flex;
`

const ActionItem = styled.div`
    cursor: pointer;
    transition: color 250ms ease-out;

    &:hover {
        color: ${palette('blue', 0)};
    }

    &:not(:last-of-type) {
        margin-right: 10px;
    }

    svg {
        width: 16px;
        height: 16px;
    }
`
