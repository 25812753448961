import { useDrop } from 'react-dnd'

import { VideoPlayerDragType } from 'src/components/VideoPlayer/VideoPlayer'
import { useWindowSize, WindowSize } from 'src/hooks/useWindowSize'
import { useVideo } from 'src/state/VideoProvider'
import { VideoResizeEdge, VideoPlayerResizeType } from './DraggableVideoPlayer'

/**
 *  Check if video's position is bigger then min & max allowed positions.
 *  if it is - set the position of the video to edges of screen,
 *  if it isn't - set the video position to current offset
 *
 *  @returns drop ref
 */

export function useVideoAbsoluteDropZone() {
    const { videoDimensions, moveVideoTo, calculateResizedVideoDimensions } = useVideo()
    const windowSize: WindowSize = useWindowSize()
    const { width: windowWidth, height: windowHeight } = windowSize

    const [, drop] = useDrop(
        () => ({
            accept: [VideoPlayerDragType, VideoPlayerResizeType],
            drop(_, monitor) {
                const itemType = monitor.getItemType()
                const item = monitor.getItem<{ edge: VideoResizeEdge } | null>()
                const currentOffset = monitor.getSourceClientOffset()
                const currentOffsetX = Math.round(currentOffset?.x ?? 0)
                const currentOffsetY = Math.round(currentOffset?.y ?? 0)

                const maxXPosition = windowWidth - videoDimensions.width
                const maxYPosition = windowHeight - 41 - videoDimensions.height

                switch (itemType) {
                    case VideoPlayerDragType: {
                        let x =
                            currentOffsetX < 0
                                ? 0
                                : currentOffsetX > maxXPosition
                                ? maxXPosition
                                : currentOffsetX
                        let y =
                            currentOffsetY < 0
                                ? 0
                                : currentOffsetY > maxYPosition
                                ? maxYPosition
                                : currentOffsetY
                        moveVideoTo({ x, y })
                        break
                    }
                    case VideoPlayerResizeType: {
                        if (item?.edge) {
                            const { coords, dimension } = calculateResizedVideoDimensions(
                                { x: currentOffsetX, y: currentOffsetY },
                                item.edge,
                            )

                            moveVideoTo(coords, dimension)
                        }
                        break
                    }
                }
                return undefined
            },
        }),
        [moveVideoTo, calculateResizedVideoDimensions, windowSize, videoDimensions],
    )
    return drop
}
