import { useEffect } from 'react'
import { Editor } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { usePrevious } from 'src/hooks/usePrevious'
import { Task } from 'src/models'
import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { Block } from '../Block'

const CONTEXT_PART_MIN_VISIBLE_HEIGHT = 150

/**
 * This will autoscroll the wrapper of the editor so the first editable segment can be seen on the screen. It does nothing
 * if the first editable segment can already be seen.
 * @param scrollingWrapperRef ref to the scrolling wrapper
 */
export function useEditableBlockAutoScroll(scrollingWrapperRef: React.RefObject<HTMLElement>) {
    const editor = useSlateStatic()
    const task = useTask<Task>()
    const previousTaskId = usePrevious(task.id)

    useEffect(() => {
        if (task.id !== previousTaskId) {
            if (!scrollingWrapperRef.current) {
                return
            }

            scrollingWrapperRef.current.scrollTo({ top: 0 })

            const blockEntries = Array.from(
                Editor.nodes(editor, {
                    at: [],
                    mode: 'highest',
                    match: (node) => Block.isBlock(node) && node.editable,
                }),
            )

            if (!blockEntries.length) {
                return
            }

            const [firstEditableBlock] = blockEntries[0]
            const [lastEditableBlock] = blockEntries[blockEntries.length - 1]

            const firstEditableBlockDomNode = ReactEditor.toDOMNode(editor, firstEditableBlock)
            const lastEditableBlockDomNode = ReactEditor.toDOMNode(editor, lastEditableBlock)

            const scrollingWrapperRect = scrollingWrapperRef.current.getBoundingClientRect()
            const firstEditableBlockRect = firstEditableBlockDomNode.getBoundingClientRect()
            const lastEditableBlockRect = lastEditableBlockDomNode.getBoundingClientRect()

            /** if the top of the first editable block is after the middle of the scrolling container height (after 50% of it's height),
             *  and the bottom of the last editable block is greater than the scrolling container height (it means we are party or fully invisible) */
            if (
                firstEditableBlockRect.top >
                    scrollingWrapperRect.top + scrollingWrapperRect.height / 2 &&
                lastEditableBlockRect.bottom > scrollingWrapperRect.bottom - 50
            ) {
                const relativeTop = firstEditableBlockRect.top - scrollingWrapperRect.top

                scrollingWrapperRef.current.scrollTo({
                    top: relativeTop - CONTEXT_PART_MIN_VISIBLE_HEIGHT,
                })
            }
        }
    }, [task.id, previousTaskId, editor, scrollingWrapperRef])
}
