import {
    ReactNode,
    DependencyList,
    useCallback,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react'

import { uuid as generateUuid } from 'src/utils/uuid'

interface ContextInterface<T extends Function> {
    isAllowed: (uuid: string) => boolean
    register: (uuid: string, fn: T) => void
    unregister: (uuid: string) => void
    callback?: T
}

interface CallbackRegistryProviderProps {
    children: ReactNode
}

interface UniqueCallbackRegistryOptions {
    enableNotRegisteredError?: boolean
    notRegisteredError?: string
    registerTimeoutMs?: number
}

export const createUniqueCallbackRegistry = <T extends Function>(
    options: UniqueCallbackRegistryOptions = {},
): [
    (props: CallbackRegistryProviderProps) => JSX.Element,
    (fn: T, deps: DependencyList) => void,
    () => T | undefined,
] => {
    const {
        enableNotRegisteredError = true,
        registerTimeoutMs = 2000,
        notRegisteredError = `No callback registered after ${registerTimeoutMs}ms`,
    } = options

    const CallbackRegistryContext = createContext<ContextInterface<T>>({
        isAllowed: () => false,
        register: () => {},
        unregister: () => {},
    })

    const CallbackRegistryProvider = ({ children }: CallbackRegistryProviderProps) => {
        const [rerenderDep, rerender] = useReducer((x) => x + 1, 0)
        const registeredUuid = useRef<string>()
        const callbackRef = useRef<T>()

        const isAllowed = useCallback(
            (uuid: string) => {
                return !registeredUuid.current || registeredUuid.current === uuid
            },
            [registeredUuid],
        )

        const register = useCallback(
            (uuid: string, fn: T) => {
                if (!isAllowed(uuid)) {
                    return
                }

                registeredUuid.current = uuid
                callbackRef.current = fn
                rerender()
            },
            [isAllowed],
        )
        const unregister = useCallback(
            (uuid: string) => {
                if (!isAllowed(uuid)) {
                    return
                }

                registeredUuid.current = undefined
                callbackRef.current = undefined
                rerender()
            },
            [isAllowed],
        )

        const ctx = useMemo<ContextInterface<T>>(
            () => ({
                register,
                isAllowed,
                unregister,
                callback: callbackRef.current,
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [isAllowed, register, unregister, rerenderDep],
        )

        useEffect(() => {
            if (enableNotRegisteredError) {
                const timeout = setTimeout(() => {
                    // if the id "test" is allowed, no body else registered
                    if (isAllowed('test')) {
                        throw new Error(notRegisteredError)
                    }
                }, registerTimeoutMs)
                return () => clearTimeout(timeout)
            }
        }, [isAllowed])

        return (
            <CallbackRegistryContext.Provider value={ctx}>
                {children}
            </CallbackRegistryContext.Provider>
        )
    }

    /**
     * This hook is used to register a callback to a context to be used by a component higher up in the tree
     * @param fn callback to-be-registered
     * @param deps dependency values to update the function reference
     */
    const useCallbackRegistry = (fn: T, deps: DependencyList) => {
        const [uuid] = useState(generateUuid())
        const { register, isAllowed, unregister } = useContext(CallbackRegistryContext)

        useEffect(() => {
            if (!isAllowed(uuid)) {
                throw new Error(`usePublish() is used more than once and this one lost.`)
            }
            register(uuid, fn)
            return () => unregister(uuid)

            // we also want to re-run this whenever the deps of the user changes
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isAllowed, register, unregister, uuid, ...deps])
    }

    /**
     * This hook is used to consume the callback currently registered in the context
     */
    const useRegisteredCallback = () => {
        const { callback } = useContext(CallbackRegistryContext)

        return callback
    }

    return [CallbackRegistryProvider, useCallbackRegistry, useRegisteredCallback]
}
