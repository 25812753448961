import React, { useEffect, useReducer } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { VideoPlayer, VideoPlayerDragType } from 'src/components/VideoPlayer/VideoPlayer'
import { useVideo } from 'src/state/VideoProvider'
import styled, { css } from 'styled-components/macro'
import { ifProp, switchProp, theme } from 'styled-tools'

export type VideoResizeEdge = 'top_left' | 'top_right' | 'bottom_left' | 'bottom_right'

export const VideoPlayerResizeType = 'VIDEO_RESIZE'

const ResizeDraggableCornerElement = styled.div<{ edge: VideoResizeEdge; isDragging?: boolean }>`
    display: ${ifProp('isDragging', 'none', 'inherit')};
    position: absolute;

    ${switchProp('edge', {
        top_left: css`
            top: -5px;
            left: -5px;

            cursor: nw-resize;
        `,
        top_right: css`
            top: -5px;
            right: -5px;

            cursor: ne-resize;
        `,
        bottom_left: css`
            bottom: -5px;
            left: -5px;

            cursor: sw-resize;
        `,
        bottom_right: css`
            bottom: -5px;
            right: -5px;

            cursor: se-resize;
        `,
    })}

    z-index: ${theme('zIndexes.videoPlayerResizeable')};
    width: 20px;
    height: 20px;
    background: transparent;
`

interface DraggableVideoPlayerProps {
    resizeable?: boolean
    width?: number
    height?: number
}

export function DraggableVideoPlayer({ resizeable, width, height }: DraggableVideoPlayerProps) {
    const { videoDimensions } = useVideo()
    const [key, update] = useReducer((x) => x + 1, 0)

    const [{ isDragging }, dragRef, preview] = useDrag({
        type: VideoPlayerDragType,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isDragging) {
            update()
        }
    }, [isDragging])

    return (
        <>
            <VideoPlayer
                key={key}
                ref={dragRef}
                width={width ?? videoDimensions.width}
                height={height ?? videoDimensions.height}
            />
            {resizeable && (
                <>
                    <ResizeDraggableCorner edge="top_left" onDragEnd={update} />
                    <ResizeDraggableCorner edge="top_right" onDragEnd={update} />
                    <ResizeDraggableCorner edge="bottom_left" onDragEnd={update} />
                    <ResizeDraggableCorner edge="bottom_right" onDragEnd={update} />
                </>
            )}
        </>
    )
}

interface ResizeDraggableCornerProps {
    edge: VideoResizeEdge
    onDragEnd: () => void
}
function ResizeDraggableCorner({ edge, onDragEnd }: ResizeDraggableCornerProps) {
    const [{ isDragging }, dragRef] = useDrag({
        type: VideoPlayerResizeType,
        item: { edge },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    useEffect(() => {
        if (!isDragging) {
            onDragEnd()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDragging])

    return <ResizeDraggableCornerElement ref={dragRef} edge={edge} />
}
