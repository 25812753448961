import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { XYCoord } from 'react-dnd'
import { VideoResizeEdge } from 'src/components/VideoPlayer/DraggableVideoPlayer'
import { useSessionMedia } from './SessionMediaProvider'

export const VIDEO_DEFAULT_DIMENSION = 400
export const VIDEO_MIN_DIMENSION = 100

export interface VideoDimension {
    width: number
    height: number
}

interface VideoContextValue {
    isVideoSnappedIntoPosition: boolean
    setIsVideoSnappedIntoPosition: (value: boolean) => void
    isValidVideo: boolean
    videoDimensions: VideoDimension
    minVideoDimensions: VideoDimension
    videoCoords: XYCoord
    setVideoCoords: (coords: XYCoord) => void
    setVideoDimensions: (videoDimension: VideoDimension) => void
    videoAspectRatio: number
    calculateVideoDimensions: (axis: 'width' | 'height', maxPx: number) => number
    calculateResizedVideoDimensions: (
        cursorCoords: XYCoord,
        resizeEdge: VideoResizeEdge,
    ) => { coords: XYCoord; dimension: VideoDimension }
    moveVideoTo: (coords: XYCoord, dimension?: VideoDimension) => void
}

const VideoContext = createContext<VideoContextValue | null>(null)

interface VideoProviderProps {
    children: ReactNode
}

export const VideoProvider = ({ children }: VideoProviderProps) => {
    const { mediaRef } = useSessionMedia(['mediaRef'])
    const [isVideoSnappedIntoPosition, setIsVideoSnappedIntoPosition] = useState(true)
    const [isValidVideo, setIsValidVideo] = useState(false)
    const [videoDimensions, setVideoDimensions] = useState<VideoDimension>({ width: 0, height: 0 })
    const [minVideoDimensions, setMinVideoDimensions] = useState<VideoDimension>({
        width: 0,
        height: 0,
    })
    const [videoCoords, setVideoCoords] = useState<XYCoord>({ x: 0, y: 0 })
    const [videoAspectRatio, setVideoAspectRatio] = useState(1)

    const wasInitializedRef = useRef(false)
    useEffect(() => {
        const videoEl = mediaRef.current
        if (videoEl) {
            videoEl.addEventListener('canplay', function () {
                //@ts-ignore
                if (!wasInitializedRef.current && videoEl.videoHeight > 0) {
                    wasInitializedRef.current = true
                    setIsValidVideo(true)

                    //@ts-ignore
                    const aspectRatio = videoEl.videoWidth / videoEl.videoHeight
                    setVideoAspectRatio(aspectRatio)

                    // set standard video dimensions with VIDEO_DEFAULT_DIMENSION px max on the longer axis depending on the aspect ratio
                    setVideoDimensions({
                        width:
                            aspectRatio > 1
                                ? VIDEO_DEFAULT_DIMENSION
                                : VIDEO_DEFAULT_DIMENSION * aspectRatio,
                        height:
                            aspectRatio > 1
                                ? VIDEO_DEFAULT_DIMENSION / aspectRatio
                                : VIDEO_DEFAULT_DIMENSION,
                    })

                    // set minimum video dimensions with VIDEO_MIN_DIMENSION px max on the longer axis depending on the aspect ratio
                    setMinVideoDimensions({
                        width:
                            aspectRatio > 1
                                ? VIDEO_MIN_DIMENSION
                                : VIDEO_MIN_DIMENSION * aspectRatio,
                        height:
                            aspectRatio > 1
                                ? VIDEO_MIN_DIMENSION / aspectRatio
                                : VIDEO_MIN_DIMENSION,
                    })
                }
            })
        }
    }, [mediaRef, setIsValidVideo])

    /**
     * Calculates the dimension for an `axis` using the maxPx for the counter-axis and the aspect-ratio of the video.
     * Example: `calculateVideoDimensions('height', 400)` calculates the height given 400px max-width.
     */
    const calculateVideoDimensions = useCallback(
        (axis: 'width' | 'height', maxPx: number) => {
            switch (axis) {
                case 'height': {
                    return Math.min(maxPx / videoAspectRatio, maxPx)
                }
                case 'width': {
                    return Math.min(maxPx * videoAspectRatio, maxPx)
                }
            }
        },
        [videoAspectRatio],
    )

    const calculateResizedVideoDimensions = useCallback(
        (
            cursorCoords: XYCoord,
            resizeEdge: VideoResizeEdge,
        ): { coords: XYCoord; dimension: VideoDimension } => {
            let width = videoDimensions.width
            let height = videoDimensions.height
            let x = videoCoords.x
            let y = videoCoords.y

            switch (resizeEdge) {
                case 'top_left': {
                    // bottom_right corner
                    const anchorCoords: XYCoord = {
                        x: videoCoords.x + width,
                        y: videoCoords.y + height,
                    }

                    width = Math.max(anchorCoords.x - cursorCoords.x, minVideoDimensions.width)
                    height = Math.max(anchorCoords.y - cursorCoords.y, minVideoDimensions.height)

                    width = videoAspectRatio > 1 ? width : height * videoAspectRatio
                    height = videoAspectRatio > 1 ? width / videoAspectRatio : height

                    x = anchorCoords.x - width
                    y = anchorCoords.y - height
                    break
                }
                case 'top_right': {
                    // bottom_left corner
                    const anchorCoords: XYCoord = {
                        x: videoCoords.x,
                        y: videoCoords.y + height,
                    }

                    width = Math.max(cursorCoords.x - anchorCoords.x, minVideoDimensions.width)
                    height = Math.max(anchorCoords.y - cursorCoords.y, minVideoDimensions.height)

                    width = videoAspectRatio > 1 ? width : height * videoAspectRatio
                    height = videoAspectRatio > 1 ? width / videoAspectRatio : height

                    x = anchorCoords.x
                    y = anchorCoords.y - height
                    break
                }
                case 'bottom_left': {
                    // top_right corner
                    const anchorCoords: XYCoord = {
                        x: videoCoords.x + width,
                        y: videoCoords.y,
                    }

                    width = Math.max(anchorCoords.x - cursorCoords.x, minVideoDimensions.width)
                    height = Math.max(cursorCoords.y - anchorCoords.y, minVideoDimensions.height)

                    width = videoAspectRatio > 1 ? width : height * videoAspectRatio
                    height = videoAspectRatio > 1 ? width / videoAspectRatio : height

                    x = anchorCoords.x - width
                    y = anchorCoords.y
                    break
                }
                case 'bottom_right': {
                    // videoCoords are the anchor

                    width = Math.max(cursorCoords.x - videoCoords.x, minVideoDimensions.width)
                    height = Math.max(cursorCoords.y - videoCoords.y, minVideoDimensions.height)

                    width = videoAspectRatio > 1 ? width : height * videoAspectRatio
                    height = videoAspectRatio > 1 ? width / videoAspectRatio : height
                    break
                }
            }

            return { coords: { x, y }, dimension: { width, height } }
        },
        [videoCoords, videoDimensions, minVideoDimensions, videoAspectRatio],
    )

    const moveVideoTo = useCallback(
        (coords: XYCoord, dimension: VideoDimension = videoDimensions) => {
            setVideoCoords(coords)
            setVideoDimensions(dimension)
            setIsVideoSnappedIntoPosition(false)
        },
        [setIsVideoSnappedIntoPosition, setVideoCoords, setVideoDimensions, videoDimensions],
    )

    const contextValue = useMemo<VideoContextValue>(
        () => ({
            isVideoSnappedIntoPosition,
            setIsVideoSnappedIntoPosition,
            isValidVideo,
            videoDimensions,
            setVideoDimensions,
            minVideoDimensions,
            videoCoords,
            setVideoCoords,
            videoAspectRatio,
            calculateVideoDimensions,
            calculateResizedVideoDimensions,
            moveVideoTo,
        }),
        [
            calculateResizedVideoDimensions,
            calculateVideoDimensions,
            isValidVideo,
            isVideoSnappedIntoPosition,
            minVideoDimensions,
            moveVideoTo,
            videoAspectRatio,
            videoCoords,
            videoDimensions,
        ],
    )

    return <VideoContext.Provider value={contextValue}>{children}</VideoContext.Provider>
}

export const useVideo = () => {
    const videoContextValue = useContext(VideoContext)

    if (!videoContextValue) {
        throw new Error('You have forgot to use VideoContext, shame on you.')
    }

    return videoContextValue
}
