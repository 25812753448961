import { useCallback, useMemo, RefObject, MouseEvent, KeyboardEvent } from 'react'
import { Node, NodeEntry, Range } from 'slate'
import {
    Editable,
    ReactEditor,
    RenderElementProps,
    RenderLeafProps,
    useSlateStatic,
} from 'slate-react'
import { useEvent } from 'react-use'
import LogRocket from 'logrocket'

import { ContextMenu } from '../ContextMenu/ContextMenu'
import { useEditorPopup } from './EditorContext'

interface EditorProps {
    editorRef?: RefObject<HTMLDivElement>
    readOnly?: boolean
    onClick?: (event: MouseEvent) => void
    onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void
}

export function TextEditor({ editorRef, ...props }: EditorProps) {
    const editor = useSlateStatic()
    const { popupRange, popupId, closePopup, openPopup } = useEditorPopup()

    useEvent(
        'keydown',
        ({ key, keyCode, code, altKey, metaKey, ctrlKey, shiftKey }: KeyboardEvent) => {
            LogRocket.log(Date.now(), 'keydown', {
                key,
                keyCode,
                code,
                altKey,
                metaKey,
                ctrlKey,
                shiftKey,
            })
        },
        undefined,
        { capture: true },
    )

    useEvent(
        'beforeinput',
        ({ inputType, data, isComposing }: InputEvent) => {
            LogRocket.log(Date.now(), 'beforeinput', { inputType, data, isComposing })
        },
        undefined,
        { capture: true },
    )

    // @ts-ignore
    window.editor = editor

    // IdanH: Commenting out onclick validations handling, as an optimization, because validations is not enabled.
    // const onClick = useCallback(
    //     (e: React.SyntheticEvent) => {
    //         e.preventDefault()
    //         if (!editor.selection || !didEventOccurInsideTheEditorNodes(e)) return
    //         const eventRange = ReactEditor.findEventRange(editor, e)
    //         const [node, path] = Editor.node(editor, eventRange)
    //         if (
    //             Text.isText(node) &&
    //             Validations.isInvalidValidation(node) &&
    //             Range.isCollapsed(editor.selection)
    //         ) {
    //             const sanatizedRange = Block.getSanitizedRange(editor, { at: path })
    //             if (Range.isRange(sanatizedRange)) {
    //                 openPopup('validation', sanatizedRange)
    //             }
    //         }
    //     },
    //     [editor, openPopup],
    // )

    const onOpenContextMenu = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()

            try {
                /** this range is always collapsed on right click event */
                const eventRange = ReactEditor.findEventRange(editor, e)

                /** if there is no current selection, or if the point where the user right clicked is outside of that selection,
                 *  we need to explicitly select the word that the user right clicked at */
                openPopup(
                    'context',
                    !editor.selection || !Range.includes(editor.selection, eventRange.anchor)
                        ? eventRange
                        : editor.selection,
                )
            } catch (e) {}
        },
        [editor, openPopup],
    )

    const renderPlaceholder = useCallback(() => <div />, [])

    const renderElement = useCallback(
        (props: RenderElementProps) => editor.renderElement(props),
        [editor],
    )
    const renderLeaf = useCallback((props: RenderLeafProps) => editor.renderLeaf(props), [editor])
    const decorate = useCallback((entry: NodeEntry<Node>) => editor.decorate(entry), [editor])

    return (
        <div ref={editorRef} tabIndex={-1}>
            {editor.renderEditor()}
            <ContextMenu
                isOpen={!!popupRange && popupId === 'context'}
                currentRange={popupRange}
                onClose={closePopup}
            />
            {/* Important! we need this div wrapper to support intersection observer */}
            <div>
                <Editable
                    {...props}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    decorate={decorate}
                    renderPlaceholder={renderPlaceholder}
                    // onClick={onClick} - Validations are not active
                    onContextMenu={onOpenContextMenu}
                    spellCheck={false}
                    style={useMemo(
                        () => ({
                            position: 'relative',
                            paddingBottom: 20,
                            outline: 'none',
                        }),
                        [],
                    )}
                    tabIndex={-1}
                />
            </div>
        </div>
    )
}
