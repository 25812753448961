import { MutableRefObject, RefObject, useEffect, useState } from 'react'
import useWindowResize from 'beautiful-react-hooks/useWindowResize'
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver'
import useThrottledCallback from 'beautiful-react-hooks/useThrottledCallback'

export const useBoundingClientRect = (elemRef: RefObject<HTMLElement>) => {
    const [boundingClientRect, setBoundingClientRect] = useState(new DOMRect())

    const updateRect = useThrottledCallback(
        () => {
            if (elemRef.current) {
                setBoundingClientRect(elemRef.current.getBoundingClientRect())
            }
        },
        [],
        1000 / 60,
    )

    const onWindowResize = useWindowResize()
    onWindowResize(updateRect)

    const staticRect = useResizeObserver(elemRef as MutableRefObject<HTMLElement>)
    useEffect(() => {
        if (elemRef.current) {
            setBoundingClientRect(elemRef.current.getBoundingClientRect())
        }
    }, [elemRef, staticRect?.width, staticRect?.height])

    useEffect(() => {
        return () => updateRect.cancel()
    }, [updateRect])

    return boundingClientRect
}
