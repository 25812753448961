import { useState, useCallback, useRef, MouseEventHandler } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { TimingRange } from 'src/utils/range'
import { useBoundingClientRect } from 'src/hooks/useBoundingClientRect'

import { UnplayedTimeRanges } from './UnplayedTimeRanges'
import { UnplayedRequiredTimeRangesWarningTooltip } from './UnplayedRequiredTimeRangesWarningTooltip'
import { secondsToHHMMSS } from 'src/utils/time'
import { MediaTimingInfo } from 'src/factories/MediaService'

const TrackWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    background-color: ${palette('cloudBlueLight', 2)};
`

interface TrackProgressProps {
    progressPercentage: number
}

const TrackProgress = styled.div.attrs<TrackProgressProps>((props) => ({
    style: {
        width: `${props.progressPercentage}%`,
    },
}))<TrackProgressProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(30, 30, 30, 0.2);
`

const TimeStamp = styled.span`
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 1em;
    text-align: center;
    color: rgb(48, 68, 113);
    z-index: 10;
    font-weight: 500;
    padding: 0 0.5em;
`

const TrackActiveSegment = styled.div<{ start: number; width: number }>`
    position: absolute;
    top: 0;
    left: ${({ start }) => start}%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width}%;
    height: 100%;
    background-color: ${palette('turquoise', 2)};

    &:before,
    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: ${palette('cloudBlue', 0)};
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
    }
`

interface TrackActiveSegmentProgressProps {
    start: number
    progressPercentage: number
}

const TrackActiveSegmentProgress = styled.div.attrs<TrackActiveSegmentProgressProps>((props) => ({
    style: {
        width: `${props.progressPercentage}%`,
    },
}))<TrackActiveSegmentProgressProps>`
    position: absolute;
    top: 0;
    left: ${({ start }) => start}%;
    height: 100%;
    background-color: ${palette('turquoise', 0)};
    will-change: width;
`

interface TrackThumbProps {
    currentTimePercentage: number
}

const TrackThumb = styled.div.attrs<TrackThumbProps>((props) => ({
    style: {
        left: `${props.currentTimePercentage}%`,
    },
}))<TrackThumbProps>`
    position: absolute;
    z-index: 1;
    width: 4px;
    height: 100%;
    top: 0;
    will-change: left;
    background-color: ${palette('cloudBlue', 0)};
`

interface AudioSegment {
    color: string
    timing: TimingRange
}

interface AudioTrackProps {
    duration: number
    currentTime: number
    activeSegment: AudioSegment
    onTrackTimeUpdate: (time: number) => void
    onSeekStart: () => void
    onSeekEnd: () => void
    mediaTiming: MediaTimingInfo
    unplayedTimeRanges?: TimingRange[]
    displayUnplayedTimeRangesWarning?: boolean
    isRuntimeTimestampsVisible?: boolean
}

export const AudioTrack = ({
    duration,
    currentTime,
    activeSegment,
    onTrackTimeUpdate,
    onSeekStart,
    onSeekEnd,
    mediaTiming,
    unplayedTimeRanges,
    displayUnplayedTimeRangesWarning,
    isRuntimeTimestampsVisible,
}: AudioTrackProps) => {
    const [mouseDown, setMouseDown] = useState(false)
    const trackWrapperRef = useRef<HTMLDivElement>(null)
    const rect = useBoundingClientRect(trackWrapperRef)
    const activeSegmentDuration = activeSegment.timing.end - activeSegment.timing.start

    const onMouseDown = useCallback<MouseEventHandler<HTMLDivElement>>(
        (e) => {
            onSeekStart()
            setMouseDown(true)
            const x = e.pageX - rect.x
            const ratio = x / (rect.width - 1)
            onTrackTimeUpdate(ratio * duration)
        },
        [onSeekStart, rect.x, rect.width, onTrackTimeUpdate, duration],
    )

    const onMouseMove = useCallback<MouseEventHandler<HTMLDivElement>>(
        (e) => {
            if (mouseDown) {
                const x = e.pageX - rect.x
                const ratio = x / (rect.width - 1)
                onTrackTimeUpdate(ratio * duration)
            }
        },
        [rect, duration, mouseDown, onTrackTimeUpdate],
    )

    const onMouseUp = useCallback(() => {
        setMouseDown(false)
        onSeekEnd()
    }, [onSeekEnd])

    const activeSegmentProgress =
        (Math.max(0, Math.min(activeSegmentDuration, currentTime - activeSegment.timing.start)) /
            duration) *
        100

    return (
        <TrackWrapper
            ref={trackWrapperRef}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
        >
            {unplayedTimeRanges && (
                <UnplayedTimeRanges
                    start={mediaTiming.timing.start}
                    end={mediaTiming.timing.end}
                    ranges={unplayedTimeRanges}
                    currentTime={currentTime}
                    color="#ffbebe"
                    colorBeforeCurrentTime="#d93a35aa"
                    decorateRange={(range, rangeIndex) => (
                        <>
                            {displayUnplayedTimeRangesWarning && rangeIndex === 0 && (
                                <UnplayedRequiredTimeRangesWarningTooltip />
                            )}
                            {range}
                        </>
                    )}
                />
            )}

            <TrackProgress progressPercentage={(currentTime / duration) * 100} />

            {isRuntimeTimestampsVisible && (
                <TimeStamp>
                    {`${secondsToHHMMSS(currentTime)} / ${secondsToHHMMSS(duration)}`}
                </TimeStamp>
            )}

            <TrackActiveSegment
                start={(activeSegment.timing.start / duration) * 100}
                width={(activeSegmentDuration / duration) * 100}
            />

            <TrackActiveSegmentProgress
                start={(activeSegment.timing.start / duration) * 100}
                progressPercentage={activeSegmentProgress}
            />

            <TrackThumb
                currentTimePercentage={Math.max(0, Math.min(1, currentTime / duration)) * 100}
            />
        </TrackWrapper>
    )
}
