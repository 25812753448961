import { useCallback, useEffect, useRef } from 'react'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'

// the currentTime from the context isn't very precise in updating
// so to get a more accurate currentTime, this hook leverages requestAnimationFrame to get the update
// on every render.
export const useExactAudioTimeUpdateListener = (
    audioRef: React.RefObject<HTMLVideoElement>,
    isAudioPlaying: boolean,
    currentTime: number,
    cb: (time: number) => void,
) => {
    const requestAnimationFrameRef = useRef(0)
    const isUnmountedRef = useRef(false)

    const updateCurrentTime = useCallback(() => {
        if (isUnmountedRef.current) {
            return
        }

        if (audioRef?.current) {
            cb(audioRef?.current.currentTime)
        }

        requestAnimationFrameRef.current = requestAnimationFrame(updateCurrentTime)
    }, [audioRef, cb])

    useEffect(() => {
        if (isAudioPlaying) {
            requestAnimationFrameRef.current = requestAnimationFrame(updateCurrentTime)
        }
        return () => cancelAnimationFrame(requestAnimationFrameRef.current)
    }, [isAudioPlaying, updateCurrentTime])

    useEffect(() => {
        cb(currentTime)
    }, [currentTime, cb])

    useWillUnmount(() => {
        isUnmountedRef.current = true
    })
}
