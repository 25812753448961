import React, { useCallback } from 'react'
import { Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import styled from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { formatDuration } from 'src/utils/time'
import { SpeakerVoiceSample } from 'src/models'
import { OverflowMenuIcon, TrashIcon } from 'src/components/icons'

import { StyledVoiceSampleButton, TickIcon } from '../common'

const OverflowMenuButton = styled(OverflowMenuIcon)`
    width: 16px;
    height: 16px;
    color: ${palette('grey', 8)};
`

const VoiceSample = styled.div<{ isDefault?: boolean }>`
    display: flex;
    align-items: center;
    padding: 5px 8px;
    margin-bottom: 10px;
    font-size: 14px;
    color: ${palette('navy', 6)};
    background-color: ${ifProp(
        'isDefault',
        palette('cloudBlueLight', 3),
        palette('cloudBlueLight', 2),
    )};
    cursor: pointer;
    transition: background-color 250ms ease-out;

    &:hover {
        ${StyledVoiceSampleButton}
        ${TickIcon},
        ${OverflowMenuButton} {
            color: ${palette('navy', 2)};
        }
    }
`

const SampleName = styled.div`
    flex: 1;
    margin-left: 6px;
`

const MenuPopover = styled(Popover2)`
    .bp4-popover-target {
        display: flex;
        margin-left: 6px;
    }

    .bp4-popover {
        width: 100%;
        box-shadow: none;
        border-radius: 0;

        .bp4-popover-content {
            border-radius: 0;
        }
    }
`

const VoiceSampleMenu = styled(Menu)`
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px ${palette('cloudBlueLight', 0)};
    background-color: ${palette('white', 0)};
`

const VoiceSampleMenuItem = styled(MenuItem)`
    height: 32px;
    align-items: center;
    font-size: 14px;
    color: ${palette('navy', 2)};

    svg {
        width: 16px;
        height: 16px;
        color: ${palette('grey', 8)};
    }
`

interface VoiceSampleFieldProps {
    onToggleVoiceSample: (sampleId: string, isPlaying: boolean, e: React.MouseEvent) => void
    onSetAsDefault: (sampleId: string) => void
    onDelete: (sampleId: string) => void
    sample: SpeakerVoiceSample
    isDefault?: boolean
    isPlaying: boolean
    isBuffering: boolean
}

export const VoiceSampleField = ({
    onToggleVoiceSample,
    onSetAsDefault,
    onDelete,
    sample,
    isDefault,
    isPlaying,
    isBuffering,
}: VoiceSampleFieldProps) => {
    const onVoiceSampleButtonClick = useCallback(
        (e: React.MouseEvent) => onToggleVoiceSample(sample.id, isPlaying, e),
        [sample, isPlaying, onToggleVoiceSample],
    )
    const onSetAsDefaultButtonClick = useCallback(
        () => onSetAsDefault(sample.id),
        [sample.id, onSetAsDefault],
    )
    const onDeleteButtonClick = useCallback(() => onDelete(sample.id), [sample.id, onDelete])

    return (
        <VoiceSample isDefault={isDefault}>
            <StyledVoiceSampleButton
                isPlaying={isPlaying}
                isBuffering={isBuffering}
                onClick={onVoiceSampleButtonClick}
            />
            <SampleName>Sample &nbsp;&nbsp; {formatDuration(sample.end - sample.start)}</SampleName>
            {isDefault && <TickIcon />}

            <MenuPopover
                minimal
                usePortal={false}
                position="bottom-right"
                content={
                    <VoiceSampleMenu>
                        <VoiceSampleMenuItem
                            onClick={onSetAsDefaultButtonClick}
                            icon={<TickIcon />}
                            text="Set as default"
                        />
                        <VoiceSampleMenuItem
                            onClick={onDeleteButtonClick}
                            icon={<TrashIcon />}
                            text="Delete"
                        />
                    </VoiceSampleMenu>
                }
            >
                <OverflowMenuButton />
            </MenuPopover>
        </VoiceSample>
    )
}
