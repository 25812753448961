import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import styled, { css } from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'

export const SelectButton = styled(Button)<{
    $hasValue?: boolean
    $isRealTimeReadOnly?: boolean
    $isSpeakerSelection?: boolean
}>`
    &.bp4-button {
        min-width: initial;
        min-height: 28px;
        padding: 0 6px;
        background-color: ${palette('white', 0)};
        border-radius: 4px;
        box-shadow: none !important;
        background-image: none !important;

        border: ${ifProp(
            '$isRealTimeReadOnly',
            css`solid 0px transparent`,
            css`solid 1px ${palette('grey', 13)}`,
        )};

        .bp4-button-text {
            color: ${palette('grey', 10)};
        }

        > svg {
            color: ${palette('cloudBlue', 2)};
            fill: currentColor;
        }

        > .bp4-icon {
            color: ${palette('cloudBlue', 0)};

            &:first-child:last-child {
                margin: 0 -2px 0 0;
            }
        }

        &:hover {
            background-color: ${palette('white', 0)};

            > .bp4-icon {
                color: ${palette('navy', 2)};
            }
        }

        &:active {
            background-color: ${palette('white', 0)};
            border-color: ${palette('grey', 11)};

            .bp4-button-text {
                color: ${palette('navy', 3)};
            }

            > svg {
                color: ${palette('navy', 2)};
            }

            > .bp4-icon {
                color: ${palette('blue', 0)};
            }
        }

        &.bp4-disabled {
            background-color: ${ifProp(
                '$isRealTimeReadOnly',
                palette('white', 0),
                palette('cloudBlueLight', 7),
            )};
            border-color: ${palette('grey', 13)};

            .bp4-button-text {
                color: ${ifProp('$isRealTimeReadOnly', palette('blue', 1), palette('grey', 8))};
            }

            > svg {
                color: ${palette('cloudBlue', 2)};
            }

            > .bp4-icon {
                display: none;
            }
        }

        ${ifProp(
            '$hasValue',
            css`
                .bp4-button-text {
                    color: ${ifProp(
                        ['$isRealTimeReadOnly', '$isSpeakerSelection'],
                        palette('blue', 1),
                        palette('navy', 0),
                    )} !important;
                }

                > .bp4-icon {
                    color: ${palette('navy', 2)};
                }
            `,
        )}
    }
`

export const SelectMenu = styled(Menu)`
    position: relative;
    min-width: 220px;
    padding: 0;
`

export const SelectMenuItem = styled(MenuItem)`
    min-height: 38px;
    align-items: center;
    padding: 0 8px;
    background-color: ${palette('white', 0)};
    border-radius: 0;
    font-size: 14px;
    color: ${palette('navy', 0)};

    &:hover,
    &.bp4-active {
        background-color: ${palette('cloudBlueLight', 2)} !important;

        .bp4-menu-item.bp4-active.bp4-intent-primary,
        .bp4-text-overflow-ellipsis.bp4-fill {
            color: ${palette('navy', 0)};
        }
    }

    .bp4-icon {
        margin-top: 0;
        color: ${palette('blue', 0)};
    }
`

export const SelectMenuDivider = styled(MenuDivider)`
    margin: 0;
    border-color: ${palette('grey', 13)};
`
