import {
    useState,
    useEffect,
    ReactNode,
    useContext,
    useMemo,
    useCallback,
    createContext,
} from 'react'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { Block } from 'src/components/Editor/plugins/withTranscript'

const SPEAKER_CHANGE_REMINDER_POPUP_DISPLAY_TIME_IN_SECONDS = 4

interface SpeakerChangeReminderContextValue {
    wasPopupDisplayed: boolean
    setWasPopupDisplayed: (value: boolean) => unknown
    dismissPopup: () => unknown
}

export const SpeakerChangeReminderContext = createContext<SpeakerChangeReminderContextValue>({
    wasPopupDisplayed: true,
    setWasPopupDisplayed: () => {},
    dismissPopup: () => {},
})

/**
 * This is the provider that keeps track of whether or not the speaker change reminder was already shown.
 * This is currently rendered within the <Session />.
 */
export const SpeakerChangeReminderProvider = ({ children }: { children: ReactNode }) => {
    const [wasPopupDisplayed, setWasPopupDisplayed] = useState(false)
    const [isPopupDismissed, setIsPopupDismissed] = useState(false)

    const [{ context }] = useTaskMachine(['task'])
    const { task } = context

    // reset the speaker change reminder if there's a new task (since its rendered above the task level) and the popup hasn't been permanently
    // dismissed by the user
    useEffect(() => {
        if (task && !isPopupDismissed) {
            setWasPopupDisplayed(false)
        }
    }, [isPopupDismissed, task])

    const dismissPopup = useCallback(() => {
        setIsPopupDismissed(true)
    }, [])

    const contextValue: SpeakerChangeReminderContextValue = useMemo(
        () => ({
            wasPopupDisplayed,
            setWasPopupDisplayed,
            dismissPopup,
        }),
        [dismissPopup, wasPopupDisplayed],
    )

    return (
        <SpeakerChangeReminderContext.Provider value={contextValue}>
            {children}
        </SpeakerChangeReminderContext.Provider>
    )
}

/**
 * This hook determines, if the "speaker change reminder" popup should be shown and closes it
 * on any user interaction.
 * @param block slate block element
 */
export const useSpeakerChangeReminder = (block: Block) => {
    const { wasPopupDisplayed, setWasPopupDisplayed, dismissPopup } = useContext(
        SpeakerChangeReminderContext,
    )
    const shouldShowPopup = !wasPopupDisplayed && block.editable && !block.hasExplicitBreak
    const [isPopupOpen, setIsPopupOpen] = useState(shouldShowPopup)

    // Hide the speaker change reminder popup on any user interaction or after a timeout
    useEffect(() => {
        function onAnyUserInteraction() {
            setIsPopupOpen(false)
        }

        if (isPopupOpen) {
            setWasPopupDisplayed(true)
            document.addEventListener('keydown', onAnyUserInteraction, { capture: true })
            document.addEventListener('mousedown', onAnyUserInteraction, { capture: true })
            const timer = setTimeout(
                onAnyUserInteraction,
                SPEAKER_CHANGE_REMINDER_POPUP_DISPLAY_TIME_IN_SECONDS * 1000,
            )

            return () => {
                document.removeEventListener('keydown', onAnyUserInteraction, { capture: true })
                document.removeEventListener('mousedown', onAnyUserInteraction, { capture: true })
                clearTimeout(timer)
            }
        }
    }, [isPopupOpen, setWasPopupDisplayed])

    return { isPopupOpen, dismissPopup }
}
