import { useEffect, useRef, useState } from 'react'

const DELAY_BEFORE_SETTING_BUFFERING = 50

export const useAudioBufferingState = (audio: HTMLAudioElement | null): boolean => {
    const [isBuffering, setIsBuffering] = useState(false)
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
        if (!audio) return

        const onWaiting = () => {
            /** Prevent loader flickering on initial play,
             *  because even on a high speed network the waiting event can be called for a really short amount of time on the initial play */
            timeoutRef.current = setTimeout(
                () => setIsBuffering(true),
                DELAY_BEFORE_SETTING_BUFFERING,
            )
        }
        const onPlaying = () => {
            if (!!timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            setIsBuffering(false)
        }

        audio.addEventListener('waiting', onWaiting)
        audio.addEventListener('playing', onPlaying)

        return () => {
            if (!!timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }

            audio.removeEventListener('waiting', onWaiting)
            audio.removeEventListener('playing', onPlaying)
        }
    }, [audio])

    return isBuffering
}
