import { RefObject, useCallback, useEffect, useState } from 'react'

import { TimingRange } from 'src/utils/range'

const convertTimeRangesToTimeRangeArray = (timeRanges: TimeRanges) => {
    const timeRangeArray: TimingRange[] = []

    for (let i = 0; i < timeRanges.length; i++) {
        timeRangeArray.push({
            start: timeRanges.start(i) ?? 0,
            end: timeRanges.end(i) ?? 0,
        })
    }

    return timeRangeArray
}

export const useMediaBufferedTimeRanges = (audioRef: RefObject<HTMLMediaElement>) => {
    const [buffered, setBuffered] = useState<TimingRange[]>([])

    const onBufferedChange = useCallback(() => {
        if (!audioRef.current) {
            return
        }

        setBuffered(convertTimeRangesToTimeRangeArray(audioRef.current.buffered))
    }, [audioRef])

    useEffect(() => {
        const audioEl = audioRef.current

        if (!audioEl) {
            return
        }

        audioEl.addEventListener('progress', onBufferedChange)
        audioEl.addEventListener('canplaythrough', onBufferedChange)
        audioEl.addEventListener('emptied', onBufferedChange)

        return () => {
            audioEl.removeEventListener('progress', onBufferedChange)
            audioEl.removeEventListener('canplaythrough', onBufferedChange)
            audioEl.removeEventListener('emptied', onBufferedChange)
        }
    }, [audioRef, onBufferedChange])

    return buffered
}
