import React, { useEffect, useState } from 'react'
import { Spinner } from './Spinner'

const NETWORK_ERROR_MESSAGE_DELAY_IN_SECONDS = 15

export function SubmittingScreen() {
    const [showNetworkError, setShowNetworkError] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowNetworkError(true)
        }, NETWORK_ERROR_MESSAGE_DELAY_IN_SECONDS * 1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    return (
        <Spinner
            title="Submitting your task ..."
            subtitle={
                showNetworkError ? (
                    <>
                        Looks like there's a network connectivity issue, but we will keep trying to
                        submit your task.
                        <br />
                        Do not refresh or close the page!
                    </>
                ) : undefined
            }
        />
    )
}
