import { isURL } from './url'
import { assertIsDefined } from './assert'

export const getChatServerBaseURL = () => process.env.REACT_APP_CHAT_SERVER_BASE_URL
export const getTrainingServerBaseURL = () => process.env.REACT_APP_TRAINING_BASE_URL
export const getMixpanelApiKey = () => process.env.REACT_APP_MIXPANEL_API_KEY
export const getRollbarApiKey = () => process.env.REACT_APP_ROLLBAR_TOKEN
export const getMixpanelVcApiKey = () => process.env.REACT_APP_MIXPANEL_VC_API_KEY
export const getFlagsmithToken = () => process.env.REACT_APP_FLAGSMITH_TOKEN

export function getEnv(): 'dev' | 'qa' | 'production' | 'staging' {
    return process.env.REACT_APP_ENV ?? 'dev'
}

/**
 * Get the API endpoint url through the querystring (?api_endpoint=) or fallback to env variable (REACT_APP_API_BASE_URL)
 */
export function getAPIEndpoint() {
    const urlParams = new URLSearchParams(window.location.search)
    const queryEndpoint = urlParams.get('api_endpoint')

    if (queryEndpoint && isURL(queryEndpoint)) {
        return queryEndpoint
    } else {
        assertIsDefined(process.env.REACT_APP_API_BASE_URL)
        assertIsDefined(process.env.REACT_APP_SESSION_ID)
        return `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_SESSION_ID}`
    }
}

export function getAuthToken() {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('auth_token') ?? undefined
}

export function shouldUseBackdoor() {
    const urlParams = new URLSearchParams(window.location.search)
    const manualLogin = urlParams.get('manual_login')
    // In dev environments, we want to default to the backdoor, unless it's explicitly set to false or 0
    // In all other environments, we want to default to NO backdoor, unless it's explicitly set and is not a falsy value (false or 0)
    if (getEnv() === 'dev') {
        return manualLogin === null || (manualLogin !== 'false' && manualLogin !== '0')
    } else {
        return manualLogin !== null && manualLogin !== 'false' && manualLogin !== '0'
    }
}

export function shouldShowIconListPage() {
    const urlParams = new URLSearchParams(window.location.search)
    const showIcons = urlParams.has('show_icons')

    return showIcons
}

export async function setCloudFrontCookie() {
    try {
        const response = await fetch('cf-cookies.json')
        const cookie = await response.json()
        Object.entries(cookie).forEach(([name, value]) => (document.cookie = `${name}=${value}`))
    } catch (e) {
        console.warn('cf-cookies.json is missing, please follow README.md instructions')
    }
}

export async function setVerbitUniversalTokenCookie() {
    try {
        const response = await fetch('verbit-universal-token.json')
        const jwt = await response.json()
        document.cookie = `qa_verbit_universal_token=${jwt}`
    } catch (e) {
        console.warn('verbit-universal-token.json is missing, please follow README.md instructions')
    }
}
