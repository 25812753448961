import React, { useState, useRef, Suspense } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { InputGroup } from '@blueprintjs/core'
import Hls from 'hls.js'

import { getEnv } from 'src/utils/env'
import { PositiveButton } from 'src/components/Common/styled/buttons'

const ReactHlsPlayer = React.lazy(
    () =>
        import(
            /* webpackChunkName: "react-hls-player" */
            'react-hls-player'
        ),
)

const InputContainer = styled.div`
    margin: 40px auto;
    width: 800px;
    display: flex;
    justify-content: center;
`

const PlayerContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 80px 20px;
`

const Input = styled(InputGroup)`
    flex: 1;
    margin-left: 10px;

    input {
        box-shadow: none !important;
        padding: 5px 10px;
        background-color: ${palette('white', 0)};
        border-radius: 4px;
        border: solid 1px ${palette('cloudBlueLight', 4)};
        font-size: 14px;
        color: ${palette('navy', 6)};

        &:focus {
            box-shadow: none !important;
        }
    }
`

export const HlsDebugTool = () => {
    const [url, setUrl] = useState('')
    const [showVideoPlayer, setShowVideoPlayer] = useState(false)
    const playerRef = useRef<HTMLVideoElement>(null)
    const isDev = getEnv() === 'dev'
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target
        setUrl(value)
        if (value.length === 0) {
            setShowVideoPlayer(false)
        }
    }

    const handleClick = () => {
        setUrl(url)
        setShowVideoPlayer(true)
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setUrl(url)
            setShowVideoPlayer(true)
        }
    }

    return (
        <>
            <InputContainer>
                <Input
                    type="text"
                    name="URL"
                    placeholder="insert URL"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    value={url}
                />
                <PositiveButton onClick={handleClick}>ok</PositiveButton>
            </InputContainer>
            <PlayerContainer>
                {showVideoPlayer && (
                    <Suspense fallback={<div>Loading...</div>}>
                        <ReactHlsPlayer
                            hlsConfig={{
                                ...Hls.DefaultConfig,
                                xhrSetup: (xhr) => {
                                    xhr.withCredentials = true // Enable cookies for HLS authentication (using CloudFront signed cookies)
                                },
                            }}
                            src={
                                isDev
                                    ? url.replace('https://legal-deposition-hls.verbit.co', '/hls')
                                    : url
                            }
                            playerRef={playerRef}
                            autoPlay={true}
                            controls={true}
                            width="80%"
                            height="80%"
                        />
                    </Suspense>
                )}
            </PlayerContainer>
        </>
    )
}
