import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Editor, RangeRef } from 'slate'
import { useSlateStatic } from 'slate-react'
import { Overlay } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { palette, prop } from 'styled-tools'

import { AbsolutePosition, useAbsolutePosition } from 'src/hooks/useAbsolutePosition'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { Theme } from 'src/components/styled'
import { Block } from 'src/components/Editor/plugins/withTranscript'

import { ContextMenuSectionComponent } from './ContextMenuSection'
import { ContextMenuInstance, MenuBuilderRegistry } from './ContextMenuBuilder'

const MAX_HEIGHT = 100

interface AbsoluteWrapperProps extends AbsolutePosition {
    theme: Theme
}

const AbsoluteWrapper = styled.div<AbsoluteWrapperProps>`
    position: absolute;
    left: ${prop('left')}px;
    ${(props) => (props.top ? `top: ${props.top}px;` : '')}
    ${(props) => (props.bottom ? `bottom: ${props.bottom}px;` : '')}
    z-index: 20;
    min-width: 200px;

    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px ${palette('cloudBlueLight', 0)};
    background-color: ${palette('white', 0)};
`

const CONTEXT_MENU_BUTTON_CLICK = 2

interface ContextMenuProps {
    isOpen: boolean
    currentRange?: RangeRef
    onClose: (selectPreviousRange?: boolean) => void
}

export type ContextMenuSelection = [number, number]

export function ContextMenu({ isOpen, currentRange, onClose }: ContextMenuProps) {
    const editor = useSlateStatic()
    const position = useAbsolutePosition(currentRange?.current, MAX_HEIGHT)

    const menuSections = useMemo(() => {
        if (!currentRange?.current) {
            return []
        }

        const nodes = Editor.nodes(editor, { at: currentRange.current })
        let menu = new ContextMenuInstance()

        for (const nodeEntry of nodes) {
            const [node] = nodeEntry

            // The 'Editor' type doesn't have a type property so we need to check if the node is an Editor first
            let type: string

            if (Editor.isEditor(node)) {
                type = 'general'

                const [[block]] = Editor.nodes(editor, {
                    at: currentRange.current,
                    match: Block.isBlock,
                })
                if (block.editable) {
                    type = 'generalEditable'
                }
            } else {
                type = node.type
            }

            MenuBuilderRegistry.getMenuForType(type, menu, nodeEntry, currentRange.current)
        }

        return menu.get()
    }, [currentRange, editor])

    // selection means the current selected context menu item. Its a tuple because the
    // menu has 2 layers
    const [selection, setSelection] = useState<ContextMenuSelection>([0, 0])

    // reset selection whenever the current range changes
    useEffect(() => {
        setSelection([0, 0])
    }, [currentRange])

    useMousetrap(
        'down',
        useCallback(
            (e) => {
                if (!isOpen) return
                e.preventDefault()
                const [sectionIdx, itemIdx] = selection

                const currentSection = menuSections[sectionIdx]
                if (itemIdx + 1 < currentSection.items.length) {
                    setSelection([sectionIdx, itemIdx + 1])
                    return
                }

                if (sectionIdx + 1 < menuSections.length) {
                    setSelection([sectionIdx + 1, 0])
                    return
                }

                setSelection([0, 0])
            },
            [isOpen, menuSections, selection],
        ),
        { condition: isOpen },
    )

    useMousetrap(
        'up',
        useCallback(
            (e) => {
                if (!isOpen) return
                e.preventDefault()
                const [sectionIdx, itemIdx] = selection

                if (itemIdx - 1 >= 0) {
                    setSelection([sectionIdx, itemIdx - 1])
                    return
                }

                if (sectionIdx - 1 >= 0) {
                    setSelection([sectionIdx - 1, menuSections[sectionIdx - 1].items.length - 1])
                    return
                }

                const lastSection = menuSections[menuSections.length - 1]
                setSelection([menuSections.length - 1, lastSection.items.length - 1])
            },
            [isOpen, menuSections, selection],
        ),
        { condition: isOpen },
    )

    const selectItem = useCallback(
        (selection: ContextMenuSelection) => {
            let res
            if (currentRange?.current) {
                const [sectionIdx, itemIdx] = selection
                res = menuSections[sectionIdx]?.items[itemIdx]?.onAction(editor, currentRange)
            }
            if (res !== false) {
                onClose()
            }
        },
        [onClose, currentRange, editor, menuSections],
    )

    useMousetrap(
        'enter',
        useCallback(
            (e) => {
                if (!isOpen) return
                e.preventDefault()

                selectItem(selection)
            },
            [isOpen, selectItem, selection],
        ),
    )

    const onOverlayClose = useCallback(
        (e?: SyntheticEvent<HTMLElement, Event>) => {
            /** if this click is a right click, it means the user wanted to open the context menu again in a different position,
             *  so we want to skip our functionality of selecting the tag range on context menu close,
             *  because this selection will interfere with the selection of the new context menu that is about to be opened */
            const shouldSelectPreviousRange =
                !e || (e.nativeEvent as MouseEvent).button !== CONTEXT_MENU_BUTTON_CLICK
            onClose(shouldSelectPreviousRange)
        },
        [onClose],
    )

    return (
        <Overlay
            isOpen={isOpen}
            transitionDuration={-1}
            transitionName="none"
            backdropClassName="popup-overlay-backdrop"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            onClose={onOverlayClose}
        >
            <AbsoluteWrapper {...position}>
                {menuSections.map((section, idx) => (
                    <ContextMenuSectionComponent
                        key={idx}
                        index={idx}
                        section={section}
                        selection={selection}
                        onSelect={selectItem}
                        onChangeSelection={setSelection}
                    />
                ))}
            </AbsoluteWrapper>
        </Overlay>
    )
}
