import React, { useEffect, useRef } from 'react'

import { ifProp, prop } from 'styled-tools'
import styled from 'styled-components/macro'

import { useVideo } from 'src/state/VideoProvider'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

export const VideoPlayerDragType = 'VIDEO'

const Container = styled.div<{
    isDragging?: boolean
    isValidVideo: boolean
    minWidth: number
    minHeight: number
}>`
    cursor: move;
    opacity: ${ifProp('isDragging', '0.8', '1')};
    min-width: ${prop('minWidth')}px;
    min-height: ${prop('minHeight')}px;
    display: ${ifProp('isValidVideo', '', 'none')};
`

interface VideoPlayerProps {
    isDragging?: boolean
    width: number
    height: number
}

export const VideoPlayer = React.forwardRef(function VideoPlayer(
    { isDragging, width, height }: VideoPlayerProps,
    ref,
) {
    const { mediaRef } = useSessionMedia(['mediaRef'])
    const videoContainerRef = useRef<HTMLDivElement | null>(null)
    const { minVideoDimensions, isValidVideo } = useVideo()

    useEffect(() => {
        const videoContainerEl = videoContainerRef.current
        const videoEl = mediaRef.current

        if (videoContainerEl && videoEl && isValidVideo) {
            videoEl.style.borderRadius = '5px'
            videoEl.style.width = '100%'
            videoEl.style.height = '100%'
            videoContainerEl.appendChild(videoEl)
        }
    }, [mediaRef, isValidVideo])

    return (
        <Container
            onContextMenu={(e) => e.preventDefault()}
            ref={(el) => {
                if (ref) {
                    typeof ref === 'function' ? ref(el) : (ref.current = el)
                }
                videoContainerRef.current = el
            }}
            style={{
                width,
                height,
            }}
            minWidth={minVideoDimensions.width}
            minHeight={minVideoDimensions.height}
            isDragging={isDragging}
            isValidVideo={isValidVideo}
        />
    )
})
