import styled from 'styled-components/macro'
import { LoadingDotsIcon } from '@verbit-ai/icons-library'

const Container = styled.div`
    flex: 1;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
`

export function InitializingScreen() {
    return (
        <Container>
            <LoadingDotsIcon size={24} />
        </Container>
    )
}
