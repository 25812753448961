import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import * as Icons from 'src/components/icons'

const Container = styled.div`
    height: 100%;
    width: 25%;
    background: white;
    padding: 50px 65px;
`

const MetricsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const MetricsHeader = styled.h4`
    color: #10264a;
    font-weight: 700;
    font-size: 14px;
    padding: 10px;
    margin: 0;
    line-height: 24px;
`

const PaymentsText = styled.p`
    color: #10264a;
    font-size: 14px;
    margin-top: 20px;
`

const Link = styled.a`
    color: #2664e8;
    font-size: 14px;
    padding-left: 5px;
`

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #cbd6df;
`

const Item = styled.div`
    display: flex;
    align-items: center;
    width: 40%;
    margin: 3em 0;

    @media (max-width: 1600px) {
        margin: 1em 0;
    }
`

const ItemIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    margin-right: 10px;
    padding: 7px;
    background-color: ${palette('cloudBlueLight', 1)};
    border-radius: 50%;

    svg {
        color: ${palette('blue', 0)};
    }
`

const Metric = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 48px;

    span {
        font-size: 32px;
    }
`

const Line = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    color: ${palette('navy', 9)};
`

const MetricName = styled.div`
    position: absolute;
    top: calc(100% + 1px);
    word-break: none;
    font-size: 12px;
    white-space: pre;
`

interface MetricsBoxProps {
    sessionStats: {
        workTime: number
        transcribedAudio: number
        segmentsCompleted: number
    }
}

export const MetricsBox = ({ sessionStats }: MetricsBoxProps) => {
    return (
        <Container>
            <MetricsHeader>Your Contribution</MetricsHeader>
            <Divider />
            <MetricsContainer>
                <Item>
                    <ItemIconWrapper>
                        <Icons.TimeIcon />
                    </ItemIconWrapper>
                    <Line>
                        <Metric>
                            {toMins(sessionStats?.workTime ?? 0)}
                            <span> min</span>
                        </Metric>
                        <MetricName>Time worked</MetricName>
                    </Line>
                </Item>
                <Item>
                    <ItemIconWrapper>
                        <Icons.MicrophoneIcon />
                    </ItemIconWrapper>
                    <Line>
                        <Metric>
                            {toMins(sessionStats?.transcribedAudio ?? 0)}
                            <span> min</span>
                        </Metric>
                        <MetricName>Audio minutes transcribed</MetricName>
                    </Line>
                </Item>
                <Item>
                    <ItemIconWrapper>
                        <Icons.CompleteIcon />
                    </ItemIconWrapper>
                    <Line>
                        <Metric>{sessionStats?.segmentsCompleted}</Metric>
                        <MetricName>Tasks completed</MetricName>
                    </Line>
                </Item>
                <PaymentsText>
                    Information about payments can be found in your
                    <Link href="https://platform.verbit.co/users/work_history">Work History</Link>
                </PaymentsText>
            </MetricsContainer>
        </Container>
    )
}

function toMins(val?: number) {
    if (!val) {
        return '0'
    }
    return Math.round(val / 60).toString()
}
