import { ComponentType, ReactNode } from 'react'
import { castArray } from 'lodash/fp'

export const withContextProvider =
    <Props extends {}>(
        ctxProviders:
            | ComponentType<{ children: ReactNode }>[]
            | ComponentType<{ children: ReactNode }>,
        Component: ComponentType<Props>,
    ) =>
    (props: Props) =>
        (
            <>
                {castArray(ctxProviders).reduce(
                    (accumulatedNode, CtxProvider) => (
                        <CtxProvider>{accumulatedNode}</CtxProvider>
                    ),
                    <Component {...props} />,
                )}
            </>
        )
