import { Tooltip2 } from '@blueprintjs/popover2'
import styled from 'styled-components/macro'
import { GlossaryVerifiedIcon } from 'src/components/icons'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag'
import { palette } from 'styled-tools'

const Wrapper = styled.div`
    cursor: pointer;
    margin-left: 6px;

    & .bp4-popover2-target {
        display: flex;
        align-items: center;
    }

    & .bp4-tooltip2 .bp4-popover2-content {
        font-weight: normal;
        cursor: auto;
        background: ${palette('navy', 0)};
        color: ${palette('white', 0)};
    }
    & .bp4-tooltip2 .bp4-popover2-arrow-fill {
        fill: ${palette('navy', 0)};
    }
`

export function GlossaryVerifiedMark() {
    const isGlossaryVerificationEnabled = useFeatureFlag('glossary_verification')

    if (!isGlossaryVerificationEnabled) {
        return null
    }

    return (
        <Wrapper>
            <Tooltip2
                usePortal={false}
                content="This term is verified"
                placement="top"
                hoverOpenDelay={100}
            >
                <GlossaryVerifiedIcon />
            </Tooltip2>
        </Wrapper>
    )
}
