import { useMemo, createContext, ReactNode } from 'react'
import { useContext } from 'react'

import { DictionaryHTTPCacheInstance } from './DictionaryHTTPCache'
import { SpellcheckerAPI } from './SpellcheckerAPI'

interface SpellcheckerContextProps {
    spellchecker: SpellcheckerAPI
}

const Context = createContext<SpellcheckerContextProps>({
    spellchecker: new SpellcheckerAPI(DictionaryHTTPCacheInstance),
})

interface SpellcheckerProviderProps {
    children: ReactNode
}

export function SpellcheckerProvider({ children }: SpellcheckerProviderProps) {
    const spellchecker = useMemo(() => {
        const s = new SpellcheckerAPI(DictionaryHTTPCacheInstance)

        s.loadDictionary('en')

        // @ts-ignore
        window.spellchecker = s

        return s
    }, [])

    return (
        <Context.Provider value={useMemo(() => ({ spellchecker }), [spellchecker])}>
            {children}
        </Context.Provider>
    )
}

export function useSpellchecker() {
    return useContext(Context)
}
