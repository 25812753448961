import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import flagsmith from 'flagsmith'

import { getFlagsmithToken } from 'src/utils/env'
import { useSessionStatus } from './SessionStatusProvider'
import { IFlags } from 'flagsmith/types'

interface FeatureFlagProviderProps {
    children: ReactNode
}

export type FeatureFlagContextType = IFlags<string> | null

export const FeatureFlagContext = createContext<FeatureFlagContextType>(null)

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
    const [flags, setFlags] = useState<FeatureFlagContextType>(null)
    const environmentID = getFlagsmithToken()
    const { sessionStatus } = useSessionStatus(['sessionStatus', 'sessionStatus.customerId'])

    useEffect(() => {
        if (!environmentID) {
            console.warn('Failed to init flagsmith: API key not provided')

            return
        }
        flagsmith.init({
            environmentID,
            cacheFlags: true,
            enableAnalytics: true, // See https://docs.flagsmith.com/flag-analytics/ for more info
            onChange: () => setFlags(flagsmith.getAllFlags()),
        })
    }, [environmentID])

    useEffect(() => {
        if (sessionStatus?.customerId) {
            const traits = {
                customerId: sessionStatus.customerId,
            }
            flagsmith.identify(sessionStatus.customerId.toString(), traits)
            setFlags(flagsmith.getAllFlags())
        }
    }, [sessionStatus])

    return <FeatureFlagContext.Provider value={flags}>{children}</FeatureFlagContext.Provider>
}

export const useFeatureFlag = (featureName: string) => {
    const flags = useContext(FeatureFlagContext)

    return flags?.[featureName]?.enabled || false
}
