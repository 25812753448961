import { useEffect, useMemo, FunctionComponent, useState } from 'react'
import { useInterval } from 'src/hooks/useInterval'
import { getTrainingServerBaseURL } from 'src/utils/env'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import qs from 'qs'

import { appcues } from 'src/appcues'
import { useAnalytics, SessionEndReason } from 'src/analytics'
import * as Icons from 'src/components/icons'
import { Spinner } from 'src/components/Session/Spinner'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'

import { TrainingSessionStats } from './TrainingSessionStats'
import { MetricsBox } from './MetricsBox'
import { useFeatureFlag } from 'src/hooks/useFeatureFlag'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`

const ContentContainer = styled.div`
    position: relative;
    width: 75%;
    height: 100%;
    background: ${palette('navy', 2)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 100px;
    color: ${palette('white', 0)};
`

const ContentHeader = styled.h3`
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 10px;
`

const SubHeader = styled.p`
    font-size: 18px;
    margin-bottom: 40px;
    line-height: 30px;
`

const Text = styled.p`
    font-size: 14px;
`

const IconWrapper = styled.div`
    position: absolute;
    bottom: 100px;
    right: 150px;
    width: 450px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1600px) {
        bottom: 50px;
        right: 100px;
        width: 25%;
    }
`

const StyledButton = styled.button`
    background-color: ${palette('turquoise', 0)};
    border-radius: 4px;
    border: none;
    padding: 6px 8px;
    cursor: pointer;
`

const ButtonWrapper = styled.div`
    margin-top: 12px;
`

const FINISHED_TEXTS: { [key: number]: string } = {
    0: 'Mission completed!',
    1: 'Hooray!',
    2: 'Way to go!',
    3: 'Super!',
    4: 'Yay, you did it!',
}

const FINISHED_ICONS: { [fileType: number]: FunctionComponent } = {
    0: Icons.AstronautIcon,
    1: Icons.ConfirmedIcon,
    2: Icons.HighFiveIcon,
    3: Icons.SuperHeroIcon,
    4: Icons.JoyIcon,
}

const SPECIFIC_CASE_TEXTS: { [key: string]: string } = {
    LEFT: "We're sad to see you go",
    TIMED_OUT: 'Internet connection unstable',
    REMOVED_BY_OPS: 'Thank you for your time',
    REMOVED_BY_NEMO: 'Your shift is over',
}

const SPECIFIC_CASE_ICONS: { [fileType: string]: FunctionComponent } = {
    LEFT: Icons.PhoneIcon,
    TIMED_OUT: Icons.ServerIcon,
    REMOVED_BY_OPS: Icons.MusicIcon,
    REMOVED_BY_NEMO: Icons.WinnersIcon,
}

export function Stats() {
    const [{ context }] = useAppMachine([
        'sessionStats',
        'isEditorInGlossersMode',
        'platformWorkerId',
        'httpClient',
    ])
    const [isSessionFeedbackAvailable, setIsSessionFeedbackAvailable] = useState(false)
    const { sessionStats, isEditorInGlossersMode, platformWorkerId, httpClient } = context
    const { sessionStatus } = useSessionStatus([
        'sessionStatus.sessionId',
        'sessionStatus.transcribersHubUrl',
    ])
    const analytics = useAnalytics()
    const randomNumber = useMemo(() => Math.floor(Math.random() * 5), [])
    const type = sessionStats?.state || 'FINISHED'
    const sessionHasFinished = type === 'FINISHED'
    const isTrainingSession = sessionStats?.isTrainingSession
    const isTrainingSessionExit = !!(isTrainingSession && type === 'LEFT')
    const isNpsFeatureFlag = useFeatureFlag('appcues_nps')

    const ANALYTICS_REFRESH_INTERVAL = 5000
    const trainingBaseUrl = getTrainingServerBaseURL()
    const redirectTrainingUrl = `${trainingBaseUrl}/analytics?${qs.stringify({
        sessionId: sessionStatus?.sessionId,
        userId: platformWorkerId,
        appName: 'trax',
    })}`

    // initiate polling - send request to the backend every 5 seconds - if there's a response redirect to the training's feedback page
    useInterval(
        async () => {
            console.log('checking if analytics is ready...')
            if (platformWorkerId) {
                try {
                    await httpClient.getSessionAnalytics(platformWorkerId)
                    setIsSessionFeedbackAvailable(true)
                } catch {
                    setIsSessionFeedbackAvailable(false)
                }
            }
        },
        ANALYTICS_REFRESH_INTERVAL,
        isTrainingSession,
    )

    useEffect(() => {
        if (isTrainingSession && platformWorkerId && isSessionFeedbackAvailable) {
            window.location.href = redirectTrainingUrl
        }
    }, [isSessionFeedbackAvailable, isTrainingSession, platformWorkerId, redirectTrainingUrl])

    useEffect(() => {
        appcues.track('END_OF_SESSION', {
            isGlosserMode: isEditorInGlossersMode,
            showNps: isNpsFeatureFlag,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (sessionStats) analytics?.sendEndSession(getReason(sessionStats.state))
    }, [analytics, sessionStats])

    if (!sessionStats) {
        return null
    }

    const renderIcon = () => {
        let Icon
        if (type) {
            if (sessionHasFinished) {
                Icon = FINISHED_ICONS[randomNumber]
            } else {
                Icon = SPECIFIC_CASE_ICONS[type]
            }
            return Icon && <Icon />
        }
    }

    const onButtonClick = () => {
        if (sessionStatus) {
            window.location.href = sessionStatus?.transcribersHubUrl
        }
    }

    const renderMatchingText = () => {
        switch (type) {
            case 'LEFT':
                return (
                    <>
                        <ContentHeader>{SPECIFIC_CASE_TEXTS[type]}</ContentHeader>
                        <SubHeader>Thank you for your time.</SubHeader>
                    </>
                )

            case 'TIMED_OUT':
                return (
                    <>
                        <ContentHeader>{SPECIFIC_CASE_TEXTS[type]}</ContentHeader>
                        <SubHeader>
                            You have been removed from this session. Thank you for your time.
                        </SubHeader>
                        <Text>Please contact Verbit support if you have any questions.</Text>
                    </>
                )
            case 'REMOVED_BY_NEMO':
                return (
                    <>
                        <ContentHeader>{SPECIFIC_CASE_TEXTS[type]}</ContentHeader>
                        <SubHeader>Thank you for your time!</SubHeader>
                    </>
                )
            case 'REMOVED_BY_OPS':
                return (
                    <>
                        <ContentHeader>{SPECIFIC_CASE_TEXTS[type]}</ContentHeader>
                        <SubHeader>
                            You've been removed from the session. This could have happened for
                            various reasons.
                        </SubHeader>
                        <Text>Please contact Verbit support if you have any questions.</Text>
                    </>
                )
            default:
                return (
                    <>
                        <ContentHeader>{FINISHED_TEXTS[randomNumber]}</ContentHeader>
                        <SubHeader>
                            The current session is over. Thank you for your contribution.
                        </SubHeader>
                        <Text>We will try to find you a new session.</Text>
                    </>
                )
        }
    }

    return (
        <>
            {isTrainingSessionExit ? (
                <TrainingSessionStats sessionStats={sessionStats} type={'LEFT'} />
            ) : isTrainingSession && !isSessionFeedbackAvailable ? (
                <TrainingSessionStats sessionStats={sessionStats} type={'FINISHED'} />
            ) : !isSessionFeedbackAvailable ? (
                <Container>
                    <ContentContainer>
                        {renderMatchingText()}
                        <ButtonWrapper>
                            <StyledButton onClick={onButtonClick}>
                                {sessionHasFinished
                                    ? 'Find a New Session'
                                    : 'Back to Transcriber’s Hub'}
                            </StyledButton>
                        </ButtonWrapper>

                        <IconWrapper>{renderIcon()}</IconWrapper>
                    </ContentContainer>
                    <MetricsBox sessionStats={sessionStats} />
                </Container>
            ) : null}
        </>
    )
}

function getReason(state: string): SessionEndReason {
    switch (state) {
        case 'LEFT':
            return SessionEndReason.USER_EXIT
        case 'FINISHED':
            return SessionEndReason.SESSION_ENDED
        case 'TIMED_OUT':
            return SessionEndReason.TIMED_OUT
        case 'REMOVED_BY_OPS':
            return SessionEndReason.REMOVED_BY_OPS
        case 'REMOVED_BY_NEMO':
            return SessionEndReason.REMOVED_BY_NEMO
        default:
            return SessionEndReason.SESSION_ENDED
    }
}

export const StatsLoader = () => <Spinner title="Waiting for results..." />
