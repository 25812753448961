import { MockControls } from './MockControls/MockControls'
import { MockManager, MockManagerMode, MockManagerOptions } from './MockManager'
import { createMockStaticDecorator } from './mockStatic'
import { createEventEmitterMock } from './mockEventEmitter'
import { createWebsocketClientMockDecorator } from './mockWebsocketClient'

function getMockUrlParam() {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('mock')
}

function getMockStreamOffset() {
    const urlParams = new URLSearchParams(window.location.search)
    const rawOffset = urlParams.get('stream_offset')
    return rawOffset ? parseInt(rawOffset) : undefined
}

function getMockState(): MockManagerOptions {
    const mockParam = getMockUrlParam()

    if (mockParam?.endsWith('.json')) {
        return {
            mode: MockManagerMode.Replay,
            path: mockParam,
            streamOffset: getMockStreamOffset(),
        }
    }

    return { mode: MockManagerMode.Record, trapErrors: true }
}

export const mockManager = new MockManager(getMockState())

//@ts-ignore
window.mockManager = mockManager

/**
 * Mocks a class method. If the mock manager is active, it can either record the return values of the method or replay them.
 *
 * **Note: This decorator can only be used with async methods.**
 * @param resourceId name of the resource to mock
 * @returns A class method decorator
 */
export const mockStatic = createMockStaticDecorator(mockManager)

/**
 * Mocks an event emitter. If the mock manager is active, it can either record the emitted events or replay them.
 * @param resourceId name of the resource to mock
 * @param eventEmitter EventEmitter to mock
 * @returns nothing
 */
export const mockEventEmitter = createEventEmitterMock(mockManager)

export const mockWebSocketClient = createWebsocketClientMockDecorator(mockManager)

export function renderMockControls() {
    // only show the mock controls if the mock param is present
    // regardless wether or not the mock manager is active
    if (!getMockUrlParam()) {
        return null
    }

    return <MockControls manager={mockManager} />
}
