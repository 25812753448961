import { keyBy } from 'lodash/fp'

export interface SpeakerVoiceSampleJSON {
    id: string
    start: number
    end: number
    is_default?: boolean
}

export interface SpeakerJSON {
    id: string
    name: string
    role: string
    samples: SpeakerVoiceSampleJSON[]
    created_at: string
    qac?: string
}

export interface SpeakerRoleJSON {
    role: string
    color: string
    require_name: boolean
    hotkey?: string
}

export interface SpeakersResponseJSON {
    speakers: SpeakerJSON[]
    roles: SpeakerRoleJSON[]
    default_speaker_role?: SpeakerRole
}

export interface SpeakerVoiceSample {
    id: string
    start: number
    end: number
    isDefault?: boolean
}

export interface Speaker {
    id: string
    name: string
    role: string
    samples: { [id: string]: SpeakerVoiceSample }
    indexForRole: number
    createdAt?: string
    qac?: string
}

export interface SpeakerRole {
    role: string
    color: string
    requiresName: boolean
    hotkey?: string
}

export interface SpeakersResponse {
    speakers: Omit<Speaker, 'indexForRole'>[]
    roles: SpeakerRole[]
    defaultSpeakerRole?: SpeakerRole
}

export const COURT_REPORTER_SPEAKER_ROLE = 'Court Reporter'

export const toSpeakersResponse = ({
    roles,
    speakers,
    default_speaker_role,
}: SpeakersResponseJSON): SpeakersResponse => {
    return {
        speakers: speakers.map(({ created_at, samples, ...speaker }) => ({
            ...speaker,
            samples: keyBy(
                'id',
                samples.map(({ is_default, ...sample }) => ({
                    ...sample,
                    isDefault: is_default,
                })),
            ),
            createdAt: created_at,
        })),
        roles: roles.map(({ require_name = true, ...speakerRole }) => ({
            ...speakerRole,
            requiresName: require_name,
        })),
        defaultSpeakerRole: default_speaker_role,
    }
}
