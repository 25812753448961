import * as Styled from './styled'

interface SpeakerLineProps {
    isNewSpeakerTurn: boolean
    isBlockEditable: boolean
    isSpeakerBoxVisible: boolean
}

export const SpeakerLine = ({
    isNewSpeakerTurn,
    isBlockEditable,
    isSpeakerBoxVisible,
}: SpeakerLineProps) => {
    const icon = isNewSpeakerTurn ? <Styled.SpeakerIcon $extraMarginTop={!isBlockEditable} /> : null

    return (
        <Styled.Container contentEditable={false}>
            {icon}
            <Styled.Line
                connectToLineAbove={!isNewSpeakerTurn}
                isBlockEditable={isBlockEditable}
                isSpeakerBoxVisible={isSpeakerBoxVisible}
            />
        </Styled.Container>
    )
}
