import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react'

export function useAudioTimePlayed(ref: RefObject<HTMLAudioElement>) {
    const timeListenedToAudioMsRef = useRef(0)
    const lastPlayTimeRef = useRef(0)
    const isPlayingRef = useRef(false)

    const audioEl = ref.current

    useEffect(() => {
        if (audioEl) {
            if (!audioEl.paused) {
                isPlayingRef.current = true
                lastPlayTimeRef.current = new Date().getTime()
            }

            const onPlay = () => {
                lastPlayTimeRef.current = new Date().getTime()
                isPlayingRef.current = true
            }
            const onPause = () => {
                timeListenedToAudioMsRef.current += new Date().getTime() - lastPlayTimeRef.current
                isPlayingRef.current = false
            }

            audioEl.addEventListener('play', onPlay)
            audioEl.addEventListener('pause', onPause)

            return () => {
                audioEl.removeEventListener('play', onPlay)
                audioEl.removeEventListener('pause', onPause)
            }
        }
    }, [audioEl])

    const getPlayedAudioTime = useCallback(() => {
        let playedTime = timeListenedToAudioMsRef.current

        if (isPlayingRef.current) {
            playedTime += new Date().getTime() - lastPlayTimeRef.current
        }

        return playedTime
    }, [])

    const reset = useCallback(() => {
        timeListenedToAudioMsRef.current = 0

        if (isPlayingRef.current) {
            lastPlayTimeRef.current = new Date().getTime()
        }
    }, [])

    return useMemo(() => ({ getPlayedAudioTime, reset }), [getPlayedAudioTime, reset])
}
