import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Descendant, Editor } from 'slate'
import { useSlateStatic } from 'slate-react'

import { OnboardingTask } from 'src/models'
import {
    useTask,
    useTaskEditorMode,
} from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { EditorContext, useEditorPopup } from 'src/components/Editor/EditorContext'
import { TextEditor } from 'src/components/Editor/TextEditor'
import { useIsSpeakerBoxVisible } from 'src/components/Editor/plugins/withTranscript/hooks/useIsSpeakerBoxVisible'
import { useEditorAudioSync } from 'src/components/Editor/plugins/withTimeline/hooks/useEditorAudioSync'
import { useAudioContextMenu } from 'src/components/Editor/plugins/withTimeline/hooks/useAudioContextMenu'
import { useWordAutoScroll } from 'src/components/Editor/plugins/withTranscript/hooks/useWordAutoScroll'
import { withTags } from 'src/components/Editor/plugins/withTags/withTags'
import { withSuggestions } from 'src/components/Editor/plugins/withSuggestions/withSuggestions'
import { withTimeline } from 'src/components/Editor/plugins/withTimeline/withTimeline'
import { Timeline } from 'src/components/Editor/plugins/withTimeline/timeline'
import { createBlocks } from 'src/components/Editor/plugins/withTranscript/Block'
import { useAudioAutoplay } from 'src/components/Editor/plugins/withTimeline/hooks/useAudioAutoplay'
import { useAudioTimingConverter } from 'src/state/TaskAudioContext'

import { useOnboardingStepPayload } from '../useOnboardingStepPayload'
import { EditorWrapper, TranscriptStepWrapper } from './common'
import { ReadOnlyEditorProps } from './TranscriptLatestStep'
import { AudioPlayer } from '../../AudioPlayer'
import { withTranscript } from 'src/components/Editor/plugins/withTranscript'
import { useCurrentTimeRef } from 'src/components/Editor/plugins/withTimeline/hooks/useCurrentTimeRef'
import { useSessionMediaCurrentTime } from 'src/state/SessionMediaProvider'
import { HeuristicAligner } from 'src/components/Editor/plugins/withTimeline/align/HeuristicAligner'

interface TranscriptIntroStepProps {
    editorScrollableWrapperRef: React.RefObject<HTMLDivElement>
}

export function TranscriptIntroStep({ editorScrollableWrapperRef }: TranscriptIntroStepProps) {
    const { sessionStatus } = useSessionStatus(['sessionStatus', 'sessionStatus.sessionId'])
    const task = useTask<OnboardingTask>()
    const editorMode = useTaskEditorMode()
    const payload = useOnboardingStepPayload('transcript_intro')

    const [initialContent, timeline] = useMemo(() => {
        const [body, timings] = createBlocks(payload.body, {
            editable: false,
            isFirstSegment: true,
        })

        const timeline = new Timeline({
            segmentTimings: payload.timing,
            aligner: new HeuristicAligner(),
            initialSegment: timings,
        })

        return [body.flat(), timeline]
    }, [payload.body, payload.timing])

    const isSpeakerLineVisible = payload.controls.diarization.visible
    const isRuntimeTimestampsVisible = task?.payload?.controls?.runtimeTimestamp?.visible
    const currentTimeRef = useCurrentTimeRef()

    const plugins = useMemo(
        () => [
            withTranscript({ isSpeakerLineVisible }),
            withTimeline({ timeline, currentTimeRef }),
            withSuggestions,
            withTags({
                enableGlossary: true,
                enableUnclears: true,
                enableLabels: task.payload.controls.label.visible ?? false,
                labels: task.payload.controls.label.items,
            }),
        ],
        [
            currentTimeRef,
            isSpeakerLineVisible,
            task.payload.controls.label.items,
            task.payload.controls.label.visible,
            timeline,
        ],
    )

    const [content, setContent] = useState<Descendant[]>(initialContent)
    const onChange = useCallback((newContent: Descendant[]) => {
        setContent(newContent)
    }, [])

    useAudioTimingConverter(
        () => ({
            timing: payload.timing,
            duration: payload.timing.end - payload.timing.start,
            requiredRangeToBePlayed: payload.controls.audio.forceListen
                ? payload.timing
                : undefined,
            requiredRangeToBeBuffered: payload.timing,
            activeSegment: { start: 0, end: 0 },
        }),
        [payload],
    )

    if (!sessionStatus) {
        return null
    }

    return (
        <EditorContext
            plugins={plugins}
            content={content}
            onChange={onChange}
            meta={{
                controls: payload.controls,
                editorMode,
                taskId: task.id,
                sessionId: sessionStatus?.sessionId,
            }}
        >
            <ReadOnlyEditor
                key={task.id}
                editorScrollableWrapperRef={editorScrollableWrapperRef}
                isRuntimeTimestampsVisible={isRuntimeTimestampsVisible}
            />
        </EditorContext>
    )
}

function ReadOnlyEditor({
    editorScrollableWrapperRef,
    isRuntimeTimestampsVisible,
}: ReadOnlyEditorProps) {
    const editor = useSlateStatic()
    const { controls } = useOnboardingStepPayload('transcript_intro')
    useSessionMediaCurrentTime() // use it as an update-subscription to currentTime
    const editorRef = useRef<HTMLDivElement>(null)

    const isSpeakerBoxVisible = useIsSpeakerBoxVisible(controls)
    const { openPopup } = useEditorPopup()

    useMousetrap(
        'mod+d',
        useCallback(() => openPopup('context'), [openPopup]),
        { label: 'Text: Open context menu', preventDefault: true },
    )

    useWordAutoScroll(editorScrollableWrapperRef)
    useEditorAudioSync(editorRef)
    useAudioAutoplay(controls.audio.autoplay ?? false)
    useAudioContextMenu()

    // force normalize initial document so we can be sure, that everything is, as it should be
    useEffect(() => {
        Editor.normalize(editor, { force: true })
    }, [editor])

    return (
        <TranscriptStepWrapper>
            <EditorWrapper isSpeakerBoxVisible={isSpeakerBoxVisible}>
                <TextEditor editorRef={editorRef} readOnly />
            </EditorWrapper>
            <AudioPlayer isRuntimeTimestampsVisible={isRuntimeTimestampsVisible} />
        </TranscriptStepWrapper>
    )
}
