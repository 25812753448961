import { PlayIconSVG } from 'src/components/icons'
import { getTimeByPoint } from './useEditorAudioSync'
import { BuilderFn, useContextMenuBuilder } from 'src/components/ContextMenu/ContextMenuBuilder'
import { useAnalytics } from 'src/analytics'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

/**
 * Adds the "Play From Here" item to the editor context menu
 */
export function useAudioContextMenu() {
    const analytics = useAnalytics()
    const { seekTime, play } = useSessionMedia(['play', 'seekTime'])

    const audioContextMenuBuilder: BuilderFn = (menu) => {
        if (!menu.hasItemWithId('playFromHere')) {
            menu.addItem({
                id: 'playFromHere',
                label: 'Play From Here',
                order: 30,
                icon: <PlayIconSVG style={{ padding: 2 }} />,
                onAction: (editor, range) => {
                    const e = editor
                    const point = range.current?.anchor

                    if (!point) {
                        return
                    }

                    const time = getTimeByPoint(e, point)

                    if (time) {
                        analytics?.sendContextMenuPlayFromHere()
                        seekTime(time)
                    }

                    play()
                },
            })
        }
    }

    useContextMenuBuilder('general', audioContextMenuBuilder, [play, seekTime])
    useContextMenuBuilder('generalEditable', audioContextMenuBuilder, [play, seekTime])
}
