import { useContext, useMemo, createContext, ReactChild } from 'react'
import { useMachine } from '@xstate/react'

import { getEnv } from 'src/utils/env'

import { useMachineDeps } from '../useMachineDeps'
import { AppMachine } from './AppMachine'

export const AppMachineContext = createContext<{
    machine: AppMachine
    state: AppMachine['state']
} | null>(null)

interface AppMachineProviderProps {
    children: ReactChild
}

export function AppMachineProvider({ children }: AppMachineProviderProps) {
    const [state, , service] = useMachine(AppMachine, { devTools: getEnv() === 'dev' })
    const props = useMemo(
        () => ({
            machine: service,
            state,
        }),
        [service, state],
    )

    // @ts-ignore
    window.sendAppEvent = service.send

    return <AppMachineContext.Provider value={props}>{children}</AppMachineContext.Provider>
}

type AppMachineProperties = 'state' | keyof AppMachine['state']['context']

export function useAppMachine(
    deps: AppMachineProperties[],
): [AppMachine['state'], AppMachine['send'], AppMachine] {
    const props = useContext(AppMachineContext)
    if (!props) throw new Error('You forgot to use <AppMachineProvider />.')

    useMachineDeps(props.machine, deps)

    return [props.state, props.machine.send, props.machine]
}
