import React from 'react'
import { Spinner as BpSpinner } from '@blueprintjs/core'
import styled, { keyframes } from 'styled-components/macro'

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(75px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
`

const CenterBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center;
    border-radius: 12px;
    opacity: 0;
    animation: ${fadeIn} 750ms 180ms ease-in-out forwards;

    & .bp4-spinner-animation {
        animation-duration: 800ms;
    }
`

const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin: 25px 0 6px;
`

const Subtitle = styled.div`
    font-size: 14px;
`

interface SpinnerProps {
    title?: string | React.ReactNode
    subtitle?: string | React.ReactNode
    className?: string
}

export const Spinner = ({ title, subtitle, className }: SpinnerProps) => {
    return (
        <CenterBox className={className}>
            <BpSpinner size={40} />
            <Title>{title}</Title>
            <Subtitle>{subtitle ?? ''}</Subtitle>
        </CenterBox>
    )
}
