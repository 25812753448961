import React, { useState, useCallback } from 'react'
import { useKey } from 'react-use'

import * as Styled from './styled'
import { Row, RowProps } from './Row'
import { ActiveItemSource } from './useGlossaryList'

interface SubmenuProps {
    title?: string
    items: RowProps[]
    submenuProps: Styled.SubmenuProps
    minWidth?: number
}

export const Submenu = ({ title, items, submenuProps }: SubmenuProps) => {
    const [activeItemIdx, setActiveItemIdx] = useState(0)
    const [, setActiveItemSource] = useState<ActiveItemSource>('mouse')

    const handleItemMouseOver = useCallback((index: number) => {
        setActiveItemIdx(index)
        setActiveItemSource('mouse')
    }, [])

    const handleItemClick = useCallback(
        (e: React.MouseEvent<HTMLElement, Event>, index: number) => {
            e.stopPropagation()

            const item = items[index]

            if (!item.disabled) {
                item.onClick?.(e)
            }
        },
        [items],
    )

    const handleDownArrow = useCallback(() => {
        setActiveItemIdx((prevIdx) => (prevIdx === items.length - 1 ? 0 : prevIdx + 1))
        setActiveItemSource('keyboard')
    }, [items.length])

    const handleUpArrow = useCallback(() => {
        setActiveItemIdx((prevIdx) => (prevIdx === 0 ? items.length - 1 : prevIdx - 1))
        setActiveItemSource('keyboard')
    }, [items.length])

    const handleEnter = useCallback(
        (e: Event) =>
            handleItemClick(e as unknown as React.MouseEvent<HTMLElement, Event>, activeItemIdx),
        [handleItemClick, activeItemIdx],
    )

    useKey('ArrowDown', handleDownArrow)
    useKey('ArrowUp', handleUpArrow)
    useKey('Enter', handleEnter, {}, [activeItemIdx, items])

    return (
        <Styled.ItemSubmenu style={submenuProps}>
            {title && <Styled.ItemSubmenuTitle>{title}</Styled.ItemSubmenuTitle>}

            <Styled.List>
                {items.map((item, i) => (
                    <Row
                        key={i}
                        text={item.text}
                        icon={item.icon}
                        active={i === activeItemIdx}
                        expanded={false}
                        withChevron={false}
                        onMouseOver={() => handleItemMouseOver(i)}
                        onClick={(e) => handleItemClick(e, i)}
                        actionButtonLabel={item.actionButtonLabel}
                        disabledLabel={item.disabledLabel}
                        disabled={item.disabled}
                        visibleOnRowHoverChildren={item.visibleOnRowHoverChildren}
                    />
                ))}
            </Styled.List>
        </Styled.ItemSubmenu>
    )
}
