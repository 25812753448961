import { ReactNode, useState } from 'react'
import styled from 'styled-components/macro'
import { ifProp, prop, withProp } from 'styled-tools'
import { format, formatDistanceToNow } from 'date-fns'
import { transparentize } from 'polished'

import { Role } from 'src/network/responses'
import { useTaskValidator } from 'src/state/TaskValidationProvider'
import { Checkbox } from 'src/components/Checkbox/Checkbox'
import { getEnv } from 'src/utils/env'
import {
    CorrectorRoleLabelIcon,
    AnnotatorRoleLabelIcon,
    ReviewerRoleLabelIcon,
    GlosserRoleLabelIcon,
    EarningCallIcon,
    MediaTypeIcon,
    DepositionTypeIcon,
    EducationTypeIcon,
    AttentionIcon,
} from 'src/components/icons'

import { useOnboardingStepPayload } from '../useOnboardingStepPayload'
import { Title } from './common'

const Section = styled.div`
    margin-bottom: 40px;
`

const Row = styled.div`
    display: flex;
    width: 100%;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 45%;
    margin-right: 5%;
`

const ErrorMessage = styled.p`
    color: #eb0011;
    display: flex;
    align-items: center;
    width: calc(100% + 8px);

    margin-top: 12px;
    margin-bottom: 24px;
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
`

const ColoredTitle = styled(Title)<{ primary?: boolean }>`
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    color: #5c7199;
    margin-bottom: ${ifProp('primary', 13, 6)}px;
`

const RoleIconWrapper = styled.div<{ color?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin-right: 8px;
    background-color: ${withProp(prop('color'), transparentize(0.75))};
    border-radius: 50%;

    &:before {
        content: '';
        position: absolute;
        width: 28px;
        height: 28px;
        background-color: ${prop('color')};
        border-radius: 50%;
    }

    svg {
        z-index: 1;
    }
`

const SessionTypeIconWrapper = styled(RoleIconWrapper)`
    background-color: transparent;
    margin-right: 0;

    &:before {
        content: '';
        background-color: transparent;
    }

    svg {
        width: 25px;
        height: 25px;
    }
`

const RoleLabel = styled.div<{ color?: string }>`
    margin-right: 8px;
    color: ${prop('color')};
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
`

const SessionTypeLabel = styled(RoleLabel)`
    color: #10264a;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
`

const ResponsibilityLi = styled.li`
    margin-left: 15px;
`

const Responsibility = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    color: #10264a;

    button {
        transform: translateY(2px);
    }
`

const SessionDetails = styled.div`
    display: grid;
    grid-template-columns: auto auto;
`

const Property = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #10264a;
    line-height: 36px;
`

const Value = styled(Property)`
    font-weight: 500;
    margin-left: 20px;
`

const SwitchingRolesTitle = styled(Title)`
    font-weight: 300;
    display: block;
`

const Bolded = styled.span`
    font-weight: 500;
`

const UnorderedListSection = styled.ul`
    padding-left: 0;
    margin: 0 50px 0 0;
`

const ErrorIcon = styled(AttentionIcon)`
    margin-right: 8px;
`

export const SessionIntroductionStep = () => {
    const {
        userRole,
        sessionName = '',
        startedAt,
        roleSwitch,
        vertical,
        responsibilities = [],
    } = useOnboardingStepPayload('session_intro')
    const [responsibilitiesIndexes, setResponsibilitiesIndexes] = useState(() =>
        // TODO: use Dict instead
        Array.from(Array(responsibilities.length).keys()),
    )
    const [error, setError] = useState(false) // TODO: use useTaskValidator return value instead

    const handleCheckboxClick = (index: number) => {
        if (!responsibilitiesIndexes.includes(index)) {
            setResponsibilitiesIndexes((lastArr) => lastArr.concat(index))
        } else {
            setResponsibilitiesIndexes((lastArr) => lastArr.filter((item) => index !== item))
        }
    }

    useTaskValidator('unmarkedResponsibilities', () => {
        if (getEnv() === 'dev') return true
        setError(true)
        return !responsibilitiesIndexes.length
    })

    const renderSessionType = () => {
        if (!vertical || !sessionTypesIcons[vertical]) return null
        return (
            <Section>
                <ColoredTitle primary>Session Type</ColoredTitle>

                <TitleBox>
                    <SessionTypeIconWrapper color="black">
                        {sessionTypesIcons[vertical].icon}
                    </SessionTypeIconWrapper>

                    <SessionTypeLabel>{sessionTypesIcons[vertical].label}</SessionTypeLabel>
                </TitleBox>
            </Section>
        )
    }

    return (
        <Row>
            <Column>
                <Section data-testid={'role-text-container'}>
                    {!roleSwitch ? (
                        <ColoredTitle primary>Your Role</ColoredTitle>
                    ) : (
                        <SwitchingRolesTitle>
                            This is the <Bolded>same session</Bolded> but your role is now:
                        </SwitchingRolesTitle>
                    )}

                    <TitleBox>
                        <RoleIconWrapper color={roleAttributes[userRole].color}>
                            {roleAttributes[userRole].icon}
                        </RoleIconWrapper>

                        <RoleLabel color={roleAttributes[userRole].color}>{userRole}</RoleLabel>
                    </TitleBox>
                </Section>

                <UnorderedListSection>
                    <ColoredTitle>Approve each of your tasks for this session</ColoredTitle>

                    {error && (
                        <ErrorMessage>
                            <ErrorIcon /> Please read and approve all your tasks to continue
                        </ErrorMessage>
                    )}

                    {responsibilities.map(({ id, text }, index) => (
                        <ResponsibilityLi key={id}>
                            <Responsibility>
                                <div>{text}</div>
                                <Checkbox index={index} handleChange={handleCheckboxClick} />
                            </Responsibility>
                        </ResponsibilityLi>
                    ))}
                </UnorderedListSection>
            </Column>

            <Column>
                {renderSessionType()}

                <Section>
                    <ColoredTitle>Session Details</ColoredTitle>

                    <SessionDetails>
                        <>
                            <Property>Session name:</Property>

                            <Value>
                                {sessionName} {startedAt && format(startedAt, 'dd/MM')}
                            </Value>
                        </>

                        <>
                            <Property>Started:</Property>

                            <Value>
                                {startedAt
                                    ? formatDistanceToNow(startedAt, { addSuffix: true })
                                    : 'Not started yet'}
                            </Value>
                        </>
                    </SessionDetails>
                </Section>
            </Column>
        </Row>
    )
}

interface RoleItem {
    icon: ReactNode
    color: string
}

const roleAttributes: { [role in Role]: RoleItem } = {
    Corrector: {
        icon: <CorrectorRoleLabelIcon />,
        color: '#8C5C99',
    },
    Annotator: {
        icon: <AnnotatorRoleLabelIcon />,
        color: '#CE930D',
    },
    Proofer: {
        icon: <ReviewerRoleLabelIcon />,
        color: '#639E2D',
    },
    Glosser: {
        icon: <GlosserRoleLabelIcon />,
        color: '#05B2DC',
    },
}

const sessionTypesIcons: { [fileType: string]: { label: string; icon: JSX.Element } } = {
    earning_call: {
        label: 'Earning Call',
        icon: <EarningCallIcon />,
    },
    legal: {
        label: 'Deposition',
        icon: <DepositionTypeIcon />,
    },
    education: {
        label: 'Live Education',
        icon: <EducationTypeIcon />,
    },
    media: {
        label: 'Media',
        icon: <MediaTypeIcon />,
    },
}
