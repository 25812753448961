import React, { useCallback } from 'react'

import styled from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'

import * as Icons from 'src/components/icons'

const Container = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${palette('cloudBlueLight', 2)};
    border-bottom: 1px solid ${palette('cloudBlueLight', 1)};
`

const Text = styled.span`
    font-weight: bold;
    line-height: 19px;
    margin-left: 12px;
`

export const DownloadButton = styled.a<{ downloadable: boolean }>`
    width: 127px;
    height: 28px;
    margin-right: 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    background: ${palette('cloudBlueLight', 1)};
    border: 1px solid ${palette('cloudBlueLight', 4)};
    color: ${palette('navy', 2)};
    pointer-events: ${ifProp('downloadable', 'auto', 'none')};

    &:hover {
        text-decoration: none;
    }
`

export const DownloadIcon = styled(Icons.DownloadIcon)`
    margin-right: 7px;
`

interface ViewerTopBarProps {
    currentFile: string
    currentFileName: string
    onDownload?: (fileUrl: string) => unknown
}

export const ViewerTopBar = ({ currentFile, currentFileName, onDownload }: ViewerTopBarProps) => {
    const onClickDownload = useCallback(() => {
        onDownload?.(currentFile)
    }, [currentFile, onDownload])

    return (
        <Container>
            <Text>{currentFileName}</Text>
            <DownloadButton
                downloadable={!!currentFile}
                target="_blank"
                download
                href={currentFile}
                onClick={onClickDownload}
            >
                <DownloadIcon />
                Download
            </DownloadButton>
        </Container>
    )
}
