import {
    useState,
    useCallback,
    useEffect,
    useRef,
    createContext,
    useContext,
    PropsWithChildren,
    useMemo,
    MutableRefObject,
} from 'react'
import { every, omit } from 'lodash/fp'
import useDidMount from 'beautiful-react-hooks/useDidMount'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'

interface TaskValidationState {
    [validationName: string]: boolean | undefined
}

type TaskValidator = () => boolean

interface TaskValidators {
    [validationName: string]: TaskValidator
}

interface TaskValidationContextType {
    taskValidationState: TaskValidationState
    validateTask: () => boolean
    resetTaskValidations: () => void
    resetTaskValidation: (validationName: string) => void
    taskValidatorsRef: MutableRefObject<TaskValidators>
}

const TaskValidationContext = createContext<TaskValidationContextType>({
    taskValidationState: {},
    validateTask: () => true,
    resetTaskValidations: () => {},
    resetTaskValidation: () => {},
    taskValidatorsRef: { current: {} },
})

export const TaskValidationProvider = ({ children }: PropsWithChildren<{}>) => {
    const [taskValidationState, setTaskValidationState] = useState<TaskValidationState>({})
    const taskValidatorsRef = useRef<TaskValidators>({})

    const validateTask = useCallback(() => {
        const nextTaskValidationState = Object.entries(taskValidatorsRef.current).reduce(
            (acc, [validationName, validate]) => ({
                ...acc,
                [validationName]: validate(),
            }),
            {},
        )

        setTaskValidationState(nextTaskValidationState)

        return every(Boolean, nextTaskValidationState)
    }, [])

    const resetTaskValidations = useCallback(() => setTaskValidationState({}), [])

    const resetTaskValidation = useCallback(
        (key: string) => setTaskValidationState(omit([key])),
        [],
    )

    return (
        <TaskValidationContext.Provider
            value={useMemo<TaskValidationContextType>(
                () => ({
                    taskValidationState,
                    validateTask,
                    resetTaskValidations,
                    resetTaskValidation,
                    taskValidatorsRef,
                }),
                [taskValidationState, validateTask, resetTaskValidations, resetTaskValidation],
            )}
        >
            {children}
        </TaskValidationContext.Provider>
    )
}

export const useTaskValidation = () => {
    const taskValidationContext = useContext(TaskValidationContext)

    if (!taskValidationContext) {
        throw new Error('You forgot to use <TaskValidationProvider/>.')
    }

    return useMemo(
        () => omit(['taskValidatorsRef'], taskValidationContext),
        [taskValidationContext],
    )
}

export const useTaskValidator = (
    validatorName: string,
    validator: TaskValidator,
): [boolean | undefined, () => void] => {
    const taskValidationContext = useContext(TaskValidationContext)

    if (!taskValidationContext) {
        throw new Error('You forgot to use <TaskValidationProvider/>.')
    }

    const { taskValidatorsRef, taskValidationState, resetTaskValidation } = taskValidationContext
    const registeredValidatorNameRef = useRef(validatorName)

    const resetValidationValue = useCallback(
        () => resetTaskValidation(validatorName),
        [resetTaskValidation, validatorName],
    )

    useDidMount(() => {
        const validatorNames = Object.keys(taskValidatorsRef.current)

        if (validatorNames.includes(validatorName)) {
            throw new Error(
                `The validator '${validatorName}' is already registered by another Component.`,
            )
        }
    })

    useEffect(() => {
        if (validatorName !== registeredValidatorNameRef.current) {
            throw new Error(`The validator name cannot be dynamic.`)
        }

        taskValidatorsRef.current = {
            ...taskValidatorsRef.current,
            [validatorName]: validator,
        }
    }, [validatorName, validator, taskValidatorsRef])

    useWillUnmount(() => {
        taskValidatorsRef.current = omit([validatorName], taskValidatorsRef.current)
    })

    return [taskValidationState[validatorName], resetValidationValue]
}
