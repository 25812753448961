import styled, { css, keyframes } from 'styled-components'
import { palette, ifProp } from 'styled-tools'

type Color = 'green' | 'red' | 'orange'

interface StatusIndicatorProps {
    variant: Color
    isGlowing?: boolean
}

const statusAnimation = keyframes`
  0% {
    ::-moz-transform: scale(0.9);
    ::-ms-transform: scale(0.9);
    ::-webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    ::-moz-transform: scale(1);
    ::-ms-transform: scale(1);
    ::-webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 15px 25px rgba(196, 66, 84, 0);
  }
  100% {
    ::-moz-transform: scale(0.9);
    ::-ms-transform: scale(0.9);
    ::-webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 25px rgba(251, 134, 134, 0);
  }
`

const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
`

const StyledIndicator = styled.span<StatusIndicatorProps>`
    position: relative;
    display: block;
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background: ${ifProp(
        'variant',
        ({ variant }) => {
            if (variant === 'green') {
                return palette(variant, 3)
            } else {
                return palette(variant, 0)
            }
        },
        `inherit`,
    )};
    box-shadow: ${ifProp(
        'variant',
        ({ variant }) => {
            if (variant === 'green') {
                return '0 0 10px rgba(0, 255, 0, 0.5)'
            } else if (variant === 'red') {
                return '0 0 10px rgba(255, 0, 0, 0.5)'
            } else if (variant === 'orange') {
                return '0 0 10px rgba(255, 165, 0, 0.5)'
            }
        },
        'inherit',
    )};
    animation: ${ifProp(
        'isGlowing',
        css`
            ${statusAnimation} 2s infinite
        `,
        'none',
    )};
    ::-webkit-animation: ${ifProp(
        'isGlowing',
        css`
            ${statusAnimation} 2s infinite
        `,
        'none',
    )};
`

export const StatusIndicator = ({ variant, isGlowing = true }: StatusIndicatorProps) => {
    return (
        <Container id="status-indicator">
            <StyledIndicator variant={variant} isGlowing={isGlowing} />
        </Container>
    )
}
