import { Node, BaseElement, Path } from 'slate'

import { Word } from 'src/models'
import { isPunctuation } from 'src/utils/string'

import { ContentText } from './ContentText'
import { Tag } from '../withTags/Tag'
import { Suggestion } from '../withSuggestions/Suggestion'
import { TimelineTimingRange } from '../withTimeline/timeline'

export interface Content extends BaseElement {
    type: 'content'
    editable: boolean
    children: Array<ContentText | Tag | Suggestion>
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Content = {
    isContent: (value: any): value is Content => {
        return value?.type === 'content'
    },

    offsetTo: (content: Content, leaf: Path): number => {
        let offset = 0

        if (leaf.length > 1 || leaf[0] > 0) {
            const prev = Path.previous(leaf)
            for (const [n] of Node.texts(content, { to: prev })) {
                offset += n.text.length
            }
        }

        return offset
    },
}

export function createContent(
    words: Word[],
    { editable }: { editable: boolean },
): [Content, TimelineTimingRange[]] {
    const timings: TimelineTimingRange[] = []
    let children: Array<ContentText | Tag | Suggestion> = []
    const groupedWords = groupBy(shouldGroupWords, words)

    for (const [groupIdx, group] of groupedWords.entries()) {
        const prevIsTag = groupIdx > 0 && !!groupedWords[groupIdx - 1]?.[0]?.tag
        const nextIsTag =
            groupIdx < groupedWords.length - 1 && !!groupedWords[groupIdx + 1]?.[0]?.tag
        const currentIsTag = !!groupedWords[groupIdx]?.[0]?.tag
        const currentStartsWithPunctuation = isPunctuation(groupedWords[groupIdx]?.[0]?.text)
        let text = ''

        for (let i = 0; i < group.length; i++) {
            // Indicate that the word json is invalid - has whitespaces
            if (/\s+/.test(group[i].text)) {
                window.Rollbar?.error('Word json with whitespace detected:', group[i])
            }

            let curr = group[i]
            const currIsPunctuation = isPunctuation(curr.text)

            if (!currIsPunctuation && i !== 0) {
                text += ' '
            }

            text += curr.text

            if (curr.type === 'word' && !!curr.text) {
                if (currIsPunctuation && timings.length > 0) {
                    timings[timings.length - 1].end = curr.timing.end
                } else {
                    timings.push({ ...curr.timing, isOrginal: true })
                }
            }
        }

        if (prevIsTag && !currentStartsWithPunctuation && !currentIsTag) text = ' ' + text
        if (nextIsTag && !currentIsTag) text = text + ' '

        const textNode: ContentText = {
            type: 'text',
            text,
            editable,
        }

        // using group[0] because it is assumed that all items have the same tag prop
        if (!!group[0].tag) {
            const { seq, ...data } = group[0].tag
            if (prevIsTag) {
                children.push({
                    type: 'text',
                    text: ' ',
                    editable,
                })
            }
            switch (data.type) {
                case 'unclear':
                    children.push({
                        type: 'tag',
                        kind: 'selection',
                        seq,
                        tagType: 'unclear',
                        children: [textNode],
                    })
                    break
                case 'glossary': {
                    const { type, ...rest } = data
                    children.push({
                        type: 'tag',
                        kind: 'selection',
                        seq,
                        tagType: 'glossary',
                        children: [textNode],
                        ...rest,
                    })
                    break
                }
                case 'label': {
                    const { type, ...rest } = data

                    // make sure that the void node text is empty to avoid complications with the aligner
                    textNode.text = ''

                    children.push({
                        type: 'tag',
                        kind: 'void',
                        seq,
                        tagType: 'label',
                        children: [textNode],
                        ...rest,
                    })
                    break
                }
                case 'suggestion': {
                    const { type, ...rest } = data
                    children.push({
                        type: 'suggestion',
                        originalText: textNode.text,
                        seq,
                        children: [textNode],
                        ...rest,
                    })
                    break
                }
            }
        } else {
            children.push(textNode)
        }
    }

    // Insert an empty text node as a child if there are no children after this process
    children = children.length > 0 ? children : [{ type: 'text', text: '', editable }]

    return [
        {
            type: 'content',
            editable,
            children,
        },
        timings,
    ]
}

function shouldGroupWords(a: Word, b: Word) {
    return (!a.tag && !b.tag) || (a.tag?.type === b.tag?.type && a.tag?.seq === b.tag?.seq)
}

function groupBy<T>(predicate: (a: T, b: T) => boolean, list: T[]): T[][] {
    const res = []
    let idx = 0
    const len = list.length
    while (idx < len) {
        let nextidx = idx + 1
        while (nextidx < len && predicate(list[nextidx - 1], list[nextidx])) {
            nextidx += 1
        }
        res.push(list.slice(idx, nextidx))
        idx = nextidx
    }

    return res
}
