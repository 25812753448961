import React from 'react'
import styled from 'styled-components/macro'
import { palette, prop } from 'styled-tools'

import { formatDuration } from 'src/utils/time'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
`

const TimeLabel = styled.div`
    font-size: 16px;
    line-height: 36px;
    color: ${palette('white', 0)};
`

const Time = styled.div`
    font-size: 72px;
    font-weight: 500;
    color: #00deca;
`

const DurationProgressBarContainer = styled.div`
    width: 100%;
    height: 2px;
`

const DurationProgressBar = styled.div<{ duration: number }>`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #627293;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #00deca;
        animation: progress ${prop('duration')}s linear;

        @keyframes progress {
            from {
                width: 0;
            }
            to {
                width: 100%;
            }
        }
    }
`

interface OnboardingTaskTimerProps {
    timeLeft: number
    warningDuration: number
}

export const OnboardingTaskTimer = ({ timeLeft, warningDuration }: OnboardingTaskTimerProps) => (
    <Container>
        <TimeLabel>Finish and get a task in:</TimeLabel>
        <Time data-testid={'finish-and-get-task-timer'}>{formatDuration(timeLeft)}</Time>

        <DurationProgressBarContainer>
            {timeLeft <= warningDuration && <DurationProgressBar duration={warningDuration} />}
        </DurationProgressBarContainer>
    </Container>
)
