import NSpell from 'nspell'

export type DictionaryOptions = 'en'

export type RawDictionary = { aff: string; dic: string }

export interface DictionaryCache {
    load(dictionary: DictionaryOptions): Promise<RawDictionary>
}

export class SpellcheckerAPI {
    private raw: RawDictionary | null = null
    private nspell: NSpell | null = null
    private addedWords = new Set<string>()
    private ignoredPatternsRegex: RegExp[] = [new RegExp('^\\d|\\$'), new RegExp('^Q[1-4]$')]
    private ignoredCharacters: string[] = ['[', ']']

    private cache: DictionaryCache

    constructor(cache: DictionaryCache) {
        this.cache = cache
    }

    async loadDictionary(dic: DictionaryOptions) {
        this.raw = await this.cache.load(dic)
        this.nspell = NSpell(this.raw)

        // sync current added words
        for (const word of Object.keys(this.addedWords)) {
            this.nspell.add(word)
        }
    }

    addWordToDictionary(word: string) {
        if (this.addedWords.has(word)) {
            return
        }

        this.addedWords.add(word)
        if (this.nspell) {
            this.nspell.add(word)
        }
    }

    removeWordFromDictionary(word: string) {
        if (!this.addedWords.has(word)) {
            return
        }

        this.addedWords.delete(word)
        if (this.nspell) {
            this.nspell.remove(word)
        }
    }

    hasWordInDictionary(word: string) {
        return !!this.addedWords.has(word)
    }

    getAddedWords(): string[] {
        return Object.keys(this.addedWords)
    }

    isCorrect(word: string) {
        const patternsCheck = this.ignoredPatternsRegex.some((regex) => regex.test(word))
        if (patternsCheck) {
            return true
        }

        let updatedWord = word
        this.ignoredCharacters.forEach((char) => {
            if (updatedWord.includes(char)) {
                updatedWord = updatedWord.replace(char, '')
            }
        })

        return this.nspell ? this.nspell.correct(updatedWord) : true
    }

    suggest(word: string) {
        return this.nspell ? this.nspell.suggest(word) : []
    }
}
