import { CSSProperties } from 'react'
import styled, { css } from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'

const TOP_BOTTOM_SPACING = 6
const LEFT_RIGHT_SPACING = 6

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 220px;
    height: 100%;
    color: ${palette('cloudBlue', 0)};
    background-color: ${palette('white', 0)};
    border: solid 1px ${palette('cloudBlueLight', 0)};
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    user-select: none;
`

export const SearchBar = styled.div`
    display: flex;
    align-items: center;
    height: 28px;
    margin: ${TOP_BOTTOM_SPACING}px ${LEFT_RIGHT_SPACING}px;
    padding: 0 ${LEFT_RIGHT_SPACING}px;
    cursor: text;
    border-radius: 4px;
    border: solid 1px ${palette('cloudBlueLight', 0)};
    background-color: ${palette('white', 0)};

    svg {
        color: ${palette('cloudBlue', 0)};
    }
`

export const SearchInput = styled.input`
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 ${LEFT_RIGHT_SPACING}px 0 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: ${palette('navy', 2)};
    background: none;
    border: none;
    resize: none;

    &::placeholder {
        color: ${palette('cloudBlue', 0)};
        font-style: italic;
    }
`

export const ListScroller = styled.div`
    min-height: 0;
    overflow: hidden auto;
`

export const List = styled.div`
    height: 100%;
`

interface ItemRowProps {
    active: boolean
    disabled?: boolean
}

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${palette('cloudBlueLight', 2)};
`

export const ItemIcon = styled.div`
    display: flex;
    align-items: center;
    margin-left: 1px;
    color: ${palette('navy', 2)};
    transition: color 150ms ease-out;

    svg {
        width: 16px;
        height: 16px;
    }
`

export const ItemText = styled.div`
    margin-left: ${LEFT_RIGHT_SPACING}px;
    margin-right: 14px;

    display: flex;
    align-items: center;
`

export const ItemRowChevron = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 6px;

    svg {
        width: auto;
        height: 16px;
    }
`

export const VisibleOnRowActive = styled.div``

export const ITEM_ROW_MIN_HEIGHT = 36
export const ItemRow = styled.div<ItemRowProps>`
    display: flex;
    justify-content: start;
    align-items: center;
    min-height: ${ITEM_ROW_MIN_HEIGHT}px;
    padding: 4px ${LEFT_RIGHT_SPACING}px;
    color: ${palette('navy', 0)};
    word-break: break-word;
    cursor: ${ifProp('disabled', 'default', 'pointer')};
    transition: background-color 150ms ease-out;

    ${VisibleOnRowActive} {
        opacity: 0;
        transition: opacity 150ms ease-out;
    }

    ${ifProp(
        'active',
        css`
            background-color: ${palette('cloudBlueLight', 2)};

            > ${VisibleOnRowActive} {
                opacity: 1;
            }

            > ${ItemIcon} {
                color: ${palette('blue', 0)};
            }
        `,
    )}

    ${ifProp(
        'disabled',
        css`
            > ${ItemIcon} {
                color: ${palette('cloudBlue', 2)};
            }

            > ${ItemText} {
                color: ${palette('cloudBlue', 0)};
            }
        `,
    )}
`

export type SubmenuProps = Pick<CSSProperties, 'top' | 'left' | 'right' | 'minWidth' | 'maxHeight'>

export const ItemSubmenu = styled.div`
    position: absolute;
    background-color: ${palette('white', 0)};
    border: solid 1px ${palette('cloudBlueLight', 0)};
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    word-break: normal;
`

export const ItemSubmenuTitle = styled.div`
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: ${palette('greyBlue', 0)};
    border-bottom: 1px solid ${palette('cloudBlueLight', 0)};
`

export const ItemActionButton = styled.button`
    padding: 4px 12px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: ${palette('navy', 2)};
    color: ${palette('white', 0)};
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    cursor: pointer;
`
export const DisabledLabel = styled.div`
    max-width: 70px;
    font-size: 14px;
    line-height: 14px;
    color: #6782a4;
`
