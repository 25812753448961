import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { AppWebSocketClient } from 'src/network/AppWebSocketClient'
import { useLiveMachine } from './state-machines/LiveMachine/LiveMachineProvider'

const AppEventsContext = createContext<AppWebSocketClient | null | undefined>(undefined)
export const RECONNECTION_TIMEOUT = 1000
export const KEEP_ALIVE_INTERVAL = 1000 * 60 * 3

export const AppEventsProvider = ({ children }: { children: ReactNode }) => {
    const [{ context }] = useLiveMachine(['websocketUrl'])
    const [webSocketClient, setWebSocketClient] = useState<AppWebSocketClient | null>(null)

    useEffect(() => {
        if (!context.websocketUrl) return

        const webSocketClient = new AppWebSocketClient(context.websocketUrl)
        const onConnectionLost = (event: Event) => {
            console.error(
                `[${new Date().toISOString()}] WS: connection lost`,
                JSON.stringify(event, null, 2),
            )
            // window.Rollbar?.warning('WS connection lost', {
            //     date: new Date().toISOString(),
            //     ...event,
            // })

            setTimeout(() => {
                console.log(`[${new Date().toISOString()}] WS: reconnecting`)
                webSocketClient.connect()
            }, RECONNECTION_TIMEOUT)
        }

        setWebSocketClient(webSocketClient)

        const keepAliveInterval = setInterval(() => {
            try {
                webSocketClient.emit('keep-alive', {
                    data: `{ "timestamp": "${new Date().toISOString()}" }`,
                })
            } catch (e) {}
        }, KEEP_ALIVE_INTERVAL)

        webSocketClient.on('open', () => {
            console.log(`[${new Date().toISOString()}] WS: connected successfully`)
        })
        webSocketClient.on('close', onConnectionLost)
        webSocketClient.on('error', onConnectionLost)
        webSocketClient.connect(() => {
            console.error(`[${new Date().toISOString()}] WS: connection error`)
        })

        return () => {
            console.log(`[${new Date().toISOString()}] WS: disconnected`)
            webSocketClient.off('close', onConnectionLost)
            webSocketClient.off('error', onConnectionLost)
            webSocketClient.disconnect()
            clearInterval(keepAliveInterval)
        }
    }, [context.websocketUrl])

    return <AppEventsContext.Provider value={webSocketClient}>{children}</AppEventsContext.Provider>
}

export const useAppEvents = () => {
    const contextValue = useContext(AppEventsContext)

    if (contextValue === undefined) {
        throw new Error('useAppEvents is being used outside AppEventsProvider')
    }

    return contextValue
}
