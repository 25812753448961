import styled from 'styled-components/macro'

import { AlertTriangleIcon } from '@verbit-ai/icons-library'

const Container = styled.div`
    position: fixed;
    bottom: 24px;
    left: 24px;
    z-index: 100;

    border-radius: 4px;
    background: #5d8de4;
    color: white;

    display: flex;
    max-width: 460px;
`

const IconContainer = styled.div`
    padding: 12px;
    padding-top: 16px;
`

const TextContainer = styled.div`
    padding: 16px;
    padding-left: 0;

    font-size: 14px;
    line-height: 21px;

    & h4 {
        margin-bottom: 8px;
    }
`

export function CriticalIssueSnackBar() {
    return (
        <Container>
            <IconContainer>
                <AlertTriangleIcon size={18} color="white" />
            </IconContainer>
            <TextContainer>
                <h4>
                    <b>Transcript Refresh in Progress</b>
                </h4>
                <span>
                    There was an issue, and we'll refresh the transcript.
                    <br />
                    Please don't refresh. There will be no impact on the audio.
                </span>
            </TextContainer>
        </Container>
    )
}
