import { Descendant } from 'slate'
import { LegalAnnotationsBySpeakerMap } from './LegalAnnotationsProvider'
import { LOCAL_STORAGE_KEYS } from './types/cacheTypes'
import { TimedTextSegment } from 'src/components/Editor/plugins/withTimeline/timeline'

type TranscriptSnapshot = {
    document: Descendant[]
    timings: TimedTextSegment
}

export const TaskCache = {
    verifyCache(sessionId: string, workerId: string) {
        const cacheSessionId = localStorage.getItem(LOCAL_STORAGE_KEYS.SESSION_ID)
        const cacheWorkerId = localStorage.getItem(LOCAL_STORAGE_KEYS.WORKER_ID)

        return sessionId === cacheSessionId && cacheWorkerId === workerId
    },
    verifyCacheTaskId(taskId: string) {
        const cacheTaskId = localStorage.getItem(LOCAL_STORAGE_KEYS.TASK_ID)

        return cacheTaskId === taskId
    },

    saveSession(sessionId: string, workerId: string) {
        try {
            TaskCache.clearSession()
            localStorage.setItem(LOCAL_STORAGE_KEYS.SESSION_ID, sessionId)
            localStorage.setItem(LOCAL_STORAGE_KEYS.WORKER_ID, workerId)
        } catch {
            TaskCache.clearCache()
            console.log('Failed to store session/worker id, storage:', localStorage)
        }
    },
    saveCurrentTaskId(taskId: string) {
        try {
            localStorage.setItem(LOCAL_STORAGE_KEYS.TASK_ID, taskId)
        } catch {
            TaskCache.clearCache()
            console.log('Failed to store trax_task_id, storage:', localStorage)
        }
    },
    saveTranscriptSnapshot(snapshot: TranscriptSnapshot) {
        try {
            localStorage.setItem(LOCAL_STORAGE_KEYS.TRANSCRIPT_SNAPSHOT, JSON.stringify(snapshot))
        } catch {
            TaskCache.clearTranscriptSnapshot()
            console.log('Failed to store transcript snapshot, storage:', localStorage)
        }
    },
    saveSpeakersAnnotations(nextSpeakersByLegalAnnotation: LegalAnnotationsBySpeakerMap) {
        TaskCache.clearAnnotations()
        localStorage.setItem(
            LOCAL_STORAGE_KEYS.SPEAKERS_ANNOTATIONS,
            JSON.stringify(nextSpeakersByLegalAnnotation),
        )
    },
    getSpeakersAnnotations() {
        const cachedAnnotations = localStorage.getItem(LOCAL_STORAGE_KEYS.SPEAKERS_ANNOTATIONS)
        return cachedAnnotations ? JSON.parse(cachedAnnotations) : {}
    },

    clearCache() {
        TaskCache.clearSession()
        TaskCache.clearTranscriptSnapshot()
    },
    clearSession() {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SESSION_ID)
        localStorage.removeItem(LOCAL_STORAGE_KEYS.WORKER_ID)
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SPEAKERS_ANNOTATIONS)
    },
    clearTranscriptSnapshot() {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TASK_ID)
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TRANSCRIPT_SNAPSHOT)
    },
    clearAnnotations() {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.SPEAKERS_ANNOTATIONS)
    },

    recoverTranscriptSnapshot(): TranscriptSnapshot | null {
        const snapshot = localStorage.getItem(LOCAL_STORAGE_KEYS.TRANSCRIPT_SNAPSHOT)

        if (snapshot === null) {
            return null
        }

        return JSON.parse(snapshot) as TranscriptSnapshot
    },
}
