
import { Header2 as VerbitHeader } from '@verbit-ai/verbit-ui-library'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TaskRegistry } from 'src/tasks/Registry'

export const TopBarTitle = () => {
    let taskTitle;
    const { Title } = VerbitHeader
    const [{ context: taskContext }] = useTaskMachine(['task'])
    const { task } = taskContext
    taskTitle = task ? TaskRegistry.getTitle(task) : ''

    return (
        <Title maxWidth={950}>{taskTitle}</Title> 
    )
}