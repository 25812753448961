import { useEffect, useRef, useState } from 'react'
import { Classes, Dialog } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'

const StyledDialog = styled(Dialog)`
    & > .bp4-dialog-close-button {
        display: none;
    }
`

export function DuplicateSessionScreen() {
    const [isOpen, setIsOpen] = useState(false)

    const [{ context }, , appMachine] = useAppMachine(['sessionId', 'workerId'])
    const { sessionId, workerId } = context

    const ref = useRef<{ trax_session_id: string | null; trax_worker_id: string | null }>({
        trax_session_id: null,
        trax_worker_id: null,
    })

    useEffect(() => {
        if (sessionId && workerId) {
            const fn = (e: StorageEvent) => {
                if (e.key === 'trax_session_id' || e.key === 'trax_worker_id') {
                    ref.current[e.key] = e.newValue

                    if (
                        ref.current.trax_session_id !== null &&
                        ref.current.trax_worker_id !== null &&
                        sessionId === ref.current.trax_session_id &&
                        workerId === ref.current.trax_worker_id
                    ) {
                        setIsOpen(true)

                        appMachine.stop()
                        appMachine.children.get('worker')?.stop?.()
                    }
                }
            }

            window.addEventListener('storage', fn)
            return () => {
                window.removeEventListener('storage', fn)
            }
        }
    }, [appMachine, sessionId, workerId])

    return (
        <StyledDialog
            isOpen={isOpen}
            title="Session is already open"
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            isCloseButtonShown={false}
        >
            <div className={Classes.DIALOG_BODY}>
                This TraX session is open in a different browser tab.
                <br />
                You may close this tab and continue working there.
            </div>
        </StyledDialog>
    )
}
