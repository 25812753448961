import { ReactNode, useState } from 'react'
import { Position } from '@blueprintjs/core'
import { ISuggestProps, SuggestProps, Suggest } from '@blueprintjs/select'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

type StyledSuggestProps = {
    withLeftElement?: boolean
    withRightElement?: boolean
}

const StyledSuggest = styled(Suggest)<StyledSuggestProps>`
    position: relative;
    cursor: pointer;

    .bp4-popover-target {
        width: 100%;

        .bp4-input-group {
            .bp4-input-left-container {
                left: 9px;
                top: 50%;
                transform: translateY(-50%);
            }

            input.bp4-input {
                width: 100%;
                height: auto;
                padding: 5px 8px;
                border-radius: 4px;
                border: solid 1px ${palette('cloudBlueLight', 4)};
                font-size: 14px;
                line-height: 16px;
                color: ${palette('navy', 6)};
                box-shadow: none !important;
                text-overflow: ellipsis;
                cursor: pointer;

                &:focus {
                    cursor: text;
                }

                ${ifProp(
                    'withLeftElement',
                    css`
                        padding-left: 26px !important;
                    `,
                )}

                ${ifProp(
                    'withRightElement',
                    css`
                        padding-right: 26px !important;
                    `,
                )}

                ::placeholder {
                    color: ${palette('grey', 8)};
                }

                &:focus {
                    border-color: ${palette('blue', 0)};
                }
            }
        }

        .bp4-input-action {
            top: calc(50% - 1px);
            right: 8px;
            transform: translateY(-50%);
        }
    }

    .bp4-overlay {
        position: absolute;
        top: 100%;

        .bp4-transition-container {
            margin-top: 4px;
            position: static !important;
            transform: none !important;

            .bp4-popover {
                width: 100%;
                box-shadow: none;
                border-radius: 0;

                .bp4-popover-content {
                    border-radius: 0;
                }
            }
        }
    }
`

const pass = <T,>(value: T): string => `${value}`

export interface SelectInputProps<Item> extends Omit<ISuggestProps<Item>, 'inputValueRenderer'> {
    inputValueRenderer?: ISuggestProps<Item>['inputValueRenderer']
    leftElement?: JSX.Element
    rightElement?: JSX.Element
    placeholder?: string
}

export function SelectInput<Item>({
    popoverProps,
    inputProps,
    leftElement,
    rightElement,
    placeholder,
    inputValueRenderer = pass,
    ...props
}: SelectInputProps<Item>) {
    const [activeItem, setActiveItem] = useState<Item | null>()

    // This is required, due to styled-component behaviour with TS generics
    const TypedStyledSuggest = StyledSuggest as (
        props: SuggestProps<Item> & StyledSuggestProps,
    ) => JSX.Element

    return (
        <TypedStyledSuggest
            activeItem={activeItem}
            onActiveItemChange={setActiveItem}
            inputValueRenderer={inputValueRenderer}
            withLeftElement={!!leftElement}
            withRightElement={!!rightElement}
            {...props}
            popoverProps={{
                position: Position.BOTTOM,
                minimal: true,
                usePortal: false,
                ...popoverProps,
                onOpening(e: HTMLElement) {
                    popoverProps?.onOpening?.(e)
                    setActiveItem(props.selectedItem)
                },
            }}
            inputProps={{
                placeholder,
                rightElement,
                leftElement,
                ...inputProps,
            }}
        />
    )
}

SelectInput.ofType = function <T>() {
    return SelectInput as (props: SelectInputProps<T>) => ReactNode
}
