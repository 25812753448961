import { useCallback } from 'react'
import useDidMount from 'beautiful-react-hooks/useDidMount'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'

import { useModal } from 'src/state/ModalProvider'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'

export const AppErrorStateHandler = () => {
    const [{ context }, sendEvent] = useAppMachine(['error'])
    const { openModal, closeModal } = useModal()
    const onRetry = useCallback(() => {
        sendEvent('RETRY')
    }, [sendEvent])

    useDidMount(() => {
        openModal({
            name: 'error',
            props: {
                error: context.error,
                onRetry,
            },
        })
    })

    useWillUnmount(() => {
        closeModal()
    })

    return null
}
