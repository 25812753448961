type IndexOfWordsResult = { left: number; at: number; right: number }

export const ZERO_WIDTH_WHITESPACE = `\uFEFF`
export const ZERO_WIDTH_WHITESPACE_REGEX = new RegExp(ZERO_WIDTH_WHITESPACE, 'g')

/**
 * Get the index of words (any run of text that does not contain a space)
 * to the left, right and containing a given position in a string.
 * If there is no word at a given location (left/at/right), -1 is returned as the index.
 *
 * @param pos The position to get the indices for
 * @param str The string to search
 * @returns The index of the words to the left, at (contained) and to the right of the given position. or -1 for no result at that location.
 */
export function indexOfWordsAround(pos: number, str: string): IndexOfWordsResult {
    let left = -1
    let at = -1
    let right = -1

    const wordCount = wordCountUntilPosition(pos, str)

    // If pos is not a space, we know the 'at' word is the last one in the count
    // We also check for pos-1, in order to still return 'at' for words where pos is at the end
    if (str[pos] !== ' ' || str[pos - 1] !== ' ') {
        at = wordCount - 1
        // If there is only one word, there is no word to the left of pos
        left = wordCount > 1 ? wordCount - 2 : -1
    } else {
        // The word to the left of pos is either the last one, or -1 if there are no words
        left = wordCount - 1
    }

    // Now we need to find the right word - the immediate word after pos
    // Increment i until we're past the current 'at' word (if any)
    let i = pos
    if (at > -1) {
        while (str[i] !== ' ') {
            i++
        }
    }

    // Any non-space character now means there's a word after pos
    while (i < str.length) {
        if (str[i] !== ' ') {
            right = wordCount
            break
        }

        i++
    }

    return { left, at, right }
}

/**
 * Get the index of a word (any run of text that does not contain a space) at a given position in a string.
 * If there is no word at a given location, -1 is returned as the index.
 *
 * @param pos The position to get the word index for
 * @param str The string to search
 * @returns The index of the word in the string, or -1 if there in no word (i.e. it's a space) in that position.
 */
export function indexOfWordAt(pos: number, str: string): number {
    // No need to search if there is a space at pos, or if pos is out of bounds
    if (str[pos] === ' ' || pos > str.length) {
        return -1
    }

    const wordCount = wordCountUntilPosition(pos, str)
    return wordCount - 1
}

/**
 * Get the index of a word (any run of text that does not contain a space) at a given position in a string.
 * If there is no word at that position, return the index of the word just before the position. If there is no word before, return -1.
 *
 * @param pos The position to get the word index for.
 * @param str The string to search
 * @returns The index of the word in the string or the word after, or -1 if there in no word at or after that position.
 */
export function indexOfWordAtOrBefore(pos: number, str: string): number {
    if (pos === 0 || pos > str.length) {
        return -1
    }

    const wordCount = wordCountUntilPosition(pos, str)
    return wordCount - 1
}

enum WordCounterState {
    INSIDE_A_WORD = 'inside',
    OUTSIDE_A_WORD = 'outside',
}

/**
 * LEGACY METHOD
 */
export function wordCountUntilPosition(pos: number, str: string): number {
    let wordCount = 0
    let state: WordCounterState = WordCounterState.OUTSIDE_A_WORD

    for (let i = 0; i <= pos && i < str.length; i++) {
        // filter spaces along with zero width whitespaces for the tags, and also "free" punctuations,
        // to not mistake them for words
        if (
            // prettier-ignore
            str[i] === ' ' ||
            str[i] === ZERO_WIDTH_WHITESPACE ||
            (state === WordCounterState.OUTSIDE_A_WORD && isPunctuation(str[i]))
        ) {
            state = WordCounterState.OUTSIDE_A_WORD
        } else if (state === WordCounterState.OUTSIDE_A_WORD) {
            state = WordCounterState.INSIDE_A_WORD
            wordCount++
        }
    }

    return wordCount
}

const vowelsRegex = /[aiyou]|e(?!$)+/gi

export function countSyllables(text: string): number {
    if (isPunctuation(text)) {
        return 0
    }

    // count vowel sequences
    const vowelSeqCount = Array.from(text.matchAll(vowelsRegex)).length

    // Return vowel sequence count or at least 1
    return Math.max(vowelSeqCount, 1)
}

export const punctuationRegex = /^[@#"_<>()=*^&*~`±§.,!?;:%-/$]+$/

export function isPunctuation(text: string) {
    return punctuationRegex.test(text)
}

export const isSingleWord = (str: string): boolean => !/[\s.,]/.test(str)

export const isWhitespace = (str: string): boolean =>
    /^\s$/.test(str) || str.includes(ZERO_WIDTH_WHITESPACE)

// remove trailing commas or whitespaces
export const trimText = (text: string) => text.replace(/(^[,\s]+)|([,\s]+$)/g, '')

export const splitToWords = (text: string) =>
    text.replaceAll(ZERO_WIDTH_WHITESPACE, ' ').split(/\s+/).filter(Boolean)

export function countWords(text: string): number {
    return splitToWords(text).length
}

export function countWordsUntilOffset(text: string, offset: number): number {
    let wordCount = 0
    let currentOffset = 0

    while (currentOffset <= offset && currentOffset <= text.length) {
        const wordBounds = getWordBounds(text, currentOffset)

        if (wordBounds) {
            wordCount++
            currentOffset = wordBounds.end + 1
        } else {
            currentOffset++
        }
    }

    return wordCount
}

export type WordBounds = { start: number; end: number }
/**
 * Get the word bounds (start + end offset) of the word at a given offset in a string.
 * @param text whole string
 * @param offset offset to look at
 * @returns start + end offset of a word if there is, null if not
 */
export function getWordBounds(text: string, offset: number): WordBounds | null {
    let state: WordCounterState = WordCounterState.OUTSIDE_A_WORD
    let start = 0
    let foundOffset = false

    for (let i = 0; i < text.length; i++) {
        const char = text[i]

        if (i === offset) {
            foundOffset = true
        }

        // if (isWhitespace(char)) {
        if (/^\s$/.test(char)) {
            switch (state) {
                case WordCounterState.INSIDE_A_WORD:
                    if (foundOffset) {
                        return { start, end: i }
                    }
                    state = WordCounterState.OUTSIDE_A_WORD
                    break
                case WordCounterState.OUTSIDE_A_WORD:
                    if (foundOffset) {
                        return null
                    }
                    break
            }
        } else if (state === WordCounterState.OUTSIDE_A_WORD) {
            state = WordCounterState.INSIDE_A_WORD
            start = i
        }
    }

    if (state === WordCounterState.INSIDE_A_WORD) {
        return { start, end: text.length }
    }

    return null
}
