import { useState } from 'react'

import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { ViewerTabBar } from './ViewerTabBar'
import { ViewerTopBar } from './ViewerTopBar'
import { DocumentViewer } from './DocumentViewer'

import { Attachment } from 'src/models/attachment'

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

const CenterContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border: 1px solid ${palette('cloudBlueLight', 1)};
`

interface DocumentViewerContainerProps {
    attachments: Attachment[]
    onDownload?: (fileUrl: string) => unknown
}

export const DocumentViewerContainer = ({
    attachments = [],
    onDownload,
}: DocumentViewerContainerProps) => {
    const [currentFile, setCurrentFile] = useState(attachments?.[0]?.url)
    const [currentFileName, setCurrentFileName] = useState(attachments?.[0]?.name)

    return (
        <Container>
            <ViewerTabBar
                tabs={attachments}
                setCurrentFileName={setCurrentFileName}
                onTabSelect={setCurrentFile}
                onDownload={onDownload}
            />
            <ViewerTopBar
                currentFileName={currentFileName}
                currentFile={currentFile}
                onDownload={onDownload}
            />
            <CenterContent>
                <DocumentViewer currentFileUrl={currentFile} onDownload={onDownload} />
            </CenterContent>
        </Container>
    )
}
