import { useCallback, useRef } from 'react'
import { Button, Classes, Intent } from '@blueprintjs/core'

import { GlossaryTerm } from 'src/models/glossary-term'
import {
    CurrentTerm,
    FlexDiv,
    FooterNote,
    StyledDialog,
} from 'src/components/Glossary/GlossaryEditModal'
import { ModalComponentProps } from 'src/state/ModalProvider'

export interface GlossaryDeleteModalProps extends ModalComponentProps {
    term: GlossaryTerm
    onDelete: (term: GlossaryTerm) => void
}

export function GlossaryDeleteModal({ term, onClose, onDelete }: GlossaryDeleteModalProps) {
    const okButtonRef = useRef<HTMLElement | null>(null)
    const onOpened = useCallback(() => {
        okButtonRef.current?.focus()
    }, [okButtonRef])

    return (
        <StyledDialog
            isOpen
            onClose={onClose}
            onOpened={onOpened}
            isCloseButtonShown={true}
            title="Delete from Glossary"
            lazy
        >
            <div className={Classes.DIALOG_BODY}>
                <CurrentTerm term={term} />
            </div>
            <FlexDiv className={Classes.DIALOG_FOOTER}>
                <FooterNote>Term will be deleted for everyone</FooterNote>
                <FlexDiv>
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={() => onDelete(term)}
                        type="submit"
                        fill
                        large
                    >
                        Delete
                    </Button>
                    <Button intent={Intent.NONE} onClick={onClose} fill large minimal>
                        Cancel
                    </Button>
                </FlexDiv>
            </FlexDiv>
        </StyledDialog>
    )
}
