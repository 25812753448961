import { Helmet } from 'react-helmet'

import { useBrowserTheme } from 'src/hooks/useBrowserTheme'

export const AppHelmet = () => {
    const browserTheme = useBrowserTheme()

    return (
        <Helmet>
            <link rel="icon" href={browserTheme === 'dark' ? 'favicon-dark.svg' : 'favicon.svg'} />
        </Helmet>
    )
}
