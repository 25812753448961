import { useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components/macro'

import { PrePopulationTask as PrePopulationTaskType } from 'src/models'
import { DocumentViewerContainer } from 'src/components/EmbeddedViewer/EmbeddedViewerContainer'
import { useTaskPayloadSender } from 'src/components/TaskRouter/TaskState'
import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { PublishButton } from 'src/components/Session/PublishButton'
import { useIsPanelTabsOpen, usePanelTabs } from 'src/components/Session/PanelTabsManager'
import { ifProp } from 'styled-tools'
import { AttachmentsDownloadSource, useAnalytics } from 'src/analytics'
import { getFileExtension } from 'src/utils/fileExtension'

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

    margin-top: 26px;
`

const ViewerWrapper = styled.div<{ isPanelTabsOpen: boolean }>`
    width: 65%;
    height: 80%;

    margin-left: ${ifProp('isPanelTabsOpen', '5vw', '13vw')};
    transition: margin-left 700ms;
`

export const PrePopulationTask = () => {
    const {
        payload: { attachments },
    } = useTask<PrePopulationTaskType>()
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { setActivePanelTab } = usePanelTabs()
    const isPanelTabsOpen = useIsPanelTabsOpen()

    useEffect(() => {
        setActivePanelTab({ name: 'GLOSSARY' })
    }, [setActivePanelTab])

    useTaskPayloadSender(() => ({}), [])

    const analytics = useAnalytics()
    const onDownload = useCallback(
        (fileUrl: string) => {
            analytics?.sendAttachmentDownloaded(
                getFileExtension(fileUrl).toLowerCase(),
                AttachmentsDownloadSource.GLOSSARY_PREPOPULATION,
            )
        },
        [analytics],
    )

    return (
        <Container>
            <ViewerWrapper ref={containerRef} isPanelTabsOpen={isPanelTabsOpen}>
                <DocumentViewerContainer attachments={attachments} onDownload={onDownload} />
            </ViewerWrapper>
            <PublishButton position="static" />
        </Container>
    )
}
