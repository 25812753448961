import { TranscriptionLayerId } from '../models'

export class AppcuesClient {
    identify(
        workerId: string,
        workerName: string | undefined,
        layerIds: TranscriptionLayerId[] | undefined,
    ) {
        window.Appcues?.identify(workerId, {
            trax_name: workerName,
            trax_layer_ids: layerIds?.join('&'),
        })
    }

    track(eventName: string, data?: object) {
        window.Appcues?.track(eventName, data)
    }
}
