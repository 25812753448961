import styled from 'styled-components/macro'

import { TrainingFileIcon, TrainingMicrophoneIcon, TrainingTimeIcon } from 'src/components/icons'

const ItemsContainer = styled.div`
    padding-top: 32px;
    display: flex;
    flex-direction: row;
`

const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
`

const Divider = styled.div`
    border-right: 1px solid #ccdae9;
`

const MetricValue = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 44px;
    padding-top: 5px;
`
const MetricName = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
`
export interface TrainingMetricsBoxProps {
    sessionStats: {
        workTime: number
        transcribedAudio: number
        segmentsCompleted: number
    }
}

export const TrainingMetricsBox = ({ sessionStats }: TrainingMetricsBoxProps) => {
    return (
        <>
            <ItemsContainer>
                <Item>
                    <TrainingTimeIcon />
                    <MetricValue>{`${toMins(sessionStats?.workTime ?? 0)} min`}</MetricValue>
                    <MetricName>
                        Time <br /> worked
                    </MetricName>
                </Item>
                <Divider />

                <Item>
                    <TrainingMicrophoneIcon />
                    <MetricValue>{`${toMins(sessionStats?.workTime ?? 0)} min`}</MetricValue>
                    <MetricName>
                        Audio <br /> covered
                    </MetricName>
                </Item>
                <Divider />

                <Item>
                    <TrainingFileIcon />
                    <MetricValue>{sessionStats?.segmentsCompleted}</MetricValue>
                    <MetricName>
                        Tasks <br /> completed{' '}
                    </MetricName>
                </Item>
            </ItemsContainer>
        </>
    )
}

function toMins(val?: number) {
    if (!val) {
        return '0'
    }
    return Math.round(val / 60).toString()
}
