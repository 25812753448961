import { useEffect, useState } from 'react'

export const useBrowserTheme = () => {
    const [isDark, setIsDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)

    useEffect(() => {
        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', ({ matches: isDark }) => {
                setIsDark(isDark)
            })
    })

    return isDark ? 'dark' : 'light'
}
