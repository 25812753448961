export enum ErrorCode {
    UnauthorizedUser = 10,
    UnauthenticatedUser = 11,
    TaskNotFound = 30,
    TaskSubmittedTooEarly = 31,
    TaskTimedOut = 32,
    TaskWrongAssignment = 33,
    WorkerNotFound = 20,
    WorkerAlreadyExists = 21,
    WorkerLeft = 22,
    WorkerRemovedInactivity = 23,
    WorkerRemovedTaskTimeout = 24,
    InvalidWorkerRole = 25,
    SessionReachedWorkerLimit = 26,

    Unknown = -100,
    IgnoredStatusCodeError = -101,
}

export const IgnoredStatusCodes: number[] = [404, 502, 504]

export class APIError extends Error {
    // Timeout errors don't need indications
    static readonly noIndicationErrors = [ErrorCode.TaskTimedOut, ErrorCode.TaskWrongAssignment]

    // Errors which should be shown in modal
    static readonly modalErrors = [
        ErrorCode.TaskSubmittedTooEarly,
        ErrorCode.WorkerNotFound,
        ErrorCode.WorkerAlreadyExists,
        ErrorCode.WorkerRemovedInactivity,
        ErrorCode.WorkerRemovedTaskTimeout,
        ErrorCode.Unknown,
        ErrorCode.IgnoredStatusCodeError,
    ]

    static readonly redirectErrors = [ErrorCode.WorkerNotFound]

    readonly code: ErrorCode
    readonly critical?: boolean
    readonly requestId?: string
    readonly payload?: { [key: string]: any }
    readonly httpCode?: number

    constructor(
        code: number,
        message: string,
        critical: boolean,
        requestId: string,
        payload?: { [key: string]: any },
        httpCode?: number,
    ) {
        super(message)
        this.code = code
        this.critical = critical
        this.requestId = requestId
        this.payload = payload
        this.httpCode = httpCode
    }

    static unknownError(message: string, requestId?: string, httpCode?: number) {
        return new APIError(ErrorCode.Unknown, message, false, requestId ?? '', undefined, httpCode)
    }

    get isModalError(): boolean {
        return APIError.modalErrors.includes(this.code)
    }

    get isWithoutNotification(): boolean {
        return APIError.noIndicationErrors.includes(this.code)
    }

    get withRedirect(): boolean {
        return APIError.redirectErrors.includes(this.code)
    }

    get redirectLink(): string {
        return 'https://platform.verbit.co'
    }
}
