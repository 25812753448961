import { EditorControls } from 'src/models'

/**
 * This hook is a convenience hook to determine, if the speaker box for a block is shown or not.
 * (this helps determine widths for the editor)
 * @param controls the controls object for the editor.
 */
export function useIsSpeakerBoxVisible(controls?: EditorControls) {
    return Boolean(
        controls?.speaker.visible ||
            controls?.legal.annotation.visible ||
            controls?.legal.exhibit.visible,
    )
}
