import { TaskRegistry } from './Registry'

import { TranscriptionTaskItem } from './TranscriptionTask'
import { OnboardingTaskItem } from './OnboardingTaskItem'
import { PrePopulationTaskItem } from './PrePopulationTaskItem'
import { RestTaskItem } from './RestTaskItem'
import { TestTaskItem } from './TestTask'

TaskRegistry.register(TranscriptionTaskItem)
TaskRegistry.register(OnboardingTaskItem)
TaskRegistry.register(PrePopulationTaskItem)
TaskRegistry.register(RestTaskItem)
TaskRegistry.register(TestTaskItem)
