import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { PublishButton } from 'src/components/Session/PublishButton'
import { useTaskPayloadSender } from 'src/components/TaskRouter/TaskState'

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 60px);
    background-color: ${palette('white', 0)};
`

const CenterBox = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export function TestTask() {
    const containerRef = useRef<HTMLDivElement | null>(null)

    useTaskPayloadSender(() => {
        return {}
    }, [])

    return (
        <Container>
            <CenterBox>
                <div ref={containerRef}>this is a test</div>
            </CenterBox>
            <PublishButton />
        </Container>
    )
}
