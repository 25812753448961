import { EventEmitter } from 'events'
import { MockDelayRange, generateRandomMockDelay } from './utils'
import { sleep } from 'src/utils/time'

export interface LiveState {
    index: number
    length: number
}

type MockStreamPlayerEvents = {
    live_state_update: (liveState: LiveState) => void
    play: () => void
    pause: () => void
    step: (count: number) => void
}

type PlayerStates = 'playing' | 'paused' | 'stepping' // stepping means playing 1 event at a time

type MockStreamPlayerOptions = {
    events: any[]
    delay?: MockDelayRange
}

export class MockStreamPlayer {
    private events: any[] = []
    private delay: MockDelayRange
    private liveEventState: LiveState = { index: 0, length: 0 }
    private emitter = new EventEmitter<MockStreamPlayerEvents>()

    private playerState: PlayerStates = 'paused'

    constructor(options: MockStreamPlayerOptions) {
        const { events, delay } = options

        this.events = events
        this.delay = delay ?? [500, 1000]
        this.liveEventState = {
            index: 0,
            length: events.length,
        }
        this.emitter.emit('live_state_update', this.liveEventState)
    }

    getCurrentPlayerState() {
        return this.playerState
    }

    play() {
        this.emitter.emit('play')
        this.playerState = 'playing'
    }

    /**
     * Step over 1 event at a time.
     */
    step(count = 1) {
        if (this.playerState === 'paused') {
            this.emitter.emit('step', count)
            this.playerState = 'stepping'
        }
    }

    pause() {
        this.emitter.emit('pause')
        this.playerState = 'paused'
    }

    async *asyncIterator() {
        let pausePromise: Promise<void> | null = null
        let resolveFn: (() => void) | null = null

        let isStepping: boolean = false
        let remainingStepsCount: number = 0

        const onPlay = () => {
            resolveFn?.()

            resolveFn = null
            pausePromise = null
        }
        const onPause = () => {
            pausePromise = new Promise((resolve) => {
                resolveFn = resolve
            })
        }
        const onStep = (count: number) => {
            isStepping = true
            remainingStepsCount += count
            onPlay()
        }
        this.on('play', onPlay)
        this.on('pause', onPause)
        this.on('step', onStep)

        if (this.playerState === 'paused') {
            onPause()
        }

        for (const [i, event] of this.events.entries()) {
            if (isStepping) {
                if (remainingStepsCount > 0) {
                    remainingStepsCount--
                }

                if (remainingStepsCount === 0) {
                    isStepping = false
                    this.pause()
                }
            }

            if (pausePromise) {
                await pausePromise
            }

            this.liveEventState = {
                index: i,
                length: this.liveEventState.length,
            }
            this.emitter.emit('live_state_update', this.liveEventState)
            yield event

            if (!isStepping) {
                await sleep(generateRandomMockDelay(this.delay))
            } else {
                await sleep(200)
            }
        }

        this.off('play', onPlay)
        this.off('pause', onPause)
        this.off('step', onStep)

        this.liveEventState = {
            index: this.liveEventState.index + 1,
            length: this.liveEventState.length,
        }
        this.emitter.emit('live_state_update', this.liveEventState)

        this.pause()
    }

    on<T extends keyof MockStreamPlayerEvents>(event: T, fn: MockStreamPlayerEvents[T]) {
        this.emitter.on(event, fn)
    }
    off<T extends keyof MockStreamPlayerEvents>(event: T, fn: MockStreamPlayerEvents[T]) {
        this.emitter.off(event, fn)
    }
}
