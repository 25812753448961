import {
    assign,
    DoneInvokeEvent,
    EventObject,
    Interpreter,
    Machine,
    pure,
    send,
    sendParent,
} from 'xstate'

import { delay } from 'src/utils/time'
import { Task, WorkerId } from 'src/models'
import { APIError, ErrorCode, HttpClient } from 'src/network'
import { analytics, TRAX_USER_INITIAL_ROLE } from 'src/analytics'
import { TaskPublishType } from 'src/analytics/client'
import { TaskCache } from 'src/state/TaskCache'
import { TaskRegistry } from 'src/tasks/Registry'
import { blocksAnalyticsSingleton } from 'src/components/Editor/plugins/withTranscript/blocksAnalytics'
import { ValidationsCount } from 'src/components/Editor/plugins/withValidations/Validations'
import { appcues } from 'src/appcues'

import { TaskValidationMachine } from '../TaskValidationMachine/TaskValidationMachine'

const MAX_INTERNAL_SERVER_ERROR_RETRIES = 3
export const TIMEOUT_GRACE_PERIOD_MS = 10000
export const TASK_POLLING_DELAY_MS = 5000
export const RETRY_DELAY_MS = 5000
export const MAX_RETRIES = 180 // 180 * 5 seconds = 20 mins

export type TaskAbortReason = 'audio_unavailable'

export interface TaskStateContext {
    httpClient?: HttpClient
    workerId?: WorkerId
    strategyName?: string
    sessionPaused?: boolean
    sessionNotStarted?: boolean
    exiting: boolean
    task?: Task
    timerDuration: number
    minSubmitDuration: number
    allowedToSubmit: boolean
    elapsed: number
    taskPayload: any | null
    autoSubmit?: boolean
    taskReceivedTimestamp: number
    spellingErrorsStart: number
    spellingErrorsEnd: number
    invalidTermsCountStart: number
    invalidTermsCountEnd: number
    currentValidationsCount: ValidationsCount
    audioTimeListenedSeconds: number
    userIdleTimeSeconds: number
    taskAbortReason?: TaskAbortReason
    initialReceivedTasks: {
        [key in Task['layerId']]: {
            [key in Task['type']]?: boolean
        }
    }
    sessionResources?: {
        tagsModifiedAt?: Date | null
        speakersModifiedAt?: Date | null
    }
    recoveredFromCache: boolean
    waitingForTaskStart: number // timestamp,
    publishedType?: TaskPublishType
}

export interface TaskStateSchema {
    states: {
        'waiting-for-task': {}
        'in-progress': {}
        'task-processing': {}
        submitting: {}
        aborting: {}
        error: {}
        'user-exit': {}
        timeout: {}
        'removed-by-ops': {}
        finished: {}
    }
}

interface ErrorEvent extends EventObject {
    type: 'ERROR'
    error: Error
}

interface TaskReceivedEvent extends EventObject {
    type: 'TASK_RECEIVED'
    task: Task
    sessionResources?: {
        tagsModifiedAt?: Date | null
        speakersModifiedAt?: Date | null
    }
    recoveredFromCache: boolean
    isFirstTaskInLayer: boolean
}

interface SubmitEvent extends EventObject {
    type: 'SUBMIT'
    autoSubmit: boolean
    publishType: TaskPublishType
}

interface NoAvailableTasksEvent extends EventObject {
    type: 'NO_AVAILABLE_TASKS'
}

interface SessionPausedEvent extends EventObject {
    type: 'SESSION_PAUSED'
}

interface SessionNotStartedEvent extends EventObject {
    type: 'SESSION_NOT_STARTED'
}

export type TaskEvent =
    | TaskReceivedEvent
    | { type: 'ABORT'; reason: TaskAbortReason }
    | SubmitEvent
    | { type: 'PROCESS_TASK' }
    | { type: 'EXIT' }
    | { type: 'TOGGLE_EXIT' }
    | ErrorEvent
    | { type: 'TICK' }
    | { type: 'TASK_VALIDATION_FAILED' }
    | { type: 'PAYLOAD_RECEIVED'; payload: any }
    | { type: 'FINISHED' }
    | { type: 'EXIT_TIMEOUT' }
    | { type: 'EXIT_REMOVED_BY_OPS' }
    | { type: 'SUBMIT_SUCCESSFUL' }
    | { type: 'RETRY_ABORTED' }
    | NoAvailableTasksEvent
    | SessionPausedEvent
    | SessionNotStartedEvent
    | { type: 'ASSIGN_SPELLING_ERRORS_COUNT_START'; spellingErrorCount: number }
    | { type: 'ASSIGN_SPELLING_ERRORS_COUNT_END'; spellingErrorCount: number }
    | { type: 'ASSIGN_INVALID_TERMS_COUNT_START'; invalidTermsCount: number }
    | { type: 'ASSIGN_INVALID_TERMS_COUNT_END'; invalidTermsCount: number }
    | { type: 'ASSIGN_CURRENT_VALIDATIONS_STATUS'; currentValidationsCount: ValidationsCount }
    | { type: 'ASSIGN_AUDIO_TIME_LISTENED'; audioTimeListenedSeconds: number }
    | { type: 'ASSIGN_USER_IDLE_TIME'; userIdleTimeSeconds: number }
    | { type: 'ENABLE_GLOSSERS_MODE' }

export type TaskMachineType = Interpreter<TaskStateContext, TaskStateSchema, TaskEvent>

export const TaskMachine = Machine<TaskStateContext, TaskStateSchema, TaskEvent>(
    {
        id: 'worker',
        initial: 'waiting-for-task',
        context: {
            exiting: false,
            timerDuration: 0,
            minSubmitDuration: 0,
            allowedToSubmit: false,
            elapsed: 0,
            taskPayload: null,
            taskReceivedTimestamp: 0,
            spellingErrorsStart: 0,
            spellingErrorsEnd: 0,
            invalidTermsCountStart: 0,
            invalidTermsCountEnd: 0,
            audioTimeListenedSeconds: 0,
            userIdleTimeSeconds: 0,
            initialReceivedTasks: {
                edit: {},
                annotate: {},
                review: {},
            },
            currentValidationsCount: {},
            sessionResources: {
                tagsModifiedAt: null,
                speakersModifiedAt: null,
            },
            recoveredFromCache: false,
            waitingForTaskStart: 0,
        },
        states: {
            'waiting-for-task': {
                on: {
                    TASK_RECEIVED: {
                        target: 'in-progress',
                        actions: [
                            assign<TaskStateContext, TaskReceivedEvent>({
                                task: (_, event) => event.task,
                                sessionPaused: () => false,
                                sessionNotStarted: () => false,
                                initialReceivedTasks: ({ initialReceivedTasks }, { task }) => ({
                                    ...initialReceivedTasks,
                                    [task?.layerId]: {
                                        ...initialReceivedTasks?.[task?.layerId],
                                        [task?.type]: true,
                                    },
                                }),
                                sessionResources: (_, event) => event.sessionResources,
                                recoveredFromCache: (_, event) => event.recoveredFromCache,
                                taskReceivedTimestamp: () => Date.now(),
                            }),
                            'setLayerIdInAnalytics',
                        ],
                    },
                    NO_AVAILABLE_TASKS: {
                        actions: assign<TaskStateContext, NoAvailableTasksEvent>({
                            sessionPaused: () => false,
                            sessionNotStarted: () => false,
                        }),
                    },
                    SESSION_PAUSED: {
                        actions: assign<TaskStateContext, SessionPausedEvent>({
                            sessionPaused: () => true,
                            sessionNotStarted: () => false,
                        }),
                    },
                    SESSION_NOT_STARTED: {
                        actions: assign<TaskStateContext, SessionNotStartedEvent>({
                            sessionPaused: () => false,
                            sessionNotStarted: () => true,
                        }),
                    },
                    EXIT: 'user-exit',
                    FINISHED: 'finished',
                    EXIT_TIMEOUT: 'timeout',
                    EXIT_REMOVED_BY_OPS: 'removed-by-ops',
                    ERROR: 'error',
                    ENABLE_GLOSSERS_MODE: {
                        actions: [sendParent('TURN_ON_IS_EDITOR_IN_GLOSSERS_MODE')],
                    },
                },
                entry: ['initializeWaitingForTask', 'resetTask'],
                invoke: {
                    id: 'fetchTask',
                    src: 'fetchTask',
                    onError: 'error',
                },
            },
            'in-progress': {
                on: {
                    SUBMIT: {
                        actions: [
                            pure<TaskStateContext, SubmitEvent, TaskEvent>((_, e) =>
                                e.autoSubmit
                                    ? send({ type: 'PROCESS_TASK' })
                                    : send({ type: 'VALIDATE_TASK' }, { to: 'taskValidation' }),
                            ),
                            assign({
                                autoSubmit: (_, e) => e.autoSubmit,
                                publishedType: (_, e) => e.publishType,
                            }),
                        ],
                    },
                    ASSIGN_SPELLING_ERRORS_COUNT_START: {
                        actions: assign({
                            spellingErrorsStart: (_, event) => event.spellingErrorCount,
                        }),
                    },
                    ASSIGN_INVALID_TERMS_COUNT_START: {
                        actions: assign({
                            invalidTermsCountStart: (_, event) => event.invalidTermsCount,
                        }),
                    },
                    PROCESS_TASK: 'task-processing',
                    TOGGLE_EXIT: {
                        actions: 'toggleExit',
                    },
                    ABORT: {
                        target: 'aborting',
                        actions: [
                            'turnOffStatusRequest',
                            assign({ taskAbortReason: (_, e) => 'audio_unavailable' }),
                        ],
                    },
                    EXIT: 'user-exit',
                    ERROR: 'error',
                    TICK: [
                        {
                            actions: send({
                                type: 'SUBMIT',
                                autoSubmit: true,
                                publishType: 'Timeout',
                            }),
                            cond: 'timerIsUp',
                        },
                        {
                            actions: 'tick',
                        },
                    ],
                },
                entry: ['resetContextForNewTask', 'analyticsTaskAssigned', 'turnOnStatusRequest'],
                exit: ['resetTaskCache'],
                invoke: [
                    {
                        id: 'timer',
                        src: 'taskTimer',
                    },
                    {
                        id: 'taskValidation',
                        src: TaskValidationMachine,
                        onDone: 'task-processing',
                    },
                ],
            },
            'task-processing': {
                on: {
                    PAYLOAD_RECEIVED: {
                        target: 'submitting',
                        actions: [
                            assign({
                                taskPayload: (_, event) => event.payload,
                            }),
                            'analyticsTaskSubmitted',
                        ],
                    },
                    ASSIGN_SPELLING_ERRORS_COUNT_END: {
                        actions: assign({
                            spellingErrorsEnd: (_, event) => event.spellingErrorCount,
                        }),
                    },
                    ASSIGN_INVALID_TERMS_COUNT_END: {
                        actions: assign({
                            invalidTermsCountEnd: (_, event) => event.invalidTermsCount,
                        }),
                    },
                    ASSIGN_CURRENT_VALIDATIONS_STATUS: {
                        actions: assign({
                            currentValidationsCount: (_, event) => event.currentValidationsCount,
                        }),
                    },
                    ASSIGN_AUDIO_TIME_LISTENED: {
                        actions: assign({
                            audioTimeListenedSeconds: (_, event) => event.audioTimeListenedSeconds,
                        }),
                    },
                    ASSIGN_USER_IDLE_TIME: {
                        actions: assign({
                            userIdleTimeSeconds: (_, event) => event.userIdleTimeSeconds,
                        }),
                    },
                },
            },
            submitting: {
                on: {
                    SUBMIT_SUCCESSFUL: 'waiting-for-task',
                    RETRY_ABORTED: 'waiting-for-task',
                    EXIT: 'user-exit',
                    FINISHED: 'finished',
                    ERROR: 'error',
                },
                invoke: {
                    id: 'submitTask',
                    src: 'submitTask',
                    onError: 'error',
                },
            },
            aborting: {
                on: {
                    RETRY_ABORTED: 'waiting-for-task',
                    EXIT: 'user-exit',
                },
                invoke: {
                    id: 'abortTask',
                    src: 'abortTask',
                },
            },
            error: {
                type: 'final',
                data: {
                    type: 'error',
                    error: (_: TaskStateContext, e: TaskEvent) => {
                        if (e.type === 'ERROR') {
                            return e.error
                        }
                        if (e.type.match('error.platform')) {
                            return (e as DoneInvokeEvent<APIError>).data
                        }
                    },
                },
            },
            'user-exit': {
                type: 'final',
                data: {
                    type: 'user-exit',
                },
            },
            timeout: {
                type: 'final',
                data: {
                    type: 'timeout',
                },
            },
            'removed-by-ops': {
                type: 'final',
                data: {
                    type: 'removed-by-ops',
                },
            },
            finished: {
                type: 'final',
                data: {
                    type: 'finished',
                },
            },
        },
    },
    {
        actions: {
            turnOnStatusRequest: sendParent('TURN_ON_STATUS_REQUESTS'),
            turnOffStatusRequest: sendParent('TURN_OFF_STATUS_REQUESTS'),
            toggleExit: assign({
                exiting: (ctx) => !ctx.exiting,
            }),
            tick: assign({
                elapsed: (ctx) => (Date.now() - ctx.taskReceivedTimestamp) / 1000,
                allowedToSubmit: (ctx) => {
                    return (
                        ctx.elapsed + 1 > Math.round(ctx.minSubmitDuration) &&
                        ctx.elapsed + 1 <= ctx.timerDuration
                    )
                },
            }),
            initializeWaitingForTask: assign<TaskStateContext, TaskEvent>({
                waitingForTaskStart: () => new Date().getTime(),
            }),
            resetContextForNewTask: assign<TaskStateContext, TaskEvent>({
                timerDuration: (ctx) => calculateTimerDuration(ctx.task),
                minSubmitDuration: (ctx) => calculateMinSubmitTime(ctx.task),
                allowedToSubmit: (ctx) => calculateMinSubmitTime(ctx.task) === 0, // check minSubmitDuration
                elapsed: () => 0,
            }),
            resetTask: assign<TaskStateContext, TaskEvent>({
                autoSubmit: () => undefined,
                taskPayload: () => undefined,
                recoveredFromCache: false,
            }),
            resetTaskCache: () => {
                TaskCache.clearTranscriptSnapshot()
            },
            analyticsTaskAssigned: (ctx, event) => {
                const { task, waitingForTaskStart } = ctx

                const isFirstTaskInLayer =
                    event.type === 'TASK_RECEIVED' && event.isFirstTaskInLayer
                const waitedForTaskSeconds = (new Date().getTime() - waitingForTaskStart) / 1000

                switch (task?.type) {
                    case 'transcription':
                        return analytics?.sendTaskAssigned(
                            task.id,
                            task.type,
                            task.assignedAt,
                            task.timeoutMs / 1000,
                            task.payload.text.editable.timing.end -
                                task.payload.text.editable.timing.start,
                            null,
                            waitedForTaskSeconds,
                            isFirstTaskInLayer,
                        )
                    case 'onboarding':
                        return analytics?.sendTaskAssigned(
                            task.id,
                            task.type,
                            task.assignedAt,
                            task.timeoutMs / 1000,
                            null,
                            task.payload.orderedStepTypes,
                            waitedForTaskSeconds,
                            isFirstTaskInLayer,
                        )
                    case 'gloss_population':
                        return analytics?.sendTaskAssigned(
                            task.id,
                            task.type,
                            task.assignedAt,
                            task.timeoutMs / 1000,
                            null,
                            null,
                            waitedForTaskSeconds,
                            isFirstTaskInLayer,
                        )
                }
            },
            analyticsTaskSubmitted: (ctx, event: TaskEvent) => {
                const {
                    task,
                    elapsed,
                    invalidTermsCountStart,
                    invalidTermsCountEnd,
                    spellingErrorsStart,
                    spellingErrorsEnd,
                    currentValidationsCount,
                    audioTimeListenedSeconds,
                    userIdleTimeSeconds,
                    autoSubmit,
                } = ctx

                if (task?.id && task?.type === 'transcription') {
                    const count = blocksAnalyticsSingleton.getValue()
                    analytics?.sendSpeakersSeparationCount(task?.id, count)
                    blocksAnalyticsSingleton.resetCount()
                }

                switch (task?.type) {
                    case 'transcription':
                        return analytics?.sendTaskSubmitted(
                            task.id,
                            task.type,
                            (event as SubmitEvent).publishType,
                            autoSubmit,
                            elapsed,
                            task.timeoutMs / 1000,
                            task.timeoutMs / 1000 - elapsed,
                            task.payload.text.editable.timing.end -
                                task.payload.text.editable.timing.start,
                            null,
                            spellingErrorsStart,
                            spellingErrorsEnd,
                            invalidTermsCountStart,
                            invalidTermsCountEnd,
                            currentValidationsCount,
                            userIdleTimeSeconds,
                            audioTimeListenedSeconds,
                        )
                    case 'onboarding':
                        return analytics?.sendTaskSubmitted(
                            task.id,
                            task.type,
                            (event as SubmitEvent).publishType,
                            autoSubmit,
                            elapsed,
                            task.timeoutMs / 1000,
                            task.timeoutMs / 1000 - elapsed,
                            null,
                            task.payload.orderedStepTypes,
                            userIdleTimeSeconds,
                        )
                    case 'gloss_population':
                        return analytics?.sendTaskSubmitted(
                            task.id,
                            task.type,
                            (event as SubmitEvent).publishType,
                            autoSubmit,
                            elapsed,
                            task.timeoutMs / 1000,
                            task.timeoutMs / 1000 - elapsed,
                            null,
                            null,
                            userIdleTimeSeconds,
                        )
                }
            },
            setLayerIdInAnalytics: (_, event) => {
                const layerId = (event as TaskReceivedEvent)?.task?.layerId
                if (!!layerId) {
                    analytics?.setLayerId(layerId)
                }
            },
        },
        guards: {
            exiting: (ctx) => ctx.exiting,
            timerIsUp: (ctx) => {
                return ctx.elapsed >= Math.floor(ctx.timerDuration)
            },
        },
        services: {
            fetchTask: (ctx) => async (send) => {
                const { httpClient, initialReceivedTasks } = ctx
                let internalServerErrorCountRequest = 0
                if (!httpClient) throw new Error(`No HttpClient available in TaskMachine`)
                while (true) {
                    try {
                        const taskRequest = await httpClient.requestNewTask()

                        if (taskRequest.task?.type === 'transcription') {
                            const { editable, suggestionsEnabled } =
                                taskRequest.task.payload.controls
                            const isGlossersMode = !editable && !suggestionsEnabled

                            if (isGlossersMode) {
                                send('ENABLE_GLOSSERS_MODE')
                            }
                        }
                        switch (taskRequest.availability) {
                            case 'NEW_TASK':
                            case 'EXISTING_TASK': {
                                const { task, sessionResources } = taskRequest
                                const isFirstTaskInLayer =
                                    !initialReceivedTasks?.[task.layerId]?.[task.type]

                                if (!initialReceivedTasks) {
                                    analytics.extendClientUserProps({
                                        [TRAX_USER_INITIAL_ROLE]: task.layerId,
                                    })
                                }

                                if (!isFirstTaskInLayer) {
                                    appcues?.track(
                                        `INITIAL_${task.layerId.toUpperCase()}_${task.type.toUpperCase()}_TASK`,
                                        task,
                                    )
                                }

                                let recoveredFromCache = false
                                if (
                                    taskRequest.availability === 'EXISTING_TASK' &&
                                    TaskCache.verifyCacheTaskId(task.id)
                                ) {
                                    recoveredFromCache = true
                                } else {
                                    TaskCache.clearTranscriptSnapshot()
                                }

                                if (TaskRegistry.allowsCache(task.type)) {
                                    TaskCache.saveCurrentTaskId(task.id)
                                }
                                send({
                                    type: 'TASK_RECEIVED',
                                    task,
                                    sessionResources,
                                    recoveredFromCache,
                                    isFirstTaskInLayer,
                                })
                                return
                            }
                            case 'PAUSED': {
                                if (taskRequest.playbackState === 'PAUSED') {
                                    send('SESSION_PAUSED')
                                }
                                break
                            }
                            case 'UNAVAILABLE_TEMPORARY': {
                                if (taskRequest.playbackState === 'NOT_STARTED') {
                                    send('SESSION_NOT_STARTED')
                                } else {
                                    send('NO_AVAILABLE_TASKS')
                                }
                                break
                            }
                            case 'UNAVAILABLE_PERMANENT': {
                                send('FINISHED')
                                return
                            }
                        }
                    } catch (e) {
                        if (!(e instanceof APIError)) {
                            console.log(e)
                            send({ type: 'ERROR', error: e as Error })
                            return
                        }

                        switch (e.code) {
                            case ErrorCode.IgnoredStatusCodeError: {
                                break
                            }
                            case ErrorCode.WorkerLeft: {
                                send('FINISHED')
                                return
                            }
                            case ErrorCode.WorkerRemovedInactivity:
                            case ErrorCode.WorkerRemovedTaskTimeout: {
                                send('EXIT_TIMEOUT')
                                return
                            }
                            case ErrorCode.WorkerNotFound: {
                                send('EXIT_REMOVED_BY_OPS')
                                return
                            }
                            default: {
                                if (e.httpCode === 500) {
                                    internalServerErrorCountRequest++
                                }
                                if (
                                    e.httpCode !== 500 ||
                                    internalServerErrorCountRequest ===
                                        MAX_INTERNAL_SERVER_ERROR_RETRIES
                                ) {
                                    send({ type: 'ERROR', error: e })
                                    console.log(e)
                                    return
                                }
                            }
                        }
                    }

                    // if no tasks are available, wait 5 seconds
                    await delay(TASK_POLLING_DELAY_MS)
                }
            },
            taskTimer: () => (send) => {
                const interval = setInterval(() => {
                    send('TICK')
                }, 1000)

                return () => clearInterval(interval)
            },
            submitTask: (ctx) => async (send) => {
                const { httpClient, taskPayload, task, exiting, autoSubmit, workerId } = ctx

                if (!task || !taskPayload) {
                    throw new Error('task or taskPayload are not defined when trying to submit')
                }

                let retries = 0
                let internalServerErrorCountSubmit = 0
                while (true) {
                    try {
                        await httpClient?.publishTask(task, {
                            type: task.type,
                            task_id: task.id,
                            worker_id: workerId,
                            auto_submit: autoSubmit,
                            payload: taskPayload,
                        })

                        if (exiting) {
                            send('EXIT')
                        } else {
                            send('SUBMIT_SUCCESSFUL')
                        }
                        return
                    } catch (e: any) {
                        if (!(e instanceof APIError) && e.message !== 'Network Error') {
                            send({ type: 'ERROR', error: e })
                            return
                        }

                        switch (e.code) {
                            // these are the exceptions, when we want to continue submitting
                            // (the break only breaks out of the switch-case, not the loop)
                            case ErrorCode.TaskSubmittedTooEarly:
                            case ErrorCode.IgnoredStatusCodeError: {
                                break
                            }

                            // these are exceptions, when we don't want to continue submitting
                            // but also don't want to show it as an error.
                            case ErrorCode.TaskNotFound:
                            case ErrorCode.TaskWrongAssignment:
                            case ErrorCode.TaskTimedOut: {
                                send({ type: 'RETRY_ABORTED' })
                                return
                            }

                            // by default, just show the error
                            default: {
                                if (e.httpCode === 500) {
                                    internalServerErrorCountSubmit++
                                }
                                if (
                                    e.httpCode !== 500 ||
                                    internalServerErrorCountSubmit ===
                                        MAX_INTERNAL_SERVER_ERROR_RETRIES
                                ) {
                                    send({ type: 'ERROR', error: e })
                                    console.log(e)
                                    return
                                }
                            }
                        }
                    }

                    retries++
                    if (retries > MAX_RETRIES) {
                        send('RETRY_ABORTED')
                        return
                    }

                    await delay(RETRY_DELAY_MS)
                }
            },
            abortTask:
                ({ httpClient, task, taskAbortReason }) =>
                async () => {
                    if (task && taskAbortReason) {
                        await httpClient?.abortTask(task.id, taskAbortReason)
                    }
                },
        },
    },
)

function calculateTimerDuration(task?: Task): number {
    if (!task) return 0

    return Math.round(
        ((task?.timeoutMs ?? TIMEOUT_GRACE_PERIOD_MS) - TIMEOUT_GRACE_PERIOD_MS) / 1000,
    )
}

function calculateMinSubmitTime(task?: Task) {
    if (!task) return 0

    return Math.round(task?.minSubmitMs > 0 ? task.minSubmitMs / 1000 : 0)
}
