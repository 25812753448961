import React from 'react'
import styled from 'styled-components/macro'

import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'

import { Spinner } from './Spinner'

const Emoji = styled.span`
    font-size: 22px;
    margin-left: 4px;
`

export function WaitingForTaskScreen() {
    const [{ context }] = useTaskMachine(['sessionPaused', 'sessionNotStarted'])

    if (context.sessionNotStarted) {
        return (
            <Spinner title="Session did not start yet" subtitle="The session will start shortly." />
        )
    }

    if (context.sessionPaused) {
        return <Spinner title="Session is paused" subtitle="The session will resume shortly." />
    }

    return (
        <Spinner
            title={
                <>
                    Waiting for a new task<Emoji>⏱</Emoji>
                </>
            }
            subtitle="This can take a few seconds to a minute."
        />
    )
}
