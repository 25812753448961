import { useCallback, useMemo, useState } from 'react'
import { Popover2 } from '@blueprintjs/popover2'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useAnalytics } from 'src/analytics'
import { formatDuration } from 'src/utils/time'
import { WorkBreakError } from 'src/network/responses'
import { HotMugIcon, MugIcon } from 'src/components/icons'
import { Container, Footer, Message, Title } from 'src/components/Common/styled/popup'
import { AnchorButton, NegativeButton, PositiveButton } from 'src/components/Common/styled/buttons'
import { useToast } from 'src/components/Toasts/ToastContext'

const Note = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;
    padding: 14px 11px 17px 11px;
    background-color: ${palette('cloudBlueLight', 9)};
    font-size: 14px;
`

const NoteTitle = styled.div`
    font-weight: 600;
    color: ${palette('navy', 4)};
`

const NoteContent = styled.div`
    margin-top: 6px;
    color: ${palette('navy', 1)};
`

const IconWrapper = styled.div`
    margin-right: 5px;

    svg {
        height: 16px;
        width: auto;
    }
`

const AvailableBreakTimeContainer = styled.div`
    display: flex;
    align-items: baseline;
`

const AvailableBreakTime = styled.div`
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: 600;
    color: ${palette('turquoise', 0)};
`

const TimeFormatLabel = styled.div`
    margin-left: 4px;
`

const ToastHotMugIcon = styled(HotMugIcon)`
    width: 18px;
    height: 18px;
    margin: auto 0 auto 13px;
    padding-bottom: 2px;
    color: ${palette('navy', 2)};
`

const ToastMessage = styled.div`
    color: ${palette('navy', 2)};
    font-size: 14px;
`

export const WorkBreakPopup = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [{ context, value: taskMachineState }, sendTaskEvent] = useTaskMachine([
        'state',
        'task',
        'httpClient',
    ])
    const { sessionStatus, refreshSessionStatus } = useSessionStatus([
        'refreshSessionStatus',
        'sessionStatus.workBreakStatus.positionInQueue',
        'sessionStatus.workBreakStatus.isAllowed',
        'sessionStatus.workBreakStatus.notAllowedReason',
        'sessionStatus.workBreakStatus.availableTime',
        'sessionStatus.workBreakStatus.workBreakRatio',
        'sessionStatus.workBreakStatus.minTimeForWorkBreak',
    ])
    const addToast = useToast()
    const analytics = useAnalytics()

    const { task, httpClient } = context
    const {
        positionInQueue,
        isAllowed,
        notAllowedReason,
        availableTime = 0,
        workBreakRatio,
        minTimeForWorkBreak = 0,
    } = sessionStatus?.workBreakStatus || {}
    const onBreak = task?.type === 'resting'
    const inPendingWorkBreak = !onBreak && positionInQueue !== null

    const onRequestBreak = useCallback(async () => {
        await httpClient?.joinRestingQueue()

        addToast({
            intent: 'none',
            icon: <ToastHotMugIcon />,
            message: <ToastMessage>Your request for a break has been accepted</ToastMessage>,
        })
    }, [addToast, httpClient])

    const onBreakButtonClick = useCallback(() => {
        if (onBreak) {
            sendTaskEvent({ type: 'SUBMIT', autoSubmit: false, publishType: 'Mouse' })
        } else if (inPendingWorkBreak) {
            httpClient?.leaveRestingQueue()
        } else if (isAllowed) {
            onRequestBreak()
        }

        setIsOpen(false)
    }, [httpClient, inPendingWorkBreak, isAllowed, onBreak, onRequestBreak, sendTaskEvent])

    const breakButtonLabel = useMemo(() => {
        if (onBreak) {
            return 'Back to work'
        }

        if (inPendingWorkBreak) {
            return 'Cancel break'
        }

        if (!isAllowed) {
            return 'OK'
        }

        return 'Request a break'
    }, [inPendingWorkBreak, isAllowed, onBreak])

    if (taskMachineState === 'aborting') {
        return null
    }

    return (
        <Popover2
            className="no-shadow"
            isOpen={isOpen}
            modifiers={{ arrow: { enabled: false }, offset: { options: { offset: [0, 9] } } }}
            onClose={() => setIsOpen(false)}
            onOpened={() => {
                analytics?.sendOpenBreakOverlay()
                refreshSessionStatus()
            }}
            canEscapeKeyClose
            content={
                <Container>
                    {!onBreak && (
                        <>
                            {notAllowedReason
                                ? renderWorkBreakError(minTimeForWorkBreak, notAllowedReason)
                                : inPendingWorkBreak && (
                                      <Note>
                                          <NoteTitle>
                                              {positionInQueue === 0
                                                  ? "You're the next to take a break"
                                                  : `There are ${positionInQueue} people in line before you`}
                                          </NoteTitle>
                                      </Note>
                                  )}

                            <Title>Available break time:</Title>
                            <AvailableBreakTimeContainer>
                                <AvailableBreakTime>
                                    {formatDuration(availableTime)}
                                </AvailableBreakTime>
                                <TimeFormatLabel>min:sec</TimeFormatLabel>
                            </AvailableBreakTimeContainer>
                        </>
                    )}

                    <Message>
                        {onBreak ? (
                            <div>
                                You may go back to work when ready, and save the remaining break
                                time for later.
                            </div>
                        ) : (
                            <>
                                <div>Break time accumulates as you work.</div>
                                <div>
                                    <strong>1 min</strong> break per{' '}
                                    <strong>{workBreakRatio} mins</strong> work.
                                </div>
                            </>
                        )}
                    </Message>

                    <Footer>
                        <PositiveButton onClick={onBreakButtonClick}>
                            {breakButtonLabel}
                        </PositiveButton>
                        {isAllowed && !(onBreak || inPendingWorkBreak) && (
                            <NegativeButton onClick={() => setIsOpen(false)}>
                                Keep Working
                            </NegativeButton>
                        )}
                    </Footer>
                </Container>
            }
        >
            <AnchorButton onClick={() => setIsOpen(!isOpen)} disabled={!isAllowed}>
                <IconWrapper>{onBreak ? <HotMugIcon color="#fff" /> : <MugIcon />}</IconWrapper>
                <div>{onBreak ? 'On a break' : 'Take a break'}</div>
            </AnchorButton>
        </Popover2>
    )
}

const renderWorkBreakError = (minTimeForWorkBreak: number, notAllowedReason: string) => {
    switch (notAllowedReason) {
        case WorkBreakError.NotAllowed:
            return (
                <Note>
                    <NoteTitle>
                        You need to accumulate at least {formatDuration(minTimeForWorkBreak)} to go
                        on a break.
                    </NoteTitle>
                    <NoteContent>
                        If you must go now, you may leave the session and attempt to rejoin later.
                    </NoteContent>
                </Note>
            )

        case WorkBreakError.MaxRestingWorkerLimitReached:
            return (
                <Note>
                    <NoteTitle>Other team members are currently on a break.</NoteTitle>
                    <NoteContent>
                        You'll be able to start you break when they return within a few minutes.
                    </NoteContent>
                </Note>
            )

        default:
            return notAllowedReason
    }
}
