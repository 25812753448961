import styled from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'
import { IMaskInput } from 'react-imask'
import { Button } from '@blueprintjs/core'

export const TimeCodeContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    top: 9px;
    width: 242px;
    height: 50px;
    background: none;
    z-index: 20 !important;
`

export const TimeCode = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    width: 242px;
    height: 40px;
    margin-bottom: 4px;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.24px;
`

export const TimeCodeGrey = styled.input<{
    isTimeCodeVisible: boolean | undefined
    isSectionCalibrationAnchor: boolean | undefined
}>`
    display: flex;
    position: relative;
    text-align: right;
    margin-top: ${ifProp(['isTimeCodeVisible', 'isSectionCalibrationAnchor'], 48, 32)}px;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    font-weight: 600;
    background: none;
    border: none;
    resize: none;
    color: ${palette('greyBlue', 0)};
    letter-spacing: 0.24px;
    input[aria-readonly='true'] {
        pointer-events: none;
    }

    &::placeholder {
        color: ${palette('greyBlue', 0)};
    }
    &:focus {
        outline: none;
    }
`

export const TimeCodeInputMask = styled(IMaskInput)`
    display: block;
    position: absolute;
    top: 4px;
    right: 0;
    background: none;
    color: ${palette('blue', 0)};
    width: 68px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    border: none;

    &::placeholder {
        color: ${palette('blue', 0)};
    }
    &:focus {
        outline: none;
    }
`

export const RemoveButton = styled(Button)`
    display: none;
    position: absolute;
    min-width: 8px;
    min-height: 8px;
    background-color: transparent !important;
    left: 130px;
    top: -2.5px;
    &.bp4-button .bp4-icon {
        color: rgb(179, 186, 197);
    }

    &.bp4-button:hover .bp4-icon {
        color: #5c7080;
    }
`
