import { BaseEditor, Editor, Node, NodeEntry, Range } from 'slate'
import { RenderElementProps, RenderLeafProps } from 'slate-react'

export interface DecoratedRange extends Range {
    selection?: boolean
    timingHighlight?: 'before' | 'now' | 'after'
    uuid?: string
    liveUpdate?: boolean
}

export interface PluggableEditor extends BaseEditor {
    /**
     * Renders things next to the editor.
     * Make sure to always call the original `editor.renderEditor()` method to allow other plugins to render things as well.
     */
    renderEditor: () => React.ReactNode
    renderElement: (props: RenderElementProps) => JSX.Element
    renderLeaf: (props: RenderLeafProps) => JSX.Element
    decorate: (entry: NodeEntry<Node>) => DecoratedRange[]
}

export function withPlugins(editor: Editor) {
    editor.renderEditor = () => null

    editor.renderElement = (props) => {
        throw new Error(
            `There is no render function for element of type ${props.element.type}. You must override editor.renderElement() in your plugin.`,
        )
    }

    editor.renderLeaf = (props) => {
        throw new Error(
            `There is no render function for ${JSON.stringify(
                props.leaf,
                undefined,
                2,
            )}.\nYou must override editor.renderLeaf() in your plugin.`,
        )
    }

    editor.decorate = (entry) => []

    return editor
}
