import { ErrorScreen } from 'src/components/Common/ErrorScreen'
import { UnsupportedBrowserIcon } from 'src/components/icons'

export const UnsupportedBrowserError = () => (
    <ErrorScreen
        icon={<UnsupportedBrowserIcon />}
        title="The browser you're using isn't supported"
        subtitle={
            <>
                <div>Collaborative sessions can only be run on Google Chrome.</div>
                <div>Install Chrome, and reopen this session.</div>
            </>
        }
        actionTitle="Download Chrome"
        action={() => window.open('https://www.google.com/chrome')}
    />
)

export const UnsupportedBrowserVersionError = () => (
    <ErrorScreen
        icon={<UnsupportedBrowserIcon />}
        title="The browser version you're using isn't supported"
        subtitle={
            <>
                <div>
                    In order to join a collaborative session, you need a newer version of Google
                    Chrome.
                </div>
                <div>Update your browser and reopen this session.</div>
            </>
        }
        actionTitle="Update Chrome"
        action={() => window.open('https://www.google.com/chrome/update/')}
    />
)
