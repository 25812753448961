import { useEffect } from 'react'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

/**
 * Plays the task audio automatically on first render, when passed true.
 * @param autoplay determines, if the task audio should be autoplayed when rendering the first time or not.
 */
export function useAudioAutoplay(autoplay: boolean) {
    const { play, mediaRef } = useSessionMedia(['play', 'mediaRef'])

    const audioSource = mediaRef.current?.src

    // autoplay if enabled
    useEffect(() => {
        if (autoplay && audioSource) {
            play()
        }
    }, [play, autoplay, audioSource])
}
