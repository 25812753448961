import { useEffect, useRef } from 'react'

import { Overlay } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import 'src/tasks/register'
import { TaskRegistry } from 'src/tasks/Registry'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useVideo } from 'src/state/VideoProvider'
import { AbsolutePositionedVideoPlayer } from 'src/components/VideoPlayer/AbsolutePositionedVideoPlayer'
import { useVideoAbsoluteDropZone } from 'src/components/VideoPlayer/useVideoAbsoluteDropZone'
import { TaskState } from 'src/components/TaskRouter/TaskState'
import { useToast } from 'src/components/Toasts/ToastContext'
import { useIsPanelTabsOpen } from 'src/components/Session/PanelTabsManager'
import { SubmittingScreen } from 'src/components/Session/SubmittingScreen'
import { CustomVideoDragLayer } from 'src/components/VideoPlayer/CustomVideoDraggingLayer'

const Container = styled.div<{ isPanelTabsOpen: boolean }>`
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
    height: calc(100% - 60px);
    background-color: ${palette('white', 0)};
    transition: width 700ms;

    & .bp4-overlay-backdrop {
        opacity: 1 !important;
        background: ${palette('cloudBlueLight', 7)};
    }

    & .bp4-overlay {
        z-index: 1000 !important;
    }
`

const SubmittingContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export function TaskRouter() {
    const [taskMachineState] = useTaskMachine(['state', 'task', 'recoveredFromCache'])
    const { isVideoSnappedIntoPosition } = useVideo()
    const { task, recoveredFromCache } = taskMachineState.context
    const addToast = useToast()
    const isPanelTabsOpen = useIsPanelTabsOpen()
    const drop = useVideoAbsoluteDropZone()

    useEffect(() => {
        if (recoveredFromCache) {
            addToast({
                intent: 'success',
                message:
                    'We restored your last task along with your progress. Watch out for the remaining time!',
            })
        }
    }, [addToast, recoveredFromCache])

    const taskRef = useRef(task)

    useEffect(() => {
        taskRef.current = task
    }, [task])

    if (!task) return null

    return (
        <>
            <Container isPanelTabsOpen={isPanelTabsOpen} ref={drop}>
                <Overlay
                    isOpen={['task-processing', 'submitting'].includes(taskMachineState.value)}
                    usePortal={false}
                >
                    <SubmittingContainer>
                        <SubmittingScreen />
                    </SubmittingContainer>
                </Overlay>
                <TaskState>{TaskRegistry.render(task.type)}</TaskState>
                {!isVideoSnappedIntoPosition && <AbsolutePositionedVideoPlayer />}
            </Container>
            <CustomVideoDragLayer />
        </>
    )
}
