import React, { useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { ifProp, prop } from 'styled-tools'

import * as Icons from 'src/components/icons'
import { theme } from 'src/components/styled'

const MainColor = styled.div`
    margin-top: 20px;
`

const MainColorShades = styled.div`
    display: flex;
    margin-top: 5px;
`

const ColorBox = styled.div<{ isSelected: boolean; color: string }>`
    width: ${ifProp('isSelected', 50, 30)}px;
    height: ${ifProp('isSelected', 50, 30)}px;
    margin: ${ifProp('isSelected', '0 10px', 0)};
    border: ${ifProp('isSelected', '1px solid #000', 'none')};
    background-color: ${prop('color')};
    cursor: pointer;
`

const IconsGrid = styled.div`
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: 10px;
    row-gap: 30px;
    padding: 20px;
`

const IconCell = styled.span<{ color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${prop('color')};

    svg {
        width: 30px;
        height: auto;
    }
`

export function IconsPreview() {
    const [selectedColor, setSelectedColor] = useState(theme.palette.navy[0])

    const icons = useMemo(() => {
        return Object.entries(Icons)
    }, [])

    return (
        <div>
            <div>
                {Object.entries(theme.palette).map(([color, shades]) => (
                    <MainColor key={color}>
                        {color}

                        <MainColorShades>
                            {shades.map((shade) => (
                                <ColorBox
                                    key={shade}
                                    onClick={() => setSelectedColor(shade)}
                                    isSelected={selectedColor === shade}
                                    color={shade}
                                />
                            ))}
                        </MainColorShades>
                    </MainColor>
                ))}
            </div>

            <IconsGrid>
                {icons.map(([name, Icon]) => (
                    <IconCell key={name} color={selectedColor}>
                        <Icon width={24} height={24} />
                        <span>{name}</span>
                    </IconCell>
                ))}
            </IconsGrid>
        </div>
    )
}
