import { BaseEditor, Editor, Path, RangeRef, Transforms } from 'slate'
import { TranscriptWebsocketMessage } from 'src/models'
import { Block, createBlocks } from '../withTranscript'
import { TimelineEditor } from '../withTimeline/TimelineEditor'
import { BlockModificationsObject } from './BlockModificationObject'
import { validateTimelineData } from '../withTimeline/validateTimelineData'
import { HistoryEditor } from 'slate-history'

export interface LiveEditsEditor extends BaseEditor {
    liveModifications: BlockModificationsObject | null
}

const EDITOR_TO_LIVE_EDIT_ENABLED = new WeakMap<Editor, boolean>()

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LiveEditsEditor = {
    mergeIncomingEvent(editor: Editor, event: TranscriptWebsocketMessage) {
        const { timeline } = editor
        const { start: eventStart, end: eventEnd } = event.timing

        if (!event.words.length) {
            return { unref: () => {}, current: null } as RangeRef
        }

        const eventRange = {
            anchor: Block.findPointByTime(editor, eventStart, { affinity: 'start' }),
            focus: Block.findPointByTime(editor, eventEnd, { affinity: 'end' }),
        }

        const [eventBlocks, eventTimings] = createBlocks(
            { ...event, type: 'editable' },
            { editable: true, isFirstSegment: false },
        )

        timeline.mergeOrginalSegment(eventTimings)

        HistoryEditor.withoutSaving(editor, () => {
            TimelineEditor.withoutTimeline(editor, () => {
                LiveEditsEditor.withoutLiveEdits(editor, () => {
                    Editor.withoutNormalizing(editor, () => {
                        const startBlock = eventRange.anchor.path[0]

                        const isStartOfStartBlock = Editor.isStart(editor, eventRange.anchor, [
                            startBlock,
                        ])
                        const isStartBlockEmpty = Editor.string(editor, [startBlock]) === ''

                        const shouldBreak = Boolean(eventBlocks[0].hasExplicitBreak)

                        Transforms.delete(editor, { at: eventRange })
                        Transforms.insertNodes(editor, eventBlocks, { at: Path.next([startBlock]) })

                        // if the first block-to-be-inserted doesn't have an explicit break,
                        // merge it with the previous block
                        if (!shouldBreak) {
                            Transforms.mergeNodes(editor, { at: Path.next([startBlock]) })

                            // after merging 2 blocks there will be 2 content nodes, that we need to merge as well
                            Transforms.mergeNodes(editor, { at: [startBlock, 1] })
                        }

                        // if the insert point is at the start of the block, it will be replaced entirely
                        // therefore we can remove the block
                        if (isStartOfStartBlock && shouldBreak) {
                            Transforms.removeNodes(editor, { at: [startBlock] })
                        }

                        const shouldAddSpace =
                            !shouldBreak && !isStartOfStartBlock && !isStartBlockEmpty
                        if (shouldAddSpace) {
                            Transforms.insertText(editor, ' ', { at: eventRange.anchor })
                        }
                    })
                })
            })
        })

        validateTimelineData(editor)

        return Editor.rangeRef(editor, {
            anchor: eventRange.anchor,
            focus: Editor.end(editor, []),
        })
    },

    shouldAllowLiveEdits(editor: Editor) {
        return EDITOR_TO_LIVE_EDIT_ENABLED.get(editor) ?? true
    },

    withoutLiveEdits(editor: Editor, fn: () => void) {
        const prev = LiveEditsEditor.shouldAllowLiveEdits(editor)

        EDITOR_TO_LIVE_EDIT_ENABLED.set(editor, false)

        try {
            fn()
        } finally {
            EDITOR_TO_LIVE_EDIT_ENABLED.set(editor, prev)
        }
    },
}
