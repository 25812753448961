import React, { ReactNode, useCallback, MouseEvent } from 'react'
import styled from 'styled-components/macro'
import { ifProp, palette, prop } from 'styled-tools'

import { VoiceSampleButton } from 'src/components/Common/VoiceSampleButton'

const ContentContainer = styled.div`
    display: flex;
`

const MainContent = styled.div`
    display: flex;
    align-items: baseline;
    flex: 1;
    padding: 6px 0;
`

const ColorLabel = styled.div<{ color: string }>`
    width: 10px;
    height: 10px;
    margin: 5px 10px 0 0;
    border-radius: 4px;
    background-color: ${prop('color')};
`

const Details = styled.div`
    padding-right: 10px;
`

const Name = styled.div`
    margin-bottom: 2px;
    color: ${palette('navy', 6)};
`

const Role = styled.div`
    font-size: 13px;
    color: ${palette('cloudBlue', 0)};
`

const VOICE_SAMPLE_BUTTON_ICON_SIZE = 12
const StyledVoiceSampleButton = styled(VoiceSampleButton)`
    width: ${VOICE_SAMPLE_BUTTON_ICON_SIZE}px;
    height: ${VOICE_SAMPLE_BUTTON_ICON_SIZE}px;
    color: ${ifProp('isPlaying', palette('navy', 0), palette('cloudBlue', 2))};
`

const VoiceSampleButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-left: solid 1px ${palette('white', 0)};
    transition: opacity 250ms ease-out;

    &:hover {
        opacity: 0.5;
    }

    &:last-of-type {
        padding-right: 2px;
    }
`

interface SpeakerMenuItemProps {
    onToggleVoiceSample: (
        speakerId: string,
        sampleId: string,
        isPlaying: boolean,
        e: MouseEvent,
    ) => void
    speakerId: string
    name: ReactNode
    role: string
    color: string
    voiceSampleId: string | null
    isVoiceSamplePlaying?: boolean
    isVoiceSampleBuffering?: boolean
}

export const SpeakerMenuItem = ({
    onToggleVoiceSample,
    speakerId,
    name,
    role,
    color,
    voiceSampleId,
    isVoiceSamplePlaying = false,
    isVoiceSampleBuffering = false,
}: SpeakerMenuItemProps) => {
    const onVoiceSampleButtonClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
        (e) => {
            if (voiceSampleId !== null) {
                onToggleVoiceSample(speakerId, voiceSampleId, isVoiceSamplePlaying, e)
            }
        },
        [speakerId, voiceSampleId, isVoiceSamplePlaying, onToggleVoiceSample],
    )

    return (
        <ContentContainer>
            <MainContent>
                <ColorLabel color={color} />

                <Details>
                    <Name>{name}</Name>
                    <Role>{role}</Role>
                </Details>
            </MainContent>

            {voiceSampleId !== null && (
                <VoiceSampleButtonContainer onClick={onVoiceSampleButtonClick}>
                    <StyledVoiceSampleButton
                        isPlaying={isVoiceSamplePlaying}
                        isBuffering={isVoiceSampleBuffering}
                    />
                </VoiceSampleButtonContainer>
            )}
        </ContentContainer>
    )
}
