import { useContext, createContext, ReactNode } from 'react'
import { AnalyticsClient } from './client'
import { analytics } from './singleton'

const Context = createContext<AnalyticsClient | null>(null)

interface AnalyticsProviderProps {
    children: ReactNode
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
    return <Context.Provider value={analytics}>{children}</Context.Provider>
}

export function useAnalytics() {
    return useContext(Context)
}
