import {
    Editor,
    BaseElement,
    Node,
    NodeEntry,
    Path,
    Transforms,
    Location,
    Range,
    BasePoint,
    Point,
    Text,
} from 'slate'
import { AssertionError } from 'assert'

import {
    Segment,
    Word,
    SegmentType,
    Speaker,
    COLLOQUY_LEGAL_ANNOTATION,
    COURT_REPORTER_SPEAKER_ROLE,
} from 'src/models'
import { LegalMetadata, LegalExhibit } from 'src/models/legal'
import { assert } from 'src/utils/assert'
import {
    legalAnnotationsStore,
    SpeakersByLegalAnnotationMap,
} from 'src/state/LegalAnnotationsProvider'
import { splitToWords } from 'src/utils/string'

import { TimedTextSegment, getRangeFromTimings } from '../withTimeline/timeline'
import { Content, createContent } from 'src/components/Editor/plugins/withTranscript/Content'
import { ContentText } from 'src/components/Editor/plugins/withTranscript/ContentText'
import { Validations } from '../withValidations'
import { Suggestion } from '../withSuggestions/Suggestion'
import { Tag } from '../withTags/Tag'
import { TimingRange } from 'src/utils/range'
import { convertTimingToDateTime, formatTimecode } from 'src/utils/timecode'

export interface Block extends BaseElement {
    type: 'block'
    segmentType: SegmentType
    speakerId: string | null
    speakerMarkedAsUnknown?: boolean
    pendingLegalAnnotation?: string | null
    section?: string
    legal?: LegalMetadata
    editable: boolean
    hasExplicitBreak: boolean
    children: Content[]
    sectionMediaTime?: number | undefined
    bitcTimeCode?: string | undefined
    mediaTimeCode?: string | undefined
    start?: number | undefined
    end?: number | undefined
    __isNewBlock?: boolean // temporary solution - do not use!
}

interface AtOptions {
    at?: Location
}

interface FindPointByTimeOptions {
    affinity: 'start' | 'end'
}

const isAlphabeticOrNumericCharacter = (char: string): boolean => /[a-zA-Z0-9\-']/.test(char)

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Block = {
    isBlock: (value: any): value is Block => {
        return value?.type === 'block'
    },

    get: (editor: Editor, path: Path): Block => {
        if (path.length > 1) {
            throw new Error(
                `Cannot get the block at path [${path}] because it is not a root-level path.`,
            )
        }

        return Node.get(editor, path) as Block
    },

    getFromInnerPath: (editor: Editor, path: Path): NodeEntry<Block> => {
        return Editor.node(editor, path, { depth: 1 }) as NodeEntry<Block>
    },

    path: (path: Path): Path => {
        return path.length === 1 ? path : [path[0]]
    },

    isEditableBlockInLocation: (editor: Editor, at: Location): boolean => {
        for (const [block] of Editor.nodes(editor, { at, match: Block.isBlock })) {
            if (!block.editable) {
                return false
            }
        }

        return true
    },

    setSection: (editor: Editor, path: Path, section?: string) => {
        const block = Block.get(editor, path)
        assertIsBlock(block)
        Transforms.setNodes(editor, { section }, { at: path })
    },

    setSectionMediaTime: (editor: Editor, path: Path, sectionMediaTime?: number) => {
        const block = Block.get(editor, path)
        assertIsBlock(block)
        Transforms.setNodes(editor, { sectionMediaTime }, { at: path })
    },

    setBITCTimeCode: (editor: Editor, path: Path, bitcTimeCode?: string) => {
        const block = Block.get(editor, path)
        assertIsBlock(block)
        Transforms.setNodes(editor, { bitcTimeCode }, { at: path })
    },

    setMediaTimeCode: (editor: Editor, path: Path, mediaTimeCode?: string) => {
        const block = Block.get(editor, path)
        assertIsBlock(block)
        Transforms.setNodes(editor, { mediaTimeCode }, { at: path })
    },

    setSpeaker: (editor: Editor, path: Path, speaker: Speaker | null) => {
        assert(path.length === 1, 'path to block type must contain only one level.')
        const blockIndex = path[0]
        const block = editor.children[blockIndex]
        assertIsBlock(block)
        let legalAnnotation: string | undefined

        if (speaker && speaker.role === COURT_REPORTER_SPEAKER_ROLE) {
            legalAnnotation = COLLOQUY_LEGAL_ANNOTATION
        } else if (speaker && legalAnnotationsStore.legalAnnotationsBySpeaker[speaker.id]) {
            legalAnnotation = legalAnnotationsStore.legalAnnotationsBySpeaker[speaker.id]
        } else if (
            speaker &&
            block.legal?.annotation &&
            !legalAnnotationsStore.speakersByLegalAnnotation[block.legal.annotation]
        ) {
            legalAnnotation = block.legal.annotation

            if (isExaminationLegalAnnotation(legalAnnotation)) {
                legalAnnotationsStore.setLegalAnnotation(legalAnnotation, speaker.id)
            }
        }

        Editor.withoutNormalizing(editor, () => {
            Transforms.setNodes(
                editor,
                {
                    speakerId: speaker?.id,
                    speakerMarkedAsUnknown: !speaker,
                    legal: {
                        ...block.legal,
                        annotation: legalAnnotation,
                    },
                },
                { at: path },
            )

            if (editor.shouldEnableQACAutomation && legalAnnotation && speaker) {
                Block.autoPopulateSpeakersAndLegalAnnotations(
                    editor,
                    blockIndex,
                    legalAnnotation,
                    true,
                )
            }
        })
    },

    setLegalAnnotation: (editor: Editor, path: Path, legalAnnotation: string) => {
        assert(path.length === 1, 'path to block type must contain only one level.')
        const block = Block.get(editor, path)
        const blockIndex = path[0]
        const legal = { ...block.legal, annotation: legalAnnotation }
        const speakerId = legalAnnotationsStore.speakersByLegalAnnotation[legalAnnotation]

        if (block.speakerId) {
            if (isExaminationLegalAnnotation(legalAnnotation)) {
                legalAnnotationsStore.setLegalAnnotation(legalAnnotation, block.speakerId)
            }
        } else if (speakerId) {
            Transforms.setNodes(editor, { speakerId }, { at: path })
        }

        // always flag to update Speaker API when setting a legal annotation
        editor.shouldUpdateSpeakerWithAnnotation = {
            id: block.speakerId ?? speakerId,
            qac: legalAnnotation,
        }

        Editor.withoutNormalizing(editor, () => {
            Transforms.setNodes(editor, { legal }, { at: path })

            if (editor.shouldEnableQACAutomation) {
                if (isExaminationLegalAnnotation(legalAnnotation)) {
                    Block.autoPopulateSpeakersAndLegalAnnotations(
                        editor,
                        blockIndex,
                        legalAnnotation,
                        false,
                    )
                } else {
                    Block.clearSpeakersAndLegalAnnotations(editor, path)
                }
            }
        })
    },

    setPendingLegalAnnotation: (
        editor: Editor,
        path: Path,
        pendingLegalAnnotation?: string | null,
    ) => {
        const block = Block.get(editor, path)
        assertIsBlock(block)
        Transforms.setNodes(editor, { pendingLegalAnnotation }, { at: path })
    },

    clearSpeakersAndLegalAnnotations(editor: Editor, path: Path) {
        const [blockIndex] = path

        Editor.withoutNormalizing(editor, () => {
            // when setting a non examination legal annotation, reset the speaker & the legal annotation of all the continuous blocks
            for (const [currentBlock, [i]] of Editor.nodes<Block>(editor, {
                at: [],
                match: (node) => Block.isBlock(node) && node.editable,
            })) {
                if (i <= blockIndex) {
                    continue
                }

                // stop when reaching a new section or a non-examination legal annotation
                if (
                    currentBlock.section ||
                    (currentBlock.legal?.annotation &&
                        !isExaminationLegalAnnotation(currentBlock.legal.annotation))
                ) {
                    return
                }

                Transforms.setNodes(
                    editor,
                    {
                        speakerId: undefined,
                        speakerMarkedAsUnknown: undefined,
                        legal: { ...currentBlock.legal, annotation: undefined },
                    },
                    { at: [i] },
                )
            }
        })
    },

    setLegalExhibit(editor: Editor, path: Path, exhibit?: LegalExhibit) {
        const block = Block.get(editor, path)
        assertIsBlock(block)
        const legal = { ...block.legal, exhibit }
        Transforms.setNodes(editor, { legal }, { at: path })
    },

    toggleExplicitBreak: (editor: Editor, path: Path, newValue?: boolean) => {
        assert(path.length === 1, 'path to block type must contain only one level.')
        const block = Block.get(editor, path)
        newValue = newValue === undefined ? !block.hasExplicitBreak : newValue
        if (newValue !== block.hasExplicitBreak) {
            Transforms.setNodes(editor, { hasExplicitBreak: newValue }, { at: path })
        }
    },

    isLegalAnnotationConflict(
        currentLegalAnnotation?: string,
        nextLegalAnnotation?: string,
        overwrite?: boolean,
    ) {
        return (
            currentLegalAnnotation &&
            nextLegalAnnotation !== currentLegalAnnotation &&
            (!overwrite || !isExaminationLegalAnnotation(currentLegalAnnotation))
        )
    },

    isSpeakerConflict(
        currentSpeakerId: string | null,
        nextSpeakerId: string | null,
        overwrite?: boolean,
    ) {
        return currentSpeakerId && nextSpeakerId !== currentSpeakerId && !overwrite
    },

    autoPopulateSpeakersAndLegalAnnotations(
        editor: Editor,
        startingBlockIndex: number,
        startingBlockLegalAnnotation: string,
        overwrite: boolean,
    ) {
        if (isExaminationLegalAnnotation(startingBlockLegalAnnotation)) {
            let prevBlockLegalAnnotation = startingBlockLegalAnnotation

            for (const [currentBlock, [i]] of Editor.nodes<Block>(editor, {
                at: [],
                match: (node) => Block.isBlock(node) && node.editable,
            })) {
                if (i <= startingBlockIndex || !currentBlock.children.length) {
                    continue
                }

                const nextLegalAnnotation =
                    getAlternatingExaminationLegalAnnotation(prevBlockLegalAnnotation)

                if (!nextLegalAnnotation) {
                    break
                }

                const nextSpeakerId =
                    legalAnnotationsStore.speakersByLegalAnnotation[nextLegalAnnotation] || null
                const legalAnnotationConflict = Block.isLegalAnnotationConflict(
                    currentBlock.legal?.annotation,
                    nextLegalAnnotation,
                    overwrite,
                )
                const speakerConflict = Block.isSpeakerConflict(
                    currentBlock.speakerId,
                    nextSpeakerId,
                    overwrite,
                )

                // stop propagating when reaching a new section, a legalAnnotationConflict or a speakerConflict
                if (currentBlock.section || legalAnnotationConflict || speakerConflict) {
                    return
                }

                Transforms.setNodes(
                    editor,
                    {
                        speakerId: nextSpeakerId,
                        speakerMarkedAsUnknown: undefined,
                        legal: { ...currentBlock.legal, annotation: nextLegalAnnotation },
                    },
                    { at: [i] },
                )

                prevBlockLegalAnnotation = nextLegalAnnotation
            }
        }
    },

    // get a range, that is expanded to contain the whole words on each side
    getSanitizedRange: (editor: Editor, options: AtOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return null

        const [node, path] = Editor.node(editor, at)

        // calculate start edge of sanitized range
        let wordStartPoint: BasePoint | undefined = Editor.point(editor, at, { edge: 'start' })
        const wordStartPointBefore =
            Editor.before(editor, wordStartPoint, { unit: 'character' }) || wordStartPoint
        const wordStartBefore = Editor.string(editor, {
            anchor: wordStartPointBefore,
            focus: wordStartPoint,
        })
        const isNodeValidationInvalid = Validations.isInvalidValidation(node)

        if (isNodeValidationInvalid) {
            const invalidNodeAnchor = { path, offset: 0 }
            const invalidNodeFocus = { path, offset: (node as ContentText).text.length }
            return { anchor: invalidNodeAnchor, focus: invalidNodeFocus }
        }

        if (isAlphabeticOrNumericCharacter(wordStartBefore)) {
            wordStartPoint = Editor.before(editor, at, { unit: 'word' })
        }

        if (Tag.hasTag(editor, { at: wordStartPoint })) {
            const [[, tagPath]] = Editor.nodes(editor, { at: wordStartPoint, match: Tag.isTag })
            wordStartPoint = Tag.getStartPoint(editor, tagPath) || wordStartPoint
        }

        if (Suggestion.hasSuggestion(editor, { at: wordStartPoint })) {
            const [[, sugPath]] = Editor.nodes(editor, {
                at: wordStartPoint,
                match: Suggestion.isSuggestion,
            })
            wordStartPoint = Suggestion.getStartPoint(editor, sugPath) || wordStartPoint
        }

        // calculate end edge of sanitized range
        let wordEndPoint: BasePoint | undefined = Editor.point(editor, at, { edge: 'end' })
        const wordEndPointAfter =
            Editor.after(editor, wordEndPoint, { unit: 'character' }) || wordEndPoint
        const wordEndAfter = Editor.string(editor, {
            anchor: wordEndPoint,
            focus: wordEndPointAfter,
        })

        if (isAlphabeticOrNumericCharacter(wordEndAfter)) {
            wordEndPoint = Editor.after(editor, at, { unit: 'word' })
        }

        if (Tag.hasTag(editor, { at: wordEndPoint })) {
            const [[, tagPath]] = Editor.nodes(editor, { at: wordEndPoint, match: Tag.isTag })
            wordEndPoint = Tag.getEndPoint(editor, tagPath) || wordEndPoint
        }

        if (Suggestion.hasSuggestion(editor, { at: wordEndPoint })) {
            const [[, sugPath]] = Editor.nodes(editor, {
                at: wordEndPoint,
                match: Suggestion.isSuggestion,
            })
            wordEndPoint = Suggestion.getEndPoint(editor, sugPath) || wordEndPoint
        }

        if (wordStartPoint && wordEndPoint) {
            const blockEntries = Array.from(
                Editor.nodes(editor, {
                    at: { anchor: wordStartPoint, focus: wordEndPoint },
                    match: (node) => Block.isBlock(node) && node.editable,
                }),
            )

            // if we selected more than one block, check if there are empty leading/trailing blocks that can be trimmed from the selection
            if (blockEntries.length > 1) {
                let firstBlockIndex = 0
                let lastBlockIndex = blockEntries.length - 1

                // trim empty leading blocks from the selection
                while (true) {
                    const [, firstBlockPath] = blockEntries[firstBlockIndex]
                    const firstBlockString = Editor.string(editor, {
                        anchor: wordStartPoint,
                        focus: Editor.end(editor, firstBlockPath),
                    }).trim()

                    if (firstBlockString || firstBlockIndex === lastBlockIndex) {
                        break
                    }

                    firstBlockIndex++
                    const [, nextFirstBlockPath] = blockEntries[firstBlockIndex]
                    wordStartPoint = Editor.start(editor, nextFirstBlockPath)
                }

                // trim empty trailing blocks from the selection
                while (true) {
                    const [, lastBlockPath] = blockEntries[lastBlockIndex]
                    const lastBlockString = Editor.string(editor, {
                        anchor: Editor.start(editor, lastBlockPath),
                        focus: wordEndPoint,
                    }).trim()

                    if (lastBlockString || firstBlockIndex === lastBlockIndex) {
                        break
                    }

                    lastBlockIndex--
                    const [, nextLastBlockPath] = blockEntries[lastBlockIndex]
                    wordEndPoint = Editor.end(editor, nextLastBlockPath)
                }
            }
        }

        // get a shrinked range from both sides to contain only the word if first/ last characters are punctuations
        if (!wordStartPoint || !wordEndPoint) return

        let firstCharacterEndPoint = Editor.after(editor, wordStartPoint)
        let lastCharacterStartPoint = Editor.before(editor, wordEndPoint)
        let firstCharacter =
            firstCharacterEndPoint &&
            Editor.string(editor, {
                anchor: wordStartPoint,
                focus: firstCharacterEndPoint,
            })
        let lastCharacter =
            lastCharacterStartPoint &&
            Editor.string(editor, {
                anchor: lastCharacterStartPoint,
                focus: wordEndPoint,
            })
        let isCollpasedRange = Range.isCollapsed({ anchor: wordStartPoint, focus: wordEndPoint })

        while (
            firstCharacter &&
            !isAlphabeticOrNumericCharacter(firstCharacter) &&
            !isCollpasedRange
        ) {
            wordStartPoint = Editor.after(editor, wordStartPoint)
            if (!wordStartPoint) return

            firstCharacterEndPoint = Editor.after(editor, wordStartPoint)
            firstCharacter =
                firstCharacterEndPoint &&
                Editor.string(editor, {
                    anchor: wordStartPoint,
                    focus: firstCharacterEndPoint,
                })
            isCollpasedRange = Range.isCollapsed({ anchor: wordStartPoint, focus: wordEndPoint })
        }

        while (
            lastCharacter &&
            !isAlphabeticOrNumericCharacter(lastCharacter) &&
            !isCollpasedRange
        ) {
            wordEndPoint = Editor.before(editor, wordEndPoint)
            if (!wordEndPoint) return

            lastCharacterStartPoint = Editor.before(editor, wordEndPoint)
            lastCharacter =
                lastCharacterStartPoint &&
                Editor.string(editor, {
                    anchor: lastCharacterStartPoint,
                    focus: wordEndPoint,
                })
            isCollpasedRange = Range.isCollapsed({ anchor: wordStartPoint, focus: wordEndPoint })
        }

        return { anchor: wordStartPoint, focus: wordEndPoint }
    },

    isSelectionAtTheBeginningOfBlock: (editor: Editor): boolean => {
        if (!editor.selection) {
            return false
        }
        const aboveNode = Editor.above(editor, { at: editor.selection.anchor })
        const firstNode = Editor.first(editor, aboveNode![1])

        return (
            Range.isCollapsed(editor.selection) &&
            Path.equals(editor.selection.anchor.path, firstNode[1]) &&
            editor.selection.anchor.offset === 0
        )
    },

    isSelectionAtTheEndOfBlock: (editor: Editor): boolean => {
        if (!editor.selection) {
            return false
        }
        const aboveNode = Editor.above(editor, { at: editor.selection.anchor })
        const lastNode = Editor.last(editor, aboveNode![1])

        return (
            Range.isCollapsed(editor.selection) &&
            Path.equals(editor.selection.anchor.path, lastNode[1]) &&
            editor.selection.anchor.offset === (lastNode?.[0] as ContentText)?.text.length
        )
    },

    getPointForOffsetInBlock(editor: Editor, path: Path, offset: number): Point {
        let remainingOffset = offset
        for (const [node, p] of Editor.nodes(editor, { at: path, match: Text.isText })) {
            const length = node.text.length

            if (remainingOffset > length) {
                remainingOffset -= length
                continue
            }

            return { path: p, offset: remainingOffset }
        }

        return Editor.end(editor, path)
    },

    /**
     * Find a point in the document by a given time.
     */
    findPointByTime: (editor: Editor, time: number, options: FindPointByTimeOptions): Point => {
        const { affinity } = options

        const blockIndex =
            editor.timeline.getBlockIndexForTime(time, affinity) ?? editor.children.length - 1

        const content = Editor.string(editor, [blockIndex])
        const words = splitToWords(content)
        const timings = editor.timeline.getTimingsForBlock(blockIndex)!

        let contentOffset = 0

        // Only "real" words have timings. But in the document, we have whitespaces in between them and they are not represented
        // inside the words array. So we iterate the words array to find the correct timing and simultaneously iterate over the "raw" content string
        // to keep track of the character offset in the block as we go.

        // increase the contentOffset to skip whitespaces
        const increaseOffsetToSkipWhitespace = () => {
            while (contentOffset < content.length && /\s+/.test(content[contentOffset])) {
                contentOffset++
            }
        }

        // increase the contentOffset until we find the word given
        const increaseOffsetToWord = (word: string) => {
            let buffer = ''
            do {
                const char = content[contentOffset]

                // whitespaces clear the buffer
                if (/\s+/.test(char)) {
                    buffer = ''
                    contentOffset++
                    continue
                }

                buffer += char
                contentOffset++

                if (buffer === word) {
                    break
                }
            } while (contentOffset < content.length)
        }

        for (let i = 0; i < words.length; i++) {
            const wordTiming = timings[i]
            const nextWordTiming: TimingRange | undefined = timings[i + 1]
            const word = words[i]

            switch (affinity) {
                case 'start': {
                    increaseOffsetToSkipWhitespace()
                    if (time < wordTiming.end) {
                        return Block.getPointForOffsetInBlock(editor, [blockIndex], contentOffset)
                    }
                    increaseOffsetToWord(word)
                    break
                }

                case 'end': {
                    increaseOffsetToWord(word)
                    if (
                        time <= wordTiming.end &&
                        (!nextWordTiming || time <= nextWordTiming.start)
                    ) {
                        return Block.getPointForOffsetInBlock(editor, [blockIndex], contentOffset)
                    }
                    break
                }
            }
        }

        return Block.getPointForOffsetInBlock(editor, [blockIndex], contentOffset)
    },
}

interface CreateBlockProps {
    editable: boolean
    isFirstSegment: boolean
    prevLegalAnnotation?: string
    shouldAutoPopulationOverwrite?: boolean
    initialSpeakersByLegalAnnotation?: SpeakersByLegalAnnotationMap
}

export function createBlocks(
    segment: Segment,
    {
        editable,
        isFirstSegment,
        prevLegalAnnotation,
        shouldAutoPopulationOverwrite,
        initialSpeakersByLegalAnnotation,
    }: CreateBlockProps,
) {
    let paragraphs: Word[][]

    // An empty editable segment should still create an empty block
    if (segment.editable && segment.words.length === 0) {
        paragraphs = [[]]
    } else {
        paragraphs = []

        for (let i = 0; i < segment.words.length; i++) {
            const currWord = segment.words[i]
            const prevWord = i > 0 ? segment.words[i - 1] : undefined

            // We split to a new block on:
            // 1. The first word in the segment, regardless of whether it has an explicit break
            // 2. The current word is a section
            // 3. The current word has an explicit break unless the previous word is not a section
            if (
                i === 0 ||
                currWord.type === 'section' ||
                (prevWord?.type !== 'section' && currWord.break)
            ) {
                paragraphs.push([])
            }

            paragraphs[paragraphs.length - 1].push(currWord)
        }
    }

    if (initialSpeakersByLegalAnnotation) {
        legalAnnotationsStore.setSpeakersByLegalAnnotationMapping(initialSpeakersByLegalAnnotation)
    }

    const blocks: Block[] = []
    const blockTimings = TimedTextSegment.create({
        timing: {
            start: segment.timing.start,
            end: segment.timing.end,
        },
    })

    for (const [i, p] of paragraphs.entries()) {
        // check if the first word in the paragraph is actually a section and remove it from the array
        let section: string | undefined = undefined
        let timecode: string | undefined = p[0]?.timecode
        let start: number | undefined = p[0]?.timing?.start
        let end: number | undefined = p[0]?.timing?.end
        if (p[0]?.type === 'section') {
            section = p[0].section?.type
            p.shift()
        }

        let legal: LegalMetadata | undefined = prevLegalAnnotation
            ? {
                  ...p[0]?.legal,
                  annotation: getAlternatingExaminationLegalAnnotation(prevLegalAnnotation),
              }
            : p[0]?.legal
        let speakerId = legal?.annotation
            ? legalAnnotationsStore.speakersByLegalAnnotation[legal.annotation]
            : null

        const legalAnnotationConflict = Block.isLegalAnnotationConflict(
            p[0]?.legal?.annotation,
            legal?.annotation,
            shouldAutoPopulationOverwrite,
        )
        const speakerConflict = Block.isSpeakerConflict(
            p[0]?.speakerId,
            speakerId,
            shouldAutoPopulationOverwrite,
        )

        // when reaching an uneditable block, a new section, a legalAnnotationConflict or a speakerConflict, simply set the paragraph values
        if (!segment.editable || p[0]?.section || legalAnnotationConflict || speakerConflict) {
            legal = p[0]?.legal
            speakerId = p[0]?.speakerId
        }

        prevLegalAnnotation = legal?.annotation

        // We consider the following blocks as having explicit breaks (for the purpose of showing the SpeakerLine component):
        // 1. The first word in the block is marked with a break
        // 2. It's the first block in the segment and:
        //    1. It's part of the first segment in the task, which means 'before' or 'editable' if there are no words in the before segment
        //    2. It's the 'after' segment. Even though it's incorrect that the after segment actually has a break on its first word, we want to avoid
        //       complicating the user by showing him a state (joined speaker line between last editable block and first 'after' block) that they cannot do anything about.
        const hasExplicitBreak =
            !!p[0]?.break || (i === 0 && (isFirstSegment || segment.type === 'after'))

        const [content, timings] = createContent(p, { editable })

        const words = splitToWords(Node.string(content))
        TimedTextSegment.addBlock(blockTimings, {
            editable,
            hasExplicitBreak,
            words,
            timings,
            timing: getRangeFromTimings(timings),
        })

        blocks.push({
            type: 'block',
            segmentType: segment.type,
            speakerId,
            pendingLegalAnnotation: null,
            section,
            legal,
            editable,
            hasExplicitBreak,
            children: [content],
            bitcTimeCode: timecode,
            mediaTimeCode: formatTimecode('00:00:00:00', convertTimingToDateTime(start ?? 0), {
                includeFps: false,
            }),
            start,
            end,
        })
    }

    blockTimings.timing.start = segment.timing.start
    blockTimings.timing.end = segment.timing.end

    return [blocks, blockTimings] as const
}

export enum ExaminationLegalAnnotation {
    Q = 'q',
    A = 'a',
}

export const isExaminationLegalAnnotation = (
    legalAnnotation: string,
): legalAnnotation is ExaminationLegalAnnotation =>
    legalAnnotation === ExaminationLegalAnnotation.Q ||
    legalAnnotation === ExaminationLegalAnnotation.A

const getAlternatingExaminationLegalAnnotation = (legalAnnotation: string) => {
    if (!isExaminationLegalAnnotation(legalAnnotation)) {
        return
    }

    return legalAnnotation === ExaminationLegalAnnotation.Q
        ? ExaminationLegalAnnotation.A
        : ExaminationLegalAnnotation.Q
}

export function assertIsBlock(val: any, message?: string): asserts val is Block {
    if (!Block.isBlock(val)) {
        throw new AssertionError({
            message: message ?? `Expected 'val' to be a Block, but received ${val}`,
        })
    }
}
