import { WorkerId, TranscriptionLayerId } from '.'
import { Attachment } from './attachment'
import { WorkBreakError } from 'src/network/responses'

export enum SessionPlaybackState {
    PLAYING = 'playing',
    PAUSED = 'paused',
    STOPPED = 'stopped',
}

export enum SessionStatusState {
    READY = 'ready',
    ACTIVE = 'active',
    FINISHED = 'finished',
}

export interface SessionStatus {
    worker: {
        id: WorkerId
        name: string
        layerIds: TranscriptionLayerId[]
    }
    active: boolean
    startedAt: Date
    tagsModifiedAt: Date
    isTraining: boolean
    speakersModifiedAt: Date
    workersModifiedAt: Date
    playbackState: SessionPlaybackState
    state: SessionStatusState
    audioUrl: string
    mediaType: string
    attachments: Attachment[] | null
    guidelines: string | null
    sessionId: string
    sessionName: string
    orderId: string
    audioStartedAt: string
    transcribersHubUrl: string
    customerId: string | undefined
    platformJobId: number | undefined
    vertical: string | null
    workBreakStatus: {
        positionInQueue: number | null
        isAllowed: boolean
        notAllowedReason?: WorkBreakError
        availableTime: number
        workBreakRatio: number
        minTimeForWorkBreak: number
    }
}

export interface SessionStats {
    workTime: number
    transcribedAudio: number
    segmentsCompleted: number
    earned: number | null
    state: string
    userMessage: string
    userMessageTitle: string
    redirectCountdownMs?: number
    isTrainingSession: boolean
}

export interface SessionHealth {
    status: string
    redirectUrl: string
}
