import { useEffect, useCallback, useRef, useState, useMemo } from 'react'
import { Transforms, Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import {
    TimeCodeContainer,
    TimeCode,
    TimeCodeGrey,
    TimeCodeInputMask,
    RemoveButton,
} from './BurntInTimeCodeStyled'
import { Block } from '../Block'
import { EditorControls } from 'src/models'
import useEditorTimeCode from 'src/components/Editor/plugins/withTranscript/hooks/useEditorTimeCode'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import Timecode from 'smpte-timecode'
import { Intent } from '@blueprintjs/core'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { theme } from 'src/components/styled'
import { MdErrorOutline } from 'react-icons/md'
import { useToast } from 'src/components/Toasts/ToastContext'

type FRAMERATE = 24 | 25 | 29.97 | 30 | 59.94 | 60

interface BurntInTimeCodeProps {
    element: Block
    editor: any
    controls: EditorControls
    isSectionTimeCodeRemoved: boolean
    setIsSectionTimeCodeRemoved: (isSectionTimeCodeRemoved: boolean) => void
}

export const BurntInTimeCode = ({
    element,
    editor,
    controls,
    isSectionTimeCodeRemoved,
    setIsSectionTimeCodeRemoved,
}: BurntInTimeCodeProps) => {
    const [{ context }] = useAppMachine(['metadata'])
    const { metadata } = context
    const ref = useRef<HTMLInputElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const MAX_INPUT_MASK_LENGTH = 11
    const [paragraphIndex] = ReactEditor.findPath(editor, element)
    const {
        handleTimeCodeFocus,
        convertTimingToDateTime,
        initTimeCodes,
        recalculateTimeCodes,
        handleDeleteTimeCode,
    } = useEditorTimeCode(undefined)
    const timeCodeVisible = controls?.timecode?.visible
    const isSectionCalibrationAnchor = element?.section === 'calibration_anchor'
    const [currBITCTimecode, setCurrBITCTimecode] = useState<string | undefined>(undefined)
    const [isValidCurrBITCTimecode, setIsValidCurrBITCTimecode] = useState<boolean>(true)
    const addToast = useToast()
    const [currBlockEntries, setCurrBlockEntries] = useState<number>(0)
    const blockEntries = useMemo(() => {
        return Array.from(
            Editor.nodes(editor, {
                at: [],
                match: (node) => Block.isBlock(node) && node.editable,
            }),
        )
    }, [editor])

    const recalculate = useCallback(() => {
        setCurrBlockEntries(blockEntries?.length)
        if (currBlockEntries < blockEntries?.length) recalculateTimeCodes()
    }, [blockEntries?.length, currBlockEntries, recalculateTimeCodes])

    useEffect(() => {
        recalculate()
    }, [recalculate])

    useEffect(() => {
        if (
            timeCodeVisible &&
            element?.bitcTimeCode !== '' &&
            element?.bitcTimeCode !== undefined &&
            element?.bitcTimeCode !== null &&
            element?.section === 'calibration_anchor'
        ) {
            initTimeCodes()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isSectionTimeCodeRemoved) handleSectionRemoved()
        // eslint-disable-next-line
    }, [isSectionTimeCodeRemoved])

    const handleSectionRemoved = () => {
        let latestBitcTimeCode, latestSectionMediaStartTime, newStartTime
        const path = ReactEditor.findPath(editor, element)
        Block.setBITCTimeCode(editor, path, undefined)
        Block.setMediaTimeCode(editor, path, undefined)
        Block.setSectionMediaTime(editor, path, undefined)

        for (const [block, blockPath] of Editor.nodes<Block>(editor, {
            at: [],
            match: (n) => Block.isBlock(n) && n.editable,
        })) {
            const [blockIndex] = blockPath
            const blockStartTime = editor.timeline.getBlockStartTime(blockIndex)

            // check if block has BITC
            if (!!block.bitcTimeCode) {
                latestBitcTimeCode = block.bitcTimeCode
                latestSectionMediaStartTime = block.sectionMediaTime
            }

            // always instantiate a new timecode object in every iteration
            let smtpe
            if (metadata !== undefined && 'fps' in metadata) {
                try {
                    smtpe = new Timecode(latestBitcTimeCode, metadata.fps as FRAMERATE | undefined)
                } catch (error) {}
            } else {
                try {
                    smtpe = new Timecode(latestBitcTimeCode)
                } catch (error) {}
            }

            if (smtpe === undefined) break
            if (latestSectionMediaStartTime !== undefined && latestSectionMediaStartTime !== null) {
                newStartTime = convertTimingToDateTime(blockStartTime - latestSectionMediaStartTime)
            } else {
                newStartTime = convertTimingToDateTime(
                    latestSectionMediaStartTime === undefined ? 0 : blockStartTime,
                )
            }

            if (smtpe !== undefined) {
                smtpe.add(newStartTime)
                let newHours = `0${smtpe.hours.toString()}`.slice(-2)
                let newMinutes = `0${smtpe.minutes.toString()}`.slice(-2)
                let newSeconds = `0${smtpe.seconds.toString()}`.slice(-2)
                let newFrames = `0${smtpe.frames.toString()}`.slice(-2)
                let newTime = newHours + ':' + newMinutes + ':' + newSeconds + ':' + newFrames
                Block.setMediaTimeCode(editor, blockPath, newTime)
                setCurrBITCTimecode(undefined)
                setIsValidCurrBITCTimecode(true)
            }
        }
        setIsSectionTimeCodeRemoved(false)
    }

    const handleResetTimeCode = useCallback(async () => {
        const path = ReactEditor.findPath(editor, element)
        const [[, currBlockPath]] = Editor.nodes(editor, { at: path, match: Block.isBlock })
        const [blockIndex] = currBlockPath
        const latestBITCIndexPosition = blockIndex
        Block.setBITCTimeCode(editor, path, '')
        Block.setMediaTimeCode(editor, path, '')
        Block.setSectionMediaTime(editor, path, undefined)

        for (const [block, blockPath] of Editor.nodes<Block>(editor, {
            at: { anchor: Editor.start(editor, path), focus: Editor.end(editor, []) },
            match: (n) => Block.isBlock(n) && n.editable,
        })) {
            const [blockIndex] = blockPath
            if (!!block.bitcTimeCode) break
            if (blockIndex >= latestBITCIndexPosition) {
                Block.setMediaTimeCode(editor, blockPath, '')
            }
        }
    }, [editor, element])

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'F1') {
                if (!timeCodeVisible) return
                handleTimeCodeFocus()
                const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
                const [positionIndex] = blockPath
                const element = document.getElementById(
                    `bitc-section-input${positionIndex}`,
                ) as HTMLInputElement
                if (element) {
                    if (document.activeElement instanceof HTMLElement) {
                        document.activeElement.blur()
                        element?.focus()
                    }
                }
            }
        },
        [editor, timeCodeVisible, handleTimeCodeFocus],
    )

    const handleToastMessage = useCallback(
        (message: string) => {
            addToast({
                intent: Intent.NONE,
                icon: (
                    <MdErrorOutline
                        size={16}
                        color="#c23030"
                        style={{ margin: 12, marginRight: 0, marginTop: 18 }}
                    />
                ),
                message: <span>{message}</span>,
            })
        },
        [addToast],
    )

    const handleInputBlur = useCallback((): void => {
        if (!isValidCurrBITCTimecode || currBITCTimecode === '00:00:00:00') {
            setCurrBITCTimecode(undefined)
            setIsValidCurrBITCTimecode(true)
            handleDeleteTimeCode()
        }
    }, [currBITCTimecode, isValidCurrBITCTimecode, handleDeleteTimeCode])

    const handleTimeCodeChange = useCallback(
        (value?: string | undefined) => {
            if (value === '' || value === undefined || value.length < MAX_INPUT_MASK_LENGTH) {
                return
            }
            if (value.length === MAX_INPUT_MASK_LENGTH) {
                handleResetTimeCode().then(() => {
                    const path = ReactEditor.findPath(editor, element)
                    const pattern = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d:\d{2}$/
                    if (pattern.test(value)) {
                        setIsValidCurrBITCTimecode(true)
                        const [[, currBlockPath]] = Editor.nodes(editor, {
                            at: path,
                            match: Block.isBlock,
                        })
                        const [blockIndex] = currBlockPath
                        const latestBITCIndexPosition = blockIndex
                        const latestSectionMediaStartTime =
                            editor.timeline.getBlockStartTime(blockIndex)

                        for (const [block, blockPath] of Editor.nodes<Block>(editor, {
                            at: {
                                anchor: Editor.start(editor, path),
                                focus: Editor.end(editor, []),
                            },
                            match: (n) => Block.isBlock(n) && n.editable,
                        })) {
                            // always instantiate a new timecode object in every iteration
                            let smtpe
                            if (metadata !== undefined && 'fps' in metadata) {
                                try {
                                    smtpe = new Timecode(
                                        value,
                                        metadata.fps as FRAMERATE | undefined,
                                    )
                                } catch (error) {
                                    handleToastMessage('Invalid timecode frame format.')
                                    setIsValidCurrBITCTimecode(false)
                                }
                            } else {
                                try {
                                    smtpe = new Timecode(value)
                                } catch (error) {
                                    handleToastMessage('Invalid timecode frame format.')
                                    setIsValidCurrBITCTimecode(false)
                                }
                            }

                            if (smtpe === undefined) break
                            const [blockIndex] = blockPath
                            if (!!block.bitcTimeCode) break
                            const blockStartTime = editor.timeline.getBlockStartTime(blockIndex)
                            let newStartTime = convertTimingToDateTime(
                                blockStartTime - latestSectionMediaStartTime,
                            )

                            if (smtpe !== undefined) {
                                smtpe.add(newStartTime)
                                let newHours = `0${smtpe.hours.toString()}`.slice(-2)
                                let newMinutes = `0${smtpe.minutes.toString()}`.slice(-2)
                                let newSeconds = `0${smtpe.seconds.toString()}`.slice(-2)
                                let newFrames = `0${smtpe.frames.toString()}`.slice(-2)
                                let newTime =
                                    newHours + ':' + newMinutes + ':' + newSeconds + ':' + newFrames

                                Block.setMediaTimeCode(editor, blockPath, newTime)
                                if (blockIndex === latestBITCIndexPosition) {
                                    Block.setBITCTimeCode(editor, path, value)
                                    Block.setSectionMediaTime(
                                        editor,
                                        path,
                                        latestSectionMediaStartTime,
                                    )
                                }
                            }
                            setIsValidCurrBITCTimecode(true)
                        }
                    } else {
                        setIsValidCurrBITCTimecode(false)
                    }
                })
            }
        },
        [
            editor,
            element,
            metadata,
            convertTimingToDateTime,
            handleToastMessage,
            handleResetTimeCode,
        ],
    )

    const handleInputMaskFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        e.preventDefault()
        handleTimeCodeFocus()
        // eslint-disable-next-line
    }, [])

    const handleKeyDownTimeCode = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!timeCodeVisible) return
            if (e.key !== 'Tab') return
            const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
            Transforms.select(editor, {
                anchor: Editor.start(editor, blockPath),
                focus: Editor.end(editor, blockPath),
            })
            Transforms.move(editor, { unit: 'character', distance: 3 })
            Transforms.select(editor, Editor.start(editor, blockPath))
            ReactEditor.focus(editor)
            handleInputBlur()
        },
        [editor, timeCodeVisible, handleInputBlur],
    )

    const handleTimeCodeKeyEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!timeCodeVisible) return
        if (e.key !== 'Enter') return
        const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
        Transforms.select(editor, Editor.start(editor, blockPath))
        ReactEditor.focus(editor)
        handleInputBlur()
    }

    return (
        <TimeCodeContainer contentEditable={false}>
            <Tooltip
                label="Invalid Format!"
                placement="left-end"
                hasArrow
                bg={theme?.palette?.red?.[3]}
                sx={{ top: '-15px !important', right: '-150px !important', zIndex: '1 !important' }}
                isOpen={isSectionCalibrationAnchor && !isValidCurrBITCTimecode}
            >
                <TimeCode>
                    {element?.bitcTimeCode !== '' &&
                        element?.bitcTimeCode !== undefined &&
                        element?.bitcTimeCode !== null &&
                        isSectionCalibrationAnchor && (
                            <RemoveButton
                                icon="small-cross"
                                minimal
                                onClick={() => handleResetTimeCode()}
                                tabIndex={-1}
                            />
                        )}

                    {isSectionCalibrationAnchor && (
                        <TimeCodeInputMask
                            key={paragraphIndex}
                            id={'bitc-section-input' + paragraphIndex}
                            name={'bitc-section-input' + paragraphIndex}
                            mask={'00[:]00[:]00[:]00'}
                            value={currBITCTimecode ?? element?.bitcTimeCode}
                            unmask={false}
                            ref={ref}
                            inputRef={inputRef}
                            lazy={false}
                            placeholderChar="0"
                            onAccept={(value: string) => {
                                setCurrBITCTimecode(value)
                                handleTimeCodeChange(value)
                            }}
                            onBlur={handleInputBlur}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                handleKeyDownTimeCode(e)
                            }}
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                handleTimeCodeKeyEnter(e)
                            }}
                            onFocus={handleInputMaskFocus}
                            autoComplete="off"
                            tabIndex={1}
                        />
                    )}

                    <TimeCodeGrey
                        id="bitc-grey-value1"
                        name="bitc-grey-value1"
                        type="text"
                        value={element?.mediaTimeCode || ''}
                        placeholder="00:00:00:00"
                        readOnly
                        isTimeCodeVisible={timeCodeVisible}
                        isSectionCalibrationAnchor={isSectionCalibrationAnchor}
                        tabIndex={-1}
                    />
                </TimeCode>
            </Tooltip>
        </TimeCodeContainer>
    )
}
