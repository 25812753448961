import React, { useRef, useMemo, CSSProperties } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useTheme } from 'styled-components/macro'

import * as Icons from 'src/components/icons'

import * as Styled from './styled'
import { Submenu } from './GlossarySubmenu'
import { Row } from './Row'
import { GlossaryItem } from './GlossaryItem'
import { useGlossaryList, UseGlossaryListProps } from './useGlossaryList'

interface GlossaryListProps extends UseGlossaryListProps {
    hideSearchbar?: boolean
    maxHeight: number
    subMenuMaxHeight: number
}

export const TEMPORARY_GLOSSARY_ID = 'temporary'

// TODO: move next to GlossaryPopup
export const GlossaryList = ({
    hideSearchbar = false,
    initialSearchTerm,
    maxHeight,
    subMenuMaxHeight,
    onClose,
    onTermSelected,
    submenuSide,
}: GlossaryListProps) => {
    const {
        searchTerm,
        filteredItems,
        addItemOptions,

        activeItemIdx,
        isAddItemActive,
        expandedItemIdx,
        isAddItemExpanded,
        displayAddItem,

        itemRefs,
        searchInputRef,

        handleSearchInputChange,
        handleTermEditRequest,
        handleTermDeleteRequest,
        handleItemMouseOver,
        handleItemClick,
        handleAddItemMouseOver,
    } = useGlossaryList({
        source: 'Context Menu',
        initialSearchTerm,
        onClose,
        onTermSelected,
        withExpandableItems: true,
        submenuSide,
        withShortcuts: true,
    })

    const containerRef = useRef<HTMLDivElement>(null)
    const addButtonRef = useRef<HTMLElement | null>(null)
    const theme = useTheme()

    const submenuPosition = useMemo(() => {
        // XXX: Bar: Stupid temporary hack to make eslint happy about `items` being in deps
        filteredItems.length.toString()

        if (expandedItemIdx === -1 && !isAddItemActive) {
            return {
                left: -1000,
                top: -1000,
            }
        }

        let itemRect
        if (isAddItemActive) {
            itemRect = addButtonRef.current?.getBoundingClientRect()
        } else {
            itemRect = itemRefs.current?.[expandedItemIdx]?.getBoundingClientRect()
        }

        const containerRect = containerRef.current?.getBoundingClientRect()
        if (itemRect && containerRect) {
            let pos: Pick<CSSProperties, 'top' | 'left' | 'right'> = {
                // subtract 1px from top position to compensate for the border of the list so the items are aligned
                top: itemRect.top - containerRect.top - 1,
            }

            // add 2px to left position to create a minor space between the menu and the submenu
            const space = 2

            if (submenuSide === 'right') {
                pos.left = itemRect.right - containerRect.left + space
            } else {
                pos.right = `calc(100% + ${space}px)`
            }

            return pos
        } else {
            return { left: -1000, top: -1000 }
        }
    }, [isAddItemActive, expandedItemIdx, filteredItems, submenuSide, itemRefs])

    return (
        <Styled.Container
            ref={containerRef}
            style={{ maxHeight: maxHeight - (subMenuMaxHeight - Styled.ITEM_ROW_MIN_HEIGHT) }}
        >
            {!hideSearchbar && (
                <Styled.SearchBar>
                    <Styled.SearchInput
                        type="text"
                        placeholder="Search Term...."
                        spellCheck={false}
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                        ref={searchInputRef}
                        // apparently, windows needs a onFocus event to actually maintain the focus
                        onFocus={() => {}}
                    />
                    <Icons.SearchIcon color={theme.palette.cloudBlue[2]} />
                </Styled.SearchBar>
            )}
            <Styled.ListScroller>
                {filteredItems.length > 0 ? (
                    <Styled.List>
                        {filteredItems.map((term, i) => (
                            <GlossaryItem
                                innerRef={(el) => (itemRefs.current[i] = el)}
                                key={term.id}
                                term={term}
                                index={i}
                                active={i === activeItemIdx}
                                expanded={i === expandedItemIdx}
                                submenuProps={{
                                    ...submenuPosition,
                                    minWidth: 180,
                                    maxHeight: subMenuMaxHeight,
                                }}
                                onMouseOver={handleItemMouseOver}
                                onClick={handleItemClick}
                                onEditSelected={handleTermEditRequest}
                                onDeleteSelected={handleTermDeleteRequest}
                            />
                        ))}
                    </Styled.List>
                ) : (
                    <Styled.ItemRow active={false} disabled>
                        <Styled.ItemText>No matches</Styled.ItemText>
                    </Styled.ItemRow>
                )}
            </Styled.ListScroller>

            {displayAddItem && (
                <>
                    <Styled.Divider />
                    <Row
                        text={
                            <span>
                                Add:
                                <i>
                                    {searchTerm.length > 30
                                        ? ` ${searchTerm.slice(0, 30)}...`
                                        : ` ${searchTerm}`}
                                </i>
                            </span>
                        }
                        innerRef={(el) => (addButtonRef.current = el)}
                        icon={<FiPlus />}
                        withChevron={true}
                        showChevron={true}
                        active={isAddItemActive}
                        expanded={isAddItemExpanded && expandedItemIdx === -1}
                        onMouseOver={handleAddItemMouseOver}
                        subMenu={
                            <Submenu
                                submenuProps={{
                                    ...submenuPosition,
                                    minWidth: 240,
                                    maxHeight: subMenuMaxHeight,
                                }}
                                title="Add to Glossary as:"
                                items={addItemOptions}
                            />
                        }
                    />
                </>
            )}
        </Styled.Container>
    )
}
