import { MouseEventHandler } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Spinner } from '@blueprintjs/core'
import { VolumeIcon, PauseIcon, AudioIcon } from '@verbit-ai/icons-library'

const VoiceSampleLoader = styled(Spinner)`
    .bp4-spinner-animation,
    svg {
        width: 100%;
        height: 100%;
    }

    .bp4-spinner-head {
        stroke: ${palette('turquoise', 1)} !important;
    }
`

interface VoiceSampleButtonProps {
    isPlaying: boolean
    isBuffering: boolean
    isDisabled?: boolean
    onClick?: MouseEventHandler
    className?: string
}

export const VoiceSampleButton = ({
    isPlaying,
    isBuffering,
    onClick,
    isDisabled,
    className,
}: VoiceSampleButtonProps) => {
    if (isBuffering) {
        return <VoiceSampleLoader className={className} />
    }

    if (isPlaying) {
        return <PauseIcon onClick={onClick} className={className} />
    }

    if (isDisabled) {
        return <VolumeIcon className={className} />
    }

    return <AudioIcon onClick={onClick} className={className} />
}
