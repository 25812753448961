import { Editor, RangeRef } from 'slate'

type WithContextMenuOptions = {
    popupRangeRef: React.MutableRefObject<RangeRef | null>
}

export function withContextMenu({ popupRangeRef }: WithContextMenuOptions) {
    return (editor: Editor) => {
        const { decorate } = editor

        editor.decorate = (entry) => {
            const [node] = entry
            const ranges = decorate(entry)

            if (Editor.isEditor(node) && popupRangeRef.current?.current) {
                ranges.push({ ...popupRangeRef.current.current, selection: true })
            }

            return ranges
        }

        return editor
    }
}
