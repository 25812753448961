import { Transforms, Editor } from 'slate'
import { useSlateStatic } from 'slate-react'
import { Block } from '../Block'
import { consoleClear } from 'src/utils/platform'
import { getTimeByPoint, pointFromRange } from '../../withTimeline/hooks/useEditorAudioSync'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { EditorControls } from 'src/models'
import { useSessionMedia } from 'src/state/SessionMediaProvider'
import { formatTimecode } from 'src/utils/timecode'

type FRAMERATE = 24 | 25 | 29.97 | 30 | 59.94 | 60

export default function useEditorTimeCode(controls: EditorControls | undefined) {
    const [{ context }] = useAppMachine(['metadata'])
    const { metadata } = context
    const { pause, seekTime } = useSessionMedia(['pause', 'seekTime'])
    const editor = useSlateStatic()

    const handleTimeCodeShortcut = () => {
        const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
        Transforms.select(editor, Editor.start(editor, blockPath))
        consoleClear()
        if (!controls?.timecode?.visible) return
        setTimeout(() => {
            pause()
            syncTaskAudioWithCaretPosition()
        }, 500)
        Transforms.move(editor, { unit: 'offset', reverse: true, distance: 0, edge: 'anchor' })
        Block.setSection(editor, blockPath, 'calibration_anchor')
        Block.setBITCTimeCode(editor, blockPath, '00:00:00:00')
        Block.setSectionMediaTime(editor, blockPath, 0.0)
        Block.setMediaTimeCode(editor, blockPath, '')
    }

    const handleDeleteTimeCode = () => {
        consoleClear()
        const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
        Block.setSection(editor, blockPath, undefined)
        Block.setBITCTimeCode(editor, blockPath, undefined)
        Block.setSectionMediaTime(editor, blockPath, undefined)
        Block.setMediaTimeCode(editor, blockPath, undefined)
    }

    const handleTimeCodeFocus = () => {
        if (!controls?.timecode?.visible) return
        pause()
    }

    const syncTaskAudioWithCaretPosition = (range = editor.selection) => {
        const caretPosition = pointFromRange(editor, range)

        if (!caretPosition) {
            return
        }

        const [[block]] = Editor.nodes(editor, { at: caretPosition, match: Block.isBlock })

        if (!block.editable) {
            return
        }

        const time = getTimeByPoint(editor, caretPosition)

        if (time) {
            seekTime(time)
        }
    }

    const convertTimingToDateTime = (secs: number) => {
        var t = new Date(1970, 0, 1) // Epoch
        t.setMilliseconds(secs * 1000)
        return t
    }

    const initTimeCodes = () => {
        let newStartTime, latestBlockBitcTimeCode
        for (const [block, blockPath] of Editor.nodes<Block>(editor, {
            at: [],
            match: (n) => Block.isBlock(n) && n.editable,
        })) {
            const [blockIndex] = blockPath
            const blockStartTime = editor.timeline.getBlockStartTime(blockIndex)

            if (block.bitcTimeCode !== undefined && block.bitcTimeCode !== null) {
                latestBlockBitcTimeCode = block.bitcTimeCode
            }

            if (block?.start !== undefined && block?.start !== null) {
                newStartTime = convertTimingToDateTime(blockStartTime - block?.start)
            } else {
                newStartTime = convertTimingToDateTime(blockStartTime)
            }

            const newTimeCode = formatTimecode(latestBlockBitcTimeCode, newStartTime, {
                fps: metadata?.fps as FRAMERATE | undefined,
            })

            if (newTimeCode) {
                Block.setMediaTimeCode(editor, blockPath, newTimeCode)
            }
        }
    }

    const recalculateTimeCodes = () => {
        let latestBitcTimeCode, latestSectionMediaStartTime, newStartTime
        for (const [block, blockPath] of Editor.nodes<Block>(editor, {
            at: [],
            match: (n) => Block.isBlock(n) && n.editable,
        })) {
            const [blockIndex] = blockPath
            const blockStartTime = editor.timeline.getBlockStartTime(blockIndex)

            // check if block has BITC
            if (!!block.bitcTimeCode) {
                latestBitcTimeCode = block.bitcTimeCode
                latestSectionMediaStartTime = block.sectionMediaTime
            }

            if (latestSectionMediaStartTime) {
                newStartTime = convertTimingToDateTime(blockStartTime - latestSectionMediaStartTime)
            } else {
                newStartTime = convertTimingToDateTime(
                    latestSectionMediaStartTime === undefined ? 0 : blockStartTime,
                )
            }

            const newTimeCode = formatTimecode(latestBitcTimeCode, newStartTime, {
                fps: metadata?.fps as FRAMERATE | undefined,
            })

            if (newTimeCode) {
                Block.setMediaTimeCode(editor, blockPath, newTimeCode)
            }
        }
    }

    useMousetrap(['f1'], handleTimeCodeShortcut, { preventDefault: true })
    useMousetrap(['shift+f1'], handleDeleteTimeCode, { preventDefault: true })

    return {
        handleTimeCodeFocus,
        convertTimingToDateTime,
        initTimeCodes,
        recalculateTimeCodes,
        handleDeleteTimeCode,
    }
}
