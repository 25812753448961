import { ChatThemeProvider, ChannelChat, useChannelWatcher } from '@verbit-ai/chat-frontend'
import styled from 'styled-components'

import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { Spinner } from 'src/components/Session/Spinner'
import { useChat } from 'src/state/ChatProvider'

const SpinnerContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

const Loader = styled(Spinner)`
    animation-duration: 0ms;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
`

export const SessionChatPanel = () => {
    const { channels } = useChat()
    const sessionId = useSessionStatus(['sessionStatus.sessionId']).sessionStatus?.sessionId
    const sessionChannel = sessionId ? channels[sessionId] : null
    const { isWatching } = useChannelWatcher(sessionChannel)

    return (
        <ChatThemeProvider>
            {!sessionId || !isWatching || !sessionChannel ? (
                <SpinnerContainer>
                    <Loader />
                </SpinnerContainer>
            ) : (
                <ChannelChat channel={sessionChannel} />
            )}
        </ChatThemeProvider>
    )
}
