import { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components/macro'

import { WarningIcon } from 'src/components/icons'

import { getFileExtension } from './ViewerTabBar'
import { DownloadButton, DownloadIcon } from './ViewerTopBar'

const SUPPORTED_OFFICE_FILES: string[] = ['doc', 'docx', 'ppt', 'pptx']
const SUPPORTED_GENERIC_FILES: string[] = ['png', 'jpg', 'pdf', 'jpeg', 'txt']

const Iframe = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
`

const ErrorContainer = styled.div`
    position: relative;
    width: 50%;
    height: 35%;
    padding: 24px 30px 24px 80px;
    font-family: Open Sans, sans-serif;
`

const ErrorMessageIcon = styled(WarningIcon)`
    position: absolute;
    color: #ffaf00;
    width: 40px;
    top: 20px;
    left: 30px;
`

const ErrorMessageHeader = styled.h4`
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
`

const BoldedText = styled.p`
    font-weight: 600;
`

interface DocumentViewerProps {
    currentFileUrl: string
    onDownload?: (fileUrl: string) => void
}

export const DocumentViewer = ({ currentFileUrl, onDownload }: DocumentViewerProps) => {
    const [fileIsNotSupported, setFileIsNotSupported] = useState(false)

    const getFileType = useCallback(
        (url: string): string => {
            const extension = getFileExtension(currentFileUrl).toLowerCase()
            if (SUPPORTED_OFFICE_FILES.includes(extension)) {
                return 'office'
            }
            if (url.includes('docs.google.com')) {
                return 'generic'
            }
            return SUPPORTED_GENERIC_FILES.includes(extension) ? 'generic' : 'not-supported'
        },
        [currentFileUrl],
    )

    const viewerUrl = useMemo(() => {
        setFileIsNotSupported(false)
        const fileType = getFileType(currentFileUrl)
        if (fileType === 'not-supported') setFileIsNotSupported(true)
        return fileType === 'office'
            ? `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(
                  currentFileUrl,
              )}`
            : currentFileUrl
    }, [currentFileUrl, getFileType])

    const onClickDownload = useCallback(() => {
        onDownload?.(currentFileUrl)
    }, [currentFileUrl, onDownload])

    return fileIsNotSupported ? (
        <ErrorContainer>
            <ErrorMessageIcon />
            <ErrorMessageHeader>File cannot be previewed</ErrorMessageHeader>
            <p>This viewer does not support all file types (e.g. TIFF and more).</p>
            <BoldedText>
                Please download this file to view it, then add relevant terms to the Glossary.
            </BoldedText>
            <DownloadButton
                downloadable={!!currentFileUrl}
                target="_blank"
                download
                href={currentFileUrl}
                onClick={onClickDownload}
            >
                <DownloadIcon />
                Download
            </DownloadButton>
        </ErrorContainer>
    ) : (
        <Iframe id="viewer" frameBorder="0" title="Viewer" src={viewerUrl}></Iframe>
    )
}
