import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { useState } from 'react'
import { CaretDownIcon, CheckIcon } from '@verbit-ai/icons-library'
import { chakra } from '@chakra-ui/react'

export const FlexTargetPopover = styled(Popover2)`
    display: flex;

    .bp4-popover-target {
        display: flex;
    }
`

const PlaybackSpeedMenu = styled(Menu)`
    min-width: 58px;
    padding: 0;
    border: none;
    border-radius: 2px;
    box-shadow: 0px 4px 14px 0px #0000002a;
    overflow: hidden;
`

const PlaybackSpeedMenuItem = styled(MenuItem)`
    padding: 12px 20px;
    border-radius: 0;
    color: #1a4af0 !important;
    font-size: 14px;
    line-height: normal;
    transition: background-color 250ms ease-out;

    & div {
        display: flex;
        justify-content: space-between;
    }

    &:hover,
    &.bp4-active {
        font-weight: 800;
        background-color: ${palette('cloudBlueLight', 2)} !important;
    }
`

const StyledPlaybackSpeedButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 8px;

    font-size: 16px;
    line-height: 12px;
    font-weight: 600;
    border-radius: 16px;
    color: #1a4af0;

    &:hover {
        background-color: #f4f6fe;
    }
`

interface PlaybackSpeedButtonProps {
    speedOptions: number[]
    selectedSpeed: number
    onChange: (speed: number) => void
}

export const PlaybackSpeedButton = ({
    speedOptions,
    selectedSpeed,
    onChange,
}: PlaybackSpeedButtonProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const onSpeedItemClick = (speed: number) => {
        onChange(speed)
        setIsOpen(false)
    }

    return (
        <FlexTargetPopover
            isOpen={isOpen}
            onInteraction={(nextOpenState) => setIsOpen(nextOpenState)}
            position={'top'}
            modifiers={{
                offset: { enabled: true },
                arrow: { enabled: false },
            }}
            content={
                <PlaybackSpeedMenu>
                    {speedOptions.map((speed) => (
                        <PlaybackSpeedMenuItem
                            key={speed}
                            onClick={() => onSpeedItemClick(speed)}
                            text={
                                <>
                                    {speed}x
                                    {speed === selectedSpeed && <CheckIcon color="#1a4af0" />}
                                </>
                            }
                            active={speed === selectedSpeed}
                        />
                    ))}
                </PlaybackSpeedMenu>
            }
        >
            <Tooltip label="Play Speed" placement="top" hasArrow>
                <StyledPlaybackSpeedButton>
                    <chakra.div mr={1}>{selectedSpeed}x</chakra.div>
                    <CaretDownIcon
                        color="#1a4af0"
                        style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
                    />
                </StyledPlaybackSpeedButton>
            </Tooltip>
        </FlexTargetPopover>
    )
}
