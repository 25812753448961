import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import LogRocket from 'logrocket'

import { createUniqueCallbackRegistry } from 'src/factories/CallbackRegistry'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useIsTaskValidationMachineActive } from 'src/state/state-machines/TaskValidationMachine/TaskValidationMachineProvider'
import { withContextProvider } from 'src/hocs/withContextProvider'

import { TaskValidationHandler } from './TaskValidationHandler'
import { useUserIdleTime } from 'src/hooks/useUserIdleTime'

export type TaskPayloadSender = (opts: { autoSubmit: boolean }) => any

const [TaskDataSenderRegistrationProvider, useTaskPayloadSender, useTaskPayloadCallbackConsumer] =
    createUniqueCallbackRegistry<TaskPayloadSender>({
        notRegisteredError: `No task data sender registered after 2 seconds.`,
    })

interface TaskSubmissionStallingContextProps {
    addStallingPromise: (promise: Promise<any>) => unknown
}

const TaskSubmissionStallingContext = React.createContext<TaskSubmissionStallingContextProps>({
    addStallingPromise: () => {},
})

const TASK_SUBMISSION_STALLING_TIMEOUT_MS = 3 * 1000

interface TaskStateProps {
    children: React.ReactNode
}

export const TaskState = withContextProvider<TaskStateProps>(
    TaskDataSenderRegistrationProvider,
    ({ children }) => {
        const [{ value: state, context }, sendEvent] = useTaskMachine([
            'state',
            'task',
            'autoSubmit',
        ])
        const senderFunction = useTaskPayloadCallbackConsumer()
        const isTaskValidationMachineActive = useIsTaskValidationMachineActive()
        const { getUserIdleTime, resetUserIdleTime } = useUserIdleTime()

        const stallingPromisesRef = useRef<Promise<any>[]>([])

        useEffect(() => {
            if (state === 'task-processing' && senderFunction) {
                // wait for all stalling promisses but for a maximum of TASK_SUMISSION_STALLING_TIMEOUT_MS
                Promise.race([
                    Promise.all(stallingPromisesRef.current),
                    new Promise<string>((resolve) =>
                        setTimeout(() => {
                            resolve('timeout')
                        }, TASK_SUBMISSION_STALLING_TIMEOUT_MS),
                    ),
                ])
                    .then((result) => {
                        // in case the stalling takes too long, report it to Logrocket and Rollbar.
                        if (result === 'timeout') {
                            const msg = `Could not stall submission long enough - Moving on. (stalling promises: ${stallingPromisesRef.current.length})`
                            LogRocket.error(msg)
                            window.Rollbar?.error(msg)
                        }
                    })
                    .finally(() => {
                        sendEvent({
                            type: 'ASSIGN_USER_IDLE_TIME',
                            userIdleTimeSeconds: getUserIdleTime() / 1000,
                        })
                        resetUserIdleTime()

                        const payload = senderFunction({ autoSubmit: !!context.autoSubmit })
                        sendEvent({ type: 'PAYLOAD_RECEIVED', payload })
                    })

                stallingPromisesRef.current = []
            }
        }, [
            sendEvent,
            senderFunction,
            context.autoSubmit,
            state,
            getUserIdleTime,
            resetUserIdleTime,
        ])

        const addStallingPromise = useCallback((promise: Promise<any>) => {
            stallingPromisesRef.current.push(promise)
        }, [])

        return (
            <TaskSubmissionStallingContext.Provider
                value={useMemo(() => ({ addStallingPromise }), [addStallingPromise])}
            >
                {isTaskValidationMachineActive && <TaskValidationHandler />}
                {children}
            </TaskSubmissionStallingContext.Provider>
        )
    },
)

// This hook will add a promise to a list of promises that will stall the submission until all of them are resolved or
// until a timeout is hit.
export function useTaskSubmissionStall() {
    const ctx = useContext(TaskSubmissionStallingContext)

    if (!ctx) {
        throw new Error('You have forgot to use <TaskState />, shame on you.')
    }

    return ctx.addStallingPromise
}

// This hook is used to pull the network-ready modified task payload out of the slate tree,
// since the xstate task machines don't have access to it.
// It has to be implemented exactly once per task type and can only be rendered once in a slate tree.
// The passed function will be triggered by a state machine state change and the returned value will be submitted
// as the task payload to the ORC.
export { useTaskPayloadSender }
