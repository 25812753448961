export interface LegalExhibit {
    identifier: string
}

export interface LegalMetadata {
    annotation?: string
    exhibit?: LegalExhibit
}

export const COLLOQUY_LEGAL_ANNOTATION = 'c'
