import { OnboardingStep, OnboardingTask } from 'src/models'
import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'

export const useOnboardingStepPayload = <T extends OnboardingStep['type']>(stepType: T) => {
    const step = useTask<OnboardingTask>().payload.steps[stepType]

    if (!step) {
        throw new Error('The step ' + stepType + ' does not exist')
    }

    return step.payload as Extract<OnboardingStep, { type: T }>['payload']
}
