import { Editor, Location, Span, Transforms, Text } from 'slate'

interface AtWithSpanOptions {
    at?: Location | Span
}

interface AtMatchOptions {
    at: Location
}

interface SpellingError {
    type: 'text'
    text: string
    spellingError: boolean
}

export const Spellchecker = {
    isSpellingError: (el: any): el is SpellingError => el?.spellingError === true,

    hasSpellingError: (editor: Editor, options: AtWithSpanOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return false

        const [node] = Editor.nodes(editor, { at, match: (n) => Spellchecker.isSpellingError(n) })
        return !!node
    },

    getSpellingErrorsCount: (editor: Editor) => {
        const spellingErrors = Array.from(
            Editor.nodes(editor, {
                at: [],
                mode: 'all',
                match: (w) => Spellchecker.isSpellingError(w),
            }),
        )
        return spellingErrors.length
    },

    removeSpellingError: (editor: Editor, options: AtMatchOptions) => {
        const { at = editor.selection } = options
        if (!at) return false

        Transforms.unsetNodes(editor, 'spellingError', { at, match: Text.isText })
    },

    setSpellingError: (editor: Editor, options: AtMatchOptions) => {
        const { at = editor.selection } = options
        if (!at) return false

        Transforms.setNodes(
            editor,
            { spellingError: true },
            {
                at,
                split: true,
                match: Text.isText,
            },
        )
    },
}
