export function makeCancelable<T>(promise: Promise<T>) {
    let isCanceled = false
    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then((val) => (isCanceled ? reject({ isCanceled }) : resolve(val)))
            .catch((error) => (isCanceled ? reject({ isCanceled }) : reject(error)))
    })
    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true
        },
    }
}
