import { RenderElementProps, useSelected } from 'slate-react'
import styled from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { Suggestion } from './Suggestion'

interface SuggestionViewProps extends RenderElementProps {
    element: Suggestion
}

const Wrapper = styled.span<{ selected: boolean }>`
    ${ifProp('selected', 'background-color: #d8e3fb;')}
    & > span {
        position: relative;
        white-space: nowrap;
        &::after {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            width: 100%;
            height: 1px;
            bottom: -3px;
            border-bottom: 2px dotted ${palette('blue', 0)};
        }
    }
`

export function SuggestionView({ element, attributes, children }: SuggestionViewProps) {
    const selected = useSelected()
    return (
        <Wrapper {...attributes} selected={selected}>
            {children}
        </Wrapper>
    )
}
