import { format } from 'date-fns'

export function formatDuration(duration: number): string {
    const timeFormat = duration >= 60 * 60 ? 'hh:mm:ss' : 'mm:ss'
    const timeString = format(new Date(0, 0, 0, 0, 0, Math.abs(duration), 0), timeFormat)

    return duration < 0 ? `-${timeString}` : timeString
}

export async function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), ms))
}
export const secondsToHHMMSS = (seconds: number) => {
    seconds = Number(seconds)
    let h = `0${~~((seconds % (3600 * 24)) / 3600)}`.slice(-2)
    let m = `0${~~((seconds % 3600) / 60)}`.slice(-2)
    let s = `0${~~(seconds % 60)}`.slice(-2)

    h = h === '00' ? '' : `${h}:`

    return `${h}${m}:${s}`
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
