import { useContext, useEffect } from 'react'
import LogRocket from 'logrocket'

import { TaskEvent, TaskMachineType } from './state-machines/TaskMachine/TaskMachine'
import { AppMachineContext } from './state-machines/AppMachine/AppMachineProvider'

const TASK_MACHINE_LOGGER_EXCLUDED_EVENTS: Array<TaskEvent['type']> = ['NO_AVAILABLE_TASKS', 'TICK']

export const TaskMachineLogger = () => {
    const props = useContext(AppMachineContext)
    if (!props) {
        throw new Error('You forgot to use <AppMachine />.')
    }

    const worker = props.machine.children.get('worker') as TaskMachineType | undefined
    if (!props.machine.state.matches('working') || !worker) {
        throw new Error(
            `You can render TaskMachineLogger only in the "working" state. (current state: ${props.machine.state.value})`,
        )
    }
    useEffect(() => {
        const subscription = worker.subscribe(({ event, value, context }) => {
            const { type: eventType, ...eventArgs } = event

            if (!TASK_MACHINE_LOGGER_EXCLUDED_EVENTS.includes(eventType))
                LogRocket.log(Date.now(), {
                    state: value,
                    eventType,
                    eventArgs,
                    context,
                })
        })

        return subscription.unsubscribe
    }, [worker])

    return null
}
