import React from 'react'

import { SessionProvider } from 'src/state/session'
import {
    useIsTaskMachineActive,
    useTaskMachine,
} from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import {
    useIsLiveMachineActive,
    useLiveMachine,
} from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { defaultLivePanelTab } from 'src/components/Session/live/components/LivePanelTabsManager/consts'

interface SessionProviderGateProps {
    children: React.ReactNode
}

const TaskMachineSessionProvider = ({ children }: SessionProviderGateProps) => {
    const [{ context: taskContext }] = useTaskMachine(['task', 'sessionResources'])
    const { task, sessionResources } = taskContext

    return (
        <SessionProvider
            taskId={task?.id}
            taskType={task?.type}
            taskLayerId={task?.layerId}
            speakersModificationDate={sessionResources?.speakersModifiedAt}
            tagsModificationDate={sessionResources?.tagsModifiedAt}
            // @ts-ignore
            controls={task?.payload?.controls}
        >
            {children}
        </SessionProvider>
    )
}

const LiveMachineSessionProvider = ({ children }: SessionProviderGateProps) => {
    const [{ context }] = useLiveMachine(['sessionId', 'controls'])
    const { sessionId, controls } = context

    return (
        <SessionProvider
            taskId={sessionId}
            taskType="live"
            taskLayerId={'customer_content_manager'}
            controls={controls}
            initialPanelTab={defaultLivePanelTab}
        >
            {children}
        </SessionProvider>
    )
}

export const SessionProviderGate = ({ children }: SessionProviderGateProps) => {
    const isTaskMachineActive = useIsTaskMachineActive()
    const isLiveMachineActive = useIsLiveMachineActive()

    if (isTaskMachineActive) {
        return <TaskMachineSessionProvider>{children}</TaskMachineSessionProvider>
    }

    if (isLiveMachineActive) {
        return <LiveMachineSessionProvider>{children}</LiveMachineSessionProvider>
    }

    return <>{children}</>
}
