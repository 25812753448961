import { useMemo, useRef } from 'react'
import { Editor, Node } from 'slate'
import { useSlateStatic, ReactEditor } from 'slate-react'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { useCurrentHighlight } from 'src/components/Editor/plugins/withTimeline/hooks/useCurrentHighlight'
import { useEditorMeta, useHighlightedWord } from 'src/components/Editor/EditorContext'
import { TimingRange } from 'src/utils/range'
import { useSessionMediaService } from 'src/state/SessionMediaProvider'

import { ContentText } from '../ContentText'
import { Block } from '../Block'

interface TextProps {
    editable: boolean
    highlight: 'before' | 'now' | 'after'

    // is audio playing
    highlightCurrent: boolean

    // highlight selection when editor is blurred (and a context menu is open)
    selection: boolean
    spellingError: boolean
    isValidationInvalid: boolean
    isEditorInGlossersMode: boolean
    liveUpdate: boolean
}

const nodeUnderlineBaseStyle = css`
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
`
const invalidTermBottomLineStyle = css`
    ${nodeUnderlineBaseStyle};
    height: 1px;
    // using border-top instead of background image because line size stays consistent like this
    border-top: 1px solid ${palette('orange', 0)};
`

export const Text = styled.span<TextProps>`
    line-height: 31px;
    position: relative;
    cursor: text;
    padding: 3px 0;
    color: ${ifProp(
        ({ highlight }) => highlight === 'after',
        palette('black', 0),
        palette('navy', 6),
    )};
    background-color: ${ifProp('selection', palette('cloudBlueLight', 9), 'inherit')};
    top: -4px;

    ${ifProp(
        'isValidationInvalid',
        css`
            background: none;

            &::before {
                ${invalidTermBottomLineStyle};
                bottom: 2px;
            }

            &::after {
                ${invalidTermBottomLineStyle};
                bottom: -1px;
            }
        `,
    )}

    ${ifProp(
        'spellingError',
        css`
            white-space: nowrap;

            &::before {
                ${nodeUnderlineBaseStyle};
                height: 5px;
                bottom: -1px;
                background: url('wavyRedLine.svg') bottom repeat-x;
            }
        `,
    )}

  ${ifProp(
        { highlight: 'now' },
        css`
            background-color: ${palette('turquoise', 0)}40;
        `,
    )}

  ${ifProp(
        'isEditorInGlossersMode',
        css`
            color: ${ifProp(
                ({ highlight }) => highlight === 'after',
                palette('cloudBlue', 6),
                palette('navy', 12),
            )};
        `,
    )}

    ${ifProp(
        'liveUpdate',
        css`
            background-color: #6eb5ff40;
        `,
    )}
`

interface ContentTextViewProps {
    leaf: ContentText
    text: Node
    attributes: any
    children: string
}

export const ContentTextView = ({ leaf, text, attributes, children }: ContentTextViewProps) => {
    const editor = useSlateStatic()
    const { editorMode } = useEditorMeta()

    const isEditorInGlossersMode = editorMode === 'glossers'
    const currentHighlightedWordRef = useRef<HTMLSpanElement>(null)

    const { setHighlightedWord } = useHighlightedWord()

    const blockRange = useMemo<TimingRange>(() => {
        const currentPath = ReactEditor.findPath(editor, text)
        const [[, blockPath]] = Editor.nodes(editor, { at: currentPath, match: Block.isBlock })
        const [blockIndex] = blockPath

        return {
            start: editor.timeline.getBlockStartTime(blockIndex),
            end: editor.timeline.getBlockEndTime(blockIndex),
        }
    }, [editor, text])

    const highlight = useCurrentHighlight(blockRange)

    useSessionMediaService(() => {
        if (highlight === 'now') {
            setHighlightedWord(currentHighlightedWordRef)
        }
    }, ['currentTime'])

    return (
        <Text
            {...attributes}
            ref={highlight === 'now' ? currentHighlightedWordRef : undefined}
            spellingError={leaf.spellingError}
            isValidationInvalid={!!leaf.invalidValidationId}
            highlight={highlight === 'now' ? leaf.timingHighlight ?? 'after' : highlight}
            editable={leaf.editable}
            isEditorInGlossersMode={isEditorInGlossersMode}
            selection={leaf.selection}
            liveUpdate={leaf.liveUpdate}
        >
            {children}
        </Text>
    )
}
