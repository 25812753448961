import { useCallback } from 'react'
import { Icon, Intent } from '@blueprintjs/core'
import { useIsPanelTabsOpen } from 'src/components/Session/PanelTabsManager'
import styled, { keyframes, css } from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'

import { Task } from 'src/models'
import { TaskRegistry } from 'src/tasks/Registry'
import { formatDuration } from 'src/utils/time'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TaskPublishType } from 'src/analytics/client'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { useToast } from 'src/components/Toasts/ToastContext'
import * as Icons from 'src/components/icons'

const COUNTDOWN_DURATION_MS = 15 * 1000

const Wrapper = styled.div<{
    isPanelTabsOpen: boolean
    position?: string
    publish_button_display_position?: string
}>`
    display: ${ifProp(
        ({ publish_button_display_position }) => publish_button_display_position === 'body',
        'flex',
        'none',
    )};
    position: ${ifProp(
        { position: 'static' },
        css`
            position: static;
            padding-left: 20px;
        `,
        'absolute',
    )};
    right: ${ifProp('isPanelTabsOpen', '25%', '17%')};
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 41px);
    transition: right 700ms;
`

const Display = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
`

const countdown = keyframes`
    from {
        transform: translateX(-100%);
    }
    to  {
        transform: translateX(0%);
    }
`

interface ButtonProps {
    active: boolean
    preCountdownStarted: boolean
}

const ArrowRightIcon = styled(Icons.CheckIcon)`
    width: 40px;
    height: 40px;
    color: ${palette('white', 0)};
    z-index: 1;
`

const PRE_COUNTDOWN_DURATION = 2000
const BLINK_COUNT = 3
const Button = styled.div<ButtonProps>`
    position: relative;
    width: 52px;
    height: 134px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: ${palette('cloudBlue', 2)};

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    cursor: ${ifProp('active', 'pointer', 'not-allowed')};

    ${ifProp(
        'preCountdownStarted',
        css`
            animation: blink ${PRE_COUNTDOWN_DURATION / BLINK_COUNT}ms ease-out infinite;
        `,
    )};

    @keyframes blink {
        0% {
            background-color: ${palette('navy', 2)};
        }

        50% {
            background-color: ${palette('blue', 2)};
        }

        100% {
            background-color: ${palette('cloudBlue', 2)};
        }
    }

    ${ifProp(
        'active',
        css`
            background-color: ${palette('navy', 2)};
            transition: background-color 500ms ease-in;

            &:hover ${ArrowRightIcon} {
                color: ${palette('turquoise', 0)};
            }
        `,
    )}
`

interface CountdownTimerProps {
    countdownStarted: boolean
}
const CountdownTimer = styled.span<CountdownTimerProps>`
    color: ${ifProp('countdownStarted', palette('blue', 0), palette('turquoise', 0))};
    font-variant-numeric: tabular-nums;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -1px;
    text-align: center;
`

interface ProgressBarProps {
    countdownStarted: boolean
    countdownDurationMs: number
}
const ProgressBar = styled.div<ProgressBarProps>`
    display: ${(props) => (props.countdownStarted ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    background: #3b72ea;

    ${ifProp(
        'countdownStarted',
        css<ProgressBarProps>`
            animation: ${countdown} ${(props) => props.countdownDurationMs}ms linear;
        `,
    )}
`

function getMessageForEarlySubmission(task?: Task): string {
    switch (task?.type) {
        case 'transcription':
            return 'Please listen to the entire task before you submit.'
        default:
            return 'You cannot submit this task yet.'
    }
}

interface PublishButtonProps {
    onPublish?: (publishType: TaskPublishType) => void
    position?: string
}

export function PublishButton({ onPublish, position }: PublishButtonProps) {
    const addToast = useToast()
    const [{ context }, sendTaskEvent] = useTaskMachine([
        'allowedToSubmit',
        'elapsed',
        'timerDuration',
    ])
    const { allowedToSubmit, elapsed, timerDuration, task } = context
    let publish_button_display_position
    if (
        task &&
        'payload' in task &&
        'controls' in task.payload &&
        'publishButton' in task.payload.controls
    ) {
        publish_button_display_position =
            task?.payload?.controls?.publishButton?.position !== undefined
                ? task.payload.controls.publishButton.position
                : 'body'
    }
    const isTimerVisible = task ? TaskRegistry.isTimerVisible(task) : true
    const timeLeft = timerDuration - Math.floor(elapsed)
    const countdownStarted = timeLeft <= COUNTDOWN_DURATION_MS / 1000
    const preCountdownStarted =
        !countdownStarted && timeLeft <= (COUNTDOWN_DURATION_MS + PRE_COUNTDOWN_DURATION) / 1000

    const publish = useCallback(
        (publishType: Exclude<TaskPublishType, 'Timeout'>) => {
            if (!allowedToSubmit) {
                addToast({
                    intent: Intent.NONE,
                    icon: <Icon icon="issue" color="#c23030" />,
                    message: getMessageForEarlySubmission(task),
                })
                return
            }

            sendTaskEvent({ type: 'SUBMIT', autoSubmit: false, publishType })
            onPublish?.(publishType)
        },
        [addToast, allowedToSubmit, onPublish, sendTaskEvent, task],
    )
    useMousetrap('alt+p', () => publish('Keyboard'), {
        label: 'Publish Task',
        preventDefault: true,
    })

    const isPanelTabsOpen = useIsPanelTabsOpen()

    return (
        <Wrapper
            isPanelTabsOpen={isPanelTabsOpen}
            position={position}
            publish_button_display_position={publish_button_display_position}
        >
            {isTimerVisible && (
                <Display>
                    <CountdownTimer
                        data-testid={'publish-button-timer'}
                        countdownStarted={countdownStarted}
                    >
                        {formatDuration(timeLeft)}
                    </CountdownTimer>
                </Display>
            )}
            <Button
                data-testid={'publish-button'}
                active={allowedToSubmit}
                preCountdownStarted={preCountdownStarted}
                onClick={() => publish('Mouse')}
            >
                <ArrowRightIcon />
                <ProgressBar
                    countdownStarted={countdownStarted}
                    countdownDurationMs={COUNTDOWN_DURATION_MS}
                />
            </Button>
        </Wrapper>
    )
}
