import { useEffect, useMemo, ReactNode, useState } from 'react'

import { TimingRange } from 'src/utils/range'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { usePrevious } from 'src/hooks/usePrevious'
import { useMediaPlayedTimeRanges } from 'src/hooks/useMediaPlayedTimeRanges'
import { createUniqueCallbackRegistry } from 'src/factories/CallbackRegistry'
import { createSubscriptionService } from 'src/factories/SubscriptionService'
import { withContextProvider } from 'src/hocs/withContextProvider'

import { useSession } from './session'
import { MediaTimingInfo } from 'src/factories/MediaService'
import { useSessionMedia } from './SessionMediaProvider'

const [MediaTimingProvider, useMediaTimingConverter, useMediaTimingConverterCallback] =
    createUniqueCallbackRegistry<() => MediaTimingInfo | null>({ enableNotRegisteredError: false })

export { useMediaTimingConverter as useAudioTimingConverter }

export interface TaskAudioContextProps {
    isPlayerVisible: boolean
    setIsPlayerVisible: (value: boolean) => void
    playedTimeRanges: TimingRange[]
}

const [TaskMediaPlayerProvider, useTaskMediaPlayer, useTaskMediaPlayyerService] =
    createSubscriptionService<TaskAudioContextProps>({
        playedTimeRanges: [],
        isPlayerVisible: false,
        setIsPlayerVisible: () => {},
    })

export { useTaskMediaPlayer, useTaskMediaPlayyerService }

interface TaskMediaProviderProps {
    children: ReactNode
}

export const TaskMediaProvider = withContextProvider<TaskMediaProviderProps>(
    MediaTimingProvider,
    function TaskMediaProvider({ children }) {
        const mediaTimingConverter = useMediaTimingConverterCallback()
        const mediaTiming = useMemo(() => mediaTimingConverter?.() ?? null, [mediaTimingConverter])
        const { taskId } = useSession()
        const { sessionStatus } = useSessionStatus(['sessionStatus.audioUrl'])
        const [isPlayerVisible, setIsPlayerVisible] = useState(false)

        const { mediaRef, setMediaTiming, isMediaConnected, connectMedia, seekTime, mediaEvents } =
            useSessionMedia([
                'connectMedia',
                'seekTime',
                'isMediaConnected',
                'isMediaPlaying',
                'mediaRef',
                'setMediaTiming',
                'mediaEvents',
            ])

        const {
            playedTimeRanges,
            resetPlayedTimeRanges,
            callbacks: mediaPlayedTimeRangesCallbacks,
        } = useMediaPlayedTimeRanges(mediaRef)

        useEffect(() => {
            mediaEvents.on('beforeplay', mediaPlayedTimeRangesCallbacks.onBeforePlay)
            mediaEvents.on('afterpause', mediaPlayedTimeRangesCallbacks.onAfterPause)
            mediaEvents.on('seeked', mediaPlayedTimeRangesCallbacks.onSeeked)
            mediaEvents.on('timeupdate', mediaPlayedTimeRangesCallbacks.onTimeUpdate)

            return () => {
                mediaEvents.off('beforeplay', mediaPlayedTimeRangesCallbacks.onBeforePlay)
                mediaEvents.off('afterpause', mediaPlayedTimeRangesCallbacks.onAfterPause)
                mediaEvents.off('seeked', mediaPlayedTimeRangesCallbacks.onSeeked)
                mediaEvents.off('timeupdate', mediaPlayedTimeRangesCallbacks.onTimeUpdate)
            }
        }, [
            mediaEvents,
            mediaPlayedTimeRangesCallbacks.onAfterPause,
            mediaPlayedTimeRangesCallbacks.onBeforePlay,
            mediaPlayedTimeRangesCallbacks.onSeeked,
            mediaPlayedTimeRangesCallbacks.onTimeUpdate,
        ])

        useEffect(() => {
            setMediaTiming(mediaTiming ?? undefined)
        }, [mediaTiming, setMediaTiming])

        const audioUrl = sessionStatus?.audioUrl
        const prevAudioUrl = usePrevious(audioUrl)

        useEffect(() => {
            if (taskId) {
                resetPlayedTimeRanges()
            }
        }, [taskId, resetPlayedTimeRanges])

        const taskMediaStartTime = mediaTiming?.requiredRangeToBeBuffered.start
        const prevTaskMediaStartTime = usePrevious(taskMediaStartTime)
        useEffect(() => {
            if (
                audioUrl &&
                taskMediaStartTime !== undefined &&
                (taskMediaStartTime !== prevTaskMediaStartTime || !prevAudioUrl)
            ) {
                if (!isMediaConnected) {
                    connectMedia(taskMediaStartTime)
                }

                console.log('setting task start time', taskMediaStartTime)
                seekTime(taskMediaStartTime)
            }
        }, [
            audioUrl,
            prevAudioUrl,
            taskMediaStartTime,
            prevTaskMediaStartTime,
            seekTime,
            isMediaConnected,
            connectMedia,
        ])

        const props = useMemo<TaskAudioContextProps>(
            () => ({
                isPlayerVisible,
                setIsPlayerVisible,
                playedTimeRanges,
            }),
            [isPlayerVisible, playedTimeRanges],
        )

        return <TaskMediaPlayerProvider data={props}>{children}</TaskMediaPlayerProvider>
    },
)
