import { TimingRange } from 'src/utils/range'

import { TextSegmentBlock } from './TextSegment'
import { TimeSegment, TimeSegmentBlock, TimelineTimingRange } from './TimeSegment'
import { BaseSegment, BaseSegmentCreateProps, SegmentPoint } from './Segment'
import { PartialBy } from 'src/utils/typescript'
import { TimelineOperation } from './translateSlateOperation'
import { pushLarge } from 'src/utils/array'
import { TimeAligner } from '../align/TimeAligner'

export type TimedTextSegmentBlock = TimeSegmentBlock & TextSegmentBlock

export type TimedTextSegment = BaseSegment<TimedTextSegmentBlock> & { timing: TimingRange }

type TimedTextSegmentCreateProps = BaseSegmentCreateProps<TimedTextSegmentBlock> & {
    timing: TimingRange
}

export type TimedTextSegmentIterateWordsOptions = {
    reverse?: boolean
    // has to be in ascending order even if reverse: true is passed
    from?: SegmentPoint
    // has to be in ascending order even if reverse: true is passed
    to?: SegmentPoint
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimedTextSegment = {
    create(props: TimedTextSegmentCreateProps): TimedTextSegment {
        return { ...BaseSegment.create(props), timing: props.timing ?? { start: 0, end: 0 } }
    },
    createBlock(block: TimedTextSegmentBlock) {
        const newBlock = BaseSegment.createBlock(block)
        TimeSegment.refreshBlock(newBlock)

        return newBlock
    },
    addBlock(
        segment: TimedTextSegment,
        block: PartialBy<TimedTextSegmentBlock, 'timing'>,
    ): TimedTextSegment {
        const newSegment = BaseSegment.addBlock<TimedTextSegment, TimedTextSegmentBlock>(segment, {
            ...block,
            hasExplicitBreak: Boolean(block.hasExplicitBreak ?? false),
            timing: block.timing ?? TimeSegment.calculateBlockTiming(block),
        })
        TimeSegment.refresh(newSegment)

        return newSegment
    },

    *words(
        segment: TimedTextSegment,
        options: TimedTextSegmentIterateWordsOptions = {},
    ): Generator<[string, TimelineTimingRange, SegmentPoint]> {
        const {
            reverse,
            from = { blockIdx: 0, wordIdx: 0 },
            to = {
                blockIdx: segment.blocks.length - 1,
                wordIdx: segment.blocks[segment.blocks.length - 1]?.words.length - 1,
            },
        } = options

        const start = reverse ? to : from
        const end = reverse ? from : to

        for (const [block, i] of BaseSegment.blocks<TimedTextSegmentBlock>(segment, {
            reverse,
            from: from.blockIdx,
            to: to.blockIdx,
        })) {
            let lastWordIndex = block.words.length - 1

            const startIdx = start.blockIdx === i ? start.wordIdx : reverse ? lastWordIndex : 0
            const endIdx = end.blockIdx === i ? end.wordIdx : reverse ? 0 : lastWordIndex

            for (
                let j = startIdx;
                (reverse && j >= endIdx) || (!reverse && j <= endIdx);
                j += reverse ? -1 : 1
            ) {
                yield [block.words[j], block.timings[j], { blockIdx: i, wordIdx: j }]
            }
        }
    },

    nextWord(segment: TimedTextSegment, point: SegmentPoint): SegmentPoint | undefined {
        const [, next] = TimedTextSegment.words(segment, { from: point, reverse: false })

        if (!next) {
            return undefined
        }

        return next[2]
    },
    prevWord(segment: TimedTextSegment, point: SegmentPoint): SegmentPoint | undefined {
        const [, prev] = TimedTextSegment.words(segment, { to: point, reverse: true })

        if (!prev) {
            return undefined
        }

        return prev[2]
    },

    getLastPoint(segment: TimedTextSegment): SegmentPoint {
        return {
            blockIdx: segment.blocks.length - 1,
            wordIdx: segment.blocks[segment.blocks.length - 1].words.length - 1,
        }
    },
    getTimingAtPoint(
        segment: TimedTextSegment,
        point: SegmentPoint,
    ): TimelineTimingRange | undefined {
        const block = BaseSegment.getBlock(segment, point.blockIdx)
        return block?.timings[point.wordIdx]
    },

    merge(baseSegment: TimedTextSegment, ...segments: TimedTextSegment[]): TimedTextSegment {
        const result = BaseSegment.merge<TimedTextSegment, TimedTextSegmentBlock>(
            baseSegment,
            segments,
            {
                getBlockTiming: (block) => block.timing,
                getSegmentTiming: (segment) => segment.timing,
                getIndexForTiming: TimeSegment.getIndexForTiming,
                mergeBlock: (block, mergeBlock, startIndex, endIndex) => {
                    const start = Math.max(startIndex === endIndex ? startIndex + 1 : startIndex, 0)
                    const length = endIndex - startIndex

                    block.words.splice(start, length, ...mergeBlock.words)
                    block.timings.splice(start, length, ...mergeBlock.timings)
                    TimeSegment.refreshBlock(block)
                },
                createEmptyBlock: () =>
                    TimedTextSegment.createBlock({
                        editable: false,
                        hasExplicitBreak: false,
                        timing: { start: 0, end: 0 },
                        timings: [],
                        words: [],
                    }),
                refreshSegment: TimeSegment.refresh,
            },
        )

        TimeSegment.refresh(result)

        return result
    },
    concat(baseSegment: TimedTextSegment, ...segments: TimedTextSegment[]): TimedTextSegment {
        const result = BaseSegment.concat<TimedTextSegment, TimedTextSegmentBlock>(
            baseSegment,
            ...segments,
        )

        TimeSegment.refresh(result)

        return result
    },
    apply: (
        segment: TimedTextSegment,
        aligner: TimeAligner,
        operations: TimelineOperation[],
    ): TimedTextSegment => {
        for (const op of operations) {
            switch (op.type) {
                case 'split_block': {
                    const { blockIndex, wordIndex } = op
                    const block = BaseSegment.getBlock(segment, blockIndex)

                    if (!block) {
                        break
                    }

                    const newBlock = TimedTextSegment.createBlock({
                        ...block,
                        words: block.words.slice(wordIndex),
                        timings: block.timings.slice(wordIndex),
                    })

                    segment.blocks.splice(blockIndex + 1, 0, newBlock)

                    block.words = block.words.slice(0, wordIndex)
                    block.timings = block.timings.slice(0, wordIndex)
                    TimeSegment.refreshBlock(block)

                    break
                }
                case 'merge_block': {
                    const { blockIndex } = op

                    if (blockIndex === 0) {
                        break
                    }

                    const block = BaseSegment.getBlock(segment, blockIndex)
                    const prevBlock = BaseSegment.getBlock(segment, blockIndex - 1)

                    if (!block || !prevBlock) {
                        break
                    }

                    pushLarge(prevBlock.words, block.words)
                    pushLarge(prevBlock.timings, block.timings)
                    TimeSegment.refreshBlock(prevBlock)

                    segment.blocks.splice(blockIndex, 1)
                    break
                }
                case 'add_word': {
                    const { blockIndex, wordIndex, wordContent } = op
                    const block = BaseSegment.getBlock(segment, blockIndex)
                    const point = { blockIdx: blockIndex, wordIdx: wordIndex }

                    if (!block) {
                        break
                    }

                    let prevWordTiming: TimelineTimingRange | undefined

                    const prevWordPoint = TimedTextSegment.prevWord(segment, point)
                    if (prevWordPoint) {
                        prevWordTiming = TimedTextSegment.getTimingAtPoint(segment, prevWordPoint)
                    }

                    let nextWordTiming: TimelineTimingRange | undefined =
                        TimedTextSegment.getTimingAtPoint(segment, point)

                    block.words.splice(wordIndex, 0, wordContent)
                    block.timings.splice(wordIndex, 0, {
                        start: prevWordTiming?.end ?? block.timing.start,
                        end: nextWordTiming?.start ?? block.timing.end,
                        isOrginal: false,
                    })

                    aligner.align(segment, point)

                    break
                }
                case 'remove_word': {
                    const { blockIndex, wordIndex } = op
                    const block = BaseSegment.getBlock(segment, blockIndex)

                    if (!block) {
                        break
                    }

                    block.words.splice(wordIndex, 1)
                    block.timings.splice(wordIndex, 1)
                    TimeSegment.refreshBlock(block)
                    break
                }
                case 'change_word_content': {
                    const { blockIndex, wordIndex, newWordContent } = op

                    const block = BaseSegment.getBlock(segment, blockIndex)
                    if (!block) {
                        break
                    }

                    block.words[wordIndex] = newWordContent

                    // TODO: Maybe re-align the word(s)?
                    break
                }
            }
        }

        TimeSegment.refresh(segment)

        return segment
    },
}
