import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import to from 'await-to-js'

import { GlossaryDict, GlossaryTerm } from '../models'
import { GlossaryEventSource } from '../analytics/client'
import { APIError } from '../network'
import { useAnalytics } from '../analytics'
import { makeCancelable } from '../utils/promise'
import { useSpellchecker } from '../components/Spellchecker/Spellchecker'
import { UUID } from '../utils/uuid'
import { usePrevious } from '../hooks/usePrevious'
import { useAppMachine } from './state-machines/AppMachine/AppMachineProvider'
import { useSessionStatus } from './SessionStatusProvider'
import { useSession } from './session'

interface GlossaryContextValue {
    glossary: GlossaryDict
    addTerm: (term: GlossaryTerm, source: GlossaryEventSource) => Promise<UUID | null>
    editTerm: (term: GlossaryTerm, source: GlossaryEventSource) => Promise<void>
    deleteTerm: (term: GlossaryTerm, source: GlossaryEventSource) => void
}

const GlossaryContext = createContext<GlossaryContextValue>({
    glossary: GlossaryDict.empty,
    addTerm: async () => null,
    editTerm: Promise.resolve,
    deleteTerm: () => {},
})

interface GlossaryProviderProps {
    children: ReactNode
}

export const GlossaryProvider = ({ children }: GlossaryProviderProps) => {
    const { taskId, taskType, tagsModificationDate } = useSession()
    const { sessionStatus: status } = useSessionStatus(['sessionStatus.tagsModifiedAt'])
    const prevStatus = usePrevious(status)
    const [glossary, setGlossary] = useState<GlossaryDict>(GlossaryDict.empty)
    const [{ context: appContext }] = useAppMachine([
        'workerId',
        'workerName',
        'workerEmail',
        'layerIds',
        'httpClient',
    ])
    const { workerId, httpClient } = appContext
    const prevWorkerId = usePrevious(workerId)
    const analytics = useAnalytics()

    const addTerm = useCallback(
        async (term: GlossaryTerm, source: GlossaryEventSource) => {
            const newGlossary = { terms: glossary.terms.concat(term) }
            setGlossary(newGlossary)

            const [err, returnedTerm] = await to(httpClient.addGlossaryTerm(term))
            // Update the term ID to the actual ID returned from the API. The initial ID is generated on the client-side and is meaningless.
            // We can update the term ID directly on the object since it's referenced in the array

            if (err instanceof APIError && err.httpCode === 500) {
                const newGlossary = { terms: glossary.terms.filter((t) => t.id !== term.id) }
                setGlossary(newGlossary)

                return Promise.reject()
            }

            if (returnedTerm) {
                // update id
                setGlossary((gloss) => ({
                    terms: gloss.terms.map((t) => {
                        if (t.id !== term.id) {
                            return t
                        }
                        return { ...t, id: returnedTerm.id }
                    }),
                }))
                analytics?.sendAddGlossaryTerm(
                    taskId ?? '',
                    taskType ?? '',
                    term.id,
                    term.category,
                    term.text,
                    source,
                )
                return returnedTerm.id
            } else {
                // if we don't get a term back, its possibly a duplicate and we filter out our temporary term
                setGlossary((gloss) => ({ terms: gloss.terms.filter((t) => t.id !== term.id) }))
                return null
            }
        },
        [analytics, glossary.terms, httpClient, taskId, taskType],
    )

    const editTerm = useCallback(
        async (term: GlossaryTerm, source: GlossaryEventSource) => {
            // TODO: handle error
            await httpClient.updateGlossaryTerm(term)

            const idx = glossary.terms.findIndex((t) => t.id === term.id)
            glossary.terms[idx] = term
            const newGlossary = { terms: [...glossary.terms] }

            setGlossary(newGlossary)
            analytics?.sendEditGlossaryTerm(
                taskId ?? '',
                term.id,
                term.category,
                term.text,
                term.verified ?? false,
                source,
            )
        },
        [analytics, glossary.terms, httpClient, taskId],
    )

    const deleteTerm = useCallback(
        async (term: GlossaryTerm, source: GlossaryEventSource) => {
            const newGlossary = { terms: glossary.terms.filter((t) => t.id !== term.id) }
            setGlossary(newGlossary)

            // Ignore the error but avoid crashing due to unhandled promise rejection
            await to(httpClient.deleteGlossaryTerm(term))
            analytics?.sendDeleteGlossaryTerm(
                taskId ?? '',
                term.id,
                term.category,
                term.text,
                term.verified ?? false,
                source,
            )
        },
        [analytics, glossary.terms, httpClient, taskId],
    )
    // Fetch glossary if needed
    useEffect(() => {
        async function fetchGlossary() {
            const newGlossary = await httpClient.getGlossary()
            setGlossary(newGlossary)
        }

        if (
            (!prevWorkerId && workerId) ||
            (prevStatus && status && status.tagsModifiedAt > prevStatus.tagsModifiedAt) ||
            (tagsModificationDate && status && tagsModificationDate > status.tagsModifiedAt)
        ) {
            const { cancel, promise } = makeCancelable(fetchGlossary())
            promise.catch(() => {})
            return cancel
        }
    }, [workerId, prevWorkerId, status, prevStatus, httpClient, tagsModificationDate])

    const { spellchecker } = useSpellchecker()
    useEffect(() => {
        const dictionaryAddedWords = spellchecker.getAddedWords()
        const syncedWords = new Set<string>()

        for (const item of glossary.terms) {
            const words = item.text.trim().split(/\s+/)

            for (const word of words) {
                syncedWords.add(word)
                spellchecker.addWordToDictionary(word)
            }
        }

        for (const addedWord of dictionaryAddedWords) {
            if (syncedWords.has(addedWord)) {
                continue
            }

            spellchecker.removeWordFromDictionary(addedWord)
        }
    }, [glossary.terms, spellchecker])

    return (
        <GlossaryContext.Provider value={{ glossary, addTerm, deleteTerm, editTerm }}>
            {children}
        </GlossaryContext.Provider>
    )
}

export const useGlossary = () => {
    const glossary = useContext(GlossaryContext)

    if (!glossary) {
        throw new Error('You have forgot to use GlossaryContext, shame on you.')
    }

    return glossary
}
