import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { DangerIcon } from 'src/components/icons'

const Field = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;
`

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 7px;
`

const Label = styled.div`
    margin-right: 2px;
    font-size: 14px;
    color: ${palette('navy', 6)};
`

const Error = styled.div`
    position: absolute;
    top: calc(100% + 2px);
    font-size: 12px;
    color: ${palette('red', 0)};
`

interface FormFieldProps {
    label: string
    error?: string
    children: ReactNode
    className?: string
}

export const FormField = ({ label, error, children, className, ...rest }: FormFieldProps) => (
    <Field className={className} {...rest}>
        <LabelContainer>
            <Label>{label}</Label>
            {error && <DangerIcon color="#FF2660" />}
        </LabelContainer>
        {children}
        <Error>{error}</Error>
    </Field>
)
