import React from 'react'
import { Icon } from '@blueprintjs/core'
import styled, { css } from 'styled-components/macro'
import { palette, switchProp, ifProp } from 'styled-tools'

import { OnboardingStep } from 'src/models'
import { StepDescriptorDict } from 'src/components/OnboardingTask/OnboardingTask'
import { OnboardingTaskTimer } from 'src/components/OnboardingTask/OnboardingTaskSidebar/OnboardingTaskTimer'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 346px;
    padding: 40px 50px;
    background-color: ${palette('navy', 2)};
`

const SidebarTitle = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: ${palette('white', 0)};
`

const StepIndicatorList = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 36px;
`

const StepIndicator = styled.div<{ clickable: boolean }>`
    display: flex;
    align-items: center;
    margin-top: 26px;
    margin-right: 18px;
    font-size: 16px;
    color: ${palette('white', 0)};
    cursor: pointer;
    pointer-events: ${ifProp('clickable', 'auto', 'none')};
`

const StepIndicatorDot = styled.div<{ state: 'completed' | 'pending' | 'uncompleted' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;

    ${switchProp('state', {
        completed: css`
            width: 20px;
            height: 20px;
            margin: 2px 16px 2px 2px;
            background-color: ${palette('cloudBlueLight', 0)};
        `,
        pending: css`
            width: 24px;
            height: 24px;
            margin-right: 14px;
            border: 4px solid #6e7d9c;
            background-color: ${palette('white', 0)};
        `,
        uncompleted: css`
            width: 22px;
            height: 22px;
            margin: 1px 15px 1px 1px;
            border: 2px solid #627293;
            background-color: transparent;
        `,
    })}
`

export const TickIcon = styled(Icon).attrs({ icon: 'tick', iconSize: 14 })`
    padding: 1px;
    color: ${palette('navy', 2)};
    animation: grow 250ms ease-out;
    transform-origin: center center;

    @keyframes grow {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }
`

interface OnboardingTaskSidebarProps {
    orderedStepTypes: Array<OnboardingStep['type']>
    stepDescriptors: StepDescriptorDict
    currentStepIndex: number
    timeLeft: number
    taskTimerWarningDuration: number
    onStepIndicatorClick: (stepIndex: number) => void
    isTimerVisible: boolean
}

export const OnboardingTaskSidebar = ({
    orderedStepTypes,
    stepDescriptors,
    currentStepIndex,
    timeLeft,
    taskTimerWarningDuration,
    onStepIndicatorClick,
    isTimerVisible,
}: OnboardingTaskSidebarProps) => (
    <Container>
        <SidebarTitle>About the Session</SidebarTitle>

        <StepIndicatorList>
            {orderedStepTypes.map((stepType, stepIndex) => {
                const stepState =
                    stepIndex < currentStepIndex
                        ? 'completed'
                        : stepIndex === currentStepIndex
                        ? 'pending'
                        : 'uncompleted'

                return (
                    <StepIndicator
                        key={stepType}
                        onClick={() => onStepIndicatorClick(stepIndex)}
                        clickable={stepIndex <= currentStepIndex + 1}
                    >
                        <StepIndicatorDot state={stepState}>
                            {stepState === 'completed' && <TickIcon />}
                        </StepIndicatorDot>

                        {stepDescriptors[stepType].indicatorLabel}
                    </StepIndicator>
                )
            })}
        </StepIndicatorList>

        {isTimerVisible && (
            <OnboardingTaskTimer timeLeft={timeLeft} warningDuration={taskTimerWarningDuration} />
        )}
    </Container>
)
