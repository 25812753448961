import { cloneDeep } from 'lodash'
import { pushLarge } from 'src/utils/array'

import { TimingRange } from 'src/utils/range'

export type BaseSegmentBlock = {
    editable: boolean
    hasExplicitBreak?: boolean
}

export type BaseSegment<Block extends BaseSegmentBlock> = {
    blocks: Block[]
    editableBlockStartIndex: number
    editableBlockEndIndex: number
}

export type SegmentPoint = {
    blockIdx: number
    wordIdx: number
}

export type SegmentRange = {
    from: SegmentPoint
    to: SegmentPoint
}

export type BaseSegmentCreateProps<B extends BaseSegmentBlock> = Omit<
    BaseSegment<B>,
    'blocks' | 'editableBlockEndIndex' | 'editableBlockStartIndex'
>

type IterateBlocksOptions = {
    reverse?: boolean
    // has to be in ascending order even if reverse: true is passed
    from?: number
    // has to be in ascending order even if reverse: true is passed
    to?: number
}

type BaseSegmentMergeAPI<B extends BaseSegmentBlock, S extends BaseSegment<B>> = {
    getSegmentTiming: (segment: S) => TimingRange
    getBlockTiming: (block: B) => TimingRange
    getIndexForTiming: (block: B, time: number, affinity?: 'start' | 'end') => number
    /**
     * Merge all contents of mergeBlock into block, replacing everything between startIndex and endIndex
     */
    mergeBlock: (block: B, mergeBlock: B, startIndex: number, endIndex: number) => void
    createEmptyBlock: () => B
    refreshSegment: (segment: S) => void
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseSegment = {
    create<S extends BaseSegment<B>, B extends BaseSegmentBlock>(
        props: BaseSegmentCreateProps<B>,
    ): S {
        const newSegment: S = {
            blocks: [] as B[],
            ...props,
            editableBlockStartIndex: 0,
            editableBlockEndIndex: 0,
        } as S
        BaseSegment.refresh(newSegment)

        return newSegment
    },

    createBlock<B extends BaseSegmentBlock>(block: Partial<B>): B {
        return {
            ...cloneDeep(block),
            editable: block.editable ?? false,
            hasExplicitBreak: block.hasExplicitBreak ?? false,
        } as B
    },

    addBlock<S extends BaseSegment<B>, B extends BaseSegmentBlock>(segment: S, newBlock: B) {
        segment.blocks.push(newBlock)
        return segment
    },

    getBlock<B extends BaseSegmentBlock>(segment: BaseSegment<B>, blockIndex: number): B | null {
        return segment.blocks[blockIndex] ?? null
    },

    *blocks<B extends BaseSegmentBlock, S extends BaseSegment<B> = BaseSegment<B>>(
        segment: S,
        options: IterateBlocksOptions = {},
    ): Generator<[B, number]> {
        const { reverse, from = 0, to = segment.blocks.length - 1 } = options

        const startIdx = reverse ? to : from
        const endIdx = reverse ? from : to

        for (
            let i = startIdx;
            (reverse && i >= endIdx) || (!reverse && i <= endIdx);
            i += reverse ? -1 : 1
        ) {
            //prettier-ignore
            yield [BaseSegment.getBlock(segment, i)!, i]
        }
    },

    hasEditableBlocks<B extends BaseSegmentBlock, S extends BaseSegment<B> = BaseSegment<B>>(
        segment: S,
    ) {
        return segment.editableBlockStartIndex !== segment.editableBlockEndIndex
    },

    concat: <S extends BaseSegment<B>, B extends BaseSegmentBlock>(
        baseSegment: S,
        ...segments: S[]
    ): S => {
        const s = cloneDeep(baseSegment)

        for (const mergeSegment of segments) {
            pushLarge(s.blocks, mergeSegment.blocks)
        }

        return s
    },

    refresh: (segment: BaseSegment<any>) => {
        // reset, because they're gonna get recalculated
        segment.editableBlockStartIndex = 0
        segment.editableBlockEndIndex = 0

        let isEditable = false
        let hasEditableSegment = false
        for (let i = 0; i < segment.blocks.length; i++) {
            if (segment.blocks[i].editable && !isEditable) {
                segment.editableBlockStartIndex = i
                isEditable = true
                hasEditableSegment = true
            }

            if (isEditable && !segment.blocks[i].editable) {
                segment.editableBlockEndIndex = i - 1
                return
            }
        }

        // If we don't have any editable blocks, we want to consider the entire segment as before context.
        // Previously, the editable block start-/endindex have remained 0 in those cases which led to problems calling the Timeline.updateEditableChunk() method
        // with only non-editable blocks in the timeline.
        // To counter that, we move the editable block start-/endindexes outside the blocks range to indicate that.
        if (!hasEditableSegment) {
            segment.editableBlockStartIndex = segment.blocks.length
        }

        // in case the last block is editable (or there aren't any editable blocks at all) we want to
        // correctly update the editableBlockEndIndex to match either the last block index or the start index (that might be out-of-bounds because of the previous condition)
        if (segment.editableBlockStartIndex >= segment.editableBlockEndIndex) {
            segment.editableBlockEndIndex = Math.max(
                segment.blocks.length - 1,
                segment.editableBlockStartIndex,
            )
        }
    },

    merge: <S extends BaseSegment<B>, B extends BaseSegmentBlock>(
        baseSegment: S,
        segments: S[],
        api: BaseSegmentMergeAPI<B, S>,
    ): S => {
        const s = cloneDeep(baseSegment)
        const emptyBlock = api.createEmptyBlock()

        // remove overlap from right block and merge into left block
        const mergeRight = (leftBlock: B, rightBlock: B) => {
            const leftBlockTiming = api.getBlockTiming(leftBlock)

            // remove overlap from right block
            const overlapEndIndex =
                api.getIndexForTiming(rightBlock, leftBlockTiming.end, 'end') + 1
            api.mergeBlock(rightBlock, emptyBlock, 0, overlapEndIndex)

            // merge into left block
            const leftBlockEndIndex = api.getIndexForTiming(leftBlock, leftBlockTiming.end, 'end')
            api.mergeBlock(leftBlock, rightBlock, leftBlockEndIndex, leftBlockEndIndex)

            // leftBlock.editable = rightBlock.editable
            // leftBlock.hasExplicitBreak = Boolean(rightBlock.hasExplicitBreak)
        }

        // remove overlap from left block and merge into right block
        const mergeLeft = (leftBlock: B, rightBlock: B) => {
            const rightBlockTiming = api.getBlockTiming(rightBlock)

            // remove overlap from left block
            const overlapStartIndex = api.getIndexForTiming(
                leftBlock,
                rightBlockTiming.start,
                'start',
            )
            const overlapEndIndex =
                api.getIndexForTiming(leftBlock, rightBlockTiming.end, 'end') + 1
            api.mergeBlock(leftBlock, emptyBlock, overlapStartIndex, overlapEndIndex)

            // merge into right block
            api.mergeBlock(leftBlock, rightBlock, overlapEndIndex, overlapEndIndex)

            leftBlock.editable = rightBlock.editable
            leftBlock.hasExplicitBreak = Boolean(rightBlock.hasExplicitBreak)
        }

        const mergeWithoutOverlap = (leftBlock: B, rightBlock: B) => {
            const leftBlockTiming = api.getBlockTiming(leftBlock)

            const leftBlockEndIndex =
                api.getIndexForTiming(leftBlock, leftBlockTiming.end, 'end') + 1
            api.mergeBlock(leftBlock, rightBlock, leftBlockEndIndex, leftBlockEndIndex)

            leftBlock.editable = rightBlock.editable
            leftBlock.hasExplicitBreak = Boolean(rightBlock.hasExplicitBreak)
        }

        for (const mergeSegment of segments) {
            if (mergeSegment.blocks.length === 0) {
                continue
            }

            const segmentTiming = api.getSegmentTiming(s)
            const mergeSegmentTiming = api.getSegmentTiming(mergeSegment)

            let startBlockIndex = s.blocks.findIndex((b) =>
                TimingRange.isPointInside(api.getBlockTiming(b), mergeSegmentTiming.start),
            )
            if (mergeSegmentTiming.start >= segmentTiming.end) {
                startBlockIndex = s.blocks.length - 1
            }

            let endBlockIndex = s.blocks.findIndex((b) =>
                TimingRange.isPointInside(api.getBlockTiming(b), mergeSegmentTiming.end),
            )
            if (mergeSegmentTiming.end > segmentTiming.end) {
                endBlockIndex = s.blocks.length - 1
            }

            const startBlock = s.blocks[startBlockIndex]
            const startBlockTiming = !!startBlock && api.getBlockTiming(startBlock)

            const endBlock = s.blocks[endBlockIndex]
            const endBlockTiming = !!endBlock && api.getBlockTiming(endBlock)

            const isStartOfStartBlock =
                !!startBlock && startBlockTiming.start === mergeSegmentTiming.start
            const shouldBreakAtMergeBlock = Boolean(mergeSegment.blocks[0].hasExplicitBreak)
            const doesFirstMergeBlockHaveOverlapWithStartBlock =
                !!startBlock && startBlockTiming.end > mergeSegmentTiming.start
            const shouldMergeStartBlock =
                !!startBlock &&
                !isStartOfStartBlock &&
                doesFirstMergeBlockHaveOverlapWithStartBlock &&
                !shouldBreakAtMergeBlock
            const shouldMergeStartBlockWithoutOverlap =
                !!startBlock &&
                !doesFirstMergeBlockHaveOverlapWithStartBlock &&
                !isStartOfStartBlock &&
                !shouldBreakAtMergeBlock

            if (
                startBlockIndex >= 0 &&
                startBlockIndex === endBlockIndex &&
                mergeSegment.blocks.length === 1 &&
                (!shouldBreakAtMergeBlock || isStartOfStartBlock)
            ) {
                const mergeBlock = mergeSegment.blocks[0]
                const startIndex = api.getIndexForTiming(
                    startBlock,
                    mergeSegmentTiming.start,
                    'start',
                )
                let endIndex = api.getIndexForTiming(startBlock, mergeSegmentTiming.end, 'end')

                if (startIndex !== endIndex) {
                    endIndex += 1
                }

                api.mergeBlock(startBlock, mergeBlock, startIndex, endIndex)

                startBlock.editable = mergeBlock.editable
                startBlock.hasExplicitBreak = mergeBlock.hasExplicitBreak

                continue
            }

            const isEndOfEndBlock = !!endBlock && endBlockTiming.end === mergeSegmentTiming.end

            let numberOfBlockToRemove =
                startBlockIndex === endBlockIndex ? 1 : endBlockIndex - startBlockIndex + 1

            if (!isStartOfStartBlock) numberOfBlockToRemove -= 1
            if (!isEndOfEndBlock) numberOfBlockToRemove -= 1

            const spliceStartIndex = startBlockIndex + (isStartOfStartBlock ? 0 : 1)

            s.blocks.splice(spliceStartIndex, numberOfBlockToRemove, ...mergeSegment.blocks)

            if (endBlockIndex > startBlockIndex) {
                endBlockIndex = endBlockIndex + mergeSegment.blocks.length - numberOfBlockToRemove
            }

            if (shouldMergeStartBlock) {
                mergeLeft(startBlock, mergeSegment.blocks[0])

                s.blocks.splice(startBlockIndex + 1, 1)
                endBlockIndex -= 1
            } else if (doesFirstMergeBlockHaveOverlapWithStartBlock && !isStartOfStartBlock) {
                const overlapStartIndex = api.getIndexForTiming(
                    startBlock,
                    mergeSegmentTiming.start,
                    'start',
                )
                const overlapEndIndex =
                    api.getIndexForTiming(startBlock, startBlockTiming.end, 'end') + 1
                api.mergeBlock(startBlock, emptyBlock, overlapStartIndex, overlapEndIndex)
            } else if (shouldMergeStartBlockWithoutOverlap) {
                mergeWithoutOverlap(startBlock, mergeSegment.blocks[0])
                s.blocks.splice(startBlockIndex + 1, 1)
                endBlockIndex -= 1
            }

            const shouldMergeEndBlock =
                !!endBlock && !isEndOfEndBlock && endBlockTiming.end >= mergeSegmentTiming.end
            if (shouldMergeEndBlock && endBlockIndex > 0) {
                mergeRight(s.blocks[endBlockIndex - 1], endBlock)

                s.blocks.splice(endBlockIndex, 1)
            }
        }

        api.refreshSegment(s)

        return s
    },

    slice: <B extends BaseSegmentBlock, S extends BaseSegment<B> = BaseSegment<B>>(
        segment: S,
        startBlockIndex: number,
        endBlockIndex: number,
    ): S => {
        segment.blocks = segment.blocks.slice(startBlockIndex, endBlockIndex + 1)
        BaseSegment.refresh(segment)

        return segment
    },
}
