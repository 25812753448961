import { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'
import useDidMount from 'beautiful-react-hooks/useDidMount'
import styled from 'styled-components/macro'
import { palette, theme } from 'styled-tools'
import { filter, flow } from 'lodash/fp'

import { useMousetrap } from 'src/hooks/useMousetrap'
import { usePrevious } from 'src/hooks/usePrevious'
import { useSpeakerVoiceSamplePlayer } from 'src/state/SpeakerVoiceSamplePlayerProvider'
import { useTaskValidator } from 'src/state/TaskValidationProvider'
import { isMac } from 'src/utils/platform'
import { TimingRange } from 'src/utils/range'

import { PlayButton, BackwardButton, ForwardButton, VolumeButton, ReplayButton } from './buttons'
import { PlaybackSpeedButton } from './PlaybackSpeedButton'
import { AudioTrack } from './AudioTrack'
import { useSessionMedia } from 'src/state/SessionMediaProvider'
import { useTaskMediaPlayer } from 'src/state/TaskAudioContext'
import { useExactAudioTimeUpdateListener } from 'src/factories/MediaService'

export const FORWARD_BACKWARD_JUMP_IN_SECONDS = 2
export const AUDIO_OFFSET_FOR_TASK_START_IN_SECONDS = 1.5
export const PLAYBACK_SPEEDS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 4]
export const UNPLAYED_TIME_RANGE_THRESHOLD = 0.5

const Strip = styled.div<{ isLeftSideBarVisible?: boolean }>`
    display: flex;
    background-color: ${palette('white', 0)};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    height: ${theme('sizes.audioPlayerHeight')}px;
    z-index: ${theme('zIndexes.audioPlayer')};
    border-top: solid 1px ${palette('cloudBlueLight', 6)};
    width: 100%;
`

const ControlsSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;

    &:first-of-type {
        border-right: solid 1px ${palette('cloudBlue', 0)};
    }

    &:last-of-type {
        border-left: solid 1px ${palette('cloudBlue', 0)};
    }
`

interface AudioPlayerProps {
    isLeftSideBarVisible?: boolean
    isRuntimeTimestampsVisible?: boolean
}

export const AudioPlayer = ({
    isLeftSideBarVisible,
    isRuntimeTimestampsVisible,
}: AudioPlayerProps) => {
    const [playbackSpeed, setPlaybackSpeed] = useState(1)
    const [displayUnplayedTimeRanges, setDisplayUnplayedTimeRanges] = useState(false)
    const speakerAudioPlayer = useSpeakerVoiceSamplePlayer()
    const {
        mediaRef,
        isMediaPlaying,
        play,
        pause,
        seekTime,
        togglePlayPause,
        mediaTiming,
        currentTime,
    } = useSessionMedia([
        'mediaRef',
        'isMediaPlaying',
        'play',
        'pause',
        'seekTime',
        'togglePlayPause',
        'mediaTiming',
        'currentTime',
    ])
    const { isPlayerVisible, setIsPlayerVisible } = useTaskMediaPlayer([
        'isPlayerVisible',
        'setIsPlayerVisible',
    ])

    const [exactCurrentTime, setExactCurrentTime] = useState(currentTime)
    useExactAudioTimeUpdateListener(mediaRef, isMediaPlaying, currentTime, setExactCurrentTime)

    const previsMediaPlaying = usePrevious(isMediaPlaying)
    const unplayedTimeRanges = useUnplayedTimeRanges()
    const initialVolume = mediaRef.current?.volume === 0 ? 0 : mediaRef.current?.volume || 1
    const [volumeValue, setVolumeValue] = useState(initialVolume)

    const seekBackward = useCallback(() => {
        if (mediaTiming) {
            seekTime(
                Math.max(
                    mediaTiming.timing.start,
                    exactCurrentTime - FORWARD_BACKWARD_JUMP_IN_SECONDS,
                ),
            )
        }
    }, [mediaTiming, exactCurrentTime, seekTime])
    const seekForward = useCallback(() => {
        if (mediaTiming) {
            seekTime(
                Math.min(
                    mediaTiming.timing.end,
                    exactCurrentTime + FORWARD_BACKWARD_JUMP_IN_SECONDS,
                ),
            )
        }
    }, [mediaTiming, exactCurrentTime, seekTime])

    const onTrackTimeUpdate = useCallback(
        (time: number) => {
            if (mediaTiming) {
                const absoluteTime = mediaTiming.timing.start + time
                seekTime(absoluteTime)
            }
        },
        [mediaTiming, seekTime],
    )

    const updateVolume = useCallback(
        (volume: number) => {
            const valueToSet = Number(volume.toFixed(1))
            setVolumeValue(valueToSet)

            if (mediaRef.current && valueToSet <= 1) {
                mediaRef.current.volume = valueToSet
            }
        },
        [mediaRef],
    )

    useEffect(() => {
        if (isMediaPlaying) {
            speakerAudioPlayer.pause()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMediaPlaying])

    useEffect(() => {
        if (!mediaRef.current) return
        mediaRef.current.playbackRate = playbackSpeed
    }, [playbackSpeed, mediaRef])

    const playFromEditableSegment = useCallback(() => {
        if (mediaTiming) {
            seekTime(mediaTiming.requiredRangeToBeBuffered.start)

            if (!isMediaPlaying) {
                togglePlayPause()
            }
        }
    }, [seekTime, mediaTiming, isMediaPlaying, togglePlayPause])

    const increasePlaybackSpeed = useCallback(() => {
        const currentSpeedIndex = PLAYBACK_SPEEDS.indexOf(playbackSpeed)
        const nextSpeedIndex = Math.min(currentSpeedIndex + 1, PLAYBACK_SPEEDS.length - 1)
        setPlaybackSpeed(PLAYBACK_SPEEDS[nextSpeedIndex])
    }, [playbackSpeed])
    const decreasePlaybackSpeed = useCallback(() => {
        const currentSpeedIndex = PLAYBACK_SPEEDS.indexOf(playbackSpeed)
        const nextSpeedIndex = Math.max(currentSpeedIndex - 1, 0)
        setPlaybackSpeed(PLAYBACK_SPEEDS[nextSpeedIndex])
    }, [playbackSpeed])

    // when audio player unmounts, pause the audio so the state doesn't choke
    useWillUnmount(pause)

    // Keyboard shortcuts
    useMousetrap(isMac ? 'alt+space' : 'ctrl+space', togglePlayPause, {
        label: 'Audio: Play/Pause',
        preventDefault: true,
        stopImmediatePropagation: true,
    })
    useMousetrap('mod+left', seekBackward, {
        label: 'Audio: Seek backward',
        preventDefault: true,
        stopImmediatePropagation: true,
        allowRepeat: true,
    })
    useMousetrap('mod+right', seekForward, {
        label: 'Audio: Seek forward',
        preventDefault: true,
        stopImmediatePropagation: true,
        allowRepeat: true,
    })
    useMousetrap('alt+s', playFromEditableSegment, {
        label: 'Audio: Play from editable start',
        preventDefault: true,
        stopImmediatePropagation: true,
    })
    useMousetrap('alt+.', increasePlaybackSpeed, {
        label: 'Audio: Increase playback speed',
        preventDefault: true,
        stopImmediatePropagation: true,
    })
    useMousetrap('alt+,', decreasePlaybackSpeed, {
        label: 'Audio: Decrease playback speed',
        preventDefault: true,
        stopImmediatePropagation: true,
    })

    const replayAfterSeekEndRef = useRef(false)
    const onAudioTrackSeekStart = useCallback(() => {
        if (!mediaRef.current?.paused) {
            pause()
            replayAfterSeekEndRef.current = true
        }
    }, [mediaRef, pause])
    const onAudioTrackSeekEnd = useCallback(() => {
        if (replayAfterSeekEndRef.current) {
            play()
            replayAfterSeekEndRef.current = false
        }
    }, [play])

    const [requiredRangeFullyPlayed, resetRequiredRangeFullyPlayed] = useTaskValidator(
        'requiredRangeFullyPlayed',
        () => {
            // if (unplayedTimeRanges.length) {
            //     LogRocket.log('unplayedTimeRanges', unplayedTimeRanges)
            // }

            return !mediaTiming?.requiredRangeToBePlayed || !unplayedTimeRanges.length
        },
    )

    useEffect(() => {
        if (requiredRangeFullyPlayed === false) {
            setDisplayUnplayedTimeRanges(true)

            if (isMediaPlaying) {
                if (previsMediaPlaying) {
                    pause()
                } else {
                    resetRequiredRangeFullyPlayed()
                }
            }
        }
    }, [
        isMediaPlaying,
        pause,
        previsMediaPlaying,
        requiredRangeFullyPlayed,
        resetRequiredRangeFullyPlayed,
    ])

    useEffect(() => {
        if (requiredRangeFullyPlayed === false) {
            if (unplayedTimeRanges[0]) {
                seekTime(unplayedTimeRanges[0].start - 1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredRangeFullyPlayed, seekTime])

    useDidMount(() => setIsPlayerVisible(true))
    useWillUnmount(() => setIsPlayerVisible(false))

    if (!mediaTiming || !isPlayerVisible) {
        return null
    }

    return (
        <Strip isLeftSideBarVisible={isLeftSideBarVisible}>
            <ControlsSection>
                <PlayButton playing={isMediaPlaying} onClick={togglePlayPause} />
                <ReplayButton onClick={playFromEditableSegment} />
                <BackwardButton onClick={seekBackward} />
                <ForwardButton onClick={seekForward} />
            </ControlsSection>

            <AudioTrack
                duration={mediaTiming.duration}
                currentTime={exactCurrentTime - mediaTiming.timing.start}
                activeSegment={{ timing: mediaTiming.activeSegment, color: 'lightblue' }}
                onTrackTimeUpdate={onTrackTimeUpdate}
                onSeekStart={onAudioTrackSeekStart}
                onSeekEnd={onAudioTrackSeekEnd}
                mediaTiming={mediaTiming}
                unplayedTimeRanges={displayUnplayedTimeRanges ? unplayedTimeRanges : undefined}
                displayUnplayedTimeRangesWarning={requiredRangeFullyPlayed === false}
                isRuntimeTimestampsVisible={isRuntimeTimestampsVisible}
            />

            <ControlsSection>
                <PlaybackSpeedButton
                    speedOptions={PLAYBACK_SPEEDS}
                    selectedSpeed={playbackSpeed}
                    onChange={setPlaybackSpeed}
                />

                <VolumeButton
                    isMuted={volumeValue === 0}
                    value={volumeValue}
                    onChange={(sliderValue) => {
                        updateVolume(sliderValue)
                    }}
                />
            </ControlsSection>
        </Strip>
    )
}

export const useUnplayedTimeRanges = () => {
    const { mediaTiming } = useSessionMedia(['mediaTiming'])
    const { playedTimeRanges } = useTaskMediaPlayer(['playedTimeRanges'])

    return useMemo(() => {
        if (!mediaTiming?.requiredRangeToBePlayed) {
            return []
        }

        const { requiredRangeToBePlayed } = mediaTiming

        return flow(
            TimingRange.resolveOverlappedRanges,
            (ranges) => TimingRange.getDiffRanges(ranges, requiredRangeToBePlayed),
            filter((range) => TimingRange.duration(range) > UNPLAYED_TIME_RANGE_THRESHOLD),
        )(playedTimeRanges)
    }, [playedTimeRanges, mediaTiming])
}
