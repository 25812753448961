import { useEffect, useMemo } from 'react'
import { Editor, Transforms } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled from 'styled-components'
import { ifProp } from 'styled-tools'
import { Overlay } from '@blueprintjs/core'
import { Intent } from '@blueprintjs/core'

import { GlossaryList } from 'src/components/Glossary/GlossaryList'
import { useToast } from 'src/components/Toasts/ToastContext'
import { useTaskSubmissionStall } from 'src/components/TaskRouter/TaskState'
import { useAbsolutePosition } from 'src/hooks/useAbsolutePosition'
import { GlossaryOpenSource, useAnalytics } from 'src/analytics'

import { Tag } from './Tag'
import { ZERO_WIDTH_WHITESPACE_REGEX } from 'src/utils/string'
import { Suggestion } from 'src/components/Editor/plugins/withSuggestions/Suggestion'
import { Block } from 'src/components/Editor/plugins/withTranscript'
import { useEditorMeta, useEditorPopup } from 'src/components/Editor/EditorContext'

const GLOSSARY_MAX_HEIGHT = 375
const GLOSSARY_SUBMENU_MAX_HEIGHT = 160

interface PopupProps {
    top?: number
    bottom?: number
    left?: number
    right?: number
}

const Bold = styled.span`
    font-weight: bold;
`

const PopupContainer = styled.div<PopupProps>`
    width: 250px;
    position: absolute;
    top: ${ifProp('top', (props) => `${props.top}px`, 'auto')};
    bottom: ${ifProp('bottom', (props) => `${props.bottom}px`, 'auto')};
    right: ${ifProp('right', (props) => `${props.right}px`, 'auto')};
    left: ${ifProp('left', (props) => `${props.left}px`, 'auto')};
`

export function GlossaryPopup() {
    const editor = useSlateStatic()
    const analytics = useAnalytics()
    const addToast = useToast()
    const { taskId } = useEditorMeta()

    const { popupRange: currentRangeRef, popupId, closePopup } = useEditorPopup()
    const isOpen = popupId === 'glossary'
    const onClose = () => closePopup(true)

    const addTaskSubmissionStallingPromise = useTaskSubmissionStall()

    useEffect(() => {
        if (isOpen) {
            // find the first and the last editable block
            const [[, firstPath]] = Editor.nodes(editor, {
                at: [],
                match: (n) => Block.isBlock(n) && n.segmentType === 'editable',
            })
            const [[, lastPath]] = Editor.nodes(editor, {
                at: [],
                match: (n) => Block.isBlock(n) && n.segmentType === 'editable',
                reverse: true,
            })

            const editableContents = Editor.string(editor, {
                anchor: Editor.start(editor, firstPath),
                focus: Editor.end(editor, lastPath),
            })

            let glossaryOpenSource = GlossaryOpenSource.EDITOR_WITH_TEXT
            if (editableContents === '') {
                glossaryOpenSource = GlossaryOpenSource.EDITOR_EMPTY
            }
            analytics?.sendOpenGlossary(taskId ?? '', glossaryOpenSource)
        }
    }, [analytics, editor, isOpen, taskId])

    const selectedText = useMemo(() => {
        if (!currentRangeRef?.current) {
            return ''
        }

        return Editor.string(editor, currentRangeRef.current).replace(
            ZERO_WIDTH_WHITESPACE_REGEX,
            '',
        )
    }, [currentRangeRef, editor])

    const position = useAbsolutePosition(currentRangeRef?.current, GLOSSARY_MAX_HEIGHT)

    return (
        <Overlay
            isOpen={isOpen}
            transitionDuration={-1}
            transitionName="none"
            backdropClassName="popup-overlay-backdrop"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            onClose={onClose}
        >
            <PopupContainer {...position}>
                <GlossaryList
                    source="Context Menu"
                    submenuSide={'right'}
                    maxHeight={GLOSSARY_MAX_HEIGHT}
                    subMenuMaxHeight={GLOSSARY_SUBMENU_MAX_HEIGHT}
                    initialSearchTerm={selectedText}
                    onClose={onClose}
                    onTermSelected={(term, promise) => {
                        if (currentRangeRef?.current) {
                            Suggestion.unwrapSuggestion(editor, { at: currentRangeRef.current })

                            if (Tag.hasTag(editor, { at: currentRangeRef.current })) {
                                Tag.unwrapTag(editor, { at: currentRangeRef.current })
                            }
                            const tagSeq = Tag.insertTag(
                                editor,
                                {
                                    type: 'glossary',
                                    glossary: term,
                                },
                                {
                                    at: currentRangeRef.current,
                                    contents: term.text,
                                },
                            )
                            ReactEditor.focus(editor)

                            // if its a new term, add its promise to the list of promisses, that will stall the sumission until they are resolved to avoid temporary ids
                            if (promise) {
                                addTaskSubmissionStallingPromise(promise)
                            }

                            promise
                                ?.then((glossaryItemId) => {
                                    if (glossaryItemId) {
                                        Transforms.setNodes(
                                            editor,
                                            {
                                                glossary: {
                                                    ...term,
                                                    id: glossaryItemId,
                                                },
                                            },
                                            {
                                                at: [],
                                                match: (n) =>
                                                    Tag.isGlossaryTag(n) && n.seq === tagSeq,
                                            },
                                        )
                                    }
                                })
                                .catch(() => {
                                    Transforms.unwrapNodes(editor, {
                                        at: [],
                                        match: (n) => Tag.isTag(n) && n.seq === tagSeq,
                                    })

                                    addToast({
                                        intent: Intent.DANGER,
                                        message: (
                                            <span>
                                                <Bold>{term.text}</Bold> could not be added.
                                            </span>
                                        ),
                                    })
                                })
                        }

                        onClose()
                    }}
                />
            </PopupContainer>
        </Overlay>
    )
}
