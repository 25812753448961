import styled from 'styled-components/macro'
import Draggable from 'react-draggable'

import { MockManager, MockManagerMode } from '../MockManager'
import { MockReplayController } from './MockReplayController'
import { MockRecordController } from './MockRecordController'

const AbsoluteContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 999999;

    background: #fff;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgb(0 0 0 / 25%);

    display: flex;
`

type MockControlsProps = {
    manager: MockManager
}

export function MockControls({ manager }: MockControlsProps) {
    if (!manager.isActive()) {
        return null
    }

    return (
        <Draggable>
            <AbsoluteContainer>
                {manager.getMode() === MockManagerMode.Replay && (
                    <MockReplayController manager={manager} />
                )}
                {manager.getMode() === MockManagerMode.Record && (
                    <MockRecordController manager={manager} />
                )}
            </AbsoluteContainer>
        </Draggable>
    )
}
