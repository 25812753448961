import { useCallback, useEffect, useMemo, useState, ReactNode } from 'react'
import { Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import useWillUnmount from 'beautiful-react-hooks/useWillUnmount'
import { Position, Icon } from '@blueprintjs/core'
import {
    ItemRenderer,
    ItemPredicate,
    Select,
    ItemListRenderer,
    ItemsEqualComparator,
} from '@blueprintjs/select'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'
import styled, { css, useTheme } from 'styled-components/macro'
import { ifProp, palette, prop } from 'styled-tools'

import { Speaker } from 'src/models'
import { useSpeakerRolesByRole, useSpeakers, useSpeakersById } from 'src/state/SpeakersProvider'
import {
    useSpeakerVoiceSamplePlayer,
    useToggleSpeakerVoiceSample,
} from 'src/state/SpeakerVoiceSamplePlayerProvider'
import { getDefaultVoiceSampleId, getSpeakerColor, isVoiceSamplePlaying } from 'src/utils/speaker'
import { trimText } from 'src/utils/string'
import { SelectButton, SelectMenu, SelectMenuItem } from 'src/components/Common/styled'
import * as Icons from 'src/components/icons'

import { SpeakerMenuItem } from './SpeakerMenuItem'

function escapeRegExpChars(text: string) {
    return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
}

function highlightText(text: string, query: string) {
    let lastIndex = 0
    const words = query
        .split(/\s+/)
        .filter((word) => word.length > 0)
        .map(escapeRegExpChars)
    if (words.length === 0) {
        return [text]
    }
    const regexp = new RegExp(words.join('|'), 'gi')
    const tokens: ReactNode[] = []
    while (true) {
        const match = regexp.exec(text)
        if (!match) {
            break
        }
        const length = match[0].length
        const before = text.slice(lastIndex, regexp.lastIndex - length)
        if (before.length > 0) {
            tokens.push(before)
        }
        lastIndex = regexp.lastIndex
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>)
    }
    const rest = text.slice(lastIndex)
    if (rest.length > 0) {
        tokens.push(rest)
    }
    return tokens
}

enum ActionItem {
    MarkSpeakerAsUnknown = 'RESET_SPEAKER',
    CreateSpeaker = 'CREATE_SPEAKER',
}

type SpeakerSelectItem = ActionItem | Speaker

const filterItem: ItemPredicate<SpeakerSelectItem> = (query, item, i, exactMatch) => {
    if (item === ActionItem.MarkSpeakerAsUnknown) {
        return true
    }

    if (item === ActionItem.CreateSpeaker) {
        return true
    }

    const { name } = item
    const normalizedSpeaker = name.toLowerCase()
    const normalizedQuery = trimText(query.toLowerCase())

    if (exactMatch) {
        return normalizedSpeaker === normalizedQuery
    }

    return normalizedSpeaker.indexOf(normalizedQuery) >= 0
}

const isSpeakerItem = (maybeSpeaker: SpeakerSelectItem): maybeSpeaker is Speaker =>
    (maybeSpeaker as Speaker).id !== undefined

const itemsEqual: ItemsEqualComparator<SpeakerSelectItem> = (itemA, itemB) => {
    if (isSpeakerItem(itemA) && isSpeakerItem(itemB)) {
        return itemA.id === itemB.id
    }

    if (!isSpeakerItem(itemA) && !isSpeakerItem(itemB)) {
        return itemA === itemB
    }

    return false
}

const SpeakerSelectContainer = styled.div`
    width: 148px;
    height: 100%;

    display: flex;
    align-items: center;
    outline: none;

    border: solid 1px transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`

const TypedSelect = styled(Select.ofType<SpeakerSelectItem>())`
    border: solid 1px transparent;
    &.bp4-popover-wrapper {
        flex: 1;
        overflow: hidden;

        .bp4-popover-target {
            flex: 1;

            > div {
                flex: 1;
                overflow: hidden;
            }
        }
    }

    &:active,
    &:focus-visible,
    &:focus-within {
        border: solid 1px ${palette('blue', 0)};
        border-color: ${palette('blue', 0)} !important;
        border-radius: 4px;
    }
`

/*
The min height used to be hardcoded as 40px; The font-size this was used at is 15px.
Now the on boarding tasks 'transcript_intro' + 'transcript_latest' have a lower font-size so the speaker select box looks huge.
To prevent this, we now set the height of the select box relative to the font-size using the em unit. It is calculated by the original ratio.
*/
const StyledSelectButton = styled(SelectButton)<{ color?: string; $isRealTimeReadOnly?: boolean }>`
    position: relative;

    &.bp4-button {
        min-height: ${7 / 3}em;
        font-size: inherit;
    }

    .bp4-button-text {
        flex: 1;
        margin-right: 0;
        padding-left: ${ifProp('color', 18, 3)}px;
        white-space: pre;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: ${15 / 18}em;
        line-height: 20px;
    }

    ${ifProp(
        'color',
        css`
            &:before {
                content: '';
                position: absolute;
                top: ${ifProp('$isRealTimeReadOnly', 15, 0)}px;
                bottom: 0;
                left: 0;
                border-radius: 1px;
                width: ${ifProp('$isRealTimeReadOnly', 10, 6)}px;
                height: ${ifProp('$isRealTimeReadOnly', '10px', '100%')};
                background-color: ${prop('color')};
            }
        `,
    )}
`

const StyledSelectMenuItem = styled(SelectMenuItem)`
    padding: 0 12px;

    &:hover {
        background-color: unset !important;

        &.bp4-active {
            background-color: ${palette('cloudBlueLight', 2)} !important;
        }
    }
`

const ActionMenuItem = styled(StyledSelectMenuItem)`
    border-top: 1px solid ${palette('grey', 13)};
    color: ${palette('navy', 2)};

    svg {
        width: 18px;
        height: 18px;
        margin-right: 3px;
    }
`

const NoResultsLabel = styled.div`
    padding: 0 12px;
    font-size: 13px;
    color: ${palette('cloudBlue', 0)};
    margin-bottom: 10px;
`

const QueryLabel = styled.span`
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    margin-left: 2px;
`

const StyledSelectMenu = styled(SelectMenu)`
    --visible-option-count: 5.35;
    max-height: calc(${54}px * var(--visible-option-count));
    overflow-y: auto;

    @media (max-height: 900px) {
        --visible-option-count: 4.35;
    }

    @media (max-height: 800px) {
        --visible-option-count: 3.35;
    }
`

const ActionItems = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

const MARK_SPEAKER_AS_UNKNOWN_LABEL = "I don't know"

interface SpeakerSelectProps {
    element: Block
    editor: any
    selectedSpeakerId: string | null
    onChange: (speaker: Speaker | null) => void
    disabled?: boolean
    markedAsUnknown?: boolean
    isRealTimeReadOnly?: boolean
}

export const SpeakerSelect = ({
    element,
    editor,
    selectedSpeakerId,
    onChange,
    disabled,
    markedAsUnknown,
    isRealTimeReadOnly,
}: SpeakerSelectProps) => {
    const theme = useTheme()
    const speakerVoiceSamplePlayer = useSpeakerVoiceSamplePlayer()
    const toggleSpeakerVoiceSample = useToggleSpeakerVoiceSample()
    const [blockIndex] = ReactEditor.findPath(editor, element)
    const {
        speakers,
        setPendingSpeaker,
        clearPendingSpeaker,
        isSelectSpeakerPopupVisible,
        setIsSelectSpeakerPopupVisible,
    } = useSpeakers([
        'speakers',
        'setPendingSpeaker',
        'clearPendingSpeaker',
        'isSelectSpeakerPopupVisible',
        'setIsSelectSpeakerPopupVisible',
    ])
    const speakersById = useSpeakersById()
    const speakerRolesByRole = useSpeakerRolesByRole()
    const [activeItem, setActiveItem] = useState<SpeakerSelectItem | null>()
    const [query, setQuery] = useState('')
    const selectedSpeaker = selectedSpeakerId ? speakersById[selectedSpeakerId] : null

    const label = useMemo(() => {
        if (selectedSpeaker) {
            return selectedSpeaker.name
        }

        if (markedAsUnknown) {
            return MARK_SPEAKER_AS_UNKNOWN_LABEL
        }

        if (disabled) {
            return 'Speaker'
        }

        return 'Select Speaker'
    }, [disabled, markedAsUnknown, selectedSpeaker])

    const items = useMemo(() => {
        const nextItems: SpeakerSelectItem[] = [...speakers]

        if (!markedAsUnknown) {
            nextItems.push(ActionItem.MarkSpeakerAsUnknown)
        }

        if (!query || !speakers.find(({ name }) => name === query)) {
            nextItems.push(ActionItem.CreateSpeaker)
        }

        return nextItems
    }, [speakers, markedAsUnknown, query])

    const renderItem = useCallback<ItemRenderer<SpeakerSelectItem>>(
        (item, { handleClick, modifiers, query }) => {
            if (!modifiers.matchesPredicate) {
                return null
            }

            if (item === ActionItem.MarkSpeakerAsUnknown) {
                return (
                    <ActionMenuItem
                        key={ActionItem.MarkSpeakerAsUnknown}
                        onClick={handleClick}
                        onMouseMove={() => setActiveItem(item)}
                        active={modifiers.active}
                        textClassName="content"
                        icon={<Icons.QuestionMarkIcon color={theme.palette.cloudBlue[2]} />}
                        text={MARK_SPEAKER_AS_UNKNOWN_LABEL}
                    />
                )
            }

            if (item === ActionItem.CreateSpeaker) {
                return (
                    <ActionMenuItem
                        key={ActionItem.CreateSpeaker}
                        onClick={handleClick}
                        onMouseMove={() => setActiveItem(item)}
                        active={modifiers.active}
                        textClassName="content"
                        shouldDismissPopover={false}
                        icon={
                            <Icons.PlusIcon
                                color={
                                    modifiers.active ? theme.palette.blue[0] : theme.palette.navy[2]
                                }
                            />
                        }
                        text={
                            query ? (
                                <>
                                    Add:<QueryLabel>{query}</QueryLabel>
                                </>
                            ) : (
                                'Add New Speaker'
                            )
                        }
                    />
                )
            }

            const { id, name, role, samples } = item
            const color = getSpeakerColor(item, speakerRolesByRole)
            const { currentPlayingVoiceSample, isBuffering } = speakerVoiceSamplePlayer
            const defaultVoiceSampleId = getDefaultVoiceSampleId(samples)
            const isDefaultSamplePlaying = isVoiceSamplePlaying(
                id,
                defaultVoiceSampleId,
                currentPlayingVoiceSample,
            )

            return (
                <StyledSelectMenuItem
                    key={id}
                    onClick={handleClick}
                    onMouseMove={() => setActiveItem(item)}
                    active={modifiers.active}
                    disabled={modifiers.disabled}
                    textClassName="content"
                    text={
                        <SpeakerMenuItem
                            onToggleVoiceSample={toggleSpeakerVoiceSample}
                            speakerId={id}
                            name={highlightText(name, query)}
                            role={role}
                            color={color}
                            voiceSampleId={defaultVoiceSampleId}
                            isVoiceSamplePlaying={isDefaultSamplePlaying}
                            isVoiceSampleBuffering={isDefaultSamplePlaying && isBuffering}
                        />
                    }
                />
            )
        },
        [speakerVoiceSamplePlayer, toggleSpeakerVoiceSample, speakerRolesByRole, theme],
    )

    const renderMenu: ItemListRenderer<SpeakerSelectItem> = useCallback(
        ({ filteredItems, itemsParentRef, renderItem, query }) => {
            const speakers = filteredItems.filter(isSpeakerItem)
            const actionItems = filteredItems.filter((item) => !isSpeakerItem(item))

            return (
                <>
                    <StyledSelectMenu ulRef={itemsParentRef}>
                        {query && !filteredItems.find(isSpeakerItem) && (
                            <NoResultsLabel>No matches</NoResultsLabel>
                        )}
                        {speakers.map(renderItem)}
                    </StyledSelectMenu>

                    <ActionItems>
                        {actionItems.map((item, i) => renderItem(item, i + speakers.length))}
                    </ActionItems>
                </>
            )
        },
        [],
    )

    const onItemSelect = useCallback(
        (item: SpeakerSelectItem) => {
            if (item === ActionItem.MarkSpeakerAsUnknown) {
                onChange(null)
                return
            }

            if (item === ActionItem.CreateSpeaker) {
                setPendingSpeaker({
                    pendingSpeaker: { name: query, role: '', samples: {} },
                    onCreate: onChange,
                })
                return
            }

            onChange(item)
        },
        [setPendingSpeaker, onChange, query],
    )

    useEffect(() => {
        const speakerResults = speakers.filter((s) => filterItem(query, s))

        setActiveItem(speakerResults.length ? speakerResults[0] : ActionItem.CreateSpeaker)
    }, [query, speakers])

    useEffect(() => {
        if (isSelectSpeakerPopupVisible) {
            const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
            const [positionIndex] = blockPath

            const element = document.getElementsByClassName(
                `btn-speaker-select-${positionIndex}`,
            ) as HTMLSelectElement
            if (element) {
                if (element.length > 0) {
                    element?.[0]?.click()
                    setIsSelectSpeakerPopupVisible && setIsSelectSpeakerPopupVisible(false)
                }
            }
        }
    }, [editor, isSelectSpeakerPopupVisible, setIsSelectSpeakerPopupVisible])

    useWillUnmount(clearPendingSpeaker)

    return (
        <SpeakerSelectContainer>
            <TypedSelect
                key={blockIndex}
                query={query}
                onQueryChange={setQuery}
                activeItem={activeItem}
                onActiveItemChange={setActiveItem}
                resetOnQuery={false}
                items={items}
                itemsEqual={itemsEqual}
                itemPredicate={filterItem}
                itemRenderer={renderItem}
                itemListRenderer={renderMenu}
                onItemSelect={onItemSelect}
                className="select-control"
                disabled={disabled}
                resetOnClose
                inputProps={{ placeholder: 'Create/Filter', leftIcon: null }}
                popoverProps={{
                    popoverClassName: 'trax-select-popover',
                    position: Position.BOTTOM_LEFT,
                    minimal: true,
                    modifiers: {
                        offset: { offset: '1,4' },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false },
                    },
                    onClosed: () => {
                        speakerVoiceSamplePlayer.pause()

                        if (
                            document.activeElement instanceof HTMLElement &&
                            !(document.activeElement instanceof HTMLInputElement)
                        ) {
                            document.activeElement.blur()
                        }
                    },
                    onOpening: () => setActiveItem(selectedSpeaker ?? speakers[0]),
                }}
            >
                <StyledSelectButton
                    id={`${'btn-speaker-select-' + blockIndex}`}
                    data-testid={'speaker-select-button'}
                    rightIcon="caret-down"
                    disabled={disabled}
                    $isRealTimeReadOnly={isRealTimeReadOnly}
                    $hasValue={!!selectedSpeaker || markedAsUnknown}
                    className={`${'btn-speaker-select-' + blockIndex}`}
                    color={
                        selectedSpeaker
                            ? getSpeakerColor(selectedSpeaker, speakerRolesByRole)
                            : 'FFF'
                    }
                    $isSpeakerSelection={true}
                >
                    {label}
                </StyledSelectButton>
            </TypedSelect>
        </SpeakerSelectContainer>
    )
}
