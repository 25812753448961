import { useContext, useMemo, createContext, ReactChild } from 'react'

import { useMachineDeps } from '../useMachineDeps'
import { LiveMachineType, LiveStateSchema } from './LiveMachine'
import { useAppMachine } from '../AppMachine/AppMachineProvider'

export const LiveMachineContext = createContext<{
    machine?: LiveMachineType
} | null>(null)

interface LiveMachineProviderProps {
    children: ReactChild
}

export function useIsLiveMachineActive() {
    const liveMachineContextProps = useContext(LiveMachineContext)

    return Boolean(liveMachineContextProps?.machine)
}

export function LiveMachineProvider({ children }: LiveMachineProviderProps) {
    const [, , appMachine] = useAppMachine(['state'])
    const service = appMachine.children.get('live-machine') as LiveMachineType | undefined
    const value = useMemo(() => ({ machine: service }), [service])

    // @ts-ignore
    window.sendLiveEvent = service?.send

    return <LiveMachineContext.Provider value={value}>{children}</LiveMachineContext.Provider>
}

type LiveMachineProperties = 'state' | keyof LiveMachineType['state']['context']

export type LiveMachineState = LiveMachineType['state'] & { value: keyof LiveStateSchema['states'] }

export function useLiveMachine(deps: LiveMachineProperties[]) {
    const { machine } = useContext(LiveMachineContext) || {}

    if (!machine) throw new Error('You forgot to use <LiveMachineProvider />.')

    useMachineDeps(machine, deps)

    return [machine.state as LiveMachineState, machine.send, machine] as const
}
