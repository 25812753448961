import { Editor } from 'slate'

export function withGlossers(editor: Editor) {
    const { insertText } = editor

    editor.insertText = (text) => {
        // MacOS has a default behaviour of inserting a period when the space key is double clicked .
        // It can be avoided only in the OS native settings- so we need to not allow this text to be inserted in Glossers mode.
        // Other text might be accepted even though this mode is some kind of "readonly mode"- because of the behaviour of capitalizing first letters when merging nodes
        if (text !== '. ') {
            insertText(text)
        }
    }
    return editor
}
