import { NodeEntry, Path } from 'slate'

import { isPunctuation } from '../../../../utils/string'
import { ContentText } from 'src/components/Editor/plugins/withTranscript'

export const isThereSpaceBetweenCurrentAndPreviousNodes = (
    currentNodeEntry: NodeEntry,
    previousNodeEntry: NodeEntry | undefined,
) => {
    if (!previousNodeEntry) {
        return true
    }
    const [currentNode, currentNodePath] = currentNodeEntry
    const [previousNode, previousNodePath] = previousNodeEntry
    const previousNodeTextLength = (previousNode as ContentText)?.text.length
    const firstCharacterOfCurrentNode = (currentNode as ContentText)?.text[0]
    const lastCharacterOfPreviousNode = (previousNode as ContentText)?.text[
        previousNodeTextLength - 1
    ]
    return (
        !Path.isSibling(currentNodePath, previousNodePath) ||
        !previousNode ||
        !lastCharacterOfPreviousNode ||
        firstCharacterOfCurrentNode === ' ' ||
        isPunctuation(firstCharacterOfCurrentNode) ||
        lastCharacterOfPreviousNode === ' ' ||
        (lastCharacterOfPreviousNode && isPunctuation(lastCharacterOfPreviousNode))
    )
}

export const isThereSpaceBetweenCurrentAndNextNodes = (
    currentNodeEntry: NodeEntry,
    nextNodeEntry: NodeEntry | undefined,
) => {
    if (!nextNodeEntry) {
        return true
    }
    const [currentNode, currentNodePath] = currentNodeEntry
    const [nextNode, nextNodePath] = nextNodeEntry
    const currentNodeTextLength = (currentNode as ContentText).text.length
    const lastCharacterOfCurrentNode = (currentNode as ContentText)?.text[currentNodeTextLength - 1]
    const firstCharacterOfNextNode = (nextNode as ContentText)?.text[0]
    return (
        !Path.isSibling(currentNodePath, nextNodePath) ||
        !nextNode ||
        lastCharacterOfCurrentNode === ' ' ||
        isPunctuation(lastCharacterOfCurrentNode) ||
        firstCharacterOfNextNode === ' ' ||
        (firstCharacterOfNextNode && isPunctuation(firstCharacterOfNextNode))
    )
}
