import { BaseSyntheticEvent, useEffect, useRef } from 'react'
import { Editor, Range, Path } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { ifProp, palette, theme } from 'styled-tools'
import { Icon, Intent, Overlay } from '@blueprintjs/core'
import styled from 'styled-components'
import disableScroll from 'disable-scroll'
import { useKeyPressEvent } from 'react-use'

import { TranscriptionTask as TranscriptionTaskType } from 'src/models'
import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useAnalytics } from 'src/analytics'
import { useAbsolutePosition, AbsolutePosition } from 'src/hooks/useAbsolutePosition'
import { KeyNumericCodes } from 'src/utils/keyboard'
import { CloseIcon } from 'src/components/icons'

import { useToast } from 'src/components/Toasts/ToastContext'
import { didEventOccurInsideTheEditorNodes } from 'src/components/Editor/utils'
import { useEditorPopup } from 'src/components/Editor/EditorContext'
import { ValidationMessage, Validations } from '.'
import { ContentText } from '../withTranscript'

const VALIDATION_POPUP_MAX_HEIGHT = 375

interface PopupProps {
    top?: number
    bottom?: number
    left?: number
    right?: number
}

export type InvalidMessageData = {
    validationName: string
} & ValidationMessage

const popupSpace = '20px'
const popupBorderWidth = '1px'
const popupBorderRadius = '2px'
const popupHeaderHeight = '35px'
const popupTopLineHeight = '5px'
const popupFooterHeight = '40px'

const PopupContainer = styled.div<PopupProps>`
    position: absolute;
    top: ${ifProp('top', (props) => `${props.top}px`, 'auto')};
    bottom: ${ifProp('bottom', (props) => `${props.bottom}px`, 'auto')};
    right: ${ifProp('right', (props) => `${props.right}px`, 'auto')};
    left: ${ifProp('left', (props) => `${props.left}px`, 'auto')};
    min-width: 200px;
    max-width: 270px;
    min-height: 100px;
    background-color: ${palette('white', 0)};
    border-radius: ${popupBorderRadius};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid ${popupBorderWidth} ${palette('cloudBlueLight', 0)};
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(${popupBorderWidth} * -1);
        left: calc(${popupBorderWidth} * -1);
        width: calc(100% + ${popupBorderWidth} * 2);
        height: ${popupTopLineHeight};
        background-color: ${palette('orange', 0)};
        opacity: 0.5;
        border-top-left-radius: ${popupBorderRadius};
        border-top-right-radius: ${popupBorderRadius};
    }
    &.bp4-overlay-content {
        z-index: ${theme('zIndexes.popovers')};
    }
`
const PopupHeader = styled.div`
    position: relative;
    height: ${popupHeaderHeight};
    line-height: ${popupHeaderHeight};
    padding: 2px 30px 0 ${popupSpace};
    border-bottom: 1px solid ${palette('cloudBlueLight', 5)};
    font-size: 12px;
    font-weight: 700;
    color: ${palette('greyBlue', 0)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:first-letter {
        text-transform: capitalize;
    }
`
const StyledCloseIcon = styled(CloseIcon)`
    position: absolute;
    top: calc(50% + (${popupTopLineHeight} / 2));
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
`

const PopupBody = styled.div`
    font-size: 14px;
    line-height: 1.5;
    padding: calc(${popupSpace} - 5px) 10px calc(${popupSpace} - 5px) ${popupSpace};
`
const PopupFooter = styled.footer`
    font-size: 14px;
    width: 100%;
    height: ${popupFooterHeight};
    padding-left: ${popupSpace};
    border-top: 1px solid ${palette('cloudBlueLight', 1)};
`
const PopupFooterButton = styled.button`
    line-height: ${popupFooterHeight};
    padding: 0;
    border: none;
    background: none;
    color: #3872dc;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
        color: #6f99e7;
    }
`

const Paragraph = styled.p`
    &:last-of-type {
        margin-bottom: 0;
    }
`

export function ValidationPopup() {
    const editor = useSlateStatic()
    const sessionStatus = useSessionStatus([
        'sessionStatus.customerId',
        'sessionStatus.platformJobId',
        'sessionStatus.sessionId',
    ])
    const analytics = useAnalytics()
    const addToast = useToast()

    const { popupRange: currentRange, popupId, closePopup } = useEditorPopup()
    const propsOnClose = () => closePopup(true)
    const isOpen = popupId === 'validation'

    const position: AbsolutePosition = useAbsolutePosition(
        currentRange?.current,
        VALIDATION_POPUP_MAX_HEIGHT,
    )
    const validationMessage = useRef<InvalidMessageData | null>(null)
    const task = useTask<TranscriptionTaskType>()
    const { customerId, platformJobId } = sessionStatus.sessionStatus || {}
    const analyticsMetadata = {
        customerId,
        sessionId: sessionStatus.sessionStatus?.sessionId,
        id: task.id,
        platformJobId,
    }

    useEffect(() => {
        if (isOpen) {
            disableScroll.on(undefined, {
                keyboardKeys: [
                    KeyNumericCodes.PageDown,
                    KeyNumericCodes.PageUp,
                    KeyNumericCodes.End,
                    KeyNumericCodes.Home,
                    KeyNumericCodes.Up,
                    KeyNumericCodes.Down,
                ],
            })
        } else {
            validationMessage.current = null
            disableScroll.off()
        }
    }, [isOpen])

    useKeyPressEvent('Escape', propsOnClose)

    if (!currentRange?.current) {
        return null
    }

    const clickedInvalidNodeEntry = Editor.node(editor, currentRange?.current as Range)
    const clickedInvalidNode = clickedInvalidNodeEntry[0] as ContentText
    const invalidString = Editor.string(editor, clickedInvalidNodeEntry[1])
    if (!!clickedInvalidNode.invalidValidationId) {
        const messageData = Validations.getInvalidMessageData(
            editor,
            clickedInvalidNode.invalidValidationId,
        )
        if (!!messageData) {
            validationMessage.current = messageData
        }
    }

    const reportIrrelevantValidationToMixpanel = () => {
        if (!!validationMessage.current && !!clickedInvalidNode.invalidValidationId) {
            const { validationName } = validationMessage.current
            analytics?.sendIrrelevantValidation({
                validationId: clickedInvalidNode.invalidValidationId,
                validationName,
                invalidString,
                ...analyticsMetadata,
            })
        }

        addToast({
            intent: Intent.NONE,
            icon: <Icon icon="tick" color="#0f9960" />,
            message: <>Irrelevant notification reported</>,
        })
        propsOnClose()
    }

    const onClose = (e: BaseSyntheticEvent) => {
        if (!didEventOccurInsideTheEditorNodes(e)) {
            propsOnClose()
            return
        }

        const eventRange = ReactEditor.findEventRange(editor, e)
        if (!eventRange || !currentRange?.current) {
            propsOnClose()
            return
        }

        // Don't close popup if the click event occured inside the invalid node
        if (Path.equals(eventRange?.anchor.path, currentRange?.current?.anchor.path)) {
            return
        }

        propsOnClose()
    }

    const { messageTitle = 'Validation', messageSegments = [] } = validationMessage.current || {}
    return (
        <Overlay
            canEscapeKeyClose={true}
            enforceFocus={false}
            hasBackdrop={false}
            isOpen={isOpen}
            transitionDuration={-1}
            transitionName="none"
            backdropClassName="popup-overlay-backdrop"
            onClose={onClose}
        >
            <PopupContainer {...position}>
                <PopupHeader>
                    {messageTitle}
                    <StyledCloseIcon onClick={propsOnClose} />
                </PopupHeader>
                <PopupBody>
                    {messageSegments.map((messageSegment, i) => (
                        <Paragraph key={`${messageTitle}${i}`}>{messageSegment}</Paragraph>
                    ))}
                </PopupBody>
                <PopupFooter>
                    <PopupFooterButton onClick={reportIrrelevantValidationToMixpanel}>
                        This notification is irrelevant
                    </PopupFooterButton>
                </PopupFooter>
            </PopupContainer>
        </Overlay>
    )
}
