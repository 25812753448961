import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'

export type FeatureFlag = 'validations' | 'glossary_verification' | 'appcues_nps'

export const useFeatureFlag = (featureName: FeatureFlag) => {
    const [{ context }] = useAppMachine(['featureFlags'])
    const { featureFlags = {} } = context

    return !!featureFlags[featureName]?.enabled
}
