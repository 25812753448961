import { DraggableVideoPlayer } from 'src/components/VideoPlayer/DraggableVideoPlayer'
import { useVideo } from 'src/state/VideoProvider'
import styled from 'styled-components/macro'
import { prop, theme } from 'styled-tools'

const VideoPlayerContainer = styled.div<{ x?: number; y?: number }>`
    z-index: ${theme('zIndexes.videoPlayer')};
    position: fixed;
    left: ${prop('x')}px;
    top: ${prop('y')}px;
`

export function AbsolutePositionedVideoPlayer() {
    const { videoCoords } = useVideo()

    return (
        <VideoPlayerContainer x={videoCoords?.x} y={videoCoords?.y}>
            <DraggableVideoPlayer resizeable />
        </VideoPlayerContainer>
    )
}
