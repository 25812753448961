import { useState } from 'react'
import { useSessionMediaService } from 'src/state/SessionMediaProvider'
import { TimingRange } from 'src/utils/range'

export function useCurrentHighlight(range: TimingRange) {
    const [highlight, setHighlight] = useState<'before' | 'after' | 'now'>('after')

    useSessionMediaService(
        ({ currentTime }) => {
            if (currentTime < range.start) {
                setHighlight('after')
                return
            }
            if (currentTime >= range.start && currentTime <= range.end) {
                setHighlight('now')
                return
            }
            if (currentTime > range.end) {
                setHighlight('before')
                return
            }
        },
        ['currentTime'],
    )

    return highlight
}
