import { useCallback, useLayoutEffect, useEffect, useState } from 'react'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { ErrorCode } from 'src/network'
import { ErrorScreen } from '../Common/ErrorScreen'
import { NotAvailableSession } from '../Session/live/components/SessionScreens'
import { TranscribersIcon, UnsupportedBrowserIcon } from '../icons'

const DEFAULT_PLAT_URL = process.env.REACT_APP_PLATFORM_URL

export function RejectedAuth() {
    const [platformFallbackUrl, setPlatformFallbackUrl] = useState(DEFAULT_PLAT_URL)
    const { sessionStatus } = useSessionStatus(['sessionStatus.transcribersHubUrl'])
    const [
        {
            context: { error, httpClient },
        },
    ] = useAppMachine(['error', 'httpClient'])

    useEffect(() => {
        ;(async () => {
            try {
                const { redirectUrl } = await httpClient.getSessionHealth()
                if (redirectUrl) {
                    setPlatformFallbackUrl(redirectUrl)
                }
            } catch (err) {
                console.log(err)
            }
        })()
    }, [httpClient])

    const openTranscribersHub = useCallback(() => {
        window.open(sessionStatus?.transcribersHubUrl || platformFallbackUrl)
    }, [sessionStatus, platformFallbackUrl])

    const closeTab = useCallback(() => {
        window.close()
    }, [])

    useLayoutEffect(() => {
        console.log(error?.payload)
        if (error?.code === ErrorCode.UnauthenticatedUser && error.payload?.login_url) {
            window.location.href = error.payload.login_url
        }
    }, [error])

    switch (error?.code) {
        case ErrorCode.SessionReachedWorkerLimit: {
            return (
                <ErrorScreen
                    icon={<TranscribersIcon />}
                    title="This session has enough transcribers at the moment."
                    subtitle="Try again later or take a different job."
                    actionTitle="Back to Transcribers Hub"
                    action={openTranscribersHub}
                />
            )
        }
        case ErrorCode.WorkerAlreadyExists: {
            return (
                <ErrorScreen
                    icon={<UnsupportedBrowserIcon />}
                    title="You are already logged in from a different window"
                    subtitle="Close this one and continue working in the previously opened one."
                    actionTitle="Close"
                    action={closeTab}
                />
            )
        }
        default: {
            return <NotAvailableSession />
        }
    }
}
