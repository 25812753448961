import { ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import { TimingRange } from 'src/utils/range'
import { usePrevious } from 'src/hooks/usePrevious'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { getEnv } from 'src/utils/env'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

interface TaskAudioBufferingTimeoutHandlerProps {
    timeoutInSeconds?: number
    onTimeout: () => void
    loaderDelay?: number
    loader: ReactElement
    connectionError: ReactElement
    children: ReactElement
}

export const TaskAudioBufferingTimeoutHandler = ({
    timeoutInSeconds,
    onTimeout,
    loaderDelay = 50,
    loader,
    connectionError,
    children,
}: TaskAudioBufferingTimeoutHandlerProps) => {
    const [taskAudioBufferingHasStarted, setTaskAudioBufferingHasStarted] = useState(false)
    const [displayBufferingLoader, setDisplayBufferingLoader] = useState(false)
    const [displayConnectionError, setDisplayConnectionError] = useState(false)
    const [{ context }] = useTaskMachine(['elapsed'])
    const isDevEnv = useMemo(() => getEnv() === 'dev', [])
    const { bufferedTimeRanges, mediaTiming, isMediaConnected } = useSessionMedia([
        'bufferedTimeRanges',
        'mediaTiming',
        'isMediaConnected',
    ])

    const { elapsed } = context
    const prevElapsed = usePrevious(elapsed)
    const requiredRangeToBeBuffered = mediaTiming?.requiredRangeToBeBuffered

    useEffect(() => {
        if (
            requiredRangeToBeBuffered &&
            (bufferedTimeRanges.some((bufferedTimeRange) =>
                TimingRange.isIntersecting(bufferedTimeRange, requiredRangeToBeBuffered),
            ) ||
                isDevEnv)
        ) {
            setTaskAudioBufferingHasStarted(true)
        }
    }, [bufferedTimeRanges, requiredRangeToBeBuffered, isDevEnv])

    const displayBufferingLoaderTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
    useEffect(() => {
        if (!timeoutInSeconds) {
            return
        }

        if (taskAudioBufferingHasStarted) {
            setDisplayBufferingLoader(false)
        } else {
            displayBufferingLoaderTimeoutRef.current = setTimeout(() => {
                setDisplayBufferingLoader(true)
            }, loaderDelay)

            return () => {
                if (!!displayBufferingLoaderTimeoutRef.current) {
                    clearTimeout(displayBufferingLoaderTimeoutRef.current)
                }
            }
        }
    }, [taskAudioBufferingHasStarted, timeoutInSeconds, loaderDelay])

    useEffect(() => {
        const hasTimedOut =
            timeoutInSeconds &&
            prevElapsed &&
            elapsed > timeoutInSeconds &&
            prevElapsed <= timeoutInSeconds

        if (!taskAudioBufferingHasStarted && hasTimedOut && !isDevEnv) {
            if (isMediaConnected) {
                onTimeout()
            } else {
                setDisplayBufferingLoader(false)
                setDisplayConnectionError(true)
            }
        }
    }, [
        timeoutInSeconds,
        taskAudioBufferingHasStarted,
        elapsed,
        prevElapsed,
        onTimeout,
        isMediaConnected,
        isDevEnv,
    ])

    if (!timeoutInSeconds || taskAudioBufferingHasStarted || isDevEnv) {
        return children
    }

    if (displayBufferingLoader) {
        return loader
    }

    if (displayConnectionError) {
        return connectionError
    }

    return null
}
