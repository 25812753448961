import { useCallback, useRef, useEffect } from 'react'
import useDidMount from 'beautiful-react-hooks/useDidMount'

import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { Speaker } from 'src/models'
import { useSpeakerRolesByRole, useSpeakers } from 'src/state/SpeakersProvider'
import {
    useSpeakerVoiceSamplePlayer,
    useToggleSpeakerVoiceSample,
} from 'src/state/SpeakerVoiceSamplePlayerProvider'
import { useLegalAnnotations } from 'src/state/LegalAnnotationsProvider'
import {
    getDefaultVoiceSampleId,
    getSpeakerColor,
    isVoiceSamplePlaying,
    getSpeakerHotkey,
} from 'src/utils/speaker'
import { AddIcon } from '@verbit-ai/icons-library'
import { Button as VerbitButton } from '@verbit-ai/verbit-ui-library'
import { Header } from './common'
import { SpeakerRow } from './SpeakerRow'
import { SpeakerForm } from './SpeakerForm/SpeakerForm'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-bottom: none;
    background-color: ${palette('white', 0)};
    overflow-y: hidden;
`

const PanelHeader = styled(Header)`
    padding: 0 16px;
    border-bottom: solid 1px ${palette('cloudBlueLight', 1)};
`

const SpeakerList = styled.div`
    overflow-y: auto;
`

export const SpeakerPanel = () => {
    let mappedHotKeys: string[] = []
    const { speakers, setSpeakerFormValue, setIsSpeakerFormVisible, speakerForm } = useSpeakers()
    const { isVisible: isSpeakerFormVisible } = speakerForm
    const speakerRolesByRole = useSpeakerRolesByRole()
    const { legalAnnotationsBySpeaker } = useLegalAnnotations()
    const toggleSpeakerVoiceSample = useToggleSpeakerVoiceSample()
    const { currentPlayingVoiceSample, ...speakerVoiceSamplePlayer } = useSpeakerVoiceSamplePlayer()

    const didMount = useRef<boolean>(false)
    useDidMount(() => {
        didMount.current = true
    })

    const openSpeakerForm = useCallback(
        (speaker?: Speaker) => {
            if (speaker) {
                setSpeakerFormValue(speaker)
            }

            setIsSpeakerFormVisible(true)
        },
        [setIsSpeakerFormVisible, setSpeakerFormValue],
    )

    useEffect(() => {
        if (!speakers.length) {
            setIsSpeakerFormVisible(true)
        }
    }, [setIsSpeakerFormVisible, speakers.length])

    return (
        <>
            {!speakers.length ? (
                <SpeakerForm />
            ) : (
                <Container>
                    <PanelHeader>
                        <div>Speakers by Role</div>
                        <VerbitButton
                            onClick={() => openSpeakerForm()}
                            variant="link"
                            leftIcon={<AddIcon color="#2F6CDA" />}
                        >
                            Add
                        </VerbitButton>
                    </PanelHeader>

                    <SpeakerList>
                        {speakers.map((speaker) => {
                            const defaultSampleId = getDefaultVoiceSampleId(speaker.samples)
                            const isPlaying = isVoiceSamplePlaying(
                                speaker.id,
                                defaultSampleId,
                                currentPlayingVoiceSample,
                            )
                            const color = getSpeakerColor(speaker, speakerRolesByRole)
                            let hotkey = getSpeakerHotkey(speaker.role, speakerRolesByRole)

                            if (hotkey && mappedHotKeys.includes(hotkey)) {
                                hotkey = ''
                            } else {
                                hotkey && mappedHotKeys.push(hotkey)
                            }

                            const legalAnnotation = legalAnnotationsBySpeaker[speaker.id]

                            return (
                                <SpeakerRow
                                    key={speaker.id}
                                    onSelect={openSpeakerForm}
                                    onToggleVoiceSample={toggleSpeakerVoiceSample}
                                    speaker={speaker}
                                    color={color}
                                    hotkey={hotkey}
                                    legalAnnotation={legalAnnotation}
                                    defaultVoiceSampleId={defaultSampleId}
                                    isVoiceSamplePlaying={isPlaying}
                                    isVoiceSampleBuffering={
                                        isPlaying && speakerVoiceSamplePlayer.isBuffering
                                    }
                                    animate={didMount.current}
                                />
                            )
                        })}
                    </SpeakerList>
                </Container>
            )}

            {isSpeakerFormVisible && <SpeakerForm />}
        </>
    )
}
