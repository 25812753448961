import React, { useCallback, useContext, useRef } from 'react'
import {
    Toaster,
    AnchorButton,
    Position,
    Intent,
    ButtonGroup,
    IconName,
    MaybeElement,
} from '@blueprintjs/core'
import styled from 'styled-components/macro'

interface ActionButton {
    text: string
    onClick: () => void
}

interface ToastItem {
    intent: Intent
    icon?: IconName | MaybeElement
    message: React.ReactNode
    actions?: ActionButton[]
    timeout?: number
}

type AddToastFn = (toast: ToastItem) => void

interface ToastProps {
    children?: React.ReactNode
}

const MessageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`
const Message = styled.div`
    padding: 11px;
`

const ToastReactContext = React.createContext<AddToastFn>(() => {})
export function ToastProvider({ children }: ToastProps) {
    const toaster = useRef<Toaster | null>()
    const addToast = useCallback<AddToastFn>((toast) => {
        if (toaster.current) {
            const { actions = [], message: suppliedMessage, ...rest } = toast

            const message = (
                <MessageContainer>
                    <Message>{suppliedMessage}</Message>
                    <ButtonGroup minimal>
                        {actions?.map((action, i) => (
                            <AnchorButton minimal key={i} onClick={action.onClick}>
                                {action.text}
                            </AnchorButton>
                        ))}
                    </ButtonGroup>
                </MessageContainer>
            )

            toaster.current.show({ ...rest, message })
        }
    }, [])

    return (
        <ToastReactContext.Provider value={addToast}>
            <Toaster
                className="toaster-position"
                ref={(el) => (toaster.current = el)}
                position={Position.TOP}
                usePortal={false}
                maxToasts={4}
            />
            {children}
        </ToastReactContext.Provider>
    )
}

export function useToast() {
    return useContext(ToastReactContext)
}
