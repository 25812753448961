// Made for temporary measurements - Event 40 - Speakers separations on LOT sessions
export class BlocksAnalytics {
    blocksSeparationCount: number

    constructor() {
        this.blocksSeparationCount = 0
    }

    resetCount() {
        this.blocksSeparationCount = 0
    }

    increment() {
        this.blocksSeparationCount++
    }

    getValue() {
        return this.blocksSeparationCount
    }
}

export const blocksAnalyticsSingleton = new BlocksAnalytics()
