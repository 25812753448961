export function isURL(value: string) {
    let url: URL

    try {
        url = new URL(value)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const getFileName = (filePath: string) => filePath?.split('/').pop() || ''
