import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { noop } from 'lodash/fp'
import { ObjectPaths, getValuesByPath } from 'src/utils/object'
import React from 'react'

interface SubscriptionServiceComponentProps<T> {
    data: T
    children: ReactNode
}

interface SubscriptionServiceContextProps<T extends object> {
    subscribe: (fn: SubscriptionServiceSubscriberFn<T>) => void
    unsubscribe: (fn: SubscriptionServiceSubscriberFn<T>) => void
    getData: () => T | null
}

type SubscriptionDeps<T extends object> = ObjectPaths<T>

/**
 * This factory function returns a subscription service and 2 consumer hooks. The service itself is
 * a react context provider that will receive the subscription data as a property and pass it to the consumer hooks.
 * The special thing about the consumer hooks is, that they can decide if they want to rerender or not. There are 2
 * hooks for different usecases.
 *
 * The simple usecase:
 * You're subscribing to data and want to rerender whenever a specific property of the data is updated. In this case, you
 * want to use useSubscriptionData. Simply pass the property names as an array to the hook and it will only rerender, whenever
 * one of the stated properties is updated.
 *
 * The complex usecase:
 * If the query is more complex, or you want to subscribe to data but not rerender at all, there's useSubscriptionService which
 * lets you register a function that will be called whenever the data changes. The amount of calls can be reduced by passing an
 * array of property names you want the updates for as a 2nd parameter.
 * This hook is especially useful if you want to keep a ref up to date without causing rerenders.
 */
export function createSubscriptionService<T extends object>(defaultValue: T) {
    const ReactContext = createContext<SubscriptionServiceContextProps<T>>({
        subscribe: noop,
        unsubscribe: noop,
        getData: () => null,
    })

    function SubscriptionServiceComponent({
        data,
        children,
    }: SubscriptionServiceComponentProps<T>): JSX.Element {
        const dataRef = useRef(data)

        const subService = useMemo(() => new SubscriptionService<T>(), [])

        useEffect(() => {
            subService.publish(data)
            dataRef.current = data
        }, [data, subService])

        const ctxProps = useMemo(
            () => ({
                subscribe: subService.subscribe.bind(subService),
                unsubscribe: subService.unsubscribe.bind(subService),
                getData: () => dataRef.current,
            }),
            [subService],
        )

        return <ReactContext.Provider value={ctxProps}>{children}</ReactContext.Provider>
    }

    function buildInitialDepsValue(deps: SubscriptionDeps<T>[] | true, data: T | null) {
        if (deps === true || !data) {
            return []
        }

        const values = []
        for (const prop of deps) {
            values.push(getValuesByPath(data, prop))
        }

        return values
    }

    function useSubscriptionService(
        fn: SubscriptionServiceSubscriberFn<T>,
        deps: SubscriptionDeps<T>[] | true = true,
    ) {
        const { subscribe, unsubscribe, getData } = useContext(ReactContext)
        const depsValuesRef = useRef<Array<any>>(buildInitialDepsValue(deps, getData()))
        const fnRef = useRef<SubscriptionServiceSubscriberFn<T>>(fn)

        useEffect(() => {
            fnRef.current = fn
        }, [fn])

        useEffect(() => {
            function service(data: T) {
                if (deps === true) {
                    fnRef.current(data)
                    return
                }

                const { current: currentDeps } = depsValuesRef
                let newDeps = currentDeps
                let changed = false

                for (const [i, prop] of deps.entries()) {
                    if (currentDeps[i] !== getValuesByPath(data, prop)) {
                        changed = true
                    }
                    newDeps[i] = getValuesByPath(data, prop)
                }
                depsValuesRef.current = newDeps

                if (changed) {
                    fnRef.current(data)
                }
            }
            subscribe(service)
            return () => unsubscribe(service)
        }, [deps, subscribe, unsubscribe])
    }

    function useSubscriptionData(deps: SubscriptionDeps<T>[] | true = true) {
        const { getData } = useContext(ReactContext)

        const [data, setData] = useState<T>(getData() || defaultValue)
        useSubscriptionService(setData, deps)

        return data
    }

    return [SubscriptionServiceComponent, useSubscriptionData, useSubscriptionService] as const
}

type SubscriptionServiceSubscriberFn<T extends Object> = (data: T) => void
class SubscriptionService<
    T extends Object,
    TFn extends SubscriptionServiceSubscriberFn<T> = SubscriptionServiceSubscriberFn<T>,
> {
    private _subscribers: TFn[] = []

    subscribe(fn: TFn) {
        this._subscribers.push(fn)
    }

    unsubscribe(fn: TFn) {
        this._subscribers = this._subscribers.filter((sub) => sub !== fn)
    }

    publish(data: T) {
        for (const sub of this._subscribers) {
            sub(data)
        }
    }
}
