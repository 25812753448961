import { createContext, useState, useContext, ReactNode, useMemo, useEffect } from 'react'
import { noop, invert } from 'lodash/fp'
import { TaskCache } from './TaskCache'

export interface LegalAnnotationsBySpeakerMap {
    [speakerId: string]: string
}

export interface SpeakersByLegalAnnotationMap {
    [legalAnnotation: string]: string
}

interface LegalAnnotationsContextValue {
    legalAnnotationsBySpeaker: LegalAnnotationsBySpeakerMap
    speakersByLegalAnnotation: SpeakersByLegalAnnotationMap
    setLegalAnnotation: (legalAnnotation: string, speakerId: string) => void
}

type StoreValueChangeListener = (
    legalAnnotationsBySpeaker: LegalAnnotationsBySpeakerMap,
    speakersByLegalAnnotation: SpeakersByLegalAnnotationMap,
) => void

class LegalAnnotationsStore {
    private _legalAnnotationsBySpeaker: LegalAnnotationsBySpeakerMap = {}
    private _speakersByLegalAnnotation: SpeakersByLegalAnnotationMap = {}
    private changeListeners: StoreValueChangeListener[] = []

    constructor() {
        this.setSpeakersByLegalAnnotationMapping(TaskCache.getSpeakersAnnotations())
    }

    get legalAnnotationsBySpeaker() {
        return this._legalAnnotationsBySpeaker
    }

    get speakersByLegalAnnotation() {
        return this._speakersByLegalAnnotation
    }

    subscribe(changeListener: StoreValueChangeListener) {
        this.changeListeners.push(changeListener)
    }

    unsubscribe(changeListener: StoreValueChangeListener) {
        this.changeListeners.filter((currentListener) => currentListener !== changeListener)
    }

    setSpeakersByLegalAnnotationMapping(
        nextSpeakersByLegalAnnotation: SpeakersByLegalAnnotationMap,
    ) {
        this._speakersByLegalAnnotation = nextSpeakersByLegalAnnotation
        this._legalAnnotationsBySpeaker = invert(this._speakersByLegalAnnotation)

        // queuing a microtask to fire all change listeners since this method can be called syncroniously during render of components.
        // This means that the LegalAnnotationsProvider component below will setState because it has a change listener
        // and it's invalid to call setState in a component while another component is rendering.
        queueMicrotask(() => {
            this.changeListeners.forEach((changeListener) =>
                changeListener(this.legalAnnotationsBySpeaker, this.speakersByLegalAnnotation),
            )
        })
    }

    setLegalAnnotation(legalAnnotation: string, speakerId: string) {
        const speakerPreviousLegalAnnotation = this.legalAnnotationsBySpeaker[speakerId]

        const nextSpeakersByLegalAnnotation = {
            ...this.speakersByLegalAnnotation,
            [speakerPreviousLegalAnnotation]: '',
            [legalAnnotation]: speakerId,
        }

        TaskCache.saveSpeakersAnnotations(nextSpeakersByLegalAnnotation)

        this.setSpeakersByLegalAnnotationMapping(nextSpeakersByLegalAnnotation)
    }
}

export const legalAnnotationsStore = new LegalAnnotationsStore()

const LegalAnnotationsContext = createContext<LegalAnnotationsContextValue>({
    legalAnnotationsBySpeaker: {},
    speakersByLegalAnnotation: {},
    setLegalAnnotation: noop,
})

interface LegalAnnotationsProviderProps {
    children: ReactNode
}

export const LegalAnnotationsProvider = ({ children }: LegalAnnotationsProviderProps) => {
    const [legalAnnotationsBySpeaker, setLegalAnnotationsBySpeaker] =
        useState<LegalAnnotationsBySpeakerMap>(legalAnnotationsStore.legalAnnotationsBySpeaker)
    const [speakersByLegalAnnotation, setSpeakersByLegalAnnotation] =
        useState<SpeakersByLegalAnnotationMap>(legalAnnotationsStore.speakersByLegalAnnotation)

    useEffect(() => {
        const onStoreValueChange: StoreValueChangeListener = (
            legalAnnotationsBySpeaker,
            speakersByLegalAnnotation,
        ) => {
            setSpeakersByLegalAnnotation(speakersByLegalAnnotation)
            setLegalAnnotationsBySpeaker(legalAnnotationsBySpeaker)
        }

        legalAnnotationsStore.subscribe(onStoreValueChange)

        return () => {
            legalAnnotationsStore.unsubscribe(onStoreValueChange)
        }
    }, [])

    return (
        <LegalAnnotationsContext.Provider
            value={useMemo(
                () => ({
                    legalAnnotationsBySpeaker,
                    speakersByLegalAnnotation,
                    setLegalAnnotation: legalAnnotationsStore.setLegalAnnotation,
                }),
                [legalAnnotationsBySpeaker, speakersByLegalAnnotation],
            )}
        >
            {children}
        </LegalAnnotationsContext.Provider>
    )
}

export const useLegalAnnotations = () => {
    const legalAnnotationsContextValue = useContext(LegalAnnotationsContext)

    if (!legalAnnotationsContextValue) {
        throw new Error('You have forgot to use LegalAnnotationsContext, shame on you.')
    }

    return legalAnnotationsContextValue
}
