import { MockManager, MockManagerMode, MockResourceType } from './MockManager'
import { MockDelayRange } from './utils'

type MockWebSocketClientOptions = {
    delay?: MockDelayRange
}

export function createWebsocketClientMockDecorator(manager: MockManager) {
    return (resourceId: string, options?: MockWebSocketClientOptions) => {
        return function <T extends { new (...args: any[]): {} }>(constructor: T) {
            if (!manager.isActive()) {
                return constructor
            }

            return class extends constructor {
                connect(...args: any[]) {
                    // @ts-ignore
                    const emitter: EventEmitter = this.eventEmitter

                    const mode = manager.getMode()
                    switch (mode) {
                        case MockManagerMode.Record:
                            // @ts-ignore
                            super.connect(...args)

                            // @ts-ignore
                            this.socket.addEventListener('open', (data: any) => {
                                console.log(`[MOCK] WebSocket connected`)
                                manager.record(MockResourceType.Stream, resourceId, {
                                    event: 'open',
                                    data,
                                })
                            })

                            // @ts-ignore
                            this.socket.addEventListener('message', (event: any) => {
                                if (!event.data) {
                                    return
                                }

                                const originalEvent = JSON.parse(event.data)
                                manager.record(MockResourceType.Stream, resourceId, {
                                    event: originalEvent.name,
                                    data: originalEvent,
                                })
                            })

                            // @ts-ignore
                            this.socket.addEventListener('close', (event: any) => {
                                console.log(`[MOCK] WebSocket disconnected`)

                                manager.record(MockResourceType.Stream, resourceId, {
                                    event: 'close',
                                    data: event,
                                })
                            })

                            break
                        case MockManagerMode.Replay:
                            const replayMessages = async () => {
                                await manager.isReady()

                                const player = manager.replayStream(resourceId, options)

                                if (!player) {
                                    console.log(
                                        `[MOCK] No player found for resource "${resourceId}"`,
                                    )
                                    return
                                }

                                for await (const event of player.asyncIterator()) {
                                    //@ts-ignore
                                    const { data } = this.mapReceivedEvent(event.data)

                                    console.log(
                                        `[MOCK] Replaying WebSocket event "${event.event}"`,
                                        data,
                                    )
                                    emitter.emit(event.event, data)
                                }
                            }
                            replayMessages()
                            break
                    }
                }

                disconnect(...args: any[]) {
                    if (manager.getMode() === MockManagerMode.Replay) {
                        console.log(`${constructor.name}.disconnect() is mocked`)
                        return
                    }

                    // @ts-ignore
                    super.disconnect(...args)
                }

                emit(...args: any[]) {
                    if (manager.getMode() === MockManagerMode.Replay) {
                        console.log(`[MOCK] emit`, ...args)
                        return
                    }

                    // @ts-ignore
                    super.emit(...args)
                }
            }
        }
    }
}
