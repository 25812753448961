import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TaskAbortReason } from 'src/state/state-machines/TaskMachine/TaskMachine'
import { ErrorScreen } from 'src/components/Common/ErrorScreen'
import { SomethingWentWrongIcon } from 'src/components/icons'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

const SubTitleContainer = styled.div`
    text-align: left;
    max-width: 440px;
`

const ListItem = styled.li`
    margin-bottom: 12px;
`

export const AbortingTaskScreen = () => {
    const [{ context }, sendEvent] = useTaskMachine(['taskAbortReason'])
    const { taskAbortReason } = context
    const errorScreenProps = useAbortingErrorScreenProps(taskAbortReason)

    if (!errorScreenProps) {
        return null
    }

    const { title, subtitle, onRetry } = errorScreenProps

    return (
        <ErrorScreen
            icon={<SomethingWentWrongIcon />}
            title={title}
            subtitle={subtitle}
            actionTitle="Retry"
            action={() => {
                onRetry()
                sendEvent({ type: 'RETRY_ABORTED' })
            }}
        />
    )
}

const useAbortingErrorScreenProps = (
    taskAbortReason?: TaskAbortReason,
): { title: string; subtitle: ReactNode; onRetry: () => void } | null => {
    const { disconnectMedia } = useSessionMedia(['disconnectMedia'])

    switch (taskAbortReason) {
        case 'audio_unavailable': {
            return {
                title: "Your internet is shaky, so we're not able to play the audio of the next task",
                subtitle: (
                    <SubTitleContainer>
                        <div>One of the following may help:</div>
                        <ul>
                            <ListItem>
                                Close unnecessary browser tabs or other applications running in your
                                computer
                            </ListItem>
                            <ListItem>Connect to a different WiFi network</ListItem>
                            <ListItem>
                                Connect to the internet by cable rather than wirelessly
                            </ListItem>
                        </ul>
                    </SubTitleContainer>
                ),
                onRetry: () => disconnectMedia(),
            }
        }

        default:
            return null
    }
}
