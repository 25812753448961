import Rollbar from 'rollbar'
import { APIError, ErrorCode } from 'src/network'

export function initRollbar(
    env: 'dev' | 'qa' | 'production' | 'staging' | 'test',
    accessToken?: string,
    commit_sha1?: string,
) {
    window.Rollbar = Rollbar.init({
        enabled: env !== 'dev' && env !== 'test',
        accessToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        ignoredMessages: [
            'Network Error',
            'timeout of 0ms exceeded',
            'Request aborted',
            'Unknown server error',
            "property 'playbackRate' of undefined",
            'ChunkLoadError: Loading chunk',
            "Can't find variable Appcues",
            'e.msMatchesSelector is not a function',
            'TypeError: Failed to fetch',
        ],
        payload: {
            environment: env,
            code_version: commit_sha1,
            source_map_enabled: true,
        },
        // This function is called by Rollbar to determine if an error should be sent to it or ignored. Return true to ignore, false to send.
        checkIgnore: (isUncaught, args, payload) => {
            // const message = args[0]
            const error = args[1]

            // Ignore our own API response errors that are just 404s. These can happen when the REST API is down but clients keep polling endpoints.
            if (error instanceof APIError && error.code === ErrorCode.IgnoredStatusCodeError) {
                return true
            }

            return false
        },
    })
}
