import React, { useMemo } from 'react'
import { sortBy } from 'lodash/fp'

import { LocationIcon, PersonIcon, TermIcon } from 'src/components/icons'

import { Table, TableColumn, TableRow } from './common'
import { useOnboardingStepPayload } from '../useOnboardingStepPayload'
import { GlossaryTerm, GlossaryTermCategory } from 'src/models'

export function GlossaryHighlightsStep() {
    const { terms } = useOnboardingStepPayload('gloss_highlights')

    const termsByCategory = useMemo(() => {
        const sortedTerms = sortBy((term) => term.text.toLowerCase(), terms)

        return sortedTerms.reduce<{ [K in GlossaryTermCategory]: GlossaryTerm[] }>(
            (acc, glossaryTerm) => ({
                ...acc,
                [glossaryTerm.category]: acc[glossaryTerm.category].concat(glossaryTerm),
            }),
            { person: [], place: [], term: [] },
        )
    }, [terms])

    return (
        <Table>
            <TableColumn
                title={
                    <>
                        <PersonIcon />
                        People
                    </>
                }
            >
                {termsByCategory.person.map(({ id, text }) => (
                    <TableRow key={id}>{text}</TableRow>
                ))}
            </TableColumn>

            <TableColumn
                title={
                    <>
                        <LocationIcon />
                        Places
                    </>
                }
            >
                {termsByCategory.place.map(({ id, text }) => (
                    <TableRow key={id}>{text}</TableRow>
                ))}
            </TableColumn>

            <TableColumn
                title={
                    <>
                        <TermIcon />
                        Professional Terms
                    </>
                }
            >
                {termsByCategory.term.map(({ id, text }) => (
                    <TableRow key={id}>{text}</TableRow>
                ))}
            </TableColumn>
        </Table>
    )
}
