import React, { ReactElement, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { theme } from 'src/components/styled'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { StatusIndicator } from 'src/components/StatusIndicator'
import { ZoomAudio, TeamsAudio } from 'src/components/icons'
import { useLiveMachine } from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { Button, Typography } from '@verbit-ai/verbit-ui-library'
import { MicrophoneIcon } from '@verbit-ai/icons-library'
import { VerbitConnect, VerbitConnectProvider } from '@verbit-ai/verbit-connect-web'
import { getMixpanelVcApiKey, getRollbarApiKey } from '../../utils/env'
import { useFeatureFlag } from '../../state/FeatureFlagProvider'

const StyledButton = styled(Button)`
    display: flex;

    &:hover {
        border: none;
    }
`

const StyledSpan = styled.span`
    border-radius: 4px;

    &:hover {
        border: 1px solid ${theme?.palette?.grey?.[8]};
        box-shadow: none;
    }
`

const MediaSourceContainer = styled.div`
    display: inline-block;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: auto;
`

const TimeCodeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 55px;
    margin-left: 7px;
    background-size: cover;
    background-position: 10px 10px;
`

type MediaSourceState = 'Streaming' | 'Ended' | 'Awaiting audio' | 'Loading...'

export const MediaSource = (): ReactElement => {
    const intervalId = useRef<number | null>(null)
    const [mediaState, setMediaState] = useState<MediaSourceState>('Loading...')
    const [elapsedTimeValue, setElapsedTimeValue] = useState<string>('00:00:00')
    const [orderId, setOrderId] = useState<string>()

    const vcFlag = useFeatureFlag('verbit_connect_pkg') // only causes re-render if specified flag values / traits change

    const [
        {
            context: { controls, mediaSource, redirect_urls },
        },
    ] = useLiveMachine(['controls', 'mediaSource', 'redirect_urls'])
    const { sessionStatus } = useSessionStatus([
        'sessionStatus.playbackState',
        'sessionStatus.audioStartedAt',
        'sessionStatus.orderId',
    ])

    const elapsedTime = (audio_started_at: string): string => {
        if (!audio_started_at) return '00::00:000'

        // check audio_started_at if it is the correct format
        const formatted_audio_started_at = appendZinTimeIfNotExist(audio_started_at)
        const pastDate = new Date(formatted_audio_started_at)
        const now = new Date()
        const diffInMilliseconds = now.getTime() - pastDate.getTime()
        const hours = Math.floor(diffInMilliseconds / 3600000)
        const minutes = Math.floor((diffInMilliseconds % 3600000) / 60000)
        const seconds = Math.floor(((diffInMilliseconds % 3600000) % 60000) / 1000)

        // Pad hours, minutes, and seconds with zeros if they are less than 10
        const hoursStr = hours < 10 ? '0' + hours : hours.toString()
        const minutesStr = minutes < 10 ? '0' + minutes : minutes.toString()
        const secondsStr = seconds < 10 ? '0' + seconds : seconds.toString()

        return `${hoursStr}:${minutesStr}:${secondsStr}`
    }

    useEffect(() => {
        if (sessionStatus?.playbackState !== 'playing') {
            setElapsedTimeValue('00:00:00')
        } else {
            intervalId.current = window.setInterval(() => {
                setElapsedTimeValue(elapsedTime(sessionStatus?.audioStartedAt ?? ''))
            }, 1000)
        }

        return () => {
            if (intervalId.current !== null) {
                clearInterval(intervalId.current)
            }
        }
    }, [sessionStatus?.audioStartedAt, sessionStatus?.playbackState])

    useEffect(() => {
        switch (sessionStatus?.playbackState.toLowerCase()) {
            case 'playing':
                setMediaState('Streaming')
                break
            case 'stopped':
                setMediaState('Ended')
                break
            case 'not_started':
                setMediaState('Awaiting audio')
                break
            default:
                setMediaState('Loading...')
                break
        }
    }, [sessionStatus?.playbackState])

    useEffect(() => {
        setOrderId(sessionStatus?.orderId)
    }, [sessionStatus?.orderId])

    const VERBIT_CONNECT_URL = redirect_urls?.verbit_connect_url
    const isMediaComponentVisible = controls?.toolbar?.media_component?.visible
    if (!isMediaComponentVisible) return <></>

    const MediaSourceAndTimeCode = () => {
        return (
            <>
                <MediaSourceContainer>
                    <Typography
                        color={
                            mediaState === 'Streaming'
                                ? theme?.palette?.green?.[3]
                                : [
                                      mediaState !== 'Ended'
                                          ? theme?.palette?.grey?.[8]
                                          : theme?.palette?.grey?.[10],
                                  ]
                        }
                        italic
                        size="md"
                    >
                        {mediaState}
                    </Typography>
                </MediaSourceContainer>

                {sessionStatus?.playbackState &&
                    sessionStatus?.playbackState.toLowerCase() !== 'stopped' && (
                        <TimeCodeContainer>
                            <Typography
                                color={theme?.palette?.grey?.[10]}
                                size="md"
                                fontFamily={'Open Sans'}
                            >
                                {elapsedTimeValue}
                            </Typography>
                        </TimeCodeContainer>
                    )}
            </>
        )
    }

    const handleAudioRecorder = () => {
        if (VERBIT_CONNECT_URL) {
            window.open(String(VERBIT_CONNECT_URL), '_blank')
        }
    }

    const VerbitConnectStatus = () => {
        return (
            <Tooltip label={`Open audio recorder`} placement="top" hasArrow>
                <Button
                    variant="light"
                    leftIcon={<MicrophoneIcon color={theme?.palette?.grey?.[10]} />}
                    onClick={handleAudioRecorder}
                    size="lg"
                >
                    {sessionStatus?.playbackState &&
                        sessionStatus?.playbackState.toLowerCase() === 'playing' && (
                            <StatusIndicator variant="green" isGlowing={false} />
                        )}
                    <Typography color={theme?.palette?.grey?.[10]}>Audio Recorder</Typography>
                </Button>
            </Tooltip>
        )
    }

    const Teams = () => {
        return (
            <StyledButton
                variant="light"
                leftIcon={<TeamsAudio />}
                size="lg"
                style={{ cursor: 'help', minWidth: '30px' }}
            >
                <MediaSourceAndTimeCode />
            </StyledButton>
        )
    }

    const Zoom = () => {
        return (
            <StyledButton
                variant="light"
                leftIcon={<ZoomAudio />}
                size="lg"
                style={{ cursor: 'help', minWidth: '30px' }}
            >
                <MediaSourceAndTimeCode />
            </StyledButton>
        )
    }

    // Note: tooltip is moved here to avoid the tooltip from being rendered multiple times
    // when the timer is running
    const MediaComponent: { [key: string]: JSX.Element } = {
        zoom: (
            <Tooltip label={`Audio stream using Zoom`} placement="top" hasArrow>
                <StyledSpan>
                    <Zoom />
                </StyledSpan>
            </Tooltip>
        ),
        teams: (
            <Tooltip label={`Audio stream using Teams`} placement="top" hasArrow>
                <StyledSpan>
                    <Teams />
                </StyledSpan>
            </Tooltip>
        ),
        verbit_connect: !vcFlag ? (
            <VerbitConnectStatus />
        ) : orderId ? (
            <VerbitConnectProvider
                orderId={orderId}
                rollbarToken={getRollbarApiKey()}
                mixpanelToken={getMixpanelVcApiKey()}
            >
                <VerbitConnect />
            </VerbitConnectProvider>
        ) : (
            <React.Fragment />
        ),
        default: <React.Fragment />,
    }

    return (mediaSource && MediaComponent[mediaSource?.toLowerCase()]) || MediaComponent.default
}
export default MediaSource

const appendZinTimeIfNotExist = (str: string) => {
    if (!str.endsWith('Z')) {
        str += 'Z'
    }
    return str
}
