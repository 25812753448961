import { Editor, Node, Path, Range } from 'slate'
import LogRocket from 'logrocket'
import { v4 as uuid } from 'uuid'

import { getEnv } from 'src/utils/env'
import { ZERO_WIDTH_WHITESPACE, isPunctuation } from 'src/utils/string'

import { Timeline, TimelineTimingRange } from './timeline'
import { Block, Content } from 'src/components/Editor/plugins/withTranscript'
import { TimeRecord } from './TimeRecord'
import { TimelineEditor } from './TimelineEditor'
import { TimingRange } from 'src/utils/range'
import { translateSlateOperation } from './timeline/translateSlateOperation'
import { validateTimelineData } from './validateTimelineData'

type WithTimelineOptions = {
    timeline: Timeline
    currentTimeRef?: React.MutableRefObject<number>
    onError?: (error: Error) => void
}

export const withTimeline = (options: WithTimelineOptions) => {
    return (editor: Editor) => {
        editor.timeline = options.timeline

        const { apply, onChange, decorate } = editor

        /**
         * When operations are applied to the editor, we need to update the timeline data as well.
         * It is important, that this is done before the operation is applied to the editor.
         */
        editor.apply = (op) => {
            if (TimelineEditor.shouldAllowTimelineUpdate(editor)) {
                try {
                    const timelineOps = translateSlateOperation(editor, op)
                    editor.timeline.apply(timelineOps)
                } catch (err: any) {
                    if (options.onError) {
                        options.onError(err)
                    } else {
                        throw err
                    }
                }
            }

            apply(op)
        }

        editor.onChange = () => {
            onChange()

            try {
                validateTimelineData(editor)
            } catch (err: any) {
                if (options.onError) {
                    options.onError(err)
                } else {
                    throw err
                }
            }
        }

        editor.decorate = (entry) => {
            const [node, path] = entry
            const { currentTimeRef } = options
            const ranges = decorate(entry)

            if (!currentTimeRef) {
                return ranges
            }

            if (Editor.isEditor(node)) {
                const blockIndex = editor.timeline.getBlockIndexForTime(currentTimeRef.current)
                if (blockIndex !== undefined && currentTimeRef.current) {
                    const blockPath = [blockIndex]
                    if (Editor.hasPath(editor, blockPath)) {
                        ranges.push({
                            anchor: Editor.start(editor, blockPath),
                            focus: Editor.end(editor, blockPath),
                            uuid: uuid(),
                        })
                    } else {
                        window.Rollbar?.error(
                            `Cannot decorate non-existent block [${blockIndex}]. This is a timeline problem.`,
                        )
                        LogRocket.error(
                            `Cannot decorate non-existent block [${blockIndex}]. This is a timeline problem.`,
                        )
                    }
                }

                return ranges
            }

            if (!Content.isContent(node)) {
                return ranges
            }

            const [block] = Editor.parent(editor, path)
            const blockIndex = path[0]
            if (!Block.isBlock(block)) {
                console.assert(
                    false,
                    'Found a Content node that has no Block parent while decorating. This is a logic error.',
                )
                return []
            }

            let timings: TimelineTimingRange[]
            try {
                timings = editor.timeline.getTimingsForBlock(blockIndex)!
            } catch (err) {
                if (getEnv() === 'production') {
                    return []
                } else {
                    throw err
                }
            }
            const timingRange: TimingRange = timings.length
                ? { start: timings[0].start, end: timings[timings.length - 1].end }
                : { start: 0, end: 0 }

            if (
                timings.length === 0 ||
                currentTimeRef.current < timingRange.start ||
                currentTimeRef.current > timingRange.end
            ) {
                return []
            }

            const leaves = Node.texts(node)
            let lastWordIndex = -1

            for (const [leaf, relativeLeafPath] of leaves) {
                const leafPath = path.concat(relativeLeafPath)
                // Split by whitespace but keep each single space as an element in the resulting array
                // also account for zero width whitespaces so the highlighting ignores them
                const leafWords = leaf.text
                    .split(new RegExp(`( |${ZERO_WIDTH_WHITESPACE})`))
                    .filter((w) => w !== '')
                const actualWords = leafWords.filter(
                    (w) => w !== ' ' && w !== ZERO_WIDTH_WHITESPACE && !isPunctuation(w),
                )
                const startWordIndex = lastWordIndex + 1
                const endWordIndex = startWordIndex + actualWords.length - 1
                lastWordIndex = lastWordIndex + actualWords.length

                // Entire leaf is after current time
                if (timings[startWordIndex]?.start > currentTimeRef.current) {
                    ranges.push(
                        rangeForTimings(leafPath, 0, leaf.text.length, {
                            timingHighlight: 'after',
                        }),
                    )
                } else if (timings[endWordIndex]?.end < currentTimeRef.current) {
                    ranges.push(
                        rangeForTimings(leafPath, 0, leaf.text.length, {
                            timingHighlight: 'before',
                        }),
                    )
                } else {
                    const leafTimings = timings.slice(startWordIndex, endWordIndex + 1)
                    const [before, now] = splitTimings(leafTimings, currentTimeRef.current)

                    let beforeStartOffset = 0
                    let beforeEndOffset = 0
                    let nowStartOffset = 0
                    let nowEndOffset = 0
                    let afterStartOffset = 0
                    let afterEndOffset = leaf.text.length
                    let offset = 0
                    let currWordIndex = 0
                    let setBefore = false
                    let setNow = false

                    for (let i = 0; i < leafWords.length; i++) {
                        const itemOffset = leafWords[i].length
                        offset += itemOffset

                        if (
                            leafWords[i] !== ' ' &&
                            leafWords[i] !== ZERO_WIDTH_WHITESPACE &&
                            !isPunctuation(leafWords[i])
                        ) {
                            if (
                                !setBefore &&
                                before.length > 0 &&
                                before.length === currWordIndex
                            ) {
                                setBefore = true
                                beforeEndOffset = offset - itemOffset
                                afterStartOffset = beforeEndOffset + 1
                            }

                            if (
                                !setNow &&
                                now.length > 0 &&
                                before.length - 1 + now.length === currWordIndex
                            ) {
                                setNow = true
                                nowStartOffset = offset - itemOffset
                                nowEndOffset = offset
                                afterStartOffset = nowEndOffset
                            }

                            currWordIndex++
                        }
                    }

                    const beforeRange = rangeForTimings(
                        leafPath,
                        beforeStartOffset,
                        beforeEndOffset,
                        {
                            timingHighlight: 'before',
                        },
                    )
                    const nowRange = rangeForTimings(leafPath, nowStartOffset, nowEndOffset, {
                        timingHighlight: 'now',
                    })
                    const afterRange = rangeForTimings(leafPath, afterStartOffset, afterEndOffset, {
                        timingHighlight: 'after',
                    })

                    ranges.push(beforeRange, nowRange, afterRange)
                }
            }

            return ranges
        }

        return editor
    }
}

function splitTimings(
    timings: TimeRecord[],
    time: number,
): [TimeRecord[], TimeRecord[], TimeRecord[]] {
    let before: TimeRecord[]
    let after: TimeRecord[]
    let now: TimeRecord[]

    const i = timings.findIndex((timing) => timing.end >= time)

    if (i === -1) {
        before = timings.slice()
        now = after = []
    } else if (time > timings[i].start && time <= timings[i].end) {
        before = timings.slice(0, i)
        now = [timings[i]]
        after = timings.slice(i + 1)
    } else {
        before = timings.slice(0, i)
        now = []
        after = timings.slice(i)
    }

    return [before, now, after]
}

function rangeForTimings(
    path: Path,
    startOffset: number = 0,
    endOffset: number = 0,
    attributes: { [key: string]: any } = {},
): Range {
    const anchor = { path, offset: startOffset }
    const focus = { path, offset: endOffset }

    return { anchor, focus, ...attributes }
}
