import { TimingRange } from 'src/utils/range'
import { BaseSegment, BaseSegmentBlock, BaseSegmentCreateProps, SegmentPoint } from './Segment'
import { PartialBy } from 'src/utils/typescript'

export type TimelineTimingRange = TimingRange & { isOrginal: boolean }

export type TimeSegmentBlock = BaseSegmentBlock & {
    timings: TimelineTimingRange[]
    timing: TimingRange
}

export type TimeSegment = BaseSegment<TimeSegmentBlock> & { timing: TimingRange }

type TimeSegmentCreateProps = BaseSegmentCreateProps<TimeSegmentBlock> & { timing: TimingRange }

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeSegment = {
    create(props: TimeSegmentCreateProps): TimeSegment {
        return { ...BaseSegment.create(props), timing: props.timing ?? { start: 0, end: 0 } }
    },
    createBlock(block: TimeSegmentBlock) {
        return BaseSegment.createBlock(block)
    },
    getBlockStartTime(segment: TimeSegment, blockIndex: number): number | undefined {
        const block = BaseSegment.getBlock(segment, blockIndex)
        return block?.timing.start
    },

    getBlockEndTime(segment: TimeSegment, blockIndex: number): number | undefined {
        const block = BaseSegment.getBlock(segment, blockIndex)
        return block?.timing.end
    },

    getTimingsForBlock(segment: TimeSegment, blockIndex: number) {
        const block = BaseSegment.getBlock(segment, blockIndex)
        return block?.timings ?? []
    },

    getWordTimingAtPoint(
        segment: TimeSegment,
        point: SegmentPoint,
    ): TimelineTimingRange | undefined {
        const block = BaseSegment.getBlock(segment, point.blockIdx)
        return block?.timings[point.wordIdx]
    },

    getBlockIndexForTime(segment: TimeSegment, time: number, affinity?: 'start' | 'end') {
        const lastBlockIndex = segment.blocks.length - 1

        for (const [block, i] of BaseSegment.blocks<TimeSegmentBlock>(segment)) {
            const { timing } = block
            const nextTiming = BaseSegment.getBlock(segment, i + 1)?.timing

            if (TimingRange.isPointInside(timing, time)) {
                if (affinity === 'start' && time === timing.end && time === nextTiming?.start) {
                    return i + 1
                }

                return i
            }

            if (time < timing.start) {
                if (affinity === 'start') {
                    return i
                } else if (affinity === 'end') {
                    return Math.max(i - 1, 0)
                }
            }

            if (affinity === 'end' && time > timing.end && i === lastBlockIndex) {
                return i
            }
        }
    },

    merge(segment: TimeSegment, ...segments: TimeSegment[]): TimeSegment {
        return BaseSegment.merge<TimeSegment, TimeSegmentBlock>(segment, segments, {
            getBlockTiming: (block) => block.timing,
            getSegmentTiming: (segment) => segment.timing,
            getIndexForTiming: TimeSegment.getIndexForTiming,
            mergeBlock: (block, mergeBlock, startIndex, endIndex) => {
                const start = Math.max(startIndex === endIndex ? startIndex + 1 : startIndex, 0)
                const length = endIndex - startIndex

                block.timings.splice(start, length, ...mergeBlock.timings)
                TimeSegment.refreshBlock(block)
            },
            createEmptyBlock: () =>
                TimeSegment.createBlock({
                    editable: false,
                    hasExplicitBreak: false,
                    timing: { start: 0, end: 0 },
                    timings: [],
                }),
            refreshSegment: (s) => TimeSegment.refresh(s),
        })
    },

    slice(segment: TimeSegment, start: number, end: number) {
        return BaseSegment.slice(segment, start, end)
    },

    addBlock(segment: TimeSegment, block: PartialBy<TimeSegmentBlock, 'timing'>) {
        const newSegment = BaseSegment.addBlock(segment, {
            ...block,
            timing: block.timing ?? TimeSegment.calculateBlockTiming(block),
        })
        TimeSegment.refresh(newSegment)

        return newSegment
    },

    getIndexForTiming<B extends TimeSegmentBlock>(
        block: B,
        time: number,
        affinity: 'start' | 'end' = 'start',
    ) {
        if (!block.timings.length || time < block.timing.start) {
            return 0
        }
        const length = block.timings.length

        for (const [i, t] of block.timings.entries()) {
            if (time > t.start && time < t.end) {
                return i
            }

            if (affinity === 'start' && (time === t.start || (time <= t.end && i === length - 1))) {
                return i
            }

            if (time === t.end && affinity === 'end') {
                return i
            }

            const nextStart = block.timings[i + 1]?.start

            if (time >= t.end && (time <= nextStart || nextStart === undefined)) {
                if (affinity === 'start') {
                    return i + 1
                } else if (affinity === 'end') {
                    return i
                }
            }
        }

        return -1
    },

    refresh: (segment: TimeSegment) => {
        BaseSegment.refresh(segment)

        segment.timing.start = segment.blocks[0]?.timing.start ?? segment.timing.start
        segment.timing.end =
            segment.blocks[segment.blocks.length - 1]?.timing.end ?? segment.timing.end

        return segment
    },

    refreshBlock(block: TimeSegmentBlock) {
        block.timing.start = block.timings[0]?.start ?? block.timing.start
        block.timing.end = block.timings[block.timings.length - 1]?.end ?? block.timing.end
    },

    calculateBlockTiming(block: PartialBy<TimeSegmentBlock, 'timing'>): TimingRange {
        return {
            start: block.timings[0]?.start ?? 0,
            end: block.timings[block.timings.length - 1]?.end ?? 0,
        }
    },
}

export function getRangeFromTimings(timings: TimingRange[]): TimingRange {
    const start = timings[0]?.start ?? 0

    return {
        start,
        end: Math.max(timings[timings.length - 1]?.end ?? 0, start),
    }
}
