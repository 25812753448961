import { useEffect, useRef } from 'react'

export function useSlateClick(onClick: (evt: MouseEvent) => void) {
    const onClickRef = useRef(onClick)
    onClickRef.current = onClick

    const isMouseDownRef = useRef(false)
    const mouseDownCoordsRef = useRef<[number, number] | null>(null)

    useEffect(() => {
        const fn = (e: MouseEvent) => {
            const { target, clientX, clientY } = e

            // We only want to change the timing if the click was inside a descdant of a ContentView.
            // ContentView adds this data attribute specifically so we can check for its existence here.
            if (!(target instanceof HTMLElement) || !target.closest('[data-slate-content]')) {
                return
            }

            isMouseDownRef.current = true
            mouseDownCoordsRef.current = [clientX, clientY]
        }

        document.addEventListener('mousedown', fn, { capture: true })
        return () => {
            document.removeEventListener('mousedown', fn, { capture: true })
        }
    }, [])

    useEffect(() => {
        const fn = (e: MouseEvent) => {
            const { target, clientX, clientY } = e

            if (
                isMouseDownRef.current &&
                mouseDownCoordsRef.current &&
                areChordsMatching(mouseDownCoordsRef.current, [clientX, clientY]) &&
                target instanceof HTMLElement &&
                target.closest('[data-slate-content]')
            ) {
                onClickRef.current(e)
            }

            isMouseDownRef.current = false
            mouseDownCoordsRef.current = null
        }

        document.addEventListener('mouseup', fn, { capture: true })
        return () => {
            document.removeEventListener('mouseup', fn, { capture: true })
        }
    }, [onClick])
}

function areChordsMatching(
    coords: [number, number],
    other: [number, number],
    maxDelta: number = 10,
) {
    const [coordX1, coordY1] = coords
    const [coordX2, coordY2] = other

    const xDelta = Math.abs(coordX1 - coordX2)
    const yDelta = Math.abs(coordY1 - coordY2)

    return xDelta <= maxDelta && yDelta <= maxDelta
}
