import { createElement } from 'react'

import { TaskItem } from './Registry'
import { Task, TaskBase, TestTask } from 'src/models'
import { TaskJSON, TestTaskJSON } from 'src/network/responses'
import { TestTask as TestTaskComponent } from 'src/components/TestTask/TestTask'

class TestTaskItem extends TaskItem {
    constructor() {
        super('test')
    }

    isTaskJSON(json: TaskJSON): json is TestTaskJSON {
        return json.type === 'test'
    }

    isTask(task: Task): task is TestTask {
        return task.type === 'test'
    }

    convertToTask(base: TaskBase, json: TestTaskJSON): TestTask {
        return {
            ...base,
            type: 'test',
            payload: json.payload,
        }
    }

    render() {
        return createElement(TestTaskComponent)
    }
}

const instance = new TestTaskItem()
export { instance as TestTaskItem }
