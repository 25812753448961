import { useCallback } from 'react'
import { Editor, Point, Node, Range, Transforms } from 'slate'
import { Block, Content } from 'src/components/Editor/plugins/withTranscript'
import { useMousetrap } from 'src/hooks/useMousetrap'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { getEnv } from 'src/utils/env'
import { indexOfWordAtOrBefore } from 'src/utils/string'
import { useSlateClick } from './useSlateClick'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

/**
 * This hook does 2 things:
 * 1. It enables the keyboard shortcut "mod+i" to play from wherever the cursor is in the document currently.
 * 2. It returns an onClick function that will find the clicked position in the document and place the "audio cursor" there (using the editor timeline).
 */
export function useEditorAudioSync(editorRef: React.MutableRefObject<HTMLDivElement | null>) {
    const editor = useSlateStatic()
    const { play, seekTime } = useSessionMedia(['play', 'seekTime'])

    const syncTaskAudioWithCaretPosition = useCallback(
        (allowUneditable?: boolean, range = editor.selection) => {
            const caretPosition = pointFromRange(editor, range)

            if (!caretPosition) {
                return
            }

            const [[block]] = Editor.nodes(editor, { at: caretPosition, match: Block.isBlock })

            if (!block.editable && !allowUneditable) {
                return
            }

            const time = getTimeByPoint(editor, caretPosition)

            if (time) {
                seekTime(time)
            }
        },
        [editor, seekTime],
    )

    const playFromCaretPosition = useCallback(() => {
        syncTaskAudioWithCaretPosition()
        play()
    }, [play, syncTaskAudioWithCaretPosition])

    useMousetrap('mod+i', playFromCaretPosition, {
        label: 'Audio: Play from text cursor',
        preventDefault: true,
    })

    const onClick = useCallback(
        (e: MouseEvent) => {
            if (!(e.target instanceof HTMLElement)) {
                return
            }

            // We only want to change the timing if the click was inside a descdant of a ContentView.
            // ContentView adds this data attribute specifically so we can check for its existence here.
            if (!e.target.closest('[data-slate-content]')) {
                return
            }
            try {
                // Only clicks on Content elements or their descendants
                const node = ReactEditor.toSlateNode(editor, e.target)
                const path = ReactEditor.findPath(editor, node)

                if (
                    Array.from(
                        Editor.nodes(editor, { at: path, match: (n) => Content.isContent(n) }),
                    ).length === 0
                ) {
                    console.log(
                        "Clicked on node that has no Content ancestor. This won't seek audio.",
                    )
                    return
                }
            } catch (e) {
                // ignore errors here because they are not interesting
            }

            // only select + change the timing on the first click. If its a double/tripple click, ignore the 2nd/3rd click.
            if (e.detail === 1) {
                // for whatever reason, the editor.selection might be out-of-sync here,
                // so we're wrapping everything in a setTimeout to make sure its in sync.
                setTimeout(() => {
                    try {
                        // make sure, we sync slates selection with the dom selection first
                        const range = ReactEditor.findEventRange(editor, e)
                        if (!editor.selection || !Range.includes(editor.selection, range)) {
                            Transforms.select(editor, range)
                        }

                        syncTaskAudioWithCaretPosition(true, range)
                    } catch (e) {
                        // ignore errors here because they are not interesting
                    }
                }, 0)
            }
        },
        [editor, syncTaskAudioWithCaretPosition],
    )

    useSlateClick(onClick)
}

export const getTimeByPoint = (editor: Editor, point: Point) => {
    try {
        const [block, blockPath] = Editor.node(editor, point, { depth: 1 })
        const blockIndex = blockPath[0]

        if (Block.isBlock(block)) {
            const textUntilCaret = Editor.string(editor, {
                anchor: Editor.start(editor, blockPath),
                focus: point,
            })
            const offset = textUntilCaret.length
            const blockText = Node.string(block)
            const i = indexOfWordAtOrBefore(offset, blockText)

            let time: number
            // cursor is before any word in the block
            if (i === -1) {
                time = editor.timeline.getBlockStartTime(blockIndex)
            } else {
                const timings = editor.timeline.getTimingsForBlock(blockIndex)!
                // we want the word under the cursor to be considered as 'now', so we need to add a small epsilon to its start
                time = timings[i].start + 0.001
            }

            return time
        }
    } catch (err) {
        if (getEnv() !== 'production') {
            throw err
        }
    }
}

/**
 * Get the Slate Point (caret position) from `range` which defaults to the current editor selection.
 * The current selection is preferably fetched from Slate's editor directly since it's the most performant (it's already calculated)
 * but in some cases, like read-only content (contenteditable=false), it can be null.
 * In these cases, we can get the caret position from the global window.getSelection() function call.
 */
export function pointFromRange(editor: Editor, range = editor.selection): Point | null {
    if (range) {
        if (Range.isCollapsed(range)) {
            return Editor.point(editor, range, { edge: 'start' })
        } else {
            return null
        }
    } else {
        const domSelection = window.getSelection()
        if (!domSelection || !domSelection.isCollapsed || !domSelection.anchorNode) {
            return null
        }

        const caretPosition = ReactEditor.toSlatePoint(
            editor,
            [domSelection.anchorNode!, domSelection.anchorOffset],
            { exactMatch: false, suppressThrow: true },
        )
        return caretPosition
    }
}
