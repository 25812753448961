import { Editor, Node } from 'slate'
import { splitToWords } from 'src/utils/string'
import { Block } from '../withTranscript'

export function validateTimelineData(editor: Editor) {
    const editorBlockLength = editor.children.length
    const timelineBlockLength = editor.timeline.current.blocks.length

    if (editorBlockLength !== timelineBlockLength) {
        throw new Error(
            `The editor and timeline data are out of sync. The editor has ${editorBlockLength} blocks, while the timeline has ${timelineBlockLength} blocks.`,
        )
    }

    const changedBlockIndexes = getChangedBlockIndexes(editor)

    for (const blockIndex of changedBlockIndexes) {
        const contents = Node.string(Node.get(editor, [blockIndex]))
        const words = splitToWords(contents)

        const timelineBlock = editor.timeline.getBlockChunk(blockIndex)
        if (!timelineBlock) {
            throw new Error(
                `The editor and timeline data are out of sync. block ${blockIndex} is missing!`,
            )
        }

        for (const [i, word] of words.entries()) {
            const timelineWord = timelineBlock.words[i]
            const timing = timelineBlock.timings[i]

            if (timelineWord !== word) {
                throw new Error(
                    `The editor and timeline data are out of sync. word ${i} in block ${blockIndex} is different! (editor: "${word}", timeline: "${timelineWord}")`,
                )
            }

            if (!timing) {
                throw new Error(
                    `The editor and timeline data are out of sync. timing ${i} in block ${blockIndex} is missing!`,
                )
            }
        }
    }
}

function getChangedBlockIndexes(editor: Editor): number[] {
    const changedBlockIndexes: number[] = []

    const set: Set<number> = new Set()
    const changeBlock = (blockIndex: number) => set.add(blockIndex)

    const addBlock = (blockIndex: number) => {
        for (const otherBlockIndex of Array.from(set).sort((a, b) => a - b)) {
            if (otherBlockIndex < blockIndex) {
                continue
            }

            set.delete(otherBlockIndex)
            set.add(otherBlockIndex + 1)
        }

        set.add(blockIndex)
    }

    const removeBlock = (blockIndex: number) => {
        for (const otherBlockIndex of Array.from(set).sort((a, b) => a - b)) {
            if (otherBlockIndex < blockIndex) {
                continue
            }

            set.delete(otherBlockIndex)
            set.add(Math.max(otherBlockIndex - 1, 0))
        }
    }

    for (const op of editor.operations) {
        switch (op.type) {
            case 'set_selection':
                break
            case 'split_node':
                if (Block.isBlock(op.properties)) {
                    addBlock(op.path[0] + 1)
                    changeBlock(op.path[0])
                } else {
                    changeBlock(op.path[0])
                }
                break
            case 'remove_node':
                if (op.path.length === 1) {
                    removeBlock(op.path[0])
                } else {
                    changeBlock(op.path[0])
                }
                break
            case 'insert_node':
                if (Block.isBlock(op.node)) {
                    addBlock(op.path[0])
                } else {
                    changeBlock(op.path[0])
                }
                break
            case 'merge_node':
                if (Block.isBlock(op.properties)) {
                    removeBlock(op.path[0] + 1)
                    changeBlock(op.path[0])
                } else {
                    changeBlock(op.path[0])
                }
                break
            case 'move_node':
                changeBlock(op.newPath[0])
                break
            default:
                changeBlock(op.path[0])
                break
        }
    }

    return changedBlockIndexes
}
