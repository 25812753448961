import React from 'react'

import { useDragLayer } from 'react-dnd'
import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

import { VideoPlayer } from 'src/components/VideoPlayer/VideoPlayer'
import {
    VideoResizeEdge,
    VideoPlayerResizeType,
} from 'src/components/VideoPlayer/DraggableVideoPlayer'
import { useVideo } from 'src/state/VideoProvider'

const DraggingLayer = styled.div`
    position: fixed;
    pointer-events: none;
    z-index: ${theme('zIndexes.draggingLayer')};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: absolute;
    max-width: 400px;
    max-height: 400px;
`

export const CustomVideoDragLayer = () => {
    const { videoDimensions, calculateResizedVideoDimensions } = useVideo()
    const { isDragging, currentOffset, isResize, resizeEdge } = useDragLayer((monitor) => ({
        isResize: monitor.getItemType() === VideoPlayerResizeType,
        resizeEdge: monitor.getItem<{ edge: VideoResizeEdge } | null>()?.edge,
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }))

    if (!isDragging) {
        return null
    }

    let width = videoDimensions.width
    let height = videoDimensions.height

    let x = currentOffset?.x
    let y = currentOffset?.y

    if (isResize && currentOffset && resizeEdge) {
        const { coords, dimension } = calculateResizedVideoDimensions(currentOffset, resizeEdge)

        width = dimension.width
        height = dimension.height

        x = coords.x
        y = coords.y
    }

    return (
        <DraggingLayer>
            <Container
                style={{
                    transform: `translate3d(${x}px, ${y}px, 0`,
                }}
            >
                <VideoPlayer isDragging={isDragging} width={width} height={height} />
            </Container>
        </DraggingLayer>
    )
}
