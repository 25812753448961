import { useMemo, useState } from 'react'
import { ItemRenderer, ItemListRenderer, Select } from '@blueprintjs/select'
import { Tooltip2 } from '@blueprintjs/popover2'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'
import { Editor } from 'slate'
import { ReactEditor } from 'slate-react'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'
import { MenuItemInterface } from 'src/models'
import {
    SelectButton,
    SelectMenu,
    SelectMenuItem,
    SelectMenuDivider,
} from 'src/components/Common/styled'
import { ReadBackIcon } from 'src/components/icons'
import { DepositionIcon, StopHandIcon, ReplayIcon } from '@verbit-ai/icons-library'
import { theme } from 'src/components/styled'

const LegalAnnotationSelectContainer = styled.div`
    width: 48px;
    margin-right: 4px;
    height: 100%;

    display: flex;
    align-items: center;

    border: solid 1px transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`

const TypedSelect = styled(Select.ofType<GroupedMenuItem>())`
    border: solid 1px transparent;
    ${ifProp(
        'disabled',
        css`
            pointer-events: none;
        `,
    )}

    &.bp4-popover-wrapper {
        .bp4-popover-target {
            flex: 1;

            > div {
                flex: 1;
                overflow: hidden;
            }
        }
    }

    &:active,
    &:focus-visible,
    &:focus-within {
        border: solid 1px ${palette('blue', 0)};
        border-color: ${palette('blue', 0)} !important;
        border-radius: 4px;
    }
`

/*
The min height used to be hardcoded as 40px; The font-size this was used at is 15px.
Now the onboarding tasks 'transcript_intro' + 'transcript_latest' have a lower font-size so the legal annotation select box looks huge.
To prevent this, we now set the height of the select box relative to the font-size using the em unit. It is calculated by the original ratio.
*/
const StyledSelectButton = styled(SelectButton)`
    &.bp4-button {
        min-height: ${7 / 3}em;
        font-size: inherit;

        line-height: 16px;

        .bp4-button-text {
            margin-right: 0;
            font-weight: 400;
        }
    }
`

const AnnotationSign = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-right: 16px;
    color: ${palette('navy', 0)};
    font-size: ${15 / 18}em;

    svg {
        width: 22px;
        height: 22px;
        color: ${palette('cloudBlue', 2)};
    }
`

const ButtonIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;

    svg {
        width: 16px;
        height: 16px;
        color: ${palette('cloudBlue', 2)};
    }
`

const ANNOTATION_ICONS: Record<string, JSX.Element> = {
    objection: <StopHandIcon />,
    readback: <ReadBackIcon />,
    playback: <ReplayIcon />,
}

const renderItem: ItemRenderer<GroupedMenuItem> = (item, { handleClick, modifiers }) => (
    <SelectMenuItem
        key={item.key}
        onClick={handleClick}
        active={modifiers.active}
        disabled={modifiers.disabled}
        icon={<AnnotationSign>{ANNOTATION_ICONS[item.key] || item.label[0]}</AnnotationSign>}
        text={item.label}
    />
)

const renderItemList: ItemListRenderer<GroupedMenuItem> = ({
    items,
    itemsParentRef,
    renderItem,
}) => {
    const menuItems: (JSX.Element | null)[] = []

    let prevItem: GroupedMenuItem | undefined
    for (let [i, item] of items.entries()) {
        if (item.group !== 0 && prevItem?.group !== item.group) {
            menuItems.push(<SelectMenuDivider key={`divider-group-${item.group}`} />)
        }
        menuItems.push(renderItem(item, i))
        prevItem = item
    }
    return <SelectMenu ulRef={itemsParentRef}>{menuItems}</SelectMenu>
}

interface GroupedMenuItem extends MenuItemInterface {
    group: number
}

interface LegalAnnotationSelectProps {
    element: Block
    editor: Editor
    items: MenuItemInterface[][]
    value?: string
    onChange: (item: MenuItemInterface) => void
    disabled?: boolean
    disabledReason?: string
    isRealTimeReadOnly?: boolean
}

export const LegalAnnotationSelect = ({
    element,
    editor,
    items,
    value,
    onChange,
    disabled,
    disabledReason,
    isRealTimeReadOnly,
}: LegalAnnotationSelectProps) => {
    const [activeItem, setActiveItem] = useState<GroupedMenuItem | null>(null)
    const [blockIndex] = ReactEditor.findPath(editor, element)

    const flattenedItems = useMemo(() => {
        const flattened: GroupedMenuItem[] = []
        for (let [groupIdx, group] of items.entries()) {
            for (let item of group) {
                flattened.push({ ...item, group: groupIdx })
            }
        }

        return flattened
    }, [items])

    const selectedItem: GroupedMenuItem | undefined = useMemo(() => {
        return flattenedItems.find((item) => item.key === value)
    }, [flattenedItems, value])

    const buttonIcon = selectedItem ? (
        ANNOTATION_ICONS[selectedItem.key]
    ) : (
        <DepositionIcon color={theme?.palette?.grey?.[8]} />
    )

    return (
        <LegalAnnotationSelectContainer>
            <Tooltip2 position="top" interactionKind="hover" content={disabledReason}>
                <TypedSelect
                    activeItem={activeItem}
                    onActiveItemChange={setActiveItem}
                    items={flattenedItems}
                    className="select-control"
                    disabled={disabled}
                    onItemSelect={onChange}
                    itemListRenderer={renderItemList}
                    itemRenderer={renderItem}
                    filterable={false}
                    popoverProps={{
                        onClosed: () => {
                            if (document.activeElement instanceof HTMLElement) {
                                document.activeElement.blur()
                            }
                        },
                        onOpening: () => setActiveItem(selectedItem ?? flattenedItems[0]),
                        popoverClassName: 'trax-select-popover',
                        position: 'bottom-left',
                        minimal: true,
                        modifiers: {
                            offset: { offset: '1,4' },
                            preventOverflow: { enabled: false },
                        },
                    }}
                >
                    <StyledSelectButton
                        id={'legal-annotation-select-' + blockIndex}
                        data-testid={'legal-annotation-select-button'}
                        $hasValue={Boolean(selectedItem)}
                        className={'legal-annotation-select-' + blockIndex}
                        icon={
                            !isRealTimeReadOnly && (
                                <ButtonIconWrapper>{buttonIcon}</ButtonIconWrapper>
                            )
                        }
                        rightIcon="caret-down"
                        disabled={disabled}
                        $isRealTimeReadOnly={isRealTimeReadOnly}
                    >
                        {selectedItem && !buttonIcon && selectedItem.label[0]}
                    </StyledSelectButton>
                </TypedSelect>
            </Tooltip2>
        </LegalAnnotationSelectContainer>
    )
}
