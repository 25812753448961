import { countSyllables } from 'src/utils/string'
import { TimeSegment, TimedTextSegment } from '../timeline'
import { SegmentPoint, SegmentRange } from '../timeline/Segment'
import { TimeAligner } from './TimeAligner'
import { TimingRange } from 'src/utils/range'

export class HeuristicAligner implements TimeAligner {
    MIN_SYL_DURATION = 0.05
    MAX_SYL_DURATION = 0.6

    align(origSegment: TimedTextSegment, point: SegmentPoint): TimedTextSegment {
        const startAnchor = this.getLeftAnchor(origSegment, point)
        const endAnchor = this.getRightAnchor(origSegment, point)
        const range = { from: startAnchor, to: endAnchor }

        const { count: syllablesCount, map: syllablesMap } = this.countSyllables(origSegment, range)
        const timingRange = this.getTimingRangeForRange(origSegment, range)
        const duration = timingRange.end - timingRange.start

        const sylDuration =
            syllablesCount === 0 ? 0 : Math.min(this.MAX_SYL_DURATION, duration / syllablesCount)

        let currTime = timingRange.start
        let i = 0
        for (const [, timing] of TimedTextSegment.words(origSegment, range)) {
            const sylCount = syllablesMap[i]
            const wordDuration = Math.min(sylCount * sylDuration, duration)

            timing.start = currTime
            timing.end = currTime + wordDuration

            currTime += wordDuration

            i++
        }

        return origSegment
    }

    getTimingRangeForRange(segment: TimedTextSegment, range: SegmentRange): TimingRange {
        const { from, to } = range

        const { start } = TimeSegment.getWordTimingAtPoint(segment, from)!
        const { end } = TimeSegment.getWordTimingAtPoint(segment, to)!

        return { start, end }
    }

    countSyllables(segment: TimedTextSegment, range: SegmentRange) {
        const { from, to } = range
        let map: { [key: number]: number } = {}
        let count = 0
        let i = 0

        for (const [word] of TimedTextSegment.words(segment, { from, to })) {
            count += countSyllables(word)
            map[i] = count

            i++
        }

        return { count, map }
    }

    getLeftAnchor(segment: TimedTextSegment, startLocation: SegmentPoint): SegmentPoint {
        let lastLocation: SegmentPoint | null = null

        for (const [, timing, location] of TimedTextSegment.words(segment, {
            reverse: true,
            to: startLocation,
        })) {
            if (timing.isOrginal) {
                return lastLocation ?? startLocation
            }

            lastLocation = location
        }

        return lastLocation ?? startLocation
    }

    getRightAnchor(segment: TimedTextSegment, endLocation: SegmentPoint): SegmentPoint {
        let lastLocation: SegmentPoint | null = null

        for (const [, timing, location] of TimedTextSegment.words(segment, { to: endLocation })) {
            if (timing.isOrginal) {
                return lastLocation ?? endLocation
            }

            lastLocation = location
        }

        return lastLocation ?? endLocation
    }
}
