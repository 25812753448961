import { Block } from 'src/components/Editor/plugins/withTranscript'
import { LiveTimeCodeContainer, LiveTimeCodeSegment, LiveTimeCodeField } from './LiveTimeCodeStyled'

interface LiveTimeCodeProps {
    element: Block
    isRealTimeReadOnly: boolean
}

export const LiveTimeCode = ({ element, isRealTimeReadOnly }: LiveTimeCodeProps) => {
    return (
        <LiveTimeCodeContainer contentEditable={false} $isRealTimeReadOnly={isRealTimeReadOnly}>
            <LiveTimeCodeSegment>
                <LiveTimeCodeField>{element?.mediaTimeCode || '00:00:00'}</LiveTimeCodeField>
            </LiveTimeCodeSegment>
        </LiveTimeCodeContainer>
    )
}
