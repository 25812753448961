import { TimingRange } from 'src/utils/range'

import { LegalMetadata } from './legal'
import { TagData, TagJSON, toTag } from './tag'
import { SpeakerJSON } from './speaker'

export interface WordJSON {
    word: string
    type: 'word' | 'section' | 'label'
    start: number
    end: number
    speaker_id: string | null
    legal?: LegalMetadata
    section?: {
        type: string
        text: string
    }
    break?: boolean
    tag?: TagJSON
    timecode?: string
}

export interface Word {
    text: string
    type: 'word' | 'section' | 'label'
    timing: TimingRange
    speakerId: string | null
    section?: {
        type: string
        text: string
    }
    legal?: LegalMetadata
    break: boolean
    tag?: TagData
    timecode?: string
}

export const toWord = (json: WordJSON): Word => ({
    text: json.word,
    type: json.type,
    timing: {
        start: json.start,
        end: json.end,
    },
    speakerId: json.speaker_id,
    section: json.section,
    legal: json.legal,
    break: !!json.break,
    tag: toTag(json.tag),
    timecode: json.timecode,
})

export type SegmentType = 'before' | 'editable' | 'after' | null

export interface Segment {
    type: SegmentType
    words: Word[]
    timing: TimingRange
    editable: boolean
}

interface TranscriptSpeaker {
    id: string
    name: string
}

export interface TranscriptJSON {
    start: number
    end: number
    id: string
    speakers: SpeakerJSON[]
    words: WordJSON[]
}

export interface Transcript extends Segment {
    id: string
    speakers: TranscriptSpeaker[]
}

export const toTranscript = (json: TranscriptJSON): Transcript => ({
    editable: true,
    id: json.id,
    speakers: json.speakers,
    timing: { start: json.start, end: json.end },
    type: 'editable',
    words: json.words.map(toWord),
})

export interface TranscriptWebsocketMessageJSON extends Omit<TranscriptJSON, 'type'> {
    type: 'new' | 'update'
}

export interface TranscriptWebsocketMessage extends Omit<Transcript, 'type'> {
    type: 'new' | 'update'
}

export const toTranscriptWebsocketMessage = (
    json: TranscriptWebsocketMessageJSON,
): TranscriptWebsocketMessage => ({
    ...toTranscript(json),
    type: json.type,
})

export interface SubmitSegmentPayload {
    start: number
    end: number
    words: Partial<WordJSON>[]
}
