import { useCallback } from 'react'
import { useToast } from '@chakra-ui/react'
import { mockManager } from 'src/network/mocks'
import { useLiveMachine } from 'src/state/state-machines/LiveMachine/LiveMachineProvider'
import { CriticalIssueSnackBar } from './Snackbar'

const TOAST_ID = 'critical-issue-toast'

const TOAST_DURATION_MS = 6_000

export function useLiveCriticalIssueHandler() {
    const [, send] = useLiveMachine([])
    const toast = useToast()

    return useCallback(
        (err: any) => {
            toast({
                id: TOAST_ID,
                variant: 'critical',
                duration: TOAST_DURATION_MS,
                isClosable: false,
                render: () => <CriticalIssueSnackBar />,
            })

            send('REFRESH_TRANSCRIPT')
            console.error(err)
            window.Rollbar?.log(err)
            console.debug(`[MOCK] mock data dump:`, mockManager.getMockData())
        },
        [send, toast],
    )
}
