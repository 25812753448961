import { useReducer } from 'react'

import { createMediaService } from 'src/factories/MediaService'
import { useSessionStatus } from './SessionStatusProvider'
import { VideoProvider } from './VideoProvider'

const [MediaProvider, useSessionMedia, useSessionMediaService] = createMediaService()

export { useSessionMedia, useSessionMediaService }

type SessionMediaProviderProps = {
    children: React.ReactNode
}

export function SessionMediaProvider({ children }: SessionMediaProviderProps) {
    const { sessionStatus } = useSessionStatus(['sessionStatus.audioUrl'])

    return (
        <MediaProvider label="MediaPlayer" mediaUrl={sessionStatus?.audioUrl ?? null}>
            <VideoProvider>{children}</VideoProvider>
        </MediaProvider>
    )
}

export function useSessionMediaCurrentTime() {
    const [, update] = useReducer((s: number) => s + 1, 0)
    useSessionMediaService(update, ['currentTime'])
}
