import { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'
import { Slider } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { Tooltip } from '@verbit-ai/verbit-ui-library'
import { KeyShortcutLabels } from 'src/utils/keyboard'
import * as Icons from 'src/components/icons'
// import { VolumeOffIcon, VolumeIcon } from '@verbit-ai/icons-library'

interface PlayButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    playing: boolean
}

interface SliderProps {
    onChange: (value: number) => void
    value: number
    isMuted: boolean
}

const Button = styled.button`
    appearance: none;
    outline: none;
    border: none;
    cursor: ${ifProp('disabled', 'default', 'pointer')};
    background-color: transparent;
`

export const AudioPlayerButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    padding: 0;
    fill: currentColor;
    color: ${palette('navy', 0)};
    transition: color 250ms ease-out;

    &:hover {
        color: ${palette('blue', 0)};
    }

    &:active {
        color: ${palette('navy', 0)};
    }

    &:disabled {
        color: ${palette('cloudBlue', 2)};
    }

    svg {
        width: 24px;
        max-height: 24px;
        height: auto;
    }
`

const SliderPopover = styled(Popover2)`
    .bp4-popover {
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 6px;
    }

    .slider {
        .bp4-slider-progress.bp4-intent-primary {
            background: ${palette('navy', 2)};
        }

        .bp4-slider-handle {
            background: ${palette('white', 0)};
        }
    }
`

const SliderWrapper = styled.div`
    padding: 5px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    height: 165px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .bp4-slider.bp4-vertical {
        min-width: unset;
        width: 20px;
        margin-left: 4px;
        height: 130px;
        margin-bottom: 10px;
    }
`

export const BackwardButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Tooltip label={`Backward ${KeyShortcutLabels('BACKWARD')}`} placement="top" hasArrow>
        <AudioPlayerButton {...props}>
            <Icons.PrevIconSVG />
        </AudioPlayerButton>
    </Tooltip>
)

export const ForwardButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Tooltip label={`Forward ${KeyShortcutLabels('FORWARD')}`} placement="top" hasArrow>
        <AudioPlayerButton {...props}>
            <Icons.NextIconSVG />
        </AudioPlayerButton>
    </Tooltip>
)

export const PlayButton = ({ playing, ...rest }: PlayButtonProps) => {
    const icon = playing ? <Icons.PauseIconSVG /> : <Icons.PlayIconSVG />
    return (
        <Tooltip label={`Play ${KeyShortcutLabels('PLAY')}`} placement="top" hasArrow>
            <AudioPlayerButton data-testid={'audio-play-button'} {...rest}>
                {icon}
            </AudioPlayerButton>
        </Tooltip>
    )
}

export const ReplayButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Tooltip label={`Replay ${KeyShortcutLabels('REPLAY')}`} placement="top" hasArrow>
        <AudioPlayerButton {...props}>
            <Icons.ReplayIconSVG />
        </AudioPlayerButton>
    </Tooltip>
)

export const VolumeButton = (props: SliderProps) => (
    <SliderPopover
        position={'top'}
        modifiers={{
            offset: { enabled: true },
            arrow: { enabled: false },
        }}
        content={
            <SliderWrapper>
                <Slider
                    {...props}
                    vertical={true}
                    labelRenderer={false}
                    initialValue={0}
                    className="slider"
                    min={0}
                    max={1}
                    stepSize={0.1}
                />
            </SliderWrapper>
        }
    >
        <Tooltip label="Volume" placement="top" hasArrow>
            <AudioPlayerButton>
                {/* NOTE: Keep the original code commented for now while icons are being finalized in the Icons-Library */}
                {/* {props.isMuted ? <VolumeOffIcon /> : <VolumeIcon />} */}

                {/* reverted to original code above */}
                {props.isMuted ? <Icons.MuteIcon /> : <Icons.SoundIcon />}
            </AudioPlayerButton>
        </Tooltip>
    </SliderPopover>
)
