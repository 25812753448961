import { createElement } from 'react'

import { PrePopulationTask as PrePopulationTaskComponent } from 'src/components/PrePopulationTask/PrePopulationTask'
import { Task, TaskBase, PrePopulationTask } from 'src/models'
import { TaskJSON, PrePopulationTaskJson } from 'src/network/responses'
import { TaskItem } from './Registry'

class PrePopulationTaskItem extends TaskItem {
    constructor() {
        super('gloss_population')
    }

    isTaskJSON(json: TaskJSON): json is PrePopulationTaskJson {
        return json.type === 'gloss_population'
    }

    isTask(task: Task): task is PrePopulationTask {
        return task.type === 'gloss_population'
    }

    convertToTask(base: TaskBase, json: PrePopulationTaskJson): PrePopulationTask {
        return {
            ...base,
            type: 'gloss_population',
            payload: {
                attachments: json.payload.attachments,
            },
        }
    }

    getTitle(): string {
        return 'Identify terms, names and places in the files below, and add them to the Glossary'
    }

    render() {
        return createElement(PrePopulationTaskComponent)
    }
}

const instance = new PrePopulationTaskItem()
export { instance as PrePopulationTaskItem }
