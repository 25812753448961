import styled from 'styled-components/macro'
import { palette, ifProp } from 'styled-tools'

export const LiveTimeCodeContainer = styled.div<{ $isRealTimeReadOnly?: boolean }>`
    display: flex;
    position: absolute;
    flex-direction: column;
    overflow: hidden;
    top: ${ifProp('$isRealTimeReadOnly', '5px', '9px')};
    left: ${ifProp('$isRealTimeReadOnly', '-74px', '0px')};
    width: 242px;
    height: 50px;
    background: none;
    z-index: 20;
`

export const LiveTimeCodeSegment = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    text-align: right;
    width: 242px;
    height: 40px;
    margin-bottom: 4px;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.24px;
`

export const LiveTimeCodeField = styled.span`
    display: flex;
    justify-content: flex-end;
    position: relative;
    text-align: right;
    margin-top: 32px;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    font-weight: 600;
    background: none;
    border: none;
    resize: none;
    color: ${palette('grey', 8)};
    letter-spacing: 0.24px;
    pointer-events: none;
    user-select: none;
`
