import mixpanel from 'mixpanel-browser'

import { ClientProps } from 'src/analytics/client'

export class MixPanelClient {
    private apiKey?: string
    private userProps: ClientProps = {}

    constructor(apiKey?: string) {
        this.apiKey = apiKey
    }

    initialize() {
        if (!this.apiKey) {
            return
        }

        mixpanel.init(this.apiKey)

        if (Object.keys(this.userProps).length) {
            this.sendUserProps()
        }
    }

    sendUserProps() {
        for (const [key, value] of Object.entries(this.userProps)) {
            mixpanel?.people?.set({ [key]: value })
        }
    }

    extendUserProps(additionalProps: ClientProps) {
        this.userProps = {
            ...this.userProps,
            ...additionalProps,
        }
        this.sendUserProps()
    }

    identifyMixpanelUser(workerId: string) {
        mixpanel.identify(workerId)
    }

    send(eventName: string, event: ClientProps) {
        mixpanel.track(eventName, event)
    }
}
