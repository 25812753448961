import styled from 'styled-components/macro'
import { Popover2 } from '@blueprintjs/popover2'
import { VolumeMaxIcon, VolumeOffIcon } from '@verbit-ai/icons-library'
import { Slider, SliderFilledTrack, SliderThumb, SliderTrack } from '@chakra-ui/react'

const StyledVolumeButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 8px;
    margin: 0 5px;

    font-size: 16px;
    line-height: 12px;
    font-weight: 600;
    border-radius: 16px;

    &:hover {
        background-color: #f4f6fe;
    }
`

const SliderPopover = styled(Popover2)`
    .bp4-popover {
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 6px;
    }

    .slider {
        .bp4-slider-progress.bp4-intent-primary {
            background: #1a4af0;
        }

        .bp4-slider-handle {
            background: #1a4af0;
        }
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    height: 120px;

    background: #f4f6fe;
    box-shadow: 0px 4px 14px 0px #0000002a;
    border-radius: 16px;
`

type VolumeButtonProps = {
    value: number
    isMuted: boolean
    onChange: (value: number) => void
}

export function VolumeButton({ value, isMuted, onChange }: VolumeButtonProps) {
    return (
        <SliderPopover
            position={'top'}
            modifiers={{
                offset: { enabled: true },
                arrow: { enabled: false },
            }}
            content={
                <ContentContainer>
                    <Slider
                        orientation="vertical"
                        min={0}
                        max={1}
                        step={0.05}
                        value={value}
                        onChange={onChange}
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                </ContentContainer>
            }
        >
            <StyledVolumeButton>
                {isMuted ? <VolumeOffIcon /> : <VolumeMaxIcon color="#1a4af0" />}
            </StyledVolumeButton>
        </SliderPopover>
    )
}
