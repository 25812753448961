import { useCallback, useEffect, useReducer, useState } from 'react'

import { TranscriptWebsocketMessage } from 'src/models'
import { useAppEvents } from 'src/state/AppEventsProvider'

type UseAutoScrollOptions = {
    autoScrollTriggerThresholdPx: number
}

export function useAutoScroll(ref: React.RefObject<HTMLDivElement>, options: UseAutoScrollOptions) {
    const { autoScrollTriggerThresholdPx } = options

    const websocket = useAppEvents()

    const [scrollToBottomUpdateCounter, triggerScrollToBottom] = useReducer((x) => x + 1, 0)
    const [isAutoScrollMode, setIsAutoScrollMode] = useState(true)
    const [hasUnreadContents, setHasUnreadContents] = useState(false)
    const scrollingContainer = ref.current

    const enterAutoScroll = useCallback(() => {
        setIsAutoScrollMode(true)
        setHasUnreadContents(false)
    }, [])

    const exitAutoScroll = useCallback(() => {
        setIsAutoScrollMode(false)
    }, [])

    const scrollToBottom = useCallback(() => {
        if (scrollingContainer) {
            const { scrollHeight } = scrollingContainer
            scrollingContainer.scrollTo({ top: scrollHeight, behavior: 'smooth' })
        }
    }, [scrollingContainer])

    useEffect(() => {
        let lastScrollTop = scrollingContainer?.scrollTop

        const onScroll = () => {
            if (lastScrollTop !== undefined && scrollingContainer) {
                const { scrollTop, scrollHeight, clientHeight } = scrollingContainer
                const isScrollingUp = lastScrollTop - scrollTop >= 1

                const maxScrollTop = scrollHeight - clientHeight
                const shouldEnterAutoScroll =
                    scrollTop >= maxScrollTop - autoScrollTriggerThresholdPx

                if (isScrollingUp) {
                    exitAutoScroll()
                } else if (shouldEnterAutoScroll) {
                    enterAutoScroll()
                }
            }

            lastScrollTop = scrollingContainer?.scrollTop
        }

        scrollingContainer?.addEventListener('scroll', onScroll)
        return () => {
            scrollingContainer?.removeEventListener('scroll', onScroll)
        }
    }, [autoScrollTriggerThresholdPx, enterAutoScroll, exitAutoScroll, scrollingContainer])

    useEffect(() => {
        if (!websocket) return

        const onTranscript = (event: TranscriptWebsocketMessage) => {
            if (event.type === 'new') {
                if (isAutoScrollMode) {
                    triggerScrollToBottom()
                } else {
                    setHasUnreadContents(true)
                }
            }
        }

        websocket.on('transcript', onTranscript)
        return () => {
            websocket.off('transcript', onTranscript)
        }
    }, [isAutoScrollMode, scrollToBottom, websocket])

    useEffect(() => {
        if (isAutoScrollMode) {
            scrollToBottom()
        }
    }, [isAutoScrollMode, scrollToBottomUpdateCounter, scrollToBottom])

    return {
        isAutoScrollMode,
        hasUnreadContents,
        enterAutoScroll,
    }
}
