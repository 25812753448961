import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { palette, withProp } from 'styled-tools'
import { addSeconds, formatDistance } from 'date-fns'

import { Task } from 'src/models'
import { TranscriptionTaskItem } from 'src/tasks/TranscriptionTask'
import { transparentize } from 'polished'

export function useCurrentSection(task?: Task) {
    if (task && TranscriptionTaskItem.isTask(task) && task.payload.section != null) {
        return {
            sectionTitle: task.payload.section.text,
            sectionStartTime: task.payload.section.start,
            taskStartTime: task.payload.text.editable.timing.start,
        }
    } else {
        return null
    }
}

const Container = styled.div`
    display: none;
    background-color: ${withProp(palette('blue', 0) as any, transparentize(0.85))};
    border-radius: 5px;
    padding: 7px 12px;
    margin: 0 auto;
    color: ${palette('navy', 6)};
    line-height: 18px;
`

const Section = styled.span`
    font-size: 16px;
    vertical-align: middle;
`

const ElapsedTime = styled.span`
    font-size: 16px;
    font-weight: 600;
    vertical-align: middle;
    margin-left: 4px;
`

interface TaskBarCurrentSectionProps {
    sectionTitle: string
    sectionStartTime: number
    taskStartTime: number
}

export const TaskBarCurrentSection = ({
    sectionTitle,
    sectionStartTime,
    taskStartTime,
}: TaskBarCurrentSectionProps) => {
    const elapsedTime = useMemo(() => {
        const secondsElapsedSinceSection = taskStartTime - sectionStartTime
        // The actual date here doesn't matter. it's here only because the API for date-fns formatDistance can only use Date objects
        const startDate = new Date(2021, 1, 1, 0, 0, 0)
        const endDate = addSeconds(startDate, secondsElapsedSinceSection)
        return formatDistance(startDate, endDate, { addSuffix: true })
    }, [sectionStartTime, taskStartTime])

    return (
        <Container>
            <Section>{`${sectionTitle}:`}</Section>
            <ElapsedTime>{elapsedTime}</ElapsedTime>
        </Container>
    )
}
