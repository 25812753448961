import React from 'react'
import { Dialog, Button, Classes } from '@blueprintjs/core'
import styled from 'styled-components/macro'

import { ModalComponentProps } from 'src/state/ModalProvider'
import { APIError } from 'src/network'

const RequestIdText = styled.div`
    font-size: 12px;
    text-align: right;
`

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgb(196, 201, 204);
    margin: 30px 0 10px;
`

export interface ErrorDialogProps extends ModalComponentProps {
    error?: APIError
    onRetry: () => void
}

export const ErrorDialog = ({ error, onRetry }: ErrorDialogProps) => {
    if (!error) {
        return <p>There is no error to be shown</p>
    }

    const { message, requestId, withRedirect, redirectLink, critical } = error

    return (
        <Dialog
            isOpen
            title="Error"
            icon="error"
            onClose={(!critical && onRetry) || undefined}
            canEscapeKeyClose={!critical}
            canOutsideClickClose={!critical}
            isCloseButtonShown={!critical}
        >
            <div className={Classes.DIALOG_BODY}>
                {message}
                {withRedirect && <a href={redirectLink}>Get another job</a>}
                {requestId && (
                    <>
                        <Separator />
                        <RequestIdText>Request ID: {requestId}</RequestIdText>
                    </>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                {!critical && (
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            onClick={!critical && onRetry}
                            className={Classes.DIALOG_CLOSE_BUTTON}
                        >
                            OK
                        </Button>
                    </div>
                )}
            </div>
        </Dialog>
    )
}
