export const sortBy = <T extends {}>(key: keyof T, array: T[]): T[] =>
    array.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1
        }

        if (a[key] > b[key]) {
            return 1
        }

        return 0
    })

export const pushLarge = <T>(array: T[], otherArray: T[], chunkSize = 200) => {
    for (let i = 0; i < otherArray.length; i += chunkSize) {
        array.push(...otherArray.slice(i, i + chunkSize))
    }

    return array
}
