import { GlossaryTerm } from './glossary-term'
import { Label } from './label'
import { SuggestionData } from './suggestion'

interface TagBaseJSON {
    seq: string
}

interface GlossaryTagJSON extends TagBaseJSON {
    type: 'glossary'
    glossary: GlossaryTerm
}

interface UnclearTagJSON extends TagBaseJSON {
    type: 'unclear'
}

interface SuggestionTagJSON extends TagBaseJSON {
    type: 'suggestion'
    suggestion: {
        glossary_ids: string[]
    }
}

interface LabelTagJSON extends TagBaseJSON {
    type: 'label'
    label: Label
}

export type TagJSON = GlossaryTagJSON | UnclearTagJSON | SuggestionTagJSON | LabelTagJSON

interface TagDataBase {
    seq: string
}

interface UnclearTagData extends TagDataBase {
    type: 'unclear'
}

interface GlossaryTagData extends TagDataBase {
    type: 'glossary'
    glossary: GlossaryTerm
}

export interface SuggestionTagData extends TagDataBase {
    type: 'suggestion'
    suggestion: SuggestionData
}

interface LabelTagData extends TagDataBase {
    type: 'label'
    label: Label
}

export type TagData = SuggestionTagData | GlossaryTagData | UnclearTagData | LabelTagData

export const toTag = (json?: TagJSON): TagData | undefined => {
    if (!json) return undefined
    const { seq } = json

    switch (json.type) {
        case 'suggestion':
            return {
                type: 'suggestion',
                seq,
                suggestion: {
                    glossaryIds: json.suggestion.glossary_ids,
                },
            }
        case 'glossary':
            return { type: 'glossary', seq, glossary: json.glossary }
        case 'unclear':
            return { type: 'unclear', seq }
        case 'label':
            return { type: 'label', seq, label: json.label }
    }
}
