import React, { ReactNode } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { GlossaryVerifiedMark } from './GlossaryVerifiedMark'

import * as Styled from './styled'

export interface RowProps {
    text: string | JSX.Element
    icon: JSX.Element
    active?: boolean
    verified?: boolean
    expanded?: boolean
    withChevron?: boolean
    showChevron?: boolean
    subMenu?: JSX.Element
    innerRef?: (el: HTMLElement | null) => void
    onClick?: (event: React.MouseEvent<HTMLElement, Event>) => void
    onMouseOver?: (event: React.MouseEvent<HTMLElement, Event>) => void
    onMouseLeave?: (event: React.MouseEvent<HTMLElement, Event>) => void
    actionButtonLabel?: string
    disabledLabel?: string
    disabled?: boolean
    visibleOnRowHoverChildren?: ReactNode
    className?: string
}

export const Row = ({
    text,
    icon,
    active = false,
    verified = false,
    expanded,
    withChevron,
    showChevron,
    subMenu,
    innerRef,
    onClick,
    onMouseOver,
    onMouseLeave,
    disabled,
    visibleOnRowHoverChildren,
    className,
}: RowProps) => {
    return (
        <Styled.ItemRow
            active={active}
            onClick={disabled ? undefined : onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            ref={innerRef}
            disabled={disabled}
            className={className}
        >
            <Styled.ItemIcon>{icon}</Styled.ItemIcon>
            <Styled.ItemText>
                {text} {verified && <GlossaryVerifiedMark />}
            </Styled.ItemText>

            <Styled.ItemRowChevron>
                {withChevron && <FiChevronRight visibility={showChevron ? 'visible' : 'hidden'} />}
            </Styled.ItemRowChevron>

            {expanded && subMenu}

            <Styled.VisibleOnRowActive>{visibleOnRowHoverChildren}</Styled.VisibleOnRowActive>
        </Styled.ItemRow>
    )
}
