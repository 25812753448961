import { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { PositiveButton } from './styled/buttons'

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const ImageContainer = styled.div`
    svg,
    img {
        display: block;
        width: auto;
        height: 100px;
    }
`

const Title = styled.div`
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    color: ${palette('navy', 6)};
`

const Subtitle = styled.div`
    margin-top: 15px;
    margin-bottom: 35px;
    font-size: 14px;
    color: ${palette('navy', 0)};
`

interface ErrorScreenProps {
    icon: ReactNode
    title: ReactNode
    subtitle: ReactNode
    actionTitle: ReactNode
    action(): void
}

export const ErrorScreen = ({ icon, title, subtitle, actionTitle, action }: ErrorScreenProps) => (
    <Container>
        <ImageContainer>{icon}</ImageContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <PositiveButton onClick={action}>{actionTitle}</PositiveButton>
    </Container>
)
