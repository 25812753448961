import { isEmpty } from 'lodash/fp'

import { Speaker, SpeakerRole, SpeakerVoiceSample } from 'src/models'
import { CurrentPlayingVoiceSample } from 'src/state/SpeakerVoiceSamplePlayerProvider'

export const getDefaultVoiceSampleId = (samples: { [id: string]: SpeakerVoiceSample }) => {
    if (isEmpty(samples)) {
        return null
    }

    const sampleList = Object.values(samples)
    return sampleList.find(({ isDefault }) => isDefault)?.id ?? sampleList[0].id
}

export const isVoiceSamplePlaying = (
    speakerId: string,
    sampleId: string | null,
    currentPlayingSpeakerVoiceSample: CurrentPlayingVoiceSample,
) => {
    if (sampleId === null) {
        return false
    }

    return (
        currentPlayingSpeakerVoiceSample?.speakerId === speakerId &&
        currentPlayingSpeakerVoiceSample?.sampleId === sampleId
    )
}

const MIN_ALPHA = 0x20

const getShade = (index: number) => Math.max(MIN_ALPHA, 0xff - index * 0x40).toString(16)

export const getSpeakerColor = (
    speaker: Speaker,
    speakerRolesByRole: { [role: string]: SpeakerRole },
) => `${speakerRolesByRole[speaker.role].color}${getShade(speaker.indexForRole)}`

export const getSpeakerHotkey = (
    role: string,
    speakerRolesByRole: { [role: string]: SpeakerRole },
) => speakerRolesByRole[role].hotkey
