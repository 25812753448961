import { useEffect, useState } from 'react'
import { EventObject, Interpreter } from 'xstate'
import { StateSchema } from 'xstate/lib/types'

// This hook enforces re-render whenever a change in the state-machine happens
export const useMachineDeps = <
    Context extends { [key: string]: any },
    State extends StateSchema,
    Event extends EventObject,
    MachineDep extends keyof Context | 'state',
>(
    machine: Interpreter<Context, State, Event> | undefined,
    deps: Array<MachineDep>,
) => {
    const [depsValues, setDepsValues] = useState<any[]>([])

    useEffect(() => {
        if (!machine) {
            return
        }

        const sub = machine.subscribe((state) => {
            const newDepsValues = []
            let changed = false

            for (const [i, prop] of deps.entries()) {
                const oldVal = depsValues[i]
                switch (prop) {
                    case 'state': {
                        newDepsValues.push(state.value as any as string)
                        if (state.value !== oldVal) {
                            changed = true
                        }
                        break
                    }
                    default: {
                        newDepsValues.push(state.context[prop])
                        if (state.context[prop] !== oldVal) {
                            changed = true
                        }
                        break
                    }
                }
            }

            if (changed) {
                setDepsValues(newDepsValues)
            }
        })

        return sub.unsubscribe
    }, [deps, depsValues, machine])
}
