import { ComponentType, ReactNode, useMemo, useState, useCallback, useRef } from 'react'
import styled from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { useAnalytics } from 'src/analytics'
import { OnboardingStep, OnboardingTask as OnboardingTaskType } from 'src/models'
import { useTaskMachine, useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useTaskValidation } from 'src/state/TaskValidationProvider'
import { sizes } from 'src/components/styled'
import { GlossaryIcon, OverlappingPersonsIcon, RightArrowWithLineIcon } from 'src/components/icons'
import { useTaskPayloadSender } from 'src/components/TaskRouter/TaskState'
import { SessionIntroductionStep as SessionIntroductionStepType } from 'src/models/task/onboarding-step'
import { Button } from 'src/components/Session/panels/SpeakerPanel/common'
import { useAudioTimePlayed } from 'src/hooks/useAudioTimePlayed'

import { OnboardingTaskSidebar } from './OnboardingTaskSidebar/OnboardingTaskSidebar'
import { SessionIntroductionStep } from './steps/SessionIntroductionStep'
import { AttachmentsStep } from './steps/AttachmentsStep'
import { TeamIntroductionStep } from './steps/TeamIntroductionStep'
import { GlossaryHighlightsStep } from './steps/GlossaryHighlightsStep'
import { SpeakersIntroductionStep } from './steps/SpeakersIntroductionStep/SpeakersIntroductionStep'
import { TranscriptIntroStep } from './steps/TranscriptIntroStep'
import { TranscriptLatestStep } from './steps/TranscriptLatestStep'
import { GuidelineStep } from './steps/GuidelineStep'
import { useSessionMedia } from 'src/state/SessionMediaProvider'

const STEP_CONTAINER_HORIZONTAL_PADDING = 100

const Container = styled.div`
    width: 100vw;
    display: flex;
    flex: 1;
    overflow: hidden;
    background-color: ${palette('white', 0)};
`

const Main = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 43px 30px 15px ${STEP_CONTAINER_HORIZONTAL_PADDING}px;
    line-height: 32px;
    border-bottom: 1px solid #cfdbe6;
    color: #10264a;
    font-size: 24px;
`

const StepTitle = styled.div`
    display: flex;
    align-items: center;

    svg {
        width: auto;
        height: 24px;
        margin-right: 7px;
    }
`

const StyledRightArrowWithLineIcon = styled(RightArrowWithLineIcon)`
    margin-left: 4px;
    & > path {
        stroke: ${palette('white', 0)};
    }
`
const NextStepButton = styled(Button)<{ isLastStep: boolean }>`
    padding: 12px 16px;
    transition: opacity 250ms ease-out, background-color 250ms ease-out;
    background-color: ${palette('blue', 1)};
    border: none;
    color: ${palette('white', 0)};
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${STEP_CONTAINER_HORIZONTAL_PADDING}px;
    border-radius: 4px;
    svg {
        color: #10264a;
    }
    @media (min-width: ${sizes.largeLaptop}) {
        right: ${STEP_CONTAINER_HORIZONTAL_PADDING + 50}px;
    }
`

const Bold = styled.span`
    font-weight: 500;
`

const StepContainer = styled.div<{ withPaddingTop?: boolean }>`
    flex: 1;
    padding: 0 ${STEP_CONTAINER_HORIZONTAL_PADDING}px;
    padding-top: ${ifProp('withPaddingTop', '40px')};
    overflow-y: scroll;

    @media (min-width: ${sizes.largeLaptop}) {
        padding-right: ${STEP_CONTAINER_HORIZONTAL_PADDING + 50}px;
    }
`

const Footer = styled.footer`
    position: relative;
    height: 140px;
    padding-right: ${STEP_CONTAINER_HORIZONTAL_PADDING}px;

    @media (min-width: ${sizes.largeLaptop}) {
        padding-right: ${STEP_CONTAINER_HORIZONTAL_PADDING + 50}px;
    }
`

const MAX_DURATION_TO_SHOW_TIMER = 60

export const OnboardingTask = () => {
    const analytics = useAnalytics()
    const [{ context }, sendTaskEvent] = useTaskMachine(['elapsed', 'timerDuration'])

    const task = useTask<OnboardingTaskType>()
    const { validateTask } = useTaskValidation()
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
    const editorScrollableWrapperRef = useRef<HTMLDivElement>(null)

    const { elapsed, timerDuration } = context
    const timeLeft = timerDuration - Math.floor(elapsed)
    const { steps, orderedStepTypes, controls } = task.payload
    const isTimerVisible = controls?.timer?.visible !== undefined ? controls?.timer?.visible : true
    const currentSection = steps.transcript_latest?.payload.currentSection
    const currentStepType = orderedStepTypes[currentStepIndex]
    const nextStepType = orderedStepTypes[currentStepIndex + 1]
    const currentStep = steps[currentStepType]
    const isLastStep = currentStepIndex === orderedStepTypes.length - 1
    const roleSwitch =
        currentStepType === 'session_intro' &&
        (currentStep as SessionIntroductionStepType)?.payload?.roleSwitch

    const stepDescriptors = useMemo(
        () => getStepDescriptors({ currentSection, roleSwitch }),
        [currentSection, roleSwitch],
    )
    const currentStepDescriptor = stepDescriptors[currentStepType]
    const nextStepDescriptor = stepDescriptors[nextStepType]
    const { component: CurrentStepComponent, title, titleIcon } = currentStepDescriptor

    useTaskPayloadSender(() => ({}), [])

    const { mediaRef } = useSessionMedia(['mediaRef'])
    const { getPlayedAudioTime, reset } = useAudioTimePlayed(mediaRef)

    const sendStepNavigation = useCallback(
        (stepIndex: number) => {
            const currentStepType = orderedStepTypes[stepIndex]
            let totalAudioLength, userPlayedAudioTime

            if (currentStepType === 'transcript_intro' || currentStepType === 'transcript_latest') {
                totalAudioLength =
                    steps[currentStepType]!.payload.timing.end -
                    steps[currentStepType]!.payload.timing.start
                userPlayedAudioTime = getPlayedAudioTime() / 1000
                reset()
            }

            analytics?.sendOnboardingStepNavigation(
                task.id,
                task.assignedAt,
                currentStepType,
                totalAudioLength,
                userPlayedAudioTime,
            )
        },
        [analytics, getPlayedAudioTime, orderedStepTypes, reset, steps, task.assignedAt, task.id],
    )

    const onNextStepClick = useCallback(() => {
        const isValid = validateTask()

        if (isValid) {
            sendStepNavigation(currentStepIndex)

            if (isLastStep) {
                return sendTaskEvent({
                    type: 'SUBMIT',
                    autoSubmit: false,
                    publishType: 'Mouse',
                })
            } else {
                setCurrentStepIndex(currentStepIndex + 1)
            }
        }
    }, [currentStepIndex, isLastStep, sendStepNavigation, sendTaskEvent, validateTask])

    const onStepIndicatorClick = useCallback(
        (stepIndex: number): void => {
            if (stepIndex < currentStepIndex) {
                sendStepNavigation(currentStepIndex)
                setCurrentStepIndex(stepIndex)
                return
            }

            const isValid = validateTask()

            if (stepIndex <= currentStepIndex + 1 && isValid) {
                sendStepNavigation(currentStepIndex)
                setCurrentStepIndex(stepIndex)
            }
        },
        [currentStepIndex, sendStepNavigation, validateTask],
    )

    const shouldAddPaddingTopToStepContainer =
        currentStepType !== 'transcript_latest' && currentStepType !== 'transcript_intro'

    const nextStepButtonText = `Next: ${nextStepDescriptor?.name ?? 'Get a Task'}`

    if (!currentStep) {
        return null
    }
    return (
        <Container>
            <OnboardingTaskSidebar
                orderedStepTypes={orderedStepTypes}
                stepDescriptors={stepDescriptors}
                currentStepIndex={currentStepIndex}
                taskTimerWarningDuration={MAX_DURATION_TO_SHOW_TIMER}
                timeLeft={timeLeft}
                onStepIndicatorClick={onStepIndicatorClick}
                isTimerVisible={isTimerVisible}
            />

            <Main>
                <Header>
                    <StepTitle>
                        {titleIcon}
                        {title}
                    </StepTitle>
                </Header>

                <StepContainer
                    withPaddingTop={shouldAddPaddingTopToStepContainer}
                    ref={editorScrollableWrapperRef}
                >
                    <CurrentStepComponent editorScrollableWrapperRef={editorScrollableWrapperRef} />
                </StepContainer>
                <Footer>
                    <NextStepButton
                        data-testid={'next-step-button'}
                        isLastStep={isLastStep}
                        onClick={onNextStepClick}
                    >
                        {nextStepButtonText}
                        <StyledRightArrowWithLineIcon />
                    </NextStepButton>
                </Footer>
            </Main>
        </Container>
    )
}

interface StepDescriptor {
    name: string
    component: ComponentType<any>
    title: ReactNode
    titleIcon?: ReactNode
    indicatorLabel: string
}

export type StepDescriptorDict = {
    [K in OnboardingStep['type']]: StepDescriptor
}

interface StepDescriptorsProps {
    currentSection?: string
    roleSwitch?: boolean | null
}

const getStepDescriptors = ({
    currentSection,
    roleSwitch,
}: StepDescriptorsProps): StepDescriptorDict => ({
    session_intro: {
        name: 'Session',
        component: SessionIntroductionStep,
        title: roleSwitch
            ? 'Your role in this session changed'
            : 'Before you start, here’s what you need to know',
        indicatorLabel: 'Session Details',
    },
    team_intro: {
        name: 'Team',
        component: TeamIntroductionStep,
        title: 'Team',
        indicatorLabel: 'Team',
    },
    gloss_highlights: {
        name: 'Glossary',
        component: GlossaryHighlightsStep,
        title: 'Glossary',
        titleIcon: <GlossaryIcon />,
        indicatorLabel: 'Glossary',
    },
    speakers_intro: {
        name: 'Speakers',
        component: SpeakersIntroductionStep,
        title: 'Speakers',
        titleIcon: <OverlappingPersonsIcon />,
        indicatorLabel: 'Speakers',
    },
    transcript_intro: {
        name: 'First Minutes',
        component: TranscriptIntroStep,
        title: 'First minutes of this session',
        indicatorLabel: 'First Minutes',
    },
    attachments: {
        name: 'Attachments',
        component: AttachmentsStep,
        title: 'Attachments',
        indicatorLabel: 'Attachments',
    },
    guidelines: {
        name: 'Guidelines',
        component: GuidelineStep,
        title: 'Guidelines',
        indicatorLabel: 'Guidelines',
    },
    transcript_latest: {
        name: 'Latest on this Session',
        component: TranscriptLatestStep,
        title: (
            <div>
                <div>Latest minutes of this session</div>
                {currentSection && (
                    <div>
                        Current Section: <Bold>{currentSection}</Bold>
                    </div>
                )}
            </div>
        ),
        indicatorLabel: 'Last Minutes',
    },
})
