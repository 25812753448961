import styled, { keyframes } from 'styled-components'
import { ErrorPage } from '@verbit-ai/verbit-ui-library'

const animateContainer = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

const NotAvailableSessionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: fixed;
    top: 61px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0;
    animation: ${animateContainer} 3s ease-in-out forwards;
`

export const NotAvailableSession = () => {
    return (
        <NotAvailableSessionContainer>
            <ErrorPage
                errorCode="NotAvailable"
                title={['The session is not', 'available yet']}
                text={['You will have access 30 minutes before the', 'scheduled start time']}
            />
        </NotAvailableSessionContainer>
    )
}
