import React from 'react'
import styled from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { RestTask as RestTaskType } from 'src/models'
import { useTaskMachine, useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { formatDuration } from 'src/utils/time'
import { HotMugIcon, WarningIcon } from 'src/components/icons'
import { NegativeButton, PositiveButton } from 'src/components/Common/styled/buttons'
import { useTaskPayloadSender } from 'src/components/TaskRouter/TaskState'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 440px;
    margin: 34px auto auto auto;
    background-color: ${palette('white', 0)};
    border: 1px solid ${palette('cloudBlueLight', 8)};

    --side-padding: 20px;
`

const Header = styled.div`
    display: flex;
    align-items: flex-end;
    padding: 10px var(--side-padding);
    font-size: 14px;
    font-weight: 600;
    color: ${palette('navy', 6)};
    border-bottom: 1px solid ${palette('cloudBlueLight', 8)};
`

const StyledHotMugIcon = styled(HotMugIcon)<{ $warningMode: boolean }>`
    width: 28px;
    height: 28px;
    margin-right: 12px;
    padding: 5px;
    border-radius: 50%;
    background-color: ${ifProp('$warningMode', palette('red', 0), palette('blue', 0))};
    color: ${palette('white', 0)};
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px var(--side-padding) 18px var(--side-padding);
`

const Message = styled.div`
    height: 18px;
    margin-top: 12px;
    font-size: 14px;
    color: ${palette('navy', 0)};
`

const BreakTime = styled.div<{ warningMode: boolean }>`
    display: flex;
    align-items: baseline;
    margin-top: 9px;
    font-size: 36px;
    line-height: 48px;
    color: ${ifProp('warningMode', palette('red', 0), palette('navy', 9))};
`

const StyledWarningIcon = styled(WarningIcon)`
    width: 24px;
    height: 24px;
    margin-left: 6px;
`

const Warning = styled.div`
    margin-top: 12px;
    font-size: 14px;
    color: ${palette('red', 0)};
`

const Footer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;
`

const StyledPositiveButton = styled(PositiveButton)`
    &.bp4-button:not([class*='bp4-intent-']) {
        background-color: ${palette('turquoise', 0)};
        color: ${palette('white', 0)};
    }
`

const StyledNegativeButton = styled(NegativeButton)`
    flex: unset;
`

const WARNING_DURATION_OFFSET = 10

export const RestTask = () => {
    const [{ context }, sendTaskEvent] = useTaskMachine(['elapsed'])
    const { restingDuration } = useTask<RestTaskType>().payload

    const timeLeft = restingDuration - context.elapsed
    const warningMode = Boolean(timeLeft <= WARNING_DURATION_OFFSET)

    useTaskPayloadSender(() => {
        return {}
    }, [])

    return (
        <Container>
            <Header>
                <StyledHotMugIcon $warningMode={warningMode} />
                <div>You are on a break</div>
            </Header>

            <Content>
                <Message>We count on you to be back on time. The team needs you :)</Message>
                <Message>Remaining break time:</Message>

                <BreakTime warningMode={warningMode}>
                    <div>{formatDuration(timeLeft)}</div>
                    {warningMode && <StyledWarningIcon />}
                </BreakTime>

                {warningMode && (
                    <Warning>
                        {timeLeft <= 0 ? (
                            <>
                                <div>You've finished your paid break time.</div>
                                <div>
                                    If you don't join back soon, we might remove you from the
                                    session.
                                </div>
                            </>
                        ) : (
                            "If you don't join back very soon, we will need to remove you from the session"
                        )}
                    </Warning>
                )}

                <Footer>
                    <StyledPositiveButton
                        onClick={() =>
                            sendTaskEvent({
                                type: 'SUBMIT',
                                autoSubmit: false,
                                publishType: 'Mouse',
                            })
                        }
                    >
                        Back to work
                    </StyledPositiveButton>
                    <StyledNegativeButton onClick={() => sendTaskEvent('EXIT')}>
                        Exit session
                    </StyledNegativeButton>
                </Footer>
            </Content>
        </Container>
    )
}
