import Timecode, { FRAMERATE } from 'smpte-timecode'

type FormatTimecodeOptions = {
    fps?: FRAMERATE
    includeFps?: boolean
}

export function formatTimecode(
    timeCode: string | undefined,
    date: Date,
    options: FormatTimecodeOptions = {},
): string | undefined {
    const { fps, includeFps = true } = options

    try {
        const timecode = new Timecode(timeCode, fps)
        timecode.add(date)

        const str = timecode.toString()

        return includeFps ? str : str.slice(0, -3)
    } catch (e) {
        return undefined
    }
}

export const convertTimingToDateTime = (secs: number) => {
    var t = new Date(1970, 0, 1) // Epoch
    t.setMilliseconds(secs * 1000)
    return t
}
