import { WebSocketClient } from '@verbit-ai/frontend-common'

import { EmittableEvents, ListenableEvents } from './types'
import { mockWebSocketClient } from '../mocks'
import { toTranscriptWebsocketMessage } from 'src/models'

@mockWebSocketClient('live_data', { delay: [2_000, 4_000] })
export class AppWebSocketClient extends WebSocketClient<ListenableEvents, EmittableEvents> {
    mapEmittedEvent<EventName extends keyof EmittableEvents>(
        eventName: EventName,
        data: EmittableEvents[EventName],
    ) {
        return {
            action: eventName,
            ...data,
        }
    }

    mapReceivedEvent(event: any) {
        if (event.name === 'transcript') {
            return {
                name: event.name,
                data: toTranscriptWebsocketMessage(event),
            }
        }

        return {
            name: event.name,
            data: event,
        }
    }
}
