import styled from 'styled-components/macro'
import { ifProp } from 'styled-tools'

export const Page = styled.div<{ $isRealTimeReadOnly?: boolean }>`
    max-height: 100%;
    color: #222222;
    font-size: 18px;

    @media (max-width: 1920px) {
        width: ${ifProp('$isRealTimeReadOnly', '70vw', '')};
    }
    @media (max-width: 1440px) {
        width: ${ifProp('$isRealTimeReadOnly', '75vw', '')};
    }
    @media (max-width: 1280px) {
        width: ${ifProp('$isRealTimeReadOnly', '85vw', '')};
    }
    @media (max-width: 1024px) {
        width: ${ifProp('$isRealTimeReadOnly', '90vw', '')};
    }
    @media (max-width: 768px) {
        width: ${ifProp('$isRealTimeReadOnly', '95vw', '')};
    }
`

export const FadedBottomEdge = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    max-height: calc(100% - 10px);
`

export const ScrollingWrapper = styled.div<{
    isEditorEmpty: boolean
    $isRealTimeReadOnly?: boolean
}>`
    display: ${ifProp('isEditorEmpty', 'none', 'flex')};
    align-items: flex-start;
    justify-content: ${ifProp('$isRealTimeReadOnly', 'center', 'none')};
    flex: 1;
    overflow-y: scroll;
    margin-left: 32px;
    padding-right: 32px;
    transition: margin-left 700ms;
    mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
    &::-webkit-mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 20px;
        border: 3px solid white;
    }
`
