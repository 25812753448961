import { useContext, useMemo, ReactChild, createContext } from 'react'

import { useMachineDeps } from '../useMachineDeps'
import { TaskValidationMachineType, TaskValidationStateSchema } from './TaskValidationMachine'
import { TaskMachineType } from '../TaskMachine/TaskMachine'
import { useAppMachine } from '../AppMachine/AppMachineProvider'

type TaskValidationMachineState = TaskValidationMachineType['state'] & {
    value: keyof TaskValidationStateSchema['states']
}

const TaskValidationMachineContext = createContext<{
    machine?: TaskValidationMachineType
} | null>(null)

interface TaskValidationMachineProviderProps {
    children: ReactChild
}

export function TaskValidationMachineProvider({ children }: TaskValidationMachineProviderProps) {
    const [, , appMachine] = useAppMachine(['state'])
    const taskMachine = appMachine.children.get('worker') as TaskMachineType | undefined
    const service = taskMachine?.children.get('taskValidation') as
        | TaskValidationMachineType
        | undefined

    useMachineDeps(taskMachine, ['state'])

    const props = useMemo(() => ({ machine: service }), [service])

    // @ts-ignore
    window.sendTaskValidationEvent = service?.send

    return (
        <TaskValidationMachineContext.Provider value={props}>
            {children}
        </TaskValidationMachineContext.Provider>
    )
}

type TaskValidationMachineProperties = 'state' | keyof TaskValidationMachineType['state']['context']

export function useIsTaskValidationMachineActive() {
    const taskValidationMachineContextProps = useContext(TaskValidationMachineContext)
    return !!taskValidationMachineContextProps?.machine
}

export function useTaskValidationMachine(
    deps: TaskValidationMachineProperties[],
): [TaskValidationMachineState, TaskValidationMachineType['send'], TaskValidationMachineType] {
    const props = useContext(TaskValidationMachineContext)
    if (!props) {
        throw new Error('You forgot to use <TaskValidationMachineProvider />.')
    }

    if (!props.machine) {
        throw new Error(
            'TaskValidationMachine is not active. \nYou forgot to use `useIsTaskValidationMachineActive` to check if the machine is active.',
        )
    }

    useMachineDeps(props.machine, deps)

    return [props.machine.state as TaskValidationMachineState, props.machine.send, props.machine]
}
