import { RefObject, useCallback, useEffect, useRef } from 'react'
import { Editor, Transforms, Range } from 'slate'
import { useSlateStatic } from 'slate-react'
import { useIsPanelTabsOpen } from 'src/components/Session/PanelTabsManager'
import styled from 'styled-components/macro'
import { ifProp } from 'styled-tools'

import { useMousetrap } from 'src/hooks/useMousetrap'
import { useTaskMachine } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TranscriptionTask } from 'src/models'
import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { TextEditor } from 'src/components/Editor/TextEditor'
import { useEditorUnclears } from 'src/components/Editor/plugins/withTags/useEditorUnclears'
import { useEditableBlockAutoScroll } from 'src/components/Editor/plugins/withTranscript/hooks/useEditableBlockAutoScroll'
import { useEditorAudioSync } from 'src/components/Editor/plugins/withTimeline/hooks/useEditorAudioSync'
import { useActiveSegmentAutoFocus } from 'src/components/Editor/plugins/withTranscript/hooks/useActiveSegmentAutoFocus'
import { useTagsContextMenu } from 'src/components/Editor/plugins/withTags/useTagsContextMenu'
import { useSuggestionsContextMenu } from 'src/components/Editor/plugins/withSuggestions/useSuggestionsContextMenu'
import { useEditorPopup } from 'src/components/Editor/EditorContext'
import { useAudioContextMenu } from 'src/components/Editor/plugins/withTimeline/hooks/useAudioContextMenu'
import { useSpellingContextMenu } from 'src/components/Editor/plugins/withSpellchecker/useSpellingContextMenu'
import { Spellchecker } from 'src/components/Editor/plugins/withSpellchecker/Spellchecker'
import { Validations } from 'src/components/Editor/plugins/withValidations'
import useEditorTimeCode from 'src/components/Editor/plugins/withTranscript/hooks/useEditorTimeCode'
import useEditorSpeaker from 'src/components/Editor/plugins/withTranscript/hooks/useEditorSpeaker'
import useEditorExhibits from 'src/components/Editor/plugins/withTranscript/hooks/useEditorExhibits'
import { Block } from 'src/components/Editor/plugins/withTranscript/Block'
import { useSlateGlossersEditorProps } from '../Editor/plugins/withGlossers/useSlateGlossersEditorProps'
import { useSessionMediaCurrentTime } from 'src/state/SessionMediaProvider'

const Page = styled.div`
    max-height: 100%;
    color: #222222;
    font-size: 18px;
    width: 63vw;
`
// change line height to 30 so if tags / suggestions are above each other in 2 lines the styling doesn't colide

const FadedBottomEdge = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    max-height: calc(100% - 42px);
`

const ScrollingWrapper = styled.div<{ isPanelTabsOpen: boolean }>`
    display: flex;
    align-items: center;
    flex: 1;
    overflow-y: scroll;
    margin-left: ${ifProp('isPanelTabsOpen', '5vw', '13vw')};
    transition: margin-left 700ms;
`

interface TaskProps {
    containerRef: RefObject<HTMLDivElement>
}

export const TaskEditor = ({ containerRef }: TaskProps) => {
    const editor = useSlateStatic()
    const task = useTask<TranscriptionTask>()
    const editorRef = useRef<HTMLDivElement>(null)
    const [, sendTaskEvent] = useTaskMachine([])

    const scrollingWrapperRef = useRef<HTMLDivElement>(null)
    useEditableBlockAutoScroll(scrollingWrapperRef)
    useEditorAudioSync(editorRef)
    useActiveSegmentAutoFocus()
    useTagsContextMenu()
    useSpellingContextMenu()
    useSuggestionsContextMenu()
    useEditorUnclears()
    useEditorTimeCode(task.payload.controls)
    useEditorSpeaker()
    useAudioContextMenu()
    const glossersProps = useSlateGlossersEditorProps()
    useSessionMediaCurrentTime() // use it as an update-subscription to currentTime
    useEditorExhibits()

    // force normalize initial document so we can be sure, that everything is, as it should be
    useEffect(() => {
        Editor.normalize(editor, { force: true })
        const spellingErrorsCount = Spellchecker.getSpellingErrorsCount(editor)
        const invalidTermsCount = Validations.getInvalidTermsCount(editor)

        sendTaskEvent({ type: 'ASSIGN_INVALID_TERMS_COUNT_START', invalidTermsCount })
        sendTaskEvent({
            type: 'ASSIGN_SPELLING_ERRORS_COUNT_START',
            spellingErrorCount: spellingErrorsCount,
        })
    }, [editor, sendTaskEvent])

    useEffect(() => {
        const handleScroll = () => {
            if (scrollingWrapperRef.current) scrollingWrapperRef.current.focus()
        }

        window.addEventListener('scroll', handleScroll, true)

        return () => window.removeEventListener('scroll', handleScroll, true)
    }, [])

    const { openPopup } = useEditorPopup()

    useMousetrap(
        'mod+g',
        useCallback(() => openPopup('glossary'), [openPopup]),
        { label: 'Text: Open glossary', preventDefault: true },
    )
    useMousetrap(
        'mod+d',
        useCallback(() => openPopup('context'), [openPopup]),
        { label: 'Text: Open context menu', preventDefault: true },
    )
    useMousetrap(
        ['command+a', 'ctrl+a'],
        useCallback(() => {
            if (editor.selection) {
                const [startPoint, endPoint] = Range.edges(editor.selection)
                const [[, startBlockPath]] = Editor.nodes(editor, {
                    at: startPoint,
                    match: Block.isBlock,
                })
                const [[, endBlockPath]] = Editor.nodes(editor, {
                    at: endPoint,
                    match: Block.isBlock,
                })
                const isBackward = Range.isBackward(editor.selection)

                const newStartPoint = Editor.start(editor, startBlockPath)
                const newEndPoint = Editor.end(editor, endBlockPath)

                Transforms.select(editor, {
                    anchor: isBackward ? newEndPoint : newStartPoint,
                    focus: isBackward ? newStartPoint : newEndPoint,
                })
            }
        }, [editor]),
        { label: 'Text: Select current blocks content', preventDefault: true },
    )

    const isPanelTabsOpen = useIsPanelTabsOpen()

    return (
        <FadedBottomEdge>
            <ScrollingWrapper
                isPanelTabsOpen={isPanelTabsOpen}
                ref={scrollingWrapperRef}
                tabIndex={-1}
            >
                <Page ref={containerRef}>
                    <TextEditor
                        data-testid={'text-editor'}
                        editorRef={editorRef}
                        {...glossersProps}
                    />
                </Page>
            </ScrollingWrapper>
        </FadedBottomEdge>
    )
}
