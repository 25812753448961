import React, { useCallback, useEffect, useState } from 'react'
import { Popover2 } from '@blueprintjs/popover2'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette, prop } from 'styled-tools'

import { Speaker } from 'src/models'
import { usePrevious } from 'src/hooks/usePrevious'
import { ExaminationLegalAnnotation } from 'src/components/Editor/plugins/withTranscript'
import { AnswerLegalAnnotationIcon, QuestionLegalAnnotationIcon } from 'src/components/icons'

import { StyledVoiceSampleButton } from './common'

const VoiceSampleButtonContainer = styled.div<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 100%;
    cursor: pointer;
    padding-left: 8px;

    &:hover ${StyledVoiceSampleButton} {
        color: ${palette('blue', 0)} !important;
    }

    ${ifProp(
        'disabled',
        css`
            cursor: initial;

            ${StyledVoiceSampleButton},
            &:hover ${StyledVoiceSampleButton} {
                color: ${palette('greyBlue', 2)} !important;
                opacity: 0.5;
            }
        `,
    )}
`

const MiddleContent = styled.div`
    display: flex;
    align-items: baseline;
    flex: 1;
    padding: 0 8px;
    overflow: hidden;
`

const Details = styled.div`
    flex: 1;
    font-size: 14px;
    overflow: hidden;
`

const ColorLabel = styled.div<{ color: string }>`
    margin-right: 16px;
    border-radius: 2px;
    width: 3px;
    align-self: stretch;
    background-color: ${prop('color')};
`

const HotKey = styled.div`
    display: flex;
    padding: 2px 8px;
    margin-left: 8px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    border-radius: 2px;
    background: ${palette('grey', 0)};
    font-size: 12px;
`

const TextDetail = styled.div`
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Name = styled(TextDetail)`
    margin-bottom: -10px;
    color: ${palette('navy', 6)};
`

const Role = styled(TextDetail)`
    color: ${palette('grey', 8)};
`

const Container = styled.div<{ displayFlashAnimation: boolean }>`
    display: flex;
    align-items: center;
    height: 54px;
    padding-right: 18px;
    border-bottom: solid 1px ${palette('cloudBlueLight', 1)};
    background-color: ${palette('white', 0)};
    transition: background-color 250ms ease-out;
    cursor: pointer;

    &:hover {
        background-color: ${palette('cloudBlueLight', 2)};

        ${StyledVoiceSampleButton} {
            color: ${palette('navy', 2)};
        }
    }

    ${ifProp(
        'displayFlashAnimation',
        css`
            animation: flash 2000ms ease-in-out;
        `,
    )}

    @keyframes flash {
        0% {
            background-color: ${palette('white', 0)};
        }
        25% {
            background-color: ${palette('cloudBlueLight', 2)};
        }
        50% {
            background-color: ${palette('white', 0)};
        }
        75% {
            background-color: ${palette('cloudBlueLight', 2)};
        }
        100% {
            background-color: ${palette('white', 0)};
        }
    }
`

const LegalAnnotationDescription = styled.div`
    max-width: 180px;
    padding: 10px;
    border-radius: 2px;
    background-color: ${palette('white', 0)};
    font-size: 13px;
    color: ${palette('navy', 6)};
`

const HotKeyPopover = styled.div`
    max-width: 180px;
    padding: 10px;
    border-radius: 2px;
    background-color: ${palette('white', 0)};
    font-size: 13px;
    color: ${palette('navy', 6)};
`

interface SpeakerRowProps {
    onSelect: (speaker: Speaker) => void
    onToggleVoiceSample: (
        speakerId: string,
        sampleId: string,
        isPlaying: boolean,
        e: React.MouseEvent,
    ) => void
    speaker: Speaker
    color: string
    hotkey?: string
    legalAnnotation: string
    defaultVoiceSampleId: string | null
    isVoiceSamplePlaying: boolean
    isVoiceSampleBuffering: boolean
    animate: boolean
}

export const SpeakerRow = ({
    onSelect,
    onToggleVoiceSample,
    speaker,
    color,
    hotkey,
    legalAnnotation,
    defaultVoiceSampleId,
    isVoiceSamplePlaying,
    isVoiceSampleBuffering,
    animate,
}: SpeakerRowProps) => {
    const [displayFlashAnimation, setDisplayFlashAnimation] = useState(false)
    const prevSpeaker = usePrevious(speaker)
    const prevLegalAnnotation = usePrevious(legalAnnotation)
    const prevDefaultVoiceSampleId = usePrevious(defaultVoiceSampleId)
    const { name, role } = speaker
    const speakerHotkey = hotkey ? hotkey : ''
    const onClick = useCallback(() => onSelect(speaker), [speaker, onSelect])

    const onVoiceSampleButtonClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation()

            if (defaultVoiceSampleId !== null) {
                onToggleVoiceSample(speaker.id, defaultVoiceSampleId, isVoiceSamplePlaying, e)
            }
        },
        [speaker.id, defaultVoiceSampleId, isVoiceSamplePlaying, onToggleVoiceSample],
    )

    useEffect(() => {
        if (
            animate &&
            (speaker.name !== prevSpeaker?.name ||
                speaker.role !== prevSpeaker?.role ||
                defaultVoiceSampleId !== prevDefaultVoiceSampleId ||
                legalAnnotation !== prevLegalAnnotation)
        ) {
            setDisplayFlashAnimation(true)
        }
    }, [
        speaker,
        prevSpeaker,
        legalAnnotation,
        prevLegalAnnotation,
        defaultVoiceSampleId,
        prevDefaultVoiceSampleId,
        animate,
    ])

    return (
        <Container
            displayFlashAnimation={displayFlashAnimation}
            onAnimationEnd={() => setDisplayFlashAnimation(false)}
            onClick={onClick}
        >
            <VoiceSampleButtonContainer
                disabled={defaultVoiceSampleId === null}
                onClick={onVoiceSampleButtonClick}
            >
                <StyledVoiceSampleButton
                    isPlaying={isVoiceSamplePlaying}
                    isBuffering={isVoiceSampleBuffering}
                    isDisabled={defaultVoiceSampleId === null}
                />
            </VoiceSampleButtonContainer>
            <MiddleContent>
                <ColorLabel color={color} />

                <Details>
                    <Name>{name}</Name>
                    <Role>{role}</Role>
                </Details>
            </MiddleContent>
            {renderLegalAnnotation(legalAnnotation, color)}
            {renderSpeakerHotkey(speakerHotkey)}
        </Container>
    )
}

const renderLegalAnnotation = (legalAnnotation: string, color: string) => {
    let LegalAnnotationIcon
    let description

    switch (legalAnnotation) {
        case ExaminationLegalAnnotation.Q:
            LegalAnnotationIcon = QuestionLegalAnnotationIcon
            description =
                'This speaker is asking questions in this section. It will be used when auto-populating Q/A'
            break

        case ExaminationLegalAnnotation.A:
            LegalAnnotationIcon = AnswerLegalAnnotationIcon
            description =
                'This speaker is answering questions in this section. It will be used when auto-populating Q/A'
            break
    }

    return LegalAnnotationIcon ? (
        <Popover2
            interactionKind="hover"
            position="right"
            content={<LegalAnnotationDescription>{description}</LegalAnnotationDescription>}
        >
            <LegalAnnotationIcon
                onClick={(e) => e.stopPropagation()}
                color={color}
                width={20}
                height={20}
            />
        </Popover2>
    ) : null
}

const renderSpeakerHotkey = (hotkey: string) => {
    if (hotkey === '') return null

    return (
        <Popover2
            interactionKind="hover"
            position="right"
            content={
                <HotKeyPopover>{`Press ${hotkey!.toUpperCase()} key to assign speaker to a segment.`}</HotKeyPopover>
            }
        >
            <HotKey>{hotkey!.toUpperCase()}</HotKey>
        </Popover2>
    )
}
