import { useEffect, useMemo, useRef } from 'react'

import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import {
    TaskMachineState,
    useTaskMachine,
} from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { useChat } from 'src/state/ChatProvider'
import { TaskRegistry } from 'src/tasks/Registry'
import { TaskRouter } from 'src/components/TaskRouter/TaskRouter'
import {
    GlossaryIcon,
    UsersIcon,
    DoublePopupIcon,
    AttachmentIcon,
    KeyboardIcon,
} from '@verbit-ai/icons-library'
import { appcues } from 'src/appcues'

import { PanelTabsManager, usePanelTabs } from './PanelTabsManager'
import { TaskBarCurrentSection, useCurrentSection } from './TaskBarCurrentSection'
import { WaitingForTaskScreen } from './WaitingForTaskScreen'
import { AbortingTaskScreen } from './AbortingTaskScreen'
import { SpeakerChangeReminderProvider } from '../Editor/plugins/withTranscript/hooks/useSpeakerChangeReminder'
import { usePrevious } from 'src/hooks/usePrevious'
import {
    BodyContainer,
    ChatIcon,
    Container,
    StyledUnreadMentionsIndicator,
    StyledUnreadMessageCounter,
    TaskBarButton,
    TaskBarButtons,
    TaskBarContainer,
    TaskBarContent,
    TaskBarTitle,
} from './styles'
import { PanelTabButtonProps } from './types'
import { theme } from 'src/components/styled'

export function Session() {
    const sessionId = useSessionStatus(['sessionStatus.sessionId']).sessionStatus?.sessionId
    const [{ context: appContext }] = useAppMachine(['layerIds', 'workerId'])
    const [{ value: taskState, context: taskContext }] = useTaskMachine([
        'state',
        'task',
        'sessionPaused',
    ])
    const { activePanelTab, setActivePanelTab } = usePanelTabs()
    const { channelUnreadCounters } = useChat()
    const { unreadMessageCount = 0, unreadMentionCount = 0 } =
        channelUnreadCounters[sessionId ?? ''] ?? {}
    const { layerIds } = appContext
    const { task } = taskContext
    const recentTask = useRef(task)
    const taskTitle = task ? TaskRegistry.getTitle(task) : null
    const currentSection = useCurrentSection(task)
    const headerPanelTabs = useMemo<PanelTabButtonProps[]>(
        () => [
            {
                tab: { name: 'GLOSSARY' },
                icon: <GlossaryIcon variant="large" color={theme?.palette?.grey?.[8]} />,
            },
            {
                tab: { name: 'SESSION_CHAT' },
                icon: (
                    <ChatIcon>
                        <DoublePopupIcon variant="large" color={theme?.palette?.grey?.[8]} />
                        <StyledUnreadMessageCounter unreadMessageCount={unreadMessageCount} />
                        <StyledUnreadMentionsIndicator unreadMentionCount={unreadMentionCount} />
                    </ChatIcon>
                ),
            },
            {
                tab: { name: 'SPEAKERS' },
                icon: <UsersIcon variant="large" color={theme?.palette?.grey?.[8]} />,
                visible: layerIds?.includes('annotate') || layerIds?.includes('review'),
            },
            {
                tab: { name: 'ATTACHMENTS' },
                icon: <AttachmentIcon variant="large" color={theme?.palette?.grey?.[8]} />,
            },
            {
                tab: { name: 'SHORTCUTS' },
                icon: <KeyboardIcon variant="large" color={theme?.palette?.grey?.[8]} />,
            },
        ],
        [layerIds, unreadMessageCount, unreadMentionCount],
    )
    const isEditorInGlossersMode = useMemo(() => {
        if (task?.type === 'transcription') {
            const {
                payload: {
                    controls: { editable, suggestionsEnabled },
                },
            } = task
            // This if condition for checking if we are in Glossers mode.
            // the hook is not working here because we are out of a task
            // We should get the data about if a session is in glossers mode in another place! not inside the task..
            if (!editable && !suggestionsEnabled) {
                return true
            }
        }
        return false
    }, [task])

    useEffect(() => {
        const isFirstTranscriptionTask =
            recentTask.current?.type !== 'transcription' && task?.type === 'transcription'
        if (isEditorInGlossersMode && isFirstTranscriptionTask) {
            appcues.track('glossers_onboarding')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task?.id])

    useEffect(() => {
        const defaultActivePanelName = isEditorInGlossersMode ? 'GLOSSARY' : 'SHORTCUTS'
        if (task) {
            // if we moved to an onboarding task
            if (task?.type === 'onboarding') {
                setActivePanelTab(null)
            }
            // if we moved from an onboarding task to a non-onboarding task
            else if (
                (recentTask.current?.type === 'onboarding' && task?.type !== 'gloss_population') ||
                recentTask.current?.type === 'gloss_population'
            ) {
                setActivePanelTab({ name: defaultActivePanelName })
            }

            recentTask.current = task
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task, setActivePanelTab])

    const prevTaskState = usePrevious(taskState, { unique: true })
    const prevActivePanel = usePrevious(activePanelTab, { unique: true })
    useEffect(() => {
        // if a task is aborting, hide the panel
        if (taskState === 'aborting') {
            setActivePanelTab(null)
        } else if (prevTaskState === 'aborting' && prevActivePanel) {
            setActivePanelTab(prevActivePanel)
        }
    }, [prevActivePanel, prevTaskState, setActivePanelTab, taskState])

    return (
        <Container>
            <TaskBarContainer taskType={task?.type}>
                <TaskBarContent>
                    {task?.type === 'onboarding' && taskTitle && (
                        <TaskBarTitle>{taskTitle}</TaskBarTitle>
                    )}
                    {currentSection?.sectionStartTime && (
                        <TaskBarCurrentSection {...currentSection} />
                    )}
                </TaskBarContent>
                {task?.type !== 'onboarding' && taskState !== 'aborting' && (
                    <TaskBarButtons>
                        {headerPanelTabs.map(({ tab, icon, visible = true }) => {
                            const isActive = activePanelTab?.name === tab.name

                            return (
                                <TaskBarButton
                                    data-testid={`task-bar-button-${tab.name}`}
                                    key={tab.name}
                                    isVisible={visible}
                                    isActive={isActive}
                                    onClick={() => setActivePanelTab(isActive ? null : tab)}
                                    highlightBackground
                                >
                                    {icon}
                                </TaskBarButton>
                            )
                        })}
                    </TaskBarButtons>
                )}
            </TaskBarContainer>
            <BodyContainer>
                <PanelTabsManager position="right" />
                <SpeakerChangeReminderProvider>
                    {renderSession(taskState)}
                </SpeakerChangeReminderProvider>
            </BodyContainer>
        </Container>
    )
}

function renderSession(state: TaskMachineState['value']) {
    switch (state) {
        case 'waiting-for-task':
            return <WaitingForTaskScreen />
        case 'submitting':
        case 'task-processing':
        case 'in-progress':
            return <TaskRouter />
        case 'aborting':
            return <AbortingTaskScreen />
        default:
            return null
    }
}
