import { Task, TaskBase } from 'src/models'
import { TaskJSON } from 'src/network/responses'

/*

TASKS EXPLANATION
-------------

The registry defined in this file poses a registry singleton, where all supported task implementations have to be registered. This happens in src/tasks/register.ts.
A registerable task implementation is a class instance, which extends the TaskItem class. This class has to override all relevant methods that would otherwise
throw a developer error.
Now if the ORC sends a task, the registry will find the right task type implementation and use the relevant methods to convert the JSON and render the appropriate UI.

*/

export class TaskItem {
    private type: string = ''
    allowCache = false

    constructor(type: Task['type']) {
        this.type = type
    }
    isTaskJSON(json: TaskJSON): json is TaskJSON {
        throw new Error(`task type ${this.getType()} needs to implement a isTaskJSON function.`)
    }

    isTask(task: Task): task is Task {
        throw new Error(`task type ${this.getType()} needs to implement a isTask function.`)
    }

    convertToTask(base: TaskBase, task: TaskJSON): Task {
        throw new Error(`task type ${this.getType()} needs to implement a convert function.`)
    }

    render(): React.ReactNode {
        throw new Error(`task type ${this.getType()} needs to implement a render function.`)
    }

    getTitle(task: Task): string | null {
        return null
    }

    getType() {
        return this.type
    }

    isTimerVisible(task: Task): boolean {
        if ('controls' in task.payload) {
            return task?.payload?.controls?.timer?.visible !== undefined ? task?.payload?.controls?.timer?.visible : true
        }
        return true
    }
}

class Registry {
    private registry: Record<string, TaskItem> = {}

    register(taskItem: TaskItem) {
        if (!taskItem.getType() || this.hasType(taskItem.getType())) {
            throw new Error(`Task type "${taskItem.getType()}" is invalid or already exists.`)
        }
        this.registry[taskItem.getType()] = taskItem
    }

    isTask(task: Task) {
        if (!this.hasType(task.type)) {
            throw new Error(`No implementation for task type ${task.type} found.`)
        }

        return this.registry[task.type].isTask(task)
    }

    hasType(type: string) {
        return type in this.registry
    }

    allowsCache(taskType: Task['type']): boolean {
        if (!this.hasType(taskType)) {
            throw new Error(`No implementation for task type ${taskType} found.`)
        }

        return this.registry[taskType].allowCache
    }

    convertToTask(json: TaskJSON): Task {
        if (!this.hasType(json.type) || !this.registry[json.type].isTaskJSON(json)) {
            throw new Error(`No implementation for task type ${json.type} found.`)
        }

        const assignedAt = new Date(json.assigned_at)
        const timeoutAt = new Date(json.timeout_at)
        const serverTime = new Date(json.server_time)
        const minSubmitAt = new Date(json.min_submit_at)

        const base: TaskBase = {
            id: json.id,
            assignedAt,
            timeoutAt,
            timeoutMs: timeoutAt.getTime() - serverTime.getTime(),
            minSubmitAt,
            minSubmitMs: minSubmitAt.getTime() - serverTime.getTime(),
            layerId: json.layer_id,
            autoSubmit: json.auto_submit,
        }
        return this.registry[json.type].convertToTask(base, json)
    }

    getTitle(task: Task): string | null {
        if (!this.hasType(task.type) || !this.registry[task.type].isTask(task)) {
            throw new Error(`No implementation for task type ${task.type} found.`)
        }

        return this.registry[task.type].getTitle(task)
    }

    render(type: string): React.ReactNode {
        if (!this.hasType(type)) {
            throw new Error(`No implementation for task type ${type} found.`)
        }
        return this.registry[type].render()
    }

    isTimerVisible(task: Task): boolean {
        if (!this.hasType(task.type) || !this.registry[task.type].isTask(task)) {
            throw new Error(`No implementation for task type ${task.type} found.`)
        }

        return this.registry[task.type].isTimerVisible(task) 
    }
}

export const TaskRegistry = new Registry()
