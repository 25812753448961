import { Editor, Range, NodeEntry, RangeRef } from 'slate'
import { DependencyList, ReactNode, useEffect } from 'react'

/*
CONTEXT MENU EXPLANATION
-----

This context menu is a general purpose context menu built for actions within the slate editor. Therefor it takes a slate range
and positions it there. 
The context menu basically has 2 layers:
  - A layer of sections
  - A layer of items within each section
A section is basically a grouping for certain kinds of actions. For example all the suggestions from the orc should be grouped together.
The items are the actual actions presented in the context menu. They are completely dynamic and can be added very easily.
To handle the extendability, there is the ContextMenuInstance class. It provides an interface to add items / sections and automatically
assign and order them. 
To actually extend it, there's the ContextMenuBuilderRegistry. Everybody can register a so-called builder function for a specific type of node. 
So when the menu is constructed, it will walk every node of the current selection and call every builder function registered for its type (while
also passing the actual node). Every builder function thus has the chance to add items and sections to the ContextMenuInstance that is passed.

To make the builder process easier, there's the useContextMenuBuilder() hook. It takes a type, your builder function and a list of dependencies
as you would pass them to useEffect for example. Whenever the dependencies change, the hook will automatically re-register your builder function 
so it can function correctly. 

*/

/*
CONTEXT MENU ITEMS ORDER
-----

common:
10: removeSuggestion
20: searchInGlossary
30: playFromHere
40: markAsUnclear
50: removeTag

 */

export interface ContextMenuItem {
    id?: string
    icon?: ReactNode
    shortcut?: string
    order?: number
    label: string | ReactNode
    onAction: (editor: Editor, range: RangeRef) => unknown
}

export interface ContextMenuSection {
    label?: string
    order?: number
    items: ContextMenuItem[]
}

export class ContextMenuInstance {
    private sections: Record<string, ContextMenuSection> = {}
    /**
     * Check, if a section already exists.
     * @param name name of the section in question
     */
    hasSection(name: string) {
        return name in this.sections
    }
    /**
     * Check the complete menu, if an item with a certain id already exists
     * @param id id of the item in question
     */
    hasItemWithId(id: string) {
        return Object.values(this.sections).some((section) => {
            return section.items.some((item) => item.id === id)
        })
    }

    /**
     * Add / extend a section.
     * The function will automatically merge the items if any are passed.
     * All other properties will be overwritten by what was passed.
     * @param name name of the (new) section
     * @param section properties of the section
     */
    setSection(name: string, section: ContextMenuSection) {
        if (this.hasSection(name)) {
            section.items = this.sections[name].items
                .concat(section.items)
                .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
            section.label = section.label ?? this.sections[name].label
            section.order = section.order ?? this.sections[name].order
        }

        // the common section is the default section and will always be placed last
        if (name === 'common') {
            section.order = 999
        }

        this.sections[name] = section
    }

    /**
     *
     * @param item item definition
     * @param section section name the item should be added to (default: 'common')
     */
    addItem(item: ContextMenuItem, section = 'common') {
        if (!this.hasSection(section)) {
            this.setSection(section, { items: [] })
        }
        this.sections[section].items = this.sections[section].items
            .concat(item)
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    }

    /**
     * Concatonate / merge 2 menues into a new one.
     * @param other menu to be concatonated
     */
    concat(other: ContextMenuInstance) {
        const newMenu = new ContextMenuInstance()

        for (const [name, section] of Object.entries(this.sections)) {
            newMenu.setSection(name, section)
        }
        for (const [name, section] of Object.entries(other.sections)) {
            newMenu.setSection(name, section)
        }
        return newMenu
    }

    /**
     * Get a sorted list of sections
     */
    get() {
        return Object.values(this.sections).sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    }
}

export type BuilderFn = (menu: ContextMenuInstance, nodeEntry: NodeEntry, range: Range) => void
class ContextMenuBuilderRegistry {
    private map: Record<string, BuilderFn[]> = {}

    /**
     * Register a builder function that will be run for every instance of a certain node type in the
     * selection.
     * @param type node type, the builer function should run for.
     * @param fn builder function
     */
    register(type: string, fn: BuilderFn) {
        if (!(type in this.map)) {
            this.map[type] = []
        }

        this.map[type].push(fn)
    }

    /**
     * Unregister a previously registered builder function
     * @param type node type, the builer function should run for.
     * @param fn builder function
     */
    unregister(type: string, fn: BuilderFn) {
        if (!(type in this.map)) {
            return
        }

        this.map[type] = this.map[type].filter((f) => f !== fn)
    }

    /**
     * Run all builder functions for a certain node type.
     * @param type node type that is present
     * @param args arguments for the builder functions
     */
    getMenuForType(type: string, ...args: Parameters<BuilderFn>) {
        for (const fn of this.map[type] ?? []) {
            fn(...args)
        }
    }
}

export const MenuBuilderRegistry = new ContextMenuBuilderRegistry()

export function useContextMenuBuilder(type: string, fn: BuilderFn, deps: DependencyList) {
    useEffect(() => {
        MenuBuilderRegistry.register(type, fn)

        return () => MenuBuilderRegistry.unregister(type, fn)
        // we explicitely want only the deps, that the user specifies. That is, because fn will potentially change on every
        // render whereas the dependencies might not.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, ...deps])
}
