import { useCallback, MouseEvent } from 'react'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { Theme } from 'src/components/styled'
import { ContextMenuSection } from './ContextMenuBuilder'
import { ContextMenuSelection } from './ContextMenu'

const MenuItemDiv = styled.div<{ selected: boolean; theme: Theme }>`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    color: ${palette('navy', 6)};
    cursor: pointer;

    ${ifProp(
        'selected',
        css`
            background-color: ${palette('cloudBlueLight', 2)};
        `,
    )}
`

const IconWrapper = styled.span<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 16px;
    margin-right: 8px;

    svg {
        width: 100%;
        color: ${ifProp('active', palette('blue', 0), palette('cloudBlue', 0))};
    }
`

const ItemLabelWrapper = styled.span`
    flex: 1;
    color: ${palette('navy', 2)};
`

const ShortcutWrapper = styled.span`
    margin-left: 8px;
    font-size: 13px;
    color: ${palette('cloudBlue', 0)};
`

interface ContextMenuSectionProps {
    selection: ContextMenuSelection
    section: ContextMenuSection
    index: number
    onSelect: (selection: ContextMenuSelection) => void
    onChangeSelection: (newSelection: [number, number]) => void
}
export function ContextMenuSectionComponent({
    selection,
    section,
    index: sectionIdx,
    onSelect,
    onChangeSelection,
}: ContextMenuSectionProps) {
    const [selectionSectionIdx, selectionItemIdx] = selection

    const onClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            onSelect(selection)
        },
        [onSelect, selection],
    )
    return (
        <>
            <Divider label={section.label} showDivider={sectionIdx !== 0} />
            {section.items.map((item, itemIdx) => {
                const isSelected =
                    selectionSectionIdx === sectionIdx && selectionItemIdx === itemIdx

                return (
                    <MenuItemDiv
                        key={itemIdx}
                        selected={isSelected}
                        onClick={onClick}
                        onMouseMove={(e) => {
                            e.preventDefault()
                            onChangeSelection([sectionIdx, itemIdx])
                        }}
                    >
                        {!!item.icon ? (
                            <IconWrapper active={isSelected}>{item.icon}</IconWrapper>
                        ) : null}
                        <ItemLabelWrapper>{item.label}</ItemLabelWrapper>
                        {!!item.shortcut ? (
                            <ShortcutWrapper>{item.shortcut}</ShortcutWrapper>
                        ) : null}
                    </MenuItemDiv>
                )
            })}
        </>
    )
}

const DividerWrapper = styled.div<{ showDivider: boolean; theme: Theme }>`
    ${ifProp(
        'showDivider',
        css`
            border-top: 1px solid ${palette('cloudBlueLight', 0)};
        `,
    )}
`
const DividerLabelWrapper = styled.div<{ theme: Theme }>`
    font-size: 14px;
    padding: 4px 8px;
    color: ${palette('greyBlue', 0)};
`

interface DividerProps {
    label?: string
    showDivider: boolean
}
function Divider({ label, showDivider }: DividerProps) {
    return (
        <DividerWrapper showDivider={showDivider}>
            {!!label ? <DividerLabelWrapper>{label}</DividerLabelWrapper> : null}
        </DividerWrapper>
    )
}
