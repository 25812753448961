import React, { useCallback, useEffect, useState } from 'react'
import { EventEmitter } from 'events'

import { usePrevious } from 'src/hooks/usePrevious'
import { useMediaSource } from 'src/hooks/useAudioSource'
import { useMediaBufferedTimeRanges } from 'src/hooks/useMediaBufferedTimeRanges'
import { createSubscriptionService } from 'src/factories/SubscriptionService'
import { MediaEvents, MediaProviderProps, MediaServiceContextProps, MediaTimingInfo } from './types'

const noop = () => {}

const DEFAULT_STATE: MediaServiceContextProps = {
    mediaRef: { current: null },
    isMediaConnected: false,
    connectMedia: noop,
    disconnectMedia: noop,
    mediaTiming: undefined,
    setMediaTiming: noop,
    isMediaPlaying: false,
    currentTime: 0,
    duration: 0,
    play: noop,
    pause: noop,
    togglePlayPause: noop,
    seekTime: noop,
    bufferedTimeRanges: [],
    mediaEvents: new EventEmitter(),
}

export function createMediaService() {
    const [Provider, useMedia, useMediaService] = createSubscriptionService(DEFAULT_STATE)

    function MediaProvider({ children, mediaUrl, label }: MediaProviderProps) {
        const mediaRef = React.useRef<HTMLVideoElement | null>(null)
        const [isMediaPlaying, setIsMediaPlaying] = useState(false)
        const [currentTime, setCurrentTime] = useState(0)
        const [duration, setDuration] = useState(0)
        const [mediaTiming, setMediaTiming] = useState<MediaTimingInfo | undefined>()
        const [mediaEvents] = useState(() => new EventEmitter<MediaEvents>())

        // @ts-ignore
        window.mediaEl = mediaRef.current

        if (mediaRef.current === null) {
            mediaRef.current = document.createElement('video')
            mediaRef.current.preload = 'auto'
        }

        const bufferedTimeRanges = useMediaBufferedTimeRanges(mediaRef)
        const { isConnected, connect, disconnect } = useMediaSource(mediaRef, mediaUrl, {
            label,
            maxBufferLength: 120,
        })

        const connectMedia = useCallback(
            (startTime?: number) => {
                connect(startTime ?? 0)
            },
            [connect],
        )

        const prevMediaUrl = usePrevious(mediaUrl)
        useEffect(() => {
            if (isConnected && mediaUrl !== prevMediaUrl) {
                disconnect()
                connectMedia(currentTime)
            }
        }, [connectMedia, currentTime, disconnect, isConnected, mediaUrl, prevMediaUrl])

        const play = useCallback(() => {
            const mediaEl = mediaRef.current
            if (!isConnected && mediaEl) {
                connect(mediaEl.currentTime)
            }

            setIsMediaPlaying(true)
            mediaEvents.emit('beforeplay')
            mediaEl?.play()?.catch(console.log)
        }, [isConnected, mediaEvents, connect])

        const pause = useCallback(() => {
            const mediaEl = mediaRef.current
            setIsMediaPlaying(false)
            mediaEl?.pause()
            mediaEvents.emit('afterpause')
        }, [mediaEvents])

        const togglePlayPause = useCallback(() => {
            if (mediaRef.current?.paused) {
                play()
            } else {
                pause()
            }
        }, [pause, play])

        const seekTime = useCallback(
            (_cursorTime: number) => {
                const audioEl = mediaRef.current

                const cursorTime = Math.min(
                    Math.max(_cursorTime, mediaTiming?.timing.start ?? 0),
                    mediaTiming?.timing.end ?? Infinity,
                )

                if (audioEl) {
                    const prevTime = audioEl.currentTime
                    audioEl.currentTime = cursorTime
                    mediaEvents.emit('seeked', prevTime)
                    setCurrentTime(cursorTime)
                }
            },
            [mediaEvents, mediaTiming?.timing.end, mediaTiming?.timing.start],
        )

        useEffect(() => {
            const onPlay = () => setIsMediaPlaying(true)
            const onPause = () => setIsMediaPlaying(false)
            const onTimeUpdate = () => {
                const mediaEl = mediaRef.current

                if (!mediaEl) {
                    return
                }

                const time = mediaEl.currentTime
                if (mediaTiming && time >= mediaTiming.timing.end) {
                    pause()
                    seekTime(mediaTiming.timing.start)
                } else {
                    setCurrentTime(time)
                    mediaEvents.emit('timeupdate', time)
                }
            }
            const onLoadedData = () => setDuration(mediaRef.current?.duration || 0)

            const mediaEl = mediaRef.current
            mediaEl?.addEventListener('play', onPlay)
            mediaEl?.addEventListener('pause', onPause)
            mediaEl?.addEventListener('timeupdate', onTimeUpdate)
            mediaEl?.addEventListener('loadeddata', onLoadedData)
            mediaEl?.addEventListener('loadedmetadata', onLoadedData)
            mediaEl?.addEventListener('durationchange', onLoadedData)

            return () => {
                mediaEl?.removeEventListener('play', onPlay)
                mediaEl?.removeEventListener('pause', onPause)
                mediaEl?.removeEventListener('timeupdate', onTimeUpdate)
                mediaEl?.removeEventListener('loadeddata', onLoadedData)
                mediaEl?.removeEventListener('loadedmetadata', onLoadedData)
                mediaEl?.removeEventListener('durationchange', onLoadedData)
            }
        }, [mediaEvents, mediaTiming, pause, seekTime])

        return (
            <Provider
                data={{
                    mediaRef,
                    isMediaConnected: isConnected,
                    connectMedia,
                    disconnectMedia: disconnect,
                    isMediaPlaying,
                    currentTime,
                    duration,
                    play,
                    pause,
                    togglePlayPause,
                    seekTime,
                    bufferedTimeRanges,
                    mediaEvents,
                    mediaTiming,
                    setMediaTiming,
                }}
            >
                {children}
            </Provider>
        )
    }

    return [MediaProvider, useMedia, useMediaService] as const
}
