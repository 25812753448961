import { Editor } from 'slate'
import LogRocket from 'logrocket'

import { getEnv } from 'src/utils/env'

export const withLogger = (editor: Editor) => {
    const { onChange } = editor

    if (getEnv() !== 'dev') {
        editor.onChange = () => {
            const hasContentChanges = editor.operations.filter(
                (op) => op.type !== 'set_selection',
            ).length

            if (hasContentChanges) {
                LogRocket.log(Date.now(), 'Editor Content Change', {
                    selection: editor.selection,
                    operations: editor.operations,
                    value: editor.children,
                })
            } else {
                LogRocket.log(Date.now(), 'Editor Selection Change', {
                    selection: editor.selection,
                    operations: editor.operations,
                })
            }

            onChange()
        }
    }

    return editor
}
