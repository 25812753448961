import { useEffect, useState } from 'react'
import { Range } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'

export interface AbsolutePosition {
    left: number
    top?: number
    bottom?: number
}

const defaultPosition: AbsolutePosition = {
    left: -1000,
    top: -1000,
}

export function useAbsolutePosition(range?: Range | null, maxHeight: number = 100) {
    const editor = useSlateStatic()
    const [position, setPosition] = useState(defaultPosition)

    useEffect(() => {
        if (!range) {
            setPosition(defaultPosition)
            return
        }
        try {
            const domRange = ReactEditor.toDOMRange(editor, range)

            const rects = Array.from(domRange.getClientRects())
            let rect = rects.find((r) => r.width > 0)
            if (!rect) {
                rect = rects[0]
            }

            if (!rect) {
                setPosition(defaultPosition)
                return
            }

            let pos: AbsolutePosition = {
                left: rect.left,
            }

            // determining, if there is enough space between the position of the selection
            // and the bottom of the viewport. If not, put the glossary on top of the selection instead of below.
            if (rect.bottom + maxHeight < window.innerHeight) {
                pos.top = rect.bottom + 7
            } else {
                // the relative parent (the overlay) doesn't have any height itself so if we want to position from
                // the bottom up, we need to pull the popup down
                pos.bottom = -rect.top
            }

            setPosition(pos)
        } catch (e) {
            setPosition(defaultPosition)
        }
    }, [editor, editor.children, maxHeight, range])

    return position
}
