import { Button } from '@blueprintjs/core'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

const StyledButton = styled(Button)`
    min-width: unset;
    min-height: unset;
    border-radius: 2px;
    font-size: 14px;
    line-height: 18px;
    transition: opacity 250ms ease-out;

    &.bp4-button:not([class*='bp4-intent-']) {
        background-image: none;
        box-shadow: none;
    }

    &:hover {
        opacity: 0.75;
    }
`

export const AnchorButton = styled.div<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    color: ${palette('navy', 0)};
    font-size: 14px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    ${ifProp(
        'disabled',
        css`
            opacity: 0.6;
        `,
    )}
`

export const PositiveButton = styled(StyledButton)`
    &.bp4-button:not([class*='bp4-intent-']) {
        background-color: ${palette('cloudBlueLight', 0)};
        color: ${palette('navy', 3)};
    }

    margin-right: 8px;
    padding: 4px 22px;
    border: solid 1px ${palette('cloudBlueLight', 4)};
`

export const NegativeButton = styled(StyledButton)`
    flex: 1;

    &.bp4-button:not([class*='bp4-intent-']) {
        background-color: transparent;
        color: ${palette('navy', 2)};
    }

    text-decoration: underline;
    border: none;
`
