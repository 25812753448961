import React from 'react'

import {
    SelectInput,
    SelectInputProps,
} from 'src/components/Session/panels/SpeakerPanel/SelectInput'

interface AutoSuggestInputProps
    extends Omit<
        SelectInputProps<string>,
        'query' | 'onQueryChange' | 'onItemSelect' | 'selectedItem'
    > {
    onChange: (item: string) => void
    value: string
}

export const AutoSuggestInput = ({ onChange, value, ...props }: AutoSuggestInputProps) => (
    <SelectInput
        {...props}
        query={value}
        onQueryChange={onChange}
        selectedItem={value}
        onItemSelect={onChange}
    />
)
