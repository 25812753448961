import React, { ReactNode, useMemo } from 'react'
import { Menu, MenuItem } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { ArrowDownIcon } from 'src/components/icons'
import { FlexTargetPopover } from 'src/components/AudioPlayer/PlaybackSpeedButton'

const StyledPopover = styled(FlexTargetPopover)`
    flex: 1;
    flex-direction: column;

    &:not(:last-of-type) {
        margin-right: 25px;
    }
`

const SelectedOption = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 6px;
    color: ${palette('navy', 2)};
    cursor: pointer;

    svg {
        width: 10px;
        height: auto;
        color: ${palette('cloudBlue', 0)};
    }
`

const StyledMenu = styled(Menu)`
    min-width: 105px;
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    overflow: hidden;
`

const StyledMenuItem = styled(MenuItem)`
    padding: 10px;
    border-radius: 0;
    color: ${palette('navy', 6)} !important;
    font-size: 14px;
    line-height: normal;
    transition: background-color 250ms ease-out;

    &:hover,
    &.bp4-active {
        background-color: ${palette('cloudBlueLight', 2)} !important;
    }

    svg {
        width: 18px;
        height: auto;
        color: ${palette('navy', 2)} !important;
    }
`

const SelectedOptionLabel = styled.div`
    text-transform: capitalize;
`

export interface MenuPopoverOption<T> {
    value: T | null
    label: string
    icon?: JSX.Element
}

interface MenuPopoverProps<T> {
    onSelect: (optionValue: T | null) => void
    selectedOptionValue: T | null
    options: MenuPopoverOption<T>[]
    renderSelectedOptionLabel?: (label: string) => ReactNode
    className?: string
}

export function MenuPopover<T>({
    onSelect,
    selectedOptionValue,
    options,
    renderSelectedOptionLabel = (label) => label,
    className,
}: MenuPopoverProps<T>) {
    const selectedOption = useMemo(
        () => options.find(({ value }) => selectedOptionValue === value),
        [selectedOptionValue, options],
    )

    return (
        <StyledPopover
            usePortal={false}
            position="bottom-left"
            className={className}
            modifiers={{ arrow: { enabled: false } }}
            content={
                <StyledMenu>
                    {options.map(({ value, label, icon }, i) => (
                        <StyledMenuItem
                            key={i}
                            onClick={() => onSelect(value)}
                            text={label}
                            icon={icon}
                            active={value === selectedOptionValue}
                        />
                    ))}
                </StyledMenu>
            }
        >
            <SelectedOption>
                <SelectedOptionLabel>
                    {selectedOption && renderSelectedOptionLabel(selectedOption.label)}
                </SelectedOptionLabel>
                <ArrowDownIcon />
            </SelectedOption>
        </StyledPopover>
    )
}
