import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { OnboardingTask } from 'src/models'
import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { PersonIcon } from 'src/components/icons'

import { useOnboardingStepPayload } from '../useOnboardingStepPayload'
import { Title } from './common'

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    text-transform: capitalize;
`

const RoleTeamMemberCount = styled.div`
    display: flex;
    align-items: baseline;
    margin-right: 18px;

    strong {
        font-size: 18px;
        font-weight: 700;
        margin-right: 1ch;
    }
`

const CurrentUserLabel = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;

    strong {
        font-weight: 500;
    }
`

const PersonIconWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: ${palette('cloudBlueLight', 2)};

    svg {
        width: 20px;
        height: 20px;
        color: ${palette('blue', 0)};
    }
`

export function TeamIntroductionStep() {
    const { layerId: userLayerId } = useTask<OnboardingTask>()
    const { team } = useOnboardingStepPayload('team_intro')

    return (
        <>
            <Title>Your Team</Title>

            {team.map(({ layerId: teamLayerId, roleDisplayName, count }) => {
                const roleToDisplay = count > 1 ? `${roleDisplayName}s` : roleDisplayName // TODO: handle plural
                return (
                    <Row key={teamLayerId}>
                        <RoleTeamMemberCount>
                            <strong>{count}</strong> <div>{roleToDisplay}</div>
                        </RoleTeamMemberCount>

                        {teamLayerId === userLayerId && (
                            <CurrentUserLabel>
                                <PersonIconWrapper>
                                    <PersonIcon />
                                </PersonIconWrapper>

                                <div>
                                    <strong>You</strong> {count > 1 && `+${count - 1}`}
                                </div>
                            </CurrentUserLabel>
                        )}
                    </Row>
                )
            })}
        </>
    )
}
