import { Editor, Path, Transforms, Node, Range, Point, Text } from 'slate'
import LogRocket from 'logrocket'
import { debounce } from 'lodash'

import { isPunctuation, ZERO_WIDTH_WHITESPACE } from 'src/utils/string'

import { Block } from './Block'
import { Content } from './Content'
import { Suggestion } from '../withSuggestions/Suggestion'
import { blocksAnalyticsSingleton } from './blocksAnalytics'
import { BlockView } from './components/BlockView'
import { ContentView } from './components/ContentView'
import { ContentTextView } from './components/ContentTextView'
import { ContentText } from './ContentText'

interface WithTranscriptProps {
    isSpeakerLineVisible?: boolean
    shouldEnableQACAutomation?: boolean
}

/**
 * This plugin handles all the speaker blocks.
 *
 * CAREFUL: Always make sure to apply this plugin first, because the `insertBreak` behavior will not allow any further handling so any
 * other plugin can alter the behavior before this plugin is called.
 */
export function withTranscript({
    isSpeakerLineVisible,
    shouldEnableQACAutomation,
}: WithTranscriptProps) {
    return (editor: Editor) => {
        const {
            deleteFragment,
            normalizeNode,
            apply,
            deleteBackward,
            deleteForward,
            insertText,
            renderElement,
        } = editor
        editor.shouldEnableQACAutomation = shouldEnableQACAutomation ?? true

        const deleteEmptyBlock = () => {
            if (!editor.selection) {
                return
            }

            const [[, currentBlockPath]] = Editor.nodes(editor, { match: Block.isBlock })
            const nextBlockPath = Path.next(currentBlockPath)

            // the next block might not exist, for example when there's no after-context.
            if (Editor.hasPath(editor, nextBlockPath)) {
                const contentNodeEntries = Array.from(
                    Editor.nodes(editor, {
                        at: nextBlockPath,
                        match: Content.isContent,
                    }),
                )

                // if the merged block contained complex nodes in it's content node,
                // the merged block will remain empty
                if (!contentNodeEntries.length) {
                    // remove the empty block
                    Transforms.removeNodes(editor, { at: nextBlockPath })
                }
            }
        }

        editor.insertText = (text) => {
            insertText(text)
            deleteEmptyBlock()
        }

        // prevent deleteFragment selection from crossing the active segment borders
        editor.deleteFragment = () => {
            if (!editor.selection) {
                return
            }

            const start = Range.start(editor.selection)
            const end = Range.end(editor.selection)

            const blockEntries = Array.from(
                Editor.nodes(editor, {
                    at: [],
                    mode: 'highest',
                    match: (node) => Block.isBlock(node) && node.editable,
                }),
            )
            const [, firstEditableBlockPath] = blockEntries[0]
            const [, lastEditableBlockPath] = blockEntries[blockEntries.length - 1]
            const firstEditableBlockStart = Editor.start(editor, firstEditableBlockPath)
            const lastEditableBlockEnd = Editor.end(editor, lastEditableBlockPath)

            Transforms.select(editor, {
                anchor: Point.isBefore(start, firstEditableBlockStart)
                    ? firstEditableBlockStart
                    : start,
                focus: Point.isAfter(end, lastEditableBlockEnd) ? lastEditableBlockEnd : end,
            })

            deleteFragment()
            deleteEmptyBlock()
        }

        editor.apply = (op) => {
            // make sure the cursor doesn't get lost on the edge of a editable / non-editable block
            if (op.type === 'set_selection' && op.newProperties !== null) {
                // if the anchor of the new selection is within a non-editable block, don't apply the selection
                const [anchorBlockEntry] = op.newProperties.anchor
                    ? Editor.nodes(editor, { at: op.newProperties.anchor, match: Block.isBlock })
                    : []
                if (anchorBlockEntry && !anchorBlockEntry[0].editable) {
                    return
                }

                // if the focus of the new selection is within a non-editable block, don't apply the selection
                const [focusBlockEntry] = op.newProperties.focus
                    ? Editor.nodes(editor, { at: op.newProperties.focus, match: Block.isBlock })
                    : []
                if (focusBlockEntry && !focusBlockEntry[0].editable) {
                    return
                }
            }

            apply(op)

            // if a content node gets merged, that means that the block also got merged.
            // Therefore, we re-apply the annotation for the current block in order to ensure the correct alternation of q/a
            if (
                editor.shouldEnableQACAutomation &&
                op.type === 'merge_node' &&
                Content.isContent(op.properties)
            ) {
                const [[block, blockPath]] = Editor.nodes(editor, {
                    at: op.path.slice(0, op.path.length - 1),
                    match: Block.isBlock,
                })
                if (block.legal?.annotation) {
                    Block.setLegalAnnotation(editor, blockPath, block.legal.annotation)
                }
            }
        }

        editor.normalizeNode = (entry) => {
            const [node, path] = entry

            if (Block.isBlock(node)) {
                const contentNodeEntries = Array.from(
                    Editor.nodes(editor, {
                        at: path,
                        match: (child) => Content.isContent(child),
                    }),
                )

                // if there are 2 content nodes, it means that the user actually wants 2 blocks so we create the new block and move the 2nd content node over
                if (contentNodeEntries.length > 1) {
                    // this case should not happen anymore! If it does, we want a warning to investigate.
                    LogRocket.getSessionURL((logrocketSessionURl) => {
                        window.Rollbar?.warning(
                            `A block was not properly split and had to be normalized!`,
                            node,
                            path,
                            logrocketSessionURl,
                        )
                        LogRocket.warn(
                            `A block was not properly split and had to be normalized!`,
                            node,
                            path,
                        )
                    })

                    // Insert second node
                    const newBlockPath = Path.next(path)
                    const newContentPath = newBlockPath.concat(0)
                    Transforms.insertNodes(
                        editor,
                        {
                            ...node,
                            hasExplicitBreak: true,
                            section: undefined,
                            legal: { ...node.legal, exhibit: undefined },
                            children: [],
                        },
                        { at: newBlockPath },
                    )

                    // Move second content node to new block node
                    Transforms.moveNodes(editor, {
                        at: contentNodeEntries[1][1],
                        to: newContentPath,
                    })

                    // Delete leading spaces from new block and capitalize first non-space character
                    const text = Node.string(contentNodeEntries[1][0])
                    const firstNonSpacePos = text.search(/[^ ]|$/)
                    const firstNonSpaceChar = text[firstNonSpacePos]
                    let charsToDelete = firstNonSpacePos
                    while (charsToDelete > 0) {
                        Editor.deleteForward(editor)
                        charsToDelete--
                    }

                    // don't delete zero width spaces from any tags
                    if (firstNonSpaceChar && firstNonSpaceChar !== ZERO_WIDTH_WHITESPACE) {
                        Editor.withoutNormalizing(editor, () => {
                            Editor.deleteForward(editor)
                            Editor.insertText(editor, firstNonSpaceChar.toUpperCase())
                            Transforms.move(editor, { distance: 1, reverse: true })
                        })
                    }

                    if (editor.shouldEnableQACAutomation && node.legal?.annotation) {
                        // Propagate the change to the blocks below the original block (the one we split from)
                        Block.autoPopulateSpeakersAndLegalAnnotations(
                            editor,
                            path[0],
                            node.legal.annotation,
                            true,
                        )
                    }
                }

                const [childBlock] = Editor.nodes(editor, {
                    at: path,
                    match: (n, p) => Block.isBlock(n) && !Path.equals(path, p),
                })

                if (!!childBlock) {
                    Transforms.unwrapNodes(editor, {
                        at: path,
                        match: (n, p) => Block.isBlock(n) && !Path.equals(path, p),
                    })
                    return
                }
            }

            // normalise text nodes without undefined type
            if (Text.isText(node)) {
                if (node.type === undefined) {
                    Transforms.setNodes(editor, { type: 'text', editable: true }, { at: path })
                    return
                }
            }

            normalizeNode(entry)
        }

        editor.insertBreak = debounce(
            () => {
                // handle case, when trying to split on the first editable block, we just want to toggle the explicit break instead of actually splitting the block.
                if (editor.selection && Range.isCollapsed(editor.selection)) {
                    try {
                        const [[block, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
                        const previousPath = Path.previous(blockPath)
                        const [[prevBlock]] = Editor.nodes(editor, {
                            at: previousPath,
                            match: Block.isBlock,
                        })
                        if (
                            isSpeakerLineVisible &&
                            !prevBlock.editable &&
                            !block.hasExplicitBreak &&
                            Point.equals(editor.selection.anchor, Editor.start(editor, blockPath))
                        ) {
                            Block.toggleExplicitBreak(editor, blockPath, true)
                            return
                        }
                    } catch (e) {}
                }

                // remember the block, before it was split
                const [[blockBeforeSplitting, blockPathBeforeSplitting]] = Editor.nodes(editor, {
                    match: Block.isBlock,
                })

                // slate's default insertBreak() will split at the lowest non-leaf node (which is a content node in our case)
                // but we want to always split the block.
                Transforms.splitNodes(editor, { match: Block.isBlock, always: true })
                Transforms.setNodes(
                    editor,
                    { hasExplicitBreak: true, section: undefined, __isNewBlock: true },
                    { match: Block.isBlock },
                )
                blocksAnalyticsSingleton.increment()

                // Delete leading spaces from new block and capitalize first non-space character
                // Therefor, we need to fetch the new block path for the block that was split off.
                const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })
                const text = Editor.string(editor, blockPath)
                const firstNonSpacePos = text.search(/[^ ]|$/)
                const firstNonSpaceChar = text[firstNonSpacePos]
                let charsToDelete = firstNonSpacePos
                while (charsToDelete > 0) {
                    Editor.deleteForward(editor)
                    charsToDelete--
                }

                // Replace first character of new block with its capitalized version.
                // don't delete zero width spaces from any tags though
                if (firstNonSpaceChar && firstNonSpaceChar !== ZERO_WIDTH_WHITESPACE) {
                    Editor.withoutNormalizing(editor, () => {
                        Editor.deleteForward(editor)
                        Editor.insertText(editor, firstNonSpaceChar.toUpperCase())
                        Transforms.move(editor, { distance: 1, reverse: true })

                        const [suggestionEntry] = Editor.nodes(editor, {
                            at: blockPath,
                            match: Suggestion.isSuggestion,
                        })
                        if (suggestionEntry) {
                            const [, suggestionPath] = suggestionEntry

                            // if the first word in the new block is a suggestion
                            if (
                                !Editor.string(editor, {
                                    anchor: Editor.start(editor, blockPath),
                                    focus: Editor.start(editor, suggestionPath),
                                }).length
                            ) {
                                // update the originalText of the suggestion, since we capitalized it's text node content
                                const updatedSuggestionText = Editor.string(editor, suggestionPath)
                                Transforms.setNodes(
                                    editor,
                                    { originalText: updatedSuggestionText },
                                    { at: suggestionPath },
                                )
                            }
                        }
                    })
                }

                if (editor.shouldEnableQACAutomation && blockBeforeSplitting.legal?.annotation) {
                    // Propagate the change to the blocks below the original block (the one we split from)
                    Block.autoPopulateSpeakersAndLegalAnnotations(
                        editor,
                        blockPathBeforeSplitting[0],
                        blockBeforeSplitting.legal.annotation,
                        true,
                    )
                }

                // intentionally, not call insertBreak here. This is, because we already split the block so we
                // want to avoid the default behavior.
            },
            50,
            { leading: true },
        )

        editor.deleteBackward = (unit) => {
            if (editor.selection) {
                const [[, blockPath]] = Editor.nodes(editor, { match: Block.isBlock })

                try {
                    // Path.previous can throw an error on a path with a negative index (no before context)
                    const previousPath = Path.previous(blockPath)
                    const [[prevBlock]] = Editor.nodes(editor, {
                        at: previousPath,
                        match: Block.isBlock,
                    })

                    // if merging current block to previous block
                    if (
                        Range.isCollapsed(editor.selection) &&
                        !Editor.string(editor, {
                            anchor: editor.selection.anchor,
                            focus: Editor.start(editor, blockPath),
                        }).length
                    ) {
                        if (!prevBlock.editable) {
                            if (isSpeakerLineVisible) {
                                Block.toggleExplicitBreak(editor, blockPath, false)
                            }
                            return
                        }

                        Editor.withoutNormalizing(editor, () => {
                            let shouldAddSpaceBetweenMergedNodes = false

                            /*
                             * This piece of code is for determining if we should add a space between first and last words of merged blocks
                             * It's inside Editor.withoutNormalizing because we want to check the blocks state of the editor
                             * before the nodes tree is changed
                             * */
                            if (editor.selection) {
                                const [, currentNodePath] = Editor.node(
                                    editor,
                                    editor.selection.anchor,
                                )
                                const previousNodeEntry = Editor.previous(editor, {
                                    at: editor.selection.anchor,
                                    match: (n) => Block.isBlock(n) && n.editable,
                                })
                                if (!!previousNodeEntry) {
                                    const [, previousNodePath] = previousNodeEntry
                                    const firstCharacterOfCurrentNode = Editor.string(
                                        editor,
                                        currentNodePath,
                                    )[0]
                                    const previousNodeText = Editor.string(editor, previousNodePath)
                                    const lastCharacterOfPreviousNode =
                                        previousNodeText[previousNodeText.length - 1]

                                    shouldAddSpaceBetweenMergedNodes =
                                        (firstCharacterOfCurrentNode === ZERO_WIDTH_WHITESPACE ||
                                            (firstCharacterOfCurrentNode !== ' ' &&
                                                !isPunctuation(firstCharacterOfCurrentNode))) &&
                                        !!lastCharacterOfPreviousNode &&
                                        lastCharacterOfPreviousNode !== ' '
                                }
                            }

                            deleteBackward(unit)
                            if (shouldAddSpaceBetweenMergedNodes) {
                                Editor.insertText(editor, ' ')
                            }

                            const contentNodeEntries = Array.from(
                                Editor.nodes(editor, {
                                    at: blockPath,
                                    match: Content.isContent,
                                }),
                            )

                            // if the merged block contained complex nodes in it's content node,
                            // the merged block will remain empty
                            if (!contentNodeEntries.length) {
                                // remove the empty block
                                Transforms.removeNodes(editor, { at: blockPath })
                            }
                        })

                        return
                    }

                    if (!prevBlock.editable) {
                        const distanceFromStart = Editor.string(editor, {
                            anchor: Editor.start(editor, blockPath),
                            focus: editor.selection.anchor,
                        }).length

                        if (!distanceFromStart) {
                            return
                        }
                    }
                } catch {}
            }

            deleteBackward(unit)
        }

        editor.deleteForward = (unit) => {
            if (editor.selection) {
                const [[, blockPath]] = Editor.nodes(editor, {
                    match: Block.isBlock,
                    reverse: true,
                })
                const nextPath = Path.next(blockPath)

                try {
                    // Editor.nodes at nextPath can throw an error if we dont really have a node at this path (no after context)
                    const [[nextBlock, nextBlockPath]] = Editor.nodes(editor, {
                        at: nextPath,
                        match: Block.isBlock,
                    })

                    if (
                        Range.isCollapsed(editor.selection) &&
                        !Editor.string(editor, {
                            anchor: editor.selection.anchor,
                            focus: Editor.end(editor, blockPath),
                        }).length
                    ) {
                        if (!nextBlock.editable) {
                            return
                        }

                        Editor.withoutNormalizing(editor, () => {
                            let shouldAddSpaceBetweenMergedNodes = false
                            /*
                             * This piece of code is for determining if we should add a space between first and last words of merged blocks
                             * It's inside Editor.withoutNormalizing because we want to check the blocks state of the editor
                             * before the nodes tree is changed
                             * */
                            if (editor.selection) {
                                const [, currentNodePath] = Editor.node(
                                    editor,
                                    editor.selection.anchor,
                                )
                                const nextNodeEntry = Editor.next(editor, {
                                    at: editor.selection.anchor,
                                    match: (n) => Block.isBlock(n) && n.editable,
                                })
                                if (!!nextNodeEntry) {
                                    const [, nextNodePath] = nextNodeEntry
                                    const firstCharacterOfNextNode = Editor.string(
                                        editor,
                                        nextNodePath,
                                    )[0]
                                    const currentNodeText = Editor.string(editor, currentNodePath)
                                    const lastCharacterOfCurrentNode =
                                        currentNodeText[currentNodeText.length - 1]
                                    shouldAddSpaceBetweenMergedNodes =
                                        (firstCharacterOfNextNode === ZERO_WIDTH_WHITESPACE ||
                                            (firstCharacterOfNextNode !== ' ' &&
                                                !isPunctuation(firstCharacterOfNextNode))) &&
                                        !!lastCharacterOfCurrentNode &&
                                        lastCharacterOfCurrentNode !== ' '
                                }
                            }

                            if (shouldAddSpaceBetweenMergedNodes) {
                                Editor.insertText(editor, ' ')
                            }
                            deleteForward(unit)

                            const contentNodeEntries = Array.from(
                                Editor.nodes(editor, {
                                    at: nextBlockPath,
                                    match: Content.isContent,
                                }),
                            )

                            // if the merged block contained complex nodes in it's content node,
                            // the merged block will remain empty
                            if (!contentNodeEntries.length) {
                                // remove the empty block
                                Transforms.removeNodes(editor, { at: nextBlockPath })
                            }
                        })

                        return
                    }

                    if (!nextBlock.editable) {
                        const distanceFromEnd = Editor.string(editor, {
                            anchor: editor.selection.anchor,
                            focus: Editor.end(editor, blockPath),
                        }).length

                        if (!distanceFromEnd) {
                            return
                        }
                    }
                } catch (e) {}
            }

            deleteForward(unit)
        }

        editor.renderElement = (props) => {
            switch (props.element.type) {
                case 'block':
                    return <BlockView {...props} element={props.element} />
                case 'content':
                    return <ContentView {...props} element={props.element} />
            }

            return renderElement(props)
        }

        editor.renderLeaf = (props) => {
            return <ContentTextView {...props} leaf={props.leaf as ContentText} />
        }

        return editor
    }
}
