import { BaseSegment, BaseSegmentBlock, SegmentPoint } from './Segment'

export type TextSegmentBlock = BaseSegmentBlock & { words: string[] }

export type TextSegment = BaseSegment<TextSegmentBlock>

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextSegment = {
    createBlock(props: Partial<TextSegmentBlock>): TextSegmentBlock {
        return BaseSegment.createBlock<TextSegmentBlock>({
            words: [],
            ...props,
        })
    },
    create(props: Partial<TextSegment>): TextSegment {
        return BaseSegment.create<TextSegment, TextSegmentBlock>({
            ...props,
        })
    },

    concat(baseSegment: TextSegment, ...segments: TextSegment[]): TextSegment {
        return BaseSegment.concat(baseSegment, ...segments)
    },

    getWordAtPoint(segment: TextSegment, point: SegmentPoint): string | undefined {
        const block = BaseSegment.getBlock(segment, point.blockIdx)
        return block?.words[point.wordIdx]
    },
}
