import { Icon } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { VoiceSampleButton } from 'src/components/Common/VoiceSampleButton'

export const StyledVoiceSampleButton = styled(VoiceSampleButton)`
    width: 16px;
    height: 16px;
    color: ${palette('cloudBlue', 2)};
    transition: color 250ms ease-out;
`

export const TickIcon = styled(Icon).attrs({ icon: 'tick', iconSize: 14 })`
    padding: 1px;
    color: ${palette('navy', 2)};
`

export const Title = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: ${palette('navy', 6)};
`

export const Header = styled(Title)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    flex: 0 0 54px;
`

// TODO: move
export const Button = styled.button`
    display: flex;
    align-items: center;
    padding: 6px 13px;
    border-radius: 4px;
    border: solid 1px ${palette('cloudBlueLight', 4)};
    background-color: ${palette('cloudBlueLight', 0)};
    color: ${palette('navy', 6)};
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    transition: opacity 250ms ease-out;

    &:hover {
        opacity: 0.75;
    }

    &:disabled {
        opacity: 0.5;
        cursor: initial;
        pointer-events: none;
    }
`
