import { useState } from 'react'
import { ReactEditor, useSelected } from 'slate-react'
import styled, { css } from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { useEditorMeta } from 'src/components/Editor/EditorContext'
import { Content } from '../Content'
import { useIsReadOnlyMode } from 'src/components/Session/live'
import { useSessionMedia, useSessionMediaService } from 'src/state/SessionMediaProvider'

const Common = styled.div`
    width: 100%;
    padding: 5px 28px;
    outline: none;
    white-space: break-spaces;
    word-break: break-word;
`

const EditableContent = styled(Common)<{
    isAudioInEditableSegment: boolean
    isEditorInGlossersMode: boolean
    isBLockSelected: boolean
}>`
    min-height: 42px;
    padding: 6px 15px 14px 15px;
    margin-left: 12px;
    background-color: ${palette('white', 0)};
    border-radius: 4px;
    border: solid 1px ${ifProp('isAudioInEditableSegment', palette('blue', 0), palette('grey', 13))};
    transition: border-color 250ms ease-in;

    &:active,
    &:hover,
    &:focus {
        border: solid 1px ${palette('blue', 0)};
        border-color: ${palette('blue', 0)} !important;
    }

    ${ifProp(
        'isEditorInGlossersMode',
        css`
            background-color: transparent;
            border-color: ${palette('cloudBlue', 5)};
        `,
    )}

    ${ifProp(
        'isBLockSelected',
        css`
            border: solid 1px ${palette('blue', 0)};
            border-color: ${palette('blue', 0)};
        `,
    )}
`

const ReadOnlyContent = styled(Common)`
    pointer-events: all;
    cursor: not-allowed;
    ::-webkit-touch-callout: all;
`

interface ContentViewProps {
    element: Content
    attributes: any
    children: any
}

export const ContentView = ({ element, attributes, children }: ContentViewProps) => {
    const editor = ReactEditor as unknown as ReactEditor
    const [blockIndex] = ReactEditor.findPath(editor, element)
    const { editorMode } = useEditorMeta()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const Component = element.editable && !isRealTimeReadOnly ? EditableContent : ReadOnlyContent
    const isEditorInGlossersMode = editorMode === 'glossers'
    const [isAudioInEditableSegment, setIsAudioInEditableSegment] = useState(false)
    const { mediaTiming } = useSessionMedia(['mediaTiming'])
    const selected = useSelected()

    useSessionMediaService(
        ({ currentTime }) => {
            const start = mediaTiming?.activeSegment.start
            const end = mediaTiming?.activeSegment.end

            const isInEditableSegment =
                start !== undefined &&
                end !== undefined &&
                currentTime >= start &&
                currentTime <= end
            if (isAudioInEditableSegment !== isInEditableSegment) {
                setIsAudioInEditableSegment(isInEditableSegment)
            }
        },
        ['currentTime'],
    )

    return (
        <Component
            id={'content-view-' + blockIndex}
            className={'content-view-' + blockIndex}
            {...attributes}
            // We need a way to identify the DOM element that contains the text,
            // so when a click occures on slate's Editable, we can differentiate if it was on text or not
            data-slate-content={true}
            contentEditable={element.editable && !isRealTimeReadOnly}
            suppressContentEditableWarning={true}
            // If the block is Readonly (contentEditable=false), it is not focusable by default
            // and hence breaks the selection logic for the onClick mouse event on Editable.
            // Setting tabIndex to 1 means the browser treats it as focusable and reachable via tab press.
            // Setting tabIndex to -1 means the browser treats it as focusable but not reachable via tab press (which is desirable behavior for Read-only blocks)
            tabIndex={element.editable ? 1 : -1}
            isEditorInGlossersMode={isEditorInGlossersMode}
            isAudioInEditableSegment={isAudioInEditableSegment}
            isBLockSelected={selected}
        >
            {children}
        </Component>
    )
}
