import { useCallback, useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'

interface UserIdleOptions {
    timeoutMs?: number
    onIdle?: () => unknown
    onActive?: () => unknown
}

export function useUserIdleTime(options: UserIdleOptions = {}) {
    const { timeoutMs = 1000 * 5, onIdle: onIdleExternal, onActive: onActiveExternal } = options

    const cumulatedIdleTimeMsRef = useRef(0)
    const lastIdleStartRef = useRef(0)

    const onIdle = useCallback(() => {
        lastIdleStartRef.current = new Date().getTime() - timeoutMs

        onIdleExternal?.()
    }, [onIdleExternal, timeoutMs])

    const onActive = useCallback(() => {
        if (lastIdleStartRef.current > 0) {
            cumulatedIdleTimeMsRef.current += new Date().getTime() - lastIdleStartRef.current
            lastIdleStartRef.current = 0
        }

        onActiveExternal?.()
    }, [onActiveExternal])

    const getUserIdleTime = useCallback(() => {
        let idleTime = cumulatedIdleTimeMsRef.current

        // if user is currently idle, add up the idle time to the current moment
        if (lastIdleStartRef.current > 0) {
            idleTime += new Date().getTime() - lastIdleStartRef.current
        }

        return idleTime
    }, [])

    const resetUserIdleTime = useCallback(() => {
        cumulatedIdleTimeMsRef.current = 0
    }, [])

    useIdleTimer({
        timeout: timeoutMs,
        onIdle,
        onActive,
    })

    return { getUserIdleTime, resetUserIdleTime }
}
