import { BaseEditor, Editor, Transforms, Element, Range, Text, Point, Node, Path } from 'slate'
import { HistoryEditor } from 'slate-history'

import { uuid } from 'src/utils/uuid'
import { ZERO_WIDTH_WHITESPACE, ZERO_WIDTH_WHITESPACE_REGEX } from 'src/utils/string'

import { Tag } from './Tag'
import { TagView } from './TagsView'
import { Label } from 'src/models'
import { GlossaryPopup } from './GlossaryPopup'

/*

TAGS EXPLANATION
--------------

Tags are used to mark specific word(s) / places that have a special meaning. For example a name or a date. 
These are then wrapped with a tag of a specific type which indicates this to the backend. In slate, a tag is represented
as an inline node. There are 2 kinds:
  - selection
  - void
A selection tag is simply a wrapper around 1 or more words. It can be empty or have a placeholder inserted when its empty.
A void tag does not have any contents but rather just indicates something at a specific point in the audio, for example laughter. Because
a void tag doesn't have any contents, it's also a void node within slate. 

IMPLEMENTATION

Most of the implementation is done by the plugin withTags() and the associated helper-namespace Tag. 

withTags() takes an array of TagDescriptions. They are the blueprints of possible tags within the editor. If a tag exists within the document
the plugin adds 4 zero-width whitespaces:
  - One at the end of the previous text node
  - One at the start of the tag text node
  - One at the end of the tag text node
  - One at the start of the next text node
These help ensure, that the user can navigate in and out of the tag explicitely (meaning 1 step to enter the tag and 1 step to overstep the first
character within the tag). Because they are inserted into the document, they shouldn't disturb the user experiences. Therefor the plugin ensures
that they are skipped when moving over the edges. This also includes actions like deleteForward or deleteBackward or expanding a selection over 
those edges.
If a TagDescription specifies a placeholderText and a tag of its type becomes empty, editor.normalizeNode automatically inserts the placeholder and 
adds a flag within the tag. Therefor it will now always make sure, that the whole placeholder is selected when a user selects the tag. The user can 
then simply step over the tag or replace the placeholder with text in which case the placeholder flag is removed. 

The namespace Tag mostly provides helpers to easily query the document around the domain of tags. 

WIP: void tags are not implemented yet.

*/

export interface TagDescBase {
    tagType: string
    data?: { [key: string]: any }
}

interface SelectionTagDesc extends TagDescBase {
    kind: 'selection'
    placeholderText?: string
}

interface VoidTagDesc extends TagDescBase {
    kind: 'void'
}

export type TagDescription = SelectionTagDesc | VoidTagDesc

export interface TagEditor extends BaseEditor {
    availableTags: TagDescription[]
    previousSelection: Range | null
}

type WithTagsLabelOptions =
    | {
          enableLabels: true
          labels: Label[]
      }
    | { enableLabels?: false }

type WithTagsOptions = {
    enableGlossary?: boolean
    enableUnclears?: boolean
    availableTags?: TagDescription[]
} & WithTagsLabelOptions

export function withTags(options: WithTagsOptions = {}) {
    const availableTags: TagDescription[] = options.availableTags ?? []

    if (options.enableGlossary) {
        availableTags.push({
            kind: 'selection',
            tagType: 'glossary',
        })
    }

    if (options.enableUnclears) {
        availableTags.push({
            kind: 'selection',
            tagType: 'unclear',
            placeholderText: '\u2026',
        })
    }

    if (options.enableLabels) {
        availableTags.push({
            kind: 'void',
            tagType: 'label',
            data: {
                labels: options.labels,
            },
        })
    }

    return (editor: Editor) => {
        const {
            isInline,
            isVoid,
            onChange,
            apply,
            deleteBackward,
            deleteForward,
            normalizeNode,
            insertText,
            insertData,
            deleteFragment,
            insertBreak,
            renderElement,
            renderEditor,
        } = editor

        editor.availableTags = availableTags
        editor.previousSelection = null

        editor.isInline = (e: Element) => Tag.isTag(e) || isInline(e)
        editor.isVoid = (e: Element) => Tag.isVoidTag(e) || isVoid(e)

        // stores the previous selection
        editor.apply = (op) => {
            if (op.type === 'set_selection') {
                editor.previousSelection = editor.selection
            }

            apply(op)
        }

        /*
            - Helps a cursor over a tag edge (in both directions) because it needs to move several steps
            - Helps a cursor over a tag in case its an expanded selection
            - Ensures, that a placeholder is always fully selected
        */
        editor.onChange = () => {
            const isExpanded = !!editor.selection && Range.isExpanded(editor.selection)
            const hasSelectionOperation = editor.operations.some(
                (op) => op.type === 'set_selection',
            )

            if (editor.selection && editor.previousSelection && hasSelectionOperation) {
                // forward movement
                if (Point.isAfter(editor.selection.focus, editor.previousSelection.focus)) {
                    const [textEntry] = Editor.nodes(editor, {
                        at: editor.selection.focus,
                        match: Text.isText,
                    })
                    const [currentTag] = Editor.nodes(editor, {
                        at: editor.selection.focus,
                        match: Tag.isTag,
                    })
                    const nextTag = Tag.getNextTag(editor, { at: editor.selection.focus })
                    const currentTagIsVoid = !!currentTag && Tag.isVoidTag(currentTag[0])
                    const nextTagIsVoid = !!nextTag && Tag.isVoidTag(nextTag[0])

                    // moving into a tag
                    if (
                        !currentTag &&
                        !!nextTag &&
                        ((!!textEntry &&
                            Point.equals(
                                editor.selection.focus,
                                Editor.end(editor, textEntry[1]),
                            )) ||
                            !textEntry)
                    ) {
                        Transforms.move(editor, {
                            unit: 'offset',
                            distance: !nextTagIsVoid ? 2 : 1,
                            edge: isExpanded ? 'focus' : undefined,
                        })
                    }

                    // moving out of a tag
                    if (
                        !!currentTag &&
                        !currentTagIsVoid &&
                        ((!!textEntry &&
                            Point.equals(
                                editor.selection.focus,
                                Editor.end(editor, textEntry[1]),
                            )) ||
                            !textEntry)
                    ) {
                        Transforms.move(editor, {
                            unit: 'offset',
                            distance: !nextTagIsVoid ? 2 : 1,
                            edge: isExpanded ? 'focus' : undefined,
                        })
                    }

                    // make sure, the cursor doesn't sit directly at a tag edge (except for void nodes, because they don't have zero-widths within them)
                    // also refetch our information because the selection has possibly changed
                    const [newTextEntry] = Editor.nodes(editor, {
                        at: editor.selection.focus,
                        match: Text.isText,
                    }) // if we don't have this, then its a void tag
                    const prevTag = Tag.getPreviousTag(editor, { at: editor.selection.focus })
                    if (
                        !!newTextEntry &&
                        !!prevTag &&
                        Point.equals(editor.selection.focus, Editor.start(editor, newTextEntry[1]))
                    ) {
                        Transforms.move(editor, {
                            unit: 'offset',
                            edge: isExpanded ? 'focus' : undefined,
                        })
                    }
                }

                // backward movement
                if (Point.isBefore(editor.selection.focus, editor.previousSelection.focus)) {
                    const [textEntry] = Editor.nodes(editor, {
                        at: editor.selection.focus,
                        match: Text.isText,
                    })
                    const [currentTag] = Editor.nodes(editor, {
                        at: editor.selection.focus,
                        match: Tag.isTag,
                    })
                    const prevTag = Tag.getPreviousTag(editor, { at: editor.selection.focus })
                    const currentTagIsVoid = !!currentTag && Tag.isVoidTag(currentTag[0])
                    const prevTagIsVoid = !!prevTag && Tag.isVoidTag(prevTag[0])

                    // moving into a tag
                    if (
                        !currentTag &&
                        !!prevTag &&
                        ((!!textEntry &&
                            Point.equals(
                                editor.selection.focus,
                                Editor.start(editor, textEntry[1]),
                            )) ||
                            !textEntry)
                    ) {
                        Transforms.move(editor, {
                            unit: 'offset',
                            reverse: true,
                            distance: !prevTagIsVoid ? 2 : 1,
                            edge: isExpanded ? 'focus' : undefined,
                        })
                    }

                    // moving out of a tag
                    if (
                        !!currentTag &&
                        !currentTagIsVoid &&
                        ((!!textEntry &&
                            Point.equals(
                                editor.selection.focus,
                                Editor.start(editor, textEntry[1]),
                            )) ||
                            !textEntry)
                    ) {
                        Transforms.move(editor, {
                            unit: 'offset',
                            reverse: true,
                            distance: !prevTagIsVoid ? 2 : 1,
                            edge: isExpanded ? 'focus' : undefined,
                        })
                    }

                    // make sure, the cursor doesn't sit directly at a tag edge (except for void nodes, because they don't have zero-widths within them)
                    // also refetch our information because the selection has possibly changed
                    const [newTextEntry] = Editor.nodes(editor, {
                        at: editor.selection.focus,
                        match: Text.isText,
                    }) // if we don't have this, then its a void tag
                    const nextTag = Tag.getNextTag(editor, { at: editor.selection.focus })
                    if (
                        !!newTextEntry &&
                        !!nextTag &&
                        Point.equals(editor.selection.focus, Editor.end(editor, newTextEntry[1]))
                    ) {
                        Transforms.move(editor, {
                            unit: 'offset',
                            reverse: true,
                            edge: isExpanded ? 'focus' : undefined,
                        })
                    }
                }
            }

            // handle placeholder selection
            if (editor.selection && Tag.hasPlaceholder(editor)) {
                const isForward = Range.isForward(editor.selection)
                const isBackward = Range.isBackward(editor.selection)

                // if a users collapses a selection to one end of the tag (aka pressing arrow left or right), he wants to move
                // a collapsed selection out of the tag. We need to specifically detect this case here.
                if (
                    Range.isCollapsed(editor.selection) &&
                    editor.previousSelection &&
                    Range.isExpanded(editor.previousSelection)
                ) {
                    const [[, tagPath]] = Editor.nodes(editor, { match: Tag.isPlaceholderTag })
                    const startPoint = Tag.getStartPoint(editor, tagPath)
                    const endPoint = Tag.getEndPoint(editor, tagPath)

                    if (startPoint && Point.equals(editor.selection.focus, startPoint)) {
                        Transforms.move(editor, { distance: 3, unit: 'offset', reverse: true })
                    }

                    if (endPoint && Point.equals(editor.selection.focus, endPoint)) {
                        Transforms.move(editor, { distance: 3, unit: 'offset' })
                    }
                } else {
                    // check anchor
                    if (Tag.hasPlaceholder(editor, { at: editor.selection.anchor })) {
                        const [[, tagPath]] = Editor.nodes(editor, { match: Tag.isPlaceholderTag })
                        const startPoint = Tag.getStartPoint(editor, tagPath)
                        const endPoint = Tag.getEndPoint(editor, tagPath)

                        // normalize forward
                        if (
                            isForward &&
                            startPoint &&
                            !Point.equals(editor.selection.anchor, startPoint)
                        ) {
                            Transforms.select(editor, {
                                anchor: startPoint,
                                focus: editor.selection.focus,
                            })
                        }

                        // normalize backward
                        if (
                            isBackward &&
                            endPoint &&
                            !Point.equals(editor.selection.anchor, endPoint)
                        ) {
                            Transforms.select(editor, {
                                anchor: endPoint,
                                focus: editor.selection.focus,
                            })
                        }
                    }

                    // check focus
                    if (Tag.hasPlaceholder(editor, { at: editor.selection.focus })) {
                        const [[, tagPath]] = Editor.nodes(editor, { match: Tag.isPlaceholderTag })
                        const startPoint = Tag.getStartPoint(editor, tagPath)
                        const endPoint = Tag.getEndPoint(editor, tagPath)

                        let cursorDidMove = false

                        // extended forward movement
                        const oneAfterStart = startPoint && Editor.after(editor, startPoint)
                        if (
                            editor.previousSelection &&
                            Point.isAfter(editor.selection.focus, editor.previousSelection.focus) &&
                            oneAfterStart &&
                            Point.equals(editor.selection.focus, oneAfterStart)
                        ) {
                            const focus =
                                endPoint &&
                                Editor.after(editor, endPoint, { distance: 3, unit: 'offset' })

                            if (focus) {
                                Transforms.select(editor, {
                                    anchor: editor.selection.anchor,
                                    focus,
                                })
                                cursorDidMove = true
                            }
                        }

                        // extended backward movement
                        const oneBeforeEnd = endPoint && Editor.before(editor, endPoint)
                        if (
                            editor.previousSelection &&
                            Point.isBefore(
                                editor.selection.focus,
                                editor.previousSelection.focus,
                            ) &&
                            oneBeforeEnd &&
                            Point.equals(editor.selection.focus, oneBeforeEnd)
                        ) {
                            const focus =
                                startPoint &&
                                Editor.before(editor, startPoint, { distance: 3, unit: 'offset' })

                            if (focus) {
                                Transforms.select(editor, {
                                    anchor: editor.selection.anchor,
                                    focus,
                                })
                                cursorDidMove = true
                            }
                        }

                        if (!cursorDidMove) {
                            // normalize forward
                            if (
                                isForward &&
                                endPoint &&
                                !Point.equals(editor.selection.focus, endPoint)
                            ) {
                                Transforms.select(editor, {
                                    anchor: editor.selection.anchor,
                                    focus: endPoint,
                                })
                            }

                            // normalize backward
                            if (
                                isBackward &&
                                startPoint &&
                                !Point.equals(editor.selection.focus, startPoint)
                            ) {
                                Transforms.select(editor, {
                                    anchor: editor.selection.anchor,
                                    focus: startPoint,
                                })
                            }
                        }
                    }
                }
            }

            onChange()
        }

        /*
            - Inserts zero-width whitespaces on all necessary positions
            - Insert the placeholder on empty tags, if they require it
            - Deleting random whitespaces
        */
        editor.normalizeNode = (entry) => {
            const [node, path] = entry

            if (!Text.isText(node)) {
                return normalizeNode(entry)
            }

            const [parent, parentPath] = Editor.parent(editor, path)
            const hasPrevTag = !!Tag.getPreviousTag(editor, { at: path })
            const hasNextTag = !!Tag.getNextTag(editor, { at: path })
            const currIsTag = Tag.isTag(parent)
            const isVoidTag = Tag.isVoidTag(parent)
            const textContents = Editor.string(editor, path)

            if ((currIsTag || hasPrevTag) && !isVoidTag) {
                if (textContents[0] !== ZERO_WIDTH_WHITESPACE) {
                    Transforms.insertText(editor, ZERO_WIDTH_WHITESPACE, {
                        at: Editor.start(editor, path),
                    })
                    return
                }
            }

            if ((currIsTag || hasNextTag) && !isVoidTag) {
                const lastChar = textContents[textContents.length - 1]
                // also insert the 2nd whitespace on empty text nodes
                if (
                    lastChar !== ZERO_WIDTH_WHITESPACE ||
                    (lastChar === ZERO_WIDTH_WHITESPACE &&
                        textContents.length === 1 &&
                        (hasPrevTag || currIsTag))
                ) {
                    Transforms.insertText(editor, ZERO_WIDTH_WHITESPACE, {
                        at: Editor.end(editor, path),
                    })
                    return
                }
            }

            // insert placeholder, if the tagType supports it
            if (
                Tag.isSelectionTag(parent) &&
                Tag.isEmpty(editor, parentPath) &&
                Tag.isPlaceholderAvailable(editor, parent.tagType)
            ) {
                const tagRange = Tag.getTagRange(editor, parentPath)
                if (tagRange) {
                    Transforms.insertText(editor, Tag.getPlaceholderText(editor, parent.tagType), {
                        at: tagRange,
                    })
                    Transforms.setNodes(editor, { hasPlaceholder: true }, { at: parentPath })
                }
                return
            }

            // remove random whitspaces in the middle
            let searchedOffset = 0

            while (searchedOffset < node.text.length) {
                const offset = node.text.indexOf(ZERO_WIDTH_WHITESPACE, searchedOffset)

                if (offset === -1) {
                    break
                }

                let removeZeroWidth = true

                if ((currIsTag || hasPrevTag) && offset === 0) {
                    removeZeroWidth = false
                }
                if ((currIsTag || hasNextTag) && offset === node.text.length - 1) {
                    removeZeroWidth = false
                }

                if (removeZeroWidth && offset >= 0) {
                    Transforms.delete(editor, { at: { path, offset } })
                    return
                }
                searchedOffset = offset + 1
            }

            normalizeNode(entry)
        }

        /*
            - Moves a cursor backwards over the edge before deleting, if it is at the edge behind a tag
            - Removes empty tags or placeholder tags, if the cursor is at the edge behind a tag
        */
        editor.deleteBackward = (unit) => {
            if (unit !== 'character' || !editor.selection || !Range.isCollapsed(editor.selection)) {
                return deleteBackward(unit)
            }

            const [[, textPath]] = Editor.nodes(editor, {
                match: (n) => Text.isText(n) || Tag.isVoidTag(n),
            })
            const textStartPoint = Editor.start(editor, textPath)
            const tagBefore = Tag.getPreviousTag(editor)
            const hasTagBefore = !!tagBefore
            const isCurrentTag = Tag.hasSelectionTag(editor, { at: textPath })
            const textOneAfterStartPoint = Editor.after(editor, textStartPoint)
            const isOneAfterStart =
                textOneAfterStartPoint &&
                Point.equals(editor.selection.focus, textOneAfterStartPoint)
            const hasWhitespace =
                textOneAfterStartPoint &&
                Editor.string(editor, { anchor: textStartPoint, focus: textOneAfterStartPoint }) ===
                    ZERO_WIDTH_WHITESPACE
            const pathBeforeEnd = Editor.before(editor, textStartPoint)
            const [voidTag] = Editor.nodes(editor, { at: pathBeforeEnd, match: Tag.isVoidTag })

            // If the previous node is a void tag and the selection is collapsed right after it, remove it
            if (
                voidTag &&
                Point.equals(editor.selection.focus, textStartPoint) &&
                Point.equals(editor.selection.anchor, textStartPoint)
            ) {
                Transforms.removeNodes(editor, { at: voidTag[1] })
                return
            }

            // if the tag is empty, has a placeholder or is a void tag, remove it instead of moving the cursor
            if (
                isOneAfterStart &&
                tagBefore &&
                (Tag.isEmpty(editor, tagBefore[1]) ||
                    Tag.hasPlaceholder(editor, { at: tagBefore[1] }))
            ) {
                Transforms.removeNodes(editor, { at: tagBefore[1] })
                return
            }

            // if the cursor is technically at the start (only the zero-width is before), move the cursor into the previous tag
            else if ((hasTagBefore || isCurrentTag) && isOneAfterStart && hasWhitespace) {
                Transforms.move(editor, { reverse: true, unit: 'offset', distance: 3 })
            }

            deleteBackward(unit)
        }

        /*
            - Moves a cursor forwards over the edge before deleting, if it is at the edge in front of a tag
            - Removes empty or placeholder tags, if the cursor is at the edge in front of a tag
        */
        editor.deleteForward = (unit) => {
            if (unit !== 'character' || !editor.selection || !Range.isCollapsed(editor.selection)) {
                return deleteForward(unit)
            }

            const [[, textPath]] = Editor.nodes(editor, {
                match: (n) => Text.isText(n) || Tag.isVoidTag(n),
            })
            const textEndPoint = Editor.end(editor, textPath)
            const tagAfter = Tag.getNextTag(editor)
            const hasTagAfter = !!tagAfter
            const isCurrentTag = Tag.hasSelectionTag(editor, { at: textPath })
            const textOneBeforeEndPoint = Editor.before(editor, textEndPoint)
            const isOneBeforeEnd =
                textOneBeforeEndPoint && Point.equals(editor.selection.focus, textOneBeforeEndPoint)
            const hasWhitespace =
                textOneBeforeEndPoint &&
                Editor.string(editor, { anchor: textEndPoint, focus: textOneBeforeEndPoint }) ===
                    ZERO_WIDTH_WHITESPACE
            const pathAfterEnd = Editor.after(editor, textEndPoint)
            const [voidTag] = Editor.nodes(editor, { at: pathAfterEnd, match: Tag.isVoidTag })

            // If the next node is a void tag and the selection is collapsed right before it, remove it
            if (
                voidTag &&
                Point.equals(editor.selection.focus, textEndPoint) &&
                Point.equals(editor.selection.anchor, textEndPoint)
            ) {
                Transforms.removeNodes(editor, { at: voidTag[1] })
                return
            }

            // if the tag is empty or has a placeholder, remove it instead of moving the cursor
            if (
                isOneBeforeEnd &&
                tagAfter &&
                (Tag.isEmpty(editor, tagAfter[1]) ||
                    Tag.hasPlaceholder(editor, { at: tagAfter[1] }))
            ) {
                Transforms.removeNodes(editor, { at: tagAfter[1] })
                return
            }

            // if the cursor is technically at the start (only the zero-width is before), move the cursor into the previous tag
            else if ((hasTagAfter || isCurrentTag) && isOneBeforeEnd && hasWhitespace) {
                Transforms.move(editor, { distance: 3, unit: 'offset' })
            }

            deleteForward(unit)
        }

        /*
            - Handle removal of placeholder tags if they are selected
        */
        editor.deleteFragment = () => {
            if (!editor.selection || !Range.isExpanded(editor.selection)) {
                deleteFragment()
                return
            }

            const [selectionStart, selectionEnd] = Range.edges(editor.selection)
            const firstNode = Editor.after(editor, selectionStart)
            const lastNode = Editor.before(editor, selectionEnd)
            const [starterVoidTag] = Editor.nodes(editor, { at: firstNode, match: Tag.isVoidTag })
            const [endingVoidTag] = Editor.nodes(editor, { at: lastNode, match: Tag.isVoidTag })

            if (
                endingVoidTag &&
                starterVoidTag &&
                !Path.equals(starterVoidTag[1], endingVoidTag[1])
            ) {
                Transforms.removeNodes(editor, { at: endingVoidTag[1] })
            }

            if (starterVoidTag) {
                Transforms.removeNodes(editor, { at: starterVoidTag[1] })
            }

            if (Tag.hasSelectionTag(editor)) {
                if (Tag.hasSelectionTag(editor, { at: selectionStart })) {
                    const [[, tagPath]] = Editor.nodes(editor, {
                        at: selectionStart,
                        match: Tag.isSelectionTag,
                    })
                    const tagStart = Tag.getStartPoint(editor, tagPath)

                    if (tagStart && Point.equals(selectionStart, tagStart)) {
                        HistoryEditor.withoutMerging(editor, () => {
                            Transforms.move(editor, {
                                distance: 3,
                                unit: 'offset',
                                edge: 'start',
                                reverse: true,
                            })
                        })
                    }
                }

                if (Tag.hasSelectionTag(editor, { at: selectionEnd })) {
                    const [[, tagPath]] = Editor.nodes(editor, {
                        at: selectionEnd,
                        match: Tag.isSelectionTag,
                    })
                    const tagEnd = Tag.getEndPoint(editor, tagPath)

                    if (tagEnd && Point.equals(selectionEnd, tagEnd)) {
                        HistoryEditor.withoutMerging(editor, () => {
                            Transforms.move(editor, { distance: 3, unit: 'offset', edge: 'end' })
                        })
                    }
                }
            }

            deleteFragment()
        }

        /*
            - Always paste plain text
            - If the user tries to paste a complete tag somewhere, where there isn't a tag already, insert the tag with a new id
        */
        editor.insertData = (data) => {
            // if its not a slate-to-slate paste, just use the default paste routine
            if (!data.getData('application/x-slate-fragment')) {
                return insertData(data)
            }

            const fragment = JSON.parse(
                decodeURIComponent(atob(data.getData('application/x-slate-fragment'))),
            ) as Node[]

            // if its only 1 block it might be a single tag
            if (fragment.length === 1) {
                const possibleTag = Node.descendant(fragment[0], [0, 0])
                if (Tag.isTag(possibleTag)) {
                    const [referenceTagMatch] = Editor.nodes(editor, {
                        at: [],
                        match: (n) => Tag.isTag(n) && n.seq === possibleTag.seq,
                    })

                    if (referenceTagMatch) {
                        const referenceTagContents = Editor.string(
                            editor,
                            referenceTagMatch[1],
                        ).replace(ZERO_WIDTH_WHITESPACE_REGEX, '')
                        const pasteTagContents = Node.string(possibleTag).replace(
                            ZERO_WIDTH_WHITESPACE_REGEX,
                            '',
                        )
                        const isCompleteTag =
                            !!referenceTagMatch && referenceTagContents === pasteTagContents

                        // if the user copied a complete tag, insert it as a tag
                        if (isCompleteTag && !Tag.hasSelectionTag(editor)) {
                            // reassign sequence id
                            possibleTag.seq = uuid()
                            Transforms.insertNodes(editor, possibleTag)
                            return
                        }
                        // don't paste a placeholder text anywhere
                        else if (
                            pasteTagContents === Tag.getPlaceholderText(editor, possibleTag.tagType)
                        ) {
                            return
                        }
                    }
                }
            }

            // by default, only insert plain text
            const text = data.getData('text/plain')
            editor.insertText(text)
        }

        editor.insertBreak = () => {
            if (Tag.hasTag(editor) && editor.selection) {
                const [[, tagPath]] = Editor.nodes(editor, { match: Tag.isTag })
                const startPoint = Tag.getStartPoint(editor, tagPath)
                const endPoint = Tag.getEndPoint(editor, tagPath)

                // if the cursor is at the start or end of the tag, don't remove the tag, just move the cursor outside
                if (
                    startPoint &&
                    endPoint &&
                    Range.isCollapsed(editor.selection) &&
                    (Point.equals(editor.selection.anchor, startPoint) ||
                        Point.equals(editor.selection.anchor, endPoint))
                ) {
                    // front
                    if (Point.equals(editor.selection.anchor, startPoint)) {
                        Transforms.move(editor, { reverse: true, unit: 'offset', distance: 3 })
                        // back
                    } else if (Point.equals(editor.selection.anchor, endPoint)) {
                        Transforms.move(editor, { unit: 'offset', distance: 3 })
                    }
                } else {
                    Tag.unwrapTag(editor)
                }
            }
            insertBreak()
        }

        /*
            - Remove the flag for placeholder if it gets overwritten by the user
        */
        editor.insertText = (text) => {
            if (!editor.selection) {
                insertText(text)
                return
            }

            // if placeholder within tag is selected, remove the flag and let insertText() replace it
            // (bc the selection is exapnded and spans over the whole placeholder)
            if (
                Range.isExpanded(editor.selection) &&
                Tag.hasPlaceholder(editor) &&
                Tag.isAtStartOfTag(editor, { match: Tag.isPlaceholderTag }) &&
                Tag.isAtEndOfTag(editor, { match: Tag.isPlaceholderTag })
            ) {
                Transforms.unsetNodes(editor, 'hasPlaceholder', { match: Tag.isPlaceholderTag })
            }

            insertText(text)
        }

        editor.renderEditor = () => {
            return (
                <>
                    <GlossaryPopup />
                    {renderEditor()}
                </>
            )
        }

        editor.renderElement = (props) => {
            const { element } = props

            if (Tag.isTag(element)) {
                return <TagView {...props} element={element} />
            }

            return renderElement(props)
        }

        return editor
    }
}
