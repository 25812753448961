import { createContext, useState, useContext, ReactNode, useMemo } from 'react'

interface ExhibitsContextvalue {
    isExhibitPopupVisible: boolean
    setIsExhibitPopupVisible: React.Dispatch<React.SetStateAction<boolean>>
}
const ExhibitsContext = createContext<ExhibitsContextvalue | undefined>(undefined)

interface ExhibitProviderProps {
    children: ReactNode
}

const ExhibitsProvider = ({ children }: ExhibitProviderProps) => {
    const [isExhibitPopupVisible, setIsExhibitPopupVisible] = useState<boolean>(false)

    return (
        <ExhibitsContext.Provider
            value={useMemo(
                () => ({
                    isExhibitPopupVisible,
                    setIsExhibitPopupVisible,
                }),
                [isExhibitPopupVisible, setIsExhibitPopupVisible],
            )}
        >
            {children}
        </ExhibitsContext.Provider>
    )
}

const useExhibits = () => {
    const exhibitsContextValue = useContext(ExhibitsContext)
    if (!exhibitsContextValue) throw new Error('useExhibits must be used within ExhibitsProvider.')
    return exhibitsContextValue
}

export { ExhibitsProvider, useExhibits }
