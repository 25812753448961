import { useEffect } from 'react'

import { useTask } from 'src/state/state-machines/TaskMachine/TaskMachineProvider'
import { useTaskValidationMachine } from 'src/state/state-machines/TaskValidationMachine/TaskValidationMachineProvider'
import { useTaskValidation } from 'src/state/TaskValidationProvider'

export const TaskValidationHandler = () => {
    const task = useTask()
    const [{ value: taskValidationMachineState }, sendTaskValidationEvent] =
        useTaskValidationMachine(['state'])
    const { validateTask, resetTaskValidations } = useTaskValidation()

    useEffect(() => {
        if (taskValidationMachineState === 'pending') {
            const isTaskValid = validateTask()

            sendTaskValidationEvent({
                type: isTaskValid ? 'TASK_VALIDATION_SUCCEEDED' : 'TASK_VALIDATION_FAILED',
            })
        }
    }, [sendTaskValidationEvent, taskValidationMachineState, validateTask])

    useEffect(() => {
        return () => {
            resetTaskValidations()
        }
    }, [task?.id, resetTaskValidations])

    return null
}
