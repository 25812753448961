import { Editor, Range, Node, Text } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import { Intent } from '@blueprintjs/core'

import { useContextMenuBuilder } from 'src/components/ContextMenu/ContextMenuBuilder'
import { useToast } from 'src/components/Toasts/ToastContext'
import { FaEdit } from 'react-icons/fa'
import { BsPlus } from 'react-icons/bs'

import * as Icons from 'src/components/icons'
import { VolumeOffIcon, SearchIcon, RemoveIcon } from '@verbit-ai/icons-library'
import { isMac } from 'src/utils/platform'
import { Label } from 'src/models/label'
import { useGlossary } from 'src/state/GlossaryProvider'
import {
    TEMPORARY_GLOSSARY_ID,
    TAG_MAX_CHARS_LENGTH,
} from 'src/components/Glossary/useGlossaryList'

import { Suggestion } from '../withSuggestions/Suggestion'
import { Tag } from './Tag'
import { ZERO_WIDTH_WHITESPACE_REGEX } from 'src/utils/string'
import { useEditorMeta, useEditorPopup } from '../../EditorContext'
import { useModal } from 'src/state/ModalProvider'
import { ActionTriggerSource, useAnalytics } from 'src/analytics'
import { useIsReadOnlyMode } from 'src/components/Session/live'

/**
 * This hook adds all the necessary context menu entries for tags.
 */
export function useTagsContextMenu() {
    const editor = useSlateStatic()
    const analytics = useAnalytics()
    const { openPopup } = useEditorPopup()
    const { openModal, closeModal } = useModal()
    const addToast = useToast()
    const { editorMode } = useEditorMeta()
    const isRealTimeReadOnly = useIsReadOnlyMode()
    const isEditorInGlossersMode = editorMode === 'glossers'

    useContextMenuBuilder(
        'generalEditable',
        (menu, nodeEntry, range) => {
            if (isRealTimeReadOnly) return

            if (!Tag.hasTag(editor, { at: range })) {
                const isExpanded = Range.isExpanded(range)
                editor.availableTags.forEach((tag) => {
                    switch (tag.tagType) {
                        case 'unclear':
                            if (!isEditorInGlossersMode && !menu.hasItemWithId('markAsUnclear')) {
                                menu.addItem({
                                    order: 40,
                                    id: 'markAsUnclear',
                                    label: 'Mark as Unclear',
                                    shortcut: 'Alt + ?',
                                    icon: <VolumeOffIcon />,
                                    onAction: (editor, range) => {
                                        if (range.current) {
                                            const string = Editor.string(editor, range.current)

                                            if (string && string.length > TAG_MAX_CHARS_LENGTH) {
                                                addToast({
                                                    intent: Intent.WARNING,
                                                    message: (
                                                        <span>
                                                            <strong>{string}</strong> could not be
                                                            added.
                                                        </span>
                                                    ),
                                                })
                                                return
                                            }
                                            Suggestion.unwrapSuggestion(editor, {
                                                at: range.current,
                                            })
                                            Tag.insertTag(
                                                editor,
                                                { type: 'unclear' },
                                                { at: range.current },
                                            )
                                            analytics?.sendMarkUnclear(
                                                ActionTriggerSource.CONTEXT_MENU,
                                            )
                                        }
                                    },
                                })
                            }
                            break

                        case 'glossary':
                            if (!menu.hasItemWithId('searchInGlossary')) {
                                menu.addItem({
                                    order: 20,
                                    id: 'searchInGlossary',
                                    label: 'Search in Glossary',
                                    shortcut: isMac ? 'Cmd + G' : 'Ctrl + G',
                                    icon: <SearchIcon />,
                                    onAction: () => {
                                        openPopup('glossary')
                                        return false
                                    },
                                })
                            }
                            break

                        case 'label': {
                            if (tag.kind === 'void' && !isExpanded && !!tag.data?.labels) {
                                for (const label of tag.data.labels as Label[]) {
                                    menu.addItem(
                                        {
                                            label: label.text,
                                            onAction: (editor, range) => {
                                                if (range.current) {
                                                    Suggestion.unwrapSuggestion(editor, {
                                                        at: range.current,
                                                    })
                                                    Tag.insertTag(
                                                        editor,
                                                        { type: 'label', label },
                                                        { at: range.current },
                                                    )
                                                }
                                            },
                                        },
                                        'labels',
                                    )
                                }
                                if (tag.data.labels.length) {
                                    menu.setSection('labels', { label: 'Labels', items: [] })
                                }
                            }
                        }
                    }
                })
            } else {
                const match = Tag.getTag(editor, { at: range })
                const totalTextNodes = Array.from(
                    Editor.nodes(editor, { at: range, match: Text.isText }),
                )

                if (match?.length) {
                    const [glossTag] = match
                    // check if we selected only one tag
                    const hasOnlyOneTag = totalTextNodes.length === 1

                    if (
                        !menu.hasItemWithId('editGlossaryTerm') &&
                        hasOnlyOneTag &&
                        glossTag.tagType === 'glossary'
                    ) {
                        menu.addItem({
                            id: 'editGlossaryTerm',
                            label: 'Edit Glossary Term',
                            icon: <FaEdit />,
                            onAction: (a, currentRangeRef) => {
                                // create a copy of currentRangeRef because it will be removed after the context menu will close
                                // (because the context menu gets closed after onAction)
                                const nextRangeRef =
                                    currentRangeRef.current &&
                                    Editor.rangeRef(editor, currentRangeRef.current, {
                                        affinity: currentRangeRef.affinity,
                                    })

                                openModal({
                                    name: 'glossaryEdit',
                                    props: {
                                        source: 'Context Menu',
                                        term: glossTag.glossary,
                                        onEdit: (term) => {
                                            closeModal()

                                            if (nextRangeRef?.current) {
                                                ReactEditor.focus(editor)

                                                Tag.unwrapTag(editor, { at: nextRangeRef.current })
                                                Tag.insertTag(
                                                    editor,
                                                    { type: 'glossary', glossary: term },
                                                    {
                                                        contents: term.text,
                                                        at: nextRangeRef.current,
                                                    },
                                                )
                                            }
                                        },
                                    },
                                })
                            },
                        })
                    }
                }
            }
        },
        [editor, openPopup, analytics],
    )

    const { addTerm } = useGlossary()

    useContextMenuBuilder(
        'tag',
        (menu, [node, path], range) => {
            if (isRealTimeReadOnly) return

            if (!isEditorInGlossersMode && !menu.hasItemWithId('removeTag')) {
                menu.addItem({
                    order: 50,
                    id: 'removeTag',
                    label: 'Remove Tag',
                    icon: <RemoveIcon />,
                    onAction: (editor, range) => {
                        if (range.current) {
                            Tag.unwrapTag(editor, { at: range.current })
                        }
                    },
                })
            }

            const totalTags = Array.from(
                Editor.nodes(editor, { at: range, match: Tag.isSelectionTag }),
            )
            const nodeContents = Node.string(node).replace(ZERO_WIDTH_WHITESPACE_REGEX, '')
            if (
                totalTags.length === 1 &&
                !menu.hasSection('glossary_modifications') &&
                Tag.isTag(node) &&
                Tag.isSelectionTag(node) &&
                Tag.isGlossaryTag(node) &&
                node.glossary.text !== nodeContents
            ) {
                menu.setSection('glossary_modifications', {
                    label: 'Modified Glossary Term',
                    items: [],
                })

                menu.addItem({
                    label: (
                        <>
                            Revert to: <b>{node.glossary.text}</b>
                        </>
                    ),
                    icon: <Icons.RevertIcon />,
                    onAction: (editor) => {
                        const tagRange = Tag.getTagRange(editor, path)
                        if (tagRange) {
                            const tagRangeRef = Editor.rangeRef(editor, tagRange, {
                                affinity: 'inward',
                            })
                            if (tagRangeRef.current) {
                                Tag.unwrapTag(editor, { at: tagRangeRef.current })
                                Tag.insertTag(
                                    editor,
                                    { type: 'glossary', glossary: node.glossary },
                                    { at: tagRangeRef.current, contents: node.glossary.text },
                                )
                            }
                        }
                    },
                })
                menu.addItem({
                    label: (
                        <>
                            Add: <b>{nodeContents}</b>
                        </>
                    ),
                    icon: <BsPlus />,
                    onAction: (editor) => {
                        const tagRange = Tag.getTagRange(editor, path)
                        if (tagRange) {
                            const tagRangeRef = Editor.rangeRef(editor, tagRange, {
                                affinity: 'inward',
                            })
                            if (tagRangeRef.current) {
                                const newTerm = {
                                    id: TEMPORARY_GLOSSARY_ID,
                                    text: nodeContents,
                                    category: node.glossary.category,
                                }
                                addTerm(newTerm, 'Context Menu')
                                Tag.unwrapTag(editor, { at: tagRangeRef.current })
                                Tag.insertTag(
                                    editor,
                                    { type: 'glossary', glossary: newTerm },
                                    { at: tagRangeRef.current, contents: nodeContents },
                                )
                            }
                        }
                    },
                })
            }
        },
        [editor],
    )
}
