import { useMemo, ReactNode } from 'react'
import { Button, Menu, MenuDivider, MenuItem } from '@blueprintjs/core'
import { Select, ItemRenderer, ItemListRenderer } from '@blueprintjs/select'
import { CaretDownIcon } from '@verbit-ai/icons-library'
import styled from 'styled-components/macro'
import { ifProp, palette } from 'styled-tools'

import { MenuItemInterface } from 'src/models'

const Label = styled.span<{ hasValue: boolean }>`
    color: ${ifProp(({ hasValue }) => hasValue, palette('blue', 1), palette('grey', 8))};
    font-weight: 400;
    text-transform: capitalize;
`

const renderItem: ItemRenderer<GroupedMenuItem> = (item, { handleClick, modifiers }) => {
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.key}
            onClick={handleClick}
            text={item.label}
        />
    )
}

const renderItemList: ItemListRenderer<GroupedMenuItem> = ({
    items,
    itemsParentRef,
    query,
    renderItem,
}) => {
    const menuItems: (JSX.Element | null)[] = []
    let prevItem: GroupedMenuItem | undefined

    for (let [i, item] of items.entries()) {
        // permanently omit calibration_anchor from the list of options if exist
        // accessible to F1 Keypress shortcut only
        if (item.key === 'calibration_anchor') {
            continue
        }

        if (item.group !== 0 && prevItem?.group !== item.group) {
            menuItems.push(<MenuDivider key={`divider-group-${item.group}`} />)
        }

        menuItems.push(renderItem(item, i))
        prevItem = item
    }

    return <Menu ulRef={itemsParentRef}>{menuItems}</Menu>
}

interface GroupedMenuItem extends MenuItemInterface {
    group: number
}

const TypedSelect = Select.ofType<GroupedMenuItem>()

interface SectionSelectProps {
    items: MenuItemInterface[][]
    value?: string
    onChange: (item: MenuItemInterface) => void
    disabled?: boolean
    onClose?: () => unknown
    placeholder?: ReactNode
    className?: string
}

export const SectionSelect = ({
    items,
    value,
    onChange,
    disabled = false,
    onClose,
    placeholder,
    className,
}: SectionSelectProps) => {
    const flattenedItems = useMemo(() => {
        const flattened: GroupedMenuItem[] = []
        for (let [groupIdx, group] of items.entries()) {
            for (let item of group) {
                flattened.push({ ...item, group: groupIdx })
            }
        }

        return flattened
    }, [items])

    const selectedItem: MenuItemInterface | undefined = useMemo(() => {
        return flattenedItems.find((item) => item.key === value)
    }, [flattenedItems, value])

    return (
        <TypedSelect
            items={flattenedItems}
            className={className + ' select-control'}
            disabled={disabled}
            onItemSelect={onChange}
            itemListRenderer={renderItemList}
            itemRenderer={renderItem}
            filterable={false}
            popoverProps={{
                onClose,
                popoverClassName: 'section-select-popover',
                position: 'bottom-left',
                minimal: true,
                modifiers: {
                    preventOverflow: { enabled: false },
                    hide: { enabled: false },
                },
            }}
        >
            {value ? (
                <Button
                    rightIcon={!disabled && <CaretDownIcon />}
                    disabled={disabled}
                    minimal={true}
                >
                    <Label hasValue={!!selectedItem}>{selectedItem?.label ?? 'ADD SECTION'}</Label>
                </Button>
            ) : (
                placeholder
            )}
        </TypedSelect>
    )
}
