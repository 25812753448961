export const theme = {
    zIndexes: {
        draggingLayer: 9999,
        videoPlayerResizeable: 1041,
        toasts: 1040,
        headerPopovers: 1040,
        videoPlayer: 1040,
        droppableZone: 1032,
        sidePanel: 1031,
        topBar: 1030,
        audioPlayer: 1030,
        popovers: 1010,
        screenLoaders: 1000,
    },

    sizes: {
        headerHeight: 60,
        speakerBoxWidth: 242,
        audioPlayerHeight: 42,
        onBoardingSideBarWidth: 346,
    },

    // prettier-ignore
    palette: {
        black: [
            '#020219'
        ],
        white: [
            '#ffffff'
        ],

        navy: [
            /** 0  */ '#0c2240',
            /** 1  */ '#1a3062',
            /** 2  */ '#304571',
            /** 3  */ '#1a395c',
            /** 4  */ '#0f2956',
            /** 5  */ '#1d3650',
            /** 6  */ '#10264a',
            /** 7  */ '#193062',
            /** 8  */ '#002d61',
            /** 9  */ '#08254a',
            /** 10 */ '#243e5c',
            /** 11 */ '#182026',
            /** 12 */ '#10264A',

        ],

        cloudBlue: [
            /** 0  */ '#6782a4',
            /** 1  */ '#6d8eb0',
            /** 2  */ '#8aa0b5',
            /** 3  */ '#768daf',
            /** 4  */ '#8ea3b7',
            /** 5  */ '#7396ba',
            /** 6  */ '#5c7199',
        ],

        grey: [
            /** 0  */ '#F5F5F6',
            /** 1  */ '#F3F6FB',
            /** 2  */ '#DFE8F1',
            /** 3  */ '#CCDAE9',
            /** 4  */ '#E5ECF4',
            /** 5  */ '#E4E4E4',
            /** 6  */ '#B3B3BA',
            /** 7  */ '#ADEBFF4D',
            /** 8  */ '#757584',
            /** 9  */ '#4F5A65',
            /** 10  */ '#4E4E5F',
            /** 11  */ '#CCCCD1',
            /** 12  */ '#e5e5e6',
            /** 13  */ '#e4e4e5',
            /** 14  */ '#f4f6fe',
            /** 15  */ '#91919D',
            /** 16  */ '#dee5fd',
        ],

        greyBlue: [
            /** 0  */ '#707d92',
            /** 1  */ '#7d889e',
            /** 2  */ '#8a93a9',
        ],

        cloudBlueLight: [
            /** 0  */ '#b0c7de',
            /** 1  */ '#ccdae9',
            /** 2  */ '#e8eef4',
            /** 3  */ '#d8e3fb',
            /** 4  */ '#a3bad3',
            /** 5  */ '#dce5ed',
            /** 6  */ '#cfdbe6',
            /** 7  */ '#f3f6fb',
            /** 8  */ '#cbd6df',
            /** 9  */ '#e2e7eb',
        ],

        blue: [
            /** 0  */ '#3b72ea',
            /** 1  */ '#1A4AF0',
            /** 2  */ '#2B7ABB',
            /** 3  */ '#194DDD',
            /** 4  */ '#573AA6',
        ],

        green: [
            /** 0  */ '#15CE96',
            /** 1  */ '#28D8C3',
            /** 2  */ '#40E0D0',
            /** 3  */ '#008A24',
        ],

        red: [
            /** 0  */ '#ff1a5b',
            /** 1  */ '#fc2f3e25',
            /** 2  */ '#fc2f3e50',
            /** 3  */ '#d93a36',
        ],
        
        turquoise: [
            /** 0  */ '#28d8c3',
            /** 1  */ '#24c1b3',
            /** 2  */ '#c1eae5',
        ],

        purple:[
            /** 0  */ '#8c5c99',
        ],

        orange:[
            /** 0  */ '#FFB62F',
        ],
    },
}

export type Theme = typeof theme

export const sizes = {
    laptop: '1024px',
    largeLaptop: '1360px',
    desktop: '2560px',
}
