import * as React from 'react'
import { Modal, Button } from '@verbit-ai/verbit-ui-library'

type EndSessionModalProps = {
    isOpen: boolean
    onClose: (val: boolean) => void
}

export const EndSessionModal = (props: EndSessionModalProps) => {
    const { isOpen, onClose } = props
    return (
        <Modal isOpen={isOpen} onClose={() => onClose(false)} isCentered>
            <Modal.Header>Are you sure you want to end this session?</Modal.Header>
            <Modal.Body>
                This action will send the transcript for formatting, and no editing can be done
                afterwards. If you haven’t stopped the audio recorder, now would be a good time to
                do so.
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose(false)} variant="secondary" mr={3}>
                    Cancel
                </Button>
                <Button onClick={() => onClose(true)} colorScheme="blue" variant="primary">
                    Yes, end session
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
