import { useState, useEffect, ReactNode, useCallback, useRef, useMemo, RefObject } from 'react'
import { findIndex, keyBy, orderBy } from 'lodash/fp'

import { makeCancelable } from 'src/utils/promise'
import { trimText } from 'src/utils/string'
import { Speaker, SpeakerRole } from 'src/models'
import { useAppMachine } from 'src/state/state-machines/AppMachine/AppMachineProvider'
import { useSessionStatus } from 'src/state/SessionStatusProvider'
import { usePrevious } from 'src/hooks/usePrevious'
import { usePanelTabs } from 'src/components/Session/PanelTabsManager'
import { createSubscriptionService } from 'src/factories/SubscriptionService'

import { useSession } from './session'

type OnCreateSpeakerCallback = (speaker: Speaker) => void

export type PendingSpeaker = Omit<Speaker, 'id' | 'indexForRole'> & { id?: string }

interface SpeakersContextValue {
    speakers: Speaker[]
    addSpeaker: (speaker: PendingSpeaker) => Promise<void>
    updateSpeaker: (speaker: Omit<Speaker, 'indexForRole'>) => Promise<void>
    speakerRoles: SpeakerRole[]
    setPendingSpeaker: (params: {
        pendingSpeaker: PendingSpeaker
        onCreate: OnCreateSpeakerCallback
    }) => void
    clearPendingSpeaker: () => void
    speakerForm: {
        value: PendingSpeaker
        isVisible: boolean
        nameInputRef: RefObject<HTMLInputElement>
    }
    setSpeakerFormValue: (
        value: PendingSpeaker | ((value: PendingSpeaker) => PendingSpeaker),
    ) => void
    setIsSpeakerFormVisible: (isVisible: boolean) => void
    isSelectSpeakerPopupVisible?: boolean
    setIsSelectSpeakerPopupVisible?: (isVisible: boolean) => void
    defaultRole?: SpeakerRole
}

export const DEFAULT_SPEAKER = {
    name: '',
    role: '',
    samples: {},
}

const [SpeakersContextProvider, useSpeakers] = createSubscriptionService<SpeakersContextValue>({
    speakers: [],
    addSpeaker: Promise.resolve,
    updateSpeaker: Promise.resolve,
    speakerRoles: [],
    setPendingSpeaker: () => {},
    clearPendingSpeaker: () => {},
    speakerForm: {
        value: DEFAULT_SPEAKER,
        isVisible: false,
        nameInputRef: { current: null },
    },
    setSpeakerFormValue: () => {},
    setIsSpeakerFormVisible: () => {},
    setIsSelectSpeakerPopupVisible: () => {},
    defaultRole: {
        role: '',
        color: '',
        requiresName: true,
    },
})

export { useSpeakers }

interface SpeakersProviderProps {
    children: ReactNode
}

export const SpeakersProvider = ({ children }: SpeakersProviderProps) => {
    const { taskLayerId, speakersModificationDate } = useSession()
    const [speakers, setSpeakers] = useState<Speaker[]>([])
    const [speakerRoles, setSpeakerRoles] = useState<SpeakerRole[]>([])
    const [defaultRole, setDefaultRole] = useState<SpeakerRole | undefined>(undefined)
    const onCreateSpeakerRef = useRef<OnCreateSpeakerCallback | null>(null)
    const [isSpeakerFormVisible, setIsSpeakerFormVisible] = useState(false)
    const [isSelectSpeakerPopupVisible, setIsSelectSpeakerPopupVisible] = useState(false)
    const [speakerFormValue, setSpeakerFormValue] = useState<PendingSpeaker>(DEFAULT_SPEAKER)
    const speakerFormNameInputRef = useRef<HTMLInputElement>(null)
    const [appMachine] = useAppMachine(['httpClient', 'workerId'])
    const { sessionStatus: status } = useSessionStatus(['sessionStatus.speakersModifiedAt'])
    const prevStatus = usePrevious(status)
    const { setActivePanelTab } = usePanelTabs()
    const { httpClient, workerId } = appMachine.context
    const prevWorkerId = usePrevious(workerId)

    const addSpeaker = useCallback<SpeakersContextValue['addSpeaker']>(
        async (pendingSpeaker) => {
            const { role, name, samples } = pendingSpeaker
            const trimmedName = trimText(name)

            const { id, created_at } = await httpClient.createSpeaker({
                role,
                name: trimmedName,
                qac: 'c',
                samples: Object.values(samples),
            })
            const newSpeaker = {
                ...pendingSpeaker,
                name: trimmedName,
                id,
                createdAt: created_at,
            }

            setSpeakers((speakers) => {
                const nextSpeakers = setIndexForRole(
                    sortSpeakers([...speakers, newSpeaker], speakerRoles),
                )
                const createdSpeaker = nextSpeakers.find((speaker) => speaker.id === id)

                if (createdSpeaker) {
                    onCreateSpeakerRef.current?.(createdSpeaker)
                    onCreateSpeakerRef.current = null
                }

                return nextSpeakers
            })
        },
        [httpClient, speakerRoles, onCreateSpeakerRef],
    )

    const updateSpeaker = useCallback<SpeakersContextValue['updateSpeaker']>(
        async (updatedSpeaker) => {
            const { id, role, name, qac, samples } = updatedSpeaker

            await httpClient.updateSpeaker({
                id,
                role,
                name,
                qac,
                samples: Object.values(samples).map(({ isDefault, ...sample }) => ({
                    ...sample,
                    is_default: isDefault,
                })),
            })

            setSpeakers((speakers) =>
                setIndexForRole(
                    sortSpeakers(
                        speakers.map((speaker) =>
                            speaker.id === updatedSpeaker.id
                                ? { ...speaker, ...updatedSpeaker }
                                : speaker,
                        ),
                        speakerRoles,
                    ),
                ),
            )
        },
        [httpClient, speakerRoles],
    )

    const setPendingSpeaker = useCallback<SpeakersContextValue['setPendingSpeaker']>(
        async ({ pendingSpeaker, onCreate }) => {
            setSpeakerFormValue(pendingSpeaker)
            onCreateSpeakerRef.current = onCreate
            setActivePanelTab({ name: 'SPEAKERS' })

            if (isSpeakerFormVisible) {
                setTimeout(() => speakerFormNameInputRef.current?.focus())
            } else {
                setIsSpeakerFormVisible(true)
            }
        },
        [isSpeakerFormVisible, setSpeakerFormValue, setIsSpeakerFormVisible, setActivePanelTab],
    )

    const clearPendingSpeaker = useCallback(() => {
        onCreateSpeakerRef.current = null
    }, [])

    // Fetch speakers if needed
    const speakersModifiedAt = status?.speakersModifiedAt
    const prevSpeakersModifiedAt = prevStatus?.speakersModifiedAt
    useEffect(() => {
        async function fetchSpeakers() {
            const { speakers, roles, defaultSpeakerRole } = await httpClient.getSpeakers()
            const nextSpeakers = setIndexForRole(sortSpeakers(speakers, roles))

            setSpeakerRoles(roles)
            setSpeakers(nextSpeakers)
            setDefaultRole(defaultSpeakerRole)
        }

        if (
            (!prevWorkerId && workerId) ||
            (speakersModifiedAt &&
                prevSpeakersModifiedAt &&
                speakersModifiedAt > prevSpeakersModifiedAt) ||
            (speakersModificationDate &&
                speakersModifiedAt &&
                speakersModificationDate > speakersModifiedAt)
        ) {
            if (taskLayerId !== 'edit') {
                const { cancel, promise } = makeCancelable(fetchSpeakers())
                promise.catch(() => {})
                return cancel
            }
        }
    }, [
        speakersModifiedAt,
        prevSpeakersModifiedAt,
        httpClient,
        workerId,
        prevWorkerId,
        taskLayerId,
        speakersModificationDate,
    ])

    useEffect(() => {
        if (!isSpeakerFormVisible) {
            clearPendingSpeaker()
        }
    }, [isSpeakerFormVisible, clearPendingSpeaker])

    const SpeakersContextValue = useMemo(
        () => ({
            speakers,
            addSpeaker,
            updateSpeaker,
            speakerRoles,
            setPendingSpeaker,
            clearPendingSpeaker,
            speakerForm: {
                value: speakerFormValue,
                isVisible: isSpeakerFormVisible,
                nameInputRef: speakerFormNameInputRef,
            },
            setSpeakerFormValue,
            setIsSpeakerFormVisible,
            isSelectSpeakerPopupVisible,
            setIsSelectSpeakerPopupVisible,
            defaultRole,
        }),
        [
            speakers,
            addSpeaker,
            updateSpeaker,
            speakerRoles,
            setPendingSpeaker,
            clearPendingSpeaker,
            speakerFormValue,
            isSpeakerFormVisible,
            speakerFormNameInputRef,
            setSpeakerFormValue,
            setIsSpeakerFormVisible,
            isSelectSpeakerPopupVisible,
            setIsSelectSpeakerPopupVisible,
            defaultRole,
        ],
    )

    return <SpeakersContextProvider data={SpeakersContextValue}>{children}</SpeakersContextProvider>
}

export const useSpeakersById = () => {
    const { speakers } = useSpeakers(['speakers'])

    return useMemo(() => keyBy('id', speakers), [speakers])
}

export const useSpeakerRolesByRole = () => {
    const { speakerRoles } = useSpeakers(['speakerRoles'])

    return useMemo(() => keyBy('role', speakerRoles), [speakerRoles])
}

const sortSpeakers = (speakers: Omit<Speaker, 'indexForRole'>[], roles: SpeakerRole[]) =>
    // prettier-ignore
    orderBy(
        [
            ({role}) => findIndex({role}, roles),
            ({createdAt}) => createdAt && new Date(createdAt)
        ],
        'asc',
        speakers,
    )

const setIndexForRole = (speakers: Omit<Speaker, 'indexForRole'>[]): Speaker[] => {
    const roleSpeakerCount: { [role: string]: number } = {}

    return speakers.map((speaker) => {
        const indexForRole = roleSpeakerCount[speaker.role] ?? 0

        roleSpeakerCount[speaker.role] = indexForRole + 1

        return {
            ...speaker,
            indexForRole,
        }
    })
}
