import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 360px;
    padding: 18px;
    background-color: ${palette('white', 0)};
    color: ${palette('navy', 7)};
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
`

export const Title = styled.div`
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 8px;
`

export const Message = styled.div`
    font-size: 14px;
`

export const Footer = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: 22px;
`
