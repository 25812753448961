import { sortBy } from './array'

export interface TimingRange {
    start: number
    end: number
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimingRange = {
    duration(range: TimingRange) {
        return range.end - range.start
    },
    isPointInside(range: TimingRange, x: number) {
        return x >= range.start && x <= range.end
    },
    isPointBefore(range: TimingRange, x: number) {
        return x > range.end
    },
    isPointAfter(range: TimingRange, x: number) {
        return x < range.start
    },
    isInside(rangeA: TimingRange, rangeB: TimingRange) {
        return rangeA.start >= rangeB.start && rangeA.end <= rangeB.end
    },
    isBefore(rangeA: TimingRange, rangeB: TimingRange) {
        return rangeA.start <= rangeB.start && rangeA.end <= rangeB.start
    },
    isAfter(rangeA: TimingRange, rangeB: TimingRange) {
        return rangeA.start >= rangeB.end && rangeA.end >= rangeB.end
    },
    isIntersecting(rangeA: TimingRange, rangeB: TimingRange) {
        return !TimingRange.isAfter(rangeA, rangeB) && !TimingRange.isBefore(rangeA, rangeB)
    },
    /** https://www.geeksforgeeks.org/merging-intervals/ **/
    resolveOverlappedRanges(_arr: TimingRange[]) {
        let arr = [..._arr]

        // Test if the given set has at least one range
        if (!arr.length) {
            return arr
        }

        // Create an empty stack of ranges
        const stack: TimingRange[] = []

        // sort the ranges in increasing order of start time
        arr = sortBy('start', arr)

        // push the first range to stack
        stack.push(arr[0])

        // Start from the next range and merge if necessary
        for (let i = 1; i < arr.length; i++) {
            // get range from stack top
            const top = stack[stack.length - 1]

            // if current range is not overlapping with stack top,
            // push it to the stack
            if (top.end < arr[i].start) {
                stack.push(arr[i])
            }

            // Otherwise update the ending time of top if ending of current
            // range is more
            else if (top.end < arr[i].end) {
                top.end = arr[i].end
                stack.pop()
                stack.push(top)
            }
        }

        return stack
    },
    /**
     * This function receives an array of sorted non-overlapping ranges and a border range,
     * and returns all the ranges in between them - all the unoccupied ranges within the border range
     * on any user interaction.
     * @param ranges - sorted non-overlapping ranges
     * @param borderRange - the border that contains all the ranges
     */
    getDiffRanges(ranges: TimingRange[], borderRange: TimingRange) {
        const diffRanges: TimingRange[] = []

        for (let i = -1; i < ranges.length; i++) {
            const currRange = ranges[i]
            const nextRange = ranges[i + 1]
            const deltaRange = {
                start: currRange ? currRange.end : borderRange.start,
                end: nextRange ? nextRange.start : borderRange.end,
            }

            if (TimingRange.isAfter(deltaRange, borderRange)) {
                break
            }

            if (TimingRange.isBefore(deltaRange, borderRange)) {
                continue
            }

            const normalizedDeltaRange = {
                start: Math.max(borderRange.start, deltaRange.start),
                end: Math.min(borderRange.end, deltaRange.end),
            }

            diffRanges.push(normalizedDeltaRange)
        }

        return diffRanges
    },
}
