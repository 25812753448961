import React from 'react'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { isMac } from 'src/utils/platform'
import { useIsSpeakerBoxVisible } from 'src/components/Editor/plugins/withTranscript/hooks/useIsSpeakerBoxVisible'
import { useSession } from 'src/state/session'
import { useIsLiveMachineActive } from 'src/state/state-machines/LiveMachine/LiveMachineProvider'

const Container = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    row-gap: 5px;
    padding: 12px;
    background-color: ${palette('white', 0)};
    overflow: scroll;
    height: calc(100% - 0.5em);

    ::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        z-index: 1000;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border: 1px solid transparent;
        background-clip: padding-box;
        -webkit-transition: background-color 0.1s ease;
        transition: background-color 0.1s ease;
    }
`

const Divider = styled.div`
    grid-column: 1/3;
    height: 12px;
`

const ShortcutAction = styled.div`
    margin-right: 25px;
    font-size: 13px;
    color: #47588c;
`

const Shortcut = styled.span`
    display: flex;
    background-color: ${palette('cloudBlueLight', 2)};
    padding: 3px 5px;
    font-family: Courier, monospace;
    font-size: 12px;
    color: ${palette('navy', 10)};
    text-align: left;
    border-radius: 4px;
    border: solid 1px ${palette('grey', 13)};
    height: 2rem;
    width: 12rem;
`

const KeyboardMacSymbol = styled.span`
    font-weight: bolder;
`

export const ShortcutPanel = () => {
    const { controls } = useSession()
    const speakerBoxVisible = useIsSpeakerBoxVisible(controls)
    const isLiveMachineActive = useIsLiveMachineActive()
    const isTimeCodeVisible = Boolean(controls?.timecode?.visible)

    return (
        <Container>
            {!isLiveMachineActive && (
                <>
                    <ShortcutAction>Publish</ShortcutAction>
                    <Shortcut>
                        {isMac ? (
                            <>
                                <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + P
                            </>
                        ) : (
                            'Alt + P'
                        )}
                    </Shortcut>
                </>
            )}

            <ShortcutAction>Glossary</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + G
                    </>
                ) : (
                    'Ctrl + G'
                )}
            </Shortcut>

            <ShortcutAction>Context Menu (tags)</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + D
                    </>
                ) : (
                    'Ctrl + D'
                )}
            </Shortcut>

            {!isLiveMachineActive && (
                <>
                    <ShortcutAction>Save Transcript</ShortcutAction>
                    <Shortcut>
                        {isMac ? (
                            <>
                                <KeyboardMacSymbol>⌘</KeyboardMacSymbol>&nbsp;(cmd) + S
                            </>
                        ) : (
                            'Ctrl + S'
                        )}
                    </Shortcut>
                </>
            )}

            <ShortcutAction>Unclear</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + ?
                    </>
                ) : (
                    'Alt + ?'
                )}
            </Shortcut>

            {isTimeCodeVisible && (
                <>
                    <ShortcutAction>Calibrate Timecode</ShortcutAction>
                    <Shortcut>F1</Shortcut>
                </>
            )}

            {isLiveMachineActive && (
                <>
                    <ShortcutAction>Choose Q / A / C</ShortcutAction>
                    <Shortcut>
                        {isMac ? (
                            <>
                                <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + Q / A / C
                            </>
                        ) : (
                            'Alt + Q / A / C'
                        )}
                    </Shortcut>
                </>
            )}

            <>
                <ShortcutAction>Add Exhibit</ShortcutAction>
                <Shortcut>
                    {isMac ? (
                        <>
                            <KeyboardMacSymbol>⌘⌥</KeyboardMacSymbol>&nbsp;(cmd) + (option) + X
                        </>
                    ) : (
                        'Ctrl + Alt + x'
                    )}
                </Shortcut>
            </>

            {speakerBoxVisible && (
                <>
                    <ShortcutAction>Add Speaker</ShortcutAction>
                    <Shortcut>
                        {isMac ? (
                            <>
                                <KeyboardMacSymbol>⌘</KeyboardMacSymbol>&nbsp;(cmd) + shift + A
                            </>
                        ) : (
                            'Ctrl + Shift + a'
                        )}
                    </Shortcut>

                    <ShortcutAction>Select Speaker</ShortcutAction>
                    <Shortcut>
                        {isMac ? (
                            <>
                                <KeyboardMacSymbol>⌘</KeyboardMacSymbol>&nbsp;(cmd) + shift + S
                            </>
                        ) : (
                            'Ctrl + Shift + s'
                        )}
                    </Shortcut>
                </>
            )}
            <Divider />

            <ShortcutAction>Play/Pause</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + Space
                    </>
                ) : (
                    'Ctrl + Space'
                )}
            </Shortcut>

            <ShortcutAction>-2 sec</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌘</KeyboardMacSymbol>&nbsp;(cmd) + ←
                    </>
                ) : (
                    'Ctrl + ←'
                )}
            </Shortcut>

            <ShortcutAction>+2 sec</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌘</KeyboardMacSymbol>&nbsp;(cmd) + →
                    </>
                ) : (
                    'Ctrl + →'
                )}
            </Shortcut>

            <ShortcutAction>Replay Task</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + S
                    </>
                ) : (
                    'Alt + S'
                )}
            </Shortcut>

            <Divider />

            <ShortcutAction>Increase Speed</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + {'>'}
                    </>
                ) : (
                    'Alt + >'
                )}
            </Shortcut>

            <ShortcutAction>Decrease Speed</ShortcutAction>
            <Shortcut>
                {isMac ? (
                    <>
                        <KeyboardMacSymbol>⌥</KeyboardMacSymbol>&nbsp;(option) + {'<'}
                    </>
                ) : (
                    'Alt + <'
                )}
            </Shortcut>
        </Container>
    )
}
