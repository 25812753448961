import React, { useCallback, useState, useEffect, useRef } from 'react'
import {
    Button,
    Classes,
    Dialog,
    Intent,
    InputGroup,
    Position,
    Icon,
    Spinner,
} from '@blueprintjs/core'
import { Select, ItemRenderer } from '@blueprintjs/select'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'
import { GlossaryTerm, GlossaryTermCategory } from 'src/models/glossary-term'
import { getTermCategoryIcon } from 'src/components/Glossary/GlossaryItem'
import { SelectButton, SelectMenu, SelectMenuItem } from 'src/components/Common/styled'
import { useToast } from 'src/components/Toasts/ToastContext'
import { ModalComponentProps } from 'src/state/ModalProvider'
import { useGlossary } from 'src/state/GlossaryProvider'
import { APIError } from 'src/network'
import { KeyStringCodes } from 'src/utils/keyboard'
import { GlossaryEventSource } from 'src/analytics/client'
import { validateTermText } from './useGlossaryList'

export interface GlossaryEditModalProps extends ModalComponentProps {
    source: GlossaryEventSource
    term: GlossaryTerm
    onEdit?: (term: GlossaryTerm) => void
}

const TypeOptions: GlossaryTermCategory[] = ['term', 'person', 'place']
const TypeSelect = styled(Select.ofType<GlossaryTermCategory>())`
    .bp4-overlay {
        display: block !important;
        position: relative;
        width: 100%;

        .bp4-transition-container {
            width: 100%;
            top: 3px !important;
            transform: none !important;

            .bp4-popover {
                width: 100%;
                .bp4-popover-content {
                    width: 100%;
                }
            }
        }
    }
`

export const FlexDiv = styled.div`
    display: flex;
`
const VertFlexDiv = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 12px 20px 0 0;

    &:last-of-type {
        margin-right: 0;
    }
`

const Label = styled.div`
    font-min-size: 14px;
    margin-bottom: 5px;
`

export const StyledDialog = styled(Dialog)`
    padding-bottom: 12px;
    background-color: ${palette('white', 0)};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px ${palette('cloudBlueLight', 0)};
    border-radius: 4px;

    .bp4-dialog-header {
        height: 38px;
        padding: 10px 18px;
        line-height: normal;
        font-size: 14px;
        font-weight: bold;
        color: ${palette('navy', 1)};
        border-bottom: solid 1px ${palette('cloudBlueLight', 0)};
        border-radius: 0;
        box-shadow: none;

        .bp4-button {
            padding: 0;
            min-height: 27px;
            min-width: 27px;

            &:hover,
            &:active {
                background-color: transparent;

                .bp4-icon-small-cross {
                    color: ${palette('cloudBlue', 0)};
                }
            }

            .bp4-icon-small-cross {
                margin: 0;

                > svg {
                    width: 27px;
                    height: 27px;

                    path {
                        fill: currentColor;
                    }
                }
            }
        }
    }

    .bp4-dialog-body {
        margin: 0;
        padding: 12px 16px;
        color: ${palette('cloudBlue', 0)};

        .glossary-input {
            input,
            .bp4-button {
                height: 28px;
                min-height: unset;
                padding: 0 8px;
                color: ${palette('navy', 2)};
                font-size: 14px;
                border: solid 1px ${palette('cloudBlueLight', 4)};
                background-color: ${palette('white', 0)};
                box-shadow: none !important;
                transition: border-color 200ms ease-out;

                &:hover,
                &:focus,
                &:active {
                    border-color: ${palette('blue', 0)};

                    svg,
                    g,
                    path {
                        fill: ${palette('navy', 2)};
                    }

                    .bp4-button-text {
                        color: ${palette('navy', 3)};
                    }
                }
            }
        }
    }

    .bp4-dialog-footer {
        flex-direction: column;
        margin: 16px 12px 0 12px;
        color: ${palette('navy', 3)};

        .bp4-button {
            width: auto;
            height: 28px;
            min-width: unset;
            min-height: unset;
            margin: 0 18px 0 0;
            padding: 0;
            color: inherit;
            font-size: 16px;
            box-shadow: none;
            transition: color 250ms ease-out, background-color 250ms ease-out;

            &:hover {
                color: ${palette('blue', 0)};
            }

            &:active {
                background-color: ${palette('white', 0)} !important;
                color: ${palette('navy', 0)};
            }

            &:disabled {
                background-color: ${palette('cloudBlueLight', 2)} !important;
                color: ${palette('cloudBlue', 2)};
            }

            &:last-of-type {
                margin-right: 0;
            }

            &.bp4-minimal {
                text-decoration: underline;
                background-color: transparent !important;
            }

            &.bp4-intent-primary {
                padding: 0 24px;
                border: solid 1px ${palette('cloudBlueLight', 4)};
                background-color: ${palette('cloudBlueLight', 0)};
            }

            .bp4-button-text {
                display: flex;
                align-items: center;
            }
        }
    }
`

const StyledSelectButton = styled(SelectButton)`
    justify-content: flex-start;
    width: 100%;

    .bp4-button-text {
        flex: 1;
    }
`

export const FooterNote = styled.div`
    margin-bottom: 16px;
`

const CurrentTermContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
`

const CurrentTermValue = styled.div`
    display: flex;
    align-items: center;
    height: 26px;
    padding: 0 7px;
    margin-left: 6px;
    border-radius: 13px;
    background-color: ${palette('cloudBlueLight', 3)};
    color: ${palette('blue', 0)};

    svg {
        width: 16px;
        height: 16px;
        margin-right: 2px;
    }
`

const SubmitSpinner = styled(Spinner)`
    margin-right: 6px;

    svg {
        width: 16px;
        height: 16px;
    }
`

const SubmitNetworkError = styled.div`
    margin-bottom: 25px;
    font-size: 14px;
    color: ${palette('red', 0)};
`

interface CurrentTermProps {
    term: GlossaryTerm
}

export const CurrentTerm = ({ term }: CurrentTermProps) => (
    <CurrentTermContainer>
        <div>Current term</div>
        <CurrentTermValue>
            {getTermCategoryIcon(term.category)}
            <div>{term.text}</div>
        </CurrentTermValue>
    </CurrentTermContainer>
)

export function GlossaryEditModal({ source, term, onClose, onEdit }: GlossaryEditModalProps) {
    const [termText, setTermText] = useState('')
    const [termCategory, setTermCategory] = useState<GlossaryTermCategory>(term.category)
    const [submitNetworkError, setSubmitNetworkError] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { editTerm } = useGlossary()
    const addToast = useToast()

    useEffect(() => setTermText(term.text), [term.text])
    useEffect(() => setTermCategory(term.category), [term.category])

    const isTermTextAllowed = validateTermText(termText)

    const onSaveChanges = useCallback(async () => {
        setSubmitNetworkError('')

        const updatedTerm = {
            ...term,
            text: termText,
            category: termCategory,
        }

        try {
            setIsSubmitting(true)
            await editTerm(updatedTerm, source)

            addToast({
                intent: Intent.NONE,
                icon: <Icon icon="tick" color="#0f9960" />,
                message: (
                    <span>
                        <strong>{term.text}</strong> has been updated.
                    </span>
                ),
            })

            onEdit?.(updatedTerm)
        } catch (e) {
            console.log({ e })
            setSubmitNetworkError(
                e instanceof APIError ? e.message : 'Submit network error, please try again',
            )
        } finally {
            setIsSubmitting(false)
        }
    }, [term, termText, termCategory, editTerm, addToast, onEdit, source])

    const firstTextField = useRef<HTMLInputElement | null>(null)
    const onOpened = useCallback(() => {
        firstTextField.current?.focus()
    }, [firstTextField])

    return (
        <StyledDialog
            // Handled by modal context - the Dialog will always be open when it is rendered
            isOpen={true}
            onClose={onClose}
            onOpened={onOpened}
            isCloseButtonShown={true}
            title="Edit Glossary"
            lazy
        >
            <div className={Classes.DIALOG_BODY}>
                <CurrentTerm term={term} />
                <FlexDiv>
                    <VertFlexDiv>
                        <Label>{getTypeLabel(termCategory)}</Label>
                        <InputGroup
                            className="glossary-input"
                            inputRef={(el) => (firstTextField.current = el)}
                            large
                            value={termText}
                            onKeyDown={(e) => {
                                if (e.code === KeyStringCodes.Enter) {
                                    e.stopPropagation()
                                    onSaveChanges()
                                }
                            }}
                            onChange={(e: React.FormEvent<HTMLInputElement>) =>
                                setTermText(e.currentTarget.value)
                            }
                        />
                    </VertFlexDiv>
                    <VertFlexDiv>
                        <Label>Select type</Label>
                        <TypeSelect
                            className="glossary-type-edit glossary-input"
                            items={TypeOptions}
                            itemListRenderer={({ items, renderItem, itemsParentRef }) => (
                                <SelectMenu ulRef={itemsParentRef}>
                                    {items.map(renderItem)}
                                </SelectMenu>
                            )}
                            itemRenderer={renderType}
                            onItemSelect={(item) => setTermCategory(item)}
                            filterable={false}
                            popoverProps={{
                                popoverClassName: 'trax-select-popover',
                                position: Position.BOTTOM_LEFT,
                                minimal: true,
                                fill: true,
                                usePortal: false,
                                modifiers: { arrow: { enabled: false } },
                            }}
                        >
                            <StyledSelectButton
                                large
                                icon={getTermCategoryIcon(termCategory)}
                                rightIcon="caret-down"
                                text={getTypeName(termCategory)}
                            />
                        </TypeSelect>
                    </VertFlexDiv>
                </FlexDiv>
            </div>
            <FlexDiv className={Classes.DIALOG_FOOTER}>
                <FooterNote>This change will apply for everyone</FooterNote>
                <SubmitNetworkError>{submitNetworkError}</SubmitNetworkError>
                <FlexDiv>
                    <Button
                        intent={Intent.PRIMARY}
                        onClick={onSaveChanges}
                        type="submit"
                        disabled={!isTermTextAllowed || isSubmitting}
                        fill
                        large
                    >
                        {isSubmitting && <SubmitSpinner />}
                        Save
                    </Button>
                    <Button intent={Intent.NONE} onClick={onClose} fill large minimal>
                        Cancel
                    </Button>
                </FlexDiv>
            </FlexDiv>
        </StyledDialog>
    )
}

function getTypeName(type: GlossaryTermCategory) {
    switch (type) {
        case 'term':
            return 'General Term'
        case 'person':
            return 'Person'
        case 'place':
            return 'Place'
    }
}

function getTypeLabel(type: GlossaryTermCategory) {
    switch (type) {
        case 'term':
            return 'New term'
        case 'person':
            return 'New name'
        case 'place':
            return 'New place'
    }
}

const renderType: ItemRenderer<GlossaryTermCategory> = (category, { handleClick, modifiers }) => (
    <SelectMenuItem
        key={category}
        icon={getTermCategoryIcon(category)}
        text={getTypeName(category)}
        active={modifiers.active}
        onClick={handleClick}
    />
)
