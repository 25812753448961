import { useDrop } from 'react-dnd'
import { useVideo } from 'src/state/VideoProvider'
import { VideoPlayerResizeType, VideoResizeEdge } from './DraggableVideoPlayer'
import { VideoPlayerDragType } from './VideoPlayer'

export function useVideoSnappedDropZone() {
    const {
        setIsVideoSnappedIntoPosition,
        videoDimensions,
        moveVideoTo,
        calculateResizedVideoDimensions,
    } = useVideo()

    const dropProps = useDrop(
        () => ({
            accept: [VideoPlayerDragType, VideoPlayerResizeType],
            collect: (monitor) => ({
                isVideoOver:
                    monitor.isOver({ shallow: true }) &&
                    monitor.getItemType() === VideoPlayerDragType,
            }),
            drop: (_, monitor) => {
                const itemType = monitor.getItemType()
                const item = monitor.getItem<{ edge: VideoResizeEdge } | null>()

                const currentOffset = monitor.getSourceClientOffset()
                const currentOffsetX = Math.round(currentOffset?.x ?? 0)
                const currentOffsetY = Math.round(currentOffset?.y ?? 0)

                switch (itemType) {
                    case VideoPlayerDragType: {
                        setIsVideoSnappedIntoPosition(true)
                        break
                    }
                    case VideoPlayerResizeType: {
                        if (item?.edge) {
                            const { coords, dimension } = calculateResizedVideoDimensions(
                                { x: currentOffsetX, y: currentOffsetY },
                                item.edge,
                            )

                            moveVideoTo(coords, dimension)
                        }
                    }
                }
            },
        }),
        [
            setIsVideoSnappedIntoPosition,
            moveVideoTo,
            calculateResizedVideoDimensions,
            videoDimensions,
        ],
    )

    return dropProps
}
