import { Editor, Location, Node, Text, Transforms, NodeEntry, Path, Point, Range } from 'slate'

import { appcues } from 'src/appcues'
import { uuid } from 'src/utils/uuid'
import { analytics } from 'src/analytics/singleton'

import { ContentText } from 'src/components/Editor/plugins/withTranscript'
import { InvalidMessageData } from './ValidationPopup'

interface AtOptions {
    at?: Location
}
export interface ValidationMessage {
    messageTitle: string
    messageSegments: string[]
}

interface FoundTerm {
    start: number
    end: number
    invalidValidationId: string
    validationName: string
}

export interface ValidationRegexObject {
    id: string
    regex: RegExp
    validationName: string
    validationMessage: ValidationMessage
}

export type ValidationsCount = Record<string, number>

export const Validations = {
    isValidationEditor(editor: Editor): boolean {
        return !!(editor.refMap instanceof Map)
    },

    setEditorTextualChange(editor: Editor, isChanged: boolean) {
        editor.textualChangeAppliedToEditor = isChanged
    },

    isTextualChangeAppliedToEditor(editor: Editor) {
        return editor.textualChangeAppliedToEditor
    },

    getInvalidTermData(
        editor: Editor,
        node: Text,
        regexExpressions: ValidationRegexObject[],
    ): FoundTerm | null {
        for (const { id: invalidValidationId, regex, validationName } of regexExpressions) {
            const matchedExpressionResult = regex.exec(Node.string(node))
            if (!!matchedExpressionResult) {
                const { index: offsetStart } = matchedExpressionResult
                const foundExpression = matchedExpressionResult[0]
                return {
                    start: offsetStart,
                    end: offsetStart + foundExpression.length - 1,
                    invalidValidationId,
                    validationName,
                }
            }
        }
        return null
    },

    getInvalidTermsCount(editor: Editor): number {
        const invalidTerms = Array.from(
            Editor.nodes(editor, {
                at: [],
                mode: 'all',
                match: Validations.isInvalidValidation,
            }),
        )
        return invalidTerms?.length || 0
    },

    sendValidationToAppcues() {
        appcues?.track('validation_triggered')
    },

    setInvalidNode(editor: Editor, options: AtOptions, invalidValidationId: string) {
        if (!editor.firstValidationTriggered) {
            editor.firstValidationTriggered = true
            Validations.sendValidationToAppcues()
        }

        let { at } = options
        if (!at) return

        const shouldTrackValidationPath = !Validations.isTextualChangeAppliedToEditor(editor)

        if (shouldTrackValidationPath) {
            if (Path.isPath(at)) {
                at = {
                    anchor: Editor.start(editor, at),
                    focus: Editor.end(editor, at),
                }
            } else if (Point.isPoint(at)) {
                at = {
                    anchor: at,
                    focus: at,
                }
            }

            const id = uuid()
            const newRef = Editor.rangeRef(editor, at, { affinity: 'outward' })

            if (newRef.current) {
                const string = Editor.string(editor, newRef.current)
                editor.refMap.set(id, { ref: newRef, beforeString: string, invalidValidationId })
            }
        }

        /**
         * This field is required for avoiding merging 2 invalid nodes (when it is incorrect to do so).
         * At the moment, it set only to "legal-000". Might be set to more nodes in the future if needed
         * */
        const randomIdForAvoidingMergeNodes =
            invalidValidationId === 'legal-000'
                ? `invalidValidationId-${Math.round(Math.random() * 100)}`
                : undefined

        Transforms.setNodes(
            editor,
            {
                invalidValidationId,
                randomIdForAvoidingMergeNodes,
            },
            {
                at,
                split: true,
                match: Text.isText,
            },
        )
    },

    isInvalidValidation(elem: any) {
        return Text.isText(elem) && !!elem?.invalidValidationId
    },

    removeInvalidFlagFromNode(editor: Editor, options: AtOptions) {
        const { at } = options
        if (!at) return
        const [nodeToUnset] = Editor.node(editor, at)
        if (!!(nodeToUnset as ContentText).randomIdForAvoidingMergeNodes) {
            Transforms.unsetNodes(editor, 'randomIdForAvoidingMergeNodes', {
                at,
                match: Text.isText,
            })
        }
        Transforms.unsetNodes(editor, 'invalidValidationId', { at, match: Text.isText })
    },

    getInvalidMessageData(
        editor: Editor,
        invalidValidationId: string,
    ): InvalidMessageData | undefined {
        const messageData = editor.regexExpressionsMap?.get(invalidValidationId)
        if (!!messageData) {
            const { validationName, messageTitle, messageSegments } = messageData
            return { validationName, messageTitle, messageSegments }
        }
    },

    getCurrentValidations(editor: Editor) {
        const triggeredValidationsCount: ValidationsCount = {}
        const invalidNodesArr: NodeEntry<ContentText>[] = Array.from(
            Editor.nodes(editor, {
                at: [],
                match: Validations.isInvalidValidation,
            }),
        )

        invalidNodesArr.forEach(([node]) => {
            if (node.invalidValidationId) {
                const currentInvalidNodeCount = triggeredValidationsCount[node.invalidValidationId]
                triggeredValidationsCount[node.invalidValidationId] = currentInvalidNodeCount
                    ? currentInvalidNodeCount + 1
                    : 1
            }
        })
        return triggeredValidationsCount
    },

    compareBeforeAndAfterStrings(editor: Editor) {
        for (const [, value] of editor.refMap.entries()) {
            if (!value.ref.current || !Range.isRange(value.ref.current)) {
                window.Rollbar?.error(
                    `Invalid validation ref: ${JSON.stringify(value.ref.current)}`,
                )
                continue
            }

            const beforeString = value.beforeString
            const afterString = Editor.string(editor, value.ref.current)
            const validationId = value.invalidValidationId

            if (analytics) {
                analytics.sendValidationBeforeAndAfterStrings(
                    beforeString,
                    afterString,
                    validationId,
                )
            }
            value.ref.unref()
        }

        editor.refMap = new Map()
    },
}
