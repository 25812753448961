import { Classes } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import styled from 'styled-components/macro'
import { palette } from 'styled-tools'

import { DangerIcon } from 'src/components/icons'

const StyledPopover = styled(Popover2)`
    &.bp4-popover2-target {
        display: flex;
    }
`

const Container = styled.div`
    max-width: 390px;
    padding: 24px;
    background: ${palette('white', 0)};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`

const Header = styled.div`
    display: flex;
    margin-bottom: 15px;
`

const StyledDangerIcon = styled(DangerIcon)`
    margin-right: 14px;
    color: #d93a35;
`

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #10264a;
`

const Content = styled.div`
    font-size: 13px;
    line-height: 18px;
    color: #10264a;
`

const RedMarked = styled(Content)`
    display: inline-block;
    background-color: #ffbebe;
    font-weight: 600;
`

export const UnplayedRequiredTimeRangesWarningTooltip = () => (
    <StyledPopover
        lazy
        isOpen
        position={'top'}
        modifiers={{ offset: { options: { offset: [0, 10] } } }}
        content={
            <Container>
                <Header>
                    <StyledDangerIcon />
                    <Title>You didn't listen to all the audio</Title>
                </Header>

                <Content>
                    We’ve highlighted in <RedMarked>&nbsp;red&nbsp;</RedMarked> the audio sections
                    that you’ve missed. Review them and submit again.
                </Content>
            </Container>
        }
        popoverClassName={`${Classes.POPOVER_CONTENT_SIZING} clean-popover-styles`}
    >
        <div />
    </StyledPopover>
)
