import { Editor, BaseElement, Location, Path, Span, Transforms } from 'slate'
import { SuggestionData } from 'src/models/suggestion'

interface AtWithSpanOptions {
    at?: Location | Span
}
interface AtOptions {
    at?: Location
}

interface SuggestionBase extends BaseElement {
    type: 'suggestion'
    seq: string
}
interface GlossarySuggestion extends SuggestionBase {
    suggestion: SuggestionData
    originalText: string
}

export type Suggestion = GlossarySuggestion

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Suggestion = {
    isSuggestion: (el: any): el is Suggestion => {
        return el.type === 'suggestion'
    },

    hasSuggestion: (editor: Editor, options: AtWithSpanOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return false

        const [node] = Editor.nodes(editor, { at, match: (n) => Suggestion.isSuggestion(n) })
        return !!node
    },

    unwrapSuggestion: (editor: Editor, options: AtOptions = {}) => {
        const { at = editor.selection } = options
        if (!at) return

        if (Suggestion.hasSuggestion(editor, { at })) {
            Transforms.unwrapNodes(editor, { at, match: Suggestion.isSuggestion })
        }
    },

    getStartPoint: (editor: Editor, sugPath: Path) => {
        return Editor.start(editor, sugPath)
    },

    getEndPoint: (editor: Editor, sugPath: Path) => {
        return Editor.end(editor, sugPath)
    },
}
