export interface LabelsDictJSON {
    label: {
        type: 'label'
        label: Label
    }[]
}

export interface Label {
    id: string
    text: string
}

export const toLabelsDict = (json: LabelsDictJSON): Label[] => {
    return json.label.map((l) => l.label)
}
