import { sleep } from 'src/utils/time'
import { MockManager, MockManagerMode, MockResourceType } from './MockManager'
import { MockDelayRange, generateRandomMockDelay } from './utils'

type GetResourceIdFn = (...args: any[]) => string

type MockStaticOptions = { resourceId: string | GetResourceIdFn; delay?: MockDelayRange }

export function createMockStaticDecorator(manager: MockManager) {
    return (options: MockStaticOptions) =>
        (target: any, property: string, descriptor: PropertyDescriptor) => {
            if (!manager.isActive()) {
                return
            }

            function getResourceId(...args: any[]) {
                if (typeof options.resourceId === 'string') {
                    return options.resourceId
                } else {
                    return options.resourceId(...args)
                }
            }

            const originalMethod = descriptor.value

            if (typeof originalMethod !== 'function') {
                return
            }

            switch (manager.getMode()) {
                case MockManagerMode.Record:
                    descriptor.value = async function mockedClassMethod(...args: any[]) {
                        const resourceId = getResourceId(...args)
                        const result = await originalMethod.apply(this, args)

                        manager.record(MockResourceType.Static, resourceId, result)

                        return result
                    }
                    break
                case MockManagerMode.Replay:
                    descriptor.value = async function mockedClassMethod(...args: any[]) {
                        await manager.isReady()

                        const resourceId = getResourceId(...args)
                        const result = manager.replayStatic(resourceId)

                        if (options.delay) {
                            await sleep(generateRandomMockDelay(options.delay))
                        }

                        console.log(`[MOCK] Replaying static resource "${resourceId}"`, result)
                        return result
                    }
                    break
            }
        }
}
