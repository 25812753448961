import { useState } from 'react'

import { Icon } from '@blueprintjs/core'
import styled from 'styled-components/macro'
import { ifProp } from 'styled-tools'

import { MockManager } from '../MockManager'

const IconWrapper = styled.button<{ disabled?: boolean }>`
    cursor: ${ifProp('disabled', 'not-allowed', 'pointer')};
    padding: 0px 4px;
    margin: 0px 4px;

    background: inherit;
    border: 0;

    opacity: ${ifProp('disabled', '0.5', '1')};
`

type MockReplayControllerProps = {
    manager: MockManager
}

export function MockReplayController({ manager }: MockReplayControllerProps) {
    const [isPlaying, setIsPlaying] = useState(false)

    const play = () => {
        for (const player of manager.getAllStreamPlayers()) {
            player.play()
        }
        setIsPlaying(true)
    }

    const pause = () => {
        for (const player of manager.getAllStreamPlayers()) {
            player.pause()
        }
        setIsPlaying(false)
    }

    const step = (count?: number) => {
        for (const player of manager.getAllStreamPlayers()) {
            player.step(count)
        }
    }

    const onPlayPauseToggle = (e: React.SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()

        if (isPlaying) {
            pause()
        } else {
            play()
        }
    }

    const onStepForward = (e: React.SyntheticEvent, count?: number) => {
        e.preventDefault()
        e.stopPropagation()

        if (isPlaying) return
        step(count)
    }

    const onStep10StepsForward = (e: React.SyntheticEvent) => onStepForward(e, 10)

    return (
        <>
            <IconWrapper
                onClick={onPlayPauseToggle}
                onTouchStart={onPlayPauseToggle}
                // disabled={liveState.index + 1 >= liveState.length}
            >
                <Icon icon={isPlaying ? 'pause' : 'play'} />
            </IconWrapper>

            <IconWrapper
                onClick={onStepForward}
                onTouchStart={onStepForward}
                // disabled={isPlaying || liveState.index + 1 >= liveState.length}
            >
                <Icon icon="step-forward" />
            </IconWrapper>

            <IconWrapper
                onClick={onStep10StepsForward}
                onTouchStart={onStep10StepsForward}
                // disabled={isPlaying || liveState.index + 10 >= liveState.length}
            >
                <Icon icon="step-forward" />
                <Icon icon="step-forward" />
            </IconWrapper>
        </>
    )
}
